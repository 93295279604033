import { Component, OnInit } from '@angular/core';
import { Helper_Class } from '../../helper_class';
import { ToastrService } from 'ngx-toastr';
import { ipaddress } from 'src/app/ipaddress';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Http,Headers} from '@angular/http';
import { Message_data } from '../../../assets/js/Message_data';
import e from 'express';


@Component({
  selector: 'app-diagnosismaster',
  templateUrl: './diagnosismaster.component.html',
  styleUrls: ['./diagnosismaster.component.scss']
})
export class DiagnosismasterComponent implements OnInit {
  DiagnosticRarraytype: any;
  reagent_type_desc: any;
  DiagnostictRarray: any;
  testtube_data: any[];
  diagnosisapp_id: any;
  tube_color_data: any = [];
  userId: any;
  user_type: string;
  provider_type: any;
  hptl_clinic_id: any;
  diag_centre_id: any;
  pharma_id: any;
  public surgery_name;
  public cost;
  public ReferedDoctorArray: any = [];
  public ReferedNameArray: any = [];
  public doctorName = "";
  public doc_reg_id:any;

  constructor(public http: HttpClient, public toastr: ToastrService,public httpp: Http,) { }
  public DiagnosticRarray: any[] = [];
  public DiagnosticRinputname: string = '';
  public reagent_type_id: string = '';
  public diag_reagent_id: string;
  public masterType;
  public masterpageheading;
  public unityinputname;
  public sampletinputname;
  public iteminputname;
  public itemforminputname;
  public pharmainputname;
  public manufactinputname;
  public specimen_type_id;
  public diag_department_id;
  public tube_type_id;
  public UserInfo;
  public diaag;
  public masterurl;
  public samplearray = [];
  public samplet = [];
  public itemarray = [];
  public pharmaarray = [];
  public manufactarray = [];
  public itemformarray = [];
  public DiagnosticDinputnamearry = [];
  public samplelistarray = [];
  public samplelistinputname;
  public DiagnosticDarray = [];
  public DiagnosticDinputname;
  public Diagnostictarray = [];
  public Diagnostictinputname;
  public DiagnostictypeRarray = [];
  public color_code;
  public tube_size;
  public hosp_id;
  public qualification;
  public surgery_list: any = [];
  public surgery_id;
  public surgpaymentarray: any = [];
  public doc_surg_pay_id:any;

  ngOnInit(): void {
    this.changeProviderType()
    this.DiagnostictRarray = [];
    this.UserInfo = Helper_Class.getInfo();
    this.masterType = Helper_Class.getReportType()
    if(this.UserInfo.user_type == 'front-desk'){
      this.hosp_id=this.UserInfo.hospitals[0].hptl_clinic_id;
    }else if(this.UserInfo.user_type == 'Billing'){
      this.hosp_id=this.UserInfo.hospitals[0].hptl_clinic_id;
    }else if(this.UserInfo.user_type =='Admin'){
      this.hosp_id=this.UserInfo.hospitals[0].hptl_clinic_id;
    }
    else{
      this.hosp_id=this.UserInfo.hospitals[0].hosp_id;
    }
   
    if (this.masterType == 'sampletype') {
      this.masterpageheading = "Specimen Type";
      var sendata = {
        diag_centre_id: this.diaag
      }
      this.samplelistdata(sendata);
      this.getDiagTesttubes();
    }
    else if (this.masterType == 'DiagnosticD') {
      this.masterpageheading = "Diagnostic Department";
      var sendataaa = {
        diag_centre_id: this.diaag
      }
      this.DiagnosticDdata(sendataaa)
    }
    else if (this.masterType == 'Diagnostict') {
      this.masterpageheading = "Diagnostic Tubes";
      var sendataaaa = {
        diag_centre_id: this.diaag
      }
      this.Diagnostictdata(sendataaaa);
    }
    else if (this.masterType == 'DiagnosticR') {
      this.masterpageheading = "Diagnostic Reagents";
      var sendatab = {
        diag_centre_id: this.diaag
      }
      this.DiagnosticRdata(sendatab),
        this.DiagnosticRtypedata()
    }else if(this.masterType == 'surgery_doc'){
      this.masterpageheading = 'Surgery doctor payments';
      var sendata_surg={
        hptl_clinic_id: this.hosp_id
      }
      this.sugerydataDdata(sendata_surg)
    }
    else {
      this.masterpageheading = "errror"
    }  
  }

  



changeProviderType() {

  if  (Helper_Class.getInfo().user_type == 'Admin') {
    this.hptl_clinic_id = Helper_Class.getInfo().hptl_clinic_id;
    this.diaag = Helper_Class.getInfo().diag_centre_id;
    this.pharma_id = Helper_Class.getInfo().pharma_id;
    this.provider_type = "Admin"
    // alert("Admin");
  } 
  else if (Helper_Class.getInfo().user_type == "front-desk") {
    this.hptl_clinic_id = Helper_Class.getInfo().hptl_clinic_id;
    this.diaag = Helper_Class.getInfo().diag_centre_id;
    this.pharma_id = Helper_Class.getInfo().pharma_id;
    this.provider_type = "front-desk"
    // alert("front-desk");
  }                                   
  else if (Helper_Class.getInfo().user_type == "doctor") {
    this.hptl_clinic_id = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
    this.diaag = Helper_Class.getInfo().hospitals[0].diag_centre_id;
    this.pharma_id = Helper_Class.getInfo().hospitals[0].pharma_id;
    this.provider_type = "doctor";
    // alert("doctor");
    
  }
  else if (Helper_Class.getInfo().user_type == "pharmacy") {
    this.hptl_clinic_id = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
    this.diaag = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
    this.pharma_id = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
    this.provider_type = "pharmacy";
    // alert("pharmacy");
  } 
  else if (Helper_Class.getInfo().user_type == "diagnosis") {
    this.hptl_clinic_id = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
    this.diaag = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
    this.pharma_id = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
    this.provider_type = "diagnosis";
    // alert("diagnosis");
  }
  else if (Helper_Class.getInfo().user_type == "physio") {
    this.hptl_clinic_id = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
    this.diaag = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
    this.pharma_id = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
    this.provider_type = "physio";
    // alert("physio");
  }
  else if (Helper_Class.getInfo().user_type == "nurse") {
    this.hptl_clinic_id = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
    this.diaag = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
    this.pharma_id = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
    this.provider_type = "physio";
    // alert("physio");
  }
else{
 // alert("your are not allowed to get this page");
}
}


  //sampletype datat 
  getDiagTesttubes() {
    this.testtube_data = [];
    console.log(" ----------------->>>"+this.diaag)
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'mas/gdtt', { diag_centre_id: this.diaag },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log("obj of getDiag tube"+JSON.stringify(obj));
          // var dept_data = obj.diag_dept_list;
          var diagtube_data = obj.tube_type_list;
          if (diagtube_data.length != 0) {
            for (var i = 0; i < diagtube_data.length; i++) {
              this.testtube_data.push(
                {
                  tube_type_id: diagtube_data[i].tube_type_id,
                  tube_desc: diagtube_data[i].tube_desc,
                  status: diagtube_data[i].status,
                }
              )
            }
          }
        }, error => { });
  }
  samplelistdata(sendata) {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'mas/gdst', sendata, { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          var samplelist = obj.specimen_type_list;
          for (var i = 0; i < samplelist.length; i++) {
            const tube_type_id = samplelist[i].tube_type_id;
            this.samplelistarray.push({
              samplelistname: samplelist[i].specimen_desc,
              specimen_type_id: samplelist[i].specimen_type_id,
              status: samplelist[i].status,
              tube_type_id: tube_type_id,
            });
          }
        },
        error => {
          this.toastr.error('error in sample data');
        }
      )
  }

  addsamplelist() {
    if (this.samplelistinputname != undefined) {
      if (this.samplelistarray.length != 0) {

        for (let i = 0; i < this.samplelistarray.length; i++) {
          if (this.samplelistarray[i].samplelistname == this.samplelistinputname) {
            this.toastr.error("sample already exists");
            break;
          }
          else {
            this.samplelistarray.push({
              samplelistname: this.samplelistinputname,
              specimen_type_id: this.samplelistarray[i].specimen_type_id_,
              tube_type_id: this.samplelistarray[i].tube_type_id,
              status: "1",
            });
            break;
          }
        }
      }
      else {
        for (let i = 0; i < this.samplelistarray.length; i++) {
          this.samplelistarray.push({
            samplelistname: this.samplelistinputname,
            specimen_type_id: this.samplelistarray[i].specimen_type_id,
            tube_type_id: this.samplelistarray[i].tube_type_id,
            status: "1",
          });
        }
      }
      this.savedata(this.samplelistinputname)
      this.samplelistarray = [];
      this.samplelistinputname = null;
    }
  }
  editsamplelistDetails(samplelistname, specimen_type_id) {
    this.specimen_type_id = specimen_type_id
    for (var i = 0; i < this.samplelistarray.length; i++) {
      if (this.samplelistarray[i].samplelistname == samplelistname) {
        this.samplelistinputname = this.samplelistarray[i].samplelistname;
        this.samplelistarray.splice(i, 1);
      }
    }
  }
  deletesamplelistDetails(specimen_type_id) {
    var deletesamplelistDetails;
    var deletesampleliststatus;
    var deletesamplelistid;
    var deletesamplelistcolor;
    var deletesamplelistname;


    const sampleListItem = this.samplelistarray.find(item => item.specimen_type_id === specimen_type_id);

    if (sampleListItem) {
      sampleListItem.status = "0";
      deletesampleliststatus = sampleListItem.status;
      deletesamplelistid = sampleListItem.specimen_type_id;
      deletesamplelistname = sampleListItem.samplelistname;
      deletesamplelistcolor = sampleListItem.tube_type_id; // Assign tube_type_id

      deletesamplelistDetails = {
        specimen_desc: deletesamplelistname,
        specimen_type_id: deletesamplelistid,
        diag_centre_id: this.diaag,
        tube_type_id: deletesamplelistcolor,
        status: deletesampleliststatus,
      };
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + "mas/sdst", deletesamplelistDetails, { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(JSON.stringify(response));
          },
          error => {
            this.toastr.error('error in deleting item form data');
          }
        );
      this.samplelistarray = this.samplelistarray.filter(item => item.specimen_type_id !== specimen_type_id);
    }
  }

  savedata(name) {

    if (this.tube_type_id.length > 1) {
      var reagent_values = ""
      for (var i = 0; i < this.tube_type_id.length; i++) {
        reagent_values += this.tube_type_id[i] + ",";
      }
      var tube_type_values = reagent_values.substring(0, reagent_values.length - 1);
    }
    //alert(tube_type_values)
    var datatobackend;
    if (this.masterType == 'sampletype') {
      this.masterurl = "mas/sdst";
      if (this.specimen_type_id != undefined) {
        datatobackend = {
          specimen_desc: name,
          specimen_type_id: this.specimen_type_id,
          diag_centre_id: this.diaag,
          tube_type_id: tube_type_values,
          status: "1"
        }
      }
      else {
        datatobackend = {
          specimen_desc: name,
          tube_type_id: tube_type_values,
          diag_centre_id: this.diaag,
        }
      }

    }
    else {
      this.toastr.error('This is a success message', 'Tada');
    }

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + this.masterurl, datatobackend,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));

          var sendata = {
            diag_centre_id: this.diaag
          }

          if (this.masterType == 'sampletype') {
            this.samplelistdata(sendata)
          }
          else {
            this.toastr.error('error')
          }
        }, error => {
          this.toastr.error('error')
        }
      )
  }
  //DiagnosticD
  DiagnosticDdata(sendataa) {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'mas/gdd', sendataa,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          var DiagnosticD = obj.diag_dept_list;
          for (var i = 0; i < DiagnosticD.length; i++) {
            this.DiagnosticDarray.push({
              DiagnosticDname: DiagnosticD[i].diag_dept_desc,
              diag_department_id: DiagnosticD[i].diag_department_id,
              status: DiagnosticD[i].status
            })
          }
        }, error => {
          this.toastr.error('error')
        }
      )
  }
  addDiagnosticD() {
    if (this.DiagnosticDinputname != undefined) {
      if (this.DiagnosticDarray.length != 0) {
        for (let i = 0; i < this.DiagnosticDarray.length; i++) {
          if (this.DiagnosticDarray[i].DiagnosticDname == this.DiagnosticDinputname) {
            this.toastr.error("Rack already exists");
            break;
          }
          else {
            this.DiagnosticDarray.push({
              DiagnosticDname: this.DiagnosticDinputname,
              diag_department_id: this.DiagnosticDarray[i].diag_department_id,
              status: "1",
            });
            break;
          }
        }
      }
      else {
        for (let i = 0; i < this.DiagnosticDarray.length; i++) {
          this.DiagnosticDarray.push({
            DiagnosticDname: this.DiagnosticDinputname,
            diag_department_id: this.DiagnosticDarray[i].diag_department_id,
            status: "1",
          });
        }
      }
      this.savedataa(this.DiagnosticDinputname)
      this.DiagnosticDarray = [];
      this.DiagnosticDinputname = null;
    }
  }
  editDiagnosticDDetails(DiagnosticDname, diag_department_id) {
    this.diag_department_id = diag_department_id
    for (var i = 0; i < this.DiagnosticDarray.length; i++) {
      if (this.DiagnosticDarray[i].DiagnosticDname == DiagnosticDname) {
        this.DiagnosticDinputname = this.DiagnosticDarray[i].DiagnosticDname;

        this.DiagnosticDarray.splice(i, 1);
      }
    }
  }
  deleteDiagnosticDDetails(diag_department_id) {
    var deleteDiagnosticDDetails
    var deleteDiagnosticDstatus;
    var deleteDiagnosticDid;
    var deleteDiagnosticDname;
    for (var i = 0; i < this.DiagnosticDarray.length; i++) {
      if (this.DiagnosticDarray[i].diag_department_id == diag_department_id) {
        this.DiagnosticDarray[i].status = "0";
        deleteDiagnosticDstatus = this.DiagnosticDarray[i].status;
        deleteDiagnosticDid = this.DiagnosticDarray[i].diag_department_id;
        deleteDiagnosticDname = this.DiagnosticDarray[i].DiagnosticDname
      }
      deleteDiagnosticDDetails = {
        diag_dept_desc: deleteDiagnosticDname,
        diag_department_id: deleteDiagnosticDid,
        diag_centre_id: this.diaag,
        status: deleteDiagnosticDstatus
      }
    }
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + "mas/sdd", deleteDiagnosticDDetails,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
        }, error => {
          this.toastr.error('error')
        })
    this.DiagnosticDarray = this.DiagnosticDarray.filter(sample => sample.diag_department_id !== diag_department_id);
  }
  savedataa(name) {
    var datatobackend;
    if (this.masterType == 'DiagnosticD') {
      this.masterurl = "mas/sdd";
      if (this.diag_department_id != undefined) {
        datatobackend = {
          diag_dept_desc: name,
          diag_department_id: this.diag_department_id,
          diag_centre_id: this.diaag,
          status: "1"
        }
      }
      else {
        datatobackend = {
          diag_dept_desc: name,
          diag_centre_id: this.diaag,
        }
      }

    }
    else {
      this.toastr.error('error')
    }
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + this.masterurl, datatobackend,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          this.toastr.info('Edited')
          var sendataa = {
            diag_centre_id: this.diaag
          }
          if (this.masterType == 'DiagnosticD') {
            this.DiagnosticDdata(sendataa)
          }
          else {
            this.toastr.error('error')
          }
        }, error => {
          this.toastr.error('error');
        })
  }
  //Diagnostict
  Diagnostictdata(sendataaa) {

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'mas/gdtt', sendataaa,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          var Diagnostict = obj.tube_type_list;
          for (var i = 0; i < Diagnostict.length; i++) {
            this.Diagnostictarray.push({
              Diagnostictname: Diagnostict[i].tube_desc,
              tube_type_id: Diagnostict[i].tube_type_id,
              color_code: Diagnostict[i].color_code,
              status: Diagnostict[i].status
            })
          }
        }, error => {
          this.toastr.error('error');
        })
  }
  addDiagnostict() {
    if (this.Diagnostictinputname != undefined) {
      if (this.Diagnostictarray.length != 0) {
        for (let i = 0; i < this.Diagnostictarray.length; i++) {
          if (this.Diagnostictarray[i].Diagnostictname == this.Diagnostictinputname) {
            this.toastr.error("Diagnostic tube data already exists");
            break;
          }
          else {
            this.Diagnostictarray.push({
              Diagnostictname: this.Diagnostictinputname,
              tube_type_id: this.Diagnostictarray[i].tube_type_id,
              color_code: this.Diagnostictarray[i].color_code,
              tube_size: this.Diagnostictarray[i].tube_size,
              status: "1",
            });
            break;
          }
        }
      }
      else {
        for (let i = 0; i < this.Diagnostictarray.length; i++) {
          this.Diagnostictarray.push({

            Diagnostictname: this.Diagnostictinputname,
            tube_type_id: this.Diagnostictarray[i].tube_type_id,
            color_code: this.Diagnostictarray[i].color_code,
            tube_size: this.Diagnostictarray[i].tube_size,
            status: "1",
          });
        }
      }

      this.savedataaa(this.Diagnostictinputname)
      this.Diagnostictarray = [];

      this.Diagnostictinputname = null;
    }
  }
  editDiagnostictDetails(Diagnostictname, tube_type_id) {
    this.tube_type_id = tube_type_id
    for (var i = 0; i < this.Diagnostictarray.length; i++) {
      if (this.Diagnostictarray[i].Diagnostictname == Diagnostictname) {
        this.Diagnostictinputname = this.Diagnostictarray[i].Diagnostictname;

        this.Diagnostictarray.splice(i, 1);
      }
    }
  }
  deleteDiagnostictDetails(tube_type_id) {
    var deleteDiagnostictDetails;
    var deleteDiagnostictstatus;
    var deleteDiagnostictid;
    var deleteDiagnostictname;
    var deleteDiagnostictcolor;
    var deleteDiagnostictsize;
    for (var i = 0; i < this.Diagnostictarray.length; i++) {
      if (this.Diagnostictarray[i].tube_type_id == tube_type_id) {
        this.Diagnostictarray[i].status = "0";
        deleteDiagnostictstatus = this.Diagnostictarray[i].status;
        deleteDiagnostictid = this.Diagnostictarray[i].tube_type_id;
        deleteDiagnostictname = this.Diagnostictarray[i].Diagnostictname;
        deleteDiagnostictcolor = this.Diagnostictarray[i].color_code;
        deleteDiagnostictsize = this.Diagnostictarray[i].tube_size
      }
      deleteDiagnostictDetails = {
        tube_desc: deleteDiagnostictname,
        tube_type_id: deleteDiagnostictid,
        diag_centre_id: this.diaag,
        color_code: this.color_code,
        tube_size: this.tube_size,
        status: deleteDiagnostictstatus
      }
      this.toastr.info('deleted');
    }
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + "mas/sdtt", deleteDiagnostictDetails,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
        }, error => {
          this.toastr.error('error');
        })
    this.Diagnostictarray = this.Diagnostictarray.filter(sample => sample.tube_type_id !== tube_type_id);
  }
  savedataaa(name) {

    var datatobackend;
    if (this.masterType == 'Diagnostict') {

      this.masterurl = "mas/sdtt";
      if (this.tube_type_id != undefined) {
        datatobackend = {
          tube_desc: name,
          tube_type_id: this.tube_type_id,
          diag_centre_id: this.diaag,
          color_code: this.color_code,
          tube_size: this.tube_size,
          status: "1"
        }
      }
      else {
        datatobackend = {
          tube_desc: name,
          diag_centre_id: this.diaag,
          color_code: this.color_code,
          tube_size: this.tube_size,
          status: "1"
        }
      }
    }
    else {
      this.toastr.error('error');
    }
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + this.masterurl, datatobackend,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          var sendataaa = { diag_centre_id: this.diaag }
          if (this.masterType == 'Diagnostict') {
            this.Diagnostictdata(sendataaa)
          }

          else {
            this.toastr.error('error');
          }
        }, error => { this.toastr.error('error'); })
  }
  //diagnosticR

  DiagnosticRtypedata() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'mas/gdat', { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          var DiagnosticR = obj.reagent_types;
          this.DiagnosticRarraytype = DiagnosticR;
        },
        error => {
          this.toastr.error('error');
        })
  }
  DiagnosticRdata(sendatab) {
    this.DiagnosticRarray = [];
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'mas/grad', sendatab,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          var DiagnosticR = obj.diag_reagents;
          for (var i = 0; i < DiagnosticR.length; i++) {
            this.DiagnosticRarray.push({
              reagent_type_desc: DiagnosticR[i].reagent_type_desc,
              DiagnosticRname: DiagnosticR[i].diag_reagent_desc,
              diag_reagent_id: DiagnosticR[i].diag_reagent_id,
              reagent_type_id: DiagnosticR[i].reagent_type_id,
              diag_centre_id: DiagnosticR[i].diag_centre_id,
              status: DiagnosticR[i].status
            })
          }
        }, error => {
          this.toastr.error('error');
        })
  }
  addDiagnosticR() {
    if (this.DiagnosticRinputname != undefined) {
      if (this.DiagnosticRarray.length != 0) {
        for (let i = 0; i < this.DiagnosticRarray.length; i++) {
          if (this.DiagnosticRarray[i].DiagnosticRname == this.DiagnosticRinputname) {
            this.toastr.error("already exists");
            break;
          }
          else {
            this.DiagnosticRarray.push({
              DiagnosticRname: this.DiagnosticRinputname,
              reagent_type_id: this.DiagnosticRarray[i].reagent_type_id,
              status: "1",
            });
            break;
          }
        }
      }
      else {
        for (let i = 0; i < this.DiagnosticRarray.length; i++) {
          this.DiagnosticRarray.push({
            DiagnosticRname: this.DiagnosticRinputname,
            reagent_type_id: this.DiagnosticRarray[i].reagent_type_id,
            status: "1",
          });
        }
      }
      this.savedataaaa(this.DiagnosticRinputname)
      this.DiagnosticRarray = [];
      this.DiagnosticRinputname = null;
    }
  }
  editDiagnosticRDetails(DiagnosticRname, reagent_type_id) {
    this.reagent_type_id = reagent_type_id
    for (var i = 0; i < this.DiagnosticRarray.length; i++) {
      if (this.DiagnosticRarray[i].DiagnosticRname == DiagnosticRname) {
        this.DiagnosticRinputname = this.DiagnosticRarray[i].DiagnosticRname;
        this.DiagnosticRarray.splice(i, 1);
      }
    }
  }
  deleteDiagnosticRDetails(data) {
    const deleteData = {
      diag_reagent_desc: data.DiagnosticRname,
      diag_reagent_id: data.diag_reagent_id,
      reagent_type_id: data.reagent_type_id,
      diag_centre_id: this.diaag,
      status: "0",
    };
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    this.http.post(ipaddress.getIp.toString() + 'mas/sdra', deleteData, { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          var sendata = {
            diag_centre_id: this.diaag
          }
          this.DiagnosticRdata(sendata);
        },
        error => {
          this.toastr.error('error');
        });
  }
  savedataaaa(name) {
    var datatobackend;
    if (this.masterType == 'DiagnosticR') {
      this.masterurl = "mas/sdra";
      if (this.reagent_type_id != undefined) {
        datatobackend = {
          diag_reagent_desc: name,
          reagent_type_id: this.reagent_type_id,
          diag_centre_id: this.diaag,
          reagent_type_desc: this.reagent_type_desc,
          status: "1"
        }
      }
      else {
        datatobackend = {
          diag_reagent_desc: name,
          diag_centre_id: this.diaag,
        }
      }
    }
    else {
      this.toastr.error('error');
    }
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + this.masterurl, datatobackend,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          this.toastr.info('success');
          var sendatab = {
            diag_centre_id: this.diaag
          }
          if (this.masterType == 'DiagnosticR') {
            this.DiagnosticRdata(sendatab)
          }
          else {
            this.toastr.error('error');
          }
        }, error => {
          this.toastr.error('error in DiagnosticR data');
        })
  }


  sugerydataDdata(sendataa) {
    console.log("sendataa = "+JSON.stringify(sendataa))
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'gen/gdsp', sendataa,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log("surgery cost data = "+JSON.stringify(obj))
           var surg_payment= obj.doc_pays;
           this.surgpaymentarray=[];
           if(surg_payment != undefined){
            if(surg_payment.length != 0){

              for (var i = 0; i < surg_payment.length; i++) {
                var docname;
                if(surg_payment[i].middle_name != undefined){
                  docname= surg_payment[i].first_name+" "+surg_payment[i].middle_name+" "+surg_payment[i].last_name  
                }else if(surg_payment[i].last_name != undefined){
                  docname=surg_payment[i].first_name+" "+surg_payment[i].last_name
                }else{
                  docname=surg_payment[i].first_name
                }

            this.surgpaymentarray.push({
              doc_surg_pay_id: surg_payment[i].doc_surg_pay_id,
              surgery_id:surg_payment[i].surgery_id,
              doc_reg_id: surg_payment[i].doc_reg_id,
              docname:docname,
              cost:surg_payment[i].cost,
              surgery_name: surg_payment[i].surgery_name

            })
          }
            }
           }
        }, error => {
          this.toastr.error('error')
        }
      )
  }


  getReferralData(e) {
    this.ReferedDoctorArray = [];
    this.ReferedNameArray = [];
    if (this.doctorName != undefined && this.doctorName.length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.httpp.post(ipaddress.getIp.toString() + 'search/docbyhns/',
        JSON.stringify({
          doc_name: this.doctorName,
          hospital_id: this.hosp_id
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            console.log("doct o = " + JSON.stringify(obj))
            this.ReferedNameArray = obj.doctors;
            if (this.ReferedNameArray != undefined) {
              for (var i = 0; i < this.ReferedNameArray.length; i++) {
                var docname;
                if (this.ReferedNameArray[i].middle_name != undefined) {
                  docname = this.ReferedNameArray[i].first_name + " " + this.ReferedNameArray[i].middle_name + " " + this.ReferedNameArray[i].last_name;
                } else {
                  docname = this.ReferedNameArray[i].first_name + " " + this.ReferedNameArray[i].last_name;
                }
                this.ReferedDoctorArray.push(
                  {
                    docname:docname,
                    prov_id:this.ReferedNameArray[i].prov_id,
                    doc_qualif:this.ReferedNameArray[i].doc_qualif
                  });
              }
            }
            this.ReferedDoctorArray = this.ReferedDoctorArray.filter(function (this: any, el) {
              return el.toLowerCase().indexOf(this.doctor_txt.toLowerCase()) > -1;
            }.bind(this));
          },
          error => {
            this.toastr.error(Message_data.network);
          }
        )
    }
    else {
      this.ReferedDoctorArray = [];
    }
  }

  Select_refer_doctor(item) {
    console.log("doc_details = "+JSON.stringify(item))
    this.doctorName = item.docname;
    this.doc_reg_id=item.prov_id;
    if (item.doc_qualif != undefined && item.doc_qualif != '') {
      this.qualification = item.doc_qualif
    } else {
      this.qualification = ''
    }
    this.ReferedDoctorArray = [];
  }

  surgeryChange(data) {
    if (data.length >= 3) {
      var send_data = {
        // spl_id: this.spl_id,
        surgery_desc: data
      };
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.httpp
        .post(ipaddress.getIp + 'mas/gsbn', JSON.stringify(send_data), {
          headers: headers,
        })
        .subscribe(
          (response) => {
            var obj = response.json();
            console.log("surg--" + JSON.stringify(obj))

            if (obj != null) {
              this.surgery_list = [];
              for (var i = 0; i < obj.surgeries.length; i++) {
                this.surgery_list.push({
                  surgery_id: obj.surgeries[i].surgery_id,
                  surgery_name: obj.surgeries[i].surgery_desc,
                });
              }
            }
          },
          (error) => {
            this.toastr.error(Message_data.getNetworkMessage());
          }
        );
    }
  }

  clearFields() {
    if (this.surgery_name == "") {
      this.surgery_id = "0";
      this.surgery_name = "";
    }
  }
 
  surgerySelect(data) {
    this.surgery_id = data.surgery_id;
    this.surgery_name = data.surgery_name;
  }

  savve_surgery_cost(data,value){
    console.log("fsys = "+JSON.stringify(data))
    
    if(value == "1" && this.doc_surg_pay_id == undefined){
      var send_data={
        hptl_clinic_id:this.hosp_id,
        doc_reg_id:this.doc_reg_id,
        surgery_id:this.surgery_id,
        cost:this.cost,
        status:value, 
      }
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'gen/sdsp', send_data,
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(JSON.stringify(response));
            console.log("save_cost data"+JSON.stringify(obj)+" save_surgery_cost"+JSON.stringify(send_data))
            if(obj.key == "1"){
              this.doc_reg_id="";
              this.doctorName='';
              this.surgery_name='';
              this.clearFields();
              this.cost='';
              this.toastr.success('data saved successfully');
              var send_data={
                hptl_clinic_id:this.hosp_id
              }
              this.sugerydataDdata(send_data)
            }else{
              this.toastr.error('Unable to save data');
            }
          }, error => {
            this.toastr.error('Unable to save data');
          })
    }else if(value == "1" && this.doc_surg_pay_id != undefined){
      var edit_send_data={
        doc_surg_pay_id:this.doc_surg_pay_id,
        hptl_clinic_id:this.hosp_id,
        doc_reg_id:this.doc_reg_id,
        surgery_id:this.surgery_id,
        cost:this.cost,
        status:value, 
      }
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'gen/sdsp', edit_send_data,
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(JSON.stringify(response));
            console.log("edit_cost data"+JSON.stringify(obj)+" save_surgery_cost"+JSON.stringify(send_data))
            if(obj.key == "1"){
              this.doc_reg_id="";
              this.doctorName='';
              this.surgery_name='';
              this.clearFields();
              this.cost='';
              this.doc_surg_pay_id='';
              this.toastr.success('data saved successfully');
              var send_data={
                hptl_clinic_id:this.hosp_id
              }
              this.sugerydataDdata(send_data)
            }else{
              this.toastr.error('Unable to save data');
            }
          }, error => {
            this.toastr.error('Unable to save data');
          })
    }
    else if(value == '0'){

      var delete_send_data={
        doc_surg_pay_id:this.doc_surg_pay_id,
        hptl_clinic_id:this.hosp_id,
        doc_reg_id:data.doc_reg_id,
        surgery_id:data.surgery_id,
        cost:data.cost,
        status:value, 
      }
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'gen/sdsp', delete_send_data,
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(JSON.stringify(response));
            this.toastr.success('data deleted successfully');
            if(obj.key == "1"){
              this.surgpaymentarray.forEach(payment=>{
                if(payment.doc_surg_pay_id == data.doc_surg_pay_id)
                  this.surgpaymentarray.splice(payment, 1);
              })
              console.log("save_cost data"+JSON.stringify(obj)+" save_surgery_cost"+JSON.stringify(send_data))
            }else{
              this.toastr.error('unable to delete data');
            }
          }, error => {
            this.toastr.error('unable to delete data');
          })
    }
  }
  
  editsavve_surgery_cost(data){
    console.log("value = ",JSON.stringify(data))
      this.doctorName=data.docname;
      this.doc_reg_id=data.doc_reg_id;
      this.surgery_id=data.surgery_id;
      this.cost=data.cost
      this.surgery_name=data.surgery_name
      this.doc_surg_pay_id=data.doc_surg_pay_id
      this.surgpaymentarray.forEach(payment=>{
        if(payment.doc_surg_pay_id == data.doc_surg_pay_id)
          this.surgpaymentarray.splice(payment, 1);
      })
    }

  }



function changeProviderType(data: any) {
  throw new Error('Function not implemented.');
}
