import { Component, OnInit } from '@angular/core';
import { CommonDataService } from '../../providers/common-data.service';
import { Http, Headers } from '@angular/http';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { ToastrService } from 'ngx-toastr';
import { Helper_Class } from '../../helper_class';
import { Router, ActivatedRoute } from '@angular/router';
import { ClientViewService } from '../clientview/client-view.service';
import { Client_Helper } from '../Client_helper';
import { ipaddress } from '../../ipaddress';
import { Message_data } from 'src/assets/js/Message_data';

@Component({
  selector: 'app-appcreate',
  templateUrl: './appcreate.component.html',
  styleUrls: ['./appcreate.component.css']
})
export class AppcreateComponent implements OnInit {
  public relativeDetailsFlag: boolean = false;
  public clnt_middle_name;
  public header_footer_flag: Boolean;
  // urls
  public relationship;
  public relationName;
  public treatementTypeUrl: string;
  public clntDetailedUrl: string;
  public otherRelDetUrl: string;

  public locationUrl: string;
  public cityUrl: string;
  public stateUrl: string;
  public countryUrl: string;
  public locationNameByUrl: string;

  public sendClientRegId; //
  public sendSelfRelationData; //
  public sendOtherRelationData; //

  public relationList = [];
  public treatmentList = [];

  public clntSubrelID: string;
  public clntFirstName: string;
  public clntMiddleName: string;
  public clntLastName: string;
  public clntAge: string;
  public clntGender: string;
  public clntBloodGrp: string;
  public clntBloodID: string;
  public clntFatherOrGuardName: string;
  public clntIncome: string;
  public clntOccupation: string;
  public clntAddress1: string;
  public clntAddress2: string;
  public clntLocation: string;
  public clntCity: string;
  public clntState: string;
  public clntCountry: string;
  public clntLocationId: string;
  public clntCityId: string;
  public clntStateId: string;
  public clntCountryId: string;
  public clntZipcode: any;
  public clntContactNo: any;
  public clntSymptom: string;
  public clntDOB: string;
  public treatementType;
  public treatementTypeList;
  public relationshipList = [];
  public otherRelationList = [];
  public clntLocationList = [];
  public clntCityList = [];
  public clntStateList = [];
  public clntCountryList = [];
  public searchclntStateList = [];

  public searchClntLocation: string;
  public searchClntCity: string;
  public searchClntCityDesc: string;
  public searchClntState: string;
  public searchClntStateDesc: string;
  public searchClntCountry: string;

  public appType: string;
  public doctorSearchList;
  public appointClientData;

  public doctorSearchFlag: Boolean;
  public opinionTypeFlag: Boolean;
  public appointTypeFlag: Boolean;
  public searchOpinionFlag: Boolean;
  public specializationUrl: string;
  public specializationList = [];
  public doctorSearchData;
  public treatmentlistURL: string;
  public treatementList;
  public secOpnListSpl = [];
  nextBtn: boolean = false;
  public n: number = 1;
  public homecare: string;
  public splName;
  public appFlow;

  constructor(public clientservice: ClientViewService, public gservice: CommonDataService, public http: Http, public routes: ActivatedRoute, public router: Router, public toastr: ToastrService) {
    this.treatementTypeUrl = ipaddress.getIp + "usercontroller/getmedicare/";
    this.clntDetailedUrl = ipaddress.getIp + "usercontroller/clientdet/";
    this.otherRelDetUrl = ipaddress.getIp + "appointment/clnrelweb/";
    this.locationNameByUrl = ipaddress.getIp + "usercontroller/locbyname";
    this.cityUrl = ipaddress.getIp + "usercontroller/city";
    this.stateUrl = ipaddress.getIp + "usercontroller/state";
    this.countryUrl = ipaddress.getIp + "usercontroller/count";
    this.locationUrl = ipaddress.getIp + "usercontroller/loc";
    this.appType = "New";
  }

  ngOnInit() {
    this.specializationUrl = ipaddress.getIp + "usercontroller/getspecialization";
    if(Helper_Class.getTreatmentList() != undefined){
      if (Helper_Class.getTreatmentList().app_flow == "without_Normal") {
        this.doctorSearchFlag = false;
        this.appFlow = Helper_Class.getTreatmentList().app_flow;
        this.clntCountryId = Helper_Class.getTreatmentList().doc_search.Search_Country;
        this.clntStateId = Helper_Class.getTreatmentList().doc_search.Search_State_id;
        this.clntCityId = Helper_Class.getTreatmentList().doc_search.Search_City_id;
        this.clntLocationId = Helper_Class.getTreatmentList().doc_search.Search_Location_id;
        this.searchClntLocation = Helper_Class.getTreatmentList().doc_search.Search_Location;
        this.getCountries("0", this.clntCountryId);
      }else{
        this.appFlow = Client_Helper.getSearchByHosptial();
      }
    }else{
      if (this.gservice.Client_login_detail_data.Country_ID != null) {
          this.doctorSearchFlag = true;
          this.clntCountryId = this.gservice.Client_login_detail_data.Country_ID;
          this.clntStateId = this.gservice.Client_login_detail_data.State_ID;
          this.clntCityId = this.gservice.Client_login_detail_data.City_ID;
          this.clntLocationId = this.gservice.Client_login_detail_data.Location_ID;
          this.searchClntLocation = this.gservice.Client_login_detail_data.Client_Location;
          this.getCountries("0", this.clntCountryId);
          this.appFlow = Client_Helper.getSearchByHosptial();
      }
    }

    if (this.appFlow == "second_opinion" || this.appFlow == "without_second_opinion") 
      this.nextBtn = true;

    this.doctorSearchData = Client_Helper.getDocSearch();
    this.getSpecializationMasterData();
    this.treatmentlistURL = ipaddress.getIp + "usercontroller/getmedicare/";
    this.getTreatTypeMasterData();

    if (this.gservice.Client_login_detail_data.Client_Id != undefined) {
      this.sendClientRegId = {
        client_reg_id: this.gservice.Client_login_detail_data.Client_Id,
      }
    } else if (this.gservice.Client_login_detail_data.Client_Id != undefined) {
      this.sendClientRegId = {
        client_reg_id: this.gservice.Client_login_detail_data.Client_Id,
      }
      this.sendOtherRelationData = {
        client_id: this.gservice.Client_login_detail_data.Client_Id,
      }
    }

    // get master data
    this.getRelationList();
    Client_Helper.setSearchByHosptial(null);
    var flow_hospital = "search_hospital"
    Client_Helper.setSearchByHosptial(flow_hospital);

    if (this.appFlow == "search_hospital" ||
      this.appFlow == "search_by_home" ||
      this.appFlow == "search_by_treatement" ||
      this.appFlow == "search_by_speciality" ||
      this.appFlow == "without_Normal" ||
      this.appFlow == "without_second_opinion" ||
      this.appFlow == "nurse_physio_app") {
      if (this.appFlow == "without_second_opinion") {
        this.opinionTypeFlag = true;
        this.appointTypeFlag = true;
      } else if (this.appFlow == "physio") {
        this.appointTypeFlag = true;
      }
    } else {
      Client_Helper.getsecondopinion();
      // set default location
      this.searchClntLocation = this.gservice.Client_login_detail_data.Client_Location;
      if ( Client_Helper.getsecondopinion() == "second_opinion") 
        this.searchOpinionFlag = true;
    }

    Client_Helper.setDocSearch(null);
    Client_Helper.setSO_client_details(null);
    Client_Helper.setsplnextdoclistpage(null);
  }

  getRelationList() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "usercontroller/grels/",this.sendClientRegId, { headers: headers }).subscribe(
      data => {
        var obj = JSON.stringify(data)
       
        if (data.json().relationships != null && data.json().relationships != undefined) {
          this.relationList = data.json().relationships;
          Helper_Class.setrelation(this.relationList)
          // Default self 
          this.relationship = data.json().relationships[0].relationship_name;
          this.getSltRelationType(this.relationship);
        }
      },error => {
        this.toastr.error(Message_data.unableToFetchData);
      });
  }

  selectSpeciality(spl_id, spl_name, e) {
    this.nextBtn = true;
    if (this.nextBtn == true) {
      if (this.secOpnListSpl.length != 0) {
        var chkFlag = true;
        for (var i = 0; i < this.secOpnListSpl.length; i++) {
          if (this.secOpnListSpl[i].specialization_id == spl_id) {
            chkFlag = false;
            this.secOpnListSpl.splice(this.secOpnListSpl.indexOf(i), 1);
            for (var j = 0; j < this.specializationList.length; j++) {
              if (this.specializationList[j].splty_id == spl_id) {
                this.specializationList[j].Css = false;
                break;
              }
            }
            break;
          }
        }

        if (chkFlag) {
          for (var j = 0; j < this.specializationList.length; j++) {
            if (this.specializationList[j].splty_id == spl_id) {
              this.specializationList[j].Css = true;
              break;
            }
          }
          this.secOpnListSpl.push({
            specialization_id: spl_id,
            specialization_name: spl_name
          });
        }
        Client_Helper.setSecOpnListSpl(this.secOpnListSpl);
      } else {
        for (var j = 0; j < this.specializationList.length; j++) {
          if (this.specializationList[j].splty_id == spl_id) {
            this.specializationList[j].Css = true;
            break;
          }
        }
        this.secOpnListSpl.push({
          specialization_id: spl_id,
          specialization_name: spl_name
        });
      }
      Client_Helper.setSecOpnListSpl(this.secOpnListSpl);
    } else {
      CommonDataService.OpnSplId = spl_id;
      if (this.homecare != "0") {
        if (this.doctorSearchData != undefined) {
          this.doctorSearchData.splity = { specialization_id: spl_id, specialization_name: spl_name };
          this.clientservice.sendMessage('doctorlist');
        }
      } 
    }
  }

  createAppt() {
    var rel_id
    for(var i=0; i<this.relationList.length; i++){
      if(this.relationship == this.relationList[i].relationship_name){
        rel_id = this.relationList[i].relation_id;
      }
    }
    var login_search = "login_search"
    localStorage.setItem("login_search", login_search)
    if (this.clntFirstName == "" || this.clntFirstName == undefined) {
      this.toastr.error(Message_data.sltFamilyMember);
    } else {
      var valid_Flag = false; // for validation checking
      if (this.appFlow != "without_Normal") {
        if(this.splName == '' || this.splName == undefined){
          this.toastr.error(Message_data.sltSpeciality);
          valid_Flag = true;
        }
      }

      if (valid_Flag == false){ // validation completed
        if (this.appFlow != "without_Normal") {
          for (var x = 0; x < this.treatmentList.length; x++) {
            if (this.treatmentList[x].medicare_name == this.treatementType) {
              this.treatementTypeList = {
                medicare_id: this.treatmentList[x].medicare_id,
                medicare_name: this.treatmentList[x].medicare_name
              }
              break;
            }
          }
        }
    
        for (var y = 0; y < this.relationList.length; y++) {
          if (this.relationList[y].relationship_name == this.relationship) {
            this.relationshipList.push({
              relation_id: this.relationList[y].relation_id,
              relationship_name: this.relationList[y].relationship_name
            })
            break;
          }
        }

        this.appointClientData = {
          relation: this.relationshipList,
          relation_id: rel_id,
          clnt_fname: this.clntFirstName,
          clnt_mname: this.clntMiddleName,
          clnt_lname: this.clntLastName,
          clnt_age: this.clntAge,
          clnt_dob: this.clntDOB,
          clnt_gender: this.clntGender,
          clnt_blood: this.clntBloodGrp,
          clnt_blood_id: this.clntBloodID,
          clnt_fg_name: this.clntFatherOrGuardName,
          clnt_income: this.clntIncome,
          clnt_occupation: this.clntOccupation,
          clnt_address1: this.clntAddress1,
          clnt_address2: this.clntAddress2,
          clnt_location_desc: this.clntLocation,
          clnt_city_desc: this.clntCity,
          clnt_state_desc: this.clntState,
          clnt_country_desc: this.clntCountry,
          clnt_location: this.clntLocationId,
          clnt_city: this.clntCityId,
          clnt_state: this.clntStateId,
          clnt_country: this.clntCountryId,
          clnt_zipcode: this.clntZipcode,
          clnt_cont_number: this.clntContactNo,
          clnt_symptom: this.clntSymptom,
          app_type: this.appType,
          sub_rel_id: this.clntSubrelID,
         //relation_id:relation_id
          treatement_type: this.treatementTypeList,
          Search_Country: this.searchClntCountry,
          Search_State: this.searchClntStateDesc,
          Search_State_id: this.searchClntState,
          Search_City: this.searchClntCityDesc,
          Search_City_id: this.searchClntCity,
          Search_Location: this.searchClntLocation,
        }

        this.gservice.set_appoint_client_data(this.appointClientData); // data for helper class
        Client_Helper.setSO_client_details(this.appointClientData);

        if (Helper_Class.getInfo().client_id != undefined || this.appFlow == "without_Normal") {
          this.clientservice.sendMessage("doctor_appointment_conform");
        }else{
          for (var m = 0; m < this.clntCityList.length; m++) {
            if (this.clntCityList[m].city_id == this.searchClntCity) {
              this.searchClntCityDesc = this.clntCityList[m].city_desc;
              break;
            }
          }

          for (var n = 0; n < this.searchclntStateList.length; n++) {
            if (this.searchclntStateList[n].state_id == this.searchClntState) {
              this.searchClntStateDesc = this.searchclntStateList[n].state_desc;
              break;
            }
          }

          this.doctorSearchList = {
            Search_Country: this.searchClntCountry,
            Search_State: this.searchClntStateDesc,
            Search_State_id: this.searchClntState,
            Search_City: this.searchClntCityDesc,
            Search_City_id: this.searchClntCity,
            Search_Location: this.searchClntLocation,
            Treatment_Type: this.treatementTypeList
          }
          
          Client_Helper.setDocSearch(this.doctorSearchList);
          if (Client_Helper.getSecOpnListSpl() != null) {
            var secopn = [];
            secopn=Client_Helper.getSecOpnListSpl();
          }
          if (this.treatementType == "Allopathy") {
            var doctorlist = {
              app_flow: "second_opinion",
              doc_search: this.doctorSearchData,
              secOpn: secopn,
              homecare: this.homecare,
              login_search: this.doctorSearchList,
            }
           
            Client_Helper.setsplnextdoclistpage(doctorlist);
            this.clientservice.sendMessage("doctorlist");
          }
        }
      }
    }
  }
  
  myCallbackFunction = function (_params) {// callback...
    return new Promise((resolve, reject) => {
      resolve(_params);
    });
  }

  getCountries(flag, country) {
    this.clntCountryList = [];

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(this.countryUrl, { headers: headers }).subscribe(
      data => {
        this.clntCountryList = [];
        if (data.json().countries != null) {
          this.clntCountryList = data.json().countries;
          for (var c = 0; c < this.clntCountryList.length; c++) {
            if (this.clntCountryList[c].country_id == country) {
              this.searchClntCountry = this.clntCountryList[c].description;
              this.clntCountry = this.clntCountryList[c].description;
              this.clntCountryId = this.clntCountryList[c].country_id;
              this.getStates(this.clntCountryId, flag);
              break;
            }
          }
        }
      },error => {});
  }

  countryChange(clnt_country, flag) {
    for (var c = 0; c < this.clntCountryList.length; c++) {
      if (this.clntCountryList[c].description == clnt_country) {
        if (flag == "0") {
          this.clntCountry = this.clntCountryList[c].description;
          this.clntCountryId = this.clntCountryList[c].country_id;
          this.clntLocation = "";
          this.clntStateList = [];
          this.clntCityList = [];
          this.clntLocationId = undefined;
        } else {
          this.searchClntCountry = this.clntCountryList[c].description;
          this.clntCountryId = this.clntCountryList[c].country_id;
          this.searchClntLocation = "";
          this.searchclntStateList = [];
          this.clntLocationId = undefined;
        }
        this.getStates(this.clntCountryId, flag);
      }
    }
  }

  getStates(country, flag) {
    if(flag == 0){
      this.clntStateId = this.gservice.Client_login_detail_data.State_ID;
    }
    this.clntStateList = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.stateUrl, JSON.stringify({ country_id: country }),
      { headers: headers }).subscribe(
        data => {
          if (data.json().states != null) {
            this.clntStateList = data.json().states;
            for (var i = 0; i < this.clntStateList.length; i++) {
              if (this.clntStateList[i].state_id == this.clntStateId) {
                this.clntStateId = this.clntStateList[i].state_id;
                this.clntState = this.clntStateList[i].description;
                this.searchClntState = this.clntStateList[i].description;
                this.getCities(this.clntStateId, flag);
                break;
              }
            }
          }
        });
  }

  stateChange(clnt_state, flag) {
    for (var i = 0; i < this.clntStateList.length; i++) {
      if (this.clntStateList[i].description == clnt_state) {
        if (flag == "0") {
          this.clntStateId = this.clntStateList[i].state_id;
          this.clntState = this.clntStateList[i].description;
          this.clntLocation = "";
        } else {
          this.clntStateId = this.clntStateList[i].state_id;
          this.searchClntState = this.clntStateList[i].description;
          this.searchClntLocation = "";
        }
        this.clntCityList = [];
        this.clntCityId = undefined;
        this.clntLocationId = undefined;
        this.getCities(this.clntStateId, flag);
      }
    }
  }

  getCities(state, flag) {
    if(flag == 0){
      this.clntCityId = this.gservice.Client_login_detail_data.City_ID;
    }
    this.clntCityList = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.cityUrl, JSON.stringify({ state_id: state }),
      { headers: headers }).subscribe(
        data => {
          if (data.json().cities != null) {
            this.clntCityList = data.json().cities;
            for (var i = 0; i < this.clntCityList.length; i++) {
              if (this.clntCityList[i].district_id == this.clntCityId) {
                this.clntCityId = this.clntCityList[i].district_id;
                this.clntCity = this.clntCityList[i].description;
                this.searchClntCity = this.clntCityList[i].description;
                this.getLocations(this.clntCityId);
                break;
              } else {
                if (flag == "0") {
                  this.clntCity = this.clntCityList[0].description;
                } else {
                  this.searchClntCity = this.clntCityList[0].description;
                }
              }
            }
          }
        });
  }

  cityChange(clnt_city, flag) {
    this.clntLocationList = [];
    for (var i = 0; i < this.clntCityList.length; i++) {
      if (this.clntCityList[i].description == clnt_city) {
        if (flag == "0") {
          this.clntCityId = this.clntCityList[i].district_id;
          this.clntCity = this.clntCityList[i].description;
          this.clntLocationId = undefined;
          this.clntLocation = "";
        } else {
          this.clntCityId = this.clntCityList[i].district_id;
          this.searchClntCity = this.clntCityList[i].description;
          this.clntLocationId = undefined;
          this.searchClntLocation = "";
        }
      }
    }
  }

  getLocations(city) {
    this.clntLocationList = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.locationUrl, JSON.stringify({ city_id: city }),
      { headers: headers }).subscribe(
        data => {
          if (data.json().locations != null && data.json().locations.length != 0) {
            this.clntLocationList = data.json().locations;
            for (var i = 0; i < this.clntLocationList.length; i++) {
              if (this.clntLocationList[i].location_id == this.clntLocationId) {
                this.clntLocation = this.clntLocationList[i].description;
                this.searchClntLocation = this.clntLocationList[i].description;
                break;
              }
            }
          }
        });
  }

  locationChange(e) {
    var locat_desc = e.target.value.toString();
    this.clntLocationList = [];

    if (locat_desc != null && locat_desc.length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.locationNameByUrl, JSON.stringify({
        name: locat_desc,
        city_id: this.clntCityId,
      }), { headers: headers }).subscribe(
        data => {
          if (data.json().locations != null && data.json().locations.length != 0) 
            this.clntLocationList = data.json().locations;
        },error => {});
    } else {
      this.clntLocationList = [];
    }
  }

  getSpecializationMasterData() {
    Client_Helper.setspecalizationtodoctorlistpage(null);
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(this.specializationUrl).subscribe(
      data => {
        for (var i = 0; i < data.json().specialization.length; i++) {
          if (data.json().specialization[i].spl_name != "-") {
            if (this.homecare != "0") {
              if (data.json().specialization[i].spl_name != "Dental") {
                this.specializationList.push({
                  Css: false,
                  splty_name: data.json().specialization[i].spl_name,
                  splty_id: data.json().specialization[i].spl_id,
                  splty_img: "assets/imgs/Speciality/" + data.json().specialization[i].spl_name + ".png"
                });
              }
            } else {
              this.specializationList.push({
                Css: false,
                splty_name: data.json().specialization[i].spl_name,
                splty_id: data.json().specialization[i].spl_id,
                splty_img: "assets/imgs/Speciality/" + data.json().specialization[i].spl_name + ".png"
              });
            }
            Client_Helper.setspecalizationtodoctorlistpage(this.specializationList);
          }
        }
      },error => {});
    setTimeout(() => {
      this.n = this.n + 10;
    }, 1000);
  }

  getTreatTypeMasterData() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(this.treatementTypeUrl).subscribe(
      data => {
        if (data.json().medicare != null && data.json().medicare != undefined) {
          this.treatmentList = data.json().medicare;
          // Default allopathy
          this.treatementType = this.treatmentList[0].medicare_name;
        }
      },error => {});
  }

  getSltRelationType(rel_data) {// relationship Change
    this.otherRelationList = [];
    this.clntFirstName = null;
    this.clntMiddleName = null;
    this.clntLastName = null;
    this.clntAge = null;
    this.clntGender = null;
    this.clntDOB = null;

    if (rel_data == "Self") {
      this.relativeDetailsFlag = false;
      rel_id="1"
      if (this.gservice.Client_login_detail_data.Client_Id != undefined) {
        this.sendSelfRelationData = {
          client_id: this.gservice.Client_login_detail_data.Client_Id,
          relationship: "Self",
          country: "IN"
        }
      } else {
        this.sendSelfRelationData = {
          client_id: this.gservice.Client_login_detail_data.Client_Id,
          relationship: rel_data,
          country: "IN"
        }
      }
      this.sendOtherRelationData = {
        client_reg_id: this.gservice.Client_login_detail_data.Client_Id,
        relationship: rel_data,
        relation_id: rel_id,
        country: "IN",
      }
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.clntDetailedUrl,(this.sendSelfRelationData), { headers: headers }).subscribe(
        data => {
          var obj = JSON.stringify(data);
          Helper_Class.setclientbasicinfo(obj)
          if (data.json().length != 0) {
            if (data.json().middle_name != null && data.json().middle_name != undefined) 
              this.clnt_middle_name= data.json().middle_name
          
            this.clntFirstName = encrypt_decript.Decript(data.json().first_name);
            this.clntLastName = encrypt_decript.Decript(data.json().last_name);
            if (data.json().middle_name != null) 
              this.clntMiddleName = encrypt_decript.Decript(data.json().middle_name);
  
            this.clntAge = data.json().age != 0 ? data.json().age : "<1";
              
            if (data.json().Occupation != null)
              this.clntOccupation = encrypt_decript.Decript(data.json().Occupation);

            if(data.json().location_desc != null)
              this.clntLocation = data.json().location_desc;

            if(data.json().city_desc != null)
              this.clntCity = data.json().city_desc;

            if(data.json().state_desc != null)
              this.clntState = data.json().state_desc;

            if(data.json().country_desc != null)
              this.clntCountry = data.json().country_desc;

            if(data.json().address1 != null)
              this.clntAddress1 = encrypt_decript.Decript(data.json().address1);

            if(data.json().address2 != null)
              this.clntAddress2 = encrypt_decript.Decript(data.json().address2);

            if(data.json().zipcode != null)
              this.clntZipcode = encrypt_decript.Decript(data.json().zipcode);

            if(data.json().symptom != null)
              this.clntSymptom = data.json().symptom;
            
            if (data.json().gender != null && data.json().gender != "0: undefined") 
              this.clntGender = encrypt_decript.Decript(data.json().gender);
  
            if (data.json().dob)
              this.clntDOB = data.json().dob;

            if (data.json().blood_desc)
                this.clntBloodGrp = data.json().blood_desc;

            if(data.json().mobile != null)
              this.clntContactNo = encrypt_decript.Decript(data.json().mobile);
          } else {
            this.toastr.error(Message_data.unableToFetchData);
          }
        }, error => {
          this.toastr.error(Message_data.unableToFetchData);
        });
    } else {
      var rel_id = ""; // to get selected relation id
      this.relationList=Helper_Class.getrelation();

      for (var i = 0; i < this.relationList.length; i++) {
        if (this.relationList[i].relationship_name ==  rel_data) {
          rel_id = this.relationList[i].relation_id;
          break;
        }
      }

      this.sendOtherRelationData = {
        client_reg_id: this.gservice.Client_login_detail_data.Client_Id,
        relationship: rel_data,
        relation_id: rel_id,
        country: "IN",
      }
      
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.otherRelDetUrl, JSON.stringify(this.sendOtherRelationData), { headers: headers }).subscribe(
        data => {
          var obj=JSON.stringify(data)
          if (data.json().rel_details != null && data.json().rel_details.length != 0) {
            if (rel_id == "2" || rel_id == "3" || rel_id == "6"){ // Selected relation is Father/mother/spouse
              this.clntSubrelID = data.json().rel_details[0].cln_rel_id;
              this.clntFirstName = data.json().rel_details[0].first_name;

              if (data.json().rel_details[0].middlename != null && data.json().rel_details[0].middlename != undefined)
                this.clntMiddleName = data.json().rel_details[0].middlename;

              this.clntLastName = data.json().rel_details[0].last_name;
              this.clntAge = data.json().rel_details[0].age != 0 ? data.json().rel_details[0].age : "<1";

              if (data.json().rel_details[0].gender != null && data.json().rel_details[0].gender != "0: undefined")
                this.clntGender = data.json().rel_details[0].gender;

              if (data.json().rel_details[0].dob) 
                this.clntDOB = data.json().rel_details[0].dob;
            } else {
              this.otherRelationList = [];

              for (var i = 0; i < data.json().rel_details.length; i++) {
                if (data.json().rel_details[i].address2 != null)
                  var orel_address_2 = encrypt_decript.Decript(data.json().rel_details[i].address2);

                if (data.json().rel_details[i].dob != null) 
                  var orel_dob = data.json().rel_details[i].dob;

                var midname = "";var name;
                if (data.json().rel_details[i].middle_name != undefined) {
                  midname = data.json().rel_details[i].middle_name;
                  name = (data.json().rel_details[i].first_name).toString() + " " + (data.json().rel_details[i].middle_name) + " " + data.json().rel_details[i].last_name;
                }else{
                  name = (data.json().rel_details[i].first_name).toString() + " " + data.json().rel_details[i].last_name;
                }

                this.otherRelationList.push({
                  cln_rel_id: data.json().rel_details[i].cln_rel_id,
                  sub_rel_id:data.json().rel_details[i].cln_rel_id,
                  relation_id: rel_id,
                  name:name,
                  first_name: data.json().rel_details[i].first_name,
                  middlename: midname,
                  last_name: data.json().rel_details[i].last_name,
                  age: data.json().rel_details[i].age,
                  gender: data.json().rel_details[i].gender,
                  blood_group: data.json().rel_details[i].blood_desc,
                  blood_group_id: data.json().rel_details[i].blood_group,
                  father_guardian_name: data.json().rel_details[i].family_guardian,
                  income: data.json().rel_details[i].family_income,
                  occupation: data.json().rel_details[i].occupation,
                  address1: encrypt_decript.Decript(data.json().rel_details[i].address1),
                  address2: orel_address_2,
                  location: data.json().rel_details[i].location_desc,
                  city: data.json().rel_details[i].city_desc,
                  state: data.json().rel_details[i].state_desc,
                  country: data.json().rel_details[i].country_desc,
                  location_id: data.json().rel_details[i].location,
                  city_id: data.json().rel_details[i].city,
                  state_id: data.json().rel_details[i].state,
                  country_id: data.json().rel_details[i].country,
                  zipcode: encrypt_decript.Decript(data.json().rel_details[i].zipcode),
                  contact_num: data.json().rel_details[i].mobile,
                  dob: orel_dob,
                  mobile: data.json().mobile,
                });
              }
              this.relativeDetailsFlag = true;  
            }
          }
        },error => {
          this.toastr.error(Message_data.unableToFetchData);
        });
    }
  }

  getSltRelationDetails(name) {
    for (var i = 0; i < this.otherRelationList.length; i++) {
      if (name == this.otherRelationList[i].name) {
        this.clntSubrelID = this.otherRelationList[i].sub_rel_id;
        this.clntFirstName = this.otherRelationList[i].first_name;

        if (this.otherRelationList[i].middle_name != null && this.otherRelationList[i].middle_name != "")
          this.clntMiddleName = this.otherRelationList[i].middle_name;

        this.clntLastName = this.otherRelationList[i].last_name;
        this.clntAge = this.otherRelationList[i].age != 0 ? this.otherRelationList[i].age : "<1";
        this.clntGender = this.otherRelationList[i].gender;
        this.clntDOB = this.otherRelationList[i].dob;
        this.clntOccupation = this.otherRelationList[i].occupation;
        this.clntAddress1 = this.otherRelationList[i].address1;
        this.clntAddress2 = this.otherRelationList[i].address2;
      }
    }
  }

  selectedLocation(loc_name) {
    this.searchClntLocation = loc_name;
    this.clntLocationList = [];
  }

}
  
  
