import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { Http, Headers } from "@angular/http";
import { Helper_Class } from "../../helper_class";
import { ToastrService } from "ngx-toastr";
import { CommonDataService } from "../../providers/common-data.service";
import { customjs } from "../../../assets/js/custom";
import { Observable, Observer, Subscription } from "rxjs";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ChangePasswordComponent } from "../../common-module/change-password/change-password.component";
import { ipaddress } from "../../ipaddress";
import { Message_data } from "../../../assets/js/Message_data";
import { Diet_Helper } from "../../../app/Diet_module/Diet_Helper";
import { MenuViewService } from "./menu-view.service";
import { MasterHelperService } from "../../../app/Doctor_module/MasterHelper.service";
import { MasterCSHelperService } from "../../../app/Doctor_module/casesheet/masterCSData.service";
import { Doc_Helper } from "../../../app/Doctor_module/Doc_Helper";
import { Master_Helper } from "../../../app/Doctor_module/Master_Helper";
import { TermsConditionComponent } from "../../../app/Doctor_module/terms-condition/terms-condition.component";
import { FrontDesk_Helper } from "../../../app/FrontDesk_module/FrontDesk_Helper";
import { Admin_Helper } from "../../../app/Admin_module/Admin_Helper";
import { Diagnosis_Helper } from "../../../app/Diagnosis_module/Diagnosis_Helper";
import { Physio_Helper } from "../../../app/Physio_module/Physio_Helper";
import { VoicerecognitionService } from "../../../app/voicerecognition.service";
import { pharmacy_helper } from "../../pharma/Pharmcy_Helper";
declare var $: any;
declare var webkitSpeechRecognition: any;

@Component({
  selector: "app-menu-view",
  templateUrl: "./menu-view.component.html",
  styleUrls: ["./menu-view.component.scss"],
})
export class MenuViewComponent implements OnInit {
  recognition = new webkitSpeechRecognition();
  @ViewChild("asied_section_width", { static: false }) asied_width: ElementRef;
  @ViewChild("right_section_width", { static: false }) right_width: ElementRef;
  @ViewChild("logo", { static: false }) logo: ElementRef;
  @ViewChild("scrol_traget") public myScrollContainer: ElementRef;
  subscription: Subscription;
  public hospitalLogo;
  public hospitalName;
  public userInfo;
  public profileImage;
  public firstName: string;
  public lastName: string;
  public settings: boolean = false;
  //Doctor menu
  public docInpatSubmenuFlag: boolean = false;
  public minimized_asied: boolean = false;
  public selectedMenu;
  public docAppointmentListFlag: boolean = true;
  public docSecondopinionFlag: boolean = true;
  public dashboardBasicFlag: boolean = true;
  public docHomecareFlag: boolean = true;
  public docCaseSheetFlag: boolean = true;
  public docWalkinFlag: boolean = true;
  public docReportsFlag: boolean = false;
  public notificationFlag: boolean = false;
  public displayPage;
  public notificationCount;
  public docScheduleSubmenuFlag: boolean = false;
  //front-desk
  public permissionSubmenuFlag: boolean = false;
  public reportsSubmenuFlag: boolean = false;
  public minimizedAsied: boolean = false;
  public inpatientFlag: boolean = false;
  public manageAppointmentFlag: boolean = true;
  public viewPrescritionFlag: boolean = true;
  public patientListFlag: boolean = true;
  public wardMasterFlag: boolean = true;
  public inpatientMenuFlag: boolean = true;
  public consultantFlag: boolean = true;
  public billingFlag: boolean = false;
  public permissionsFlag: boolean = true;
  public reportsFlag: boolean = true;
  public hospBillsReportFlag: boolean = true;
  public diagBillsReportFlag: boolean = true;
  public inpatReportFlag: boolean = true;
  public reference_video: boolean = true;
  public WardTransferFlag: boolean = true;
  //Admin
  public schdForDrugInspFlag: boolean = true;
  public inwardReportFlag: boolean = true;
  public salesForDrugInspFlag: boolean = true;
  //diagnosis
  public pathSubMenuFlag: boolean = false;
  public radioSubMenuFlag: boolean = false;
  public microSubMenuFlag: boolean = false;
  public outsubFlag: boolean = false;
  public dashSubMenuFlag: boolean = false;
  public minimizedAsiedFlag: boolean = false;
  public DrugsNameFlag: boolean = false;
  public isAdmin: boolean = true;
  public currentDate;
  public loginType;
  public sendType;
  public doctor_referral: boolean = true;
  public leadMgmtFlag: boolean = true;
  public partialPaymentFlag: boolean = true;
  public Bill_refundflag: boolean = true;
  public billDueFlag: boolean = true;
  public bedOccupancyFlag: boolean = true;
  public abcAnalysisFlag: boolean = true;
  public productReceivablesFlag: boolean = true;
  public labtestFlag: boolean = true;
  public consolidatedreportflag: boolean = true;
  public notvistedpatreportflag: boolean = true;
  public tatsubmenuFlag1: boolean = false;
  public hospitaltatflag: boolean = true;
  public diagtatflag = false;
  public pharmatatflag: boolean = true;
  public Docsubmenu_flag: boolean = false;
  public fullMenu: boolean = false;
  public inpatientDoctorMenuFlag: boolean = true;
  public physiosubFlagsub: boolean = false;
  public daig_mainMenu: boolean = false;
  public nurseMainMenusub: boolean = true;
  public dietiMainMenusub: boolean = true;
  public manageOrders: boolean = true;
  public currentorder: boolean = true;
  public pastorder: boolean = true;
  public interdepart: boolean = false;
  public bill_est: boolean = true;
  //removeby
  public inventoryReceivables: boolean = false;
  public rackandbinflag: boolean = true;
  public suppaymentret: boolean = true;
  public pharmareports: boolean = true;
  public stockstatusflag: boolean = false;
  public salesreportflag: boolean = true;
  public receivalblesreportflag: boolean = true;
  public returnsreportflag: boolean = true;
  public genericnameflag: boolean = true;
  public help: boolean = true;
  public stockadjustflag: boolean = true;
  public patienregFlag: boolean = true;
  public UserRoleFlag: boolean = true;
  public Otbookingfra: boolean = true;
  public visitpurposeFlag: boolean = true;
  public RatecardFlag: boolean = true;
  public gramstainsflag: boolean = true;
  public addtemplateflag: boolean = true;
  public ProductFormflag: boolean = true;
  public StoreNameflag: boolean = true;
  public Stockexchangeflag: boolean = true;
  public DiagnosticReagentflag: boolean = true;
  public DiagnosticTube: boolean = true;
  public specimenaccessionerFlag: boolean = true;
  public ConductionDoctorFlag: boolean = true;
  public Surgery_equipmentsFlag: boolean = true;
  public submenuFlagb: boolean = false;
  public inwardFlag: boolean = true;  
  public repAppSubFlag: boolean;
  public specimen_coll_flag: boolean = true;
  public diag_testAndPack_flag: boolean = true;
  public pathology_report_flag: boolean = true;
  public path_rept_pending_flag: boolean = true;
  public path_rept_rejected_flag: boolean = true;
  public path_rept_completed_flag: boolean = true;
  public path_rept_approved_flag: boolean = true;
  public radiology_report_flag: boolean = true;
  public radio_rept_pending_flag: boolean = true;
  public radio_rept_rejected_flag: boolean = true;
  public radio_rept_completed_flag: boolean = true;
  public radio_rept_approved_flag: boolean = true;
  public microbiology_report_flag: boolean = true;
  public micro_rept_pending_flag: boolean = true;
  public micro_rept_rejected_flag: boolean = true;
  public micro_rept_completed_flag: boolean = true;
  public micro_rept_approved_flag: boolean = true;
  public outsource_centre_flag: boolean = true;
  public outsource_payment_flag: boolean = true;
  public diab_reading_flag: boolean = true;
  public nephro_reading_flag: boolean = true;
  public diag_users_flag: boolean = true;
  public diag_pres_flag: boolean = true;
  public OutsourceFlag: boolean = true;
  public diagAppointmentFlag: boolean = true;
  public diag_masters_flag: boolean = true;
  public cult_sense_drugType_flag: boolean = true;
  public cult_path_flag: boolean = true;
  public specimen_type_flag: boolean = true;
  public cult_sense_drugName_flag: boolean = true;
  public FraMasters: boolean = true;
  public fraScheduleName: boolean = true;
  public fraSubDeparment: boolean = true;
  public fraAddtemplate: boolean = true;
  public OtProcedurefra: boolean = true;
  public Otmasterfra: boolean = true;
  public wardMasterviewFlag: boolean = true;
  public schedulesviewschedules: boolean = true;
  public schedulescalendar: boolean = true;
  public schedulesavailability: boolean = true;
  public docDashboard: boolean = true;
  //Billing
  public advancesFlag: boolean = true;
  public refundFlag: boolean = true;
  public billListFlag: boolean = true;
  public chargeFlag: boolean = true;
  public consolidatedBillFlag: boolean = true;
  public insuranceBillingFlag: boolean = true;
  public addTemplateFlag: boolean = true;
  public gramStainFlag: boolean = true;
  public rateCardFlag: boolean = true;
  public visitPurposeFlag: boolean = true;
  public dischargeListfra: boolean = true;
  public MasterFlag = true;
  public nursedocappointment: boolean = true;
  public nursedoclist: boolean = true;
  public nurseward: boolean = true;
  public nurseinpat: boolean = true;
  public nursebooking: boolean = true;
  public nurseprocedure: boolean = true;
  public nurseMedicine: boolean = true;
  public nurseAssignments: boolean = true;
  public mainBilllistFlag: boolean = true;
  public pharmacyID;
  public submenuFlag: boolean = false;
  public reportSubmenuFlag: boolean = false;
  //removeby
  public manufacturer: boolean = true;
  public suppliers: boolean = true;
  public suppliersProduct: boolean = true;
  public discount: boolean = true;
  public productMaster: boolean = true;
  public purchaseRequest: boolean = true;
  public purchaseOrder: boolean = true;
  public stockEdit: boolean = true;
  public stockorlevel: boolean = true;
  public pharmasetting: boolean = true;
  public purchaseRequestlist: boolean = true;
  public addmasterflag: boolean = true;
  public pharmasalesGst: boolean = true;
  public pharamasales_report: boolean = true;
  public pharmastockReceivable: boolean = true;
  public docbilling: boolean = true;
  public Receivablereports: boolean = true;
  public Stockreports: boolean = true;
  public docDoctorcalender: boolean = true;
  public bill_listf = false;
  public hidefilter = false;
  public pastinwardsflag: boolean;
  public shortexpiryflag: boolean;
  public central_store;
  public req_central_store;
  public user_department;
  public user_department_id;
  public user_store_id;
  public user_store;
  public weightageFlag: boolean = true;
  
  public menu_bar_keys = {
    "in patient": "patient",
    home: "home",
    patients: "patientsinnermenu",
    dashboard: "dashboardinnermenu",
    consolidatedbills: "consolidatedbillsinnermenu",
    appointments: "appointment_list",
    walkin: "walkin",
    permissions: "permission",
    "walk in": "walkin",
    wapking: "walkin",
    secondopinion: "secondopinion",
    billings: "billing",
    building: "billing",
    buildings: "billing",
    "2nd opinion": "secondopinion",
    "second opinion": "secondopinion",
    homecare: "homecare",
    schedule: "schedule",
    mycalender: "mycalenderinnermenu",
    "home care": "homecare",
    permission: "permissionsmenu",
    billing: "billing",
    doctor: "doctorinnermenu",
    viewschedule: "viewscheduleinnermenu",
    notification: "notification",
    notifications: "notification",
    help: "help",
    referencevideo: "reference_video",
    misreports: "reportsMenu",
    "reference video": "reference_video",
    salesreports: "salesreports",
    stockreports: "stockreportsinnermenu",
    "mis report": "reportsMenu",
    "mis reports": "reportsMenu",
    receivablereports: "receivablereportsinnermenu",
  };
  public client_side_bar = {
    appointment: "appointment_menu_bar_client",
    doctor: "doctorinnermenu",
    diagnosis: "diagnosisinnermenu",
    physio: "physioinnermenu",
    dietician: "dieticianinnermenu",
    "second opinion": "sec_opi_smenu",
    "vitals measurments": "Vitals_mes_menu",
    "lab reports": "labrepmenu",
    "know your health": "yourhealthmenu",
    activities: "activity_inner_mennu",
    activity: "activity_inner_mennu",
    diet: "diet_inner_mennu",
    mindfulness: "mind_inner_mennu",
    ehr: "ehrmenu",
    "health data": "health_inner_mennu",
    "post covid": "post_inner_mennu",
    documents: "documents_inner_mennu",
    pharmacy: "Pharma_menu_client",
    current: "current_inner_menu",
    completed: "completed_inner_menu",
    medicine: "medicinemenu",
    relation: "relationmenu",
    "bill list": "bill_menu_client",
    reading: "reading_menu",
    readings: "reading_menu",
    settings: "settings_menu",
    features: "feature_innermenu",
    "health packages": "healtpackages_menu",
    "health package": "healtpackages_menu",
    notifications: "Notification_menu",
    notification: "Notification_menu",
  };
  public usertype: any;
  public mainreport: boolean = true;
  public Usertype: any;
  public demanageappointment: boolean;
  public deappointment: boolean;
  public doctorappointments: boolean;
  public defront: boolean;
  public debillestimate: any;
  public dediet_dashboard: boolean;
  public deotbooking: boolean;
  public demanageappointments: boolean;
  public inpatFlag: boolean = true;
  public nurs_shortexpiryflag: boolean = true;
  public front_stockstatusflag: boolean = true;
  public dayBookReportFlag: boolean = true;
  public otherExpenseFlag: boolean = true;
  public logReportFlag: boolean = true;
  public purchaseOrder_acc: boolean = true;
  public suppaymentret_acc: boolean = true;
  public stockstatusflag_acc: boolean = true;
  public receivalblesreportflag_acc: boolean = true;
  public salesreportflag_acc: boolean = true;
  public inpatReportFlag_acc: boolean = true;
  public salesrepgst_acc: boolean = true;
  public diagBillsReportFlag_acc: boolean = true;
  public hospBillsReportFlag_acc: boolean = true;
  public timesystflag: boolean = true;
  public accountpurchase_report: boolean = false;
  public billpurchase_report: boolean = false;
  public pharmapurchase_report: boolean = false;
  public dietpurchase_report: boolean = false;
  public phypurchase_report: boolean = false;
  public nurse_purchase_report: boolean = false;
  public diagpurchase_report: boolean = false;
  public adminpurchase_report: boolean = false;
  public frontpurchase_report: boolean = true;
  public Moduleidlist: any;
  //newlyadd_flag
  public doc_dashboardflag: boolean = false;
  public doc_inpatientreport: boolean = false;
  public doc_day_book: boolean = false;
  public appointment: boolean = false;
  public new_nursewardflag: boolean = false;
  public new_nursepatientflag: boolean = false;
  public new_nursedocflag: boolean = false;
  public TAT_report_flag: boolean = true;
  public front_inpatientreport: boolean = true;
  public diag_doctor_payments: boolean = true;
  public consingment_flag: boolean = true;
  public followup_report_flag: boolean = true; 
  public bill_refund_repo: boolean = true;
  public new_dietappointmentflag: boolean = true;
  public new_stockreturns: boolean = true;
  public accessRights_flag: boolean = true;
  //removeby
  public new_stock_management: boolean = false;
  public refund_reportflag: boolean = true;
  public inpatient_flag: boolean = true;
  public logreportFlag: boolean = true;
  public pharmalogreportFlag: boolean = true;
  public DiaglogreportFlag:boolean = true;
  public conductdocFlag: boolean = true;
  public outsourcepayFlag: boolean = true;
  public diagtestFlag:boolean=true;
  public DiagcountFlag:boolean=true;
  public RevenuerepoFlag:boolean=true;
  public ProductusageFlag:boolean=true;
  public surgdocFlag:boolean=true;
  public outsource_test_flag:boolean=true;
  public radiology_visit_flag:boolean=true;
  public adjustreportFlag:boolean=true;

  constructor(
    public gservice: CommonDataService,
    public http: Http,
    public routes: ActivatedRoute,
    public router: Router,
    public toastr: ToastrService,
    public messageservice: MenuViewService,
    public dialog: MatDialog,
    public masterData: MasterHelperService,
    public masterCSdata: MasterCSHelperService,
    public speechRecognition: VoicerecognitionService
  ) {
    this.masterData.callMasterData();
    this.masterCSdata.clearValue();
    FrontDesk_Helper.setBillFlag(undefined);
    Admin_Helper.setDiagBill(undefined);
    Diet_Helper.setDietFlow(undefined);
    this.appointments("app");
    // this.menuNav("appointment");
  }

  check_scroll() {
    this.myScrollContainer.nativeElement.scrollTop =
    this.myScrollContainer.nativeElement.scrollHeight;
  }

  speechRecognitionvalue() {
    this.subscription = this.speechRecognition
      .getMessage()
      .subscribe((message) => {
        if (message) {
          console.log("menu" + message);
          if (message in this.menu_bar_keys) {
            $("#" + this.menu_bar_keys[message]).click();
          }
        }
      });
  }

  ngOnInit() {
    this.loginType = Helper_Class.getLoginType();
    this.speechRecognitionvalue();
    console.log("loginType" + this.loginType);
    this.getCurrentDate();
    this.subscription = this.messageservice
      .getMessage()
      .subscribe((message) => {
        if (message) {
          // if (this.loginType == "Admin") {
          //   // this.menuNavAdmin(message, "");
          // } 
          if(true) {
            this.menuNav(message);
          }
        }
      });
    this.messageservice.listen().subscribe((m: any) => {
      if (m == "detail_view") {
        //this.check_scroll();
      }
    });
    this.userInfo = Helper_Class.getInfo();
    this.firstName = this.userInfo.first_name;
    this.lastName = this.userInfo.last_name;
    this.docInpatSubmenuFlag = false;
    this.minimized_asied = false;
    this.pastinwardsflag = false;
    this.shortexpiryflag = false;
    this.central_store = this.userInfo.hospitals[0].is_central_store;
    this.req_central_store = this.userInfo.hospitals[0].req_central_store;
    this.user_department = this.userInfo.hospitals[0].dept_desc;
    this.user_department_id = this.userInfo.hospitals[0].department_id;
    this.user_store = this.userInfo.hospitals[0].store_desc;
    this.user_store_id = this.userInfo.hospitals[0].store_id;
    console.log("USER INFO DETAILS " + JSON.stringify(this.userInfo));
    if (this.userInfo.hptl_logo != undefined) {
      this.hospitalLogo =
        ipaddress.Ip_with_img_address + this.userInfo.hptl_logo;
    } else {
      if (this.userInfo.provider == "pharmacy") {
        this.hospitalLogo =
          ipaddress.Ip_with_img_address + this.userInfo.hospitals[0].logo;
      }
    }
    this.hospitalName = this.userInfo.hospitals[0].pat_ident_prefix;
    this.sendType = this.loginType;
    this.Usertype = this.userInfo.user_type;
    var Moduleidlist = Helper_Class.getmodulelist();
    console.log("Moduleidlist", Moduleidlist);
    if (Moduleidlist != null && Moduleidlist != undefined) {
      this.dashboardBasicFlag = false;
      Doc_Helper.setCasesheet_flag("0");
      Doc_Helper.setCasesumm_flag("0");
      // this.logreportFlag= false;
      // this.pharmalogreportFlag=false;
      // this.DiaglogreportFlag=false;
      // this.conductdocFlag=false;
      // this.outsourcepayFlag=false;
      // this.diagtestFlag=false;
      // this.DiagcountFlag=false;
      // this.RevenuerepoFlag=false;
      // this.ProductusageFlag=false;
      // this.surgdocFlag=false;
      // this.diag_doctor_payments = false;
      // this.outsource_test_flag=false;

      for (var i = 0; i < Moduleidlist.length; i++) {
        if (Moduleidlist[i].module_id == "1") {
          this.inpatient_flag = false;
          if (this.loginType != 'doctor') {
            this.inpatientMenuFlag = false;
          } else {
            this.inpatientDoctorMenuFlag = false;
          }     
          this.inpatientFlag = false; //in-patients
        } else if (Moduleidlist[i].module_id == "2") {
          this.docDashboard = false;
          this.inpatientFlag = false;
        } else if (Moduleidlist[i].module_id == "3") {
          this.dischargeListfra = false; //ip-discharge
        } else if (Moduleidlist[i].module_id == "4") {
          this.manageAppointmentFlag = false;
          this.appointment = true;
          if (Moduleidlist[i].create == "1") {
            Helper_Class.setAppCreate("1");
          } else {
            Helper_Class.setAppCreate("0");
          }
          if (Moduleidlist[i].delete == "1") {
            Helper_Class.setBillDelete("1");
          } else {
            Helper_Class.setBillDelete("0");
          }
        } else if (Moduleidlist[i].module_id == "5") {
          this.docWalkinFlag = false;
        } else if (Moduleidlist[i].module_id == "6") {
          this.viewPrescritionFlag = false;
        } else if (Moduleidlist[i].module_id == "7") {
          if (
            Moduleidlist[i].create == "1" ||
            Moduleidlist[i].delete == "1" ||
            Moduleidlist[i].print == "1" ||
            Moduleidlist[i].view == "1" ||
            Moduleidlist[i].edit == "1"
          ) {
            this.billListFlag = false;
            this.defront = false;
          }
        } else if (Moduleidlist[i].module_id == "8") {
          this.docSecondopinionFlag = false;
        } else if (Moduleidlist[i].module_id == "9") {
          this.schedulescalendar = false;
          this.docDoctorcalender = false;
        } else if (Moduleidlist[i].module_id == "10") {
          this.schedulesavailability = false;
          this.docDoctorcalender = false;
        } else if (Moduleidlist[i].module_id == "11") {
          this.schedulesviewschedules = false;
          this.docDoctorcalender = false;
        } else if (
          Moduleidlist[i].module_id == "12" &&
          (Moduleidlist[i].create == "1" ||
            Moduleidlist[i].view == "1" ||
            Moduleidlist[i].edit == "1")
        ) {
          this.dashboardBasicFlag = false;
        } else if (Moduleidlist[i].module_id == "13") {
          // Medical prescription
          this.reference_video = false;
        } else if (Moduleidlist[i].module_id == "14") {
          if (
            Moduleidlist[i].edit == "0" &&
            Moduleidlist[i].create == "0" &&
            Moduleidlist[i].delete == "0" &&
            Moduleidlist[i].print == "0" &&
            Moduleidlist[i].view == "0"
          ) {
            this.help = true;
          } else {
            this.help = false;
          }
        } else if (Moduleidlist[i].module_id == "15") {
          // Doctor appointments
          if(this.loginType == "front-desk"){
            this.manageAppointmentFlag=false
          }else{
            this.docAppointmentListFlag = false;
            this.demanageappointment = false;
            this.demanageappointments = false;
          }
        } else if (Moduleidlist[i].module_id == "16") {
          // Diagnosis appointment
          if(this.loginType == "front-desk"){
            this.manageAppointmentFlag=false
          }else{
            this.diagAppointmentFlag = false;
          }
          
        } else if (Moduleidlist[i].module_id == "17") {
          // Dietician appointment
          if(this.loginType == "front-desk"){
            this.manageAppointmentFlag=false
          }else{
            this.new_dietappointmentflag = false;
          }
          
        } else if (Moduleidlist[i].module_id == "18") {
          // Patient registration
          this.patienregFlag = false;
        } else if (Moduleidlist[i].module_id == "19") {
          if (
            Moduleidlist[i].create == "1" ||
            Moduleidlist[i].delete == "1" ||
            Moduleidlist[i].print == "1" ||
            Moduleidlist[i].view == "1" ||
            Moduleidlist[i].edit == "1"
          ) {
            this.advancesFlag = false;
          }
        } else if (Moduleidlist[i].module_id == "20") {
          if (
            Moduleidlist[i].create == "1" ||
            Moduleidlist[i].delete == "1" ||
            Moduleidlist[i].print == "1" ||
            Moduleidlist[i].view == "1" ||
            Moduleidlist[i].edit == "1"
          ) {
            this.chargeFlag = false;
          }
        } else if (Moduleidlist[i].module_id == "21") {
          if (
            Moduleidlist[i].create == "1" ||
            Moduleidlist[i].delete == "1" ||
            Moduleidlist[i].print == "1" ||
            Moduleidlist[i].view == "1" ||
            Moduleidlist[i].edit == "1"
          ) {
            this.consolidatedBillFlag = false;
          }
        } else if (Moduleidlist[i].module_id == "22") {
          if (
            Moduleidlist[i].create == "1" ||
            Moduleidlist[i].delete == "1" ||
            Moduleidlist[i].print == "1" ||
            Moduleidlist[i].view == "1" ||
            Moduleidlist[i].edit == "1"
          ) {
            this.partialPaymentFlag = false;
          }
        } else if (Moduleidlist[i].module_id == "23") {
          if (
            Moduleidlist[i].create == "1" ||
            Moduleidlist[i].delete == "1" ||
            Moduleidlist[i].print == "1" ||
            Moduleidlist[i].view == "1" ||
            Moduleidlist[i].edit == "1"
          ) {
            this.insuranceBillingFlag = false;
          }
        } else if (Moduleidlist[i].module_id == "24") {
          // OT booking
          this.Otbookingfra = false;
        } else if (Moduleidlist[i].module_id == "25") {
          // OT booking
          this.OtProcedurefra = false;
        } else if (Moduleidlist[i].module_id == "26") {
          this.cult_sense_drugType_flag = false;
          // fraMicrobiologydruggroup
        } else if (Moduleidlist[i].module_id == "27") {
          // Lead management
          this.leadMgmtFlag = false;
        } else if (Moduleidlist[i].module_id == "28") {
          this.cult_path_flag = false;
          this.FraMasters = false;
        } else if (Moduleidlist[i].module_id == "29") {
          this.specimen_type_flag = false;
          this.FraMasters = false;
        } else if (Moduleidlist[i].module_id == "30") {
          // Diagnostict
          this.DiagnosticTube = false;
          this.FraMasters = false;
        } else if (Moduleidlist[i].module_id == "31") {
          // DiagnosticR
          this.DiagnosticReagentflag = false;
          this.FraMasters = false;
        } else if (Moduleidlist[i].module_id == "32") {
          // stockar
          this.Stockexchangeflag = false;
          this.FraMasters = false;
        } else if (Moduleidlist[i].module_id == "33") {
          // StoreName
          this.StoreNameflag = false;
          this.FraMasters = false;
        } else if (Moduleidlist[i].module_id == "34") {
          this.genericnameflag = false;
          this.FraMasters = false;
        } else if (Moduleidlist[i].module_id == "35") {
          this.fraScheduleName = false;
        } else if (Moduleidlist[i].module_id == "36") {
          // ifm
          this.ProductFormflag = false;
          this.FraMasters = false;
        } else if (Moduleidlist[i].module_id == "37") {
          this.wardMasterFlag = false;
          this.FraMasters = false;
        } else if (Moduleidlist[i].module_id == "38") {
          this.Otmasterfra = false;
        } else if (Moduleidlist[i].module_id == "39") {
          this.doctor_referral = false;
        } else if (Moduleidlist[i].module_id == "40") {
          this.reportsFlag = false;
          this.hospBillsReportFlag = false;
        } else if (Moduleidlist[i].module_id == "41") {
          this.reportsFlag = false;
          this.diagBillsReportFlag = false;
        } else if (Moduleidlist[i].module_id == "42") {
          this.reportsFlag = false;
          this.notvistedpatreportflag = false;
        } else if (Moduleidlist[i].module_id == "43") {
          this.reportsFlag = false;
          this.consolidatedreportflag = false;
        } else if (Moduleidlist[i].module_id == "44") {
          this.wardMasterviewFlag = false;
        } else if (Moduleidlist[i].module_id == "45") {
          if (
            Moduleidlist[i].edit == "0" &&
            Moduleidlist[i].create == "0" &&
            Moduleidlist[i].delete == "0" &&
            Moduleidlist[i].print == "0" &&
            Moduleidlist[i].view == "0"
          ) {
            this.manageOrders = true;
            this.currentorder = true;
          } else {
            this.manageOrders = false;
            this.currentorder = false;
          }
        } else if (Moduleidlist[i].module_id == "46") {
          if (
            Moduleidlist[i].edit == "0" &&
            Moduleidlist[i].create == "0" &&
            Moduleidlist[i].delete == "0" &&
            Moduleidlist[i].print == "0" &&
            Moduleidlist[i].view == "0"
          ) {
            this.manageOrders = true;
            this.pastorder = true;
          } else {
            this.manageOrders = false;
            this.pastorder = false;
          }
        } else if (Moduleidlist[i].module_id == "47") {
          if (
            Moduleidlist[i].edit == "0" &&
            Moduleidlist[i].create == "0" &&
            Moduleidlist[i].delete == "0" &&
            Moduleidlist[i].print == "0" &&
            Moduleidlist[i].view == "0"
          ) {
            this.manageOrders = true;
            this.interdepart = true;
          } else {
            this.manageOrders = false;
            this.interdepart = false;
          }
        } else if (Moduleidlist[i].module_id == "48") {
          if (
            Moduleidlist[i].edit == "0" &&
            Moduleidlist[i].create == "0" &&
            Moduleidlist[i].delete == "0" &&
            Moduleidlist[i].print == "0" &&
            Moduleidlist[i].view == "0"
          ) {
            this.manageOrders = true;
            this.bill_est = true;
            this.debillestimate = true;
          } else {
            this.manageOrders = false;
            this.bill_est = false;
            this.debillestimate = false;
          }
        } else if (Moduleidlist[i].module_id == "49") {
          if (
            Moduleidlist[i].edit == "0" &&
            Moduleidlist[i].create == "0" &&
            Moduleidlist[i].delete == "0" &&
            Moduleidlist[i].print == "0" &&
            Moduleidlist[i].view == "0"
          ) {
            this.manufacturer = true;
          } else {
            this.new_stock_management = false;
            this.manufacturer = false;
          }
        } else if (Moduleidlist[i].module_id == "50") {
          if (
            Moduleidlist[i].edit == "0" &&
            Moduleidlist[i].create == "0" &&
            Moduleidlist[i].delete == "0" &&
            Moduleidlist[i].print == "0" &&
            Moduleidlist[i].view == "0"
          ) {
            this.stockorlevel = true;
          } else {
            this.new_stock_management = false;
            this.stockorlevel = false;
          }
        } else if (Moduleidlist[i].module_id == "51") {
          if (
            Moduleidlist[i].edit == "0" &&
            Moduleidlist[i].create == "0" &&
            Moduleidlist[i].delete == "0" &&
            Moduleidlist[i].print == "0" &&
            Moduleidlist[i].view == "0"
          ) {
            this.suppliers = true;
          } else {
            if(this.central_store == '1'){
              this.new_stock_management = false;
              this.suppliers = false;
            }else{
              if(this.req_central_store =='1'){
                this.suppliers = true;
              }else{
                this.new_stock_management = false;
                this.suppliers = false;
              }
            }
          }
        } else if (Moduleidlist[i].module_id == "52") {
          if (
            Moduleidlist[i].edit == "0" &&
            Moduleidlist[i].create == "0" &&
            Moduleidlist[i].delete == "0" &&
            Moduleidlist[i].print == "0" &&
            Moduleidlist[i].view == "0"
          ) {
            this.discount = true;
          } else {
            this.discount = false;
          }
        } else if (Moduleidlist[i].module_id == "53") {
          //product master
          if (
            Moduleidlist[i].edit == "0" &&
            Moduleidlist[i].create == "0" &&
            Moduleidlist[i].delete == "0" &&
            Moduleidlist[i].print == "0" &&
            Moduleidlist[i].view == "0"
          ) {
            this.productMaster = true;
          } else {
            if(this.central_store == '1'){
              this.productMaster = false;
              this.new_stock_management = false;
            }else{
              if(this.req_central_store == '1'){
                this.productMaster = true;
              }else{
                this.productMaster = false;
                this.new_stock_management = false;
              }
            }

          }
        } else if (Moduleidlist[i].module_id == "54") {
          if (
            Moduleidlist[i].edit == "0" &&
            Moduleidlist[i].create == "0" &&
            Moduleidlist[i].delete == "0" &&
            Moduleidlist[i].print == "0" &&
            Moduleidlist[i].view == "0"
          ) {
            this.suppliersProduct = true;
          } else {
            if(this.central_store == '1'){
              this.new_stock_management = false;
              this.suppliersProduct = false;
            }else{
              if(this.req_central_store == '1'){
                this.suppliersProduct = true;
              }else{
                this.new_stock_management = false;
                this.suppliersProduct = false;
              }
            }

          }
        } else if (Moduleidlist[i].module_id == "55") {
          if (
            Moduleidlist[i].edit == "0" &&
            Moduleidlist[i].create == "0" &&
            Moduleidlist[i].delete == "0" &&
            Moduleidlist[i].print == "0" &&
            Moduleidlist[i].view == "0"
          ) {
            this.purchaseRequest = true;
          } else {
            if(this.central_store == '1'){
              if(this.req_central_store == '1'){
                this.new_stock_management = false;
                this.purchaseRequest = false;
              }else{
               
                this.purchaseRequest = true;
              }
            }else if(this.central_store == '1'){
              if(this.req_central_store == "1"){
                this.new_stock_management = false;
                this.purchaseRequest = false;
              }else if(this.central_store == "0"){
                this.purchaseRequest = true;
              }
            }else{
              this.purchaseRequest = true;
            }

          }
        } else if (Moduleidlist[i].module_id == "56") {
          if (
            Moduleidlist[i].edit == "0" &&
            Moduleidlist[i].create == "0" &&
            Moduleidlist[i].delete == "0" &&
            Moduleidlist[i].print == "0" &&
            Moduleidlist[i].view == "0"
          ) {
            this.purchaseOrder = true;
          } else {
            if(this.central_store == "1"){
              this.purchaseOrder = false;
            }else{
              if (this.req_central_store == "1") {
                this.purchaseOrder = true;
              }else{
                this.new_stock_management = false;
                this.purchaseOrder = false;
              }
            }

          }
        } else if (Moduleidlist[i].module_id == "57") {
          if (
            Moduleidlist[i].edit == "0" &&
            Moduleidlist[i].create == "0" &&
            Moduleidlist[i].delete == "0" &&
            Moduleidlist[i].print == "0" &&
            Moduleidlist[i].view == "0"
          ) {
            this.inventoryReceivables = true;
          } else {
            if(this.central_store == "1"){
              this.inventoryReceivables = false;
            }else{
              if (this.req_central_store == "1") {
                this.inventoryReceivables = true;
              }else{
                this.new_stock_management = false;
                this.inventoryReceivables = false;
              }
            }

          }
        } else if (Moduleidlist[i].module_id == "58") {
          if (
            Moduleidlist[i].edit == "0" &&
            Moduleidlist[i].create == "0" &&
            Moduleidlist[i].delete == "0" &&
            Moduleidlist[i].print == "0" &&
            Moduleidlist[i].view == "0"
          ) {
            this.stockEdit = true;
          } else {
            this.new_stock_management = false;
            this.stockEdit = false;
          }
        } else if (Moduleidlist[i].module_id == "59") {
          if (
            Moduleidlist[i].edit == "0" &&
            Moduleidlist[i].create == "0" &&
            Moduleidlist[i].delete == "0" &&
            Moduleidlist[i].print == "0" &&
            Moduleidlist[i].view == "0"
          ) {
            this.pharmareports = true;
            this.new_stockreturns = true;
          } else {
            this.pharmareports = false;
            this.new_stock_management = false;
            this.new_stockreturns = false;
          }
        } else if (Moduleidlist[i].module_id == "60") {
          if (
            Moduleidlist[i].edit == "0" &&
            Moduleidlist[i].create == "0" &&
            Moduleidlist[i].delete == "0" &&
            Moduleidlist[i].print == "0" &&
            Moduleidlist[i].view == "0"
          ) {
            this.suppaymentret = true;
          } else {
            this.suppaymentret = false;
          }
        } else if (Moduleidlist[i].module_id == "61") {
          if (
            Moduleidlist[i].edit == "0" &&
            Moduleidlist[i].create == "0" &&
            Moduleidlist[i].delete == "0" &&
            Moduleidlist[i].print == "0" &&
            Moduleidlist[i].view == "0"
          ) {
            this.rackandbinflag = true;
          } else {
            this.new_stock_management = false;
            this.rackandbinflag = false;
          }
        } else if (Moduleidlist[i].module_id == "62") {
          this.reportsFlag = false;
          this.Stockreports = false;
          this.mainreport = false;
        } else if (Moduleidlist[i].module_id == "63") {
          if (
            Moduleidlist[i].create == "1" ||
            Moduleidlist[i].delete == "1" ||
            Moduleidlist[i].print == "1" ||
            Moduleidlist[i].view == "1" ||
            Moduleidlist[i].edit == "1"
          ) {
            this.reportsFlag = false;
            this.schdForDrugInspFlag = false;
          }
        } else if (Moduleidlist[i].module_id == "64") {
          // Pharmacy
        } else if (Moduleidlist[i].module_id == "65") {
          // Provider rating
        } else if (
          Moduleidlist[i].module_id == "66" &&
          (Moduleidlist[i].create == "1" ||
            Moduleidlist[i].view == " 1" ||
            Moduleidlist[i].edit == "1")
        ) {
          this.docHomecareFlag = false;
        } else if (Moduleidlist[i].module_id == "67") {
          // Case summary home
        } else if (Moduleidlist[i].module_id == "68") {
          // Transfer record
          this.inpatReportFlag = false;
        } else if (Moduleidlist[i].module_id == "69") {
          // Share record
        } else if (Moduleidlist[i].module_id == "70") {
          // Blood donation
        } else if (Moduleidlist[i].module_id == "71") {
          // Upload reports
        } else if (Moduleidlist[i].module_id == "72") {
          // Manage relations
        } else if (Moduleidlist[i].module_id == "73") {
          // My documents
        } else if (Moduleidlist[i].module_id == "74") {
          // Blood pressure
        } else if (Moduleidlist[i].module_id == "75") {
          // Heart rate
        } else if (Moduleidlist[i].module_id == "76") {
          // Suggested diet plan
        } else if (Moduleidlist[i].module_id == "77") {
          // Self diet plan
        } else if (Moduleidlist[i].module_id == "78") {
          // Wellness
        } else if (Moduleidlist[i].module_id == "79") {
          // Plans and packages
        } else if (Moduleidlist[i].module_id == "80") {
          // Vitals
        } else if (Moduleidlist[i].module_id == "81") {
          // Mindfulness
        } else if (Moduleidlist[i].module_id == "82") {
          // Medicines
        } else if (Moduleidlist[i].module_id == "83") {
          // medicine tracker or diet tracker
          this.nurseMedicine = false;
        } else if (Moduleidlist[i].module_id == "84") {
          // Activities
        } else if (Moduleidlist[i].module_id == "85") {
          this.docCaseSheetFlag = false;
          Doc_Helper.setCasesheet_flag("1");
        } else if (Moduleidlist[i].module_id == "86" ) {
          this.new_nursewardflag = false; 
        } else if (Moduleidlist[i].module_id == "87") {
          this.new_nursepatientflag = false;
        } else if (Moduleidlist[i].module_id == "88") {
          this.new_nursedocflag = false;
        } else if (Moduleidlist[i].module_id == "89") {
          this.reportsFlag = false;
          this.abcAnalysisFlag = false;
        } else if (Moduleidlist[i].module_id == "90") {
          this.reportsFlag = false;
          this.bedOccupancyFlag = false;
        } else if (Moduleidlist[i].module_id == "91") {
          this.reportsFlag = false;
          this.billDueFlag = false;
        } else if (Moduleidlist[i].module_id == "92") {
          this.reportsFlag = false;
          this.Bill_refundflag = false;
        } else if (Moduleidlist[i].module_id == "93") {
          // Sleep plan
        } else if (Moduleidlist[i].module_id == "94") {
          // Recipe
        } else if (Moduleidlist[i].module_id == "95") {
          // News feed
        } else if (Moduleidlist[i].module_id == "96") {
          // Wearable device
        } else if (Moduleidlist[i].module_id == "97") {
          // Sleep pattern
        } else if (Moduleidlist[i].module_id == "98") {
          // Step count
        } else if (Moduleidlist[i].module_id == "99") {
          // Blood glucose
        } else if (Moduleidlist[i].module_id == "100") {
          // Roles and access
          this.accessRights_flag = false;
        } else if (Moduleidlist[i].module_id == "101") {
          this.specimen_coll_flag = false;
        } else if (Moduleidlist[i].module_id == "102") {
          this.diag_testAndPack_flag = false;
          this.diag_masters_flag = false;
        } else if (
          Moduleidlist[i].module_id == "103" ||
          Moduleidlist[i].module_id == "104" ||
          Moduleidlist[i].module_id == "105" ||
          Moduleidlist[i].module_id == "106"
        ) {
          this.pathology_report_flag = false;
          if (Moduleidlist[i].module_id == "103") {
            this.path_rept_pending_flag = false;
          } else if (Moduleidlist[i].module_id == "104") {
            this.path_rept_rejected_flag = false;
          } else if (Moduleidlist[i].module_id == "105") {
            this.path_rept_completed_flag = false;
          } else if (Moduleidlist[i].module_id == "106") {
            this.path_rept_approved_flag = false;
          }
        } else if (
          Moduleidlist[i].module_id == "107" ||
          Moduleidlist[i].module_id == "108" ||
          Moduleidlist[i].module_id == "109" ||
          Moduleidlist[i].module_id == "110"||
          Moduleidlist[i].module_id == "202" 
        ) {
          this.radiology_report_flag = false;
          if (Moduleidlist[i].module_id == "107") {
            this.radio_rept_pending_flag = false;
          } else if (Moduleidlist[i].module_id == "108") {
            this.radio_rept_rejected_flag = false;
          } else if (Moduleidlist[i].module_id == "109") {
            this.radio_rept_completed_flag = false;
          } else if (Moduleidlist[i].module_id == "110") {
            this.radio_rept_approved_flag = false;
          }else if(Moduleidlist[i].module_id =="202"){
            this.radiology_visit_flag=false;
          }
        } else if (
          Moduleidlist[i].module_id == "111" ||
          Moduleidlist[i].module_id == "112" ||
          Moduleidlist[i].module_id == "113" ||
          Moduleidlist[i].module_id == "114"
        ) {
          this.microbiology_report_flag = false;
          if (Moduleidlist[i].module_id == "111") {
            this.micro_rept_pending_flag = false;
          } else if (Moduleidlist[i].module_id == "112") {
            this.micro_rept_rejected_flag = false;
          } else if (Moduleidlist[i].module_id == "113") {
            this.micro_rept_completed_flag = false;
          } else if (Moduleidlist[i].module_id == "114") {
            this.micro_rept_approved_flag = false;
          }
        } else if (Moduleidlist[i].module_id == "115" || Moduleidlist[i].module_id == "116" || Moduleidlist[i].module_id == '203') {
          this.OutsourceFlag = false;
          if (Moduleidlist[i].module_id == "115") {
            this.outsource_centre_flag = false;
          } else if (Moduleidlist[i].module_id == "116") {
            this.outsource_payment_flag = false;
          }else if(Moduleidlist[i].module_id == '203'){
            this.outsource_test_flag = false
          }
        } else if (Moduleidlist[i].module_id == "117") {
          this.diab_reading_flag = false;
        } else if (Moduleidlist[i].module_id == "118") {
          this.diag_users_flag = false;
        } else if (Moduleidlist[i].module_id == "119") {
          this.diag_pres_flag = false;
        } else if (Moduleidlist[i].module_id == "120") {
          // permissions
          this.permissionsFlag = false;
          this.permissionSubmenuFlag = false;
        } else if (Moduleidlist[i].module_id == "121") {
          // this.inventoryReceivables = false;          
        } else if (Moduleidlist[i].module_id == "122") {
          if (
            Moduleidlist[i].edit == "0" &&
            Moduleidlist[i].create == "0" &&
            Moduleidlist[i].delete == "0" &&
            Moduleidlist[i].print == "0" &&
            Moduleidlist[i].view == "0"
          ) {
            this.pharmareports = true;
            this.stockstatusflag = true;
          } else {

            if(this.central_store == "1"){
              this.pharmareports = false;
              this.reportsFlag = false;
              this.stockstatusflag = false;
            }else{
              if(this.req_central_store == '1'){
                this.stockstatusflag = true;
              }else{
                this.pharmareports = false;
                this.reportsFlag = false;
                this.stockstatusflag = false;
              }
            }
          }
        }  else if (Moduleidlist[i].module_id == "123") {
          if (
            Moduleidlist[i].edit == "0" &&
            Moduleidlist[i].create == "0" &&
            Moduleidlist[i].delete == "0" &&
            Moduleidlist[i].print == "0" &&
            Moduleidlist[i].view == "0"
          ) {
            this.pharmareports = true;
            this.salesreportflag = true;
          } else {
            this.pharmareports = false;
            this.reportsFlag = false;
            this.salesreportflag = false;
          }
        } else if (Moduleidlist[i].module_id == "124") {
          // receivable report
          this.reportsFlag = false;
          this.receivalblesreportflag = false;
          this.mainreport = false;
        } else if (Moduleidlist[i].module_id == "125") {
          this.reportsFlag = false;
          this.returnsreportflag = false;
        } else if (Moduleidlist[i].module_id == "126") {
          if (
            Moduleidlist[i].edit == "0" &&
            Moduleidlist[i].create == "0" &&
            Moduleidlist[i].delete == "0" &&
            Moduleidlist[i].print == "0" &&
            Moduleidlist[i].view == "0"
          ) {
            this.stockadjustflag = true;
          } else {
            this.new_stock_management = false;
            this.stockadjustflag = false;
          }
        } else if (Moduleidlist[i].module_id == "127") {
          if (
            Moduleidlist[i].edit == "0" &&
            Moduleidlist[i].create == "0" &&
            Moduleidlist[i].delete == "0" &&
            Moduleidlist[i].print == "0" &&
            Moduleidlist[i].view == "0"
          ) {
            this.inwardFlag = true;
          } else {
            this.inwardFlag = false;
          }
        } else if (Moduleidlist[i].module_id == "128") {
          this.nursedocappointment = false;
        } else if (Moduleidlist[i].module_id == "129") {
          // Doctor list
          this.nursedoclist = false;
        }  else if (Moduleidlist[i].module_id == "130") {
          // Doctor list
          this.nurseAssignments = false;
        } else if (Moduleidlist[i].module_id == "131") {
          // patient List
          this.isAdmin = false;
        } else if (Moduleidlist[i].module_id == "132") {
          // patient List
          this.patientListFlag = false;
          this.inpatientMenuFlag = false;
        } else if (Moduleidlist[i].module_id == "133") {
          this.UserRoleFlag = false;
        } else if (Moduleidlist[i].module_id == "134") {
          this.cult_sense_drugName_flag = false;
          this.diag_masters_flag = false;
        } else if (Moduleidlist[i].module_id == "135") {
          this.hospitaltatflag = false;
          this.tatsubmenuFlag1 = false;
          this.TAT_report_flag = false;
        } else if (Moduleidlist[i].module_id == "136") {
          this.diagtatflag = false;
          this.tatsubmenuFlag1 = false;
          this.TAT_report_flag = false;
        } else if (Moduleidlist[i].module_id == "137") {
          this.pharmatatflag = false;
          this.tatsubmenuFlag1 = false;
          this.TAT_report_flag = false;
        } else if (Moduleidlist[i].module_id == "138") {
          this.consultantFlag = false;
        } else if (Moduleidlist[i].module_id == "139") {
          this.fraSubDeparment = false;
        } else if (Moduleidlist[i].module_id == "140") {
          // ADD TEMPLATE
          this.FraMasters = false;
          this.addtemplateflag = false;
        } else if (Moduleidlist[i].module_id == "141") {
          // GRAMSTAIN
          this.FraMasters = false;
          this.gramstainsflag = false;
        } else if (Moduleidlist[i].module_id == "142") {
          // RateCard
          this.FraMasters = false;
          this.RatecardFlag = false;
        } else if (Moduleidlist[i].module_id == "143") {
          // Inpatient reports
          this.reportsFlag = false;
          this.front_inpatientreport = false;
        } else if (Moduleidlist[i].module_id == "144") {
          // Product receivables
          this.reportsFlag = false;
          this.productReceivablesFlag = false;
        } else if (Moduleidlist[i].module_id == "145") {
          this.reportsFlag = false;
          this.labtestFlag = false;
        } else if (Moduleidlist[i].module_id == "146") {
          // Visitpurpose
          this.FraMasters = false;
          this.visitpurposeFlag = false;
        } else if (Moduleidlist[i].module_id == "147") {
          // Inpatient discharge
        } else if (Moduleidlist[i].module_id == "148") {
          // specimenaccessionerFlag
          this.FraMasters = false;
          this.specimenaccessionerFlag = false;
        } else if (Moduleidlist[i].module_id == "149") {
          this.FraMasters = false;
          this.Surgery_equipmentsFlag = false;
        } else if (Moduleidlist[i].module_id == "150") {

        } else if (Moduleidlist[i].module_id == "151") {
          if (
            Moduleidlist[i].create == "1" ||
            Moduleidlist[i].delete == "1" ||
            Moduleidlist[i].print == "1" ||
            Moduleidlist[i].view == "1" ||
            Moduleidlist[i].edit == "1"
          ) {
            this.WardTransferFlag = false;
          }
        } else if (Moduleidlist[i].module_id == "152") {
          // ConductionDoctorFlag
          this.FraMasters = false;
          this.ConductionDoctorFlag = false;
        } else if (Moduleidlist[i].module_id == "153") {
          // Medicine tracker
        } else if (Moduleidlist[i].module_id == "154") {
          // Refer to friend 
        } else if (Moduleidlist[i].module_id == "155") {
          // No appointment
        } else if (Moduleidlist[i].module_id == "156") {
          // Professional Dashboard
        } else if (Moduleidlist[i].module_id == "157") {
          // View prescription
        } else if (Moduleidlist[i].module_id == "158") {
          this.reportsFlag = false;
        } else if (Moduleidlist[i].module_id == "159") {
          // Physio appointment
        } else if (Moduleidlist[i].module_id == "160") {
          // Profile update
        } else if (Moduleidlist[i].module_id == "161") {
          // Health data
        } else if (Moduleidlist[i].module_id == "162") {
          // Post covid
        } else if (Moduleidlist[i].module_id == "163") {
          // Physio Appointments
        } else if (Moduleidlist[i].module_id == "164") {
          // Health survey
        } else if (Moduleidlist[i].module_id == "165") {
          // Pathology
        } else if (Moduleidlist[i].module_id == "166") {
          // Radiology
        } else if (Moduleidlist[i].module_id == "167") {
          // Notes
        } else if (Moduleidlist[i].module_id == "168") {
          this.nephro_reading_flag = false;
        } else if (Moduleidlist[i].module_id == "169") {
           this.diag_doctor_payments = false;
        } else if (Moduleidlist[i].module_id == "170") {
          // Doctor surgery details
        } else if (Moduleidlist[i].module_id == "171") {
          this.reportsFlag = false;
          this.nurs_shortexpiryflag = false;
        } else if (Moduleidlist[i].module_id == "172") {
          // Inpatient report (Repeat)
        } else if (Moduleidlist[i].module_id == "173") {
          if (
            Moduleidlist[i].edit == "1" ||
            Moduleidlist[i].create == "1" ||
            Moduleidlist[i].delete == "1" ||
            Moduleidlist[i].print == "1" ||
            Moduleidlist[i].view == "1"
          ) {
            this.reportsFlag = false;
            this.dayBookReportFlag = false;
          }
        } else if (Moduleidlist[i].module_id == "174") {
          if (
            Moduleidlist[i].edit == "1" ||
            Moduleidlist[i].create == "1" ||
            Moduleidlist[i].delete == "1" ||
            Moduleidlist[i].print == "1" ||
            Moduleidlist[i].view == "1"
          ) {
            this.otherExpenseFlag = false;
          }
        } else if (Moduleidlist[i].module_id == "175") {
          this.reportsFlag = false;
          this.frontpurchase_report = false;
        } else if (Moduleidlist[i].module_id == "176") {
          if (
            Moduleidlist[i].edit == "1" ||
            Moduleidlist[i].create == "1" ||
            Moduleidlist[i].delete == "1" ||
            Moduleidlist[i].print == "1" ||
            Moduleidlist[i].view == "1"
          ) {
            this.logReportFlag = false;
          }
        } else if (Moduleidlist[i].module_id == "177") {
          // Current order inpatient
        } else if (Moduleidlist[i].module_id == "178") {
          this.new_stock_management = false;
          this.consingment_flag = false;
        } else if (Moduleidlist[i].module_id == "179") {
          // Purchase report 
        } else if (Moduleidlist[i].module_id == "180") {
          // Returns report (Repeat) 
        } else if (Moduleidlist[i].module_id == "181") {
          // Short expiry (Repeat) 
        } else if (Moduleidlist[i].module_id == "182") {
          if (
            Moduleidlist[i].create == "1" ||
            Moduleidlist[i].delete == "1" ||
            Moduleidlist[i].print == "1" ||
            Moduleidlist[i].view == "1" ||
            Moduleidlist[i].edit == "1"
          ) {
            this.reportsFlag = false;
            this.inwardReportFlag = false;
          }
        } else if (Moduleidlist[i].module_id == "183") {
          this.weightageFlag = false;
        } else if (Moduleidlist[i].module_id == "184") {
          this.fraAddtemplate = false;
        } else if (Moduleidlist[i].module_id == "185") {
          // Pharma TAT (Repeat)
        } else if (Moduleidlist[i].module_id == "186") {
          this.followup_report_flag = false;
        } else if (Moduleidlist[i].module_id == "187") {
          this.reportsFlag = false;
          this.front_stockstatusflag = false;
        } else if (Moduleidlist[i].module_id == "188") {
          this.reportsFlag = false;
          this.pharmasalesGst = false;
        } else if (Moduleidlist[i].module_id == "189") {
          // Diagnosis bill report (Repeat)
        } else if (Moduleidlist[i].module_id == "190") {
          this.reportsFlag = false;
          this.bill_refund_repo = false;
        } else if (Moduleidlist[i].module_id == "191") {
          this.reportsFlag = false;
          this.salesForDrugInspFlag = false;
        } else if (Moduleidlist[i].module_id == "192") {
          this.reportsFlag = false;
          this.refund_reportflag = false;
         }else if (Moduleidlist[i].module_id == "193"){
          this.reportsFlag = false;
          this.pharmalogreportFlag=false;
         
        }
        else if (Moduleidlist[i].module_id == "194"){
          this.reportsFlag = false;
          this.DiaglogreportFlag=false;
         
        }else if(Moduleidlist[i].module_id == "195"){
          this.reportsFlag = false;
          this.conductdocFlag=false;
          
        }else if(Moduleidlist[i].module_id == "196"){
          this.reportsFlag = false;
          this.outsourcepayFlag=false;
        
        }else if(Moduleidlist[i].module_id == "197"){
          this.reportsFlag = false;
          this.diagtestFlag=false;
         
        }
        else if(Moduleidlist[i].module_id == "198"){
          this.reportsFlag = false;
          this.DiagcountFlag=false;
         
        }else if(Moduleidlist[i].module_id == "199"){
          this.reportsFlag = false;
          this.RevenuerepoFlag=false;
        
        }else if(Moduleidlist[i].module_id == "200"){
          
          this.surgdocFlag = false;
         
        }else if(Moduleidlist[i].module_id == "201"){
          if (
            Moduleidlist[i].edit == "0" &&
            Moduleidlist[i].create == "0" &&
            Moduleidlist[i].delete == "0" &&
            Moduleidlist[i].print == "0" &&
            Moduleidlist[i].view == "0"
          ) {
            this.ProductusageFlag=true;
          } else {
            this.new_stock_management=false
            this.ProductusageFlag=false
          }
        }else if(Moduleidlist[i].module_id == "202"){
          this.adjustreportFlag=false;
        }
      }
    }
  }

  appointments(data) {
    if (data == "home") Physio_Helper.setHomecare("1");
    else Physio_Helper.setHomecare("0");
  }

  setAppoinmentHelper(e) {
    if (e == "appinment") {
      Doc_Helper.setAppDate(this.currentDate);
    } else if (e == "billing" || e == "ipbilling") {
      Doc_Helper.setBillFromDate(this.currentDate);
      Doc_Helper.setBillToDate(this.currentDate);
      if (e == "billing") {
        Doc_Helper.setIpCreateBill("opbil");
      } else {
        Doc_Helper.setIpCreateBill("ipbill");
      }
    } else if (e == "homecare") {
      Doc_Helper.setHomecareDate(this.currentDate);
    } else if (e == "doctor_availability") {
      Doc_Helper.setDocAvailabitiyDate(this.currentDate);
    }
  }

  getCurrentDate() {
    var obj = Master_Helper.getMasterCurrentDate();
    if (obj.current_date != null) {
      this.currentDate = obj.current_date;
    }
  }

  getDateMatePicker1(e) {
    var month = (e.getMonth() + 1 < 10 ? "0" : "") + (e.getMonth() + 1);
    var day = (e.getDate() < 10 ? "0" : "") + e.getDate();
    var apt = e.getFullYear() + "-" + month + "-" + day;
    return apt.trim();
  }

  getNotificationList() {
    console.log("check no list" + this.sendType + this.userInfo.user_id);
    var headers = new Headers();
    headers.append("Content-Type", "application/json");
    this.http
      .post(
        ipaddress.getIp.toString() + "gen/nc",
        {
          type: this.sendType,
          user_id: this.userInfo.user_id,
        },
        { headers: headers }
      )
      .subscribe(
        (response) => {
          var obj = response.json();

          console.log("check obj list" + JSON.stringify(obj));
          if (obj.notifications != null) {
            if (obj.notifications.length != 0) {
              this.notificationCount = obj.notifications;
              this.notificationFlag = true;
            } else {
              this.notificationCount = "0";
            }
          }
        },
        (error) => {}
      );
  }

  open_setting() {
    this.settings = !this.settings;
  }

  logout(name) {
    var headers = new Headers();
    headers.append("Content-Type", "application/json");
    this.http
      .post(
        ipaddress.getIp.toString() + "usercontroller/logout/",
        JSON.stringify({
          type: this.sendType,
          user: this.userInfo.user_id,
        }),
        { headers: headers }
      )
      .subscribe(
        (response) => {
          this.toastr.success(Message_data.logOut + name);
          localStorage.clear();
          this.router.navigateByUrl("/loginpage");
        },
        (error) => {}
      );
  }

  menuNav(page) {
    let page_nmae: string = page;
    this.selectedMenu = page;
    this.inpatientFlag = false;
    this.permissionSubmenuFlag = false;
    this.submenuFlag1 = false;
    this.pathSubMenuFlag = false;
    this.radioSubMenuFlag = false;
    this.microSubMenuFlag = false;
    this.outsubFlag = false;
    this.submenuFlag = false;
    this.DrugsNameFlag = false;
    this.reportsSubmenuFlag = false;
    this.docScheduleSubmenuFlag = false;
    this.tatsubmenuFlag1 = false;

    switch (page_nmae) {
      case "nurse_appointment":
        this.displayPage = page;
        break;
      case "manageappointments":
        this.displayPage = page;
        break;
      case "doctorAppList":
        this.displayPage = page;
        break;
      case "appointment_list":
        this.displayPage = page;
        break;
      case "diag_appointment":
        this.displayPage = page;
        break;
      case "diet_appointment":
        this.displayPage = page;
        break;
      case "diet_manageappointments":
        this.displayPage = page;
        break;
      case "inpatient":
        this.inpatientFlag = !this.inpatientFlag;
        if (this.minimizedAsied == true) {
          this.minimizing();
        }
        break;
      case "Patients":
        this.displayPage = page;
        Helper_Class.setReportType("Patients");
        break;
      case "patient_dashboard":
        this.docInpatSubmenuFlag = false;
        this.displayPage = page;
        break;
      case "dischargeList":
        this.displayPage = page;
        break;
      case "medicineTracker":
        this.displayPage = page;
        break;
      case "OTBooking":
        this.displayPage = page;
        break;
      case "OTProcedure":
        this.displayPage = page;
        break;
      case "wardTransfer":
        this.displayPage = page;
        break;
      case "in_patient":
        this.docInpatSubmenuFlag = false;
        this.displayPage = page;
        break;
      case "walkin":
        this.displayPage = page;
        break;
      case "prescriptionlist":
        this.displayPage = page;
        break;
      case "permissionF":
        this.permissionSubmenuFlag = !this.permissionSubmenuFlag;
        if (this.minimized_asied == true) {
          this.minimizing();
        }
        break;
      case "nurse_ward":
        this.permissionSubmenuFlag = false;
        this.displayPage = page;
        break;
      case "nurse_patient":
        this.permissionSubmenuFlag = false;
        this.displayPage = page;
        break;
      case "nurse_doctor":
        this.permissionSubmenuFlag = false;
        this.displayPage = page;
        break;
      case "secondopinion":
        this.displayPage = page;
        break;
      case "DrugsName":
        this.DrugsNameFlag = !this.DrugsNameFlag;
        if (this.minimized_asied == true) {
          this.minimizing();
        }
        break;
      case "wardmaster":
        this.displayPage = page;
        break;
      case "OTmaster":
        this.displayPage = page;
        break;
      case "DrugsNameType":
        this.displayPage = page;
        break;
      case "CultureSensitivityDrug":
        this.displayPage = page;
        break;
      case "addmaster":
        this.displayPage = page;
        break;
      case "CulturePathology":
        this.displayPage = page;
        break;
      case "Sampletype":
        this.displayPage = page;
        break;
      case "DiagnosticTube":
        this.displayPage = page;
        break;
      case "home":
        this.displayPage = "dashboard";
        break;
      case "patient":
        this.docInpatSubmenuFlag = !this.docInpatSubmenuFlag;
        if (this.minimized_asied == true) {
          this.minimizing();
        }
        break;
      case "patient_bill":
        this.docInpatSubmenuFlag = false;
        break;
      case "permission":
        this.displayPage = page;
        break;
      case "billing":
        Doc_Helper.setIpCreateBill("opbill");
        this.displayPage = page;
        break;
      case "homecare":
        this.displayPage = page;
        break;
      case "dashboars":
        this.displayPage = page;
        break;
      case "docassessment":
        this.displayPage = page;
        break;
      case "schedule":
        this.docScheduleSubmenuFlag = !this.docScheduleSubmenuFlag;
        if (this.minimized_asied == true) {
          this.minimizing();
        }
        break;
      case "calender":
        this.displayPage = page;
        break;
      case "doctor_availability":
        this.displayPage = page;
        break;
      case "manage_schedule":
        this.displayPage = page;
        break;
      case "notification":
        this.displayPage = page;
        break;
      case "inPatientViewInfo":
        this.displayPage = page;
        break;
      case "cashSheetPersonal":
        this.displayPage = page;
        break;
      case "billcreate":
        this.displayPage = page;
        break;
      case "billdetail":
        this.displayPage = page;
        break;
      case "billedit":
        this.displayPage = page;
        break;
      case "inPatientDS":
        this.displayPage = page;
        break;
      case "inpatient_Discharge_view":
        this.displayPage = page;
        break;
      case "inpatient_guno_Discharge_view":
        this.displayPage = page;
        break;
      case "billlist":
        this.displayPage = page;
        break;
      case "help":
        this.displayPage = page;
        break;
      case "casesheet":
        this.displayPage = page;
        break;
      case "secondopinion_casesheet":
        this.displayPage = page;
        break;
      case "profile_update":
        this.displayPage = page;
        break;
      case "medprescription":
        this.displayPage = page;
        break;
      case "ModalPage":
        this.displayPage = page;
        break;
      case "casesheet_list":
        this.displayPage = page;
        break;
      case "cardio_casesheet_view":
        this.displayPage = page;
        break;
      case "appointment_view":
        this.displayPage = page;
        break;
      case "reference_videodoc":
        this.displayPage = page;
        break;
      case "viewIpatient":
        this.displayPage = page;
        break;
      case "reportsMenu":
        this.docReportsFlag = !this.docReportsFlag;
        if (this.minimized_asied == true) {
          this.minimizing();
        }
        break;
      case "salseReport":
        this.displayPage = page;
        break;
      case "receivablesReport":
        this.displayPage = page;
        break;
      case "stockReport":
        this.displayPage = page;
        break;
      case "inpatientbilling":
        this.displayPage = page;
        break;
      case "ipbilling":
        Doc_Helper.setIpCreateBill("ipbill");
        this.displayPage = page;
        break;
      case "ipbillcreate":
        this.displayPage = page;
        break;
      case "ipcard":
        this.displayPage = page;
        break;
      case "consolidatedBills":
        this.displayPage = page;
        break;
      case "inpat_reports":
        this.displayPage = page;
        break;
      case "inPat_report":
        this.displayPage = page;
        break;
      case "Day_book":
        this.displayPage = page;
        break;
      case "docappcreate":
        this.displayPage = page;
        break;
      case "Sales_report_pharma":
        this.displayPage = page;
        break;
      case "diag_bill_reports":
        this.displayPage = page;
        break;
      case "hosp_bill_reports":
        this.displayPage = page;
        break;
      case "docpresnocase":
        this.displayPage = page;
        break;
      case "receptviewprescription":
        this.displayPage = page;
        break;
      case "editpres":
        this.displayPage = page;
        break;
      case "viewpres":
        this.displayPage = page;
        break;
      case "DiagnosticDepartment":
        this.displayPage = page;
        break;
      case "DiagnosticReagent":
        this.displayPage = page;
        break;
      case "Accounthead":
        this.displayPage = page;
        break;
      case "AccountSubhead":
        this.displayPage = page;
        break;
      case "Stockexchange":
        this.displayPage = page;
        break;
      case "StoreName":
        this.displayPage = page;
        break;
      case "GenericName":
        this.displayPage = page;
        break;
      case "ScheduleName":
        this.displayPage = page;
        break;
      case "ProductForm":
        this.displayPage = page;
        break;
      case "Sub-Deparmentc":
        this.displayPage = page;
        break;
      case "addtemplate":
        this.displayPage = page;
        break;
      case "rateCard":
        this.displayPage = page;
        break;
      case "visitpurpose":
        this.displayPage = page;
        break;
      case "UserRole":
        this.displayPage = page;
        break;
      case "CultureSensitivityDrugName":
        this.displayPage = page;
        break;
      case "reference_video":
        this.displayPage = page;
        break;
      case "specimenaccessioner":
        this.displayPage = page;
        break;
      case "ConductionDoctor":
        this.displayPage = page;
        break;
      case "Surgery_equipments":
        this.displayPage = page;
        break;
      case "patient_list":
        this.displayPage = page;
        break;
      case "manageclient":
        this.displayPage = page;
        break;
      case "discharge_timeline":
        this.displayPage = page;
        break;
      case "concernform":
        this.displayPage = page;
        break;
      case "other_expenses":
        this.displayPage = page;
        break;
      case "home":
        this.displayPage = "home";
        break;
      case "other_expenses":
        this.displayPage = page;
        break;
      case "consingmentlist":
        this.displayPage = page;
        break;
      case "other_expenses":
        this.displayPage = page;
        break;
      case "Day_book":
        this.displayPage = page;
        break;
      case "Day_book":
        this.displayPage = page;
        break;
      case "stockmanagment":
        this.submenuFlag1 = !this.submenuFlag1;
        if (this.minimizedAsied == true) {
          this.minimizing();
        }
        break;
      case "inventory_receivables":
        this.displayPage = page;
        break;
      case "supppay":
        this.displayPage = page;
        break;
      case "pharmacyresponse":
        this.displayPage = page;
        break;
      case "orderview":
        this.displayPage = page;
        break;
      case "sales_report":
        this.displayPage = page;
        break;
      case "receivables_report":
        this.displayPage = page;
        break;
      case "reports":
        this.reportsSubmenuFlag = !this.reportsSubmenuFlag;
        if (this.minimizedAsied == true) {
          this.minimizing();
        }
        break;
      case "inPat_report":
        this.displayPage = page;
        break;
      case "sales_report":
        this.displayPage = page;
        break;
      case "receivables_report":
        this.displayPage = page;
        break;
      case "inPat_report":
        this.displayPage = page;
        break;
      case "receivables_report":
        this.displayPage = page;
        break;
      case "stock_status":
        this.displayPage = page;
        break;
      case "purchase_report":
        this.displayPage = page;
        break;
      case "salesGst":
        this.displayPage = page;
        break;
      case "notification":
        this.displayPage = page;
        break;
      case "home":
          this.displayPage = "home";
          break;
      case "notification":
        this.displayPage = page;
        break;
      case "appcreate":
        this.displayPage = page;
        break;
      case "receptionistdoctordetailview":
        this.displayPage = page;
        break;
      case "receptviewprescription":
        this.displayPage = page;
        break;
      case "frontdeskbilling":
        var send = {
          flow: "front-desk",
          id: this.userInfo.user_id,
        };
        Helper_Class.setadminBillingFlow(send);
        this.displayPage = page;
        break;
      case "receptbillcreate":
        this.displayPage = page;
        break;
      case "notification":
        this.displayPage = page;
        break;
      case "receptbilledit":
        this.displayPage = page;
        break;
      case "receptbilldetail":
        this.displayPage = page;
        break;
      case "registration":
        this.displayPage = page;
        break;
      case "patient_list":
        this.displayPage = page;
        break;
      case "permission":
        this.permissionSubmenuFlag = !this.permissionSubmenuFlag;
        if (this.minimizedAsied == true) {
          this.minimizing();
        }
        break;
      case "billing":
        this.billingFlag = !this.billingFlag;
        if (this.minimizedAsied == true) {
          this.minimizing();
        }
        break;
      case "advances":
        this.displayPage = page;
        break;
      case "bills":
        this.displayPage = page;
        break;
      case "billingDiagDetailsView":
        this.displayPage = page;
        break;
      case "billingDetailsView":
        this.displayPage = page;
        break;
      case "charges":
        this.displayPage = page;
        break;
      case "refund":
        this.displayPage = page;
        break;
      case "createRefund":
        this.displayPage = page;
        break;
      case "Patients":
        this.displayPage = page;
        break;
      case "inPatientCreate":
        this.displayPage = page;
        break;
      case "consultant":
        this.displayPage = page;
        break;
      case "diaBillCreate":
        this.displayPage = page;
        break;
      case "diaAppDetail":
        this.displayPage = page;
        break;
      case "diaAppCreate":
        this.displayPage = page;
        break;
      case "warddetails":
        this.displayPage = page;
        break;
      case "viewIpatient":
        this.displayPage = page;
        break;
      case "diaBillingDetailsView":
        this.displayPage = page;
        break;
      case "ipatientBillCreate":
        this.displayPage = page;
        break;
      case "frontConsolidatedBills":
        this.displayPage = page;
        break;
      case "dietappointments":
        Diet_Helper.setAppFlow(undefined);
        this.displayPage = page;
        break;
      case "dietappointmentsdetailed":
        this.displayPage = page;
        break;
      case "dietappointmentscreate":
        this.displayPage = page;
        break;
      case "reports":
        this.reportsSubmenuFlag = !this.reportsSubmenuFlag;
        if (this.minimizedAsied == true) {
          this.minimizing();
        }
        break;
      case "stock_status":
        this.reportsSubmenuFlag = false;
        this.displayPage = page;
        break;
      case "hosp_bill_reports":
        this.reportsSubmenuFlag = false;
        this.displayPage = page;
        break;
      case "diag_bill_reports":
        this.reportsSubmenuFlag = false;
        this.displayPage = page;
        break;
      case "reference_video":
        this.displayPage = page;
        break;
      case "listPackage":
        this.displayPage = page;
        break;
      case "planpackage":
        this.displayPage = page;
        break;
      case "createPackage":
        this.displayPage = page;
        break;
      case "inpdischargeview":
        this.displayPage = page;
        break;
      case "inPatientDS":
        this.displayPage = page;
        break;
      case "docref":
        this.displayPage = page;
        break;
      case "docrefcreate":
        this.displayPage = page;
        break;
      case "medprescription":
        this.displayPage = page;
        break;
      case "leadmgmt":
        this.displayPage = page;
        break;
      case "partialPayments":
        this.displayPage = page;
        break;
      case "insuranceBill":
        this.displayPage = page;
        break;
      case "manageclient":
        this.displayPage = page;
        break;
      case "sales_report_doctor":
        this.reportsSubmenuFlag = false;
        this.displayPage = page;
        break;
      case "bill_refund":
        this.reportsSubmenuFlag = false;
        this.displayPage = page;
        break;
      case "bill_due":
        this.reportsSubmenuFlag = false;
        this.displayPage = page;
        break;
      case "bed_occupancy":
        this.reportsSubmenuFlag = false;
        this.displayPage = page;
        break;
      case "abc_analysis":
        this.reportsSubmenuFlag = false;
        this.displayPage = page;
        break;
      case "purchase_order":
        this.reportsSubmenuFlag = false;
        this.displayPage = page;
        break;
      case "purchase_report":
        this.reportsSubmenuFlag = false;
        this.displayPage = page;
        break;
      case "product":
        this.reportsSubmenuFlag = false;
        this.displayPage = page;
        break;
      case "product_receivables":
        this.reportsSubmenuFlag = false;
        this.displayPage = page;
        break;
      case "lab_test_wise":
        this.reportsSubmenuFlag = false;
        this.displayPage = page;
        break;
      case "notvisited_report":
        this.reportsSubmenuFlag = false;
        this.displayPage = page;
        break;
      case "inpat_reports":
        this.reportsSubmenuFlag = false;
        this.displayPage = page;
        break;
      case "inPat_report":
        this.reportsSubmenuFlag = false;
        this.displayPage = page;
        break;
      case "Day_book":
        this.reportsSubmenuFlag = false;
        this.displayPage = page;
        break;
      case "consolidat_report":
        this.reportsSubmenuFlag = false;
        this.displayPage = page;
        break;
      case "appedit":
        this.displayPage = page;
        break;
      case "concent_form":
        this.displayPage = page;
        break;
      case "DiagnosticDepartment":
        this.displayPage = page;
        break;
      case "DiagnosticReagent":
        this.displayPage = page;
        break;
      case "Accounthead":
        this.displayPage = page;
        break;
      case "AccountSubhead":
        this.displayPage = page;
        break;
      case "Stockexchange":
        this.displayPage = page;
        break;
      case "StoreName":
        this.displayPage = page;
        break;
      case "GenericName":
        this.displayPage = page;
        break;
      case "ScheduleName":
        this.displayPage = page;
        break;
      case "ProductForm":
        this.displayPage = page;
        break;
      case "RateCard":
        this.displayPage = page;
        break;
      case "patreg":
        this.displayPage = page;
        break;
      case "addtemplate":
        this.displayPage = page;
        break;
      case "gramstains":
        this.displayPage = page;
        break;
      case "visitpurpose":
        this.displayPage = page;
        break;
      case "UserRole":
        this.displayPage = page;
        break;
      case "specimenaccessioner":
        this.displayPage = page;
        break;
      case "ConductionDoctor":
        this.displayPage = page;
        break;
      case "Surgery_equipments":
        this.displayPage = page;
        break;
      case "dietTracker":
        this.displayPage = page;
        break;
      case "discharge_timeline":
        this.displayPage = page;
        break;
      case "other_expenses":
        this.displayPage = page;
        break;
      // stock management
      case "stockmanagment":
        this.submenuFlag1 = !this.submenuFlag1;
        if (this.minimizedAsied == true) {
          this.minimizing();
        }
        break;
      case "diagmanufacturer":
        this.displayPage = page;
        break;
      case "srl":
        this.displayPage = page;
        break;
      case "pharmacysupplier":
        this.displayPage = page;
        break;
      case "supplierProduct":
        this.displayPage = page;
        break;
      case "frontproduct":
        this.displayPage = page;
        break;
      case "purchasereqlist":
        this.displayPage = page;
        break;
      case "pharmacyresponse":
        this.displayPage = page;
        break;
      case "inventory_receivables":
        this.displayPage = page;
        break;
      case "stockStatusAdmin":
        this.displayPage = page;
        break;
      case "productReturns":
        this.displayPage = page;
        break;
      case "supppay":
        this.displayPage = page;
        break;
      case "stockadjustment":
        this.displayPage = page;
        break;
      case "departwise":
        this.displayPage = page;
        break;
      case "rackbin":
        this.displayPage = page;
        break;
      case "pharmacyrequest":
        this.displayPage = page;
        break;
      case "orderview":
        this.displayPage = page;
        break;
      case "inventory_upload":
        this.displayPage = page;
        break;

      case "timesyst":
        this.timesystflag = !this.timesystflag;
        if (this.minimized_asied == true) {
          this.minimizing();
        }
        break;
      case "timeshift":
        this.displayPage = page;
        break;
      case "timeshiftview":
        this.displayPage = page;
        break;
      case "shiftchange":
        this.displayPage = page;
        break;
      case "alternateshift":
        this.displayPage = page;
        break;
      case "shiftallotdet":
        this.displayPage = page;
        break;
      case "app_report":
        this.displayPage = page;
        break;
      case "notification":
          this.displayPage = page;
          break;
      case "diagnosis_home":
        this.displayPage = page;
        break;
      case "appointment":
        this.displayPage = page;
        break;
      case "tests":
        this.displayPage = page;
        break;
      case "path_report_upload":
        this.pathSubMenuFlag = !this.pathSubMenuFlag;
        if (this.minimizedAsiedFlag == true) {
          this.minimizing();
        }
        break;
      case "path_samplecollectADV":
        this.pathSubMenuFlag = false;
        this.displayPage = page;
        break;
      case "pathpending":
        this.pathSubMenuFlag = false;
        this.displayPage = page;
        break;
      case "pathrejected":
        this.pathSubMenuFlag = false;
        this.displayPage = page;
        break;
      case "pathcompleted":
        this.pathSubMenuFlag = false;
        this.displayPage = page;
        break;
      case "radio_report_upload":
        this.radioSubMenuFlag = !this.radioSubMenuFlag;
        if (this.minimizedAsiedFlag == true) {
          this.minimizing();
        }
        break;
      case "radio_samplecollectADV":
        this.radioSubMenuFlag = false;
        this.displayPage = page;
        break;
      case "radiopending":
        this.radioSubMenuFlag = false;
        this.displayPage = page;
        break;
      case "radiorejected":
        this.radioSubMenuFlag = false;
        this.displayPage = page;
        break;
      case "radiocompleted":
        this.radioSubMenuFlag = false;
        this.displayPage = page;
        break;
      case "micro_report_upload":
        this.microSubMenuFlag = !this.microSubMenuFlag;
        if (this.minimizedAsiedFlag == true) {
          this.minimizing();
        }
        break;
      case "micro_samplecollectADV":
        this.pathSubMenuFlag = false;
        this.displayPage = page;
        break;
      case "micropending":
        this.microSubMenuFlag = false;
        this.displayPage = page;
        break;
      case "microrejected":
        this.microSubMenuFlag = false;
        this.displayPage = page;
        break;
      case "microcompleted":
        this.microSubMenuFlag = false;
        this.displayPage = page;
        break;
      case "report_approval":
        this.repAppSubFlag = !this.repAppSubFlag;
        if (this.minimizedAsiedFlag == true) {
          this.minimizing();
        }
        break;
      case "report_approval_pathology":
        this.pathSubMenuFlag = false;
        this.displayPage = page;
        break;
      case "report_approval_radiology":
        this.radioSubMenuFlag = false;
        this.displayPage = page;
        break;
      case "report_approval_microbiology":
        this.microSubMenuFlag = false;
        this.displayPage = page;
        break;

      case "report_upload_edit":
        this.displayPage = page;
        break;
      case "report_upload_view":
        this.displayPage = page;
        break;
      case "report_approval":
        this.displayPage = page;
        break;
      case "nephrology_readings":
        this.displayPage = page;
        break;
      case "diabetics_readings":
        this.displayPage = page;
        break;
      case "billing":
        this.displayPage = page;
        break;
      case "billingextender":
        this.billingFlag = !this.billingFlag;
        if (this.minimizedAsied == true) {
          this.minimizing();
        }
        break;
      case "billinglist":
        this.displayPage = page;
        break;
      case "users":
        this.displayPage = page;
        break;
      case "help":
        this.displayPage = page;
        break;
      case "diagnosisview":
        this.displayPage = page;
        break;
      case "nephrologyreadingviewpage":
        this.displayPage = page;
        break;
      case "users_view":
        this.displayPage = page;
        break;
      case "report_approval_detailed":
        this.displayPage = page;
        break;
      case "diagtestadd":
        this.displayPage = page;
        break;
      case "diagtestaddadv":
        this.displayPage = page;
        break;
      case "diagbilldetailview":
        this.displayPage = page;
        break;
      case "diagbillcreate":
        this.displayPage = page;
        break;
      case "diag_app_create":
        this.displayPage = page;
        break;
      case "stockmanagment":
        this.submenuFlag1 = !this.submenuFlag1;
        if (this.minimizedAsied == true) {
          this.minimizing();
        }
        break;
      case "diagmanufacturer":
        this.displayPage = page;
        break;
      case "srl":
        this.displayPage = page;
        break;
      case "pharmacysupplier":
        this.displayPage = page;
        break;
      case "supplierProduct":
        this.displayPage = page;
        break;
      case "product":
        this.displayPage = page;
        break;
      case "purchasereqlist":
        this.displayPage = page;
        break;
      case "pharmacyresponse":
        this.displayPage = page;
        break;
      case "inventory_receivables":
        this.displayPage = page;
        break;
      case "stockStatusAdmin":
        this.displayPage = page;
        break;
      case "productReturns":
        this.displayPage = page;
        break;
      case "supppay":
        this.displayPage = page;
        break;
      case "stockadjustment":
        this.displayPage = page;
        break;
      case "rackbin":
        this.displayPage = page;
        break;
      case "pharmacyrequest":
        this.displayPage = page;
        break;
      case "orderview":
        this.displayPage = page;
        break;
      case "inventory_upload":
        this.displayPage = page;
        break;
      case "reports":
        this.reportSubmenuFlag = !this.reportSubmenuFlag;
        if (this.minimizedAsied == true) {
          this.minimizing();
        }
        break;
      case "stock_status":
        this.displayPage = page;
        break;
      case "sales_report":
        this.displayPage = page;
        break;
      case "purchase_report":
        this.displayPage = page;
        break;
      case "receivables_report":
        this.displayPage = page;
        break;
      case "productReturnsReport":
        this.displayPage = page;
        break;
      case "gsd":
        this.displayPage = page;
        break;
      case "SDI":
        this.displayPage = page;
        break;
      case "bed_occupancypharm":
        this.displayPage = page;
        break;
      case "abc_analysispharm":
        this.displayPage = page;
        break;
      case "shrtexpiry":
        this.displayPage = page;
        break;
      case "pastinwards":
        this.displayPage = page;
        break;
      case "dashboard":
        this.dashSubMenuFlag = !this.dashSubMenuFlag;
        if (this.minimizedAsiedFlag == true) {
          this.minimizing();
        }
        break;
      case "patient_list":
        this.dashSubMenuFlag = false;
        this.displayPage = page;
        break;
      case "reports":
        this.dashSubMenuFlag = false;
        this.displayPage = page;
        break;
      case "billedit":
        this.displayPage = page;
        break;
      case "diagPackageCreate":
        this.displayPage = page;
        break;
      case "appedit":
        this.displayPage = page;
        break;
      case "samplecollect":
        this.displayPage = page;
        break;
      case "samplecollectADV":
        this.displayPage = page;
        break;
      case "assertion":
        this.displayPage = page;
        break;
      case "outsource":
        this.outsubFlag = !this.outsubFlag;
        if (this.minimizedAsiedFlag == true) {
          this.minimizing();
        }
        break;
      case "out-centre-list":
        this.outsubFlag = false;
        this.displayPage = page;
        break;
      case "out-centre":
        this.outsubFlag = false;
        this.displayPage = page;
        break;
      case "out-reports":
        this.outsubFlag = false;
        this.displayPage = page;
        break;
      case "out-payments":
        this.outsubFlag = false;
        this.displayPage = page;
        break;
      case "out-payments-adv":
        this.outsubFlag = false;
        this.displayPage = page;
        break;
      case "out-tests":
        this.outsubFlag = false;
        this.displayPage = page;
        break;
      case "out-test-map":
        this.displayPage = page;
        break;
      case "partialPayments":
        this.displayPage = page;
        break;
      case "testsandpackadv":
        this, (this.displayPage = page);
        break;
      case "specimen-report-template":
        this.displayPage = page;
        break;
      case "DiagnosticDepartment":
        this.displayPage = page;
        break;
      case "DiagnosticReagent":
        this.displayPage = page;
        break;
      case "Accounthead":
        this.displayPage = page;
        break;
      case "AccountSubhead":
        this.displayPage = page;
        break;
      case "Stockexchange":
        this.displayPage = page;
        break;
      case "StoreName":
        this.displayPage = page;
        break;
      case "GenericName":
        this.displayPage = page;
        break;
      case "ScheduleName":
        this.displayPage = page;
        break;
      case "ProductForm":
        this.displayPage = page;
        break;
      case "gram_stains":
        this.displayPage = page;
        break;
      case "visit_purpose":
        this.displayPage = page;
        break;
      case "addtemplate":
        this.displayPage = page;
        break;
      case "specimenaccessioner":
        this.displayPage = page;
        break;
      case "ConductionDoctor":
        this.displayPage = page;
        break;
      case "conductionDoc":
        this.displayPage = page;
        break;
      case "Surgery_equipments":
        this.displayPage = page;
        break;
      case "patient_list":
        this.displayPage = page;
        break;
      case "manageclient":
        this.displayPage = page;
        break;
      case "other_expenses":
        this.displayPage = page;
        break;
      case "notification":
        this.displayPage = page;
        break;
      case "profile":
        this.displayPage = page;
        break;
      case "appointment":
        this.displayPage = page;
        break;
      case "appoinmentDetailView":
        this.displayPage = page;
        break;
      case "assignList":
        this.displayPage = page;
        break;
      case "assignDetailView":
        this.displayPage = page;
        break;
      case "doctorList":
        this.displayPage = page;
        break;
      case "inPatientViewInfo":
        this.displayPage = page;
        break;
      case "medicine_tracker":
        this.displayPage = page;
        break;
      case "DoctorAppDetail":
        this.displayPage = page;
        break;
      case "viewIpatient":
        this.displayPage = page;
        break;
      case "casesheet":
        this.displayPage = page;
        break;
      case "casesheet_list":
        this.displayPage = page;
        break;
      case "cardio_casesheet_view":
        this.displayPage = page;
        break;
      case "Patients":
        this.displayPage = page;
        break;
      case "warddetails":
        this.displayPage = page;
        break;
      case "DIpatientTracking":
        this.displayPage = page;
        break;
      case "dietTracker":
        this.displayPage = page;
        break;
      case "dietNewTracker":
        this.displayPage = page;
        break;
      case "specimen-report-template":
        this.displayPage = page;
        break;
      case "docpresnocase":
        this.displayPage = page;
        break;
      case "DiagnosticDepartment":
        this.displayPage = page;
        break;
      case "DiagnosticReagent":
        this.displayPage = page;
        break;
      case "Accounthead":
        this.displayPage = page;
        break;
      case "AccountSubhead":
        this.displayPage = page;
        break;
      case "Stockexchange":
        this.displayPage = page;
        break;
      case "StoreName":
        this.displayPage = page;
        break;
      case "GenericName":
        this.displayPage = page;
        break;
      case "ScheduleName":
        this.displayPage = page;
        break;
      case "ProductForm":
        this.displayPage = page;
        break;
      case "gram_stains":
        this.displayPage = page;
        break;
      case "visit_purpose":
        this.displayPage = page;
        break;
      case "addtemplate":
        this.displayPage = page;
        break;
      case "specimenaccessioner":
        this.displayPage = page;
        break;
      case "ConductionDoctor":
        this.displayPage = page;
        break;
      case "Surgery_equipments":
        this.displayPage = page;
        break;
      case "wardtall":
        this.displayPage = page;
        break;
      case "nursereports":
        this.displayPage = page;
        break;
      case "patient_list":
        this.displayPage = page;
        break;
      case "manageclient":
        this.displayPage = page;
        break;
      case "discharge_timeline":
        this.displayPage = page;
        break;
      case "dietaccess":
        this.displayPage = page;
        break;
      case "stockmanagment":
        this.submenuFlag1 = !this.submenuFlag1;
        if (this.minimizedAsied == true) {
          this.minimizing();
        }
        break;
      case "diagmanufacturer":
        this.displayPage = page;
        break;
      case "srl":
        this.displayPage = page;
        break;
      case "pharmacysupplier":
        this.displayPage = page;
        break;
      case "supplierProduct":
        this.displayPage = page;
        break;
      case "product":
        this.displayPage = page;
        break;
      case "purchasereqlist":
        this.displayPage = page;
        break;
      case "pharmacyresponse":
        this.displayPage = page;
        break;
      case "inventory_receivables":
        this.displayPage = page;
        break;
      case "stockStatusAdmin":
        this.displayPage = page;
        break;
      case "productReturns":
        this.displayPage = page;
        break;
      case "supppay":
        this.displayPage = page;
        break;
      case "stockadjustment":
        this.displayPage = page;
        break;
      case "rackbin":
        this.displayPage = page;
        break;
      case "pharmacyrequest":
        this.displayPage = page;
        break;
      case "orderview":
        this.displayPage = page;
        break;
      case "inventory_upload":
        this.displayPage = page;
        break;
      case "reports":
        this.reportSubmenuFlag = !this.reportSubmenuFlag;
        if (this.minimizedAsied == true) {
          this.minimizing();
        }
        break;
      case "stock_status":
        this.displayPage = page;
        break;
      case "sales_report":
        this.displayPage = page;
        break;
      case "receivables_report":
        this.displayPage = page;
        break;
      case "purchase_report":
        this.displayPage = page;
        break;
      case "productReturnsReport":
        this.displayPage = page;
        break;
      case "gsd":
        this.displayPage = page;
        break;
      case "SDI":
        this.displayPage = page;
        break;
      case "bed_occupancypharm":
        this.displayPage = page;
        break;
      case "abc_analysispharm":
        this.displayPage = page;
        break;
      case "shrtexpiry":
        this.displayPage = page;
        break;
      case "pastinwards":
        this.displayPage = page;
        break;
      case "other_expenses":
        this.displayPage = page;
        break;
      case "notification":
          this.displayPage = page;
          break;
      case "appointment":
        this.displayPage = page;
        break;
      case "casesheet":
        this.displayPage = page;
        break;
      case "assignmentList":
        this.displayPage = page;
        break;
      case "assignmentDetailPage":
        this.displayPage = page;
        break;
      case "therapies":
        this.displayPage = page;
        break;
      case "userList":
        this.displayPage = page;
        break;
      case "userViewPage":
        this.displayPage = page;
        break;
      case "Home":
        this.displayPage = page;
        break;
      case "billing":
        this.displayPage = page;
        break;
      case "billdetails":
        this.displayPage = page;
        break;
      case "billCreate":
        this.displayPage = page;
        break;
      case "DiagnosticDepartment":
        this.displayPage = page;
        break;
      case "DiagnosticReagent":
        this.displayPage = page;
        break;
      case "Accounthead":
        this.displayPage = page;
        break;
      case "AccountSubhead":
        this.displayPage = page;
        break;
      case "Stockexchange":
        this.displayPage = page;
        break;
      case "StoreName":
        this.displayPage = page;
        break;
      case "GenericName":
        this.displayPage = page;
        break;
      case "ScheduleName":
        this.displayPage = page;
        break;
      case "ProductForm":
        this.displayPage = page;
        break;
      case "specimenaccessioner":
        this.displayPage = page;
        break;
      case "ConductionDoctor":
        this.displayPage = page;
        break;
      case "Surgery_equipments":
        this.displayPage = page;
        break;
      case "patient_list":
        this.displayPage = page;
        break;
      case "manageclient":
        this.displayPage = page;
        break;
      case "discharge_timeline":
        this.displayPage = page;
        break;
      case "other_expenses":
        this.displayPage = page;
        break;
      case "stockmanagment":
        this.submenuFlag1 = !this.submenuFlag1;
        if (this.minimizedAsied == true) {
          this.minimizing();
        }
        break;
      case "diagmanufacturer":
        this.displayPage = page;
        break;
      case "srl":
        this.displayPage = page;
        break;
      case "pharmacysupplier":
        this.displayPage = page;
        break;
      case "supplierProduct":
        this.displayPage = page;
        break;
      case "product":
        this.displayPage = page;
        break;
      case "purchasereqlist":
        this.displayPage = page;
        break;
      case "pharmacyresponse":
        this.displayPage = page;
        break;
      case "inventory_receivables":
        this.displayPage = page;
        break;
      case "stockStatusAdmin":
        this.displayPage = page;
        break;
      case "productReturns":
        this.displayPage = page;
        break;
      case "supppay":
        this.displayPage = page;
        break;
      case "stockadjustment":
        this.displayPage = page;
        break;
      case "rackbin":
        this.displayPage = page;
        break;
      case "pharmacyrequest":
        this.displayPage = page;
        break;
      case "orderview":
        this.displayPage = page;
        break;
      case "inventory_upload":
        this.displayPage = page;
        break;
      case "reports":
        this.reportSubmenuFlag = !this.reportSubmenuFlag;
        if (this.minimizedAsied == true) {
          this.minimizing();
        }
        break;
      case "stock_status":
        this.displayPage = page;
        break;
      case "sales_report":
        this.displayPage = page;
        break;
      case "purchase_report":
        this.displayPage = page;
        break;
      case "receivables_report":
        this.displayPage = page;
        break;
      case "productReturnsReport":
        this.displayPage = page;
        break;
      case "gsd":
        this.displayPage = page;
        break;
      case "SDI":
        this.displayPage = page;
        break;
      case "bed_occupancypharm":
        this.displayPage = page;
        break;
      case "abc_analysispharm":
        this.displayPage = page;
        break;
      case "shrtexpiry":
        this.displayPage = page;
        break;
      case "pastinwards":
        this.displayPage = page;
        break;
      case "profile":
        this.displayPage = page;
        break;
      case "dietPlan":
        this.displayPage = page;
        break;
      case "dietPlancard":
        this.displayPage = page;
        break;
      case "appointment":
        this.displayPage = page;
        break;
      case "appt_create":
        this.displayPage = page;
        break;
      case "casesheet":
        this.displayPage = page;
        break;
      case "notification":
        this.displayPage = page;
        break;
      case "diet_dashboard":
        this.displayPage = page;
        break;
      case "patientList":
        Helper_Class.setDiagReadingList(null);
        Helper_Class.setDiagReadingList("dietList");
        this.displayPage = page;
        break;
      case "patientTracking":
        this.displayPage = page;
        break;
      case "billList":
        var setFlow = {
          flow: "dietcian",
          user_id: this.userInfo.user_id,
        };
        Helper_Class.setBillingFlow(null);
        Helper_Class.setBillingFlow(setFlow);
        this.displayPage = page;
        break;
      case "billCreate":
        this.displayPage = page;
        break;
      case "billdetail":
        this.displayPage = page;
        break;
      case "readings":
        Helper_Class.setDiagReadingList(null);
        Helper_Class.setDiagReadingList("readings");
        this.displayPage = page;
        break;
      case "diabReadings":
        this.displayPage = page;
        break;
      case "reference_video":
        this.displayPage = page;
        break;
      case "DiagnosticDepartment":
        this.displayPage = page;
        break;
      case "DiagnosticReagent":
        this.displayPage = page;
        break;
      case "Accounthead":
        this.displayPage = page;
        break;
      case "AccountSubhead":
        this.displayPage = page;
        break;
      case "Stockexchange":
        this.displayPage = page;
        break;
      case "StoreName":
        this.displayPage = page;
        break;
      case "GenericName":
        this.displayPage = page;
        break;
      case "ScheduleName":
        this.displayPage = page;
        break;
      case "ProductForm":
        this.displayPage = page;
        break;
      case "specimenaccessioner":
        this.displayPage = page;
        break;
      case "ConductionDoctor":
        this.displayPage = page;
        break;
      case "Surgery_equipments":
        this.displayPage = page;
        break;
      case "patient_list":
        this.displayPage = page;
        break;
      case "manageclient":
        this.displayPage = page;
        break;
      case "other_expenses":
        this.displayPage = page;
        break;
      case "stockmanagment":
        this.submenuFlag1 = !this.submenuFlag1;
        if (this.minimizedAsied == true) {
          this.minimizing();
        }
        break;
      case "diagmanufacturer":
        this.displayPage = page;
        break;
      case "srl":
        this.displayPage = page;
        break;
      case "pharmacysupplier":
        this.displayPage = page;
        break;
      case "supplierProduct":
        this.displayPage = page;
        break;
      case "product":
        this.displayPage = page;
        break;
      case "purchasereqlist":
        this.displayPage = page;
        break;
      case "pharmacyresponse":
        this.displayPage = page;
        break;
      case "inventory_receivables":
        this.displayPage = page;
        break;
      case "stockStatusAdmin":
        this.displayPage = page;
        break;
      case "productReturns":
        this.displayPage = page;
        break;
      case "supppay":
        this.displayPage = page;
        break;
      case "stockadjustment":
        this.displayPage = page;
        break;
      case "rackbin":
        this.displayPage = page;
        break;
      case "pharmacyrequest":
        this.displayPage = page;
        break;
      case "orderview":
        this.displayPage = page;
        break;
      case "inventory_upload":
        this.displayPage = page;
        break;
      case "reports":
        this.reportSubmenuFlag = !this.reportSubmenuFlag;
        if (this.minimizedAsied == true) {
          this.minimizing();
        }
        break;
      case "stock_status":
        this.displayPage = page;
        break;
      case "sales_report":
        this.displayPage = page;
        break;
      case "purchase_report":
        this.displayPage = page;
        break;
      case "receivables_report":
        this.displayPage = page;
        break;
      case "productReturnsReport":
        this.displayPage = page;
        break;
      case "gsd":
        this.displayPage = page;
        break;
      case "SDI":
        this.displayPage = page;
        break;
      case "bed_occupancypharm":
        this.displayPage = page;
        break;
      case "abc_analysispharm":
        this.displayPage = page;
        break;
      case "shrtexpiry":
        this.displayPage = page;
        break;
      case "pastinwards":
        this.displayPage = page;
        break;
      case "notification":
          this.displayPage = page;
          break;
      case "appointment":
        this.displayPage = page;
        break;
      case "frontAdvances":
        this.displayPage = page;
        break;
      case "chargesTYPE":
        this.displayPage = page;
        break;
      case "Frontrefund":
        this.displayPage = page;
        break;
      case "frontAdminConsolidatedBills":
        this.displayPage = page;
        break;
      case "frontpartialPayments":
        this.displayPage = page;
        break;
      case "FrontAdmininsuranceBill":
        this.displayPage = page;
        break;
      case "billingDetailsView":
        this.displayPage = page;
        break;
      case "billingDiagDetailsView":
        this.displayPage = page;
        break;
      case "ipatientBillCreate":
        this.displayPage = page;
        break;
      case "adminReceptbillcreate":
        this.displayPage = page;
        break;
      case "dietappointments":
        Diet_Helper.setAppFlow(undefined);
        this.displayPage = page;
        break;
      case "dietappointmentsdetailed":
        this.displayPage = page;
        break;
      case "dietappointmentscreate":
        this.displayPage = page;
        break;
      case "frontdeskbilling":
        var send = {
          flow: "Billing",
          id: this.userInfo.user_id,
        };
        Helper_Class.setadminBillingFlow(send);
        this.displayPage = page;
        break;
      case "appcreate":
        this.displayPage = page;
        break;
      case "receptionistdoctordetailview":
        this.displayPage = page;
        break;
      case "receptviewprescription":
        this.displayPage = page;
        break;
      case "frontdeskbilling":
        var send = {
          flow: "front-desk",
          id: this.userInfo.user_id,
        };
        Helper_Class.setadminBillingFlow(send);
        this.displayPage = page;
        break;
      case "receptbillcreate":
        this.displayPage = page;
        break;
      case "receptbilledit":
        this.displayPage = page;
        break;
      case "receptbilldetail":
        this.displayPage = page;
        break;
      case "registration":
        this.displayPage = page;
        break;
      case "patient_list":
        this.displayPage = page;
        break;
      case "permission":
        this.permissionSubmenuFlag = !this.permissionSubmenuFlag;
        if (this.minimizedAsied == true) {
          this.minimizing();
        }
        break;
      case "billing":
        this.billingFlag = !this.billingFlag;
        if (this.minimizedAsied == true) {
          this.minimizing();
        }
        break;
      case "advances":
        this.displayPage = page;
        break;
      case "bills":
        this.displayPage = page;
        break;
      case "billingDetailsView":
        this.displayPage = page;
        break;
      case "charges":
        this.displayPage = page;
        break;
      case "refund":
        this.displayPage = page;
        break;
      case "createRefund":
        this.displayPage = page;
        break;
      case "inPatientCreate":
        this.displayPage = page;
        break;
      case "consultant":
        this.displayPage = page;
        break;
      case "diaBillCreate":
        this.displayPage = page;
        break;
      case "diaAppDetail":
        this.displayPage = page;
        break;
      case "diaAppCreate":
        this.displayPage = page;
        break;
      case "viewIpatient":
        this.displayPage = page;
        break;
      case "diaBillingDetailsView":
        this.displayPage = page;
        break;
      case "frontConsolidatedBills":
        this.displayPage = page;
        break;
      case "dietappointments":
        Diet_Helper.setAppFlow(undefined);
        this.displayPage = page;
        break;
      case "dietappointmentsdetailed":
        this.displayPage = page;
        break;
      case "dietappointmentscreate":
        this.displayPage = page;
        break;
      case "reports":
        this.reportsSubmenuFlag = !this.reportsSubmenuFlag;
        if (this.minimizedAsied == true) {
          this.minimizing();
        }
        break;
      case "hosp_bill_reports":
        this.reportsSubmenuFlag = false;
        this.displayPage = page;
        break;
      case "diag_bill_reports":
        this.reportsSubmenuFlag = false;
        this.displayPage = page;
        break;
      case "reference_video":
        this.displayPage = page;
        break;
      case "listPackage":
        this.displayPage = page;
        break;
      case "createPackage":
        this.displayPage = page;
        break;
      case "inpdischargeview":
        this.displayPage = page;
        break;
      case "discharge_timeline":
        this.displayPage = page;
        break;
      case "inPatientDS":
        this.displayPage = page;
        break;
      case "docref":
        this.displayPage = page;
        break;
      case "docrefcreate":
        this.displayPage = page;
        break;
      case "medprescription":
        this.displayPage = page;
        break;
      case "leadmgmt":
        this.displayPage = page;
        break;
      case "partialPayments":
        this.displayPage = page;
        break;
      case "insuranceBill":
        this.displayPage = page;
        break;
      case "manageclient":
        this.displayPage = page;
        break;
      case "sales_report_doctor":
        this.reportsSubmenuFlag = false;
        this.displayPage = page;
        break;
      case "bill_refund":
        this.reportsSubmenuFlag = false;
        this.displayPage = page;
        break;
      case "bill_due":
        this.reportsSubmenuFlag = false;
        this.displayPage = page;
        break;
      case "bed_occupancy":
        this.reportsSubmenuFlag = false;
        this.displayPage = page;
        break;
      case "abc_analysis":
        this.reportsSubmenuFlag = false;
        this.displayPage = page;
        break;
      case "purchase_order":
        this.reportsSubmenuFlag = false;
        this.displayPage = page;
        break;
      case "product":
        this.reportsSubmenuFlag = false;
        this.displayPage = page;
        break;
      case "product_receivables":
        this.reportsSubmenuFlag = false;
        this.displayPage = page;
        break;
      case "lab_test_wise":
        this.reportsSubmenuFlag = false;
        this.displayPage = page;
        break;
      case "notvisited_report":
        this.reportsSubmenuFlag = false;
        this.displayPage = page;
        break;
      case "consolidat_report":
        this.reportsSubmenuFlag = false;
        this.displayPage = page;
        break;
      case "inpat_reports":
        this.reportsSubmenuFlag = false;
        this.displayPage = page;
        break;
      case "inPat_report":
        this.reportsSubmenuFlag = false;
        this.displayPage = page;
        break;
      case "Day_book":
        this.reportsSubmenuFlag = false;
        this.displayPage = page;
        break;
      case "appedit":
        this.displayPage = page;
        break;
      case "concent_form":
        this.displayPage = page;
        break;
      case "DiagnosticDepartment":
        this.displayPage = page;
        break;
      case "DiagnosticReagent":
        this.displayPage = page;
        break;
      case "Accounthead":
        this.displayPage = page;
        break;
      case "AccountSubhead":
        this.displayPage = page;
        break;
      case "Stockexchange":
        this.displayPage = page;
        break;
      case "StoreName":
        this.displayPage = page;
        break;
      case "GenericName":
        this.displayPage = page;
        break;
      case "ScheduleName":
        this.displayPage = page;
        break;
      case "ProductForm":
        this.displayPage = page;
        break;
      case "RateCard":
        this.displayPage = page;
        break;
      case "specimenaccessioner":
        this.displayPage = page;
        break;
      case "ConductionDoctor":
        this.displayPage = page;
        break;
      case "Surgery_equipments":
        this.displayPage = page;
        break;
      case "chargetype":
        this.displayPage = page;
        break;
      case "consolidat_reportadmin":
        this.displayPage = page;
        break;
      case "notvisited_reportadmin":
        this.displayPage = page;
        break;
      case "stock_report":
        this.displayPage = page;
        break;
      case "IPR":
        this.displayPage = page;
        break;
      case "IR":
        this.displayPage = page;
        break;
      case "HBR":
        Helper_Class.setReportType(null);
        var sendData = {
          flow: "phrama",
          report_type: page,
          pharmacyID: this.pharmacyID,
        };
        Helper_Class.setReportType(sendData);
        this.displayPage = page;
        break;
      case "DBR":
        this.displayPage = page;
        break;
      case "SDI":
        this.displayPage = page;
        break;
      case "gsd":
        this.displayPage = page;
        break;
      case "stockReceivable":
        this.displayPage = page;
        break;
      case "salesGst":
        this.displayPage = page;
        break;
      case "purchase_report":
        this.displayPage = page;
        break;
      case "sales_report":
        this.displayPage = page;
        break;
      case "patient_list":
        this.displayPage = page;
        break;
      case "manageclient":
        this.displayPage = page;
        break;
      case "reporter":
        this.submenuFlagb = !this.submenuFlagb;
        if (this.minimized_asied == true) {
          this.minimizing();
        }
        break;
      case "other_expenses":
        this.displayPage = page;
        break;
      case "inpatientbilldetail":
        this.displayPage = page;
        break;
      case "inpatientbilledit":
        this.displayPage = page;
        break;
      case "pharmacyhome":
        this.displayPage = "pharmacyhome";
        break;
      case "patient":
        this.submenuFlag = !this.submenuFlag;
        if (this.minimizedAsied == true) {
          this.minimizing();
        }
        break;
      case "stockmanagment":
        this.submenuFlag1 = !this.submenuFlag1;
        if (this.minimizedAsied == true) {
          this.minimizing();
        }
        break;
      case "notification":
        this.displayPage = page;
        break;
      case "pharmacysupplier":
        this.submenuFlag1 = false;
        this.displayPage = page;
        break;
      case "discounts":
        this.submenuFlag1 = false;
        this.displayPage = page;
        break;
      case "product":
        this.submenuFlag1 = false;
        this.displayPage = page;
        break;
      case "pharmacyrequest":
        this.submenuFlag1 = false;
        this.displayPage = page;
        break;
      case "pharmacyresponse":
        this.submenuFlag1 = false;
        this.displayPage = page;
        break;
      case "inventory_upload":
        this.submenuFlag1 = false;
        this.displayPage = page;
        break;
      case "inventory_receivables":
        this.submenuFlag1 = false;
        this.displayPage = page;
        break;
      case "stockStatusAdmin":
        this.submenuFlag1 = false;
        this.displayPage = page;
        break;
      case "reports":
        this.reportSubmenuFlag = !this.reportSubmenuFlag;
        if (this.minimizedAsied == true) {
          this.minimizing();
        }
        break;
      case "stock_status":
        this.reportSubmenuFlag = false;
        this.displayPage = page;
        break;
      case "sales_report":
        this.reportSubmenuFlag = false;
        this.displayPage = page;
        break;
      case "purchase_report":
        this.reportSubmenuFlag = false;
        this.displayPage = page;
        break;
      case "receivables_report":
        this.reportSubmenuFlag = false;
        this.displayPage = page;
        break;
      case "pharmaracksbins":
        this.reportSubmenuFlag = false;
        this.displayPage = page;
        break;
      case "productReturnsReport":
        this.reportSubmenuFlag = false;
        this.displayPage = page;
        break;
      case "weightflag":
        this.reportsSubmenuFlag = false;
        this.displayPage = page;
        break;
        case "surgery_doc":
          this.reportsSubmenuFlag = false;
          this.displayPage = page;
          break;
        Helper_Class.setReportType(null);
        sendData = {
          flow: "phrama",
          report_type: "returnsReport",
          pharmacyID: this.pharmacyID,
        };
        Helper_Class.setReportType(sendData);
        this.displayPage = page;
        break;
      case "ordercreate":
        this.displayPage = page;
        break;
      case "referral":
        this.displayPage = page;
        break;
      case "filterpage":
        this.displayPage = page;
        break;
      case "help":
        this.displayPage = page;
        break;
      case "pharmacycurentinformation":
        this.submenuFlag = false;
        this.displayPage = page;
        break;
      case "pharmacypastinformation":
        this.submenuFlag = false;
        this.displayPage = page;
        break;
      case "pharmacymoduleorderdetailview":
        this.submenuFlag = false;
        this.displayPage = page;
        break;
      case "orderview":
        this.submenuFlag = false;
        this.displayPage = page;
        break;
      case "inward":
        this.displayPage = page;
        break;
      case "pastinwards":
        this.displayPage = page;
        break;
      case "shrtexpiry":
        this.displayPage = page;
        break;
      case "phamratimeline":
        this.displayPage = page;
        break;
      case "ipWriteRx":
        this.displayPage = page;
        break;
      case "manufacturer":
        this.displayPage = page;
        break;
      case "srl":
        this.displayPage = page;
        break;
      case "supplierProduct":
        this.displayPage = page;
        break;
      case "productReturns":
        this.displayPage = page;
        break;
      case "interdept":
        this.displayPage = page;
        break;
      case "interdeptlist":
        this.displayPage = page;
        break;
      case "supppay":
        this.displayPage = page;
        break;
      case "rackbin":
        this.displayPage = page;
        break;
      case "pharmasettings":
        this.displayPage = page;
        break;
      case "purchasereqlist":
        this.displayPage = page;
        break;
      case "billestimate":
        this.displayPage = page;
        break;
      case "reference_video":
        this.displayPage = page;
        break;
      case "bed_occupancypharm":
        this.displayPage = page;
        break;
      case "abc_analysispharm":
        this.displayPage = page;
        break;
      case "DiagnosticDepartment":
        this.displayPage = page;
        break;
      case "DiagnosticReagent":
        this.displayPage = page;
        break;
      case "Accounthead":
        this.displayPage = page;
        break;
      case "AccountSubhead":
        this.displayPage = page;
        break;
      case "Stockexchange":
        this.displayPage = page;
        break;
      case "StoreName":
        this.displayPage = page;
        break;
      case "GenericName":
        this.displayPage = page;
        break;
      case "pharmaratecard":
        this.displayPage = page;
        break;
      case "ScheduleName":
        this.displayPage = page;
        break;
      case "ProductForm":
        this.displayPage = page;
        break;
      case "Sub-Deparmentc":
        this.displayPage = page;
        break;
      case "addtemplate":
        this.displayPage = page;
        break;
      case "rateCard":
        this.displayPage = page;
        break;
      case "visitpurpose":
        this.displayPage = page;
        break;
      case "UserRole":
        this.displayPage = page;
        break;
      case "CultureSensitivityDrugName":
        this.displayPage = page;
        break;
      case "reference_video":
        this.displayPage = page;
        break;
      case "specimenaccessioner":
        this.displayPage = page;
        break;
      case "ConductionDoctor":
        this.displayPage = page;
        break;

      case "Surgery_equipments":
        this.displayPage = page;
        break;
      case "stockadjustment":
        this.displayPage = page;
        break;
      case "patient_list":
        this.displayPage = page;
        break;
      case "manageclient":
        this.displayPage = page;
        break;
      case "patreg":
        this.displayPage = page;
        break;
      case "gsd":
        this.displayPage = page;
        break;
      case "SDI":
        this.displayPage = page;
        break;
      case "other_expenses":
        this.displayPage = page;
        break;
      case "consing":
        this.displayPage = page;
        break;
      case 'authorize_list': 
        this.displayPage = page;
        break;
        case 'log_rep': 
        this.displayPage = page;
        break;
        case 'pharma_log_rep': 
        this.displayPage = page;
        break;
        case 'diag_log_rep': 
        this.displayPage = page;
        break;
        case 'cond_log_rep': 
        this.displayPage = page;
        break;
        case 'out_log_rep': 
        this.displayPage = page;
        break;
        case 'Diag_test': 
        this.displayPage = page;
        break;
        case 'Diag_count': 
        this.displayPage = page;
        break;
        case 'revenue_rep': 
        this.displayPage = page;
        break;
        case 'stock_adjust': 
        this.displayPage = page;
        break;

      case 'tatreports':
        this.tatsubmenuFlag1 = !this.tatsubmenuFlag1;
        if (this.minimized_asied == true) {
          this.minimizing();
        }
        break;
      case 'hospitaltat': 
        this.displayPage = page; 
        break;
      case 'diagnosistat': 
        this.displayPage = page; 
        break;
      case 'pharmasistat': 
        this.displayPage = page; 
        break;
      case 'Sub-Deparmentc': 
        this.displayPage = page; 
        break;
      case 'pharmacy_ordercreate':
        this.submenuFlag = !this.submenuFlag;
        if (this.minimizedAsied == true) {
          this.minimizing();
        }
        break;
    }
    customjs.SelectMenuFun(this.selectedMenu, this.loginType);
  }

  public submenuFlag1: boolean = false;
  public submenu_flag: boolean = false;

  minimizing() {
    this.minimized_asied = !this.minimized_asied;
    if (this.minimized_asied == true) {
      this.asied_width.nativeElement.setAttribute(
        "style",
        "width:3%; transition: all 0.3s ease-in-out;animation-delay: 15s;"
      );
      this.right_width.nativeElement.setAttribute(
        "style",
        "width:97%;margin-left: 3%; transition: all 0.3s ease-in-out;animation-delay: 15s;"
      );
      this.logo.nativeElement.setAttribute("style", "padding: 12px 0 0 3px;}");
      this.docInpatSubmenuFlag = false;
      this.Docsubmenu_flag = false;
      this.fullMenu = false;
      this.daig_mainMenu = false;
      this.physiosubFlagsub = false;
      this.nurseMainMenusub = false;
      this.dietiMainMenusub = false;
      this.manageOrders = false;
      this.tatsubmenuFlag1 = false;
      this.DrugsNameFlag = false;
      this.submenuFlag1 = false;
      this.submenu_flag = false;
      this.billingFlag = false;
      this.submenuFlagb = false;
      this.reportsSubmenuFlag = false;
    } else {
      this.asied_width.nativeElement.setAttribute(
        "style",
        "width:17%; transition: all 0.3s ease-in-out;animation-delay: 15s;"
      );
      this.right_width.nativeElement.setAttribute(
        "style",
        "width:83%;margin-left: 17%; transition: all 0.3s ease-in-out;animation-delay: 15s;"
      );
      this.logo.nativeElement.setAttribute("style", "padding: 17px 0 0 14px;}");
    }
  }

  onResize(event) {
    this.asied_width.nativeElement.removeAttribute("style");
    this.right_width.nativeElement.removeAttribute("style");
    this.logo.nativeElement.removeAttribute("style");
    this.minimized_asied = false;
  }

  openMenumodel() {
    this.minimized_asied = false;
    customjs.openMobileMenuFun();
  }

  menuModelAction(action) {
    if (
      action == "profile_update" ||
      action == "diagnosis_home" ||
      action == "profile"
    ) {
      if (this.loginType == "Admin") {
        // this.menuNavAdmin(action, "");
      } else if (
        Helper_Class.getInfo().user_type == "Chief pathologist" ||
        Helper_Class.getInfo().user_type == "Pathologist" ||
        Helper_Class.getInfo().user_type == "Chief lab technician" ||
        Helper_Class.getInfo().user_type == "Lab technician"
      ) {
        this.menuNav("users_view");
      } else if (this.loginType == "front-desk") {
        //do nothing
      } else {
        this.menuNav(action);
      }
    } else if (action == "changePassword") {
      const dialogRef = this.dialog.open(ChangePasswordComponent, {
        width: "33%",
        height: "275px",
        data: { type: this.sendType },
      });
    } else if (action == "terms_conditions") {
      const dialogRef = this.dialog.open(TermsConditionComponent, {
        width: "50%",
        height: "500px",
        data: {},
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result["key"] == 1) {
        } else {
          this.toastr.error(Message_data.defaultErr);
        }
      });
    } else {
      var headers = new Headers();
      headers.append("Content-Type", "application/json");
      this.http
        .post(
          ipaddress.getIp.toString() + "usercontroller/logout/",
          JSON.stringify({
            type: this.sendType,
            user: this.userInfo.user_id,
          }),
          { headers: headers }
        )
        .subscribe(
          (response) => {
            this.toastr.success(Message_data.logOut);
            localStorage.clear();
            this.router.navigateByUrl("/loginpage");
          },
          (error) => {}
        );
    }
    $("#exampleModal").modal("hide");
  }

  setDoctor(e) {
    FrontDesk_Helper.setDate(undefined);
    if (e == "prescriptionlist") {
      FrontDesk_Helper.setDocPrescription(undefined);
      FrontDesk_Helper.setDate(this.currentDate);
    } else if (e == "manageappointments") {
      FrontDesk_Helper.setdoctor(undefined);
      FrontDesk_Helper.setDietician(undefined);
      FrontDesk_Helper.setDate(this.currentDate);
    }
  }

  setFrontDeskAppoinmentHelper(e) {
    if (e == "billing") {
      FrontDesk_Helper.setBillFromDate(this.currentDate);
      FrontDesk_Helper.setBillToDate(this.currentDate);
    }
  }
  setValue() {
    pharmacy_helper.setManufacturer(false);
    pharmacy_helper.setSupplier(false);
  }

  setAppFlow(data) {
    Helper_Class.setPartialUser(data);
  }

  setReport(reports) {
    Helper_Class.setReportType(undefined);
    Helper_Class.setReportType(reports);
  }

  setReportType(data) {
    Diagnosis_Helper.setReportType(data);
  }

  //admin
  setAdminAppoinmentHelper(e) {
    if (e == "billing") {
      FrontDesk_Helper.setBillFromDate(this.currentDate);
      FrontDesk_Helper.setBillToDate(this.currentDate);
      var bill = {
        edit: "55",
      };
      FrontDesk_Helper.setBillFlag(bill);
    }
  }
  isStoppedSpeechRecog = false;
  public text = "";
  tempWords: any;
  Name: string;
  // text:string;
  arr = [];

  init() {
    this.recognition.interimResults = true;
    this.recognition.lang = "en-US";

    this.recognition.addEventListener(
      "result",
      (e: { results: Iterable<unknown> | ArrayLike<unknown> }) => {
        const transcript = Array.from(e.results)
          .map((result: any) => result[0])
          .map((result) => result.transcript)
          .join("");
        this.tempWords = transcript.toLowerCase().trim();
        console.log("transcript" + transcript);
      }
    );
  }

  start() {
    const constraints = { audio: true }; // Constraints object for getUserMedia()
    this.isStoppedSpeechRecog = false;

    // Request access to user's microphone
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream) => {
        // Enumerate available devices
        navigator.mediaDevices
          .enumerateDevices()
          .then((devices) => {
            const audioDevices = devices.filter(
              (device) => device.kind === "audioinput"
            );
            if (audioDevices.length > 0) {
              const selectedDeviceId = audioDevices[0].deviceId; // Select the first available microphone
              this.startRecognition(selectedDeviceId); // Start recognition with the selected microphone
            } else {
              console.error("No audio input devices found.");
            }
          })
          .catch((error) => {
            console.error("Error enumerating devices:", error);
          });
      })
      .catch((error) => {
        console.error("Error accessing microphone:", error);
      });
  }

  startRecognition(deviceId: string) {
    const recognition = new webkitSpeechRecognition();
    recognition.interimResults = true;
    recognition.lang = "en-US";
    recognition.start();
    console.log("Speech recognition started");

    recognition.addEventListener("end", (condition: any) => {
      if (this.isStoppedSpeechRecog) {
        recognition.stop();
        console.log("End speech recognition");
      } else {
        this.wordConcat();
        console.log("wordConcat");
        this.startRecognition(deviceId); // Restart recognition with the selected microphone
      }
    });

    recognition.addEventListener(
      "result",
      (e: { results: Iterable<unknown> | ArrayLike<unknown> }) => {
        const transcript = Array.from(e.results)
          .map((result: any) => result[0])
          .map((result) => result.transcript)
          .join("");
        this.tempWords = transcript.toLowerCase().trim();
        console.log("transcript" + transcript);
      }
    );
  }

  wordConcat() {
    if (this.tempWords != undefined && this.tempWords != "") {
      this.text = this.tempWords;
      this.speechRecognition.sendMessage(this.tempWords);

      if (this.text === "login") {
        this.router.navigateByUrl("loginpage");
      }
      if (this.text === "privacy policy") {
        this.router.navigateByUrl("privacy_policy");
      }
      if (this.text === "registration") {
        this.router.navigateByUrl("registration");
      }
    }
    this.tempWords = "";
  }

  stopregpp() {
    this.isStoppedSpeechRecog = true;
    this.speechRecognition.stopMessages();
    console.log("Speech recognition stoped");
  }

  MenuModelAction(action) {
    if (action == "pharmacyhome") {
      this.menuNav(action);
    } else if (action == "changePassword") {
      const dialogRef = this.dialog.open(ChangePasswordComponent, {
        width: "33%",
        height: "275px",
        data: {},
      });
    } else {
      var headers = new Headers();
      headers.append("Content-Type", "application/json");
      this.http
        .post(
          ipaddress.getIp.toString() + "usercontroller/logout/",
          JSON.stringify({
            type: "doctor",
            user: this.userInfo.user_id,
          }),
          { headers: headers }
        )
        .subscribe(
          (response) => {
            this.toastr.success("Logout successfully" + name);
            localStorage.clear();
            this.router.navigateByUrl("/loginpage");
          },
          (error) => {}
        );
    }
    $("#exampleModal").modal("hide");
  }
}
