import { Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Helper_Class } from '../../helper_class';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { Message_data } from '../../../assets/js/Message_data';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { FormControl } from '@angular/forms';
import { Doc_Helper } from '../../Doctor_module/Doc_Helper';
import { Date_Formate, ConvertTimeformat, Time_Formate, convertNumberToWords } from '../../../assets/js/common';
import { ipaddress } from '../../ipaddress';
import { Master_Helper } from '../../Doctor_module/Master_Helper';
import { MasterHelperService } from '../../Doctor_module/MasterHelper.service';
import { MenuViewService } from '../../../app/common-module/menu-view/menu-view.service';
import moment from 'moment';
import { FrontDesk_Helper } from 'src/app/FrontDesk_module/FrontDesk_Helper';
declare var $: any;

@Component({
  selector: 'app-inpatient-bill-edit',
  templateUrl: './inpatient-bill-edit.component.html',
  styleUrls: ['./inpatient-bill-edit.component.scss']
})

export class InpatientBillEditComponent implements OnInit {
  @ViewChild('printbanner') printbanner: ElementRef;
  @ViewChild('printnoheader') printnoheader: ElementRef;
  @ViewChild('focusMRNo') focusMRNo: ElementRef;
  @ViewChild('focusMobileNo') focusMobileNo: ElementRef;
  @ViewChild('focusFName') focusFName: ElementRef;
  public update_bill_data: any;
  public quantityArray = [];
  tomorrow = new Date();
  public doc_visitscreate = [];
  public warddetails_billcreate = [];
  public bill_palnche;
  public appoint_txt;
  public charge_type: string;
  public charge_id: string;
  public amount_txt;
  public final_amount: any = 0;
  public totalBillAmount: any = 0;
  public inGST: boolean;
  public msgflag: boolean = false;
  public profile_image: string;
  public first_name;
  public last_name;
  public hospital_logo;
  public messages;
  public notifications;
  public notifyflag: boolean = false;
  public concession_txt;
  public con_txt;
  public session: string;
  public amount_aftcon: any;
  public table_div: boolean;
  public final_div: boolean;
  public gst_amount_txt;
  public Advance_bill;
  public CurrentDatetime;
  public Appoint_Date;
  public appointment_list: any = [];
  public Docidname;
  public Bill_appoint_id;
  public hospital_name;
  public middle_appoin: string;
  public billden_plan: any = [];
  public gst_retrive_list: any = [];
  public billaddlist: any = [];
  public gst_data_list: any = [];
  public chk_treat_arr: any = [];
  public charge_name;
  public estimate_id: any = [];
  public paytypelist: any = [];
  public insurerlist: any = [];
  public pay_type: any;
  public insurer_id: string;
  public insurFlag: boolean;
  public remaing_billbal: any = 0;
  public addvancetxt;
  public totalcolletxt;
  public cent_gst;
  public sta_gst;
  public client_reg_id;
  public relation_id: any;
  public sub_rel_id;
  public Charge_id;
  public charge_desc;
  public hptl_clinic_id: string;
  public header_footer_flag: boolean;
  public addbtn_flag: boolean;
  public bill_date;
  public current_amount: any = 0;
  public billdate;
  public diet_followtable: boolean;
  public Gsthidden: boolean = false;
  public submenu_flag: boolean = false;
  public submenu_flag1: boolean = false;
  public doctor_profile_img!: string | null;
  public cenra_gst;
  public stat_gst;
  public prev_bal: string;
  public total_bill: string;
  public total_bill1: string;
  public currentDate;
  public userinfo;
  public tempRoundoff = 0;
  //mdbTable
  dtOptions: DataTables.Settings = {};
  public persons: any = [];
  public masterSelected: boolean = false;
  public checklist: any;
  public checkedList: any;
  public user_id;
  //Get apointment details
  public patient_name: string;
  public prev_bill_id;
  public balance_show: boolean;
  public amount_collected: boolean;
  public previous_balshow: boolean;
  public balance_data;
  public total_collected;
  public round_off;
  public paid_bill;
  public sign;
  public billing_print_array;
  public medcalDetailsArray: any = [];
  public digDetailsArray: any = [];
  public hospitalAddress;
  public Hospital_location;
  public diagnosisAmount: any = 0;
  public medicine_amount = 0;
  public medicinBal: any = 0;
  public dog_reg_id;
  public serviceType: any = [];
  public inpatientEdit: boolean = false;
  public quantity: any = 1;
  public hospitalBills: any = 0;
  public saveBillURL;
  public bill_id;
  public pre_paid_bill = 0;
  public pre_bill_amount: any = 0;
  public pharmaPaid: any = 0;
  public diagPaid: any = 0;
  public show_save_btn: boolean = true;
  public bill_cons_flag: boolean = true;
  public bill_qty_flag: boolean = true;
  private rowid: number = 1;
  public bill_time1;
  public bill_time2;
  public bill_time3;
  public bill_hrs: any = [];
  public bill_min: any = [];
  public currentTime;
  public department_id;
  public ward_id;
  public chargeNameArray: any = [];
  public transId;
  public RateCardArray = [];
  public rate_card;
  public checkBox: any;
  public paytypreq: boolean = false;
  public credit_flag: boolean = true;
  public hosp_credits: any = [];
  public credit_type: string;
  public credit_bill;
  public payType1;
  public amountval: any;
  public paymentsarray: any = [];
  public mrno;
  public clnt_saldesc;
  public clnt_age;
  public gender;
  public mobile_no;
  public e_contact;
  public admitted_date;
  public admitted_time;
  public discharge_date;
  public discharge_time;
  public createpage_flag: boolean = false;
  public Moduleidlist = [];
  public editbutton: boolean = false;
  public newbutton: boolean = false;
  public deletebutton: boolean = false;
  public printbutton: boolean = false;
  public viewbutton: boolean = false;
  public admin_flag: boolean = false;
  public bulkAddFlag: boolean = false;
  public credit_person;
  public bill_head;
  public billId;
  public numToWords: any;
  public time;
  public temp_key: any;
  public print_size: any = null;
  public showModal: boolean = false;
  public cashDenomination: { value: number; count: number }[] = [
    { value: 2000, count: 0 },
    { value: 500, count: 0 },
    { value: 200, count: 0 },
    { value: 100, count: 0 },
    { value: 50, count: 0 },
    { value: 20, count: 0 },
    { value: 10, count: 0 },
    { value: 5, count: 0 },
    { value: 2, count: 0 },
    { value: 1, count: 0 },
  ];
  public totalAmount: number;
  public doctorVisitsChargeArray = [];
  public hospitalLogo: any;
  public printlogostyle: any;
  public print_template: any;
  public doctor_name: any;
  public dept_desc: any;
  public wardName: any;
  public bed_no: any;
  public profileImg: string;
  public discount_amount: any = 0;
  public tot_pay_array_amount: any;
  public filter: string;
  public toFlag: boolean;
  public fromFlag: boolean;
  public date;
  public firstNameFlag: boolean;
  public firstName: string;
  public uhidFlag: boolean;
  public patientId;
  public mobileFlag: boolean;
  public mobileNo: string;
  public lastName: string;
  public mr_no_flag: boolean;
  public total: any;
  public other_charges_array = [];
  public hosp_charges = [];
  public diag_charges = [];
  public diagnosis_bills = [];
  public pharma_charges = [];
  public pharma_bills = [];
  public sendAdvanceAmount: any;
  public dateval: moment.Moment;
  public enddate: any;
  public endtime: any;
  public HalfDayHour: number;
  public DayHour: number;
  public HourlyHour: number;
  public cardFlag: boolean;
  public cardNumber;
  public cardreqflag: boolean = false;
  public cardHolderName;
  public transactionFlag: boolean;
  public transactionId;
  public transreqflag: boolean = false;
  public chequeFlag: boolean;
  public chequereqflag: boolean = false;
  public bankName;
  public insreqflag: boolean = false;
  public discount_flag: boolean = false;
  public open_app_billing;
  public wardbasecharge: string;
  public surgery: any;
  public bill_type_pharma: any;
  public barcode_url;
  public barcode_flag: boolean = false;
  public logUserName;
  public barcode_style;
  public nobanner_style;
  public inpatient_flag: boolean = true;
  public multiply_flag: boolean = true;
  public amount_td_style;
  public paymentsarray_total;
  public p_tag_style: any;
  public grouping_flag: boolean = true;
  public categoryChargeList: any = [];
  public ward_details_flag: boolean = false;
  public paid_amount_flag: boolean = false;
  public rate_card_disable: boolean = false;
  public billAddList: any[];
  public hospital_finalAmount: any;
  public hospital_totalBillAmount: any;
  public hospital_discount_amount: any;
  public hospital_gstAmount: any;
  public hospital_totalCollected: any;
  public hospital_gstDataList: any[] = [];
  public hospital_remainingBalance;
  public hospital_paidBill: any = 0;
  public hospital_paymentsarray: any = [];
  public hospital_billId = '';
  public hospital_paid_percentage = [];
  public hospital_pay_arrry_tot_amt;
  public hospitalEqualSplitValue;
  public sponsorDueflag: boolean = false;
  public sponsorDue;
  public adv_refflag: boolean = false;
  public refund_reason: any;
  public totalPaidAmountvalue: any = "";
  public totalBalanceAmountvalue: any = "";
  public totalBillAmountvalue: any = "";
  public totalDiscountvalue: any = 0;
  public total_advancevalue: any = '';
  public discount_rembalance = 0;
  public selectedAllFlage: boolean = false;
  public is_insurance: boolean;
  public totalPaidAmount: any;
  public TotalBalance: string;
  public medBills: any;
  public diagBills: any;
  public doctorBills: any;
  public docBills: any;
  public sendDoctor: any[];
  public totalDiscount: string;
  public totalBill: string;
  public totalPaid: any;
  public sendPharmacy: any[];
  public mediBillAmount: string;
  public sendDiagnosis: any;
  public diag_centre_id: any;
  public fin_year: any;
  public pharma_id;
  public combinedArray: any[];
  public refund_amount: any = 0;
  public temppaid_bill = "0.00";
  public totalAdvance_bills = 0;
  public remainingbalance_advance: any;
  public remainingbalance_sponsor_due: any;
  public sponsor_due
  public approved_amount = "0.00";
  public paidflag: boolean = false;
  public discountflag: boolean = false;
  public balanceflag: boolean = false;
  public advanceflag: boolean = false;
  public paid_bill1;
  public totalBillAmount1 = "0.00";
  public doctor;
  public Insuranceflag: boolean = false;
  public insuranceFlag: boolean = false;
  public patientList = [];
  public floorvalue;
  public floorarray = [];
  public count;
  public payment_mode;
  public doctorList = [];
  public doctorName;
  public graphicalformat: boolean = false;
  public graph_defaultimg: boolean = false;
  public tabular_format: boolean = true;
  public view_type;
  public name;
  public location;
  public mobile: string;
  public mrnoflag: boolean;
  public locationflag: boolean;
  public nameflag: boolean;
  public fdateflag: boolean;
  public todateflag: boolean;
  public mobileflag: boolean;
  public fromDate;
  public toDate;
  public filterbasedurl;
  public selectvalue;
  public currentMaxDate;
  public remaining_billbal;
  public total_advance = "0.00";
  public discountvaluecheck = 0.00;
  public client_id;
  public sub_id;
  public rel_id;
  public tempparray;
  public app_id;
  public inpatientId
  public admitted_timevalue;
  public inpid;
  public insurer;
  public insCardNo;
  public policyno;
  public CCNno;
  public clientnamenew; public mobilenumber; public doctorname; inpatientidnew;
  public age;
  public bedno;
  public agegendervalue;
  public ward_name;
  public surgery_name: string;
  public doc_qualif: any;
  public specialization_name: any;
  public dischargeTime = "";
  public rate_plan: any;
  public Filter_txt;
  public f_date;
  public t_date;
  public admissionDate = "";
  public admissionTime = "";
  public dischargeDate = "";
  public DisplayBills: any = [];
  public insuranceDec;
  public patient_selectionflag: boolean = true;
  public docBillDetails: any = [];
  public header_style: string;
  public bill_no: string;
  public bill_type: string;
  public From_show: boolean = true;
  public To_show: boolean = true;
  public dateshow: boolean = false;
  public Filter_based;
  public mr_no;
  public rate_card_disc: any;
  public advanceList = [];
  public package = [];
  public return_paymentsarray: any[];
  public return_pay_arrry_tot_amt: any;
  public returnEqualSplitValue: any;
  public local_payment_array = [];
  public hosp_id;
  //////
  public billDetailedView;
  public finyear;
  public docRegId;
  public inpatient;
  public cgstFlag;
  public sgstFlag;
  public inpatientflag:boolean=false;
  public billingDetailedViewData;
  public genderval: string;
  public ageval: string;
  public advanceFlag: boolean;
  public totalFlag: boolean;
  public advancevalue;
  public prevBalanceFlag: boolean;
  public sendAddress: string;
  public clientAddress1: string;
  public clientAddress2: string;
  public prevBalance: any;
  public mainBalanceFlag: boolean;
  public payType: string;
  public payFlag: boolean;
  public billAmount: any;
  public cgst: any;
  public sgst: any;
  public paid;
  public discount;
  public clientName: string;
  public docName: string;
  balance: number;
  refund: number;
  client_name: any;
  dr_name: any;
  created_date: string;
  caddress1: string;
  caddress2: string;
  clocation: string;
  clientcity: string;
  clestare: string;
  uhid: any;
  billingDetailList: any;
  public discount_amount1;
  public appointment_id: any;
  public med_with_det: boolean = false;
  public selectedValue;
  // public settled;
  // public finalized;
  // public pending;
  public status: string = "";
  public bank_details_flag_adv: boolean = false;
  public acc_number_adv: any;
  public holder_Name_adv: any;
  public bank_Name_adv: any;
  public bank_ifc_code_adv: any;
  public refund_array = [];  
  // public pending_flag: boolean = false;
  // public finalized_flag: boolean = false;
  public local_paid_bill: any;
  public insurance_flag:boolean = false;
  public pharma_bill_flag: boolean = false;
  public inTDS: boolean = false;
  public spons_set_amount:any = 0;
  public spons_tds_percen: any = 0;
  public tds_amount: any = 0;
  public sponser_name;
  public sponser_GSTIN = '';
  public status_billtype:string;
  public hosp_pay_array:any = [];
  public payments_array:any = [];
  public biill_type;

  constructor(
    public doctorservice: MenuViewService,
    public toastr: ToastrService,
    public http: Http,
    public router: Router,
    public messageservice: MenuViewService,
    public masterData: MasterHelperService,
    private cdr: ChangeDetectorRef
  ) {
    this.addbtn_flag = true;
    this.insurFlag = true;
    this.tomorrow.setDate(this.tomorrow.getDate() + 0);
    this.billdate = new FormControl(new Date());
    this.userinfo = Helper_Class.getInfo();
    this.user_id = this.userinfo.user_id;
    this.bill_time1 = '';
    this.bill_time2 = '00';
    this.amount_txt = ' ';
    for (var i = 1; i <= 12; i++) {
      if (i < 10) {
        this.bill_hrs.push('0' + i);
      } else {
        this.bill_hrs.push(i);
      }
    }

    for (var i = 0; i <= 59; i += 1) {
      if (i < 10) {
        this.bill_min.push('0' + i);
      } else {
        this.bill_min.push(i);
      }
    }
    this.transactionFlag = true;
    this.cardFlag = true;
    this.chequeFlag = true;
    this.firstNameFlag = true;
    this.fromFlag = true;
    this.toFlag = true;
    this.uhidFlag = false;
    this.mobileFlag = true;
    this.mr_no_flag = false;
    this.discount_flag = false;
    ////////////
    this.inpatient_flag = true;
    this.billDetailedView = FrontDesk_Helper.getreceptbilledit();
    console.log("BillDetailedView",this.billDetailedView);
    this.billId = this.billDetailedView.bill_view_id;
    this.docRegId = this.billDetailedView.doc_reg_id;
    this.finyear = this.billDetailedView.fin_year;
    this.inpatient = this.billDetailedView.inpatient;
    this.advanceFlag = true;
    this.payFlag = true;
    this.med_with_det = false;
    this.spons_set_amount = 0;
    this.tds_amount = 0;
  }

  ngOnInit(): void {
    this.refund_amount = 0;
    this.credit_type = '';
    this.paid_amount_flag = false;
    this.profileImg = '../../assets/img/default.jpg';
    this.ward_details_flag = false;
    this.show_save_btn = true;
    $(document).ready(function () {
      $('input').attr('autocomplete', 'off');
    });
    var hospitaldet = Helper_Class.getHospital();
    this.hptl_clinic_id = hospitaldet[0].hptl_clinic_id;
    this.userinfo = Helper_Class.getInfo();
    console.log(this.userinfo);

    if (this.userinfo.fin_year != undefined) {
      this.fin_year = this.userinfo.fin_year;
    }

    if (this.userinfo.pharma_id != undefined) {
      this.pharma_id = this.userinfo.pharma_id;
    }

    if (this.userinfo.diag_centre_id != undefined) {
      this.diag_centre_id = this.userinfo.diag_centre_id;
    }

    this.wardbasecharge = this.userinfo.ward_base_charge;
    this.print_size = this.userinfo.bill_print_paper;
    if (this.userinfo.bill_pay_type_req == '1') {
      this.paytypreq = false;
    } else {
      this.paytypreq = true;
    }
    this.hospital_logo =
      ipaddress.Ip_with_img_address + Helper_Class.getInfo().hospitals[0].logo;
    if (this.userinfo.bulk_add_req === '1') {
      this.bulkAddFlag = true;
    }
    this.dtOptions = {
      pageLength: 25,
      lengthMenu: [
        [25, 50, -1],
        [25, 50, 'All'],
      ],
      columnDefs: [{ orderable: false, targets: [2, 3, 4, 5, 6] }],
      language: {
        search: "<img src='../../../assets/img/search.png' width='18px'/>",
        searchPlaceholder: 'Search chargetypes by name',
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12 d-flex justify-content-end"l>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>',
    };

    this.Moduleidlist = Helper_Class.getmodulelist();
    if (this.Moduleidlist != undefined && this.Moduleidlist.length != 0) {
      for (var i = 0; i < this.Moduleidlist.length; i++) {
        if (this.Moduleidlist[i].module_id == '7') {
          if (this.Moduleidlist[i].edit == '1') {
            this.editbutton = true;
          }
          if (this.Moduleidlist[i].create == '1') {
            this.newbutton = true;
          }
          if (this.Moduleidlist[i].delete == '1') {
            this.deletebutton = true;
          }
          if (this.Moduleidlist[i].print == '1') {
            this.printbutton = true;
          }
          if (this.Moduleidlist[i].view == '1') {
            this.viewbutton = true;
          }
        }
      }
    }

    if (Helper_Class.getInfo().user_type == 'Admin') {
      this.editbutton = true;
      this.newbutton = true;
      this.deletebutton = true;
      this.printbutton = true;
      this.viewbutton = true;
      this.admin_flag = true;
    }

    if (this.userinfo.hptl_name == 'SGH') {
      this.p_tag_style = "text-align: center;font-size: 16px;margin: 0px;margin-right: 10px !important;margin-bottom: 3px !important;margin-top: 10px;";
    } else {
      this.p_tag_style = "text-align: center;font-size: 16px;margin: 0px;margin-right: 10px !important;margin-bottom: 3px !important;";
    }

    this.hospitalLogo = ipaddress.Ip_with_img_address + Helper_Class.getInfo().bill_print_logo;
    this.printlogostyle = Helper_Class.getInfo().bill_print_logo_style;
    this.doctor_profile_img = this.userinfo.profile_image;
    this.amount_change();
    this.getPaymentType();
    this.getCreditTypes();
    this.final_amount = 0;
    this.discount_amount = 0;
    this.totalBillAmount = 0;
    this.gst_amount_txt = 0;
    this.Advance_bill = 0;
    this.paid_bill = 0;
    this.round_off = 0;
    this.concession_txt = 0;
    this.quantity = 1;
    this.inGST = false;
    this.inTDS = false;
    this.Get_current_date();
    this.gst_retrive_list = [];
    this.billaddlist = [];
    this.categoryChargeList = [];
    this.Get_Gst_Data();
    this.estimate_id = [];
    this.previous_balshow = true;
    this.balance_show = true;
    this.getInsurers();
    this.RateCardData();
    this.getServiceType();
    this.saveBillURL = 'bill/savebill/';
    this.grouping_flag = true;
    if (Helper_Class.getInfo().bill_cons_flag == 0) {
      this.bill_cons_flag = true;

    } else {
      this.bill_cons_flag = false;
    }


    if (Helper_Class.getInfo().bill_qty_flag == 0) {
      this.bill_qty_flag = true;
    } else {
      this.bill_qty_flag = false;
    }

    if (this.userinfo.bill_print_template != undefined) {
      this.print_template = this.userinfo.bill_print_template;
    } else {
      this.print_template = 'noheader';
    }

    if (this.userinfo.open_app_billing != null) {
      this.open_app_billing = this.userinfo.open_app_billing;
    }

    if (this.userinfo.first_name != undefined) {
      this.logUserName = this.userinfo.first_name;
      if (this.userinfo.last_name != undefined) {
        this.logUserName = this.userinfo.first_name + " " + this.userinfo.last_name;
      }
    }

    this.bill_head = Helper_Class.getInfo().bill_heading;
    this.filter = 'Patient_ID';
    this.cdr.detectChanges();
    this.Filter_based = "mrno";
    this.dog_reg_id = "All";
    this.doctor = "All";
    this.selectvalue = "mrno";
    this.floorvalue = "All";
    this.view_type = "graph";
    this.bill_type = "doctor";
    this.Filter_txt = "All";
    this.advanceList = [];
    this.Get_current_date();
    this.getPaymentType();
    this.cgstFlag = true;
    this.sgstFlag = true;
    this.prevBalanceFlag = true;
    this.insurance_flag = false;
    this.pharma_bill_flag = false;
    this.spons_tds_percen = 0;
    this.sponser_name = '';
    this.sponser_GSTIN = '';
    this.getBillDetails();
  }

  billType(status) {
    if (status == "pending") {
      // this.settled = false;
      // this.finalized = false;
      // this.pending = true;
      // this.status = "1";
    } else if (status == "finalized") {
      // this.settled = false;
      // this.pending = false;
      // this.finalized = true;
      // this.status = "2"; 
    } else if (status == "settled") {
      // this.finalized = false;
      // this.pending = false;
      // this.settled = true;
      this.status = "0";
    }
    this.status_billtype=status
  }

  showCreditOption() {
    this.credit_flag = !this.credit_flag;
  }

  getCreditTypes() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(
        ipaddress.getIp.toString() + 'bill/ghco',
        JSON.stringify({
          country: ipaddress.country_code.toString(),
          hptl_clinic_id: this.userinfo.hptl_clinic_id,
          type: 'hospital',
        }),
        { headers: headers }
      )
      .subscribe(
        (response) => {
          var obj = response.json();
          if (obj.hosp_credits != null) {
            for (var i = 0; i < obj.hosp_credits.length; i++) {
              this.hosp_credits.push({
                credit_id: obj.hosp_credits[i].credit_id,
                credit_desc: obj.hosp_credits[i].credit_desc,
              });
            }
          }
        },
        (error) => { }
      );
  }

  back() {
    if(Helper_Class.getIsAdmin() != undefined){
      this.messageservice.sendMessage("frontdeskadminbilling");
    }else if (Helper_Class.getLoginType() == 'doctor'){
      this.messageservice.sendMessage('billing');
    } else {
      this.messageservice.sendMessage('frontdeskbilling');
    }
  }

  RateCardData() {
    this.rate_card_disable = false;
    this.RateCardArray = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    var requestData = {
      centre_id: this.hptl_clinic_id,
      center_type: 'hospital',
    };
    this.http
      .post(ipaddress.getIp.toString() + 'mas/grcd', requestData, {
        headers: headers,
      })
      .subscribe(
        (response) => {
          var obj = response.json();
          console.log(obj);
          var RateCard = obj.rate_cards;
          if (RateCard != undefined && RateCard.length != 0) {
            for (var i = 0; i < RateCard.length; i++) {
              this.RateCardArray.push({
                discount: RateCard[i].discount,
                rate_card_desc: RateCard[i].rate_card_desc,
                rate_card_id: RateCard[i].rate_card_id,
                default: RateCard[i].default,
                inp_default: RateCard[i].inp_default
              });
            }
            this.rate_card = this.RateCardArray[0].rate_card_id;
            for (var i = 0; i < this.RateCardArray.length; i++) {
              if (this.RateCardArray[i].inp_default != undefined && this.RateCardArray[i].inp_default == '1') {
                this.rate_card = this.RateCardArray[i].rate_card_id;
                this.rate_card_disable = true;
                break;
              }
            }
            console.log(this.RateCardArray);
            this.getConcession();
            // this.charge_name = Helper_Class.getBillCharges();
            // if (this.charge_name != undefined && this.charge_name.charges != 0) {
            //   this.charge_name = this.charge_name.charges;
            // }
            // console.log(this.charge_name);
          }
        },
        (error) => {
          this.toastr.error('An error occurred');
        }
      );
  }

  //Bill add table
  Bill_add_click() {
    this.diet_followtable = true;
    this.addbtn_flag = true;

    if (this.appoint_txt == undefined) {
      this.toastr.error(Message_data.sltAppId);
    } else if (this.charge_type == undefined) {
      this.toastr.error(Message_data.sltBillType);
    } else if (this.amount_txt == undefined || this.amount_txt == '') {
      this.toastr.error(Message_data.enterAmt);
    } else {
      if (this.final_amount == 0) {
        this.inGST == false;
      }
      this.table_div = true;
      this.final_div = true;
      var flag = true;
      for (var i = 0; i < this.billaddlist.length; i++) {
        if (
          this.billaddlist[i].biill_type == this.charge_type &&
          this.billaddlist[i].paytype == this.pay_type
        ) {
          flag = false;
          break;
        }
      }

      if (flag == true) {
        if (this.charge_name.length != 0) {
          for (var i = 0; i < this.charge_name.length; i++) {
            if (this.charge_type == this.charge_name[i].description) {
              this.charge_id = this.charge_name[i].charge_id;
            }
          }
        }
        this.con_txt = this.concession_txt == '' ? 0 : this.concession_txt;
        var paydesc;
        if (this.paytypelist.length != 0) {
          for (var i = 0; i < this.paytypelist.length; i++) {
            if (this.pay_type == this.paytypelist[i].pay_id) {
              paydesc = this.paytypelist[i].pay_desc;
            }
          }
        }

        this.total = parseInt(this.quantity) * parseInt(this.amount_txt);
        var result = this.finekeyInArray_billaddChargeType(
          this.billaddlist,
          this.charge_id
        );
        if (result == true) {
          this.toastr.error('Charge type already exists');
        } else if (this.paytypreq == true) {
          this.billaddlist.push({
            sno: this.rowid,
            bill_type: 'hospital',
            biill_type: this.charge_type,
            amount_txt: parseInt(this.amount_txt),
            concession: this.con_txt,
            fee: parseInt(this.amount_aftcon),
            charge_type: this.charge_id,
            pay_type: paydesc,
            paytype: this.pay_type,
            insurer: this.insurer_id,
            quantity: this.quantity,
            total: this.total,
          });
        } else {
          this.billaddlist.push({
            sno: this.rowid,
            bill_type: 'hospital',
            biill_type: this.charge_type,
            amount_txt: parseInt(this.amount_txt),
            concession: this.con_txt,
            fee: parseInt(this.amount_aftcon),
            charge_type: this.charge_id,
            pay_type: paydesc,
            paytype: this.pay_type,
            insurer: this.insurer_id,
            ref_code: this.transId,
            quantity: this.quantity,
            total: this.total,
          });
        }

        this.hospitalBills = 0;
        this.rowid = 1;
        if (this.billaddlist.length != 0) {
          for (var i = 0; i < this.billaddlist.length; i++) {
            if (this.billaddlist[i].fee == '') {
              this.billaddlist[i].fee = 0;
            }
            this.hospitalBills = (
              parseFloat(this.hospitalBills) +
              parseFloat(this.billaddlist[i].fee)
            );
            this.billaddlist[i].sno = this.rowid;
            this.rowid += 1;
          }
        }
        this.charge_type = '';
        this.amount_txt = '';
        this.amount_aftcon = '';
      }

      if (this.final_amount == 0) {
        this.inGST == false;
      }

      this.Calculate_gst('0', '0');
    }
  }

  finekeyInArray_billaddChargeType(array, biill_type) {
    var flag = false;
    for (var i = 0; i < array.length; i++) {
      if (array[i].charge_type == biill_type) {
        flag = true;
        break;
      }
    }
    return flag;
  }

  updateBillList(charge) {
    var results = this.finekeyInArray_billadd(
      this.billaddlist,
      charge.charge_type
    );
    if (charge.selected) {
      if (results == false) {
        this.charge_type = charge.biill_type;
        this.amount_txt = parseFloat(charge.amount_txt);
        this.concession_txt = charge.concession;
        this.amount_aftcon = parseFloat(charge.fee);
        this.charge_id = charge.charge_type;
        this.insurer_id = charge.insurer;
        this.transId = charge.ref_code;
        this.quantity = charge.quantity;
        setTimeout(() => {
          this.Bill_add_click();
        }, 500);
      }
    } else {
      this.Delete_bill(charge);
    }
  }

  // Delete_bill(charge) {
  //   if (charge.charge_type.length != 0) {
  //     if (this.billaddlist.length != 0) {
  //       for (var i = 0; i < this.billaddlist.length; i++) {
  //         if (
  //           this.billaddlist[i].charge_type == charge.charge_type &&
  //           this.billaddlist[i].amount_txt == charge.amount_txt
  //         ) {
  //           this.billaddlist.splice(i, 1);
  //           break;
  //         }
  //       }
  //       for (var i = 0; i < this.categoryChargeList.length; i++) {
  //         for (var j = 0; j < this.categoryChargeList[i].charges.length; j++) {
  //           if (
  //             this.categoryChargeList[i].charges[j].charge_type == charge.charge_type &&
  //             this.categoryChargeList[i].charges[j].amount_txt == charge.amount_txt
  //           ) {
  //             this.categoryChargeList[i].charges.splice(j, 1);
  //             break;
  //           }
  //         }
  //       }
  //     }
  //     this.rowid = 0;
  //     for (var i = 0; i < this.billaddlist.length; i++) {
  //       if (this.billaddlist[i].status != '2') {
  //         this.rowid += 1;
  //         this.billaddlist[i].sno = this.rowid;
  //       }
  //     }

  //     this.rowid = 1;
  //     for (var i = 0; i < this.categoryChargeList.length; i++) {
  //       for (var j = 0; j < this.categoryChargeList[i].charges.length; j++) {
  //         this.categoryChargeList[i].charges[j].sno = this.rowid;
  //         this.rowid += 1;
  //       }
  //     }
  //     // this.createGroupping();
  //     this.billaddlist = this.combineCharges(this.categoryChargeList);
  //     this.hospitalBills = 0;
  //     if (this.billaddlist.length != 0) {
  //       for (var i = 0; i < this.billaddlist.length; i++) {
  //         if (this.billaddlist[i].fee == '') {
  //           this.billaddlist[i].fee = 0;
  //         }
  //         this.hospitalBills = (
  //           parseFloat(this.hospitalBills) + parseFloat(this.billaddlist[i].fee)
  //         );
  //       }
  //     }
  //     if (this.billaddlist.length == 0) {
  //       this.table_div = false;
  //       this.final_div = false;
  //     } else {
  //       this.table_div = true;
  //     }

  //     if (this.final_amount == 0) {
  //       this.inGST == false;
  //     }
  //     this.Calculate_gst('0', '0');
  //   } else {
  //     for (var i = 0; i < this.billaddlist.length; i++) {
  //       if (charge.sno == this.billaddlist[i].sno) {
  //         if (charge.biill_type == '') {
  //           this.billaddlist.splice(i, 1);
  //           this.Calculate_gst('0', '0');
  //           break;
  //         }
  //       }
  //     }
  //     for (var i = 0; i < this.categoryChargeList.length; i++) {
  //       for (var j = 0; j < this.categoryChargeList[i].charges.length; j++) {
  //         if (
  //           this.categoryChargeList[i].charges[j].sno == charge.sno &&
  //           charge.biill_type == ''
  //         ) {
  //           this.categoryChargeList[i].charges.splice(j, 1);
  //           // this.createGroupping();
  //           this.Calculate_gst('0', '0');
  //           break;
  //         }
  //       }
  //     }

  //     this.rowid = 1;
  //     for (var i = 0; i < this.billaddlist.length; i++) {
  //       this.billaddlist[i].sno = this.rowid;
  //       this.rowid += 1;
  //     }

  //     this.rowid = 1;
  //     for (var i = 0; i < this.categoryChargeList.length; i++) {
  //       for (var j = 0; j < this.categoryChargeList[i].charges.length; j++) {
  //         this.categoryChargeList[i].charges[j].sno = this.rowid;
  //         this.rowid += 1;
  //       }
  //     }
  //   }
  // }

  Delete_bill(charge) {
    if (charge.charge_type.length != 0) {
      if (this.billaddlist.length != 0) {
        for (var i = 0; i < this.billaddlist.length; i++) {
          if (
            this.billaddlist[i].charge_type == charge.charge_type &&
            this.billaddlist[i].amount_txt == charge.amount_txt
          ) {
            this.billaddlist.splice(i, 1);
            break;
          }
        }
        for (var i = 0; i < this.hosp_charges.length; i++) {
          if (
            this.hosp_charges[i].charge_id == charge.charge_type &&
            this.hosp_charges[i].amount == charge.amount_txt
          ) {
            this.hosp_charges.splice(i, 1);
            break;
          }
        }
        for (var i = 0; i < this.categoryChargeList.length; i++) {
          for (var j = 0; j < this.categoryChargeList[i].charges.length; j++) {
            if (
              this.categoryChargeList[i].charges[j].charge_type == charge.charge_type &&
              this.categoryChargeList[i].charges[j].amount_txt == charge.amount_txt
            ) {
              this.categoryChargeList[i].charges.splice(j, 1);
              break;
            }
          }
        }
      }
      this.rowid = 0;
      for (var i = 0; i < this.billaddlist.length; i++) {
        if (this.billaddlist[i].status != '2') {
          this.rowid += 1;
          this.billaddlist[i].sno = this.rowid;
        }
      }

      this.rowid = 1;
      for (var i = 0; i < this.categoryChargeList.length; i++) {
        for (var j = 0; j < this.categoryChargeList[i].charges.length; j++) {
          this.categoryChargeList[i].charges[j].sno = this.rowid;
          this.rowid += 1;
        }
      }
      // this.createGroupping();
      this.billaddlist = this.combineCharges(this.categoryChargeList);
      this.hospitalBills = 0;
      if (this.billaddlist.length != 0) {
        for (var i = 0; i < this.billaddlist.length; i++) {
          if (this.billaddlist[i].fee == '') {
            this.billaddlist[i].fee = 0;
          }
          this.hospitalBills = (
            parseFloat(this.hospitalBills) + parseFloat(this.billaddlist[i].fee)
          );
        }
      }
      if (this.billaddlist.length == 0) {
        this.table_div = false;
        this.final_div = false;
      } else {
        this.table_div = true;
      }

      if (this.final_amount == 0) {
        this.inGST == false;
      }
      
      this.Calculate_gst('0', '0');
      this.calculateHospitalGst('0', '0');
      this.discount_amount = "0";
      this.discountvalue("0");
      this.subtotalCalculation();
    } else {
      for (var i = 0; i < this.billaddlist.length; i++) {
        if (charge.sno == this.billaddlist[i].sno) {
          if (charge.biill_type == '') {
            this.billaddlist.splice(i, 1);
            this.calculateHospitalGst('0', '0');
            this.Calculate_gst('0', '0');
            this.discount_amount = "0";
            this.discountvalue("0");
            this.subtotalCalculation();
            break;
          }
        }
      }
      for (var i = 0; i < this.categoryChargeList.length; i++) {
        for (var j = 0; j < this.categoryChargeList[i].charges.length; j++) {
          if (
            this.categoryChargeList[i].charges[j].sno == charge.sno &&
            charge.biill_type == ''
          ) {
            this.categoryChargeList[i].charges.splice(j, 1);
            // this.createGroupping();
            this.calculateHospitalGst('0', '0');
            this.Calculate_gst('0', '0');
            this.discount_amount = "0";
            this.discountvalue("0");
            this.subtotalCalculation();
            break;
          }
        }
      }

      this.rowid = 1;
      for (var i = 0; i < this.billaddlist.length; i++) {
        this.billaddlist[i].sno = this.rowid;
        this.rowid += 1;
      }

      this.rowid = 1;
      for (var i = 0; i < this.categoryChargeList.length; i++) {
        for (var j = 0; j < this.categoryChargeList[i].charges.length; j++) {
          this.categoryChargeList[i].charges[j].sno = this.rowid;
          this.rowid += 1;
        }
      }
    }
    console.log(this.categoryChargeList);
  }

  finekeyInArray_billadd(array, id) {
    var flag = false;
    for (var i = 0; i < array.length; i++) {
      if (array[i].charge_type === id) {
        flag = true;
        break;
      }
    }
    return flag;
  }

  Get_Bill_Type() {
    this.categoryChargeList = [];
    console.log("Function Called..");
    var paydesc;
    if (this.paytypelist.length != 0) {
      for (var i = 0; i < this.paytypelist.length; i++) {
        if (this.pay_type == this.paytypelist[i].pay_id) {
          paydesc = this.paytypelist[i].pay_desc;
        }
      }
    }
    console.log(this.hosp_charges);
    var hospital_charges = [];
    if (this.hosp_charges.length != 0) {
      for (var i = 0; i < this.hosp_charges.length; i++) {
        var total = (parseFloat(this.hosp_charges[i].quantity) * parseFloat(this.hosp_charges[i].amount));
        var concession = this.hosp_charges[i].concession != undefined ? this.hosp_charges[i].concession : parseFloat(this.rate_card_disc);
        var created_date_time = this.hosp_charges[i].created_date != undefined ? Date_Formate(this.hosp_charges[i].created_date) + " " + Time_Formate(this.hosp_charges[i].created_time) : '';
        var end_date_time = this.hosp_charges[i].end_date != undefined ? Date_Formate(this.hosp_charges[i].end_date) + " " + Time_Formate(this.hosp_charges[i].end_time) : '';
        var final_amount = this.hosp_charges[i].final_amount != undefined && this.hosp_charges[i].final_amount != null ? parseFloat(this.hosp_charges[i].final_amount) : parseFloat('0.00');
        var cqty = this.hosp_charges[i].quantity.split(".");
        var hqty = 0;
        if (cqty[1] == "0") {
          hqty = cqty[0];
        } else {
          hqty = this.hosp_charges[i].quantity;
        }
        if (end_date_time != undefined && end_date_time != null && end_date_time != '') {
          created_date_time = created_date_time +'</br> To '+end_date_time;
        }
        if (this.hosp_charges[i].charge_desc != undefined && this.hosp_charges[i].charge_desc != "") {
          hospital_charges.push({
            sno: i + 1,
            bill_type: this.hosp_charges[i].type,
            created_date: this.hosp_charges[i].created_date,
            created_time: this.hosp_charges[i].created_time,
            created_date_time: created_date_time,
            biill_type: this.hosp_charges[i].charge_desc,
            amount_txt: parseFloat(this.hosp_charges[i].amount),
            concession: concession,
            fee: final_amount,
            charge_type: this.hosp_charges[i].charge_id,
            paytype: this.pay_type,
            pay_type: paydesc,
            insurer: this.insurer_id,
            quantity: hqty,
            total: total,
            edit_flag: true,
            category: this.hosp_charges[i].category_desc,
            charge_cat_id: this.hosp_charges[i].charge_category_id,
            order_no: this.hosp_charges[i].order_no
          })
        }
      }
      this.createGroupping(hospital_charges);
    }

    var diag_charges = [];
    if (this.diag_charges.length != 0) {
      for (var i = 0; i < this.diag_charges.length; i++) {
        if (this.diag_charges[i].amount != "0.00") {
          var total = (parseFloat(this.diag_charges[i].quantity) * parseFloat(this.diag_charges[i].amount));
          var concession = this.diag_charges[i].concession != undefined ? this.diag_charges[i].concession : parseFloat("0.00");
          var created_date_time = this.diag_charges[i].created_date != undefined ? Date_Formate(this.diag_charges[i].created_date) + " " + Time_Formate(this.diag_charges[i].created_time) : '';
        
          diag_charges.push({
            sno: i + 1,
            billing_id: this.diag_charges[i].billing_id,
            bill_type: this.diag_charges[i].type,
            sub_type: this.diag_charges[i].sub_type,
            created_date: this.diag_charges[i].created_date,
            created_time: this.diag_charges[i].created_time,
            created_date_time: created_date_time,
            biill_type: this.diag_charges[i].charge_desc,
            amount_txt: parseFloat(this.diag_charges[i].amount),
            concession: concession,
            fee: parseFloat(this.diag_charges[i].final_amount),
            charge_type: this.diag_charges[i].charge_id,
            paytype: this.pay_type,
            pay_type: paydesc,
            insurer: this.insurer_id,
            quantity: parseInt(this.diag_charges[i].quantity),
            total: total,
            edit_flag: true,
          })
        }
      }

      if (diag_charges.length != 0) {
        var diag_bill_array = [];
        var sub_total = 0;
        diag_charges.forEach(diag => {
          diag_bill_array.push(diag)
          sub_total = sub_total + diag.total;
        })

        this.categoryChargeList.push({
          bill_type: "Diagnosis",
          charges: diag_bill_array,
          sub_total: sub_total
        });
      }

      if (this.categoryChargeList.length != 0) {
        this.rowid = 1;
        for (var i = 0; i < this.categoryChargeList.length; i++) {
          for (var j = 0; j < this.categoryChargeList[i].charges.length; j++) {
            this.categoryChargeList[i].charges[j].sno = this.rowid;
            this.rowid += 1;
          }
        }
      }
    }

    var pharma_charges = [];
    if (this.pharma_charges.length != 0 && this.pharma_bills.length != 0) {
      if (this.med_with_det == true) {
        for (var i = 0; i < this.pharma_charges.length; i++) {
          var total = (parseFloat(this.pharma_charges[i].quantity) * parseFloat(this.pharma_charges[i].amount));
          var concession = this.pharma_charges[i].concession != undefined ? this.pharma_charges[i].concession : parseFloat("0.00");
          var created_date_time = this.pharma_charges[i].created_date != undefined ? Date_Formate(this.pharma_charges[i].created_date) + " " + Time_Formate(this.pharma_charges[i].created_time) : '';
          pharma_charges.push({
            sno: i + 1,
            billing_id: this.pharma_charges[i].billing_id,
            bill_type: this.pharma_charges[i].type,
            created_date: this.pharma_charges[i].created_date,
            created_time: this.pharma_charges[i].created_time,
            created_date_time: created_date_time,
            biill_type: this.pharma_charges[i].charge_desc,
            amount_txt: Math.round(this.pharma_charges[i].amount),
            concession: concession,
            fee: Math.round(this.pharma_charges[i].final_amount),
            charge_type: this.pharma_charges[i].charge_id,
            paytype: this.pay_type,
            pay_type: paydesc,
            insurer: this.insurer_id,
            quantity: parseInt(this.pharma_charges[i].quantity),
            total: Math.round(this.pharma_charges[i].final_amount),
            edit_flag: true,
          })
        }
      } else {
        if (this.pharma_bills != undefined && this.pharma_bills.length != 0) {
          for (var i = 0; i < this.pharma_bills.length; i++) {
            var total = Math.round(this.pharma_bills[i].bill_amount);
            var concession:any = this.pharma_bills[i].bill_disc != undefined ? parseFloat(this.pharma_bills[i].bill_disc) : parseFloat("0.00");
            var created_date_time = this.pharma_bills[i].created_date != undefined ? Date_Formate(this.pharma_bills[i].created_date) + " " + Time_Formate(this.pharma_bills[i].created_time) : '';
            pharma_charges.push({
              sno: i + 1,
              billing_id: this.pharma_bills[i].billing_id,
              bill_type: "pharma",
              created_date: this.pharma_bills[i].created_date,
              created_time: this.pharma_bills[i].created_time,
              created_date_time: created_date_time,
              biill_type: "Medicine Bill No - " + this.pharma_bills[i].billing_id,
              amount_txt: Math.round(this.pharma_bills[i].bill_amount),
              concession: concession,
              fee: Math.round(this.pharma_bills[i].bill_amount),
              charge_type: this.pharma_bills[i].charge_id,
              paytype: this.pay_type,
              pay_type: paydesc,
              insurer: this.insurer_id,
              quantity: "-",
              total: total,
              edit_flag: true,
            })
          }
        }      
      }
      
      if (pharma_charges.length != 0) {
        var pharma_bill_array = [];
        var sub_total = 0;
        pharma_charges.forEach(pharma => {
          pharma_bill_array.push(pharma)
          sub_total = sub_total + pharma.total;
        })

        this.categoryChargeList.push({
          bill_type: "Medicines",
          charges: pharma_bill_array,
          sub_total: sub_total
        });
      }

      if (this.categoryChargeList.length != 0) {
        this.rowid = 1;
        for (var i = 0; i < this.categoryChargeList.length; i++) {
          for (var j = 0; j < this.categoryChargeList[i].charges.length; j++) {
            this.categoryChargeList[i].charges[j].sno = this.rowid;
            this.rowid += 1;
          }
        }
      }
    } 

    this.billaddlist = this.combineCharges(this.categoryChargeList);
    if (this.billaddlist.length == 0 && this.wardName == 'Dialysis') {
      this.addNewBillDetails();
    }
    this.rowid = 1;
    this.hospitalBills = 0;
    for (var i = 0; i < this.billaddlist.length; i++) {
      this.billaddlist[i].sno = this.rowid;
      this.rowid += 1;
      if (this.billaddlist[i].fee == '') {
        this.billaddlist[i].fee = 0;
      }
      this.hospitalBills = (
        parseFloat(this.hospitalBills) + parseFloat(this.billaddlist[i].fee)
      );
    }
    this.Calculate_gst('0', '0');
    this.calculateHospitalGst('0', '0');
  }

  createGroupping(hospital_charges) {
    console.log(hospital_charges);
    this.categoryChargeList = [];
    this.categoryChargeList = hospital_charges;
    const groupedByCategory = this.groupBy(this.categoryChargeList, 'category');
    console.log("groupedByCategory", groupedByCategory);
    var hospitalChargeArray2 = [];
    var new_viewchargearray = [];
    for (var key in groupedByCategory) {
      console.log(key + ': ' + JSON.stringify(groupedByCategory[key]));
      if (key !== 'undefined') {
        console.log(key);
        new_viewchargearray = [];
        var sub_total = 0;
        for (var k = 0; k < groupedByCategory[key].length; k++) {
          var charge_order_list = groupedByCategory[key][k].order_no;
          var category_Name = "";
          if (groupedByCategory.length > 1) {
            category_Name = groupedByCategory[key][k].category;
          }

          new_viewchargearray.push({
            category: key,
            order_no: charge_order_list,
            sno: k + 1,
            bill_type: groupedByCategory[key][k].bill_type,
            created_date_time: groupedByCategory[key][k].created_date_time,
            created_date: groupedByCategory[key][k].created_date,
            created_time: groupedByCategory[key][k].created_time,
            biill_type: groupedByCategory[key][k].biill_type,
            amount_txt: groupedByCategory[key][k].amount_txt,
            concession: groupedByCategory[key][k].concession,
            fee: groupedByCategory[key][k].fee,
            charge_type: groupedByCategory[key][k].charge_type,
            pay_type: groupedByCategory[key][k].pay_type,
            paytype: groupedByCategory[key][k].paytype,
            insurer: groupedByCategory[key][k].insurer,
            quantity: groupedByCategory[key][k].quantity,
            total: groupedByCategory[key][k].total,
            edit_flag: groupedByCategory[key][k].edit_flag,
            charge_cat_id: groupedByCategory[key][k].charge_cat_id,
          })
          sub_total = sub_total + groupedByCategory[key][k].total;
        }
        var category_flag;
        if (new_viewchargearray.length == 0)
          category_flag = false;
        else
          category_flag = true;

        hospitalChargeArray2.push({
          category: key,
          category_flag: category_flag,
          order_no: charge_order_list,
          charges: new_viewchargearray.sort((a, b) => a.order_no - b.order_no),
          sub_total: sub_total,
        })
      }
    }

    this.categoryChargeList = [];
    this.categoryChargeList = hospitalChargeArray2.sort((a, b) => a.order_no - b.order_no);
    this.rowid = 1;
    for (var i = 0; i < this.categoryChargeList.length; i++) {
      for (var j = 0; j < this.categoryChargeList[i].charges.length; j++) {
        this.categoryChargeList[i].charges[j].sno = this.rowid;
        this.rowid += 1;
      }
    }
    console.log("hospitalChargeArray2", hospitalChargeArray2);
    console.log("this.categoryChargeList", this.categoryChargeList);
  }

  combineCharges(groupedData) {
    // Use reduce to iterate over each item and combine their charges
    return groupedData.reduce((combinedCharges, item) => {
      return combinedCharges.concat(item.charges);
    }, []);
  }

  groupBy(array, key) {
    return array.reduce((result, obj) => {
      const keyValue = obj[key];
      // If the key doesn't exist in the result, create an empty array for it
      if (!result[keyValue]) {
        result[keyValue] = [];
      }
      // Push the object to the corresponding key's array
      result[keyValue].push(obj);
      return result;
    }, {});
  }

  addNewBillDetails() {
    this.categoryChargeList.push({
      charges: [{
        sno: this.rowid,
        biill_type: '',
        amount_txt: '',
        concession: '',
        fee: '',
        charge_type: '',
        pay_type: '',
        insurer: '',
        ref_code: '',
        quantity: '',
        total: '',
      }],
      category_flag: true
    });

    this.rowid = 1;
    for (var i = 0; i < this.categoryChargeList.length; i++) {
      for (var j = 0; j < this.categoryChargeList[i].charges.length; j++) {
        this.categoryChargeList[i].charges[j].sno = this.rowid;
        this.rowid += 1;
      }
    }

    console.log(this.categoryChargeList);
    setTimeout(function () {
      $(".getChargeDesc").last().focus();
    }, 500);
  }

  getamountAftCon1(amount) {
    var RateCard = this.RateCardArray;
    for (var i = 0; i < RateCard.length; i++) {
      if (this.rate_card == RateCard[i].rate_card_id) {
        if (RateCard[i].discount == 0 && RateCard[i].rate_card_desc != 'Service wise') {
          return (this.amount_aftcon = parseFloat(amount) * this.quantity);
        } else {
          return (this.amount_aftcon =
            parseFloat(amount) * this.quantity -
            (this.concession_txt / 100) *
            (parseFloat(amount) * this.quantity));
        }
      }
    }
  }

  //Get payment type
  getPaymentType() {
    var obj = Master_Helper.getMasterPaymentType();
    if (obj.payments != undefined && obj.payments != null && obj.payments.length != 0) {
      this.paytypelist = obj.payments;
      if (this.paytypreq == false) {
        this.pay_type = this.paytypelist[0].pay_id;
      }
      this.payType1 = this.paytypelist[0].pay_id;
    }
  }

  pay_change() {
    document.getElementById('paytype')!.style.borderColor = '#2ca8fe';
    if (this.pay_type == '3') {
      this.insurFlag = false;
    } else {
      this.insurFlag = true;
    }
  }

  //Get payment type
  getInsurers() {
    var obj = Master_Helper.getMasterInsurers();
    if (obj != undefined)
      this.insurerlist = obj.insurers;
  }

  getConcession() {
    for (var i = 0; i < this.RateCardArray.length; i++) {
      if (this.RateCardArray[i].rate_card_id == this.rate_card) {
        if (this.RateCardArray[i].rate_card_desc == 'Total Bill') {
          this.paid_amount_flag = true;
        } else {
          this.paid_amount_flag = false;
        }
        if (this.RateCardArray[i].rate_card_desc == 'Service wise') {
          this.discount_flag = false;
          if (this.concession_txt != '') {
            this.concession_txt = parseFloat(this.concession_txt);
          } else {
            this.concession_txt = parseFloat(this.RateCardArray[i].discount);
          }
        } else {
          this.discount_flag = true;
        }
        if (this.RateCardArray[i].rate_card_desc != 'Service wise') {
          this.concession_txt = parseInt(this.RateCardArray[i].discount);
          console.log(this.concession_txt);
        }
        this.flag_function();
      }
    }
    if (this.billaddlist != undefined && this.billaddlist.length != 0) {
      for (var i = 0; i < this.categoryChargeList.length; i++) {
        for (var j = 0; j < this.categoryChargeList[i].charges.length; j++) {
          this.categoryChargeList[i].charges[j].concession == this.concession_txt;
        }
      }
      this.billaddlist = this.combineCharges(this.categoryChargeList);
      for (var i = 0; i < this.billaddlist.length; i++) {
        this.billaddlist[i].concession = parseFloat(this.concession_txt);
        this.changeamount1(this.billaddlist[i], 0);
      }
    }
  }

  charge_change(charge) {
    this.charge_type = charge.description;
    this.serviceType = [];
    for (var i = 0; i < this.charge_name.length; i++) {
      if (this.charge_name[i].description == this.charge_type) {
        this.amount_txt = this.charge_name[i].amount;
        this.amount_aftcon = this.charge_name[i].amount;
      }
    }
    this.addbtn_flag = false;
  }

  changeChargeType1(charge) {
    var result = this.finekeyInArray_billaddChargeType(
      this.billaddlist,
      charge.charge_id
    );
    if (result == true && this.wardName != "Dialysis") {
      this.toastr.error('Charge type already exists');
      this.serviceType = [];
      // person.biill_type = '';
      this.biill_type = '';
    } else {
      this.addNewBillDetails();
      this.Get_current_date();
      setTimeout(()=>{
        if (this.hosp_charges != undefined) {
          this.hosp_charges.push({
            amount: parseInt(charge.amount),
            category_desc: charge.category_desc,
            charge_category_id: charge.charge_cat_id,
            charge_desc: charge.description,
            charge_id: charge.charge_id,
            created_date: this.currentDate,
            created_time: this.currentTime,
            final_amount: this.getamountAftCon1(charge.amount),
            order_no: charge.order_no,
            quantity: '1.0',
            type: "hospital",
          })
        }
  
        console.log("hosp_charges", this.hosp_charges);
        this.Get_Bill_Type();
        this.serviceType = [];  
        this.biill_type = '';
      },400)
    }
  }

  getServiceType() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(
        ipaddress.getIp.toString() + 'bill/chgtyped',
        JSON.stringify({
          hptl_clinic_id: this.hptl_clinic_id,
          provider: 'doctor',
          type: 'in-patient',
        }),
        { headers: headers }
      )
      .subscribe(
        (response) => {
          var obj = response.json();
          console.log(obj);
          Helper_Class.setBillCharges(obj);
          this.charge_name = Helper_Class.getBillCharges();
          console.log(this.charge_name);
          if (this.charge_name != undefined && this.charge_name.charges != 0) {
            this.charge_name = this.charge_name.charges;
          }
          console.log(this.charge_name);
        },
        (error) => {
          this.toastr.error(Message_data.getNetworkMessage());
        }
      );
  }

  getServiceType1(bill_type) {
    this.serviceType = [];
    var filteredItems = [];
    if (bill_type.length >= 2 && bill_type !== undefined) {
      if (this.charge_name.length !== 0) {
        if (
          this.department_id != undefined &&
          this.department_id != '' &&
          this.ward_id != undefined &&
          this.ward_id != ''
        ) {
          if (this.wardbasecharge == "1") {
            filteredItems = this.charge_name.filter(
              (o) =>
                o.description.toLowerCase().includes(bill_type.toLowerCase()) &&
                o.department_id.includes(this.department_id) &&
                o.ward_id.includes(this.ward_id)
            );
          } else {
            filteredItems = this.charge_name.filter((o) =>
              o.description.toLowerCase().includes(bill_type.toLowerCase())
            );
          }

        } else {
          filteredItems = this.charge_name.filter((o) =>
            o.description.toLowerCase().includes(bill_type.toLowerCase())
          );
        }

        if (filteredItems.length > 0) {
          filteredItems.forEach((o) => {
            this.serviceType.push({
              description: o.description,
              amount: o.amount,
              charge_id: o.charge_id,
              category_desc: o.category_desc,
              charge_cat_id: o.charge_cat_id,
              order_no: o.order_no
            });
          });
        } else {
          this.serviceType = [];
        }
      }
    }
  }

  handleKeyUp(event: KeyboardEvent, biill_type) {
    const key = event.key;
    if (key !== 'ArrowUp' && key !== 'ArrowDown') {
      var biill_type;
      this.getServiceType1(biill_type);
    }
    if (key === 'Enter') {
      let charge;
      for (let i = 0; i < this.serviceType.length; i++) {
        if (this.serviceType[i].description === biill_type) {
          const { description, amount, charge_id, category_desc, charge_cat_id, order_no } = this.serviceType[i];
          charge = {
            description,
            amount,
            charge_id,
            category_desc,
            charge_cat_id,
            order_no
          };
          break;
        }
      }
      if (biill_type) {
        this.changeChargeType1(charge);
      }
    }
  }

  getBillDetails() {
    this.billden_plan = [];
    this.billaddlist = [];
    this.billAddList = [];
    this.categoryChargeList = [];
    this.medcalDetailsArray = [];
    this.digDetailsArray = [];
    this.billing_print_array = [];
    this.quantityArray = [];
    this.other_charges_array = [];
    this.hosp_charges = [];
    this.diag_charges = [];
    this.diagnosis_bills = [];
    this.pharma_charges = [];
    this.pharma_bills = [];
    this.final_amount = 0;
    this.totalBillAmount = 0;
    this.remaing_billbal = 0;
    this.balance_data = 0;
    this.Advance_bill = 0;
    this.mrno = '';
    this.clnt_saldesc = '';
    this.patient_name = '';
    this.clnt_age = '';
    this.gender = '';
    this.mobile_no = '';
    this.doctor_name = '';
    this.admitted_date = '';
    this.admitted_time = '';
    this.e_contact = '';
    this.dept_desc = '';
    this.wardName = '';
    this.bed_no = '';
    this.ward_id = '';
    this.profileImg = '';
    this.hospitalBills = 0;
    this.doc_visitscreate = [];
    this.doctorVisitsChargeArray = [];
    this.rate_card_disc = 0;
    this.advanceList = [];
    this.refund_array = [];
    this.acc_number_adv = '';
    this.holder_Name_adv = '';
    this.bank_Name_adv = '';
    this.bank_ifc_code_adv = '';
    // this.pending_flag = false;
    // this.finalized_flag = false;
    this.local_paid_bill = 0;
    this.insurance_flag = false;
    this.pharma_bill_flag = false;
    this.spons_set_amount = 0;
    this.spons_tds_percen = 0;
    this.tds_amount = 0;
    this.hosp_pay_array = [];

    var sendData = JSON.stringify({
      billing_id: this.billId,
      fin_year: this.finyear,
      center_id: this.userinfo.hptl_clinic_id
    })
    
    var headers = new Headers();   
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'bill/gdobi' ,sendData,  
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          console.log("Bill details "+ JSON.stringify(obj));
          if (obj.bills != null) {
            this.appointment_id = obj.appointment_id != undefined ?  obj.appointment_id : "";
            this.department_id = obj.department_id != undefined ? obj.department_id : "";
            console.log('Department_Id', this.department_id);

            this.ward_id = obj.ward_id != undefined ? obj.ward_id : "";
            console.log('Ward_Id', this.ward_id);

            if (obj.middle_name != undefined) {
              this.patient_name =
                encrypt_decript.Decript(obj.first_name) +
                ' ' +
                encrypt_decript.Decript(obj.middle_name) +
                ' ' +
                encrypt_decript.Decript(obj.last_name);
            } else {
              if (obj.last_name != undefined) {
                this.patient_name =
                  encrypt_decript.Decript(obj.first_name) +
                  ' ' +
                  encrypt_decript.Decript(obj.last_name);
              } else {
                this.patient_name = encrypt_decript.Decript(
                  obj.first_name
                );
              }
            }

            this.dog_reg_id = obj.doc_reg_id;
            var zipcode =
              obj.zipcode != undefined
                ? encrypt_decript.Decript(obj.zipcode)
                : '';
            this.mrno = obj.client_hosp_id;

            if (obj.salutation != undefined) {
              this.clnt_saldesc = obj.salutation + '.';
            }

            this.clnt_age = obj.age;
            if (
              obj.gender != null &&
              obj.gender != undefined
            ) {
              this.gender = encrypt_decript.Decript(
                obj.gender
              );
              this.gender = this.gender[0];
              if (this.gender[0] == 'T') {
                this.gender = 'Tgen';
              }
            }

            this.mobile_no =
              obj.mobile != undefined 
                ? encrypt_decript.Decript(obj.mobile)
                : '';
            this.e_contact =
              obj.emerg_contact != undefined
                ? obj.emerg_contact
                : '';
            this.admitted_date =
              obj.admission_date != undefined
                ? obj.admission_date
                : '';
            this.admitted_time = obj.admission_time != undefined ? Time_Formate(obj.admission_time) : '';
            this.discharge_date =
              obj.discharge_date != undefined
                ? Date_Formate(obj.discharge_date)
                : '';
            if (
              obj.discharge_time != undefined &&
              obj.discharge_time != ''
            ) {
              this.discharge_time = Time_Formate(
                obj.discharge_time
              );
            }
            if (
              obj.dr_middle_name != undefined &&
              obj.dr_middle_name != null
            ) {
              this.doctor_name =
                'Dr.' +
                obj.dr_first_name +
                ' ' +
                obj.dr_middle_name +
                ' ' +
                obj.dr_last_name;
            } else if (
              obj.dr_first_name != undefined &&
              obj.dr_last_name != undefined
            ) {
              this.doctor_name =
                'Dr.' +
                obj.dr_first_name +
                ' ' +
                obj.dr_last_name;
            }

            if (obj.doc_qualif != undefined && obj.doc_qualif != null && obj.doc_qualif != "") {
              this.doctor_name = this.doctor_name + " " + obj.doc_qualif;
            }
              
            this.dept_desc =
              obj.department_desc != undefined
                ? obj.department_desc
                : '';
            this.wardName =
              obj.ward_name != undefined
                ? obj.ward_name
                : '';
            this.bed_no =
              obj.bed_no != undefined
                ? obj.bed_no
                : '';
            this.profileImg =
              obj.profile_image != undefined
                ? ipaddress.Ip_with_img_address + obj.profile_image
                : '';

           
            if (obj.bills != undefined && obj.bills != null) {
              for (var b = 0; b < obj.bills.length; b++) {
                if (obj.bills[b].type == 'hospital')
                this.hosp_charges.push({
                  amount: obj.bills[b].amount_txt,
                  billing_id: obj.bills[b].billing_id,
                  category_desc: obj.bills[b].category_desc,
                  charge_category_id: obj.bills[b].charge_category_id,                   
                  charge_desc: obj.bills[b].charge_desc,
                  charge_id: obj.bills[b].charge_id,
                  created_date: obj.bills[b].created_date,
                  created_time: obj.bills[b].created_time,
                  final_amount: obj.bills[b].fee,
                  order_no: obj.bills[b].order_no,
                  quantity: obj.bills[b].quantity,
                  type: obj.bills[b].type
                })

                if (obj.bills[b].type == 'diagnosis') {
                  this.diag_charges.push({
                    amount: obj.bills[b].amount_txt,
                    billing_id: obj.bills[b].billing_id,
                    charge_desc: obj.bills[b].charge_desc,
                    charge_id: obj.bills[b].charge_id,
                    concession: obj.bills[b].concession,
                    created_date: obj.bills[b].created_date,
                    created_time: obj.bills[b].created_time,
                    fee_before_concession: obj.bills[b].amount_txt,
                    final_amount: obj.bills[b].fee,
                    quantity: obj.bills[b].quantity,
                    sub_type: obj.bills[b].type,
                    type: obj.bills[b].type,
                  })
                }

                if (obj.bills[b].type == 'pharma') {
                  this.pharma_charges.push({
                    amount: obj.bills[b].amount_txt,
                    billing_id: obj.bills[b].billing_id,
                    charge_desc: obj.bills[b].charge_desc,
                    charge_id: obj.bills[b].charge_id,
                    created_date: obj.bills[b].created_date,
                    created_time: obj.bills[b].created_time,
                    final_amount: obj.bills[b].fee,
                    quantity: obj.bills[b].quantity,
                    type: obj.bills[b].type
                  })
                }
              }
            }

            // if (obj.bills != undefined && obj.bills != null) {
            //   for (var b = 0; b < obj.bills.length; b++) {
            //     if (obj.bills[b].type == 'diagnosis') {
            //       this.diagnosis_bills.push({
            //         advance: '0.00',
            //         balance: obj.bills[b].fee,
            //         bill_amount: obj.bills[b].fee,
            //         billing_id: obj.bills[b].billing_id,
            //         created_date: obj.bills[b].created_date,
            //         created_time: obj.bills[b].created_time,
            //         paid_amount: "0.00",
            //         paid_flag: "Un paid",
            //         sub_type: obj.bills[b].type
            //       })
            //     }

            // if (obj.bills[b].type == 'pharma') {
            //   this.pharma_bills.push({
            //     balance: obj.bills[b].fee,
            //     bill_amount: obj.bills[b].fee,
            //     bill_disc: "0.00",
            //     billing_id: obj.bills[b].billing_id,
            //     created_date: obj.bills[b].created_date,
            //     created_time: obj.bills[b].created_time,
            //     paid_amount: "0.00",
            //     paid_flag: "Un paid",
            //   })
            // }
            //   } 
            // }  
            if (obj.diagnosis_bills != undefined && obj.diagnosis_bills != null) {
              this.diagnosis_bills = obj.diagnosis_bills;
            } 

            if (obj.pharma_bills != undefined && obj.pharma_bills != null) {
              this.pharma_bills = obj.pharma_bills;
              if (obj.pharma_bills.length != 0) {
                this.pharma_bill_flag = true;
              }
            }

            if (obj.approved_amount != undefined) {
              this.sponsorDue = obj.approved_amount;
            }

            var advanceList = [];
            if (obj.advances != undefined) {
              advanceList = obj.advances;
              console.log("advanceList",advanceList);
              if (advanceList.length != 0) {
                for (var j = 0; j < advanceList.length; j++) {
                  var created_date_time = advanceList[j].created_date != undefined ? Date_Formate(advanceList[j].created_date) + " " + Time_Formate(advanceList[j].created_time) : '';
                  this.advanceList.push({
                    sno: j + 1,
                    advance_id: advanceList[j].advance_id,
                    created_date_time: created_date_time,
                    created_by: advanceList[j].created_by,
                    payment_desc: advanceList[j].payment_desc,
                    amount: parseFloat(advanceList[j].amount),
                    balance: parseFloat(advanceList[j].balance)
                  })
                }
              }
            }

            console.log("advanceList", this.advanceList);
            console.log("hosp_charges", this.hosp_charges, "diag_charges", this.diag_charges, "pharma_charges", this.pharma_charges);
            console.log("diagnosis_bills", this.diagnosis_bills, "pharma_bills", this.pharma_bills);
            this.rate_card_disc = obj.rate_card_disc != undefined ? obj.rate_card_disc : 0;
            this.surgery = obj.surgery_desc != undefined ? obj.surgery_desc : '';
            this.bill_type_pharma = obj.bill_incl_pharma != undefined ? obj.bill_incl_pharma : '';
            this.barcode_url = obj.barcode;

            var address1 = '',
              address2 = '';
            if (
              obj.caddress1 != null &&
              obj.caddress2 != undefined &&
              obj.caddress1 != undefined
            ) {
              address1 =
                encrypt_decript.Decript(obj.caddress1) + ',';
              if (
                obj.caddress2 != ' ' &&
                obj.caddress2 != 'null'
              ) {
                if (
                  encrypt_decript.Decript(obj.caddress2) == ''
                ) {
                  address2 == '';
                } else {
                  address2 =
                    encrypt_decript.Decript(obj.caddress2) +
                    ',';
                }
              }
            } else if (obj.caddress1 != undefined) {
              address1 =
                encrypt_decript.Decript(obj.caddress1) + ',';
            } else if (obj.caddress2 != undefined) {
              address2 =
                encrypt_decript.Decript(obj.caddress2) + ',';
            }

            var location;
            if (
              obj.clocation != undefined &&
              obj.clocation != ''
            ) {
              location = obj.clocation + ',';
            }

            var city = '';
            if (
              obj.ccity != undefined &&
              obj.ccity != null
            ) {
              city = obj.ccity + '-';
            }

            var zipcode;
            if (
              obj.czipcode != undefined &&
              obj.czipcode != null
            ) {
              zipcode =
                encrypt_decript.Decript(obj.czipcode) + ',';
            }

            var state = '';
            if (
              obj.cstate != undefined &&
              obj.cstate != null
            ) {
              state = obj.cstate + '-';
            }

            var country = '';
            if (
              obj.ccountry != undefined &&
              obj.ccountry != null
            ) {
              country = obj.ccountry + '.';
            }
            this.totalBillAmount = obj.bill_amount != undefined ? parseFloat(encrypt_decript.Decript(obj.bill_amount)) : 0;
            this.Advance_bill = obj.advance != undefined ? parseFloat(obj.advance) : 0;
            this.sponsorDue = obj.sponsor_due != undefined ? parseFloat(obj.sponsor_due) : 0;
            this.sponsorDueflag = obj.sponsor_due != undefined && obj.sponsor_due != 0 && obj.sponsor_due != '0.00' && obj.sponsor_due != '0' ? true : false;
            this.discount_amount = obj.discount != undefined ? parseFloat(obj.discount) : 0;
            this.paid_bill = obj.paid_amount != undefined ? parseFloat(obj.paid_amount) : 0;
            this.local_paid_bill = this.paid_bill;
            this.remaing_billbal = obj.balance != undefined ? parseFloat(obj.balance) : 0;
            this.paymentsarray = obj.payments != undefined ? obj.payments : [];
            
            if (obj.status != undefined && (obj.status == 2 || obj.status == '2')) {
              if (obj.payments != undefined && obj.payments.length != 0) {
                this.hosp_pay_array = JSON.parse(JSON.stringify(obj.payments));
                console.log("hosp_pay_array",this.hosp_pay_array);
              }   
            }

            if (obj.status != undefined && (obj.status == 0 || obj.status == '0') && this.userinfo.user_type == 'Admin') {
              if (obj.payments != undefined && obj.payments.length != 0) {
                this.hosp_pay_array = JSON.parse(JSON.stringify(obj.payments));
                console.log("hosp_pay_array",this.hosp_pay_array);
              }   
            }
            
            this.amountval = 0;
            var inpatient_id;
            if (obj.inp_hosp_id != undefined) {
              inpatient_id = obj.inp_hosp_id;
              this.inpatientflag = true;
            } else {
              if (obj.appointment_id != undefined) {
                inpatient_id = obj.appointment_id;
              }      
              this.inpatientflag = false;        
            }
            if (obj.created_date != undefined) {
              var get_date = obj.created_date.toString().split('-');
              var datetxt = get_date[2] + "-" + get_date[1] + "-" + get_date[0]+" "+Time_Formate(obj.created_time);
            }

            if (obj.barcode != undefined && obj.barcode != null) {
              this.barcode_flag = true;
              this.barcode_url = ipaddress.Ip_with_img_address + obj.barcode;
            }

            if (obj.status != undefined && (obj.status == 0 || obj.status == '0')) {
              // this.pending_flag = true;
              // this.finalized_flag = true;
              // this.selectedValue = 'settled';
              // this.status_billtype = 'settled'
              // this.billType('settled');
            } else if (obj.status != undefined && (obj.status == 1 || obj.status == '1')) {
              // this.pending_flag = false;
              // this.finalized_flag = false;
              // this.selectedValue = 'pending';
              // this.status_billtype = 'pending'
              // this.billType('pending');
            } else if (obj.status != undefined && (obj.status == 2 || obj.status == '2')) {
              // this.pending_flag = true;
              // this.finalized_flag = false;
              // this.selectedValue = 'finalized';
              // this.status_billtype = 'finalized'
              // this.billType('finalized');
            }

            if (obj.hosp_id != undefined) {
              this.hosp_id = obj.hosp_id;
            }

            if (obj.pat_type != undefined && obj.pat_type == 'insurance') {
              this.insurance_flag = true;
            } else {
              this.insurance_flag = false;
            }

            if (obj.sponsor_tds != undefined) {
              this.spons_tds_percen = obj.sponsor_tds;
            }

            this.insurer = obj.insurer != undefined ? obj.insurer : '';
            this.sponser_name = obj.tpa != undefined ? obj.tpa : '';
            this.sponser_GSTIN = obj.policy_no != undefined ? obj.policy_no : '';
            this.payment_mode = obj.payment_mode != undefined ? obj.payment_mode : '';
            this.spons_set_amount = obj.sponsor_settle_amount != undefined ? parseFloat(obj.sponsor_settle_amount) : 0;
            if (obj.tds_amount != undefined && obj.tds_amount != 0 && parseFloat(obj.tds_amount) != 0) {
              this.tds_amount = parseFloat(obj.tds_amount);
              this.inTDS = true;
            }
            
            this.billing_print_array.push({
              dr_name: this.doctor_name,
              licence_code: '',
              hptl_name: '',
              created_date: datetxt,
              address1: address1,
              address2: address2,
              location: location,
              city: city + zipcode,
              state: state + country,
              inpatient_id: inpatient_id,
              appointment_id: obj.appointment_id,
              client_name: this.patient_name,
              client_reg_id: obj.client_reg_id,
              advance: obj.advance,
              balance: obj.balance,
              relation_id: obj.relation_id,
              sub_rel_id: obj.sub_rel_id,
            });
            console.log("paid_bill",this.paid_bill);
            this.createpage_flag = true;
            this.Get_Bill_Type();

            if (this.surgery == '' && this.credit_type == '') {
              this.barcode_style = "width: 157px; height: auto; margin-left: 0px; margin-top: 0px;";
              this.nobanner_style = "width: 100%; height: 95px;";
            } else if (this.surgery != '' && this.credit_type != '') {
              this.barcode_style = "width: 157px; height: auto; margin-left: 0px; margin-top: 0px;";
              this.nobanner_style = "width: 100%; height: 115px;";
            } else if (this.surgery != '' || this.credit_type != '') {
              this.barcode_style = "width: 157px; height: auto; margin-left: 0px; margin-top: 0px;";
              this.nobanner_style = "width: 100%; height: 110px;";
            }
          }
        }) 
        this.medicinBal = 0;
        this.diagnosisAmount = 0;
  }

  digAmount(id, value) {
    for (var i = 0; i < this.digDetailsArray.length; i++) {
      if (this.digDetailsArray[i].digID == id) {
        var val = value != '' ? value : 0;
        var previous = this.digDetailsArray[i].previous_value;
        this.current_amount = (
          parseFloat(this.current_amount) -
          parseFloat(this.digDetailsArray[i].previous_value)
        );
        this.current_amount = (
          parseFloat(this.current_amount) + parseFloat(val)
        );
        this.total_bill1 = this.current_amount;
        this.total_bill = this.current_amount;
        this.final_amount = this.current_amount;
        if (value != '') {
          this.digDetailsArray[i].amount_txt = val;
          this.digDetailsArray[i].fee = val;
          this.digDetailsArray[i].previous_value = val;
        } else {
          this.digDetailsArray[i].amount_txt = 0;
          this.digDetailsArray[i].fee = 0;
          this.digDetailsArray[i].previous_value = 0;
        }
      }
    }
  }

  amount_change() {
    var perce!: number | null;
    perce = this.concession_txt / 100;
    var amt = this.amount_txt;
    var amt_cons = this.amount_aftcon;
    var net_pay = parseFloat(amt) * parseFloat(perce.toString());
    var tot = amt - net_pay;
    if (isNaN(tot)) {
      tot = 0;
    }
    this.amount_aftcon = Math.round(tot) * parseInt(this.quantity);
  }

  changeamount(charge: any) {
    for (var i = 0; i < this.chargeNameArray.length; i++) {
      if (charge.charge_type == this.chargeNameArray[i].charge_type) {
        var concec;
        if (charge.concession == 'NaN') {
          concec = 0;
        } else {
          concec = charge.concession;
        }
        charge.fee =
          charge.quantity *
          (charge.amount_txt - charge.amount_txt * (concec / 100));
        charge.fee = parseInt(charge.fee);
      }
    }
  }

  changeamount1(charge, num) {
    this.hospitalBills = 0;
    if (this.billaddlist != undefined && this.billaddlist.length != 0) {
      for (var i = 0; i < this.billaddlist.length; i++) {
        if (charge.biill_type == this.billaddlist[i].biill_type) {
          if (this.hosp_charges.length != 0) {
            for (var h = 0; h < this.hosp_charges.length; h++) {
              if (charge.charge_type == this.hosp_charges[h].charge_id) {
                this.hosp_charges[h].amount = charge.amount_txt;
                this.hosp_charges[h].quantity = charge.quantity;
                this.hosp_charges[h].final_amount = charge.amount_txt * charge.quantity;
              } 
            }
          }
          console.log("hosp_charges",this.hosp_charges);
          if (num != 2) {
            var concec;
            if (charge.concession == 'NaN') {
              charge.concession = 0;
              concec = 0;
            } else {
              concec = charge.concession;
            }
            charge.fee = (charge.quantity * (charge.amount_txt - charge.amount_txt * (concec / 100)));
            charge.fee = parseFloat(charge.fee);
            charge.total = charge.quantity * charge.amount_txt;
            charge.total = parseInt(charge.total);
          } else {
            var fee;
            if (charge.fee == 'NaN') {
              charge.fee = 0;
              fee = 0;
            } else {
              fee = charge.fee;
            }

            if (charge.amount_txt != 0 && charge.quantity != 0 && charge.fee != '') {
              charge.concession = (100 - ((fee / (charge.amount_txt * charge.quantity)) * 100));
            } else {
              charge.concession = 0;
            }
          }
        }
      }
      console.log("categoryChargeList", this.categoryChargeList);
      this.billaddlist = this.combineCharges(this.categoryChargeList);
      for (var i = 0; i < this.billaddlist.length; i++) {
        if (this.billaddlist[i].fee == '') {
          this.billaddlist[i].fee = 0;
        }
        this.hospitalBills = (
          parseFloat(this.hospitalBills) + parseFloat(this.billaddlist[i].fee)
        );
      }
      console.log("billaddlist", this.billaddlist);
      this.Calculate_gst('0', num);
      this.calculateHospitalGst('0', '0');
      this.subtotalCalculation();
      // this.discount_amount = 0;
      // this.discountvalue();
      // this.calculatePaidAmount();
    }
  }

  subtotalCalculation() {
    if (this.categoryChargeList.length != 0 ) {
      for (var i = 0; i < this.categoryChargeList.length; i++) {
        if (this.categoryChargeList[i].charges.length != 0) {
          var sub_total = 0;
          for (var j = 0; j < this.categoryChargeList[i].charges.length; j++) { 
            if (this.categoryChargeList[i].charges[j].total != undefined) {
              sub_total = sub_total + this.categoryChargeList[i].charges[j].total;
            }
          }
          this.categoryChargeList[i].sub_total = sub_total;
        }      
      }
    }
  }

  //Get gst
  async Get_Gst_Data() {
    var obj = Master_Helper.getMasterGSTData();
    if (Master_Helper.getMasterGSTData() == undefined) {
      await this.masterData.getGstData();
    }
    var obj = Master_Helper.getMasterGSTData();
    if (obj != undefined) this.gst_retrive_list = obj.taxes;
  }

  // Include GST
  include_gst_click(gst_click) {
    this.calculateHospitalGst('0', '0');
    this.Calculate_gst('0', '0');
  }

  includeTDSAmount(tds_click) {
    this.tds_amount = 0;
    if (this.inTDS) {
      if (this.spons_set_amount == undefined || this.spons_set_amount == 0) {
        this.inTDS = false;
        this.toastr.error("Please enter the sponserdue settled amount");
      } else {
        if (this.inTDS) { 
          this.tds_amount =  Math.round(parseFloat(this.spons_set_amount) * (parseFloat(this.spons_tds_percen) / 100));
          this.Calculate_gst('0', '0');
          this.calculateHospitalGst('0', '0');      
        }  
      }
    } else {
      this.inTDS = false;
      this.Calculate_gst('0', '0');
      this.calculateHospitalGst('0', '0');
    } 
  }

  checkSponsorSettled() {
    this.spons_set_amount = 0;
  } 

  sponsorSettledValue() {
    if (this.spons_set_amount != 0) {
      this.Calculate_gst('0', '0');
      this.calculateHospitalGst('0', '0');
    }
  }

  calculateHospitalGst(gst_click, concession) {
    this.billAddList = [];
    this.hospital_finalAmount = 0;
    this.hospital_totalBillAmount = 0;
    this.hospital_discount_amount = 0;
    this.hospital_gstAmount = 0;
    this.hospital_totalCollected = 0;
    this.hospital_gstDataList = [];

    if (this.billaddlist.length != 0) {
      for (var i = 0; i < this.billaddlist.length; i++) {
        if (this.billaddlist[i].bill_type == 'hospital') {
          this.billAddList.push({
            sno: i + 1,
            bill_type: this.billaddlist[i].bill_type,
            biill_type: this.billaddlist[i].biill_type,
            created_date: this.billaddlist[i].created_date,
            created_time: this.billaddlist[i].created_time,
            amount_txt: parseFloat(this.billaddlist[i].amount_txt),
            concession: this.billaddlist[i].concession,
            fee: parseFloat(this.billaddlist[i].fee),
            charge_type: this.billaddlist[i].charge_type,
            paytype: this.billaddlist[i].paytype,
            pay_type: this.billaddlist[i].pay_type,
            insurer: this.billaddlist[i].insurer,
            quantity: this.billaddlist[i].quantity,
            total: this.billaddlist[i].total,
            edit_flag: this.billaddlist[i].edit_flag,
            category: this.billaddlist[i].category,
            charge_cat_id: this.billaddlist[i].charge_cat_id,
            order_no: this.billaddlist[i].order_no
          })
        }
      }
    }
    console.log("billAddList",this.billAddList);
    if (this.billAddList.length != 0) {
      for (var i = 0; i < this.billAddList.length; i++) {
        if (this.billAddList[i].fee == '') {
          this.billAddList[i].fee = 0;
        }
        if (this.billAddList[i].amount_txt == '') {
          this.billAddList[i].amount_txt = 0;
        }
        if (this.billAddList[i].quantity == '') {
          this.billAddList[i].quantity = 0;
        }
        if (this.billAddList[i].concession == '') {
          this.billAddList[i].concession = 0;
        }

        this.hospital_discount_amount = (
          parseFloat(this.hospital_discount_amount) +
          parseFloat(this.billAddList[i].amount_txt) *
          parseFloat(this.billAddList[i].quantity) *
          (parseFloat(this.billAddList[i].concession) / 100)
        );
        this.hospital_finalAmount = (
          parseFloat(this.hospital_finalAmount) + parseFloat(this.billAddList[i].fee)
        );

        this.hospital_totalBillAmount = (
          parseFloat(this.hospital_totalBillAmount) +
          parseFloat(this.billAddList[i].amount_txt) *
          parseFloat(this.billAddList[i].quantity)
        );
        this.hospital_gstAmount = (
          parseFloat(this.hospital_gstAmount) + parseFloat(this.billAddList[i].fee)
        );

        if (parseFloat(this.Advance_bill) < parseFloat(this.hospital_finalAmount)) {
          this.hospital_remainingBalance = (
            parseFloat(this.hospital_finalAmount) - parseFloat(this.Advance_bill)
          );
        }

        if (parseFloat(this.Advance_bill) > parseFloat(this.hospital_finalAmount)) {
          this.hospital_remainingBalance = (
            parseFloat(this.Advance_bill) - parseFloat(this.hospital_finalAmount)
          );
          this.hospital_totalCollected = 0;
        }
      }
      this.hospital_discount_amount = parseInt(this.hospital_discount_amount);
      if (this.inGST == false) {
        this.hospital_finalAmount = Math.round(parseFloat(this.hospital_finalAmount));
      }

      this.hospital_finalAmount = parseFloat(this.hospital_finalAmount);
    }

    for (var i = 0; i < this.billAddList.length; i++) {
      if (
        this.billAddList[i].fee == 0 &&
        this.billAddList[i].biill_type == ''
      ) {
        this.billAddList[i].fee = '';
      }
      if (this.billAddList[i].amount_txt == 0 && this.billAddList[i].biill_type == '') {
        this.billAddList[i].amount_txt = '';
      }
      if (this.billAddList[i].quantity == 0 && (this.billAddList[i].biill_type == '' || this.billAddList[i].biill_type != '')) {
        this.billAddList[i].quantity = '';
      }
      if (this.billAddList[i].total == 0 && this.billAddList[i].biill_type == '') {
        this.billAddList[i].total = '';
      }
      if (this.billAddList[i].biill_type == '') {
        this.billAddList[i].concession = '';
      }
    }

    if (this.inGST == true) {
      for (var i = 0; i < this.gst_retrive_list.length; i++) {
        this.hospital_gstDataList.push({
          Gst_Descrip:
            this.gst_retrive_list[i].tax_desc + ' ' + '(' + parseInt(this.gst_retrive_list[i].tax) + ' %' + ')',
          Gst_amount: Math.round(
            parseFloat(this.hospital_gstAmount) *
            (parseFloat(this.gst_retrive_list[i].tax) / 100)
          ),
        });

        this.hospital_finalAmount = (parseFloat(this.hospital_finalAmount) +
          Math.round(parseFloat(this.hospital_gstAmount) *
            (parseFloat(this.gst_retrive_list[i].tax) / 100)));

        this.hospital_totalBillAmount =
          parseFloat(this.hospital_totalBillAmount) +
          Math.round(parseFloat(this.hospital_gstAmount) *
            (parseFloat(this.gst_retrive_list[i].tax) / 100));
      }
      this.hospital_finalAmount = parseFloat(this.hospital_finalAmount);
      this.hospital_totalBillAmount = parseFloat(this.hospital_totalBillAmount);
    }

    if (this.balance_data != undefined) {
      this.hospital_finalAmount = (
        parseFloat(this.hospital_finalAmount) + parseFloat(this.balance_data)
      );
      this.hospital_totalBillAmount = (
        parseFloat(this.hospital_totalBillAmount) + parseFloat(this.balance_data)
      );
    }

    //amount to be collected
    // this.hospital_remainingBalance = (
    //   parseFloat(this.hospital_finalAmount) - parseFloat(this.Advance_bill)
    // );
    this.hospital_remainingBalance = parseFloat(this.hospital_finalAmount);
    if (this.estimate_id.length == 0 && this.billAddList.length == 0) {
      this.inGST = false;
      this.hospital_gstDataList = [];
      this.Advance_bill = 0;
      this.hospital_finalAmount = 0;
      this.hospital_totalBillAmount = 0;
      this.hospital_gstAmount = 0;
      this.hospital_totalCollected = 0;
      this.hospital_remainingBalance = 0;
    }

    // if (parseFloat(this.Advance_bill) > parseFloat(this.hospital_finalAmount)) {
    //   this.hospital_remainingBalance = 0;
    // }

    console.log("HospitalTotalAmount", this.hospital_totalBillAmount);
    console.log("HospitalDiscountAmount", this.hospital_discount_amount);
    console.log("HospitalFinalAmount", this.hospital_finalAmount);
    console.log("HospitalRemainingBalance", this.hospital_remainingBalance);
    console.log("HospitalGstDataList", this.hospital_gstDataList);
    this.getAllBills("0");
  }

  paidCalculation() {
    var temp_paidBill: any;
    temp_paidBill = parseFloat(this.paid_bill) + parseFloat(this.Advance_bill); //parseFloat(this.Advance_bill)
    console.log("Temp_PaidBill", temp_paidBill);
    if (this.billAddList != undefined && this.billAddList.length != 0 && this.hospital_finalAmount != 0) {
      if (temp_paidBill != '') {
        if (temp_paidBill >= this.hospital_finalAmount) {
          this.hospital_paidBill = this.hospital_finalAmount;
          this.hospital_remainingBalance = 0;
          temp_paidBill = (parseFloat(temp_paidBill) - parseFloat(this.hospital_finalAmount));
        } else {
          this.hospital_paidBill = parseFloat(temp_paidBill);
          this.hospital_remainingBalance = (parseFloat(this.hospital_finalAmount) - parseFloat(temp_paidBill));
          temp_paidBill = 0;
        }
      }
      console.log('temp_paidBill', temp_paidBill);
      console.log("HospitalPaidBill", this.hospital_paidBill);
      console.log("HospitalRemainingBalance", this.hospital_remainingBalance);
    }
  }

  paymentArrayCalculation(paid_amount) {
    this.return_paymentsarray = [];
    this.return_pay_arrry_tot_amt = 0;
    this.returnEqualSplitValue = 0;
    if (this.paid_bill != '' && this.paid_bill != 0 ) {
      if (paid_amount != '' && paid_amount != 0) {
        for (var i = 0; i < this.local_payment_array.length; i++) {
          if (this.local_payment_array[i].amount != 0) {
            if (parseInt(paid_amount) != parseInt(this.paid_bill)) {
              this.return_paymentsarray.push({
                pay_id: this.local_payment_array[i].pay_id,
                pay_desc: this.local_payment_array[i].pay_desc,               
                amount: Math.round((paid_amount / this.paid_bill) * parseFloat(this.local_payment_array[i].amount))
              })
            } else {
              this.return_paymentsarray.push({
                pay_id: this.local_payment_array[i].pay_id,
                pay_desc: this.local_payment_array[i].pay_desc,               
                amount: Math.round(parseFloat(this.local_payment_array[i].amount))
              })
            }  
          }
        }  
        
        if (this.return_paymentsarray.length != 0) {
          for (var i = 0; i < this.return_paymentsarray.length; i++) {
            this.return_pay_arrry_tot_amt = parseInt(this.return_pay_arrry_tot_amt) + parseInt(this.return_paymentsarray[i].amount);
          }                   
          if (parseInt(this.return_pay_arrry_tot_amt) < parseInt(paid_amount)) {
            this.returnEqualSplitValue = ((parseFloat(paid_amount) - parseFloat(this.return_pay_arrry_tot_amt)) / this.return_paymentsarray.length);              
            for (var i = 0; i < this.return_paymentsarray.length; i++) {
              this.return_paymentsarray[i].amount = (parseFloat(this.return_paymentsarray[i].amount) + parseFloat(this.returnEqualSplitValue)).toFixed(2);                
            }
          } else if (parseInt(this.return_pay_arrry_tot_amt) > parseInt(paid_amount)) {
            this.hospitalEqualSplitValue = ((parseFloat(this.return_pay_arrry_tot_amt) - parseFloat(paid_amount)) / this.return_paymentsarray.length);               
            for (var i = 0; i < this.return_paymentsarray.length; i++) {
              this.return_paymentsarray[i].amount = (parseFloat(this.return_paymentsarray[i].amount) - parseFloat(this.returnEqualSplitValue)).toFixed(2);
            }
          }
        }
        console.log('return_paymentsarray',this.return_paymentsarray);
        return this.return_paymentsarray;
      }
    } 
    console.log(console.log('return_paymentsarray',this.return_paymentsarray));
    console.log("local_payment_array---",this.local_payment_array);
    if (this.return_paymentsarray.length != 0 && this.local_payment_array.length != 0) {
      for (var r = 0; r < this.return_paymentsarray.length; r++) {
        for (var p = 0; p < this.local_payment_array.length; p++) {
          if (this.local_payment_array[p].pay_id === this.return_paymentsarray[r].pay_id && parseInt(this.local_payment_array[p].amount) != 0 && parseInt(this.return_paymentsarray[r].amount) != 0) { 
            this.local_payment_array[p].amount = parseInt(this.local_payment_array[p].amount) - parseInt(this.return_paymentsarray[r].amount);
          }
        }
      }
    }
    console.log("local_payment_array---",this.local_payment_array);
  }

  Calculate_gst(gst_click, num) {
    console.log(this.billaddlist);
    this.final_amount = 0;
    this.totalBillAmount = 0;
    if (num != 1) {
      // this.discount_amount = 0;
    }
    this.gst_amount_txt = 0;
    this.total_collected = 0;
    this.current_amount = 0;
    this.gst_data_list = [];

    if (this.billden_plan.length != 0) {
      for (var i = 0; i < this.billden_plan.length; i++) {
        for (var j = 0; j < this.estimate_id.length; j++) {
          if (
            this.estimate_id[j] == this.billden_plan[i].den_treatment_plan_id
          ) {
            this.current_amount = (
              parseFloat(this.current_amount) +
              parseFloat(this.billden_plan[i].estimate)
            );
            this.final_amount = (
              parseFloat(this.final_amount) +
              parseFloat(this.billden_plan[i].estimate)
            );
            this.totalBillAmount = (
              parseFloat(this.final_amount) +
              parseFloat(this.billden_plan[i].estimate)
            );
            this.gst_amount_txt = (
              parseFloat(this.gst_amount_txt) +
              parseFloat(this.billden_plan[i].estimate)
            );

            if (parseFloat(this.Advance_bill) < parseFloat(this.final_amount)) {
              this.balance_show = false;
              this.total_collected = (
                parseFloat(this.final_amount) - parseFloat(this.Advance_bill)
              );
              this.remaing_billbal = (
                parseInt(this.final_amount) - parseInt(this.Advance_bill)
              );
            }
            if (parseFloat(this.Advance_bill) > parseFloat(this.final_amount)) {
              this.balance_show = false;
              this.remaing_billbal = (
                parseFloat(this.Advance_bill) - parseFloat(this.final_amount)
              );
            }

            this.amount_collected = true;
            if (this.total_collected == 0) {
              this.amount_collected = false;
            }
            if (this.remaing_billbal == 0) {
              this.balance_show = true;
            }
            break;
          }
        }
      }
    }

    if (this.pre_bill_amount != undefined && this.inpatientEdit == true) {

      this.pre_bill_amount = (
        parseInt(this.pre_bill_amount) +
        parseInt(this.pharmaPaid) +
        parseInt(this.diagPaid)
      );
      this.current_amount = (
        parseFloat(this.current_amount) + parseFloat(this.pre_bill_amount)
      );
      this.final_amount = (
        parseFloat(this.final_amount) + parseFloat(this.pre_bill_amount)
      );
      this.totalBillAmount = (
        parseFloat(this.totalBillAmount) + parseFloat(this.pre_bill_amount)
      );
      this.gst_amount_txt = (
        parseFloat(this.gst_amount_txt) + parseFloat(this.pre_bill_amount)
      );
      this.remaing_billbal = 0;
      this.paid_bill =
        this.Advance_bill != undefined
          ? (
            parseFloat(this.pre_bill_amount) - parseFloat(this.Advance_bill)
          )
          : parseFloat(this.pre_bill_amount);

      if (this.total_collected == 0) {
        this.amount_collected = false;
      }

      if (this.remaing_billbal == 0) {
        this.balance_show = true;
      }
    } else {
      var diag_bill_amount: any = 0,pharma_bill_amount: any = 0;
      if (this.diagnosis_bills.length != 0) {
        for (var d = 0; d < this.diagnosis_bills.length; d++) {
          if (this.diagnosis_bills[d].bill_amount != undefined && this.diagnosis_bills[d].bill_amount != "") {
            diag_bill_amount = parseFloat(diag_bill_amount) + parseFloat(this.diagnosis_bills[d].bill_amount);
          }
        }
      }
      if (this.pharma_bills.length != 0) {
        for (var p = 0; p < this.pharma_bills.length; p++) {
          if (this.pharma_bills[p].bill_amount != undefined && this.pharma_bills[p].bill_amount != "") {
            pharma_bill_amount = parseFloat(pharma_bill_amount) + parseFloat(this.pharma_bills[p].bill_amount);
          }
        }
      }
      console.log("diag_bill_amount---",diag_bill_amount ,"pharma_bill_amount--- ",pharma_bill_amount);
      console.log("Else Called");
      console.log(this.billaddlist);
      if (this.billaddlist.length != 0) {
        for (var i = 0; i < this.billaddlist.length; i++) {
          if (this.billaddlist[i].amount_txt == '') {
            this.billaddlist[i].amount_txt = 0;
          }
          if (this.billaddlist[i].quantity == '') {
            this.billaddlist[i].quantity = 0;
          }
          if (this.billaddlist[i].concession == '') {
            this.billaddlist[i].concession = 0;
          }


          // if (num != 1) {
          //   this.discount_amount = (
          //     parseFloat(this.discount_amount) +
          //     parseFloat(this.billaddlist[i].amount_txt) *
          //     parseFloat(this.billaddlist[i].quantity) *
          //     (parseInt(this.billaddlist[i].concession) / 100)
          //   );

          // }
          if (this.billaddlist[i].bill_type == 'hospital') {
            this.totalBillAmount = (parseFloat(this.totalBillAmount) + parseFloat(this.billaddlist[i].fee));
          }
        }
        this.totalBillAmount = Math.round(parseFloat(this.totalBillAmount) + parseFloat(diag_bill_amount) + parseFloat(pharma_bill_amount));
        console.log("totalBillAmount",this.totalBillAmount);
        this.current_amount = Math.round(parseFloat(this.current_amount) + parseFloat(this.hospitalBills));
        this.current_amount = parseFloat(this.current_amount);
        // if (this.discount_amount != '' && this.discount_amount != 0) {
        //   var floatValue = this.discount_amount;
        //   var floatString = floatValue.toString();
        //   var parts = floatString.split('.');
        //   var digitsAfterDecimal = parts[1] ? parts[1] : 0;
        //   if (digitsAfterDecimal[0] > 5) {
        //     this.discount_amount = Math.round(this.discount_amount);
        //     this.discount_amount = parseInt(this.discount_amount);
        //   } else {
        //     this.discount_amount = parseInt(this.discount_amount);
        //   }
        // }

        if (this.inGST == false) {
          this.final_amount = Math.round(parseFloat(this.final_amount) + parseFloat(this.hospitalBills));
        } else {
          this.final_amount = (
            parseFloat(this.final_amount) + parseFloat(this.hospitalBills)
          );
        }
        this.final_amount = parseFloat(this.final_amount);
        this.gst_amount_txt = (
          parseFloat(this.gst_amount_txt) + parseFloat(this.hospitalBills)
        );

        if (parseFloat(this.Advance_bill) < parseFloat(this.final_amount)) {
          this.balance_show = false;
          this.remaing_billbal = (
            parseFloat(this.final_amount) - parseFloat(this.Advance_bill)
          );
        }

        if (parseFloat(this.Advance_bill) > parseFloat(this.final_amount)) {
          this.balance_show = false;
          this.remaing_billbal = (
            parseFloat(this.Advance_bill) - parseFloat(this.final_amount)
          );
          this.total_collected = 0;
        }
        if (this.sponsorDue != undefined) {
          this.remaing_billbal = parseFloat(this.remaing_billbal) - parseFloat(this.sponsorDue);
        }

        if (this.total_collected == 0) {
          this.amount_collected = false;
        }

        if (this.remaing_billbal == 0) {
          this.balance_show = true;
        }
      }
      if (this.medcalDetailsArray.length != 0) {
        this.current_amount = (
          parseFloat(this.current_amount) + parseFloat(this.medicinBal)
        );
        this.final_amount = (
          parseFloat(this.final_amount) + parseFloat(this.medicinBal)
        );
        this.totalBillAmount = (
          parseFloat(this.totalBillAmount) + parseFloat(this.medicinBal)
        );
        this.gst_amount_txt = (
          parseFloat(this.gst_amount_txt) + parseFloat(this.medicinBal)
        );
        if (parseFloat(this.Advance_bill) < parseFloat(this.final_amount)) {
          this.balance_show = false;
          this.remaing_billbal = (
            parseFloat(this.final_amount) - parseFloat(this.Advance_bill)
          );
        }

        if (parseFloat(this.Advance_bill) > parseFloat(this.final_amount)) {
          this.balance_show = false;
          this.remaing_billbal = (
            parseFloat(this.Advance_bill) - parseFloat(this.final_amount)
          );
          this.total_collected = 0;
        }
        if (this.sponsorDue != undefined) {
          this.remaing_billbal = parseFloat(this.remaing_billbal) - parseFloat(this.sponsorDue);
        }
        if (this.total_collected == 0) {
          this.amount_collected = false;
        }

        if (this.remaing_billbal == 0) {
          this.balance_show = true;
        }
      }
      if (this.digDetailsArray.length != 0) {
        this.current_amount = (
          parseFloat(this.current_amount) + parseFloat(this.diagnosisAmount)
        );
        this.final_amount = (
          parseFloat(this.final_amount) + parseFloat(this.diagnosisAmount)
        );
        this.totalBillAmount = (
          parseFloat(this.totalBillAmount) + parseFloat(this.diagnosisAmount)
        );
        this.gst_amount_txt = (
          parseFloat(this.gst_amount_txt) + parseFloat(this.diagnosisAmount)
        );
        if (parseFloat(this.Advance_bill) < parseFloat(this.final_amount)) {
          this.balance_show = false;
          this.remaing_billbal = (
            parseFloat(this.final_amount) - parseFloat(this.Advance_bill)
          );
        }

        if (parseFloat(this.Advance_bill) > parseFloat(this.final_amount)) {
          this.balance_show = false;
          this.remaing_billbal = (
            parseFloat(this.Advance_bill) - parseFloat(this.final_amount)
          );
          this.total_collected = 0;
        }

        if (this.total_collected == 0) {
          this.amount_collected = false;
        }

        if (this.remaing_billbal == 0) {
          this.balance_show = true;
        }
      }
      for (var i = 0; i < this.billaddlist.length; i++) {
        if (this.billaddlist[i].fee == 0 && this.billaddlist[i].biill_type == '') {
          this.billaddlist[i].fee = '';
        }
        if (this.billaddlist[i].amount_txt == 0 && this.billaddlist[i].biill_type == '') {
          this.billaddlist[i].amount_txt = '';
        }
        if (this.billaddlist[i].quantity == 0 && (this.billaddlist[i].biill_type == '' || this.billaddlist[i].biill_type != '')) {
          this.billaddlist[i].quantity = '';
        }
        if (this.billaddlist[i].total == 0 && this.billaddlist[i].biill_type == '') {
          this.billaddlist[i].total = '';
        }
        if (this.billaddlist[i].biill_type == '') {
          this.billaddlist[i].concession = '';
        }

        if (this.billaddlist[i].concession != '') {
          var floatValue = this.billaddlist[i].concession;
          var floatString = floatValue.toString();
          var parts = floatString.split('.');
          var digitsAfterDecimal = parts[1] ? parts[1].length : 0;

          if (digitsAfterDecimal === 2) {
          } else if (digitsAfterDecimal === 3) {
            this.billaddlist[i].concession = parseFloat(this.billaddlist[i].concession);
          } else {
          }
        }

      }
    }

    if (this.inGST == true) {
      for (var i = 0; i < this.gst_retrive_list.length; i++) {
        this.gst_data_list.push({
          Gst_Descrip:
            this.gst_retrive_list[i].tax_desc +
            ' ' + '(' +
            parseInt(this.gst_retrive_list[i].tax) + ' %' + ')',
          Gst_amount: Math.round(
            parseFloat(this.gst_amount_txt) *
            (parseFloat(this.gst_retrive_list[i].tax) / 100)
          ),
        });
        this.final_amount = (parseFloat(this.final_amount) +
          Math.round(parseFloat(this.gst_amount_txt) *
            (parseFloat(this.gst_retrive_list[i].tax) / 100)));


        this.totalBillAmount =
          parseFloat(this.totalBillAmount) +
          Math.round(parseFloat(this.gst_amount_txt) *
            (parseFloat(this.gst_retrive_list[i].tax) / 100));
      }
      this.final_amount = Math.round(parseFloat(this.final_amount));
      this.totalBillAmount = parseFloat(this.totalBillAmount);
    }
    if (this.wardName == 'Dialysis' && this.insurance_flag) {
      this.sponsorDue = parseInt(this.totalBillAmount);
      this.sponsorDueflag = true;
    }
    console.log("sponsorDue",this.sponsorDue);
    if (
      this.estimate_id.length == 0 &&
      this.billaddlist.length == 0 &&
      this.medcalDetailsArray.length == 0 &&
      this.digDetailsArray.length == 0
    ) {
      this.inGST = false;
      this.gst_data_list = [];
      this.final_amount = 0;
      this.discount_amount = 0;
      this.totalBillAmount = 0;
      this.gst_amount_txt = 0;
      this.total_collected = 0;
      this.remaing_billbal = 0;
    }

    // if (parseFloat(this.Advance_bill) > parseFloat(this.final_amount)) {
    //   this.remaing_billbal = 0;
    // }
    console.log(this.final_amount);
    console.log(this.remaing_billbal);
  }

  update_bill() {
    this.Get_current_date();
    if (!this.adv_refflag) {
      this.allBillPaymentsArray();
    } 
    console.log(this.paymentsarray);
    var flag = true;
    if (this.adv_refflag == false) { //this.settled == true && 
      if (parseInt(this.remaing_billbal) == 0 || parseFloat(this.remaing_billbal) == 0 || this.remaing_billbal == "0") {
        flag = true;
      } else if (parseFloat(this.remaing_billbal) != 0 || this.remaing_billbal != 0) {
        flag = false;
        this.toastr.error("Please settle the bill");
      } 
    } else if (this.adv_refflag == true) { //this.settled == true && 
      var total_refund_amount: any = 0;
      if (parseInt(this.remaing_billbal) != 0 && this.remaing_billbal != "") {
        if (this.paymentsarray.length == 0) {
          this.toastr.error("Please Check the payment details");
          flag = false;
        } else if (this.paymentsarray.length != 0) {
          for (var p = 0; p < this.paymentsarray.length; p++) {
            total_refund_amount = parseFloat(total_refund_amount) + parseFloat(this.paymentsarray[p].amount);
          }
          console.log("this.paidBill  ",parseFloat(this.remaing_billbal)+ "   " + "total_refund_amount  ",parseFloat(total_refund_amount));
          total_refund_amount = Math.round(total_refund_amount);
          console.log("total_refund_amount",total_refund_amount);
          if (parseFloat(this.remaing_billbal) != parseFloat(total_refund_amount)) {
            this.toastr.error("Please Check the payment details");
            flag = false;
          }
        } else {
          console.log("this.total_refund_amount  ",parseFloat(this.remaing_billbal)+ "   " + "total_refund_amount  ",parseFloat(total_refund_amount));
          flag = true;
        }
      }
    }
    var total_paid_amount: any = 0;
    if (parseInt(this.paid_bill) != 0 && this.paid_bill != "" && !this.adv_refflag) {
      if (this.paymentsarray.length == 0) {
        this.toastr.error("Please Check the payment details");
        flag = false;
      } else if (this.paymentsarray.length != 0) {
        for (var p = 0; p < this.paymentsarray.length; p++) {
          total_paid_amount = parseFloat(total_paid_amount) + parseFloat(this.paymentsarray[p].amount);
        }
        console.log("this.paidBill  ",parseFloat(this.paid_bill)+ "   " + "total_paid_amount  ",parseFloat(total_paid_amount));
        total_paid_amount = Math.round(total_paid_amount);
        console.log("total_paid_amount",total_paid_amount);
        if (parseFloat(this.paid_bill) != parseFloat(total_paid_amount)) {
          this.toastr.error("Please Check the payment details");
          flag = false;
        }
      } else {
        console.log("this.paidBill  ",parseFloat(this.paid_bill)+ "   " + "total_paid_amount  ",parseFloat(total_paid_amount));
        flag = true;
      }
    }

    var paytype;
    var instype;
    if (this.billAddList != undefined && this.billAddList.length != 0) {
      for (var i = 0; i < this.billAddList.length; i++) {
        if (
          this.billAddList[i].biill_type == '' &&
          this.billAddList[i].amount_txt == ''
        ) {
          this.billAddList.splice(i, 1);
        }
      }

      this.rowid = 1;
      for (var i = 0; i < this.billAddList.length; i++) {
        if (this.billAddList[i].biill_type == '') {
          this.toastr.error("Remove the empty charge type rows");
          flag = false;
          break;
        }
        this.billAddList[i].sno = this.rowid;
        this.rowid += 1;
      }
    }

    if (this.billAddList.length == 0 && this.estimate_id.length == 0) {
      this.toastr.error(Message_data.getAddBill());
      flag = false;
    } else {
      for (var i = 0; i < this.billAddList.length; i++) {
        paytype = this.billAddList[i].paytype;
        instype = this.billAddList[i].insurer;
        if (this.billAddList[i].quantity == '') {
          this.billAddList[i].quantity = 0;
        }
        if (this.billAddList[i].biill_type != '' && (this.billAddList[i].amount_txt == 0 || this.billAddList[i].amount_txt == 0 || this.billAddList[i].amount_txt == '0')) {
          this.toastr.error('Service charge cannot be zero');
          flag = false;
          break;
        }
      }
    }
    paytype = this.pay_type;
    if (flag == true) {
      if (this.billing_print_array.length != 0) {
        for (var i = 0; i < this.billing_print_array.length; i++) {
          this.client_reg_id = this.billing_print_array[i].client_reg_id;
          this.relation_id = this.billing_print_array[i].relation_id;
          if (this.billing_print_array[i].sub_rel_id != undefined) {
            this.sub_rel_id = this.billing_print_array[i].sub_rel_id;
          }
        }
      }

      var bill_time;
      if (this.bill_time1 != undefined && this.bill_time2 != undefined) {
        bill_time = ConvertTimeformat(
          '',
          this.bill_time1 + ':' + this.bill_time2 + ' ' + this.bill_time3
        );
      }

      if (this.inGST == true && this.gst_retrive_list.length != 0) {
        this.cent_gst = this.gst_retrive_list[0].tax;
        this.sta_gst = this.gst_retrive_list[1].tax;
      }
      if (this.estimate_id.length != 0) {
        var estimateid = this.estimate_id;
      }
      var doctp_id = Helper_Class.getInfo().user_id;
      var cbill;
      var credit_person;
      if (this.credit_bill == true) {
        cbill = this.credit_type;
        credit_person = this.credit_person;
      } else {
        this.credit_type = '';
        this.credit_person = '';
      }

      var finalAmount,advance,sponsor_due,balance,paid_flag,paid_amount,paymentsarray,status,send_status,discount; 
      if (this.sendDoctor.length != 0) {
        finalAmount = this.sendDoctor[0].bill_amount;
        advance = "0.00";
        sponsor_due = "0.00";
        discount = "0.00";
        balance = this.sendDoctor[0].bill_amount;
        paid_flag = "Un paid";
        paid_amount = "0.00";
        // paymentsarray = this.sendDoctor[0].paytypes
      }

      if (this.userinfo.user_type == 'Admin') { //&& this.settled
        if (parseFloat(finalAmount) == parseFloat(this.remaing_billbal)) {
          paid_flag = "Un paid";
        } else if (parseFloat(this.remaing_billbal) == 0 || parseFloat(this.remaing_billbal) == 0) {
          paid_flag = "Fully paid";
        } else {
          paid_flag = "Partially paid";
        }
      }

      finalAmount = encrypt_decript.Encript(finalAmount).toString();
      
      // if (this.settled) {
      //   send_status = this.status;
      // } else if (this.finalized) {
      //   send_status = this.status;
      // } else if (this.pending) {
      //   send_status = this.status;
      // } else {
      //   status = status = parseInt(balance) <= 0 ? '0' : '1';
      //   send_status = status;
      // }

      if(true) {
        status = status = parseInt(balance) <= 0 ? '0' : '1';
        send_status = status;
      }

      this.payments_array = [];
      this.payments_array = this.paymentsarray;
      if (this.userinfo.user_type == 'Admin') { //&& this.settled
        if (this.hosp_pay_array != undefined && this.hosp_pay_array.length != 0) {
          if (this.payments_array != undefined && this.payments_array.length != 0) {
            console.log(this.hosp_pay_array);
            for (var h = 0; h < this.hosp_pay_array.length; h++) {
              for (var d = 0; d < this.payments_array.length; d++) {
                if (this.hosp_pay_array[h].pay_id == this.payments_array[d].pay_id) {
                  if (this.payments_array[d].amount > this.hosp_pay_array[h].amount) {
                    this.payments_array[d].amount = parseFloat(this.payments_array[d].amount) - parseFloat(this.hosp_pay_array[h].amount)
                  } else if ((this.payments_array[d].amount == this.hosp_pay_array[h].amount)) {
                    this.payments_array.splice(i, 1);
                  }
                }
              }
            }                 
          }
        }
      }

      if (
        this.sub_rel_id != null &&
        this.sub_rel_id != undefined &&
        this.sub_rel_id != '' &&
        this.sub_rel_id.length != 0
      ) {
        if (send_status == '1' || (this.userinfo.user_type == 'Admin')) { //&& this.settled this.pending || this.finalized
          this.update_bill_data = {
            bill_id: this.billId,
            appointment_id: parseFloat(this.appointment_id).toString(),
            app_type: 'doctor',
            hptl_clinic_id: this.hptl_clinic_id.toString(),
            doc_reg_id: this.dog_reg_id.toString(),
            bill_amount: finalAmount,
            created_by: this.user_id.toString(),
            bills: this.billAddList,
            country: ipaddress.country_code.toString(),
            fin_year: this.finyear,
            estimates: estimateid,
            client_reg_id: this.client_reg_id,
            relation_id: this.relation_id,
            sub_rel_id: this.sub_rel_id,
            advance: this.Advance_bill,
            balance: this.remaing_billbal, 
            cgst: this.cent_gst,
            sgst: this.sta_gst,  
            pay_type: paytype,
            insurer: instype,
            card_no: this.cardNumber,
            card_holder_name: this.cardHolderName,
            transaction_no: this.transactionId,
            bank_name: this.bankName,
            userid: doctp_id,
            paid_flag: paid_flag,
            paid_amount: this.paid_bill,
            bill_date: this.bill_date,
            bill_time: bill_time,
            credit_type: cbill,
            credit_person: credit_person,
            payments: this.payments_array,
            status: send_status,
            discount: this.discount_amount,
            sponsor_due: sponsor_due
          };
        } else {
          this.update_bill_data = {
            bill_id: this.billId,
            appointment_id: parseFloat(this.appointment_id).toString(),
            app_type: 'doctor',
            hptl_clinic_id: this.hptl_clinic_id.toString(),
            doc_reg_id: this.dog_reg_id.toString(),
            bill_amount: finalAmount,
            created_by: this.user_id.toString(),
            bills: this.billAddList,
            country: ipaddress.country_code.toString(),
            fin_year: this.finyear,
            estimates: estimateid,
            client_reg_id: this.client_reg_id,
            relation_id: this.relation_id,
            sub_rel_id: this.sub_rel_id,
            advance: advance,
            balance: balance, 
            cgst: this.cent_gst,
            sgst: this.sta_gst,  
            pay_type: paytype,
            insurer: instype,
            card_no: this.cardNumber,
            card_holder_name: this.cardHolderName,
            transaction_no: this.transactionId,
            bank_name: this.bankName,
            userid: doctp_id,
            paid_flag: paid_flag,
            paid_amount: paid_amount,
            bill_date: this.bill_date,
            bill_time: bill_time,
            credit_type: cbill,
            credit_person: credit_person,
            status: send_status,
            discount: discount,
            sponsor_due: sponsor_due
          };
        }
      } else {
        if(send_status == '1' || (this.userinfo.user_type == 'Admin')){ //&& this.settled this.pending || this.finalized
          this.update_bill_data = {
            bill_id: this.billId,
            appointment_id: parseFloat(this.appointment_id).toString(),
            app_type: 'doctor',
            hptl_clinic_id: this.hptl_clinic_id.toString(),
            doc_reg_id: this.dog_reg_id.toString(),
            bill_amount: finalAmount,
            created_by: this.user_id.toString(),
            bills: this.billAddList,
            country: ipaddress.country_code.toString(),
            fin_year: this.finyear,
            estimates: estimateid,
            client_reg_id: this.client_reg_id,
            relation_id: this.relation_id,
            advance: this.Advance_bill,
            balance: this.remaing_billbal,
            cgst: this.cent_gst,
            sgst: this.sta_gst,
            pay_type: paytype,
            insurer: instype,
            card_no: this.cardNumber,
            card_holder_name: this.cardHolderName,
            transaction_no: this.transactionId,
            bank_name: this.bankName,
            userid: doctp_id,
            paid_flag: paid_flag,
            paid_amount:  this.paid_bill,
            bill_date: this.bill_date,
            bill_time: bill_time,
            credit_type: cbill,
            credit_person: credit_person,
            payments: this.payments_array,
            status: send_status,
            discount: this.discount_amount,
            sponsor_due: sponsor_due
          };
        } else {
          this.update_bill_data = {
            bill_id: this.billId,
            appointment_id: parseFloat(this.appointment_id).toString(),
            app_type: 'doctor',
            hptl_clinic_id: this.hptl_clinic_id.toString(),
            doc_reg_id: this.dog_reg_id.toString(),
            bill_amount: finalAmount,
            created_by: this.user_id.toString(),
            bills: this.billAddList,
            country: ipaddress.country_code.toString(),
            fin_year: this.finyear,
            estimates: estimateid,
            client_reg_id: this.client_reg_id,
            relation_id: this.relation_id,
            advance: advance,
            balance: balance,
            cgst: this.cent_gst,
            sgst: this.sta_gst,
            pay_type: paytype,
            insurer: instype,
            card_no: this.cardNumber,
            card_holder_name: this.cardHolderName,
            transaction_no: this.transactionId,
            bank_name: this.bankName,
            userid: doctp_id,
            paid_flag: paid_flag,
            paid_amount: paid_amount,
            bill_date: this.bill_date,
            bill_time: bill_time,
            credit_type: cbill,
            credit_person: credit_person,
            status: send_status,
            discount: discount,
            sponsor_due: sponsor_due
          };
        }
      }

      Doc_Helper.setbillid_create(this.billAddList);
      console.log(this.update_bill_data);
      // this.allBillSave();
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http
        .post(
          ipaddress.getIp.toString() + 'bill/ubill/',
          JSON.stringify(this.update_bill_data),
          { headers: headers }
        )
        .subscribe(
          (response) => {
            var obj = response.json();
            console.log(obj);
            if (obj.result != null) {
              if (obj.key == '1') {
                console.log("sendDoctor", this.sendDoctor);
                if (send_status == '0' && this.userinfo.user_type != 'Admin') {
                  this.allBillSave();
                } else if (send_status == '0' && this.userinfo.user_type == 'Admin') {
                  this.toastr.success("Bill successfully updated");
                  setTimeout(()=>{
                    this.print_area1();
                  },1000);
                  this.back();
                } else {
                  this.toastr.success("Bill successfully updated");
                  this.back();
                }
              } else {
                this.toastr.error(Message_data.getBillUngen());
              }
            }
          },
          (error) => {
            this.show_save_btn = true;
          }
        );
    } else {
      this.show_save_btn = true;
    }
  }

  //add the bill details
  advance_change() {
    this.Advance_bill = this.Advance_bill == '' ? 0 : this.Advance_bill;
    this.remaing_billbal = (
      parseFloat(this.final_amount) -
      parseFloat(this.round_off) -
      (parseFloat(this.paid_bill) + parseFloat(this.Advance_bill))
    );
  }

  addRound(data) {
    this.sign = data;
    this.round_off = this.round_off == '' ? 0 : this.round_off;
    if (data == 'puls') {
      this.remaing_billbal = (
        parseFloat(this.final_amount) +
        parseFloat(this.round_off) -
        (parseFloat(this.paid_bill) + parseFloat(this.Advance_bill))
      );
    } else {
      this.remaing_billbal = (
        parseFloat(this.final_amount) -
        parseFloat(this.round_off) -
        (parseFloat(this.paid_bill) + parseFloat(this.Advance_bill))
      );
    }
    this.tempRoundoff = this.round_off;
  }

  paid_change() {
    if (this.paymentsarray.length == 0) {
      this.payType1 = this.paytypelist[0].pay_id;
    } else {
      this.paymentsarray_total = 0;
      for (var i = 0; i < this.paymentsarray.length; i++) {
        this.paymentsarray_total = (parseFloat(this.paymentsarray_total) + parseFloat(this.paymentsarray[i].amount));
      }
      if (this.paymentsarray_total > this.final_amount) {
        this.paymentsarray = [];
        this.payType1 = this.paytypelist[0].pay_id;
      }
    }
    this.paid_bill = this.paid_bill == '' ? 0 : this.paid_bill;
    this.remaing_billbal = (
      parseFloat(this.final_amount) -
      parseFloat(this.round_off) -
      (parseFloat(this.paid_bill) + parseFloat(this.Advance_bill))
    );
    if (parseFloat(this.paid_bill) > this.final_amount) {
      this.amountval = parseFloat(this.final_amount);
      this.numToWords = convertNumberToWords(
        this.final_amount,
        ipaddress.country_code
      );
    } else {
      this.amountval = parseFloat(this.paid_bill);
      this.numToWords = convertNumberToWords(
        this.paid_bill,
        ipaddress.country_code
      );
    }

    if (this.paid_bill != undefined) {
      if (parseFloat(this.paid_bill) > parseFloat(this.final_amount)) {
        if (this.paymentsarray.length == 0) {
          this.amountval = parseFloat(this.final_amount);
        } else {
          var payArr_Amt = 0;
          for (var i = 0; i < this.paymentsarray.length; i++) {
            payArr_Amt += parseFloat(this.paymentsarray[i].amount);
          }

          if (payArr_Amt > parseFloat(this.final_amount)) {
            this.amountval = parseFloat(this.final_amount);
          } else {
            this.amountval = (parseFloat(this.final_amount) - payArr_Amt);
          }
        }
      } else {
        if (this.paymentsarray.length == 0) {
          this.amountval = parseFloat(this.paid_bill);
        } else {
          var payArr_Amt = 0;
          for (var i = 0; i < this.paymentsarray.length; i++) {
            payArr_Amt += parseFloat(this.paymentsarray[i].amount);
          }

          if (payArr_Amt > parseFloat(this.paid_bill)) {
            this.amountval = parseFloat(this.paid_bill);
          } else {
            this.amountval = (parseFloat(this.paid_bill) - payArr_Amt);
          }
        }
      }
    } else {
      this.paid_bill = 0;
      this.remaing_billbal = this.final_amount;
    }
  }

  //Bill click
  bill_click(planid, planmodal, rowid) {
    if (this.estimate_id.length != 0) {
      if (this.estimate_id.indexOf(planid) > -1) {
        for (var i = 0; i < this.estimate_id.length; i++) {
          if (this.estimate_id[i] == planid) {
            this.estimate_id.splice(i, 1);
            this.Calculate_gst('0', '0');
          }
        }
      } else {
        this.estimate_id.push(planid);
        this.Calculate_gst('0', '0');
      }
    } else {
      this.estimate_id.push(planid);
      this.Calculate_gst('0', '0');
    }

    for (var i = 0; i < this.billden_plan.length; i++) {
      for (var j = 0; j < this.estimate_id.length; j++) {
        if (this.estimate_id[j] == this.billden_plan[i].den_treatment_plan_id) {
          this.billden_plan[i].checked = true;
        }
      }
    }
  }

  flag_function() {
    this.inpatient_flag = true;
    if (this.bill_qty_flag == true && !this.discount_flag == false && this.inpatient_flag == true) {
      this.multiply_flag = false;
    } else if (this.bill_qty_flag == true && !this.discount_flag == true && this.inpatient_flag == true) {
      this.multiply_flag = true;
    } else if (this.bill_qty_flag == false && !this.discount_flag == false && this.inpatient_flag == true) {
      this.multiply_flag = false;
    } else if (this.bill_qty_flag == false && !this.discount_flag == true && this.inpatient_flag == true) {
      this.multiply_flag = true;
    }

    if (this.bill_qty_flag == true && this.discount_flag == true && !this.inpatient_flag) {
      this.amount_td_style = "text-align: right; border: 1px solid black;border-collapse: collapse;width: 300px";
    } else {
      this.amount_td_style = "text-align: right; border: 1px solid black;border-collapse: collapse;width: 150px;";
    }
  }

  allBillSave() {  
    var flag = true;
    if (this.sendDoctor.length == 0 && this.sendPharmacy.length == 0 && this.sendDiagnosis.length == 0) {
      this.toastr.error("Select atleast one bill type");
      flag = false;
    } 
    if (this.adv_refflag == true) {
      this.advanceRefundCalculation();
    }
    console.log("sendDiagnosis",this.sendDiagnosis);
    this.package = [];
    if (this.sendDiagnosis.length != 0) {
      for (var d = 0; d < this.sendDiagnosis.length; d++) {
        if (this.sendDiagnosis[d].sub_type != undefined && this.sendDiagnosis[d].sub_type == 'package') {
          this.package.push({
            client_reg_id: this.client_reg_id,
            hosp_id: this.hosp_id,
            billing_id: this.sendDiagnosis[d].billing_id,
            bill_amount: this.sendDiagnosis[d].bill_amount,
            paid_amount: this.sendDiagnosis[d].paid_amount,
            balance: this.sendDiagnosis[d].balance,
            paid_flag: this.sendDiagnosis[d].paid_flag,
            advance: this.sendDiagnosis[d].advance,
            sponsor_due: this.sendDiagnosis[d].sponsor_due,
            bill_date: this.sendDiagnosis[d].bill_date,
            fin_year: this.sendDiagnosis[d].fin_year,
            paytypes: this.sendDiagnosis[d].paytypes
          })
        }
      }

      for (var d = 0; d < this.sendDiagnosis.length; d++) {
        if (this.sendDiagnosis[d].sub_type != undefined && this.sendDiagnosis[d].sub_type == 'package') {
          this.sendDiagnosis.splice(d, 1);
          d--;
        }
      }
      console.log("package",this.package);
    }
    console.log("sendDiagnosis",this.sendDiagnosis);
    var advance;
    if (parseFloat(this.final_amount) == (parseFloat(this.paid_bill) + parseFloat(this.Advance_bill))) {
      advance = "0.00";
    } else if (parseFloat(this.final_amount) < parseFloat(this.Advance_bill)) {
      advance = ((parseFloat(this.Advance_bill) - parseFloat(this.final_amount))).toFixed(2);
    } else {
      advance = "0.00";
    }

    if (flag == true) {
      console.log("Doctor bills " + JSON.stringify(this.sendDoctor));
      console.log("PHARMA BILLS " + JSON.stringify(this.sendPharmacy));
      console.log("DIAGNOSIS BILLS " + JSON.stringify(this.sendDiagnosis));
      if (this.sendDoctor != undefined && this.sendDoctor.length != 0) {
        this.sendDoctor[0].total_advance = parseFloat(this.Advance_bill);
      }
      var send_status,status;
      // if (this.settled) {
      //   send_status = this.status;
      // } else if (this.finalized) {
      //   send_status = this.status;
      // } else if (this.pending) {
      //   send_status = this.status;
      // } else {
      //   status = parseInt(this.remaing_billbal) <= 0 ? '0' : '1';
      //   send_status = status;
      // }
      if (true) {
        status = parseInt(this.remaing_billbal) <= 0 ? '0' : '1';
        send_status = status;
      }
      var sendData: any = {
        client_reg_id: this.client_reg_id,
        advance_balance: advance,
        hptl_clinic_id: this.hptl_clinic_id,
        appointment_id: parseFloat(this.appointment_id).toString(),
        pharma: this.sendPharmacy,
        diagnosis: this.sendDiagnosis,
        doctor: this.sendDoctor,
        created_by: this.user_id.toString(),
        creator_name:this.userinfo.first_name+" "+this.userinfo.last_name,
        status: send_status
      }

      var neft_flag = false;
      if (this.paymentsarray.length != 0) {
        for (var i = 0; i < this.paymentsarray.length; i++) {
          if (this.paymentsarray[i].pay_desc == 'NEFT') {
            neft_flag = true;
            break;
          }
        }
      }

      if (this.adv_refflag == true) {
        sendData.ref_pay_id = this.paymentsarray[0].pay_id;
        if (neft_flag == true) {
          sendData.ref_account_no = this.acc_number_adv;
          sendData.ref_holder_name = this.holder_Name_adv;
          sendData.ref_bank_name = this.bank_Name_adv;
          sendData.ref_bank_ifc_code = this.bank_ifc_code_adv;
        } 
        sendData.refund_array = this.refund_array;
      }
      if (this.package.length != 0) {
        sendData.package = this.package;
      }
      if (this.package.length != 0) {
        sendData.package = this.package;
      }
      console.log("SENDDATA UCB" + JSON.stringify(sendData));
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'bill/ucb/', sendData,
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            if (obj["result"] != null) {
              if (obj["key"] == "1") {
                this.toastr.success("Bill successfully updated");
                if (send_status == '0') {
                  setTimeout(()=>{
                    this.print_area1();
                  },1000);
                } 
                this.show_save_btn = false;
                this.back();
              } else {
                this.toastr.error("Error in save bill");
              }
            }
          },
          error => {
            this.toastr.error(Message_data.getNetworkMessage());
          }
        )
    }
  }

  allBillSet(num) {
    if (num == '1') {
      if ((parseFloat(this.local_paid_bill) != 0 || parseInt(this.local_paid_bill) != 0) && (parseFloat(this.paid_bill) != 0 || parseInt(this.paid_bill))) {
        this.totalPaidAmountvalue = parseFloat(this.local_paid_bill) + parseFloat(this.paid_bill);
      } else if (parseFloat(this.local_paid_bill) != 0 || parseInt(this.local_paid_bill) != 0) {
        this.totalPaidAmountvalue = parseFloat(this.local_paid_bill);
      } else {
        this.totalPaidAmountvalue = parseFloat(this.paid_bill);
      }       
    } else {
      this.totalPaidAmountvalue = parseFloat(this.paid_bill);
    }
    
    console.log("totalPaidAmountvalue",this.totalPaidAmountvalue);
    this.paid_bill = 0;
    this.totalBalanceAmountvalue = parseFloat(this.remaing_billbal);
    this.remaing_billbal = 0;
    this.totalBillAmountvalue = parseFloat(this.totalBillAmount);
    this.totalDiscountvalue = parseFloat(this.discount_amount);
    console.log(this.totalDiscountvalue);
    this.discount_amount = 0;
    this.total_advancevalue = parseFloat(this.Advance_bill);
    this.selectedAllFlage = true;
    if (this.selectedAllFlage == true) {
      if (this.docBills.length != 0) {
        this.sendDoctor = [];
        for (var k = 0; k < this.docBills.length; k++) {
          var flag;
          if (parseFloat(this.docBills[k].balance) == 0.00) {
            flag = "Fully paid";
          } else if (parseFloat(this.docBills[k].balance) == parseFloat(this.docBills[k].bill_amount)) {
            flag = "un paid";
          } else {
            flag = "Partially paid";
          }
          console.log("doc bills " + JSON.stringify(this.docBills))
          console.log("doc sendDoctor " + JSON.stringify(this.sendDoctor))
          this.sendDoctor.push({
            billing_id: this.docBills[k].billing_id,
            hptl_clinic_id: this.hptl_clinic_id,
            paid_amount: this.docBills[k].paid_amount,
            balance: this.docBills[k].balance,
            paid_flag: flag,
            advance: this.docBills[k].advance,
            sponsor_due: this.docBills[k].sponsor_due,
            discount: this.docBills[k].discount,
            temp_balance: this.docBills[k].balance,
            fin_year: this.docBills[k].fin_year,
            paidamountprint: this.docBills[k].paid_amount,
            bill_amount: this.docBills[k].bill_amount,
            discountprint: this.docBills[k].discount != undefined ? this.docBills[k].discount : "0.00",
            bill_date: this.docBills[k].bill_date,
            paytypes: this.paymentArrayCalculation(this.docBills[k].paid_amount),
            tds_amount: this.docBills[k].tds_amount
          });
          this.final_amount = (parseFloat(this.final_amount) + parseFloat(this.docBills[k].balance)).toFixed(2);
          this.totalDiscount = (parseFloat(this.totalDiscount) + parseFloat(this.docBills[k].discount)).toFixed(2);
          this.totalBill = (parseFloat(this.totalBill) + parseFloat(this.docBills[k].bill_amount)).toFixed(2);
          this.totalPaid = (parseFloat(this.totalPaid) + parseFloat(this.docBills[k].paid_amount)).toFixed(2);
        }
      }
      console.log("sendDoctor",this.sendDoctor);
    } else {
      this.sendDoctor = [];
      for (var k = 0; k < this.docBills.length; k++) {
        this.docBills[k].checked = this.selectedAllFlage;
        this.final_amount = (parseFloat(this.final_amount) - parseFloat(this.docBills[k].balance)).toFixed(2);
        this.totalDiscount = (parseFloat(this.totalDiscount) - parseFloat(this.docBills[k].discount)).toFixed(2);
        this.totalBill = (parseFloat(this.totalBill) - parseFloat(this.docBills[k].bill_amount)).toFixed(2);
        this.totalPaid = (parseFloat(this.totalPaid) - parseFloat(this.docBills[k].paid_amount)).toFixed(2);
      }
    }
    
    if (this.selectedAllFlage == true) {
      if (this.medBills.length != 0) {
        this.sendPharmacy = [];
        for (var k = 0; k < this.medBills.length; k++) {
          var flag;
          if (parseFloat(this.medBills[k].balance) == 0.00) {
            flag = "Fully paid";
          } else if (parseFloat(this.medBills[k].balance) == parseFloat(this.medBills[k].bill_amount)) {
            flag = "un paid";
          } else {
            flag = "Partially paid";
          }
          this.sendPharmacy.push({
            order_id: this.medBills[k].billing_id,
            pharma_id: this.medBills[k].pharma_id,
            paid_amount: this.medBills[k].paid_amount,
            balance: this.medBills[k].balance,
            paidamountprint: this.medBills[k].paid_amount,
            bill_amount: this.medBills[k].bill_amount,
            discountprint: this.medBills[k].discount != undefined ? this.medBills[k].discount : "0.00",
            paid_flag: flag,
            temp_balance: this.medBills[k].balance,
            fin_year: this.medBills[k].fin_year,
            advance: this.medBills[k].advance != undefined ? this.medBills[k].advance : "0.00",
            sponsor_due: this.medBills[k].sponsor_due,
            bill_date: this.medBills[k].bill_date,
            paytypes: this.paymentArrayCalculation(this.medBills[k].paid_amount)
          });
          this.final_amount = (parseFloat(this.final_amount) + parseFloat(this.medBills[k].balance)).toFixed(2);
          this.mediBillAmount = (parseFloat(this.mediBillAmount) + parseFloat(this.medBills[k].bill_amount)).toFixed(2);
          this.totalDiscount = (parseFloat(this.totalDiscount) + parseFloat(this.medBills[k].discount)).toFixed(2);
          this.totalBill = (parseFloat(this.totalBill) + parseFloat(this.medBills[k].bill_amount)).toFixed(2);
          this.totalPaid = (parseFloat(this.totalPaid) + parseFloat(this.medBills[k].paid_amount)).toFixed(2);
        }
      }

    } else {
      this.sendPharmacy = [];
      for (var k = 0; k < this.medBills.length; k++) {
        this.medBills[k].checked = this.selectedAllFlage;
        this.final_amount = (parseFloat(this.final_amount) - parseFloat(this.medBills[k].balance)).toFixed(2);
        this.mediBillAmount = (parseFloat(this.mediBillAmount) - parseFloat(this.medBills[k].bill_amount)).toFixed(2);
        this.totalDiscount = (parseFloat(this.totalDiscount) - parseFloat(this.medBills[k].discount)).toFixed(2);
        this.totalBill = (parseFloat(this.totalBill) - parseFloat(this.medBills[k].bill_amount)).toFixed(2);
        this.totalPaid = (parseFloat(this.totalPaid) - parseFloat(this.medBills[k].paid_amount)).toFixed(2);
      }
    }
    console.log("diagBills", JSON.stringify(this.diagBills));
    if (this.selectedAllFlage == true) {
      if (this.diagBills.length != 0) {
        this.sendDiagnosis = [];
        var sno = 1;
        for (var k = 0; k < this.diagBills.length; k++) {
          var flag;
          if (parseFloat(this.diagBills[k].balance) == 0.00) {
            flag = "Fully paid";
          } else if (parseFloat(this.diagBills[k].balance) == parseFloat(this.diagBills[k].bill_amount)) {
            flag = "un paid";
          } else {
            flag = "Partially paid";
          }
          this.sendDiagnosis.push({
            billing_id: this.diagBills[k].billing_id,
            diag_centre_id: this.diag_centre_id,
            sub_type: this.diagBills[k].sub_type,
            paid_amount: this.diagBills[k].paid_amount,
            balance: this.diagBills[k].balance,
            paid_flag: flag,
            temp_balance: this.diagBills[k].balance,
            fin_year: this.diagBills[k].fin_year,
            advance: this.diagBills[k].advance != undefined ? this.diagBills[k].advance : "0.00",
            sponsor_due: this.diagBills[k].sponsor_due,
            paidamountprint: this.diagBills[k].paid_amount,
            bill_amount: this.diagBills[k].bill_amount,
            bill_date: this.diagBills[k].bill_date,
            discountprint: this.diagBills[k].discount != undefined ? this.diagBills[k].discount : "0.00",
            paytypes: this.paymentArrayCalculation(this.diagBills[k].paid_amount)
          });
          this.final_amount = (parseFloat(this.final_amount) + parseFloat(this.diagBills[k].balance)).toFixed(2);
          this.totalBill = (parseFloat(this.totalBill) + parseFloat(this.diagBills[k].bill_amount)).toFixed(2);
          this.totalPaid = (parseFloat(this.totalPaid) + parseFloat(this.diagBills[k].paid_amount)).toFixed(2);
        }
      }

    } else {
      this.sendDiagnosis = [];
      for (var k = 0; k < this.diagBills.length; k++) {
        this.diagBills[k].checked = this.selectedAllFlage;
        this.final_amount = (parseFloat(this.final_amount) - parseFloat(this.diagBills[k].balance)).toFixed(2);
        this.totalBill = (parseFloat(this.totalBill) - parseFloat(this.diagBills[k].bill_amount)).toFixed(2);
        this.totalPaid = (parseFloat(this.totalPaid) - parseFloat(this.diagBills[k].paid_amount)).toFixed(2);
      }
    }
    this.combinedArray = [...this.sendDoctor, ...this.sendDiagnosis, ...this.sendPharmacy];
    this.remaing_billbal = this.remaing_billbal;
    console.log("paid_bill",this.paid_bill);
    this.discountvalue("0");
    if (num != '1') {
      this.calculatePaidAmount(num);
    }  
  }  

  calculateCombinedValue() {
    this.refund_amount = 0;
    this.refund_reason = '';
    for (var item of this.combinedArray) {
      this.totalPaidAmountvalue = (parseFloat(this.totalPaidAmountvalue) + parseFloat(item.paid_amount)).toFixed(2);
      this.totalBalanceAmountvalue = (parseFloat(this.totalBalanceAmountvalue) + parseFloat(item.balance)).toFixed(2);
      this.totalBillAmountvalue = (parseFloat(this.totalBillAmountvalue) + parseFloat(item.bill_amount)).toFixed(2);
      this.totalDiscountvalue = (parseFloat(this.totalDiscountvalue) + parseFloat(item.discountprint)).toFixed(2);
      this.total_advancevalue = (parseFloat(this.total_advancevalue) + parseFloat(item.advance)).toFixed(2);
    }

    this.temppaid_bill = (parseFloat(this.totalPaidAmountvalue) - parseFloat(this.total_advancevalue)).toFixed(2);
    this.discount_amount = parseFloat(this.totalDiscountvalue);
    var totalAdvance = parseFloat(this.Advance_bill);
    var totalAdvanceBills = !isNaN(this.totalAdvance_bills) ? this.totalAdvance_bills : 0;
    this.remainingbalance_advance = (totalAdvance - totalAdvanceBills).toFixed(2);
    this.remainingbalance_sponsor_due = parseFloat(this.sponsorDue);
    console.log("this.remainingbalance_advance   **" + totalAdvance + "????" + totalAdvanceBills)
    console.log("this.remainingbalance_advance   " + this.remainingbalance_advance)

    if (this.sponsorDue !== undefined) {
      if ((parseInt(this.spons_set_amount) != 0 || parseFloat(this.spons_set_amount) && this.inTDS && parseInt(this.tds_amount) != 0)) {
        var deductval = parseFloat(this.totalPaidAmountvalue) + totalAdvance + parseFloat(this.spons_set_amount) + parseFloat(this.tds_amount) + this.discount_amount;
        this.remaing_billbal = (parseFloat(this.totalBillAmountvalue) - deductval).toFixed(2);
      } else if (parseInt(this.spons_set_amount) != 0 || parseFloat(this.spons_set_amount) != 0) {
        var deductval = parseFloat(this.totalPaidAmountvalue) + totalAdvance + parseFloat(this.spons_set_amount) + this.discount_amount;
        this.remaing_billbal = (parseFloat(this.totalBillAmountvalue) - deductval).toFixed(2);
      } else {
        var deductval = parseFloat(this.totalPaidAmountvalue) + totalAdvance + parseFloat(this.sponsorDue) + this.discount_amount;
        this.remaing_billbal = (parseFloat(this.totalBillAmountvalue) - deductval).toFixed(2);
      }
    } else {
      var deductval = parseFloat(this.totalPaidAmountvalue) + totalAdvance + this.discount_amount;
      this.remaing_billbal = (parseFloat(this.totalBillAmountvalue) - deductval).toFixed(2);
      console.log("calculateCombinedValue  --" + this.totalBillAmountvalue + " ...." + deductval)
      console.log("calculateCombinedValue  --" + this.remaing_billbal + " ...." + deductval)
    }

    if (this.totalBillAmountvalue.trim() === this.paid_bill.trim()) {
      this.paidflag = false;

    } else {
      this.paidflag = true;
    }
    if (this.totalDiscountvalue === "0" || this.totalDiscountvalue === "0.00" || this.totalDiscountvalue === "0.0") {
      this.discountflag = false;

    } else {
      this.discountflag = true;
    }
    if (this.remaing_billbal == 0) {
      this.balanceflag = false;

    } else {
      this.balanceflag = true;
    }
    if (this.Advance_bill === "0.00" || this.Advance_bill === "0") {
      this.advanceflag = false;

    } else {
      this.advanceflag = true;
    }

    if (parseInt(this.Advance_bill) > parseInt(this.totalBillAmountvalue) && parseInt(this.paid_bill) == 0) {
      console.log(" if this.total_advance ----" + this.Advance_bill + "????this.remaining_billbal???" + this.totalBillAmountvalue);
      this.adv_refflag = true;
      this.refund_amount = Math.abs(this.remaing_billbal);
      this.remaing_billbal = parseFloat(this.refund_amount).toFixed(2);
      this.refundcal();
    } else {
      this.adv_refflag = false;
      this.refund_amount = 0;
      this.bank_details_flag_adv = false;
      if (this.paymentsarray.length == 0) {
        this.payType1 = this.paytypelist[0].pay_id;
        this.amountval = parseFloat(this.remaing_billbal);
      }
    }
  }

  checkdiscount() {
    this.discount_amount = "0";
  }

  discountvalue(num) {
    console.log(this.discount_amount);
    this.discount_amount1 = Number(this.discount_amount) + Number(this.totalDiscountvalue); // Convert to number if needed
    this.discount_amount = this.discount_amount1;
    console.log(this.totalDiscountvalue);
    console.log(this.discount_amount);
    this.remaing_billbal = this.remaing_billbal - this.discount_amount;
    this.remaing_billbal = parseFloat(this.remaing_billbal);
    if (this.discount_amount != 0 || this.discount_amount != 0.00) {
      this.discountflag = true;
    }
    this.remaing_billbal = this.checkAndAssignValue(this.remaing_billbal);
    this.discount_amount = this.checkAndAssignValue(this.discount_amount);
    console.log("hospBillAmount", this.doctorBills);
    console.log("hospBillAmount", this.sendDoctor);
    this.sendDoctor[0].balance = this.doctorBills[0].balance;
    if (this.discount_amount != 0 || this.sendDoctor[0].balance != 0) {
      this.sendDoctor[0].discount = this.discount_amount;
      if (parseInt(this.spons_set_amount) != 0 && this.inTDS && parseInt(this.tds_amount) != 0) {
        this.sendDoctor[0].tds_amount = this.tds_amount;
        this.sendDoctor[0].balance = parseFloat(this.sendDoctor[0].bill_amount) - (parseFloat(this.discount_amount) + parseFloat(this.sendDoctor[0].advance) + parseFloat(this.sendDoctor[0].sponsor_due) + parseFloat(this.tds_amount));
      } else {
        this.sendDoctor[0].balance = parseFloat(this.sendDoctor[0].bill_amount) - (parseFloat(this.discount_amount) + parseFloat(this.sendDoctor[0].advance) + parseFloat(this.sendDoctor[0].sponsor_due));
      }
    }
    console.log("afterhospBillAmount", this.sendDoctor);
    if (num == '1') {
      this.paid_bill = "0";
      this.calculatePaidAmount('0');
    }
  }

  checkAndAssignValue(value: any): number {
    return isNaN(value) ? 0.00 : value;
  }

  checkBalance1() {
    this.paid_bill = "0";
  }

  calculateAdvanceAmount(num) {
    if (parseFloat(this.Advance_bill) != 0) {
      var advance_balance: any = parseFloat(this.Advance_bill);

      if (this.sendDoctor != undefined && this.sendDoctor.length != 0) {
        for (var i = 0; i < this.sendDoctor.length; i++) {
          console.log("this.sendDoctor[i].balance", this.sendDoctor[i].balance);
          // if (parseFloat(this.sendDoctor[i].bill_amount) >= parseFloat(advance_balance)) {
          //   this.sendDoctor[i].paid_amount = parseFloat("0.00");
          //   console.log("this.sendDoctor[i].balance  ", parseFloat(this.sendDoctor[i].balance), "advance_balance  ", parseFloat(advance_balance));
          //   this.sendDoctor[i].balance = parseFloat(this.sendDoctor[i].balance) - parseFloat(advance_balance);
          //   this.sendDoctor[i].advance = parseFloat(advance_balance);
          //   console.log("this.sendDoctor[i].balance  ", parseFloat(this.sendDoctor[i].balance), "advance_balance  ", parseFloat(advance_balance));
          //   advance_balance = parseFloat("0.00");
          //   this.sendDoctor[i].paid_flag = "Fully paid";
          // } else {
          //   this.sendDoctor[i].paid_amount = parseFloat("0.00");
          //   this.sendDoctor[i].balance = parseFloat("0.00");
          //   this.sendDoctor[i].advance = parseFloat(this.sendDoctor[i].bill_amount);
          //   advance_balance = parseFloat(advance_balance) - parseFloat(this.sendDoctor[i].bill_amount);
          //   this.sendDoctor[i].paid_flag = "Partially paid";
          // }

          if (parseFloat(this.sendDoctor[i].balance) > parseFloat(advance_balance)) {
            console.log("Condition Passed..");
            this.sendDoctor[i].advance =  parseFloat(advance_balance);
            this.sendDoctor[i].balance = parseFloat(this.sendDoctor[i].balance) - parseFloat(advance_balance);
            advance_balance = parseFloat("0.00");
            this.sendDoctor[i].paid_flag = "Partially paid";
          } else if (parseFloat(this.sendDoctor[i].balance) == parseFloat(advance_balance)) {
            this.sendDoctor[i].advance = parseFloat(advance_balance);
            this.sendDoctor[i].balance = parseFloat("0.00");
            advance_balance = parseFloat("0.00");
            this.sendDoctor[i].paid_flag = "Fully paid";
          } else {
            // this.sendDoctor[i].advance = parseFloat(advance_balance);
            // this.sendDoctor[i].balance = parseFloat(this.sendDoctor[i].balance) - parseFloat(advance_balance);
            // advance_balance = parseFloat("0.00");
            // this.sendDoctor[i].paid_flag = "Partially paid";
            this.sendDoctor[i].advance = parseFloat(this.sendDoctor[i].balance);
            advance_balance = parseFloat(advance_balance) - parseFloat(this.sendDoctor[i].balance);
            this.sendDoctor[i].balance = parseFloat("0.00");
            this.sendDoctor[i].paid_flag = "Fully Paid";
          }
        }
        console.log("DoctorBillAfterAdvanceCalculate", this.sendDoctor);
      }

      if (this.sendDiagnosis != undefined && this.sendDiagnosis.length != 0) {
        for (var i = 0; i < this.sendDiagnosis.length; i++) {
          // if (parseFloat(this.sendDiagnosis[i].bill_amount) >= parseFloat(advance_balance)) {
          //   this.sendDiagnosis[i].paid_amount = parseFloat("0.00");
          //   console.log("this.sendDiagnosis[i].balance", parseFloat(this.sendDiagnosis[i].balance) + "----" + "advance_balance" + parseFloat(advance_balance));
          //   this.sendDiagnosis[i].balance = parseFloat(this.sendDiagnosis[i].balance) - parseFloat(advance_balance);
          //   this.sendDiagnosis[i].advance = parseFloat(advance_balance);
          //   advance_balance = parseFloat("0.00");
          //   this.sendDiagnosis[i].paid_flag = "Fully paid";
          // } else {
          //   this.sendDiagnosis[i].paid_amount = parseFloat("0.00");
          //   this.sendDiagnosis[i].balance = parseFloat("0.00");
          //   this.sendDiagnosis[i].advance = parseFloat(this.sendDiagnosis[i].bill_amount);
          //   advance_balance = parseFloat(advance_balance) - parseFloat(this.sendDiagnosis[i].bill_amount);
          //   this.sendDiagnosis[i].paid_flag = "Partially paid";
          // }

          if (parseInt(this.sendDiagnosis[i].balance) > parseFloat(advance_balance)) {
            this.sendDiagnosis[i].advance =  parseFloat(advance_balance);
            console.log("this.sendDiagnosis[i].balance", parseFloat(this.sendDiagnosis[i].balance) + "----" + "paid_balance" + parseFloat(advance_balance));
            this.sendDiagnosis[i].balance = parseFloat(this.sendDiagnosis[i].balance) - parseFloat(advance_balance);
            advance_balance = parseFloat("0.00");
            this.sendDiagnosis[i].paid_flag = "Partially paid";
          } else if (parseInt(this.sendDiagnosis[i].balance) == parseFloat(advance_balance)) {
            this.sendDiagnosis[i].advance = parseFloat(advance_balance);
            this.sendDiagnosis[i].balance = parseFloat("0.00");
            advance_balance = parseFloat("0.00");
            this.sendDiagnosis[i].paid_flag = "Fully paid";
          } else {
            // this.sendDiagnosis[i].advance = parseFloat(paid_balance);
            // this.sendDiagnosis[i].balance = parseFloat(this.sendDiagnosis[i].balance) - parseFloat(paid_balance);
            // paid_balance = parseFloat("0.00");
            // this.sendDiagnosis[i].paid_flag = "Fully paid";
            this.sendDiagnosis[i].advance = parseFloat(this.sendDiagnosis[i].balance);
            advance_balance = parseFloat(advance_balance) - parseFloat(this.sendDiagnosis[i].balance);
            this.sendDiagnosis[i].balance = parseFloat("0.00");
            this.sendDiagnosis[i].paid_flag = "Fully Paid";
          }
        }
        console.log("DiagBillAfterAdvanceCalculate", this.sendDiagnosis);
      }

      if (this.sendPharmacy != undefined && this.sendPharmacy.length != 0) {
        for (var i = 0; i < this.sendPharmacy.length; i++) {
          // if (parseFloat(this.sendPharmacy[i].bill_amount) >= parseFloat(advance_balance)) {
          //   this.sendPharmacy[i].paid_amount = parseFloat("0.00");
          //   this.sendPharmacy[i].balance = parseFloat(this.sendPharmacy[i].balance) - parseFloat(advance_balance);
          //   this.sendPharmacy[i].advance = parseFloat(advance_balance);
          //   advance_balance = parseFloat("0.00");
          //   this.sendPharmacy[i].paid_flag = "Fully paid";
          // } else {
          //   this.sendPharmacy[i].paid_amount = parseFloat("0.00");
          //   this.sendPharmacy[i].balance = parseFloat("0.00");
          //   this.sendPharmacy[i].advance = parseFloat(this.sendPharmacy[i].bill_amount);
          //   advance_balance = parseFloat(advance_balance) - parseFloat(this.sendPharmacy[i].bill_amount);
          //   this.sendPharmacy[i].paid_flag = "Partially paid";
          // }

          if (parseInt(this.sendPharmacy[i].balance) > parseFloat(advance_balance)) {
            this.sendPharmacy[i].advance = parseFloat(advance_balance);
            this.sendPharmacy[i].balance = parseFloat(this.sendPharmacy[i].balance) - parseFloat(advance_balance);
            advance_balance = parseFloat("0.00");
            this.sendPharmacy[i].paid_flag = "Partially paid";
          } else if (parseInt(this.sendPharmacy[i].balance) == parseFloat(advance_balance)) {
            this.sendPharmacy[i].advance = parseFloat(advance_balance);
            this.sendPharmacy[i].balance = parseFloat("0.00");
            advance_balance = parseFloat("0.00");
            this.sendPharmacy[i].paid_flag = "Fully paid";
          } else {
            // this.sendPharmacy[i].advance = parseFloat(paid_balance);
            // this.sendPharmacy[i].balance = parseFloat(this.sendPharmacy[i].balance) - parseFloat(paid_balance);
            // paid_balance = parseFloat("0.00");
            // this.sendPharmacy[i].paid_flag = "Partially paid";
            this.sendPharmacy[i].advance = parseFloat(this.sendPharmacy[i].balance);
            advance_balance = parseFloat(advance_balance) - parseFloat(this.sendPharmacy[i].balance);
            this.sendPharmacy[i].balance = parseFloat("0.00");
            this.sendPharmacy[i].paid_flag = "Fully Paid";
          }
        }
        console.log("PharmacyBillAfterAdvanceCalculate", this.sendPharmacy);
      }
    }
    console.log("doctor bills " + JSON.stringify(this.sendDoctor))
    console.log("TOTAL BILL AMOUNT " + this.totalBill)
    this.remaing_billbal = (parseFloat(this.totalBill) - advance_balance).toFixed(2);
    if (this.remaing_billbal < 0 && parseInt(this.paid_bill) == 0) {
      this.adv_refflag = true;
      this.remaing_billbal = Math.abs(this.remaing_billbal);
      this.refundcal();
    } else {
      this.adv_refflag = false;
      this.refund_amount = 0;
      this.bank_details_flag_adv = false;
      if (this.paymentsarray.length == 0) {
        this.payType1 = this.paytypelist[0].pay_id;
        this.amountval = parseFloat(this.remaing_billbal);
      }
    }
    this.calculateSponsorDueAmount(num);
  }

  calculateSponsorDueAmount(num) {
    var sponser_due_balance: any;
    if (parseInt(this.spons_set_amount) != 0 || parseFloat(this.spons_set_amount) != 0) {
      sponser_due_balance = parseFloat(this.spons_set_amount);
    } else {
      sponser_due_balance = parseFloat(this.sponsorDue);
    }
    console.log(sponser_due_balance);
    if (sponser_due_balance != 0) {
      if (this.sendDoctor != undefined && this.sendDoctor.length != 0) {
        for (var i = 0; i < this.sendDoctor.length; i++) {
          if (parseInt(this.sendDoctor[i].balance) != 0) {
            console.log("Passed", parseInt(this.sendDoctor[i].balance));
            // if (parseFloat(this.sendDoctor[i].balance) >= parseFloat(sponser_due_balance)) {
            //   this.sendDoctor[i].paid_amount = parseFloat("0.00");
            //   this.sendDoctor[i].sponsor_due = parseFloat(sponser_due_balance);
            //   this.sendDoctor[i].balance = parseFloat(this.sendDoctor[i].balance) - parseFloat(sponser_due_balance);
            //   sponser_due_balance = parseFloat("0.00");
            //   this.sendDoctor[i].paid_flag = "Fully paid";
            // } else {
            //   this.sendDoctor[i].paid_amount = parseFloat("0.00");
            //   this.sendDoctor[i].sponsor_due = parseFloat(this.sendDoctor[i].balance);
            //   sponser_due_balance = parseFloat(sponser_due_balance) - parseFloat(this.sendDoctor[i].balance);
            //   this.sendDoctor[i].balance = parseFloat("0.00");
            //   this.sendDoctor[i].paid_flag = "Partially paid";
            // }

            if (parseFloat(this.sendDoctor[i].balance) > parseFloat(sponser_due_balance)) {
              console.log("Condition Passed..");
              this.sendDoctor[i].sponsor_due =  parseFloat(sponser_due_balance);
              this.sendDoctor[i].balance = parseFloat(this.sendDoctor[i].balance) - parseFloat(sponser_due_balance);
              sponser_due_balance = parseFloat("0.00");
              this.sendDoctor[i].paid_flag = "Partially paid";
            } else if (parseFloat(this.sendDoctor[i].balance) == parseFloat(sponser_due_balance)) {
              this.sendDoctor[i].sponsor_due = parseFloat(sponser_due_balance);
              this.sendDoctor[i].balance = parseFloat("0.00");
              sponser_due_balance = parseFloat("0.00");
              this.sendDoctor[i].paid_flag = "Fully paid";
            } else {
              // this.sendDoctor[i].paid_amount = parseFloat(paid_balance);
              // this.sendDoctor[i].balance = parseFloat(this.sendDoctor[i].balance) - parseFloat(paid_balance);
              // paid_balance = parseFloat("0.00");
              // this.sendDoctor[i].paid_flag = "Partially paid";
              this.sendDoctor[i].sponsor_due = parseFloat(this.sendDoctor[i].balance);
              sponser_due_balance = parseFloat(sponser_due_balance) - parseFloat(this.sendDoctor[i].balance);
              this.sendDoctor[i].balance = parseFloat("0.00");
              this.sendDoctor[i].paid_flag = "Fully Paid";
            }
          }
        }
        console.log("DoctorBillAfterSponsor_dueCalculate", this.sendDoctor);
      }

      if (this.sendDiagnosis != undefined && this.sendDiagnosis.length != 0) {
        for (var i = 0; i < this.sendDiagnosis.length; i++) {
          if (parseInt(this.sendDiagnosis[i].balance) != 0) {
            console.log("Passed", parseInt(this.sendDiagnosis[i].balance));
            // if (parseFloat(this.sendDiagnosis[i].balance) >= parseFloat(sponser_due_balance)) {
            //   this.sendDiagnosis[i].paid_amount = parseFloat("0.00");
            //   this.sendDiagnosis[i].sponsor_due = parseFloat(sponser_due_balance);
            //   console.log("this.sendDiagnosis[i].balance", parseFloat(this.sendDiagnosis[i].balance) + "----" + "sponser_due_balance" + parseFloat(sponser_due_balance));
            //   this.sendDiagnosis[i].balance = parseFloat(this.sendDiagnosis[i].balance) - parseFloat(sponser_due_balance);
            //   sponser_due_balance = parseFloat("0.00");
            //   this.sendDiagnosis[i].paid_flag = "Fully paid";
            // } else {
            //   this.sendDiagnosis[i].paid_amount = parseFloat("0.00");
            //   this.sendDiagnosis[i].sponsor_due = parseFloat(this.sendDiagnosis[i].balance);
            //   sponser_due_balance = parseFloat(sponser_due_balance) - parseFloat(this.sendDiagnosis[i].balance);
            //   this.sendDiagnosis[i].balance = parseFloat("0.00");
            //   this.sendDiagnosis[i].paid_flag = "Partially paid";
            // }

            if (parseInt(this.sendDiagnosis[i].balance) > parseFloat(sponser_due_balance)) {
              this.sendDiagnosis[i].sponsor_due =  parseFloat(sponser_due_balance);
              console.log("this.sendDiagnosis[i].balance", parseFloat(this.sendDiagnosis[i].balance) + "----" + "paid_balance" + parseFloat(sponser_due_balance));
              this.sendDiagnosis[i].balance = parseFloat(this.sendDiagnosis[i].balance) - parseFloat(sponser_due_balance);
              sponser_due_balance = parseFloat("0.00");
              this.sendDiagnosis[i].paid_flag = "Partially paid";
            } else if (parseInt(this.sendDiagnosis[i].balance) == parseFloat(sponser_due_balance)) {
              this.sendDiagnosis[i].sponsor_due = parseFloat(sponser_due_balance);
              this.sendDiagnosis[i].balance = parseFloat("0.00");
              sponser_due_balance = parseFloat("0.00");
              this.sendDiagnosis[i].paid_flag = "Fully paid";
            } else {
              // this.sendDiagnosis[i].paid_amount = parseFloat(paid_balance);
              // this.sendDiagnosis[i].balance = parseFloat(this.sendDiagnosis[i].balance) - parseFloat(paid_balance);
              // paid_balance = parseFloat("0.00");
              // this.sendDiagnosis[i].paid_flag = "Fully paid";
              this.sendDiagnosis[i].sponsor_due = parseFloat(this.sendDiagnosis[i].balance);
              sponser_due_balance = parseFloat(sponser_due_balance) - parseFloat(this.sendDiagnosis[i].balance);
              this.sendDiagnosis[i].balance = parseFloat("0.00");
              this.sendDiagnosis[i].paid_flag = "Fully Paid";
            }
          }
        }
        console.log("DiagBillAfterSponsor_dueCalculate", this.sendDiagnosis);
      }

      if (this.sendPharmacy != undefined && this.sendPharmacy.length != 0) {
        for (var i = 0; i < this.sendPharmacy.length; i++) {
          if (parseInt(this.sendPharmacy[i].balance) != 0) {
            console.log("Passed", parseInt(this.sendPharmacy[i].balance));
            // if (parseFloat(this.sendPharmacy[i].balance) >= parseFloat(sponser_due_balance)) {
            //   this.sendPharmacy[i].paid_amount = parseFloat("0.00");
            //   this.sendPharmacy[i].sponsor_due = parseFloat(sponser_due_balance);
            //   this.sendPharmacy[i].balance = parseFloat(this.sendPharmacy[i].balance) - parseFloat(sponser_due_balance);
            //   sponser_due_balance = parseFloat("0.00");
            //   this.sendPharmacy[i].paid_flag = "Fully paid";
            // } else {
            //   this.sendPharmacy[i].paid_amount = parseFloat("0.00");
            //   this.sendPharmacy[i].sponsor_due = parseFloat(this.sendPharmacy[i].balance);
            //   sponser_due_balance = parseFloat(sponser_due_balance) - parseFloat(this.sendPharmacy[i].balance);
            //   this.sendPharmacy[i].balance = parseFloat("0.00");
            //   this.sendPharmacy[i].paid_flag = "Partially paid";
            // }

            if (parseInt(this.sendPharmacy[i].balance) > parseFloat(sponser_due_balance)) {
              this.sendPharmacy[i].sponsor_due = parseFloat(sponser_due_balance);
              this.sendPharmacy[i].balance = parseFloat(this.sendPharmacy[i].balance) - parseFloat(sponser_due_balance);
              sponser_due_balance = parseFloat("0.00");
              this.sendPharmacy[i].paid_flag = "Partially paid";
            } else if (parseInt(this.sendPharmacy[i].balance) == parseFloat(sponser_due_balance)) {
              this.sendPharmacy[i].sponsor_due = parseFloat(sponser_due_balance);
              this.sendPharmacy[i].balance = parseFloat("0.00");
              sponser_due_balance = parseFloat("0.00");
              this.sendPharmacy[i].paid_flag = "Fully paid";
            } else {
              // this.sendPharmacy[i].paid_amount = parseFloat(paid_balance);
              // this.sendPharmacy[i].balance = parseFloat(this.sendPharmacy[i].balance) - parseFloat(paid_balance);
              // paid_balance = parseFloat("0.00");
              // this.sendPharmacy[i].paid_flag = "Partially paid";
              this.sendPharmacy[i].sponsor_due = parseFloat(this.sendPharmacy[i].balance);
              sponser_due_balance = parseFloat(sponser_due_balance) - parseFloat(this.sendPharmacy[i].balance);
              this.sendPharmacy[i].balance = parseFloat("0.00");
              this.sendPharmacy[i].paid_flag = "Fully Paid";
            }
          }
        }
        console.log("PharmacyBillAfterSponsor_dueCalculate", this.sendPharmacy);
      }
    }
    if (this.remaing_billbal)
      this.remaing_billbal = (parseFloat(this.totalBillAmountvalue) - sponser_due_balance);
    // if (num != "1") {
    //   this.calculatePaidAmount('0');
    // }  
  }

  calculatePaidAmount(num) {
    if (num == "1") {
      this.getAllBills("1");
    } 
    console.log("Function Called");
    
    this.paid_bill1 = parseFloat(this.paid_bill) + parseFloat(this.totalPaidAmountvalue);

    this.paid_bill = this.paid_bill1;
    console.log(this.paid_bill);
    console.log("totalBillAmountvalue",this.totalBillAmountvalue);
    console.log("sponsorDue = "+this.sponsorDue);
    if (this.sponsorDue != undefined) {
      if ((parseInt(this.spons_set_amount) != 0 || parseFloat(this.spons_set_amount) && this.inTDS && parseInt(this.tds_amount) != 0)) {
        var totaldeduct = parseFloat(this.paid_bill1) + parseFloat(this.Advance_bill) + this.discount_amount + parseFloat(this.spons_set_amount) + parseInt(this.tds_amount);
        this.remaing_billbal = (parseFloat(this.totalBillAmountvalue) - totaldeduct);
      } else if (parseInt(this.spons_set_amount) != 0 || parseFloat(this.spons_set_amount) != 0) {
        var totaldeduct = parseFloat(this.paid_bill1) + parseFloat(this.Advance_bill) + this.discount_amount + parseFloat(this.spons_set_amount);
        this.remaing_billbal = (parseFloat(this.totalBillAmountvalue) - totaldeduct);
      } else {
        var totaldeduct = parseFloat(this.paid_bill1) + parseFloat(this.Advance_bill) + this.discount_amount + parseFloat(this.sponsorDue);
        this.remaing_billbal = (parseFloat(this.totalBillAmountvalue) - totaldeduct);
      }
    } else {
      var totaldeduct = parseFloat(this.paid_bill1) + parseFloat(this.Advance_bill) + this.discount_amount;
      this.remaing_billbal = (parseFloat(this.totalBillAmountvalue) - totaldeduct);
    }
    console.log("remaing_billbal", this.remaing_billbal);
    if (this.remaing_billbal < 0 && parseInt(this.paid_bill) == 0) {
      this.adv_refflag = true;
      this.remaing_billbal = Math.abs(this.remaing_billbal);
      this.refundcal();
    } else {
      this.adv_refflag = false;
      this.refund_amount = 0;
      this.bank_details_flag_adv = false;
      if (this.paymentsarray.length == 0) {
        this.payType1 = this.paytypelist[0].pay_id;
        this.amountval = parseFloat(this.remaing_billbal);
      }
    }
    var paid_balance: any = parseFloat(this.paid_bill);
    console.log(paid_balance);
    if (paid_balance != 0) {
      if (this.sendDoctor != undefined && this.sendDoctor.length != 0) {
        for (var i = 0; i < this.sendDoctor.length; i++) {
          if (parseInt(this.sendDoctor[i].balance) != 0) {
            console.log("Passed", parseInt(this.sendDoctor[i].balance));
            console.log(parseFloat(this.sendDoctor[i].balance) + "-----------" + parseFloat(paid_balance));
            if (parseFloat(this.sendDoctor[i].balance) > parseFloat(paid_balance)) {
              console.log("Condition Passed..");
              this.sendDoctor[i].paid_amount =  parseFloat(paid_balance);
              this.sendDoctor[i].balance = parseFloat(this.sendDoctor[i].balance) - parseFloat(paid_balance);
              paid_balance = parseFloat("0.00");
              this.sendDoctor[i].paid_flag = "Partially paid";
            } else if (parseFloat(this.sendDoctor[i].balance) == parseFloat(paid_balance)) {
              this.sendDoctor[i].paid_amount = parseFloat(paid_balance);
              this.sendDoctor[i].balance = parseFloat("0.00");
              paid_balance = parseFloat("0.00");
              this.sendDoctor[i].paid_flag = "Fully paid";
            } else {
              // this.sendDoctor[i].paid_amount = parseFloat(paid_balance);
              // this.sendDoctor[i].balance = parseFloat(this.sendDoctor[i].balance) - parseFloat(paid_balance);
              // paid_balance = parseFloat("0.00");
              // this.sendDoctor[i].paid_flag = "Partially paid";
              this.sendDoctor[i].paid_amount = parseFloat(this.sendDoctor[i].balance);
              paid_balance = parseFloat(paid_balance) - parseFloat(this.sendDoctor[i].balance);
              this.sendDoctor[i].balance = parseFloat("0.00");
              this.sendDoctor[i].paid_flag = "Fully Paid";
            }
          }
        }
        console.log("DoctorBillAfterPaidAmountCalculate",JSON.stringify(this.sendDoctor));
      }

      if (this.sendDiagnosis != undefined && this.sendDiagnosis.length != 0) {
        for (var i = 0; i < this.sendDiagnosis.length; i++) {
          if (parseInt(this.sendDiagnosis[i].balance) != 0) {

            console.log("Passed", parseInt(this.sendDiagnosis[i].balance));
            if (parseInt(this.sendDiagnosis[i].balance) > parseFloat(paid_balance)) {
              this.sendDiagnosis[i].paid_amount =  parseFloat(paid_balance);
              console.log("this.sendDiagnosis[i].balance", parseFloat(this.sendDiagnosis[i].balance) + "----" + "paid_balance" + parseFloat(paid_balance));
              this.sendDiagnosis[i].balance = parseFloat(this.sendDiagnosis[i].balance) - parseFloat(paid_balance);
              paid_balance = parseFloat("0.00");
              this.sendDiagnosis[i].paid_flag = "Partially paid";
            } else if (parseInt(this.sendDiagnosis[i].balance) == parseFloat(paid_balance)) {
              this.sendDiagnosis[i].paid_amount = parseFloat(paid_balance);
              this.sendDiagnosis[i].balance = parseFloat("0.00");
              paid_balance = parseFloat("0.00");
              this.sendDiagnosis[i].paid_flag = "Fully paid";
            } else {
              // this.sendDiagnosis[i].paid_amount = parseFloat(paid_balance);
              // this.sendDiagnosis[i].balance = parseFloat(this.sendDiagnosis[i].balance) - parseFloat(paid_balance);
              // paid_balance = parseFloat("0.00");
              // this.sendDiagnosis[i].paid_flag = "Fully paid";
              this.sendDiagnosis[i].paid_amount = parseFloat(this.sendDiagnosis[i].balance);
              paid_balance = parseFloat(paid_balance) - parseFloat(this.sendDiagnosis[i].balance);
              this.sendDiagnosis[i].balance = parseFloat("0.00");
              this.sendDiagnosis[i].paid_flag = "Fully Paid";
            }
          }
        }
        console.log("DiagBillAfterAfterPaidAmountCalculate", JSON.stringify(this.sendDiagnosis));
      }

      if (this.sendPharmacy != undefined && this.sendPharmacy.length != 0) {
        for (var i = 0; i < this.sendPharmacy.length; i++) {
          if (parseInt(this.sendPharmacy[i].balance) != 0) {
            console.log("Passed", parseInt(this.sendPharmacy[i].balance), "----", parseFloat(paid_balance));
            if (parseInt(this.sendPharmacy[i].balance) > parseFloat(paid_balance)) {
              this.sendPharmacy[i].paid_amount = parseFloat(paid_balance);
              this.sendPharmacy[i].balance = parseFloat(this.sendPharmacy[i].balance) - parseFloat(paid_balance);
              paid_balance = parseFloat("0.00");
              this.sendPharmacy[i].paid_flag = "Partially paid";
            } else if (parseInt(this.sendPharmacy[i].balance) == parseFloat(paid_balance)) {
              this.sendPharmacy[i].paid_amount = parseFloat(paid_balance);
              this.sendPharmacy[i].balance = parseFloat("0.00");
              paid_balance = parseFloat("0.00");
              this.sendPharmacy[i].paid_flag = "Fully paid";
            } else {
              // this.sendPharmacy[i].paid_amount = parseFloat(paid_balance);
              // this.sendPharmacy[i].balance = parseFloat(this.sendPharmacy[i].balance) - parseFloat(paid_balance);
              // paid_balance = parseFloat("0.00");
              // this.sendPharmacy[i].paid_flag = "Partially paid";
              this.sendPharmacy[i].paid_amount = parseFloat(this.sendPharmacy[i].balance);
              paid_balance = parseFloat(paid_balance) - parseFloat(this.sendPharmacy[i].balance);
              this.sendPharmacy[i].balance = parseFloat("0.00");
              this.sendPharmacy[i].paid_flag = "Fully Paid";
            }
          }
        }
        console.log("PharmacyBillAfterAfterPaidAmountCalculate", JSON.stringify(this.sendPharmacy));
      }
    }

    this.calculateAdvanceAmount(num);

    if (this.sponsorDue != undefined) {
      if ((parseInt(this.spons_set_amount) != 0 || parseFloat(this.spons_set_amount) && this.inTDS && parseInt(this.tds_amount) != 0)) {
        var totaldeduct = parseFloat(this.paid_bill1) + parseFloat(this.Advance_bill) + this.discount_amount + parseFloat(this.spons_set_amount) + parseInt(this.tds_amount);
        this.remaing_billbal = (parseFloat(this.totalBillAmountvalue) - totaldeduct);
      } else if (parseInt(this.spons_set_amount) != 0 || parseFloat(this.spons_set_amount) != 0) {
        var totaldeduct = parseFloat(this.paid_bill1) + parseFloat(this.Advance_bill) + this.discount_amount + parseFloat(this.spons_set_amount);
        this.remaing_billbal = (parseFloat(this.totalBillAmountvalue) - totaldeduct);
      } else {
        var totaldeduct = parseFloat(this.paid_bill1) + parseFloat(this.Advance_bill) + this.discount_amount + parseFloat(this.sponsorDue);
        this.remaing_billbal = (parseFloat(this.totalBillAmountvalue) - totaldeduct);
      }
    } else {
      var totaldeduct = parseFloat(this.paid_bill1) + parseFloat(this.Advance_bill) + this.discount_amount;
      this.remaing_billbal = (parseFloat(this.totalBillAmountvalue) - totaldeduct);
    }
    console.log("remaing_billbal", this.remaing_billbal);
    if (this.remaing_billbal < 0 && parseInt(this.paid_bill) == 0) {
      this.adv_refflag = true;
      this.remaing_billbal = Math.abs(this.remaing_billbal);
      this.refundcal();
    } else {
      this.adv_refflag = false;
      this.refund_amount = 0;
      this.bank_details_flag_adv = false;
      if (this.paymentsarray.length == 0) {
        this.payType1 = this.paytypelist[0].pay_id;
        this.amountval = parseFloat(this.remaing_billbal);
      }
    }

    if (this.sendDoctor != undefined && this.sendDoctor.length != 0) {
      for (var i = 0; i < this.sendDoctor.length; i++) {
        if (parseInt(this.sendDoctor[i].balance) == 0) {
          this.sendDoctor[i].paid_flag = "Fully paid";
        } else if (parseInt(this.sendDoctor[i].balance) == parseInt(this.sendDoctor[i].bill_amount)) {
          this.sendDoctor[i].paid_flag = "Un paid";
        } else {
          this.sendDoctor[i].paid_flag = "Partially paid";
        }
      }
    }

    if (this.sendDiagnosis != undefined && this.sendDiagnosis.length != 0) {
      for (var i = 0; i < this.sendDiagnosis.length; i++) {
        if (parseInt(this.sendDiagnosis[i].balance) == 0) {
          this.sendDiagnosis[i].paid_flag = "Fully paid";
        } else if (parseInt(this.sendDiagnosis[i].balance) == parseInt(this.sendDiagnosis[i].bill_amount)) {
          this.sendDiagnosis[i].paid_flag = "Un paid";
        } else {
          this.sendDiagnosis[i].paid_flag = "Partially paid";
        }
      }
    }

    if (this.sendPharmacy != undefined && this.sendPharmacy.length != 0) {
      for (var i = 0; i < this.sendPharmacy.length; i++) {
        if (parseInt(this.sendPharmacy[i].balance) == 0) {
          this.sendPharmacy[i].paid_flag = "Fully paid";
        } else if (parseInt(this.sendPharmacy[i].balance) == parseInt(this.sendPharmacy[i].bill_amount)) {
          this.sendPharmacy[i].paid_flag = "Un paid";
        } else {
          this.sendPharmacy[i].paid_flag = "Partially paid";
        }
      }
    }


    if (this.totalBillAmountvalue == this.paid_bill) {
      this.paidflag = false;
    } else {
      this.paidflag = true;
    }

    if (this.totalDiscountvalue === "0" || this.totalDiscountvalue === "0.00" || this.totalDiscountvalue === "0.0") {
      this.discountflag = false;
    } else {
      this.discountflag = true;
    }

    if (this.remaing_billbal == 0) {
      this.balanceflag = false;
    } else {
      this.balanceflag = true;
    }

    if (this.Advance_bill == "0.00" || this.Advance_bill === "0") {
      this.advanceflag = false;
    } else {
      this.advanceflag = true;
    }
    this.remaing_billbal = this.checkAndAssignValue(this.remaing_billbal);
    this.paid_bill = this.checkAndAssignValue(this.paid_bill);
    console.log(JSON.parse(JSON.stringify(this.hosp_pay_array)));
    if (!this.adv_refflag && this.paymentsarray.length == 0) {
      this.changePaidAmount();
    }   
  }

  allBillPaymentsArray() {
    console.log("hosp_pay_array",this.hosp_pay_array);
    if (this.sendDoctor.length != 0 && !this.adv_refflag) {
      for (var i = 0; i < this.sendDoctor.length; i++) {
        if (parseInt(this.sendDoctor[i].paid_amount) != 0) {
          this.sendDoctor[i].paytypes = this.paymentArrayCalculation(this.sendDoctor[i].paid_amount);
          console.log(this.sendDoctor[i].paytypes);
        }
      }

      if (this.sendDoctor[0].paytypes != undefined && this.sendDoctor[0].paytypes.length != 0) {
        if (this.hosp_pay_array != undefined && this.hosp_pay_array.length != 0) {
          console.log(this.hosp_pay_array);
          for (var h = 0; h < this.hosp_pay_array.length; h++) {
            for (var d = 0; d < this.sendDoctor[0].paytypes.length; d++) {
              if (this.hosp_pay_array[h].pay_id == this.sendDoctor[0].paytypes[d].pay_id) {
                if (this.sendDoctor[0].paytypes[d].amount > this.hosp_pay_array[h].amount) {
                  this.sendDoctor[0].paytypes[d].amount = parseFloat(this.sendDoctor[0].paytypes[d].amount) - parseFloat(this.hosp_pay_array[h].amount)
                } else if ((this.sendDoctor[0].paytypes[d].amount == this.hosp_pay_array[h].amount)) {
                  this.sendDoctor[0].paytypes.splice(i, 1);
                }
              }
            }
          }
        }
      }
    }
    if (this.sendDiagnosis.length != 0 && !this.adv_refflag) {
      for (var i = 0; i < this.sendDiagnosis.length; i++) {
        if (parseInt(this.sendDiagnosis[i].paid_amount) != 0) {
          this.sendDiagnosis[i].paytypes = this.paymentArrayCalculation(this.sendDiagnosis[i].paid_amount);
        }
      }
    }
    if (this.sendPharmacy.length != 0 && !this.adv_refflag) {
      for (var i = 0; i < this.sendPharmacy.length; i++) {
        if (parseInt(this.sendPharmacy[i].paid_amount) != 0) {
          this.sendPharmacy[i].paytypes = this.paymentArrayCalculation(this.sendPharmacy[i].paid_amount);
        }
      }
    }
    console.log("sendDoctor----",this.sendDoctor," sendDiagnosis----",this.sendDiagnosis," sendPharmacy----",this.sendPharmacy);
  }

  changePaidAmount() {
    // this.paymentsarray = [];
    if (this.paymentsarray.length == 0) {
      this.payType1 = this.paytypelist[0].pay_id;
    }

    if (this.adv_refflag == false) {
      this.amountval = parseFloat(this.paid_bill);
    } else {
      if (this.paid_bill == 0) {
        this.amountval = 0;
      } else {
        this.amountval = parseFloat(this.paid_bill) - parseFloat(this.remaing_billbal);
      }
    }
    
  }

  getAllBills(num) {
    this.docBills = [];
    this.medBills = [];
    this.diagBills = [];
    this.sendDoctor = [];
    this.sendPharmacy = [];
    this.sendDiagnosis = [];
    this.totalBillAmount1 = "0.00";
    this.totalPaidAmount = "0.00";
    // this.totalPaidAmount = parseFloat(this.paid_bill);
    this.TotalBalance = "0.00";
    this.final_amount = "0.00";
    this.totalDiscount = "0.00";
    // this.totalPaid = "0.00";
    // this.totalPaid = parseFloat(this.paid_bill);
    this.totalBill = "0.00";
    this.refund_amount = 0;
    this.refund_reason = '';
    this.adv_refflag = false;
    this.doctorBills = [];
    this.doctorBills.push({
      advance: "0.00",
      sponsor_due: "0.00",
      bill_amount: this.hospital_finalAmount,
      balance: this.hospital_finalAmount,
      discount: this.hospital_discount_amount,
      billing_id: this.billId,
      created_date: this.CurrentDatetime,
      created_time: this.currentTime,
      paid_amount: "0.00",
      paid_flag: "Un paid"
    })
    if (this.inTDS && parseInt(this.tds_amount) != 0) {
      this.doctorBills[0].tds_amount = this.tds_amount;
    }
    var doctorBills = this.doctorBills;
    console.log(doctorBills);
    if (doctorBills != undefined) {
      for (var j = 0; j < doctorBills.length; j++) {
        var bill_amount = doctorBills[j].bill_amount != undefined ? doctorBills[j].bill_amount : "0.00";
        var bill_date = doctorBills[j].created_date != undefined ? Date_Formate(doctorBills[j].created_date) : "";
        var advance = doctorBills[j].advance != undefined ? parseFloat(doctorBills[j].advance).toFixed(2) : "0.00";
        var sponsor_due = doctorBills[j].sponsor_due != undefined ? parseFloat(doctorBills[j].sponsor_due).toFixed(2) : "0.00";
        // var balance = (parseFloat(bill_amount) - (parseFloat(doctorBills[j].paid_amount) + parseFloat(doctorBills[j].discount)) - parseFloat(advance)).toFixed(2);
        var balance = doctorBills[j].bill_amount != undefined ? doctorBills[j].bill_amount : "0.00";;
        this.totalBillAmount1 = (parseFloat(this.totalBillAmount1) + parseFloat(bill_amount)).toFixed(2);
        this.totalPaidAmount = (parseFloat(this.totalPaidAmount) + parseFloat(doctorBills[j].paid_amount)).toFixed(2);
        this.TotalBalance = (parseFloat(this.TotalBalance) + parseFloat(doctorBills[j].balance)).toFixed(2);

        var tempDoc = {
          billing_id: doctorBills[j].billing_id,
          bill_type: 'Hospital',
          bill_amount: parseFloat(bill_amount).toFixed(2),
          advance: parseFloat(advance).toFixed(2),
          sponsor_due: parseFloat(sponsor_due).toFixed(2),
          balance: parseFloat(balance).toFixed(2),
          bill_date: bill_date + " " + Time_Formate(doctorBills[j].created_time),
          paid_amount: parseFloat(doctorBills[j].paid_amount).toFixed(2),
          discount: parseFloat(doctorBills[j].discount).toFixed(2),
          fin_year: this.fin_year,
          checked: false,
          tds_amount: doctorBills[j].tds_amount
        }
        this.docBills.push(tempDoc);
        console.log(this.docBills);
      }
    }
    // for Pharma bill 
    var pharmaBills = this.pharma_bills;
    console.log("pharmaBills" + JSON.stringify(pharmaBills));
    if (pharmaBills != undefined) {
      this.medBills = [];
      for (var j = 0; j < pharmaBills.length; j++) {
        var tempPharma = {};
        var pbill_amount;
        if (this.is_insurance == true) {
          if (pharmaBills[j].sponsor_due != undefined) {
            pbill_amount = pharmaBills[j].sponsor_due;
          }
        } else {
          pbill_amount = pharmaBills[j].bill_amount != undefined ? pharmaBills[j].bill_amount : "0.00";
        }
        var blanc;
        if (this.is_insurance == true) {
          if (pharmaBills[j].sponsor_due != undefined) {
            blanc = pharmaBills[j].sponsor_due;
          }
        } else {
          // blanc = pharmaBills[j].balance != undefined ? pharmaBills[j].balance : "0.00";
          blanc = pharmaBills[j].bill_amount != undefined ? pharmaBills[j].bill_amount : "0.00";
        }

        var bill_date = pharmaBills[j].created_date != undefined ? Date_Formate(pharmaBills[j].created_date) : "";
        // var paid = pharmaBills[j].paid_amount != undefined ? pharmaBills[j].paid_amount : "0.00";
        var paid = "0.00";
        // var discount = pharmaBills[j].bill_disc != undefined ? pharmaBills[j].bill_disc : "0.00";
        var discount = "0.00";
        // var pharma_advance = pharmaBills[j].advance != undefined ? pharmaBills[j].advance : "0.00";
        var pharma_advance = "0.00";
        // var sponsor_due = pharmaBills[j].sponsor_due != undefined ? parseFloat(pharmaBills[j].sponsor_due).toFixed(2) : "0.00";
        var sponsor_due = "0.00";
        this.totalBillAmount1 = (parseFloat(this.totalBillAmount1) + parseFloat(bill_amount)).toFixed(2);
        this.totalPaidAmount = (parseFloat(this.totalPaidAmount) + parseFloat(paid)).toFixed(2);
        this.TotalBalance = (parseFloat(this.TotalBalance) + parseFloat(blanc)).toFixed(2);

        tempPharma = {
          billing_id: pharmaBills[j].billing_id,
          bill_type: 'Pharmacy',
          bill_amount: parseFloat(pbill_amount).toFixed(2),
          advance: parseFloat(pharma_advance).toFixed(2),
          sponsor_due: parseFloat(sponsor_due).toFixed(2),
          balance: parseFloat(blanc).toFixed(2),
          bill_date: bill_date + " " + Time_Formate(pharmaBills[j].created_time),
          paid_amount: parseFloat(paid).toFixed(2),
          checked: false,
          discount: parseFloat(discount).toFixed(2),
          fin_year: this.fin_year,
          pharma_id: this.pharma_id,
        }
        this.medBills.push(tempPharma);
        console.log("medBills" + JSON.stringify(this.medBills));
      }
    }
    // for Diagnosis bill
    var diagBills = this.diagnosis_bills;
    console.log("diagBills" + JSON.stringify(diagBills));
    if (diagBills != undefined) {
      this.diagBills = [];
      var diag = {};
      for (var j = 0; j < diagBills.length; j++) {
        var bill_amount = diagBills[j].bill_amount != undefined ? diagBills[j].bill_amount : "0.00";
        // var paid = diagBills[j].paid_amount != undefined ? diagBills[j].paid_amount : "0.00";
        var paid =  "0.00";
        // var diagbalance = diagBills[j].balance != undefined ? diagBills[j].balance : "0.00";
        var diagbalance:any = diagBills[j].bill_amount != undefined ? diagBills[j].bill_amount : "0.00";
        // var discount = diagBills[j].discount != undefined ? diagBills[j].discount : "0.00";
        var discount = "0.00";
        // var diag_advance = diagBills[j].advance != undefined ? diagBills[j].advance : "0.00";
        var diag_advance = "0.00";
        // var sponsor_due = diagBills[j].sponsor_due != undefined ? parseFloat(diagBills[j].sponsor_due).toFixed(2) : "0.00";
        var sponsor_due = "0.00";
        var bill_date = diagBills[j].created_date != undefined ? Date_Formate(diagBills[j].created_date) : "";
        this.totalBillAmount1 = (parseFloat(this.totalBillAmount1) + parseFloat(bill_amount)).toFixed(2);
        this.totalPaidAmount = (parseFloat(this.totalPaidAmount) + parseFloat(paid)).toFixed(2);
        this.TotalBalance = (parseFloat(this.TotalBalance) + parseFloat(diagbalance)).toFixed(2);

        diag = {
          billing_id: diagBills[j].billing_id,
          bill_type: 'Diagnosis',
          sub_type: diagBills[j].sub_type,
          bill_amount: parseFloat(bill_amount).toFixed(2),
          advance: parseFloat(diag_advance).toFixed(2),
          sponsor_due: parseFloat(sponsor_due).toFixed(2),
          balance: parseFloat(diagbalance).toFixed(2),
          bill_date: bill_date + " " + Time_Formate(diagBills[j].created_time),
          paid_amount: parseFloat(paid).toFixed(2),
          checked: false,
          discount: parseFloat(discount).toFixed(2),
          fin_year: this.fin_year,
        }
        this.diagBills.push(diag);
        console.log("AfterSetDiagBills", JSON.stringify(this.diagBills));
      }
    }

    this.allBillSet(num);
  }

  Get_current_date() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(
        ipaddress.getIp.toString() + 'adm/curdate',
        JSON.stringify({
          country: ipaddress.country_code.toString(),
        }),
        { headers: headers }
      )
      .subscribe(
        (response) => {
          var obj = response.json();
          console.log(obj);
          if (obj.current_date != null) {
            this.CurrentDatetime = obj.current_date;
            this.currentTime = obj.current_time;
            var timevalue = Time_Formate(this.currentTime);
            var tval = timevalue.split(' ');
            var tval1 = tval[0].split(':');
            this.bill_time1 = tval1[0];
            this.bill_time2 = tval1[1];
            this.bill_time3 = tval[1];
            var get_date = obj.current_date.split('-');
            this.Appoint_Date = get_date[2] + '-' + get_date[1] + '-' + get_date[0];
            this.currentDate = obj.current_date;
            this.bill_date = obj.current_date;
            this.time = Time_Formate(obj.current_time);
            this.date = obj.current_date;
            this.Appoint_Date = this.CurrentDatetime;
            Doc_Helper.setBillFromDate(null);
            Doc_Helper.setBillToDate(null);
            Doc_Helper.setBillFromDate(obj.current_date);
            Doc_Helper.setBillToDate(obj.current_date);
          }
        },
        (error) => { }
      );
  }

  billDateChange(date) {
    if (date > this.currentDate) {
      this.toastr.error('Select current or past date');
      this.bill_date = undefined;
    } else {
      this.bill_date = date;
    }
  }

  checkUncheckAll() {
    this.checklist = this.billaddlist;
    for (var i = 0; i < this.checklist.length; i++) {
      this.checklist[i].isSelected = this.masterSelected;
    }
  }

  isAllSelected() {
    this.masterSelected = this.checklist.every(function (item: any) {
      return item.isSelected == true;
    });
  }

  getCheckedItemList() {
    this.checkedList = [];
    for (var i = 0; i < this.checklist.length; i++) {
      if (this.checklist[i].isSelected)
        this.checkedList.push(this.checklist[i].patient_id);
    }

    if (this.checklist.length == this.checkedList.length) {
      this.toastr.success(Message_data.allRowSlt);
    } else if (this.checkedList.length != 0) {
      this.toastr.success('Thank you...', `selected Id: ${this.checkedList}`);
    } else {
      this.checkedList = `No row seleted`;
      this.toastr.success(Message_data.noRowSlt);
    }
  }

  clearAll() {
    this.checklist = this.billaddlist;
    for (var i = 0; i < this.checklist.length; i++) {
      this.checklist[i].isSelected = false;
    }
    this.masterSelected = false;
    this.checkedList = [];
  }

  print_area(print) {
    this.temp_key = print;
    if (this.categoryChargeList.length == 0) {
      this.toastr.error(Message_data.getAddBill());
    } else {
      this.print_area1();
    }
  }

  print_area1() {
    var margin_top;
    let printContents, popupWin;
    if (this.print_template != undefined && this.print_template == 'banner') {
      printContents = this.printbanner.nativeElement.innerHTML;
      margin_top = '0px';
    } else {
      printContents = this.printnoheader.nativeElement.innerHTML;
      margin_top = '122px';
    }
    popupWin = window.open(
      '',
      '_blank',
      'top=20,left=10,height=100%,width=auto'
    );
    popupWin.document.open();
    popupWin.document.write(`
    <html>
      <head>
        <title>Bill</title>
        <link rel="stylesheet" media="screen" href="">
        <style>                        
          @page {
            size: auto;
            margin-top: ${margin_top};
            margin-right: 0px;
            margin-left: 0px;
            margin-bottom: 0px;
            overflow: visible;
          } 
          * {
              font-size: 12px !important;
              line-height: 1 !important;
              page-break-inside: always;
              overflow: visible;
            }                                     
          @media print {   
            body {
              height: auto; /* Allow body height to adjust dynamically */
            }          
            .page-break {
              page-break-before: always; /* or page-break-after: always; */
              page-break-inside: always;
            }
          }           
          .address_visibility {
            visibility: hidden;
          }        
          table.report-container {
            page-break-after: always;
          }
          thead.report-header {
            display: table-header-group;
          }
          tfoot.report-footer {
            display: table-footer-group;
          }
          .print:last-child {
            page-break-after: auto;
          }
          .alignRight {
            text-align: right;
          }
          .col-print-1 {width:8%;  float:left;}
          .col-print-2 {width:16%; float:left;}
          .col-print-3 {width:25%; float:left;}
          .col-print-4 {width:33%; float:left;}
          .col-print-5 {width:42%; float:left;}
          .col-print-6 {width:50%; float:left;}
          .col-print-7 {width:58%; float:left;}
          .col-print-8 {width:66%; float:left;}
          .col-print-9 {width:75%; float:left;}
          .col-print-10 {width:83%; float:left;}
          .col-print-11 {width:92%; float:left;}
          .col-print-12 {width:100%; float:left;}
        </style>
      </head>
      <body onload="window.print(); window.onfocus=function(){ window.close();}">${printContents}</body>
    </html>`);
    popupWin.document.close();
  }

  array_exits(array, id) {
    var flag = false;
    for (var i in array) {
      if (array[i].bill_id === id) {
        flag = true;
        break;
      }
    }
    return flag;
  }

  showCashModal() {
    this.payType1 = '1';
    this.showModal = true;
  }

  saveCashDenomination() {
    this.amountval = this.totalAmount;
    this.showModal = false;
  }

  calculateTotalAmount() {
    this.totalAmount = this.cashDenomination.reduce((total, denomination) => {
      return total + denomination.value * denomination.count;
    }, 0);
  }

  addPaymentDetails() {
    console.log("hosp_pay_array",JSON.parse(JSON.stringify(this.hosp_pay_array)));
    this.hosp_pay_array = JSON.parse(JSON.stringify(this.hosp_pay_array));
    this.local_payment_array = [];
    var bamt: any = 0,
      balance = 0;
    var results = this.finekeyInArray_Payment(
      this.paymentsarray,
      this.payType1
    );
    if (!this.adv_refflag) {
      if (this.payType1 != undefined && this.payType1 != '') {
        for (var i = 0; i < this.paytypelist.length; i++) {
          if (this.payType1 == this.paytypelist[i].pay_id) {
            if (this.paymentsarray.length != 0) {
              for (var j = 0; j < this.paymentsarray.length; j++) {
                bamt += parseInt(this.paymentsarray[j].amount);
              }
              bamt += parseFloat(this.amountval);
            } else {
              bamt += parseFloat(this.amountval);
            }
  
            if (bamt > this.paid_bill) {
              this.toastr.error('Amount cannot be more than bill amount');
            } else if (this.amountval == 0 || this.amountval < 0) {
              this.toastr.error('Amount cannot be zero or minus');
            } else if (results == true) {
              this.toastr.error('Payment mode already exists');
            } else {
              if (
                this.amountval < parseFloat(this.paid_bill) ||
                this.amountval == parseFloat(this.paid_bill)
              ) {
                if (parseFloat(this.paid_bill) > parseFloat(this.final_amount)) {
                  if (bamt > parseFloat(this.final_amount)) {
                    this.toastr.error('Amount cannot be more than bill amount');
                  } else {
                    this.paymentsarray.push({
                      pay_id: this.paytypelist[i].pay_id,
                      pay_desc: this.paytypelist[i].pay_desc,
                      amount: parseFloat(this.amountval),
                    });
                    this.amountval = parseFloat(this.final_amount) - bamt;
                    this.amountval = parseFloat(this.amountval);
                    this.payType1 = '';
                    console.log("hosp_pay_array",this.hosp_pay_array);
                    if (this.hosp_pay_array != undefined && this.hosp_pay_array.length != 0) {
                      for (var h = 0; h < this.hosp_pay_array.length; h++) {
                        if (this.hosp_pay_array[h].pay_id == this.paytypelist[i].pay_id) {
                          if (parseFloat(this.amountval) >= parseFloat(this.hosp_pay_array[h].amount)) {
                            this.paymentsarray.push({
                              pay_id: this.paytypelist[i].pay_id,
                              pay_desc: this.paytypelist[i].pay_desc,
                              amount: parseFloat(this.amountval),
                            });
                            this.amountval = parseFloat(this.final_amount) - bamt;
                            this.amountval = parseFloat(this.amountval);
                            this.payType1 = '';
                          } else {
                            this.toastr.error('Please check the payment details');
                          }
                        }
                      }
                    } else {
                      this.paymentsarray.push({
                        pay_id: this.paytypelist[i].pay_id,
                        pay_desc: this.paytypelist[i].pay_desc,
                        amount: parseFloat(this.amountval),
                      });
                      this.amountval = parseFloat(this.paid_bill) - bamt;
                      this.amountval = parseFloat(this.amountval);
                      this.payType1 = '';
                    }
                  }
                } else {
                  if (bamt > parseFloat(this.paid_bill)) {
                    this.toastr.error('Amount cannot be more than bill amount');
                  } else {
                    console.log("hosp_pay_array",this.hosp_pay_array);
                    if (this.hosp_pay_array != undefined && this.hosp_pay_array.length != 0) {
                      for (var h = 0; h < this.hosp_pay_array.length; h++) {
                        if (this.hosp_pay_array[h].pay_id == this.paytypelist[i].pay_id) {
                          console.log(parseFloat(this.amountval) +"-----"+ parseFloat(this.hosp_pay_array[h].amount));
                          if (parseFloat(this.amountval) >= parseFloat(this.hosp_pay_array[h].amount)) {
                            this.paymentsarray.push({
                              pay_id: this.paytypelist[i].pay_id,
                              pay_desc: this.paytypelist[i].pay_desc,
                              amount: parseFloat(this.amountval),
                            });
                            this.amountval = parseFloat(this.paid_bill) - bamt;
                            this.amountval = parseFloat(this.amountval);
                            this.payType1 = '';
                          } else {
                            this.toastr.error('Please check the payment details');
                          }
                        }
                      }
                    } else {
                      this.paymentsarray.push({
                        pay_id: this.paytypelist[i].pay_id,
                        pay_desc: this.paytypelist[i].pay_desc,
                        amount: parseFloat(this.amountval),
                      });
                      this.amountval = parseFloat(this.paid_bill) - bamt;
                      this.amountval = parseFloat(this.amountval);
                      this.payType1 = '';
                    }  
                  }
                }
              } else {
                this.amountval = 0;
              }
            }
          }
        }
      } else {
        this.toastr.error('Please select the payment mode');
      }
    } else {
      if (this.payType1 != undefined && this.payType1 != '') {
        for (var i = 0; i < this.paytypelist.length; i++) {
          if (this.payType1 == this.paytypelist[i].pay_id) {
            if (this.paymentsarray.length != 0) {
              for (var j = 0; j < this.paymentsarray.length; j++) {
                bamt += parseInt(this.paymentsarray[j].amount);
              }
              bamt += parseFloat(this.amountval);
            } else {
              bamt += parseFloat(this.amountval);
            }
  
            if (bamt > this.remaing_billbal) {
              this.toastr.error('Amount cannot be more than refund amount');
            } else if (this.amountval == 0 || this.amountval < 0) {
              this.toastr.error('Amount cannot be zero or minus');
            } else if (results == true) {
              this.toastr.error('Payment mode already exists');
            } else {
              if (
                this.amountval < parseFloat(this.remaing_billbal) ||
                this.amountval == parseFloat(this.remaing_billbal)
              ) {
                if (parseFloat(this.remaing_billbal) > parseFloat(this.remaing_billbal)) {
                  if (bamt > parseFloat(this.final_amount)) {
                    this.toastr.error('Amount cannot be more than refund amount');
                  } else {
                    this.paymentsarray.push({
                      pay_id: this.paytypelist[i].pay_id,
                      pay_desc: this.paytypelist[i].pay_desc,
                      amount: parseFloat(this.amountval),
                    });
                    this.amountval = parseFloat(this.final_amount) - bamt;
                    this.amountval = parseFloat(this.amountval);
                    this.payType1 = '';
                  }
                } else {
                  if (bamt > parseFloat(this.remaing_billbal)) {
                    this.toastr.error('Amount cannot be more than bill amount');
                  } else {
                    this.paymentsarray.push({
                      pay_id: this.paytypelist[i].pay_id,
                      pay_desc: this.paytypelist[i].pay_desc,
                      amount: parseFloat(this.amountval),
                    });
                    this.amountval = parseFloat(this.remaing_billbal) - bamt;
                    this.amountval = parseFloat(this.amountval);
                    this.payType1 = '';
                  }
                }
              } else {
                this.amountval = 0;
              }
            }
          }
        }
      } else {
        this.toastr.error('Please select the payment mode');
      }
    }
    this.local_payment_array = this.paymentsarray;
  }

  finekeyInArray_Payment(array, id) {
    var flag = false;
    for (var i = 0; i < array.length; i++) {
      if (array[i].pay_id === id) {
        flag = true;
        break;
      }
    }
    return flag;
  }

  editPayment(data) {
    console.log(JSON.stringify(this.hosp_pay_array));
    this.hosp_pay_array = JSON.parse(JSON.stringify(this.hosp_pay_array));
    if (data != undefined) {
      this.payType1 = data.pay_id;
      this.amountval = parseInt(data.amount);
      for (var i = 0; i < this.paymentsarray.length; i++) {
        if (data.pay_id == this.paymentsarray[i].pay_id) {
          this.paymentsarray.splice(i, 1);
        }
      }
    }   
  }

  deletePayment(data) {
    for (var i = 0; i < this.paymentsarray.length; i++) {
      if (data.pay_id == this.paymentsarray[i].pay_id) {
        this.paymentsarray.splice(i, 1);
      }
    }
  }

  refundcal() {
    if(this.adv_refflag == true && parseInt(this.paid_bill) == 0 && parseInt(this.remaing_billbal) != 0) {
      if(parseFloat(this.remaing_billbal) > 5000) {
        for(var k = 0; k < this.paytypelist.length; k++) {
          if(this.paytypelist[k].pay_desc == "NEFT") {
            this.payType1 = this.paytypelist[k].pay_id;
            if (this.paymentsarray.length == 0) {
              this.amountval = parseFloat(this.remaing_billbal);
            }
            this.bank_details_flag_adv = true;
          }
        }
        if (!this.bank_details_flag_adv) {
          console.log(parseFloat(this.remaing_billbal));
          if (this.paymentsarray.length == 0) {
            this.payType1 = this.paytypelist[0].pay_id;
            this.amountval = parseFloat(this.remaing_billbal);
          }
        }
      } else {
        this.bank_details_flag_adv = false;
        this.payType1 = this.paytypelist[0].pay_id;
        if (this.paymentsarray.length == 0) {
          this.amountval = parseFloat(this.remaing_billbal);
        }
      }
      console.log("bank_details_flag_adv ---",this.bank_details_flag_adv);
    } else {
      this.bank_details_flag_adv = false;
    }
  }

  changePaymentType() { 
    if (this.adv_refflag) {
      for(var i = 0; i < this.paytypelist.length; i++) {
        if(this.payType1 == this.paytypelist[i].pay_id) {
          if(this.paytypelist[i].pay_desc == "NEFT") {
            this.bank_details_flag_adv = true;
          } else {
            this.bank_details_flag_adv = false;
          }
        }
      }
    } else {
      this.bank_details_flag_adv = false;
    }
  }

  advanceRefundCalculation() {
    this.refund_array = []
    if (this.advanceList != undefined && this.advanceList.length != 0) {
      if (parseFloat(this.remaing_billbal) != 0) {
        var refund_total_amount:any = parseFloat(this.remaing_billbal);
        for (var i = 0; i < this.advanceList.length; i++) {
          if (parseFloat(refund_total_amount) != 0) {
            var refund_amount:any = 0, balance:any = 0;
            if (parseFloat(this.advanceList[i].amount) >= parseFloat(refund_total_amount)) {
              refund_amount = parseFloat(refund_total_amount);
              balance = parseFloat(this.advanceList[i].amount) - parseFloat(refund_total_amount);
              refund_total_amount = parseFloat("0.00");
            } else {
              refund_amount = parseFloat(this.advanceList[i].amount);
              balance = parseFloat("0.00");
              refund_total_amount =  parseFloat(refund_total_amount) - parseFloat(this.advanceList[i].amount);
            }
            console.log(parseFloat(refund_amount) + " --- " + parseFloat(balance) + " --- " + parseFloat(refund_total_amount))
            this.refund_array.push({
              advance_id: this.advanceList[i].advance_id,
              refund_amount: refund_amount,
              balance: balance,
              refund_reason: this.refund_reason,
            })
          }        
        }
      }
    }
  }
}
