<div class="row" style="width:100%;margin:0 auto;">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">{{reportHeading}}</h5>
          </div>

          <div style="width: 145px; margin-left: 15px;" *ngIf="reportType == 'HBR' || reportType == 'DBR' || reportType == 'salesGst'">
            <mat-label class="matlabel">Preferrence</mat-label>
            <mat-select class="ipcss" multiple [(ngModel)]="selected_filter"
              (selectionChange)="setTable(selected_filter);">
              <mat-option *ngIf="reportType == 'HBR'" value="doctor">Doctor</mat-option>
              <mat-option *ngIf="reportType == 'HBR'" value="paidon">Paid On</mat-option>
              <mat-option value="discount" *ngIf="reportType != 'salesGst'">Discount</mat-option>
              <mat-option *ngIf="reportType != 'salesGst'" value='ipOp'>Ip / Op</mat-option>
              <mat-option *ngIf="reportType != 'salesGst'" value='advance'>Advance</mat-option>
              <mat-option *ngIf="reportType != 'salesGst'" value="paid">Paid</mat-option>
              <mat-option *ngIf="reportType != 'salesGst'" value="cash">Cash</mat-option>
              <mat-option *ngIf="reportType != 'salesGst'" value="upi">UPI</mat-option>
              <mat-option *ngIf="reportType != 'salesGst'" value="card">Card</mat-option>
              <mat-option *ngIf="reportType != 'salesGst'" value="insurance">Insurance</mat-option>
              <mat-option *ngIf="reportType != 'salesGst'" value="due">Due/Credit</mat-option>
              <mat-option value="Disc" *ngIf="reportType == 'salesGst'">Disc</mat-option>
              <mat-option value="Exempt" *ngIf="reportType == 'salesGst'">Exempt</mat-option>
              <mat-option value="FiGST" *ngIf="reportType == 'salesGst'">5% GST</mat-option>
              <mat-option value="TWCGST" *ngIf="reportType == 'salesGst'">2.5% CGST</mat-option>
              <mat-option value="TWSGST" *ngIf="reportType == 'salesGst'">2.5% SGST</mat-option>
              <mat-option value="TWEGST" *ngIf="reportType == 'salesGst'">12% GST</mat-option>
              <mat-option value="SICGST" *ngIf="reportType == 'salesGst'">6% CGST</mat-option>
              <mat-option value="SISGST" *ngIf="reportType == 'salesGst'">6% SGST</mat-option>
              <mat-option value="EIGst" *ngIf="reportType == 'salesGst'">18% GST</mat-option>
              <mat-option value="NICgst" *ngIf="reportType == 'salesGst'">9% CGST</mat-option>
              <mat-option value="NISgst" *ngIf="reportType == 'salesGst'">9% SGST</mat-option>
              <mat-option value="TWGst" *ngIf="reportType == 'salesGst'">28% GST</mat-option>
              <mat-option value="FOcgst" *ngIf="reportType == 'salesGst'">14% CGST</mat-option>
              <mat-option value="FOsgst" *ngIf="reportType == 'salesGst'">14% SGST</mat-option>
             
            </mat-select>
          </div>

          <div class="headerButtons">
            <mat-label class="matlabel"><br>
              <a style="margin:0 5px;" (click)="print_area()"><img src="../../../assets/img/printer.svg"
                  class="backimgbtn_inpatinfo" style="width: 25px; height: 25px;" *ngIf="backbutton" /></a>
              <img src="../../../assets/ui_icons/buttons/XL_export.svg" class="saveimgbtn_inpatinfo"
                (click)="getExcelData('excel')" style="margin-right:10px" *ngIf="backbutton" />
            </mat-label>
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="cover_div_header" style="padding-bottom: 13px;" >
          <!-- *ngIf="forcoverdiv" -->
          <div class="content_cover">
            <div class="row">
              <div *ngIf="scheduleFlag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Schedule type<br>
                  <select disableOptionCentering class="ipcss " [(ngModel)]="scheduleType">
                    <option value="All">All</option>
                    <option *ngFor="let schedule of scheduleList" value={{schedule.schedule_type_id}}>
                      {{schedule.category}}</option>
                  </select>
                </mat-label>
              </div>


              <div *ngIf="srd_flag" class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 select_bottom">
                <mat-label class="matlabel">Filter by<br>
                  <select [(ngModel)]="Filter_by_Id" class="ipcss" (change)="filterby()">
                    <option value="Doctor" selected>Doctor</option>
                    <option value="Speciality">Speciality</option>
                    <option value="Service">Charge</option>
                  </select>
                </mat-label>
              </div>

              <!-- Diagnosis Bill Reports - Filter by -->
              <div *ngIf="DBR_flag" class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 select_bottom">
                <mat-label class="matlabel">Filter by<br>
                  <select [(ngModel)]="Filter_by_dbr" class="ipcss" (change)="dbr_filterby()">
                    <option value="Doctor" selected>Doctor</option>
                    <option value="Department">Department</option>
                    <option value="Ward">Ward</option>
                    <option value="Test">Test</option>
                  </select>
                </mat-label>
              </div>

              <div *ngIf="DBR_dept_flag" class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 select_bottom">
                <mat-label class="matlabel">Department<br>
                  <select disableOptionCentering class="ipcss " [(ngModel)]="diag_dept"
                    (change)="getTestCategory(diag_dept, '0')">

                    <option *ngFor="let dept of department_data" required value={{dept.diag_department_id}}>
                      {{dept.diag_dept_desc}}</option>
                  </select>
                </mat-label>
              </div>

              <div *ngIf="DBR_dept_flag" class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 select_bottom">
                <mat-label class="matlabel">Sub department<br>
                  <select disableOptionCentering class="ipcss " [(ngModel)]="diag_test_categ">
                    <option value='All'> All</option>
                    <option *ngFor="let testcat of test_category_data" required value={{testcat.diag_test_cat_id}}>
                      {{testcat.description}}</option>
                  </select>
                </mat-label>
              </div>

              <!----addded for Bed Occupancy start-------->
              <div *ngIf="bed_occupancyflag" class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 select_bottom">
                <mat-label class="matlabel">Filter by<br>
                  <select [(ngModel)]="bed_occupancyfilter" class="ipcss" (change)="wardbedfilterby()">
                    <option value="ward" selected>Ward</option>
                    <option value="bed">Bed</option>
                  </select>
                </mat-label>
              </div>
              <!--added for Bed Occupancy end--->

              <!-----------Product wise start---------------------->
              <div *ngIf="productflag" class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 select_bottom">
                <mat-label class="matlabel">Filter by<br>
                  <select [(ngModel)]="productfilter" class="ipcss" (change)="productfilterby()">
                    <option value="product" selected>Product </option>
                    <option value="product Type">Product Type</option>
                  </select>
                </mat-label>
              </div>

              <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2" *ngIf="product_medicine_name_flag">
                <mat-label class="matlabel">Product Type<br>
                  <input type="text" class="ipcss" maxlength="50" (keyup)="medicineData_name(product_medicine_name)"
                    [(ngModel)]="product_medicine_name" matInput [matAutocomplete]="auto1" />
                  <mat-autocomplete #auto1="matAutocomplete" [panelWidth]="'auto'">
                    <mat-option (click)="select_refer_medicine(item)" *ngFor="let item of ReferedmedicineArray"
                      value="{{item.med_code}}">
                      {{item.med_name}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-label>
              </div>

              <!-----------------Product wise end---------------->
              <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 select_bottom" *ngIf="doctorFlag">
                <mat-label class="matlabel" [(ngModel)]="fieldtype"> {{ fieldtype }} <br>
                  <select [(ngModel)]="doctorId" class="ipcss" (change)="selectedText()">
                    <option *ngFor="let data of doctorList" value={{data.id}}>{{data.name}}</option>
                  </select>
                </mat-label>
              </div>

              <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 select_bottom" *ngIf="surgdoctorFlag">
                <mat-label class="matlabel" [(ngModel)]="fieldtype"> {{ fieldtype }} <br>
                  <select [(ngModel)]="doctorId" class="ipcss" (change)="selectedText()">
                    <option *ngFor="let data of doctorList" value={{data.id}}>{{data.name}}</option>
                  </select>
                </mat-label>
              </div>

              <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2 select_bottom" *ngIf="doctorFlag">
                <mat-label class="matlabel">Patient type<br>
                  <select [(ngModel)]="pattype" class="ipcss" (change)="setGroupbyFlag(pattype)">
                    <option value="All">All</option>
                    <option value="out-pat">OP</option>
                    <option value="in-pat">IP</option>
                  </select>
                </mat-label>
              </div>

              <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2 select_bottom" *ngIf="pattyperepoFlag">
                <mat-label class="matlabel">Patient type<br>
                  <select [(ngModel)]="pattyperepo" class="ipcss">
                    <option value="All">All</option>
                    <option value="out-pat">OP</option>
                    <option value="in-pat">IP</option>
                  </select>
                </mat-label>
              </div>

              <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 select_bottom" *ngIf="userFlag">
                <mat-label class="matlabel"> User <br>
                  <select [(ngModel)]="userId" class="ipcss" (change)="selectedText()">
                    <option value="All">All</option>
                    <option *ngFor="let data of userList" value={{data.id}}>{{data.name}}</option>
                  </select>
                </mat-label>
              </div>

              <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2 select_bottom">
                <mat-label class="matlabel">Duration<br>
                  <select [(ngModel)]="filtduration" class="ipcss">
                    <option value="select">Select</option>
                    <option value="7">1 week</option>
                    <option value="30">1 month</option>
                    <option value="91">3 months</option>
                    <option value="183">6 months</option>
                    <option value="365">1 year</option>
                  </select>
                </mat-label>
              </div>

              <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2">
                <mat-label class="matlabel" style="margin-top: 7px;">From
                  <input type="date" class="ipcss_date " (change)="selectDate(fromDate,'1')" [(ngModel)]="fromDate"
                    max="{{currentDate}}" #matInput style="width: 140px;">
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2">
                <mat-label class="matlabel" style="margin-top: 7px;">To
                  <input type="date" class="ipcss_date " (change)="selectDate(toDate,'2')" [(ngModel)]="toDate"
                    #matInput style="width: 140px;">
                </mat-label>
              </div>
              <div *ngIf="srd_flag && grp_by_flag" class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 select_bottom">
                <mat-label class="matlabel">Group by<br>
                  <select [(ngModel)]="group_by" class="ipcss">
                    <option value="all" selected>All</option>
                    <option value="new">Consultation</option>
                    <option value="followup">Follow up</option>
                  </select>
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2">
                <mat-label class="matlabel"><br>
                  <img src="../../../assets/ui_icons/buttons/search_button.svg" class="saveimgbtn_inpatinfo"
                    (click)="getSalesData()" />
                </mat-label>
              </div>
            </div>
          </div>
        </div>

        <div
          style="box-shadow: 0 0 2px 0 ;
        background: inherit;
        backdrop-filter: blur(10px);border: 1px solid #66b9eb;border-radius: 5px;padding: 1px 20px 5px 20px;margin-top:5px"
          *ngIf="amountcard">

          <div style="margin-top: 5px;" class="row">
            <div class="col-12 col-sm-6 col-md-4 col-lg-12 col-xl-12 my-auto d-flex justify-content-end"
              *ngIf="reportType == 'salesGst'">
              <mat-label class="matlabel" style="margin-top: 7px;" *ngIf="totalAmount == 'NaN' ? '0.00' : totalAmount"
                #printsalesamountgst id="printsalesamountgst"><span style="font-weight: 600;">Sales Amount :
                </span> {{totalAmount}}
              </mat-label>
              <mat-label [hidden]="true" class="matlabel" style="margin-top: 7px;"
                *ngIf="returntotalamount == 'NaN' ? '0.00' : returntotalamount" #printsalesreturns
                id="printsalesreturns"><span style="font-weight: 600;">Return Amount :
                </span> {{returntotalamount}}
              </mat-label>
              <mat-label class="matlabel" [hidden]="true" style="margin-top: 7px;"
                *ngIf="finalamount == 'NaN' ? '0.00' : finalamount" #printsalesfinal id="printsalesfinal"><span
                  style="font-weight: 600;">Final Amount :
                </span> {{finalamount}}
              </mat-label>
            </div>

            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 my-auto" *ngIf="reportType == 'SDI'">
              <mat-label class="matlabel" style="margin-top: 7px;" *ngIf="totalAmount == 'NaN' ? '0.00' : totalAmount"
                #printsalesamountgst id="printsalesamountgst"><span style="font-weight: 600;">Sales Amount :
                </span> {{totalAmount}}
              </mat-label>
            </div>

            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 my-auto"
              *ngIf="reportType == 'HBR' || reportType == 'DBR' || reportType == 'CHBR'">
              <mat-label class="matlabel" style="margin-top: 7px;" *ngIf="totalAmount == 'NaN' ? '0.00' : totalAmount"
                #totalamount id="totalamount"><span style="font-weight: 600;">Total : </span> {{totalAmount}}
              </mat-label>
            </div>

            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 my-auto"
              *ngIf="reportType == 'HBR' || reportType == 'DBR' || reportType == 'CHBR'">


              <mat-label class="matlabel" style="margin-top: 7px;"
                *ngIf="DiscountAmount == 'NaN' ? '0.00' : DiscountAmount" #discountamount id="discountamount"><span
                  style="font-weight: 600;">Discount : </span> {{DiscountAmount}}
              </mat-label>
            </div>

            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 my-auto"
              *ngIf="reportType == 'HBR'|| reportType == 'DBR'||  reportType == 'SDI' || reportType == 'CHBR'">
              <mat-label class="matlabel" style="margin-top: 7px;" *ngIf="cashAmount == 'NaN' ? '0.00' : cashAmount"
                #cashamount id="cashamount"><span style="font-weight: 600;">Cash : </span>
                {{cashAmount}}
              </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 my-auto"
              *ngIf="reportType == 'HBR'|| reportType == 'DBR'|| reportType == 'SDI' || reportType == 'CHBR'">
              <mat-label class="matlabel" style="margin-top: 7px;" *ngIf="cardAmount == 'NaN' ? '0.00' : cardAmount"
                #cardamount id="cardamount"><span style="font-weight: 600;">Card : </span>
                {{cardAmount}}
              </mat-label>
            </div>

            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 my-auto"
              *ngIf="reportType == 'HBR' || reportType == 'DBR'|| reportType == 'SDI' || reportType == 'CHBR'">
              <mat-label class="matlabel" style="margin-top: 7px;" *ngIf="insureAmount == 'NaN' ? '0.00' : insureAmount"
                #insureamount id="insureamount"><span style="font-weight: 600;">Insurance :
                </span> {{insureAmount}}
              </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 my-auto"
              *ngIf="reportType == 'HBR' || reportType == 'DBR'||  reportType == 'SDI' || reportType == 'CHBR'">
              <mat-label class="matlabel" style="margin-top: 7px;" *ngIf="upiAmount == 'NaN' ? '0.00' : upiAmount"
                #upiamount id="upiamount"><span style="font-weight: 600;">UPI:</span>
                {{upiAmount}}
              </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 my-auto"
              *ngIf="reportType == 'HBR' || reportType == 'DBR'||  reportType == 'SDI' || reportType == 'CHBR'">
              <mat-label class="matlabel" style="margin-top: 7px;" *ngIf="creditAmount == 'NaN' ? '0.00' : creditAmount"
                #creditamount id="creditamount"><span style="font-weight: 600;">Credit:</span>
                {{creditAmount}}
              </mat-label>
            </div>
          </div>
        </div>

        <p class="nodata" *ngIf="salesList.length == 0 &&  reportType != 'CR'">No records found</p>
        <p class="nodata" *ngIf="reportType == 'CR' && !consoldatedreport">No records found</p>
        <div [hidden]="true">
          <table style="margin-left:10px;margin-right: 10px;" #printimage id="printimage">
            <tr>
              <td>
                <img alt="image" src={{hospital_logo}} style="width: 95%; height: 130px;">
              </td>
            </tr>
          </table>
          <div style="position: relative;top: 20px;left: 10px;text-align: left;" #hospitalwithnamelogo
            id="hospitalwithnamelogo">
            <span><b>{{hosp_name}}</b>,</span><br />
            <span *ngIf="hosp_addr != ''">{{hosp_addr}}</span><br />
            <span>{{hosp_csz}}</span><br />
            <span *ngIf="hosp_tel != undefined"><img src="../../../assets/img/phone.png" width="15px"
                height="15px" />{{hosp_tel}}.</span><br>
          </div>
        </div>

        <div class="table-responsive cover_div"
          [ngStyle]="{'width': applystyle ? '100%' : '50%','height': applyheight ? '65vh':'65vh'}"
          *ngIf="salesList.length != 0 && reportType != 'CR'">

          <div style="width: 106%; margin-left: -25px;" *ngIf="reportType == 'salesGst'" id="headingThree">
            <div style="height: 70vh; overflow-y: scroll; width: 110%;margin-left: 10px !important;">
              <!-- margin-left: -25px; -->
              <table style="font-size: 12px;margin-left: 13px !important;" class="headerfixed">
                <thead>
                  <tr>
                    <th >S.no</th>
                    <th>Order no</th>
                    <th style="width: 120px !important;">Order date</th>
                    <!-- <th>Name </th> -->
                    <th>Bill amount</th>
                    <th *ngIf="columns.Disc">Disc</th>
                    <th *ngIf="columns.Exempt">Exempt</th>
                    <th style="width: 75px !important;" *ngIf="columns.FiGST">5% GST</th>
                    <th style="width: 75px !important;" *ngIf="columns.TWCGST">2.5% CGST</th>
                    <th style="width: 75px !important;" *ngIf="columns.TWSGST">2.5% SGST</th>
                    <th style="width: 75px !important;" *ngIf="columns.TWEGST">12% GST</th>
                    <th style="width: 75px !important;" *ngIf="columns.SISGST">6% CGST</th>
                    <th style="width: 75px !important;" *ngIf="columns.SICGST">6% SGST</th>
                    <th style="width: 75px !important;" *ngIf="columns.EIGst">18% GST</th>
                    <th style="width: 75px !important;" *ngIf="columns.NICgst">9% CGST</th>
                    <th style="width: 75px !important;" *ngIf="columns.NISgst">9% SGST</th>
                    <th style="width: 75px !important;" *ngIf="columns.TWGst">28% GST</th>
                    <th style="width: 75px !important;" *ngIf="columns.FOcgst">14% CGST</th>
                    <th style="width: 75px !important;" *ngIf="columns.FOsgst">14% SGST</th>
                    <th>CGST</th>
                    <th>SGST</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let sale of salesList">
                    <td>{{sale.sno}}</td>
                    <td>{{sale.order_id}}</td>
                    <td>{{sale.order_date}} {{sale.order_time}}</td>
                    <!-- <td>{{sale.name}}</td> -->
                    <td>{{sale.bill_amount}}</td>
                    <td *ngIf="columns.Disc">{{sale.bill_disc}}</td>
                    <td *ngIf="columns.Exempt">{{sale.Exempt}}</td>
                    <td *ngIf="columns.FiGST">{{sale.five_gst}}</td>
                    <td *ngIf="columns.TWCGST">{{sale.two_cgst}}</td>
                    <td *ngIf="columns.TWSGST">{{sale.two_sgst}}</td>
                    <td *ngIf="columns.TWEGST">{{sale.twelve_gst}}</td>
                    <td *ngIf="columns.SISGST">{{sale.six_cgst}}</td>
                    <td *ngIf="columns.SICGST">{{sale.six_sgst}}</td>
                    <td *ngIf="columns.EIGst">{{sale.eighteen_gst}}</td>
                    <td *ngIf="columns.NICgst">{{sale.nine_cgst}}</td>
                    <td *ngIf="columns.NISgst">{{sale.nine_sgst}}</td>
                    <td *ngIf="columns.TWGst">{{sale.twenty_gst}}</td>
                    <td *ngIf="columns.FOcgst">{{sale.fourteen_cgst}}</td>
                    <td *ngIf="columns.FOsgst">{{sale.fourteen_sgst}}</td>
                    <td >{{sale.TotalCGST}}</td>
                    <td >{{sale.TotalSGST}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div [hidden]="true" *ngIf="reportType == 'salesGst'">
            <table #printsalesGST id="printsalesGST" style="font-size: 12px;" class="headerfixed">
              <thead>
                <tr>
                  <th >S.no</th>
                  <th>Order no</th>
                  <th style="width: 120px !important;">Order date</th>
                  <!-- <th>Name </th> -->
                  <th>Bill amount</th>
                  <th *ngIf="columns.Disc">Disc</th>
                  <th *ngIf="columns.Exempt">Exempt</th>
                  <th style="width: 75px !important;" *ngIf="columns.FiGST">5% GST</th>
                  <th style="width: 75px !important;" *ngIf="columns.TWCGST">2.5% CGST</th>
                  <th style="width: 75px !important;" *ngIf="columns.TWSGST">2.5% SGST</th>
                  <th style="width: 75px !important;" *ngIf="columns.TWEGST">12% GST</th>
                  <th style="width: 75px !important;" *ngIf="columns.SISGST">6% CGST</th>
                  <th style="width: 75px !important;" *ngIf="columns.SICGST">6% SGST</th>
                  <th style="width: 75px !important;" *ngIf="columns.EIGst">18% GST</th>
                  <th style="width: 75px !important;" *ngIf="columns.NICgst">9% CGST</th>
                  <th style="width: 75px !important;" *ngIf="columns.NISgst">9% SGST</th>
                  <th style="width: 75px !important;" *ngIf="columns.TWGst">28% GST</th>
                  <th style="width: 75px !important;" *ngIf="columns.FOcgst">14% CGST</th>
                  <th style="width: 75px !important;" *ngIf="columns.FOsgst">14% SGST</th>
                  <th>CGST</th>
                  <th>SGST</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let sale of salesList">
                  <td>{{sale.sno}}</td>
                  <td>{{sale.order_id}}</td>
                  <td>{{sale.order_date}} {{sale.order_time}}</td>
                  <!-- <td>{{sale.name}}</td> -->
                  <td>{{sale.bill_amount}}</td>
                  <td *ngIf="columns.Disc">{{sale.bill_disc}}</td>
                  <td *ngIf="columns.Exempt">{{sale.Exempt}}</td>
                  <td *ngIf="columns.FiGST">{{sale.five_gst}}</td>
                  <td *ngIf="columns.TWCGST">{{sale.two_cgst}}</td>
                  <td *ngIf="columns.TWSGST">{{sale.two_sgst}}</td>
                  <td *ngIf="columns.TWEGST">{{sale.twelve_gst}}</td>
                  <td *ngIf="columns.SISGST">{{sale.six_cgst}}</td>
                  <td *ngIf="columns.SICGST">{{sale.six_sgst}}</td>
                  <td *ngIf="columns.EIGst">{{sale.eighteen_gst}}</td>
                  <td *ngIf="columns.NICgst">{{sale.nine_cgst}}</td>
                  <td *ngIf="columns.NISgst">{{sale.nine_sgst}}</td>
                  <td *ngIf="columns.TWGst">{{sale.twenty_gst}}</td>
                  <td *ngIf="columns.FOcgst">{{sale.fourteen_cgst}}</td>
                  <td *ngIf="columns.FOsgst">{{sale.fourteen_sgst}}</td>
                  <td >{{sale.TotalCGST}}</td>
                  <td >{{sale.TotalSGST}}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <table #printStockRec *ngIf="reportType == 'stockReceivable'" id="printStockRec"
            style="font-size: 12px; width: 130%;">
            <thead>
              <tr>
                <th>S.no</th>
                <th style="width:150px">Invoice no</th>
                <th style="width:150px">Product Name</th>
                <th style="width:100px">Batch No</th>
                <th>Qty Received Date</th>
                <th>Total Qty</th>
                <th>MRP Value</th>
                <th>MRP</th>
                <th>CGST/SGST</th>
                <th>CGST/SGST Price</th>
                <th>SGST Value</th>
                <th>Total SGST</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let sales of salesList">
                <td>{{sales.sno}}</td>
                <td>{{sales.invoice_no}}</td>
                <td class="align_left" style="width:150px">{{sales.product_name}}</td>
                <td>{{sales.batch_no}}</td>
                <td>{{sales.qty_received_date}}</td>
                <td>{{sales.total_qty}}</td>
                <td>{{sales.mrp_value}}</td>
                <td>{{sales.mrp}}</td>
                <td>{{sales.cgst}}</td>
                <td>{{sales.sgst_price}}</td>
                <td>{{sales.sgst_value}}</td>
                <td>{{sales.total_gst}}</td>
              </tr>
            </tbody>
          </table>

          <table #printgsd *ngIf="reportType == 'gsd'" id="printgsd" style="font-size: 12px; width: 140%;">
            <thead>
              <tr>
                <th>S.no</th>
                <th>Order No</th>
                <th>Order Date</th>
                <th>Product Name</th>
                <th style="width:150px;text-align: left">Doctor Name</th>
                <th style="width:150px;text-align: left">Patient Name</th>
                <th>Batch No</th>
                <th style="text-align: left;">Supplier/Manufacturer</th>
                <th>Schedule Type</th>
                <th>Quantity Sold</th>
                <th>Expiry Date</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let sales of salesList">
                <td>{{sales.sno}}</td>
                <td>{{sales.order_id}}</td>
                <td>{{sales.order_date}}</td>
                <td class="align_left">{{sales.product_name}}</td>
                <td class="align_left" style="width:150px">{{sales.doctor_name}}</td>
                <td class="align_left" style="width:150px">{{sales.delivered_person}}</td>
                <td>{{sales.batch_no}}</td>
                <td style="text-align: left;">{{sales.supplier}}</td>
                <td>{{sales.category}}</td>
                <td>{{sales.qty_sold}}</td>
                <td>{{sales.expiry_date}}</td>
              </tr>
            </tbody>
          </table>

          <table #printSDI *ngIf="reportType == 'SDI'" id="prinSDI" style="font-size: 12px; width: 110%;">
            <thead>
              <tr>
                <th>S.no</th>
                <th>Order No</th>
                <th>Order Date</th>
                <th style="width: 150px;">Product Name</th>
                <th>Batch No</th>
                <th style="width: 180px;text-align:left">Manufacturer Name</th>
                <th>Qty Sold</th>
                <th>Reminding Qty</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let sales of salesList">
                <td>{{sales.sno}}</td>
                <td>{{sales.order_id}}</td>
                <td>{{sales.order_date}}</td>
                <td class="align_left">{{sales.product_name}}</td>
                <td>{{sales.batch_no}}</td>
                <td style="width: 180px;text-align:left">{{sales.mfg_name}}</td>
                <td>{{sales.sold_qty}}</td>
                <td>{{sales.qty_hand}}</td>
                <td>{{sales.amount}}</td>
              </tr>
            </tbody>
          </table>

          <div *ngIf="reportType == 'HBR' && userdataList.length !=0" class="container-fluid">
            <div class="row">
              <div class="col-12">
                <div style="width: 104%; margin-left: -25px;">
                  <mat-accordion displayMode="flat" multi="false" class="mat-table">
                    <section matSort class="mat-elevation-z2 mat-header-row" style="background-color: #c1e6fb; position: sticky; top: 0px;
                    z-index: 2;">
                      <span class="mat-header-cell">S.No</span>
                      <span class="mat-header-cell">USER NAME</span>
                      <span class="mat-header-cell">BILL</span>
                      <span class="mat-header-cell">CASH</span>
                      <span class="mat-header-cell">CARD</span>
                      <span class="mat-header-cell">UPI</span>
                      <span class="mat-header-cell">CHEQUE</span>
                      <span class="mat-header-cell">INSURANCE</span>
                      <span class="mat-header-cell">DUE/CREDIT</span>
                    </section>


                    <mat-expansion-panel *ngFor="let user of userdataList">
                      <mat-expansion-panel-header class="mat-row" (click)="getUserbasedDetails(user)">
                        <span class="mat-cell">{{user.sno}}</span>
                        <span class="mat-cell">{{user.username}}</span>
                        <span class="mat-cell">{{user.bill}}</span>
                        <span class="mat-cell">{{user.cash}}</span>
                        <span class="mat-cell">{{user.card}}</span>
                        <span class="mat-cell">{{user.upi}}</span>
                        <span class="mat-cell">{{user.cheque}}</span>
                        <span class="mat-cell">{{user.insurance}}</span>
                        <span class="mat-cell">{{user.balance}}</span>

                      </mat-expansion-panel-header>
                      <div style="height: 50vh; overflow-y: scroll; width: 104%; margin-left: -25px;">
                        <table style="font-size: 12px;" class="headerfixed" *ngIf="pattype != 'All'">
                          <thead>
                            <tr>
                              <th>S.no</th>
                              <th style="width:75px">Bill No</th>
                              <!-- <th style="width:125px">Created By</th> -->

                              <th style="width:125px;text-align: left;">Bill Date</th>
                              <th *ngIf="columns.paidon" style="width:115px;text-align: left;">Paid On</th>
                              <th>MR number</th>
                              <th>Patient Name</th>

                              <th *ngIf=" this.Filter_by_Id == 'Doctor' && columns.doctor">Doctor</th>
                              <th *ngIf="this.Filter_by_Id == 'Speciality' && columns.doctor">Speciality</th>
                              <th *ngIf="this.Filter_by_Id == 'Service' && columns.doctor">Charge</th>
                              <th>Bill Amt</th>
                              <th *ngIf="columns.discount">Discount</th>


                              <th *ngIf="columns.paid">Paid</th>
                              <th *ngIf="columns.cash">Cash</th>
                              <th *ngIf="columns.card">Card</th>
                              <th *ngIf="columns.upi">UPI</th>
                              <th *ngIf="columns.insurance">Insurance</th>
                              <th *ngIf="columns.due">Due/Credit</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>


                            <tr *ngFor="let sales of filteredSaleslistArray">
                              <td>{{sales.sno}}</td>
                              <td style="width:75px">{{sales.billing_id}}</td>
                              <!-- <td class="align_left" style="width:150px">{{sales.user_name}}</td> -->
                              <td style="width:125px;text-align: left;">{{sales.created_date}} {{sales.created_time}}
                              </td>
                              <td style="width:115px;text-align: left;">{{sales.pay_date}} {{sales.pay_time}}</td>
                              <td>{{sales.mr_no}}</td>
                              <td class="align_left">{{sales.patient_name}}</td>
                              <td *ngIf="columns.doctor" class="align_left">{{sales.doc_name}}</td>
                              <td>{{sales.bill_amount}}</td>
                              <td *ngIf="columns.discount">{{sales.discount}}</td>


                              <td *ngIf="columns.paid">{{sales.paid_amount}}</td>
                              <td *ngIf="columns.cash">{{sales.cash}}</td>
                              <td *ngIf="columns.card">{{sales.card}}</td>
                              <td *ngIf="columns.upi">{{sales.upi}}</td>
                              <td *ngIf="columns.insurance">{{sales.insurance}}</td>
                              <td *ngIf="columns.due">{{sales.balance}}</td>
                              <td>{{sales.paid}}</td>
                            </tr>
                          </tbody>
                        </table>

                        <table style="font-size: 12px;" class="headerfixed" *ngIf="pattype == 'All'">
                          <thead>
                            <tr>
                              <th>S.no</th>
                              <th style="width:75px">Bill No</th>
                              <!-- <th style="width:125px">Created By</th> -->

                              <th style="width:125px;text-align: left;">Bill Date</th>
                              <th *ngIf="columns.paidon" style="width:115px;text-align: left;">Paid on</th>
                              <th>MR NO</th>
                              <th>Patient Name</th>
                              <th>Patient Type</th>
                              <th *ngIf=" this.Filter_by_Id == 'Doctor' && columns.doctor">Doctor</th>
                              <th *ngIf="this.Filter_by_Id == 'Speciality' && columns.doctor">Speciality</th>
                              <th *ngIf="this.Filter_by_Id == 'Service' && columns.doctor">Charge</th>
                              <th>Bill Amt</th>
                              <th *ngIf="columns.discount">Discount</th>


                              <th *ngIf="columns.paid">Paid</th>
                              <th *ngIf="columns.cash">Cash</th>
                              <th *ngIf="columns.card">Card</th>
                              <th *ngIf="columns.upi">UPI</th>
                              <th *ngIf="columns.insurance">Insurance</th>
                              <th *ngIf="columns.due">Due/Credit</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>


                            <tr *ngFor="let sales of op_SalestlistArr">
                              <td>{{sales.sno}}</td>
                              <td style="width:75px">{{sales.billing_id}}</td>
                              <!-- <td class="align_left" style="width:150px">{{sales.user_name}}</td> -->
                              <td style="width:125px;text-align: left;">{{sales.created_date}} {{sales.created_time}}
                              </td>
                              <td *ngIf="columns.paidon" style="width:115px;text-align: left;">{{sales.pay_date}}
                                {{sales.pay_time}}</td>
                              <td>{{sales.mr_no}}</td>
                              <td class="align_left">{{sales.patient_name}}</td>
                              <td class="align_left">{{sales.patient_type}}</td>
                              <td *ngIf="columns.doctor" class="align_left">{{sales.doc_name}}</td>
                              <td>{{sales.bill_amount}}</td>
                              <td *ngIf="columns.discount">{{sales.discount}}</td>


                              <td *ngIf="columns.paid">{{sales.paid_amount}}</td>
                              <td *ngIf="columns.cash">{{sales.cash}}</td>
                              <td *ngIf="columns.card">{{sales.card}}</td>
                              <td *ngIf="columns.upi">{{sales.upi}}</td>
                              <td *ngIf="columns.insurance">{{sales.insurance}}</td>
                              <td *ngIf="columns.due">{{sales.balance}}</td>
                              <td>{{sales.paid}}</td>
                            </tr>

                            <tr *ngFor="let sales of ip_SalestlistArr">
                              <td>{{sales.sno}}</td>
                              <td style="width:75px">{{sales.billing_id}}</td>
                              <!-- <td class="align_left" style="width:150px">{{sales.user_name}}</td> -->
                              <td style="width:125px;text-align: left;">{{sales.created_date}} {{sales.created_time}}
                              </td>
                              <td *ngIf="columns.paidon" style="width:125px;text-align: left;">{{sales.pay_date}}
                                {{sales.pay_time}}</td>
                              <td>{{sales.mr_no}}</td>
                              <td class="align_left">{{sales.patient_name}}</td>
                              <td class="align_left">{{sales.patient_type}}</td>
                              <td *ngIf="columns.doctor" class="align_left">{{sales.doc_name}}</td>
                              <td>{{sales.bill_amount}}</td>
                              <td *ngIf="columns.discount">{{sales.discount}}</td>


                              <td *ngIf="columns.paid">{{sales.paid_amount}}</td>
                              <td *ngIf="columns.cash">{{sales.cash}}</td>
                              <td *ngIf="columns.card">{{sales.card}}</td>
                              <td *ngIf="columns.upi">{{sales.upi}}</td>
                              <td *ngIf="columns.insurance">{{sales.insurance}}</td>
                              <td *ngIf="columns.due">{{sales.balance}}</td>
                              <td>{{sales.paid}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </mat-expansion-panel>
                  </mat-accordion>
                </div>
              </div>
            </div>
          </div>

          <div #printHBR [hidden]="true" *ngIf="reportType == 'HBR'" id="printHBR">
            <div *ngIf="pattype != 'out-pat'">
              <table style="font-size: 12px; width: 95%; margin-top: 10px !important;">
                <thead>
                  <tr>
                    <th style="border: 1px solid #eeeeee;border-collapse: collapse;">S.no</th>
                    <th style="width:42px; border: 1px solid #eeeeee;border-collapse: collapse;">Bill No</th>
                    <!-- <th style="width:125px">Created By</th> -->


                    <th style="width:80px;text-align: left; border: 1px solid #eeeeee;border-collapse: collapse;">

                      Bill Date</th>
                    <th *ngIf="columns.paidon"
                      style="width:80px;text-align: left; border: 1px solid #eeeeee;border-collapse: collapse;">
                      Paid On</th>
                    <th style="width:70px; border: 1px solid #eeeeee;border-collapse: collapse;">MR NO</th>
                    <th style="width:90px; border: 1px solid #eeeeee;border-collapse: collapse;">Paitent Name</th>


                    <th style="width:90px; border: 1px solid #eeeeee;border-collapse: collapse;"
                      *ngIf=" this.Filter_by_Id == 'Doctor' && columns.doctor">Doctor</th>
                    <th style="width:90px; border: 1px solid #eeeeee;border-collapse: collapse;"
                      *ngIf="this.Filter_by_Id == 'Speciality'">Speciality</th>
                    <th style="width:90px; border: 1px solid #eeeeee;border-collapse: collapse;"
                      *ngIf="this.Filter_by_Id == 'Service'">Charge</th>
                    <th style="border: 1px solid #eeeeee;border-collapse: collapse;">Bill Amt</th>

                    <th *ngIf="columns.discount" style="border: 1px solid #eeeeee;border-collapse: collapse;">Discount
                    </th>
                    <th *ngIf="columns.paid" style="border: 1px solid #eeeeee;border-collapse: collapse;">Paid</th>
                    <th *ngIf="columns.cash" style="border: 1px solid #eeeeee;border-collapse: collapse;">Cash</th>
                    <th *ngIf="columns.card" style="border: 1px solid #eeeeee;border-collapse: collapse;">Card</th>
                    <th *ngIf="columns.upi" style="border: 1px solid #eeeeee;border-collapse: collapse;">UPI</th>


                    <th *ngIf="columns.insurance" style="border: 1px solid #eeeeee;border-collapse: collapse;">Insurance
                    </th>
                    <th *ngIf="columns.due" style="border: 1px solid #eeeeee;border-collapse: collapse;">Due/Credit</th>
                    <th style="border: 1px solid #eeeeee;border-collapse: collapse;">Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let sales of salesList">
                    <td style="border: 1px solid #eeeeee;border-collapse: collapse;">{{sales.sno}}</td>
                    <td style=" border: 1px solid #eeeeee;border-collapse: collapse;">{{sales.billing_id}}</td>
                    <!-- <td class="align_left" style="width:150px">{{sales.user_name}}</td> -->


                    <td style="text-align: left; border: 1px solid #eeeeee;border-collapse: collapse;">
                      {{sales.created_date}} {{sales.created_time}}</td>
                    <td style="text-align: left; border: 1px solid #eeeeee;border-collapse: collapse;">
                      {{sales.pay_date}} {{sales.pay_time}}</td>
                    <td style="border: 1px solid #eeeeee;border-collapse: collapse;" class="align_left">{{sales.mr_no}}
                    </td>
                    <td style="border: 1px solid #eeeeee;border-collapse: collapse;" class="align_left">
                      {{sales.patient_name}}</td>

                    <td *ngIf="columns.doctor" style="border: 1px solid #eeeeee;border-collapse: collapse;"
                      class="align_left">
                      {{sales.doc_name}}</td>
                    <td style="border: 1px solid #eeeeee;border-collapse: collapse; text-align: right;">
                      {{sales.bill_amount}}</td>

                    <td *ngIf="columns.discount"
                      style="border: 1px solid #eeeeee;border-collapse: collapse; text-align: right;">
                      {{sales.discount}}</td>
                    <td *ngIf="columns.paid"
                      style="border: 1px solid #eeeeee;border-collapse: collapse; text-align: right;">
                      {{sales.paid_amount}}</td>
                    <td *ngIf="columns.cash"
                      style="border: 1px solid #eeeeee;border-collapse: collapse; text-align: right;">{{sales.cash}}
                    </td>
                    <td *ngIf="columns.card"
                      style="border: 1px solid #eeeeee;border-collapse: collapse; text-align: right;">{{sales.card}}
                    </td>
                    <td *ngIf="columns.upi"
                      style="border: 1px solid #eeeeee;border-collapse: collapse; text-align: right;">{{sales.upi}}</td>
                    <td *ngIf="columns.insurance"
                      style="border: 1px solid #eeeeee;border-collapse: collapse; text-align: right;">
                      {{sales.insurance}}</td>
                    <td *ngIf="columns.due"
                      style="border: 1px solid #eeeeee;border-collapse: collapse; text-align: right;">{{sales.balance}}
                    </td>
                    <td style="border: 1px solid #eeeeee;border-collapse: collapse; text-align: right;">{{sales.paid}}
                    </td>
                  </tr>
                  <tr>


                    <td [attr.colspan]="colspann"></td>
                    <td style="border: 1px solid #eeeeee;border-collapse: collapse;">
                      <div style="width: 100%; text-align: end;"><b>Subtotal</b></div>
                    </td>
                    <td style="border: 1px solid #eeeeee;border-collapse: collapse;">
                      <div style="width: 100%; text-align: end;"><b>{{BillAmt_Subtotal}}</b></div>
                    </td>
                    <td *ngIf="columns.discount" style="border: 1px solid #eeeeee;border-collapse: collapse;">
                      <div style="width: 100%; text-align: end;"><b>{{discountAmt_Subtotal}}</b></div>
                    </td>
                    <td *ngIf="columns.paid" style="border: 1px solid #eeeeee;border-collapse: collapse;">
                      <div style="width: 100%; text-align: end;"><b>{{paidAmt_Subtotal}}</b></div>
                    </td>
                    <td *ngIf="columns.cash" style="border: 1px solid #eeeeee;border-collapse: collapse;">
                      <div style="width: 100%; text-align: end;"><b>{{cash_Subtotal}}</b></div>
                    </td>
                    <td *ngIf="columns.card" style="border: 1px solid #eeeeee;border-collapse: collapse;">
                      <div style="width: 100%; text-align: end;"><b>{{card_Subtotal}}</b></div>
                    </td>
                    <td *ngIf="columns.upi" style="border: 1px solid #eeeeee;border-collapse: collapse;">
                      <div style="width: 100%; text-align: end;"><b>{{upi_Subtotal}}</b></div>
                    </td>
                    <td *ngIf="columns.insurance" style="border: 1px solid #eeeeee;border-collapse: collapse;">
                      <div style="width: 100%; text-align: end;"><b>{{ins_Subtotal}}</b></div>
                    </td>
                    <td *ngIf="columns.due" style="border: 1px solid #eeeeee;border-collapse: collapse;">
                      <div style="width: 100%; text-align: end;"><b>{{due_Subtotal}}</b></div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div *ngIf="pattype == 'out-pat'">
              <h2 *ngIf="showNewPat_arr && newPat_arr.length != 0"
                style="margin: 15px 0px -5px 5px; font-size: 14px !important; font-weight: bold;">Consulting</h2>
              <table style="font-size: 12px; width: 95%; margin-top: 10px !important;"
                *ngIf="showNewPat_arr && newPat_arr.length != 0">
                <thead>
                  <tr>
                    <th style="border: 1px solid #eeeeee;border-collapse: collapse;">S.no</th>
                    <th style="width:42px; border: 1px solid #eeeeee;border-collapse: collapse;">Bill No</th>
                    <!-- <th style="width:125px">Created By</th> -->


                    <th style="width:80px; text-align: left; border: 1px solid #eeeeee;border-collapse: collapse;">

                      Bill Date</th>
                    <th *ngIf="columns.paidon"
                      style="width:80px;text-align: left; border: 1px solid #eeeeee;border-collapse: collapse;">
                      Paid On</th>
                    <th style="width:90px; border: 1px solid #eeeeee;border-collapse: collapse;">Paitent Name</th>


                    <th style="width:90px; border: 1px solid #eeeeee;border-collapse: collapse;"
                      *ngIf=" this.Filter_by_Id == 'Doctor' && columns.doctor">Doctor</th>
                    <th style="width:90px; border: 1px solid #eeeeee;border-collapse: collapse;"
                      *ngIf="this.Filter_by_Id == 'Speciality'">Speciality</th>
                    <th style="width:90px; border: 1px solid #eeeeee;border-collapse: collapse;"
                      *ngIf="this.Filter_by_Id == 'Service'">Charge</th>
                    <th style="border: 1px solid #eeeeee;border-collapse: collapse;">Bill Amt</th>

                    <th *ngIf="columns.discount" style="border: 1px solid #eeeeee;border-collapse: collapse;">Discount
                    </th>
                    <th *ngIf="columns.paid" style="border: 1px solid #eeeeee;border-collapse: collapse;">Paid</th>
                    <th *ngIf="columns.cash" style="border: 1px solid #eeeeee;border-collapse: collapse;">Cash</th>
                    <th *ngIf="columns.card" style="border: 1px solid #eeeeee;border-collapse: collapse;">Card</th>
                    <th *ngIf="columns.upi" style="border: 1px solid #eeeeee;border-collapse: collapse;">UPI</th>


                    <th *ngIf="columns.insurance" style="border: 1px solid #eeeeee;border-collapse: collapse;">Insurance
                    </th>
                    <th *ngIf="columns.due" style="border: 1px solid #eeeeee;border-collapse: collapse;">Due/Credit</th>
                    <th style="border: 1px solid #eeeeee;border-collapse: collapse;">Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let sales of newPat_arr">
                    <td style="border: 1px solid #eeeeee;border-collapse: collapse;">{{sales.sno}}</td>
                    <td style=" border: 1px solid #eeeeee;border-collapse: collapse;">{{sales.billing_id}}</td>
                    <!-- <td class="align_left" style="width:150px">{{sales.user_name}}</td> -->


                    <td style="text-align: left; border: 1px solid #eeeeee;border-collapse: collapse;">
                      {{sales.created_date}}</td>
                    <td *ngIf="columns.paidon"
                      style="text-align: left; border: 1px solid #eeeeee;border-collapse: collapse;">
                      {{sales.pay_date}}</td>
                    <td style="border: 1px solid #eeeeee;border-collapse: collapse;" class="align_left">
                      {{sales.patient_name}}</td>

                    <td *ngIf="columns.doctor" style="border: 1px solid #eeeeee;border-collapse: collapse;"
                      class="align_left">
                      {{sales.doc_name}}</td>
                    <td style="border: 1px solid #eeeeee;border-collapse: collapse; text-align: right;">
                      {{sales.bill_amount}}</td>

                    <td *ngIf="columns.discount"
                      style="border: 1px solid #eeeeee;border-collapse: collapse; text-align: right;">
                      {{sales.discount}}</td>
                    <td *ngIf="columns.paid"
                      style="border: 1px solid #eeeeee;border-collapse: collapse; text-align: right;">
                      {{sales.paid_amount}}</td>
                    <td *ngIf="columns.cash"
                      style="border: 1px solid #eeeeee;border-collapse: collapse; text-align: right;">{{sales.cash}}
                    </td>
                    <td *ngIf="columns.card"
                      style="border: 1px solid #eeeeee;border-collapse: collapse; text-align: right;">{{sales.card}}
                    </td>
                    <td *ngIf="columns.upi"
                      style="border: 1px solid #eeeeee;border-collapse: collapse; text-align: right;">{{sales.upi}}</td>
                    <td *ngIf="columns.insurance"
                      style="border: 1px solid #eeeeee;border-collapse: collapse; text-align: right;">
                      {{sales.insurance}}</td>
                    <td *ngIf="columns.due"
                      style="border: 1px solid #eeeeee;border-collapse: collapse; text-align: right;">{{sales.balance}}
                    </td>
                    <td style="border: 1px solid #eeeeee;border-collapse: collapse; text-align: right;">{{sales.paid}}
                    </td>
                  </tr>
                  <tr>


                    <td [attr.colspan]="colspann"></td>
                    <td style="border: 1px solid #eeeeee;border-collapse: collapse;">
                      <div style="width: 100%; text-align: end;"><b>Subtotal</b></div>
                    </td>
                    <td style="border: 1px solid #eeeeee;border-collapse: collapse;">
                      <div style="width: 100%; text-align: end;"><b>{{BillAmt_Subtotal}}</b></div>
                    </td>
                    <td *ngIf="columns.discount" style="border: 1px solid #eeeeee;border-collapse: collapse;">
                      <div style="width: 100%; text-align: end;"><b>{{discountAmt_Subtotal}}</b></div>
                    </td>


                    <td *ngIf="columns.paid" style="border: 1px solid #eeeeee;border-collapse: collapse;">
                      <div style="width: 100%; text-align: end;"><b>{{paidAmt_Subtotal}}</b></div>
                    </td>
                    <td *ngIf="columns.cash" style="border: 1px solid #eeeeee;border-collapse: collapse;">
                      <div style="width: 100%; text-align: end;"><b>{{cash_Subtotal}}</b></div>
                    </td>
                    <td *ngIf="columns.card" style="border: 1px solid #eeeeee;border-collapse: collapse;">
                      <div style="width: 100%; text-align: end;"><b>{{card_Subtotal}}</b></div>
                    </td>
                    <td *ngIf="columns.upi" style="border: 1px solid #eeeeee;border-collapse: collapse;">
                      <div style="width: 100%; text-align: end;"><b>{{upi_Subtotal}}</b></div>
                    </td>
                    <td *ngIf="columns.insurance" style="border: 1px solid #eeeeee;border-collapse: collapse;">
                      <div style="width: 100%; text-align: end;"><b>{{ins_Subtotal}}</b></div>
                    </td>
                    <td *ngIf="columns.due" style="border: 1px solid #eeeeee;border-collapse: collapse;">
                      <div style="width: 100%; text-align: end;"><b>{{due_Subtotal}}</b></div>
                    </td>
                  </tr>
                </tbody>
              </table>


              <h2 *ngIf="showOldPat_arr && followUpPat_arr.length != 0"
                style="margin: 15px 0px -5px 5px; font-size: 14px !important; font-weight: bold;">Follow-up</h2>
              <table style="font-size: 12px; width: 95%; margin-top: 10px !important;"
                *ngIf="showOldPat_arr && followUpPat_arr.length != 0">
                <thead>
                  <tr>
                    <th style="border: 1px solid #eeeeee;border-collapse: collapse;">S.no</th>
                    <th style="width:42px; border: 1px solid #eeeeee;border-collapse: collapse;">Bill No</th>
                    <!-- <th style="width:125px">Created By</th> -->


                    <th style="width:80px;text-align: left; border: 1px solid #eeeeee;border-collapse: collapse;">

                      Bill Date</th>
                    <th *ngIf="columns.paidon"
                      style="width:80px;text-align: left; border: 1px solid #eeeeee;border-collapse: collapse;">
                      Paid On</th>
                    <th style="width:90px; border: 1px solid #eeeeee;border-collapse: collapse;">Paitent Name</th>


                    <th style="width:90px; border: 1px solid #eeeeee;border-collapse: collapse;"
                      *ngIf=" this.Filter_by_Id == 'Doctor' ">Doctor</th>
                    <th style="width:90px; border: 1px solid #eeeeee;border-collapse: collapse;"
                      *ngIf="this.Filter_by_Id == 'Speciality'">Speciality</th>
                    <th style="width:90px; border: 1px solid #eeeeee;border-collapse: collapse;"
                      *ngIf="this.Filter_by_Id == 'Service'">Charge</th>
                    <th style="border: 1px solid #eeeeee;border-collapse: collapse; ">Bill Amt</th>
                    <th style="border: 1px solid #eeeeee;border-collapse: collapse; ">Discount</th>
                    <th *ngIf="columns.paid" style="border: 1px solid #eeeeee;border-collapse: collapse;">Paid</th>
                    <th *ngIf="columns.cash" style="border: 1px solid #eeeeee;border-collapse: collapse;">Cash</th>
                    <th *ngIf="columns.card" style="border: 1px solid #eeeeee;border-collapse: collapse;">Card</th>
                    <th *ngIf="columns.upi" style="border: 1px solid #eeeeee;border-collapse: collapse;">UPI</th>


                    <th *ngIf="columns.insurance" style="border: 1px solid #eeeeee;border-collapse: collapse;">Insurance
                    </th>
                    <th *ngIf="columns.due" style="border: 1px solid #eeeeee;border-collapse: collapse;">Due/Credit</th>
                    <th style="border: 1px solid #eeeeee;border-collapse: collapse; ">Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let sales of followUpPat_arr">
                    <td style="border: 1px solid #eeeeee;border-collapse: collapse;">{{sales.sno}}</td>
                    <td style="border: 1px solid #eeeeee;border-collapse: collapse;">{{sales.billing_id}}</td>
                    <!-- <td class="align_left" style="width:150px">{{sales.user_name}}</td> -->


                    <td style="text-align: left; border: 1px solid #eeeeee;border-collapse: collapse;">
                      {{sales.created_date}}</td>
                    <td *ngIf="columns.paidon"
                      style="text-align: left; border: 1px solid #eeeeee;border-collapse: collapse;">
                      {{sales.pay_date}}</td>
                    <td style="border: 1px solid #eeeeee;border-collapse: collapse;" class="align_left">
                      {{sales.patient_name}}</td>
                    <td style="border: 1px solid #eeeeee;border-collapse: collapse;" class="align_left">
                      {{sales.doc_name}}</td>
                    <td style="border: 1px solid #eeeeee;border-collapse: collapse; text-align: right;">
                      {{sales.bill_amount}}</td>
                    <td style="border: 1px solid #eeeeee;border-collapse: collapse; text-align: right;">
                      {{sales.discount}}</td>
                    <td *ngIf="columns.paid"
                      style="border: 1px solid #eeeeee;border-collapse: collapse; text-align: right;">
                      {{sales.paid_amount}}</td>
                    <td *ngIf="columns.cash"
                      style="border: 1px solid #eeeeee;border-collapse: collapse; text-align: right;">{{sales.cash}}
                    </td>
                    <td *ngIf="columns.card"
                      style="border: 1px solid #eeeeee;border-collapse: collapse; text-align: right;">{{sales.card}}
                    </td>
                    <td *ngIf="columns.upi"
                      style="border: 1px solid #eeeeee;border-collapse: collapse; text-align: right;">{{sales.upi}}</td>
                    <td *ngIf="columns.insurance"
                      style="border: 1px solid #eeeeee;border-collapse: collapse; text-align: right;">
                      {{sales.insurance}}</td>
                    <td *ngIf="columns.due"
                      style="border: 1px solid #eeeeee;border-collapse: collapse; text-align: right;">{{sales.balance}}
                    </td>
                    <td style="border: 1px solid #eeeeee;border-collapse: collapse; text-align: right;">{{sales.paid}}
                    </td>
                  </tr>
                  <tr>


                    <td [attr.colspan]="colspann"></td>
                    <td style="border: 1px solid #eeeeee;border-collapse: collapse;">
                      <div style="width: 100%; text-align: end;"><b>Subtotal</b></div>
                    </td>
                    <td style="border: 1px solid #eeeeee;border-collapse: collapse;">
                      <div style="width: 100%; text-align: end;"><b>{{BillAmt_Subtotal_1}}</b></div>
                    </td>
                    <td *ngIf="columns.discount" style="border: 1px solid #eeeeee;border-collapse: collapse;">
                      <div style="width: 100%; text-align: end;"><b>{{discountAmt_Subtotal_1}}</b></div>
                    </td>
                    <td *ngIf="columns.paid" style="border: 1px solid #eeeeee;border-collapse: collapse;">
                      <div style="width: 100%; text-align: end;"><b>{{paidAmt_Subtotal_1}}</b></div>
                    </td>
                    <td *ngIf="columns.cash" style="border: 1px solid #eeeeee;border-collapse: collapse;">
                      <div style="width: 100%; text-align: end;"><b>{{cash_Subtotal_1}}</b></div>
                    </td>
                    <td *ngIf="columns.card" style="border: 1px solid #eeeeee;border-collapse: collapse;">
                      <div style="width: 100%; text-align: end;"><b>{{card_Subtotal_1}}</b></div>
                    </td>
                    <td *ngIf="columns.upi" style="border: 1px solid #eeeeee;border-collapse: collapse;">
                      <div style="width: 100%; text-align: end;"><b>{{upi_Subtotal_1}}</b></div>
                    </td>
                    <td *ngIf="columns.insurance" style="border: 1px solid #eeeeee;border-collapse: collapse;">
                      <div style="width: 100%; text-align: end;"><b>{{ins_Subtotal_1}}</b></div>
                    </td>
                    <td *ngIf="columns.due" style="border: 1px solid #eeeeee;border-collapse: collapse;">
                      <div style="width: 100%; text-align: end;"><b>{{due_Subtotal_1}}</b></div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <table #printchargetable *ngIf="reportType == 'HBR' && chargeList.length!=0" id="printchargetable" style="margin-top: 20px;width:100%;font-size: 12px;position: relative;
            left: 20%;">
            <thead>
              <tr>
                <td>S.No</td>
                <th>Charge type</th>
                <th>Bill amount</th>
                <th>Paid amount</th>
                <th>Cash</th>
                <th>Card</th>
                <th>UPI</th>
                <th>Cheque</th>
                <th>Insurance</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let charge of chargeList">
                <td>{{charge.sno}}</td>
                <td style="text-align: left;">{{charge.charge}}</td>
                <td>{{charge.billamt}}</td>
                <td>{{charge.paidamt}}</td>
                <td>{{charge.cash}}</td>
                <td>{{charge.card}}</td>
                <td>{{charge.upi}}</td>
                <td>{{charge.cheque}}</td>
                <td>{{charge.insurance}}</td>
              </tr>
            </tbody>
          </table>

          <table #printusertable [hidden]="true" *ngIf="reportType == 'HBR' && userdataList.length !=0"
            id="printusertable" style="margin-top: 20px;width:100%;font-size: 12px;position: relative;
          left: 20%;">
            <thead>
              <tr>
                <td>S.No</td>
                <th>User Name</th>
                <th>Bill</th>
                <th>Discount</th>
                <th> Cash</th>
                <th>Card</th>
                <th>UPI</th>
                <th>Cheque</th>
                <th>Insurance</th>
                <th>Due/Credit</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let user of userdataList">
                <td>{{user.sno}}</td>
                <td>{{user.username}}</td>
                <td>{{user.bill}}</td>
                <td>{{user.discount}}</td>
                <td>{{user.cash}}</td>
                <td>{{user.card}}</td>
                <td>{{user.upi}}</td>
                <td>{{user.cheque}}</td>
                <td>{{user.insurance}}</td>
                <td>{{user.balance}}</td>
              </tr>
            </tbody>
          </table>
          <!------added by christo------->

          <div class="accordion" id="accordionExample" *ngIf="consoldatedreport">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <span class="reportsheanding">Hospital Bill Report</span>
                <button class="accordion-button collapsed" style="background: inherit;" type="button"
                  data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false"
                  aria-controls="collapseOne" (click)="conbillstyle()">

                  <div class="accordinamountcard">
                    <div class="row">

                      <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 my-auto" *ngIf="reportType == 'CR'">
                        <mat-label #printCRHBRtotal id="printCRHBRtotal" class="matlabel" style="margin-top: 7px;"
                          *ngIf="doctotalAmount == 'NaN' ? '0.00' : doctotalAmount"><span
                            style="font-weight: 600;">Total : </span> {{doctotalAmount}}
                        </mat-label>
                      </div>
                      <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 my-auto" *ngIf="reportType == 'CR'">
                        <mat-label #printCRHBRcash id="printCRHBRcash" class="matlabel" style="margin-top: 7px;"
                          *ngIf="doccashAmount == 'NaN' ? '0.00' : doccashAmount"><span style="font-weight: 600;">Cash :
                          </span>
                          {{doccashAmount}}
                        </mat-label>
                      </div>
                      <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 my-auto" *ngIf="reportType == 'CR'">
                        <mat-label #printCRHBRcard id="printCRHBRcard" class="matlabel" style="margin-top: 7px;"
                          *ngIf="doccardAmount == 'NaN' ? '0.00' : doccardAmount"><span style="font-weight: 600;">Card :
                          </span>
                          {{doccardAmount}}
                        </mat-label>
                      </div>
                      <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 my-auto" *ngIf="reportType == 'CR'">
                        <mat-label #printCRHBRinsurance id="printCRHBRinsurance" class="matlabel"
                          style="margin-top: 7px;" *ngIf="docinsureAmount == 'NaN' ? '0.00' : docinsureAmount"><span
                            style="font-weight: 600;">Insurance :
                          </span> {{docinsureAmount}}
                        </mat-label>
                      </div>
                      <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 my-auto" *ngIf="reportType == 'CR'">
                        <mat-label #printCRHBRupi id="printCRHBRupi" class="matlabel" style="margin-top: 7px;"
                          *ngIf="docupiAmount == 'NaN' ? '0.00' : docupiAmount"><span
                            style="font-weight: 600;">UPI:</span>
                          {{docupiAmount}}
                        </mat-label>
                      </div>
                    </div>
                  </div>

                </button>
              </h2>

              <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne"
                data-bs-parent="#accordionExample" [ngClass]="{'success': isSuccessful, 'error': !isSuccessful}"
                style="width: 100%;">
                <div class="accordion-body">
                  <table #printCRHBR id="printCRHBR" *ngIf="reportType == 'CR'" style="font-size: 12px;">
                    <thead>
                      <tr>
                        <th>S.no</th>
                        <th style="width:75px">Bill No</th>
                        <th style="width:125px">Created By</th>
                        <th style="width:125px;text-align: left;">Created Date</th>
                        <th>Paitent Name</th>
                        <th *ngIf=" this.Filter_by_Id == 'Doctor' ">Doctor</th>
                        <th *ngIf="this.Filter_by_Id == 'Speciality'">Speciality</th>
                        <th *ngIf="this.Filter_by_Id == 'Service'">Charge</th>

                        <th>Bill Amt</th>
                        <th>Paid</th>
                        <th>Cash</th>
                        <th>Card</th>
                        <th>UPI</th>

                        <th>Insurance</th>
                        <th>Due/Credit</th>

                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let sales of salesList">
                        <td>{{sales.sno}}</td>
                        <td style="width:75px">{{sales.billing_id}}</td>
                        <td class="align_left" style="width:150px">{{sales.user_name}}</td>
                        <td style="width:125px;text-align: left;">{{sales.created_date}}</td>

                        <td class="align_left">{{sales.patient_name}}</td>
                        <td class="align_left">{{sales.doc_name}}</td>

                        <td>{{sales.bill_amount}}</td>
                        <td>{{sales.paid_amount}}</td>
                        <td>{{sales.cash}}</td>
                        <td>{{sales.card}}</td>
                        <td>{{sales.upi}}</td>

                        <td>{{sales.insurance}}</td>
                        <td>{{sales.balance}}</td>
                        <td>{{sales.paid}}</td>
                      </tr>
                    </tbody>
                  </table>
                  <table #printCRHBRusertable id="printCRHBRusertable" *ngIf="reportType == 'CR'"
                    style="margin-top: 20px;font-size: 12px;position: relative">
                    <thead>
                      <tr>
                        <td>S.No</td>
                        <th>User Name</th>
                        <th>Bill</th>

                        <th>Cash</th>
                        <th>Card</th>
                        <th>UPI</th>
                        <th>Cheque</th>
                        <th>Insurance</th>
                        <th>Due/Credit</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let user of docuserdataList">
                        <td>{{user.sno}}</td>
                        <td>{{user.username}}</td>
                        <td>{{user.bill}}</td>
                        <td>{{user.cash}}</td>
                        <td>{{user.card}}</td>
                        <td>{{user.upi}}</td>
                        <td>{{user.check}}</td>
                        <td>{{user.insurance}}</td>
                        <td>{{user.balance}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwo">

                <button class="accordion-button collapsed" style="background: inherit;" type="button"
                  data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false"
                  aria-controls="collapseTwo" (click)="conbillstyle()">

                  <div class="accordinamountcard">
                    <div class="row">
                      <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 my-auto" *ngIf="reportType == 'CR'">
                        <mat-label class="matlabel" #printCRDBRtotal id="printCRDBRtotal" style="margin-top: 7px;"
                          *ngIf="diagtotalAmount == 'NaN' ? '0.00' : diagtotalAmount"><span
                            style="font-weight: 600;">Total : </span> {{diagtotalAmount}}
                        </mat-label>
                      </div>
                      <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 my-auto" *ngIf="reportType == 'CR'">
                        <mat-label class="matlabel" #printCRDBRcash id="printCRHBRcash" style="margin-top: 7px;"
                          *ngIf="diagcashAmount == 'NaN' ? '0.00' : diagcashAmount"><span style="font-weight: 600;">Cash
                            : </span>
                          {{diagcashAmount}}
                        </mat-label>
                      </div>
                      <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 my-auto" *ngIf="reportType == 'CR'">
                        <mat-label class="matlabel" #printCRDBRcard id="printCRHBRcard" style="margin-top: 7px;"
                          *ngIf="diagcardAmount == 'NaN' ? '0.00' : diagcardAmount"><span style="font-weight: 600;">Card
                            : </span>
                          {{diagcardAmount}}
                        </mat-label>
                      </div>
                      <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 my-auto" *ngIf="reportType == 'CR'">
                        <mat-label class="matlabel" #printCRDBRinsurance id="printCRHBRinsurance"
                          style="margin-top: 7px;" *ngIf="diaginsureAmount == 'NaN' ? '0.00' : diaginsureAmount"><span
                            style="font-weight: 600;">Insurance :
                          </span> {{diaginsureAmount}}
                        </mat-label>
                      </div>
                      <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 my-auto" *ngIf="reportType == 'CR'">
                        <mat-label class="matlabel" #printCRDBRupi id="printCRHBRupi" style="margin-top: 7px;"
                          *ngIf="diagupiAmount == 'NaN' ? '0.00' : diagupiAmount"><span
                            style="font-weight: 600;">UPI:</span>
                          {{diagupiAmount}}
                        </mat-label>
                      </div>
                    </div>
                  </div>
                </button>
              </h2>
              <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample" [ngClass]="{'success': isSuccessful, 'error': !isSuccessful}"
                style="width: 100%;">
                <div class="accordion-body">
                  <table *ngIf="reportType == 'CR'" style="font-size: 12px" #printCRDBR id="printCRDBR">
                    <thead>
                      <tr>
                        <th>S.no</th>
                        <th style="width:75px">Bill No</th>
                        <th style="width:150px">Created By</th>
                        <th style="width:125px">Created Date</th>
                        <th>In/Out Patient</th>
                        <th>Paitent Name</th>
                        <th>Advance</th>
                        <th>Balance</th>
                        <th style="width: 100px;">Bill Amt</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let sales of diagnosissalesList">
                        <td>{{sales.sno}}</td>
                        <td style="width:75px">{{sales.billing_id}}</td>
                        <td class="align_left" style="width:100px">{{sales.user_name}}</td>
                        <td style="width:125px">{{sales.created_date}}</td>
                        <td>{{sales.patient_type}}</td>
                        <td class="align_left">{{sales.patient_name}}</td>
                        <td>{{sales.advance}}</td>
                        <td>{{sales.balance}}</td>
                        <td style="width: 75px;">{{sales.bill_amount}}</td>
                        <td>{{sales.paid}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <span class="reportsheanding">Pharma Sales Report</span>
                <button class="accordion-button collapsed" style="background: inherit;" type="button"
                  data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false"
                  aria-controls="collapseThree" (click)="conbillstyle()">
                  <div class="accordinamountcard">
                    <div class="row">
                      <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 my-auto">
                        <mat-label class="matlabel" style="margin-top: 7px;" #printpharmatotal id="printpharmatotal"
                          *ngIf="pharmatotalAmount == 'NaN' ? '0.00' : pharmatotalAmount">
                          <span style="font-weight: 600;">Amount : </span> {{pharmatotalAmount}}
                        </mat-label>
                      </div>
                      <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 my-auto">
                        <mat-label class="matlabel" style="margin-top: 7px;"
                          *ngIf="pharmacashAmount == 'NaN' ? '0.00' : pharmacashAmount" #printpharmacash
                          id="printpharmacash"><span style="font-weight: 600;">Cash : </span>
                          {{pharmacashAmount}}
                        </mat-label>
                      </div>
                      <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 my-auto">
                        <mat-label class="matlabel" style="margin-top: 7px;"
                          *ngIf="pharmacardAmount == 'NaN' ? '0.00' : pharmacardAmount" #printpharmacard
                          id="printpharmacard"><span style="font-weight: 600;">Card : </span>
                          {{pharmacardAmount}}
                        </mat-label>
                      </div>
                      <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 my-auto">
                        <mat-label class="matlabel" style="margin-top: 7px;"
                          *ngIf="pharmainsureAmount == 'NaN' ? '0.00' : pharmainsureAmount" #printpharmainsurance
                          id="  printpharmainsurance"><span style="font-weight: 600;">Insurance :
                          </span> {{pharmainsureAmount}}
                        </mat-label>
                      </div>

                      <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 my-auto">
                        <mat-label class="matlabel" style="margin-top: 7px;"
                          *ngIf="pharmaupiAmount == 'NaN' ? '0.00' : pharmaupiAmount" #printpharmaupi
                          id="printpharmaupi"><span style="font-weight: 600;">UPI:</span>
                          {{pharmaupiAmount}}
                        </mat-label>
                      </div>
                    </div>
                  </div>
                </button>
              </h2>
              <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                data-bs-parent="#accordionExample" style="width: 100%;"
                [ngClass]="{'success': isSuccessful, 'error': !isSuccessful}">
                <div class="accordion-body">


                  <table style="font-size: 12px;margin-left: 5px;" id="printpharma" #printpharma>
                    <thead>
                      <tr>
                        <th style="width:100px">Bill no</th>
                        <th style="width:140px;text-align: left;">Buyer</th>
                        <th style="width:140px;text-align: left;">Created by</th>
                        <th style="width:140px;">Purchase type</th>
                        <th>Amount</th>
                        <th style="width:150px">Deliver To</th>
                        <th>Bill date</th>
                        <th>Balance</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let sales of pharmasalesList">
                        <td style="width:100px">{{sales.order_id}}</td>
                        <td class="align_left" style="width:140px">{{sales.name}}</td>
                        <td class="align_left" style="width:140px">{{sales.created_by}}</td>
                        <td>{{sales.order_type}}</td>
                        <td style="text-align: right;">{{sales.amount}}</td>
                        <td style="width:150px">{{sales.deliver_to}}</td>
                        <td style="width:100px">{{sales.order_date}}</td>
                        <td style="text-align: right;">{{sales.balance}}</td>
                        <td style="width:100px">{{sales.paid}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <!-----added by christo--------->

          <!-- mat accordin -->
          <table #printNVR *ngIf="reportType == 'NVR'" id="printNVR" style="font-size: 12px; width: 105%;">
            <thead>
              <tr>
                <th>S.no</th>
                <th style="width: 25%;text-align:left">Name</th>
                <th style="width:19%">Mobile Number</th>
                <th>Session</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let sales of salesList">
                <td>{{sales.sno}}</td>
                <td style="width: 25%;text-align:left">{{sales.patientname}}</td>
                <td style="width:10%;text-align:left">{{sales.number}}</td>
                <td>{{sales.session}}</td>
                <td>{{sales.date}}</td>
              </tr>
            </tbody>
          </table>

          <table #printCHBR *ngIf="reportType == 'CHBR'" id="printCHBR" style="font-size: 12px; width: 135%;">
            <thead>
              <tr>
                <th>S.no</th>
                <th style="width:75px">Bill No</th>
                <th style="width:180px">Product</th>
                <th style="width:100px">Batch No</th>
                <th style="width:150px">MRP(On book)</th>
                <th>MRP(Sold)</th>
                <th>Amount</th>
                <th style="width:130px">Sold By</th>
                <th style="width:125px">Date</th>
                <th>Qty Sold</th>
                <th>Qty Onhand</th>
                <th>Balance</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let sales of salesList">
                <td>{{sales.sno}}</td>
                <td style="width:100px">{{sales.bill_no}}</td>
                <td class="align_center">{{sales.product_name}}</td>
                <td>{{sales.batchno}}</td>
                <td>{{sales.mrponbook}}</td>
                <td class="align_left">{{sales.mrpsold}}</td>
                <td class="align_left">{{sales.amount}}</td>
                <td>{{sales.created}}</td>
                <td style="width:125px">{{sales.date}}</td>
                <td>{{sales.sold_qty}}</td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
          <table class="headerfixed" #printpatreport *ngIf="reportType == 'Patreport'" id="printpatreport"
            style="font-size: 12px; width: 95%;">
            <thead>
              <tr>
                <th style="width:40px">S.no</th>
                <th style="width:270px;text-align: left;">Name</th>
                <th style="width:50px">Mobile no</th>
                <th style="width:50px">Patient Id</th>
                <th style="width:50px">Gender</th>
                <th style="width:50px">Age</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let sales of salesList">
                <td>{{sales.sno}}</td>
                <td style="width:270px;text-align: left;">{{sales.name}}</td>
                <td class="align_center">{{sales.MobileNo}}</td>
                <td>{{sales.patId}}</td>
                <td>{{sales.gender}}</td>
                <td>{{sales.age}}</td>
              </tr>
            </tbody>
          </table>
          <table #printSURREPO *ngIf="reportType == 'SURREPO'" id="printSURREPO" style="font-size: 12px; width: 105%;">
            <thead>
              <tr>
                <th>S.no</th>
                <th style="width:150px">Patient Name</th>
                <th>Mobile no</th>
                <th>Age</th>
                <th>Gender</th>
                <th>Doctor</th>
                <th>Surgery</th>
                <th>Surgery Date</th>

              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let sales of salesList">
                <td>{{sales.sno}}</td>
                <td style="width:150px">{{sales.name}}</td>
                <td class="align_center">{{sales.MobileNo}}</td>
                <td>{{sales.age}}</td>
                <td>{{sales.gender}}</td>
                <td>{{sales.surgonname}}</td>
                <td>{{sales.surgery}}</td>
                <td>{{sales.date}}</td>
              </tr>
            </tbody>
          </table>


          <table class="headerfixed" *ngIf="reportType == 'DBR'" style="font-size: 12px; width: 110%;">
            <thead>
              <tr>
                <th>S.no</th>
                <th style="width:75px">Bill No</th>

                <!-- <th style="width:150px">Created By</th> -->
                <th style="width:125px">Created Date</th>

                <th *ngIf="columns.ipOp">In/Out Patient</th>
                <th>Paitent Name</th>

                <th *ngIf="columns.advance">Advance</th>
                <th style="width: 100px;">Bill Amt</th>

                <th *ngIf="columns.discount">Discount</th>


                <th *ngIf="columns.paid">Paid</th>
                <th *ngIf="columns.cash">Cash</th>
                <th *ngIf="columns.card">Card</th>
                <th *ngIf="columns.upi">UPI</th>
                <th *ngIf="columns.insurance">Insurance</th>
                <th *ngIf="columns.due">Due/Credit</th>
                <!-- <th>Pay type</th> -->
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let sales of salesList">
                <td>{{sales.sno}}</td>
                <td style="width:75px">{{sales.billing_id}}</td>

                <!-- <td class="align_left" style="width:100px">{{sales.user_name}}</td> -->
                <td style="width:125px">{{sales.created_date}}</td>

                <td *ngIf="columns.ipOp">{{sales.patient_type}}</td>
                <td class="align_left">{{sales.patient_name}}</td>

                <td *ngIf="columns.advance">{{sales.advance}}</td>

                <td style="width: 75px;">{{sales.bill_amount}}</td>

                <td *ngIf="columns.discount">{{sales.discount}}</td>


                <td *ngIf="columns.paid">{{sales.paid_amount}}</td>
                <td *ngIf="columns.cash">{{sales.cash}}</td>
                <td *ngIf="columns.card">{{sales.card}}</td>
                <td *ngIf="columns.upi">{{sales.upi}}</td>
                <td *ngIf="columns.insurance">{{sales.insurance}}</td>
                <td *ngIf="columns.due">{{sales.balance}}</td>
                <!-- <td>{{sales.pay_type}}</td> -->
                <td>{{sales.paid}}</td>
              </tr>
            </tbody>
          </table>



          <div #printDBR id="printDBR" [hidden]="true">
            <table *ngIf="reportType == 'DBR'" style="font-size: 12px; width: 95%;">
              <thead>
                <tr>
                  <th style="width:10px; border: 1px solid #eeeeee;border-collapse: collapse;">S.no</th>
                  <th style="width:50px; border: 1px solid #eeeeee;border-collapse: collapse;">Bill No</th>
                  <!-- <th style="width:150px">Created By</th> -->
                  <th style="width:95px; border: 1px solid #eeeeee;border-collapse: collapse;">Created Date</th>

                  <th style="width:50px; border: 1px solid #eeeeee;border-collapse: collapse;" *ngIf="columns.ipOp">
                    IP/OP</th>
                  <th style="width:100px; border: 1px solid #eeeeee;border-collapse: collapse;">Paitent Name</th>

                  <th style="width:20px; border: 1px solid #eeeeee;border-collapse: collapse;" *ngIf="columns.advance">
                    Advance</th>
                  <th style="width:25px; border: 1px solid #eeeeee;border-collapse: collapse;">Bill Amt</th>

                  <th style="width:20px; border: 1px solid #eeeeee;border-collapse: collapse;" *ngIf="columns.discount">
                    Discount</th>
                  <th style="width:20px; border: 1px solid #eeeeee;border-collapse: collapse;" *ngIf="columns.paid">Paid
                  </th>
                  <th style="width:20px; border: 1px solid #eeeeee;border-collapse: collapse;" *ngIf="columns.cash">Cash
                  </th>
                  <th style="width:20px; border: 1px solid #eeeeee;border-collapse: collapse;" *ngIf="columns.card">Card
                  </th>
                  <th style="width:20px; border: 1px solid #eeeeee;border-collapse: collapse;" *ngIf="columns.upi">UPI
                  </th>
                  <th style="width:20px; border: 1px solid #eeeeee;border-collapse: collapse;"
                    *ngIf="columns.insurance">Insurance</th>
                  <th style="width:20px; border: 1px solid #eeeeee;border-collapse: collapse;" *ngIf="columns.due">
                    Due/Credit</th>
                  <!-- <th>Pay type</th> -->


                  <th style="width: 50px;border: 1px solid #eeeeee;border-collapse: collapse;">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let sales of salesList">


                  <td style="border: 1px solid #eeeeee;border-collapse: collapse;">{{sales.sno}}</td>
                  <td style="border: 1px solid #eeeeee;border-collapse: collapse;">{{sales.billing_id}}</td>
                  <!-- <td class="align_left" style="width:100px">{{sales.user_name}}</td> -->



                  <td style="border: 1px solid #eeeeee;border-collapse: collapse; text-align: left;">
                    {{sales.created_date}}</td>
                  <td style="border: 1px solid #eeeeee;border-collapse: collapse; text-align: left;"
                    *ngIf="columns.ipOp">{{sales.patient_type}}</td>
                  <td style="border: 1px solid #eeeeee;border-collapse: collapse;text-align: left;">
                    {{sales.patient_name}}</td>
                  <td style="border: 1px solid #eeeeee;border-collapse: collapse;text-align: right;"
                    *ngIf="columns.advance">{{sales.advance}}</td>

                  <td style="border: 1px solid #eeeeee;border-collapse: collapse;text-align: right;">
                    {{sales.bill_amount}}</td>
                  <td style="border: 1px solid #eeeeee;border-collapse: collapse;text-align: right;"
                    *ngIf="columns.discount">{{sales.discount}}</td>
                  <td style="border: 1px solid #eeeeee;border-collapse: collapse;text-align: right;"
                    *ngIf="columns.paid">{{sales.paid_amount}}</td>
                  <td style="border: 1px solid #eeeeee;border-collapse: collapse;text-align: right;"
                    *ngIf="columns.cash">{{sales.cash}}</td>
                  <td style="border: 1px solid #eeeeee;border-collapse: collapse;text-align: right;"
                    *ngIf="columns.card">{{sales.card}}</td>
                  <td style="border: 1px solid #eeeeee;border-collapse: collapse;text-align: right;"
                    *ngIf="columns.upi">{{sales.upi}}</td>
                  <td style="border: 1px solid #eeeeee;border-collapse: collapse;text-align: right;"
                    *ngIf="columns.insurance">{{sales.insurance}}</td>
                  <td style="border: 1px solid #eeeeee;border-collapse: collapse;text-align: right;"
                    *ngIf="columns.due">{{sales.balance}}</td>
                  <!-- <td>{{sales.pay_type}}</td> -->


                  <td style="border: 1px solid #eeeeee;border-collapse: collapse;text-align: right;">{{sales.paid}}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <table #printIR *ngIf="reportType == 'IR'" id="printIR" style="font-size: 12px; width: 100%;">
            <thead>
              <tr>
                <th>S.no</th>
                <th>Order No</th>
                <th>User Name</th>
                <th>Product Name</th>
                <th>Batch No</th>
                <th>Quantity</th>
                <th>Balance</th>
                <th>Discount</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let sales of salesList">
                <td>{{sales.sno}}</td>
                <td>{{sales.order_id}}</td>
                <td class="align_left">{{sales.prop_name}}</td>
                <td class="align_left">{{sales.prod_name}}</td>
                <td>{{sales.batch_no}}</td>
                <td style="text-align:right;position:relative;right:25px;">{{sales.quantity}}</td>
                <td style="text-align:right;position:relative;right:25px;">{{sales.balance}}</td>
                <td style="text-align:right;position:relative;right:25px;">{{sales.discount}}</td>
                <td style="text-align:right;position:relative;right:25px;">{{sales.amount}}</td>
              </tr>
            </tbody>
          </table>

          <table #printIPR *ngIf="reportType == 'IPR'" id="printIPR" style="font-size: 12px; width: 105%;">
            <thead>
              <tr>
                <th>S.no</th>
                <th>DOA - TOA</th>
                <th>Patient Name</th>
                <th>Age</th>
                <th>Doctor Name</th>
                <th>Bed No</th>
                <th>DOD - TOD</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let sales of salesList">
                <td>{{sales.sno}}</td>
                <td>{{sales.admission}}</td>
                <td class="align_left">{{sales.patient_name}}</td>
                <td>{{sales.age}}</td>
                <td class="align_left">{{sales.doctor_name}}</td>
                <td>{{sales.bed_no}}</td>
                <td>{{sales.discharge}}</td>
              </tr>
            </tbody>
          </table>

          <table #printReturnsrepo *ngIf="reportType == 'returnsReport'" id="#printReturnsrepo"
            style="font-size: 12px; width: 105%;">
            <thead>
              <tr>
                <th>S.no</th>
                <th>Medicine</th>
                <th>Supplier</th>
                <th>Batch No</th>
                <th>Expiry date</th>
                <th>Return qty</th>
                <th>Return Date</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let sales of salesList">
                <td>{{sales.sno}}</td>
                <td>{{sales.product_name}}</td>
                <td>{{sales.Supplier}}</td>
                <td>{{sales.batch}}</td>
                <td>{{sales.expiryDate}}</td>
                <td>{{sales.qty}}</td>
                <td>{{sales.returned_date | date: 'dd-MM-yyyy'}} </td>
              </tr>
            </tbody>
          </table>

          <table #printSRD *ngIf="reportType == 'SRD'" id="printSRD" style="font-size: 12px; width: 105%;">
            <thead>
              <tr *ngIf="this.Filter_by_Id !='Service'">
                <th>S.no</th>
                <th style="width: 45%;">Name</th>
                <th>Count</th>
              </tr>
              <tr *ngIf="this.Filter_by_Id =='Service'">
                <th>S.no</th>
                <th style="width: 45%;">Name</th>
                <th>Count</th>
                <th>Fees</th>
                <th>Amounts</th>
              </tr>
            </thead>
            <tbody *ngIf="this.Filter_by_Id !='Service'">
              <tr *ngFor="let sales of salesList">
                <td>{{sales.sno}}</td>
                <td class="align_left">{{sales.name}}</td>
                <td>{{sales.count}}</td>
              </tr>
            </tbody>
            <tbody *ngIf="this.Filter_by_Id =='Service'">
              <tr *ngFor="let sales of salesList">
                <td>{{sales.sno}}</td>
                <td>{{sales.name}}</td>
                <td>{{sales.count}}</td>
                <td>{{sales.fee}}</td>
                <td>{{sales.amount}}</td>
              </tr>
            </tbody>
          </table>

          <table #printBillRefund *ngIf="reportType =='Bill_refund'" id="printBillRefund"
            style="font-size: 12px; width: 95%;">
            <thead>
              <tr>
                <th>S.no</th>
                <th>Bill No</th>
                <!-- <th>Client registration id</th> -->
                <th>Name</th>
                <th>Created Date</th>
                <th>Refund Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let sales of salesList">
                <td style="padding: 0px 0px;width:1px">{{sales.sno}}</td>
                <td>{{sales.billid}}</td>
                <!-- <td class="align_left">{{sales.client_reg_id}}</td> -->
                <td class="align_left">{{sales.name}}</td>
                <td>{{sales.billdate}}</td>
                <td>{{sales.refund_amount}}</td>
              </tr>
            </tbody>
          </table>

          <table #printBillDue *ngIf="reportType =='Bill_due'" id="printBillDue" style="font-size: 12px; width: 100%;">
            <thead>
              <tr>
                <th>S.no</th>
                <th>Bill No</th>
                <!-- <th>Client registration id</th> -->
                <th>Name</th>
                <th>Created date </th>
                <th>Bill Due</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let sales of salesList">
                <td>{{sales.sno}}</td>
                <td>{{sales.billid}}</td>
                <!-- <td >{{sales.client_reg_id}}</td> -->
                <td>{{sales.name}}</td>
                <td>{{sales.bill_date}}</td>
                <td>{{sales.refund_amount}}</td>
              </tr>
            </tbody>
          </table>

          <table #printBedOcc *ngIf="reportType =='bed_occupancy'" id="#printBedOcc"
            style="font-size: 12px; width: 95%;">
            <thead>
              <tr *ngIf="bed_occupancyfilter =='ward'">
                <th>S.no</th>
                <th>Ward Name</th>
              </tr>
              <tr *ngIf="bed_occupancyfilter =='bed'">
                <th>S.no</th>
                <th>Ward Name</th>
                <th>Bed Number</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let sales of salesList">
                <td>{{sales.sno}}</td>
                <td>{{sales.wardname}}</td>
                <td>{{sales.bedno}}</td>
              </tr>
            </tbody>
          </table>

          <table #printabc *ngIf="reportType == 'abc_analysis'" id="printabc" style="font-size: 12px; width: 95%;">
            <thead>
              <tr>
                <th>S.no</th>
                <th>Product Name</th>
                <th>Quantity</th>
                <th>Sales</th>
                <th>Product Rank</th>
                <th>Product Type</th>
                <th>ABC Analysis</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let sales of salesList">
                <td>{{sales.sno}}</td>
                <td>{{sales.product_name}}</td>
                <td>{{sales.quantity}}</td>
                <td>{{sales.sales}}</td>
                <td>{{sales.product_rank}}</td>
                <td>{{sales.type_name}}</td>
                <td>{{sales.ABC}}</td>
              </tr>
              
            </tbody>
          </table>
          
          <table #printpurchaseOrder *ngIf="reportType == 'sum_of_purchaseOrder'" id="printpurchaseOrder"
            style="font-size: 12px; width: 95%;">
            <thead>
              <tr>
                <th>S.no</th>
                <th>Pharmacy ID</th>
                <th>Date</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let sales of salesList">
                <td style="padding: 0px 0px;width:1px">{{sales.sno}}</td>
                <td style="padding: 0px 0px;width:1px">{{sales.po_id}}</td>
                <td style="padding: 0px 0px;width:1px">{{sales.po_date}}</td>
                <td style="padding: 0px 0px;width:1px;text-align: right;">{{sales.amount}}</td>
              </tr>
            </tbody>
          </table>

          <table #printproduct id="printproduct" *ngIf="reportType == 'product'" style="font-size: 12px; width: 95%;">
            <thead>
              <tr>
                <th>S.no</th>
                <th>Product Name</th>
                <th>Quantity</th>
                <th>Sales Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let sales of salesList">
                <td style="padding: 0px 0px;width:1px">{{sales.sno}}</td>
                <td class="align_left" style="padding: 0px 0px;width:1px">{{sales.product_name}}</td>
                <td style="padding: 0px 0px;width:1px">{{sales.quantity}}</td>
                <td style="padding: 0px 0px;width:1px; text-align: right;">{{sales.sales_amount}}</td>
              </tr>
            </tbody>
          </table>

          <table #printproductrec *ngIf="reportType == 'product_receivables'" id="printproductrec"
            style="font-size: 12px; width: 95%;">
            <thead>
              <tr>
                <th>S.no</th>
                <th>Product Name</th>
                <th>Invoice Number</th>
                <th>Batch Number</th>
                <th>Quantity</th>
                <th>Free Quantity</th>
                <th>Received Date</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let sales of salesList">
                <td>{{sales.sno}}</td>
                <td>{{sales.product_name}}</td>
                <td>{{sales.invoice_no}}</td>
                <td>{{sales.batch_no}}</td>
                <td>{{sales.quantity}}</td>
                <td>{{sales.free_quantity}}</td>
                <td>{{sales.received_date}}</td>
              </tr>
            </tbody>
          </table>

          <table #printlabrepo *ngIf="reportType == 'lab_test_report'" id="printlabrepo"
            style="font-size: 12px; width: 95%;">
            <thead>
              <tr>
                <th>S.no</th>
                <th>Billing Id</th>
                <th>Billing Date</th>
                <th>Bill Amount</th>
                <th>Test Name</th>
                <th>Test Type</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let sales of salesList">
                <td>{{sales.sno}}</td>
                <td>{{sales.billingid}}</td>
                <td>{{sales.billingdate}}</td>
                <td>{{sales.amount}}</td>
                <td>{{sales.testname}}</td>
                <td>{{sales.testtype}}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <table #printabc *ngIf="reportType == 'abc_analysis' && salesList.length !=0" id="printabc" style="font-size: 14px; width: 100%;">
            <tr>
              <td>
                <b>The ABC analysis method categorizes items into three groups:</b>
                <ul>
                  <Li>A-Items: These are high-priority items with the highest sales value or profitability but low on volume (around 10 - 20% of inventory).</Li>
                  <Li>B-Items: These are moderate-priority items with a moderate sales value and moderate in volume as well (around 30% of inventory).</Li>
                  <Li>C-Items: These are low-priority items with the lowest sales value but high in volume (around 50% of inventory).</Li>
                </ul>
              </td>
            </tr>
          </table>
        </div>

        <div class="table-responsive cover_div"
          [ngStyle]="{'width': applystyle ? '100%' : '50%','height': applyheight ? '65vh':'65vh'}"
          *ngIf="reportType == 'CR' && consoldatedreport">


          <!------added by christo------->
          <div class="accordion" id="accordionExample" *ngIf="consoldatedreport">

            <div class="accordion-item" *ngIf="salesList.length != 0">
              <h2 class="accordion-header" id="headingOne">
                <span class="reportsheanding">Hospital Bill Report</span>
                <button class="accordion-button collapsed" style="background: inherit;" type="button"
                  data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false"
                  aria-controls="collapseOne" (click)="conbillstyle()">

                  <div class="accordinamountcard">

                    <div class="row">


                      <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 my-auto" *ngIf="reportType == 'CR'">
                        <mat-label #printCRHBRtotal id="printCRHBRtotal" class="matlabel" style="margin-top: 7px;"
                          *ngIf="doctotalAmount == 'NaN' ? '0.00' : doctotalAmount"><span
                            style="font-weight: 600;">Total : </span> {{doctotalAmount}}
                        </mat-label>
                      </div>
                      <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 my-auto" *ngIf="reportType == 'CR'">
                        <mat-label #printCRHBRcash id="printCRHBRcash" class="matlabel" style="margin-top: 7px;"
                          *ngIf="doccashAmount == 'NaN' ? '0.00' : doccashAmount"><span style="font-weight: 600;">Cash :
                          </span>
                          {{doccashAmount}}
                        </mat-label>
                      </div>
                      <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 my-auto" *ngIf="reportType == 'CR'">
                        <mat-label #printCRHBRcard id="printCRHBRcard" class="matlabel" style="margin-top: 7px;"
                          *ngIf="doccardAmount == 'NaN' ? '0.00' : doccardAmount"><span style="font-weight: 600;">Card :
                          </span>
                          {{doccardAmount}}
                        </mat-label>
                      </div>
                      <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 my-auto" *ngIf="reportType == 'CR'">
                        <mat-label #printCRHBRinsurance id="printCRHBRinsurance" class="matlabel"
                          style="margin-top: 7px;" *ngIf="docinsureAmount == 'NaN' ? '0.00' : docinsureAmount"><span
                            style="font-weight: 600;">Insurance :
                          </span> {{docinsureAmount}}
                        </mat-label>
                      </div>
                      <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 my-auto" *ngIf="reportType == 'CR'">
                        <mat-label #printCRHBRupi id="printCRHBRupi" class="matlabel" style="margin-top: 7px;"
                          *ngIf="docupiAmount == 'NaN' ? '0.00' : docupiAmount"><span
                            style="font-weight: 600;">UPI:</span>
                          {{docupiAmount}}
                        </mat-label>
                      </div>
                    </div>
                  </div>


                </button>

              </h2>

              <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne"
                data-bs-parent="#accordionExample" [ngClass]="{'success': isSuccessful, 'error': !isSuccessful}">
                <div class="accordion-body">
                  <table #printCRHBR id="printCRHBR" *ngIf="reportType == 'CR'" style="font-size: 12px;">
                    <thead>
                      <tr>
                        <th>S.no</th>
                        <th style="width:75px">Bill No</th>
                        <th style="width:125px">Created By</th>
                        <th style="width:125px;text-align: left;">Created Date</th>
                        <th>Paitent Name</th>
                        <th *ngIf=" this.Filter_by_Id == 'Doctor' ">Doctor</th>
                        <th *ngIf="this.Filter_by_Id == 'Speciality'">Speciality</th>
                        <th *ngIf="this.Filter_by_Id == 'Service'">Charge</th>

                        <th>Bill Amt</th>
                        <th>Paid</th>
                        <th>Cash</th>
                        <th>Card</th>
                        <th>UPI</th>

                        <th>Insurance</th>
                        <th>Due/Credit</th>

                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let sales of salesList">
                        <td>{{sales.sno}}</td>
                        <td style="width:75px">{{sales.billing_id}}</td>
                        <td class="align_left" style="width:150px">{{sales.user_name}}</td>
                        <td style="width:125px;text-align: left;">{{sales.created_date}}</td>

                        <td class="align_left">{{sales.patient_name}}</td>
                        <td class="align_left">{{sales.doc_name}}</td>

                        <td>{{sales.bill_amount}}</td>
                        <td>{{sales.paid_amount}}</td>
                        <td>{{sales.cash}}</td>
                        <td>{{sales.card}}</td>
                        <td>{{sales.upi}}</td>

                        <td>{{sales.insurance}}</td>
                        <td>{{sales.balance}}</td>
                        <td>{{sales.paid}}</td>
                      </tr>
                    </tbody>
                  </table>
                  <table #printCRHBRusertable id="printCRHBRusertable" *ngIf="reportType == 'CR'"
                    style="margin-top: 20px;font-size: 12px;position: relative">
                    <thead>
                      <tr>
                        <td>S.No</td>
                        <th>User Name</th>
                        <th>Bill</th>
                        <th> Cash</th>
                        <th>Card</th>
                        <th>UPI</th>
                        <th>Cheque</th>
                        <th>Insurance</th>
                        <th>Due/Credit</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let user of docuserdataList">
                        <td>{{user.sno}}</td>
                        <td>{{user.username}}</td>
                        <td>{{user.bill}}</td>
                        <td>{{user.cash}}</td>
                        <td>{{user.card}}</td>
                        <td>{{user.upi}}</td>
                        <td>{{user.check}}</td>
                        <td>{{user.insurance}}</td>
                        <td>{{user.balance}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="accordion-item" *ngIf="diagnosissalesList.length != 0">
              <h2 class="accordion-header" id="headingTwo">
                <span class="reportsheanding">Diagnosis Bill Report</span>
                <button class="accordion-button collapsed" style="background: inherit;" type="button"
                  data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false"
                  aria-controls="collapseTwo" (click)="conbillstyle()">

                  <div class="accordinamountcard">
                    <div class="row">
                      <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 my-auto" *ngIf="reportType == 'CR'">
                        <mat-label class="matlabel" #printCRDBRtotal id="printCRDBRtotal" style="margin-top: 7px;"
                          *ngIf="diagtotalAmount == 'NaN' ? '0.00' : diagtotalAmount"><span
                            style="font-weight: 600;">Total : </span> {{diagtotalAmount}}
                        </mat-label>
                      </div>
                      <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 my-auto" *ngIf="reportType == 'CR'">
                        <mat-label class="matlabel" #printCRDBRcash id="printCRHBRcash" style="margin-top: 7px;"
                          *ngIf="diagcashAmount == 'NaN' ? '0.00' : diagcashAmount"><span style="font-weight: 600;">Cash
                            : </span>
                          {{diagcashAmount}}
                        </mat-label>
                      </div>
                      <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 my-auto" *ngIf="reportType == 'CR'">
                        <mat-label class="matlabel" #printCRDBRcard id="printCRHBRcard" style="margin-top: 7px;"
                          *ngIf="diagcardAmount == 'NaN' ? '0.00' : diagcardAmount"><span style="font-weight: 600;">Card
                            : </span>
                          {{diagcardAmount}}
                        </mat-label>
                      </div>
                      <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 my-auto" *ngIf="reportType == 'CR'">
                        <mat-label class="matlabel" #printCRDBRinsurance id="printCRHBRinsurance"
                          style="margin-top: 7px;" *ngIf="diaginsureAmount == 'NaN' ? '0.00' : diaginsureAmount"><span
                            style="font-weight: 600;">Insurance :
                          </span> {{diaginsureAmount}}
                        </mat-label>
                      </div>
                      <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 my-auto" *ngIf="reportType == 'CR'">
                        <mat-label class="matlabel" #printCRDBRupi id="printCRHBRupi" style="margin-top: 7px;"
                          *ngIf="diagupiAmount == 'NaN' ? '0.00' : diagupiAmount"><span
                            style="font-weight: 600;">UPI:</span>
                          {{diagupiAmount}}
                        </mat-label>
                      </div>
                    </div>
                  </div>
                </button>
              </h2>
              <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample" [ngClass]="{'success': isSuccessful, 'error': !isSuccessful}">
                <div class="accordion-body">
                  <table *ngIf="reportType == 'CR'" style="font-size: 12px" #printCRDBR id="printCRDBR">
                    <thead>
                      <tr>
                        <th>S.no</th>
                        <th style="width:75px">Bill No</th>
                        <th style="width:150px">Created By</th>
                        <th style="width:125px">Created Date</th>
                        <th>In/Out Patient</th>
                        <th>Paitent Name</th>
                        <th>Advance</th>
                        <th>Balance</th>
                        <th style="width: 100px;">Bill Amt</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let sales of diagnosissalesList">
                        <td>{{sales.sno}}</td>
                        <td style="width:75px">{{sales.billing_id}}</td>
                        <td class="align_left" style="width:100px">{{sales.user_name}}</td>
                        <td style="width:125px">{{sales.created_date}}</td>
                        <td>{{sales.patient_type}}</td>
                        <td class="align_left">{{sales.patient_name}}</td>
                        <td>{{sales.advance}}</td>
                        <td>{{sales.balance}}</td>
                        <td style="width: 75px;">{{sales.bill_amount}}</td>
                        <td>{{sales.paid}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="accordion-item" *ngIf="pharmasalesList.length != 0">
              <h2 class="accordion-header" id="headingThree">
                <span class="reportsheanding">Pharma Sales Report</span>
                <button class="accordion-button collapsed" style="background: inherit;" type="button"
                  data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false"
                  aria-controls="collapseThree" (click)="conbillstyle()">
                  <div class="accordinamountcard">
                    <div class="row">
                      <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 my-auto">
                        <mat-label class="matlabel" style="margin-top: 7px;" #printpharmatotal id="printpharmatotal"
                          *ngIf="pharmatotalAmount == 'NaN' ? '0.00' : pharmatotalAmount">
                          <span style="font-weight: 600;">Amount : </span> {{pharmatotalAmount}}
                        </mat-label>
                      </div>
                      <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 my-auto">
                        <mat-label class="matlabel" style="margin-top: 7px;"
                          *ngIf="pharmacashAmount == 'NaN' ? '0.00' : pharmacashAmount" #printpharmacash
                          id="printpharmacash"><span style="font-weight: 600;">Cash : </span>
                          {{pharmacashAmount}}
                        </mat-label>
                      </div>
                      <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 my-auto">
                        <mat-label class="matlabel" style="margin-top: 7px;"
                          *ngIf="pharmacardAmount == 'NaN' ? '0.00' : pharmacardAmount" #printpharmacard
                          id="printpharmacard"><span style="font-weight: 600;">Card : </span>
                          {{pharmacardAmount}}
                        </mat-label>
                      </div>
                      <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 my-auto">
                        <mat-label class="matlabel" style="margin-top: 7px;"
                          *ngIf="pharmainsureAmount == 'NaN' ? '0.00' : pharmainsureAmount" #printpharmainsurance
                          id="  printpharmainsurance"><span style="font-weight: 600;">Insurance :
                          </span> {{pharmainsureAmount}}
                        </mat-label>
                      </div>

                      <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 my-auto">
                        <mat-label class="matlabel" style="margin-top: 7px;"
                          *ngIf="pharmaupiAmount == 'NaN' ? '0.00' : pharmaupiAmount" #printpharmaupi
                          id="printpharmaupi"><span style="font-weight: 600;">UPI:</span>
                          {{pharmaupiAmount}}
                        </mat-label>
                      </div>
                    </div>
                  </div>
                </button>
              </h2>
              <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                data-bs-parent="#accordionExample" [ngClass]="{'success': isSuccessful, 'error': !isSuccessful}">
                <div class="accordion-body">
                  <table style="font-size: 12px;margin-left: 5px;width: 155vh;" id="printpharma" #printpharma>
                    <thead>
                      <tr>
                        <th style="width:100px">Bill no</th>
                        <th style="width:140px;text-align: left;">Buyer</th>
                        <th style="width:140px;text-align: left;">Created by</th>
                        <th style="width:140px;">Purchase type</th>
                        <th>Amount</th>
                        <th style="width:150px">Deliver To</th>
                        <th>Bill date</th>
                        <th>Balance</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let sales of pharmasalesList">
                        <td style="width:100px">{{sales.order_id}}</td>
                        <td class="align_left" style="width:140px">{{sales.name}}</td>
                        <td class="align_left" style="width:140px">{{sales.created_by}}</td>
                        <td>{{sales.order_type}}</td>
                        <td style="text-align: right;">{{sales.amount}}</td>
                        <td style="width:150px">{{sales.deliver_to}}</td>
                        <td style="width:100px">{{sales.order_date}}</td>
                        <td style="text-align: right;">{{sales.balance}}</td>
                        <td style="width:100px">{{sales.paid}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

          </div>
          <!-----added by christo--------->

          <!-- mat accordin -->

        </div>


      </mdb-card-body>
    </mdb-card>
  </div>
</div>