<div class="container-fluid g-0" style="height: 100%;"> 
  <div class="warpper_div">
    <div class="asied_section" #asied_section_width>
      <section id="aside_section">
        <div class="logopanel" id="logo" #logo>
          <h1>
            <a (click)="menuNav('doctorAppList');"></a>
          </h1>
        </div>
        <div class="scrollbar">
          <div class="scrollbar-inner">
            <div class="menu_list">
              <div [hidden]="patienregFlag" class="menu" id="patreg" (click)="menuNav('patreg');">
                <div class="menu_icon">
                  <i class="icon-menu">
                    <img src="../../../assets/images/admin_nav/Patient_registration.svg" />
                  </i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Patient registration</span>
                  <span style="float: right;padding-right: 7px;">
                    <i class="fa fa-angle-right"></i>
                  </span>
                </div>
              </div>

              <div [hidden]="patientListFlag" class="menu" id="patient_list" (click)="menuNav('patient_list');">
                <div class="menu_icon"> 
                  <i class="icon-menu">
                    <img class="subiconmain" src="../../../assets/images/admin_nav/patient.svg" />
                  </i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Patient list</span>
                  <span style="float: right;padding-right: 7px;">
                    <i class="fa fa-angle-right"></i>
                  </span>
                </div>
              </div>

              <div class="menu" [hidden]="manageAppointmentFlag || (loginType != 'front-desk' && loginType != 'nurse')" id="manageappointments"
                (click)="setDoctor('manageappointments');menuNav('manageappointments');">
                <div class="menu_icon"> 
                  <i class="icon-menu">
                    <img src="../../../assets/images/admin_nav/Manage_appointments.svg" />
                  </i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Manage appointments</span>
                  <span style="float: right;padding-right: 7px;">
                    <i class="fa fa-angle-right"></i>
                  </span>
                </div>
              </div>
        
              <!-- <div class="menu" id="appointment" [hidden]="docAppointmentListFlag ||loginType != 'doctor'"
                (click)="menuNav('nurse_appointment');">
                <div class="menu_icon"> 
                  <i class="icon-menu">
                    <img src="../../assets/global/images/appointment.svg" />
                  </i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Appointments</span>
                  <span style="float: right;padding-right: 7px;">
                    <i class="fa fa-angle-right"></i>
                  </span>
                </div>
              </div> -->
              
              <div class="menu" id="doctorAppList" [hidden]="docAppointmentListFlag ||loginType != 'nurse'" (click)="menuNav('doctorAppList');">
                <div class="menu_icon"> 
                  <i class="icon-menu">
                    <img src="../../../assets/images/admin_nav/doctor_appointment.svg" />
                  </i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Doctor appointments</span>
                  <span style="float: right;padding-right: 7px;">
                    <i class="fa fa-angle-right"></i>
                  </span>
                </div>
              </div>
             
              <div class="menu" id="appointment_list" (click)="setAppoinmentHelper('appinment');menuNav('appointment_list');"
                [hidden]="docAppointmentListFlag || loginType != 'doctor'">
                <!-- need to change -->
                <div class="menu_icon">
                  <i class="icon-menu">
                    <img class="subiconmain" src=" ../../../assets/images/admin_nav/doctor_appointment.svg" />
                  </i>
                </div>
                <div class="menu_title" *ngIf="!minimized_asied  && minimized_asied !== undefined">
                  <span>
                    Appointments
                  </span>
                  <span style="float: right;padding-right: 7px;">
                    <i class="fa fa-angle-right"></i>
                  </span>
                </div>
              </div>
             
              <!-- <div class="menu" id="appointment" [hidden]="diagAppointmentFlag" (click)="menuNav('diag_appointment');">
                <div class="menu_icon"> 
                  <i class="icon-menu">
                    <img class="subiconmain" src="../../../assets/images/admin_nav/doctor_appointment.svg" />
                  </i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsiedFlag  && minimizedAsiedFlag !== undefined">
                  <span>
                    Diagnosis Appointments
                  </span>
                  <span style="float: right;padding-right: 7px;">
                    <i class="fa fa-angle-right"></i>
                  </span>
                </div>
              </div> -->
              
              <div class="menu" [hidden]="new_dietappointmentflag" id="diet_appointment" (click)="menuNav('diet_appointment');">
                <div class="menu_icon"> 
                  <i class="icon-menu">
                    <img src="../../assets/global/images/appointment.svg" />
                  </i>
                </div>
                <div class="menu_title" *ngIf="!minimized_asied  && minimized_asied !== undefined">
                  <span>Diet Appointments</span>
                  <span style="float: right;padding-right: 7px;">
                    <i class="fa fa-angle-right"></i>
                  </span>
                </div>
              </div>
              
              <!-- <div class="menu" id="manageappointments" [hidden]="manageAppointmentFlag"
                      (click)="menuNav('diet_manageappointments');">
                    <div class="menu_icon"> 
                      <i class="icon-menu">
                        <img src="../../assets/global/images/doctor.svg" />
                      </i>
                    </div>
                    <div class="menu_title" *ngIf="!minimized_asied  && minimized_asied !== undefined"> 
                      <span>Doctor appointments</span>
                    </div>
                  </div> -->
              
              <div [hidden]="inpatient_flag" class="menu" id="inpatient"
                (click)="setAppoinmentHelper('inpatient');menuNav('inpatient');">
                <div class="menu_icon">
                  <i class="icon-menu">
                    <img src="../../assets/global/images/doctor.svg" />
                  </i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Inpatient</span>
                  <span style="float: right;padding-right: 7px;">
                    <i class="fa fa-angle-right"></i> 
                  </span>
                </div>
                <ul *ngIf="inpatientFlag" class="nav-children">
                  <li [hidden]="inpatientMenuFlag" (click)="menuNav('Patients');setReport('Patients');">
                    <a id="nav-a">
                      <img class="subiconi" src="../../../assets/images/admin_nav/patient.svg" />
                      <span class="sub-nav-icon">Patients</span>
                    </a>
                  </li>
                  <li [hidden]="inpatientDoctorMenuFlag" (click)="menuNav('in_patient');setReport('in_patient');">
                    <a id="nav-a">
                      <img class="subiconi" src="../../../assets/images/admin_nav/patient.svg" />
                      <span class="sub-nav-icon">Patients</span>
                    </a>
                  </li>
                  <li [hidden]="docDashboard" (click)="menuNav('patient_dashboard');" class="mb-1">
                    <a id="nav-a">
                      <i class="fa-solid fa-download subiconi"></i> 
                      <span class="sub-nav-icon">Dashboard</span>
                    </a>
                  </li>
                  <li [hidden]="dischargeListfra" (click)="menuNav('dischargeList');">
                    <a id="nav-a">
                      <img class="subiconi" src="../../../assets/images/admin_nav/discharge_list.svg" />
                      <span class="sub-nav-icon">Discharge list</span>
                    </a>
                  </li>
                  <li [hidden]="nurseMedicine" (click)="menuNav('medicineTracker');">
                    <a id="nav-a"> 
                      <img class="subiconi" src="../../../assets/images/admin_nav/tat_pharmacy.svg" />
                      <span class="sub-nav-icon">Tracker</span>
                    </a>
                  </li>
                  <li [hidden]="Otbookingfra" (click)="menuNav('OTBooking');">
                    <a id="nav-a">
                      <img class="subiconi" src="../../../assets/images/admin_nav/OT_booking.svg" /> 
                      <span class="sub-nav-icon">OT booking</span>
                    </a>
                  </li>
                  <li [hidden]="OtProcedurefra" (click)="menuNav('OTProcedure');">
                    <a id="nav-a">
                      <img class="subiconi" src="../../../assets/images/admin_nav/OT_procedure.svg" />
                      <span class="sub-nav-icon">OT procedure</span>
                    </a>
                  </li>
                  <li [hidden]="WardTransferFlag" (click)="menuNav('wardTransfer');setReport('wardTransfer')">
                    <a id="nav-a">
                      <img class="subiconi" src="../../../assets/images/admin_nav/Ward_transfer.svg" />
                      <span class="sub-nav-icon">Ward transfer</span>
                    </a>
                  </li>
                </ul>
              </div>
              
              <div [hidden]="docWalkinFlag" class="menu" id="walkin" (click)="menuNav('walkin');">
                <div class="menu_icon"> 
                  <i class="icon-menu">
                    <img class="subiconmain" src=" ../../../assets/images/admin_nav/walk-in.svg" />
                  </i>
                </div>
                <div class="menu_title" *ngIf="!minimized_asied  && minimized_asied !== undefined">
                  <span>Walk-in</span>
                  <span style="float: right;padding-right: 7px;">
                    <i class="fa fa-angle-right"></i>
                  </span>
                </div>
              </div>
              
              <div class="menu" id="prescriptionlist"
                (click)="setDoctor('prescriptionlist');menuNav('prescriptionlist');" [hidden]="viewPrescritionFlag">
                <div class="menu_icon">
                  <img class="subiconmain" src="../../../assets/images/admin_nav/prescription.svg" />
                </div>
                <div class="menu_title" *ngIf="!minimized_asied  && minimized_asied !== undefined">
                  <span>Prescriptions</span>
                  <span style="float: right;padding-right: 7px;">
                    <i class="fa fa-angle-right"></i>
                  </span>
                </div>
              </div>
              
              <div [hidden]="permissionsFlag" class="menu" id="permission" (click)="menuNav('permissionF')">
                <div class="menu_icon">
                  <i class="icon-menu">
                    <img class="subiconmain" src="../../../assets/images/admin_nav/permissions.svg" />
                  </i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Permission</span>
                  <span style="float: right;padding-right: 7px;">
                    <i class="fa fa-angle-right"></i>
                  </span>
                </div>
                <ul *ngIf="permissionSubmenuFlag" class="nav-children">
                  <li [hidden]="new_nursewardflag" (click)="menuNav('nurse_ward');">
                    <a id="nav-a">
                      <img class="subiconi" src="../../../assets/images/admin_nav/Nurse_by_doctor.svg" />
                      <span class="sub-nav-icon">Nurse by ward</span>
                    </a>
                  </li>
                  <li [hidden]="new_nursepatientflag" (click)="menuNav('nurse_patient');">
                    <a>
                      <img class="subiconi" src="../../../assets/images/admin_nav/Nurse_by_patient.svg" />
                      <span class="sub-nav-icon">Nurse by patient</span>
                    </a>
                  </li>
                  <li [hidden]="new_nursedocflag" (click)="menuNav('nurse_doctor');">
                    <a>
                      <img class="subiconi" src="../../../assets/images/admin_nav/Nurse_by_ward.svg" />
                      <span class="sub-nav-icon">Nurse by doctor</span>
                    </a>
                  </li>
                </ul>
              </div>
              
              <div class="menu" id="secondopinion" (click)="menuNav('secondopinion');"
                [hidden]="docSecondopinionFlag">
                <div class="menu_icon">
                  <img class="subiconmain" src="../../../assets/images/admin_nav/second_opinion.svg" />
                </div>
                <div class="menu_title" *ngIf="!minimized_asied  && minimized_asied !== undefined">
                  <span>Second opinion</span>
                  <span style="float: right;padding-right: 7px;">
                    <i class="fa fa-angle-right"></i>
                  </span>
                </div>
              </div>
              
              <div [hidden]='otherExpenseFlag' class="menu" id="other_expenses" (click)="menuNav('other_expenses');">
                <div class="menu_icon"> 
                  <i class="icon-menu">
                    <img class="subiconmain" src="../../../assets/images/admin_nav/Partial_payments.svg" />
                  </i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Other expenses</span>
                  <span style="float: right;padding-right: 7px;"> 
                    <i class="fa fa-angle-right"></i>
                  </span>
                </div>
              </div>
              
              <div [hidden]="wardMasterviewFlag" class="menu" id="warddetails" (click)="menuNav('warddetails');">
                <div class="menu_icon"> 
                  <i class="icon-menu">
                    <img class="subiconmain" src="../../../assets/images/admin_nav/wards.svg" />
                  </i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Ward</span>
                  <span style="float: right;padding-right: 7px;"> 
                    <i class="fa fa-angle-right"></i>
                  </span>
                </div>
              </div>
             
              <div [hidden]="leadMgmtFlag" class="menu" id="leadmgmt" (click)="menuNav('leadmgmt');">
                <div class="menu_icon"> 
                  <i class="icon-menu">
                    <img class="subiconmain" src="../../../assets/images/admin_nav/lead_managment.svg" />
                  </i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Lead management</span>
                  <span style="float: right;padding-right: 7px;"> 
                    <i class="fa fa-angle-right"></i>
                  </span>
                </div>
              </div>
             
              <div class="menu" id="frontdeskbilling" [hidden]="billListFlag" (click)="menuNav('frontdeskbilling');">
                <div class="menu_icon"> 
                  <i class="icon-menu">
                    <img src=" ../../../assets/images/admin_nav/bill_list.svg" />
                  </i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Bill list</span>
                  <span style="float: right;padding-right: 7px;"> 
                    <i class="fa fa-angle-right"></i>
                  </span>
                </div>
              </div>
              
              <div class="menu" id="frontAdvances" [hidden]="advancesFlag" (click)="menuNav('frontAdvances');">
                <div class="menu_icon"> 
                  <i class="icon-menu">
                    <img src=" ../../../assets/images/admin_nav/advances.svg" />
                  </i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Advances</span>
                  <span style="float: right;padding-right: 7px;"> 
                    <i class="fa fa-angle-right"></i>
                  </span>
                </div>
              </div>
              
              <div class="menu" id="chargesTYPE" [hidden]="chargeFlag" (click)="menuNav('chargesTYPE');">
                <div class="menu_icon"> 
                  <i class="icon-menu">
                    <img src="../../../assets/images/admin_nav/charge_types.svg" />
                  </i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Service charges</span>
                  <span style="float: right;padding-right: 7px;"> 
                    <i class="fa fa-angle-right"></i>
                  </span>
                </div>
              </div>
             
              <div class="menu" id="Frontrefund" [hidden]="Bill_refundflag" (click)="menuNav('Frontrefund');">
                <div class="menu_icon"> 
                  <img class="subiconmain" src="../../../assets/images/admin_nav/bill_refund.svg" />
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Refunds</span>
                  <span style="float: right;padding-right: 7px;"> 
                    <i class="fa fa-angle-right"></i>
                  </span>
                </div>
              </div>
              
              <div [hidden]="consolidatedBillFlag" class="menu" id="frontAdminConsolidatedBills"
                (click)="menuNav('frontAdminConsolidatedBills');">
                <div class="menu_icon"> 
                  <i class="icon-menu">
                    <img class="subiconmain" src="../../../assets/images/admin_nav/consalidated_bills.svg" />
                  </i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Consolidated bills</span>
                  <span style="float: right;padding-right: 7px;"> 
                    <i class="fa fa-angle-right"></i>
                  </span>
                </div>
              </div>
              
              <div [hidden]="partialPaymentFlag" class="menu" id="partialPayments"
                (click)="menuNav('partialPayments');setAppFlow('diagnosis')">
                <div class="menu_icon"> 
                  <i class="icon-menu">
                    <img class="subiconmain" src="../../../assets/images/admin_nav/Partial_payments.svg" />
                  </i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Partial payments</span>
                  <span style="float: right;padding-right: 7px;"> 
                    <i class="fa fa-angle-right"></i>
                  </span>
                </div>
              </div>

              <div [hidden]="insuranceBillingFlag" class="menu" id="FrontAdmininsuranceBill"
                (click)="menuNav('FrontAdmininsuranceBill');">
                <div class="menu_icon">
                  <i class="icon-menu">
                    <img src="../../../assets/images/admin_nav/insurance_billing.svg" /> 
                  </i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Insurance bill</span>
                  <span style="float: right;padding-right: 7px;"> 
                    <i class="fa fa-angle-right"></i>
                  </span>
                </div>
              </div>
              
              <div [hidden]="consultantFlag" class="menu" id="consultant" (click)="menuNav('consultant');">
                <div class="menu_icon"> 
                  <i class="icon-menu">
                    <img class="subiconmain" src="../../../assets/images/admin_nav/consultant.svg" />
                  </i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Consultant</span>
                  <span style="float: right;padding-right: 7px;"> 
                    <i class="fa fa-angle-right"></i>
                  </span>
                </div>
              </div>
              
              <div [hidden]="doctor_referral" class="menu" id="docref" (click)="menuNav('docref');">
                <div class="menu_icon">
                  <i class="icon-menu">
                    <img class="subiconmain" src="../../../assets/images/admin_nav/referral.svg" />
                  </i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined"> 
                  <span>Doctor referral</span>
                  <span style="float: right;padding-right: 7px;"> 
                    <i class="fa fa-angle-right"></i>
                  </span>
                </div>
              </div>
              
              <div class="menu" id="samplecollectADV" [hidden]="specimen_coll_flag "
                (click)="menuNav('samplecollectADV');setReportType('laboratory');">
                <div class="menu_icon"> 
                  <img class="subiconmain" src="../../../assets/images/admin_nav/specimen_collection.svg" />
                </div>
                <div class="menu_title" *ngIf="!minimizedAsiedFlag  && minimizedAsiedFlag !== undefined">
                  <span>Specimen collection</span>
                  <span style="float: right;padding-right: 7px;"> 
                    <i class="fa fa-angle-right"></i>
                  </span>
                </div>
              </div>

              <div class="menu" id="assertion" [hidden]="specimenaccessionerFlag"
                (click)="menuNav('assertion'); setReportType('laboratory');">
                <div class="menu_icon"> 
                  <img class="subiconmain" src="../../../assets/images/admin_nav/specimen_accessioner.svg" />
                </div>
                <div class="menu_title" *ngIf="!minimizedAsiedFlag  && minimizedAsiedFlag !== undefined">
                  <span>Specimen assertion</span>
                  <span style="float: right;padding-right: 7px;"> 
                    <i class="fa fa-angle-right"></i>
                  </span>
                </div>
              </div>

              <div class="menu" id="path_report_upload" [hidden]="pathology_report_flag"
                (click)="menuNav('path_report_upload');">
                <div class="menu_icon">
                  <i class="icon-menu">
                    <img class="subiconmain" src="../../../assets/images/admin_nav/laboratory_report.svg" />
                  </i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsiedFlag  && minimizedAsiedFlag !== undefined">
                  <span>Laboratory report</span>
                  <span style="float: right;padding-right: 7px;"> 
                    <i class="fa fa-angle-right"></i>
                  </span>
                </div>
                <ul *ngIf="pathSubMenuFlag" class="nav-children">
                  <li (click)="menuNav('pathpending');setReportType('pathology');" [hidden]="path_rept_pending_flag" class="mb-1">
                    <a id="nav-a">
                      <img class="subiconi" src="../../../assets/images/admin_nav/pending.svg" />
                      <span class="sub-nav-icon">Pending</span>
                    </a>
                  </li>
                  <li (click)="menuNav('report_approval_pathology');setReportType('pathology');"
                    [hidden]="path_rept_approved_flag" class="mb-1">
                    <a id="nav-a">
                      <img class="subiconi" src="../../../assets/images/admin_nav/approval.svg" />
                      <span class="sub-nav-icon">Approval</span>
                    </a>
                  </li>
                  <li (click)="menuNav('pathcompleted');setReportType('pathology');" 
                    [hidden]="path_rept_completed_flag" class="mb-1">
                    <a>
                      <img class="subiconi" src="../../../assets/images/admin_nav/completed.svg" />
                      <span class="sub-nav-icon">Completed</span>
                    </a>
                  </li>
                </ul>
              </div>

              <div class="menu" id="radio_report_upload" [hidden]="radiology_report_flag"
                (click)="menuNav('radio_report_upload');">
                <div class="menu_icon">
                  <i class="icon-menu">
                    <img class="subiconmain" src="../../../assets/images/admin_nav/radiology_report.svg" />
                  </i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsiedFlag  && minimizedAsiedFlag !== undefined">
                  <span>Radiology report</span>
                  <span style="float: right;padding-right: 7px;"> 
                    <i class="fa fa-angle-right"></i>
                  </span>
                </div>
                <ul *ngIf="radioSubMenuFlag" class="nav-children">
                  <li (click)="menuNav('radio_samplecollectADV');setReportType('radiology');"
                    [hidden]="radiology_visit_flag" class="mb-1">
                    <a id="nav-a">
                      <img class="subiconi" src="../../../assets/images/admin_nav/Visits.svg" />
                      <span class="sub-nav-icon">Visits</span>
                    </a>
                  </li>
                  <li (click)="menuNav('radiopending');setReportType('radiology');" 
                    [hidden]="radio_rept_pending_flag" class="mb-1">
                    <a id="nav-a">
                      <img class="subiconi" src="../../../assets/images/admin_nav/pending.svg" />
                      <span class="sub-nav-icon">Pending</span>
                    </a>
                  </li>
                  <li (click)="menuNav('report_approval_radiology'); setReportType('radiology');"
                    [hidden]="radio_rept_approved_flag" class="mb-1">
                    <a>
                      <img class="subiconi" src="../../../assets/images/admin_nav/approval.svg" />
                      <span class="sub-nav-icon">Approval</span>
                    </a>
                  </li>
                  <li (click)="menuNav('radiocompleted');setReportType('radiology');"
                    [hidden]="radio_rept_completed_flag" class="mb-1">
                    <a>
                      <img class="subiconi" src="../../../assets/images/admin_nav/completed.svg" />
                      <span class="sub-nav-icon">Completed</span>
                    </a>
                  </li>
                </ul>
              </div>

              <div class="menu" id="micro_report_upload" [hidden]="microbiology_report_flag"
                (click)="menuNav('micro_report_upload');">
                <div class="menu_icon">
                  <i class="icon-menu">
                    <img class="subiconmain" src="../../../assets/images/admin_nav/Microbiology_report.svg" />
                  </i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsiedFlag  && minimizedAsiedFlag !== undefined">
                  <span>Microbiology report</span>
                  <span style="float: right;padding-right: 7px;"> 
                    <i class="fa fa-angle-right"></i>
                  </span>
                </div>
                <ul *ngIf="microSubMenuFlag" class="nav-children">
                  <li (click)="menuNav('micropending');setReportType('microbiology');"
                    [hidden]="micro_rept_pending_flag" class="mb-1">
                    <a id="nav-a">
                      <img class="subiconi" src="../../../assets/images/admin_nav/pending.svg" />
                      <span class="sub-nav-icon">Pending</span>
                    </a>
                  </li>
                  <li (click)="menuNav('report_approval_microbiology'); setReportType('microbiology');"
                    [hidden]="micro_rept_approved_flag" class="mb-1">
                    <a>
                      <img class="subiconi" src="../../../assets/images/admin_nav/approval.svg" />
                      <span class="sub-nav-icon">Approval</span>
                    </a>
                  </li>
                  <li (click)="menuNav('microcompleted');setReportType('microbiology');"
                    [hidden]="micro_rept_completed_flag" class="mb-1">
                    <a>
                      <img class="subiconi" src="../../../assets/images/admin_nav/completed.svg" />
                      <span class="sub-nav-icon">Completed</span>
                    </a>
                  </li>
                </ul>
              </div>

              <div class="menu" id="outsource" [hidden]="OutsourceFlag" (click)="menuNav('outsource');">
                <div class="menu_icon">
                  <i class="icon-menu">
                    <img class="subiconmain" src=" ../../../assets/images/admin_nav/out_source.svg" />
                  </i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsiedFlag  && minimizedAsiedFlag !== undefined">
                  <span>Outsource</span>
                  <span style="float: right;padding-right: 7px;"> 
                    <i class="fa fa-angle-right"></i>
                  </span>
                </div>
                <ul *ngIf="outsubFlag" class="nav-children">
                  <li (click)="menuNav('out-centre-list');" [hidden]="outsource_centre_flag" class="mb-1">
                    <a id="nav-a">
                      <img class="subiconi" src="../../../assets/images/admin_nav/centre.svg" />
                      <span class="sub-nav-icon">Centre</span>
                    </a>
                  </li>
                  <li (click)="menuNav('out-payments-adv');" [hidden]="outsource_payment_flag" class="mb-1">
                    <a>
                      <img class="subiconi" src="../../../assets/images/admin_nav/payments.svg" />
                      <span class="sub-nav-icon">Payments</span>
                    </a>
                  </li>
                  <li (click)="menuNav('out-tests');" [hidden]="outsource_test_flag" class="mb-1">
                    <a>
                      <img class="subiconi" src="../../../assets/images/admin_nav/tests.svg" />
                      <span class="sub-nav-icon">Tests</span>
                    </a>
                  </li>
                </ul>
              </div>

              <div [hidden]="diag_doctor_payments" class="menu" id="conductionDoc" (click)="menuNav('conductionDoc');setReport('doc_payment')">
                <div class="menu_icon"> 
                  <img class="subiconmain" src="../../../assets/images/admin_nav/specimen_accessioner.svg" />
                </div>
                <div class="menu_title" *ngIf="!minimizedAsiedFlag  && minimizedAsiedFlag !== undefined">
                  <span>Doctor payments</span>
                  <span style="float: right;padding-right: 7px;"> 
                    <i class="fa fa-angle-right"></i>
                  </span>
                </div>
              </div>

              <div class="menu" id="diabetics_readings" [hidden]="diab_reading_flag"
                (click)="menuNav('diabetics_readings');">
                <div class="menu_icon"> 
                  <i class="icon-menu">
                    <img class="subiconmain" src=" ../../../assets/images/admin_nav/diabetics_readings.svg" />
                  </i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsiedFlag  && minimizedAsiedFlag !== undefined">
                  <span>Diabetics readings</span>
                  <span style="float: right;padding-right: 7px;">
                    <i class="fa fa-angle-right"></i>
                  </span>
                </div>
              </div>

              <div class="menu" id="nephro_readings" [hidden]="nephro_reading_flag"
                (click)="menuNav('nephrology_readings');">
                <div class="menu_icon"> 
                  <i class="icon-menu">
                    <img class="subiconmain" src=" ../../../assets/images/admin_nav/diabetics_readings.svg" />
                  </i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsiedFlag  && minimizedAsiedFlag !== undefined">
                  <span>Nephro readings</span>
                  <span style="float: right;padding-right: 7px;">
                    <i class="fa fa-angle-right"></i>
                  </span>
                </div>
              </div>
              
              <div class="menu" id="appointment" [hidden]="nurseAssignments" (click)="menuNav('assignList');">
                <div class="menu_icon"> 
                  <i class="icon-menu">
                    <img class="subiconmain" src=" ../../../assets/images/admin_nav/Assignments.svg" />
                  </i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Assignments</span>
                </div>
              </div>

              <div class="menu" id="therapies" [hidden]="isAdmin" (click)="menuNav('therapies');">
                <div class="menu_icon"> 
                  <i class="icon-menu">
                    <img class="subiconmain" src="../../../assets/images/admin_nav/Therapies.svg" />
                  </i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Therapies</span>
                </div>
              </div>
             
              <div [hidden]="bill_est" id="billestimate" class="menu" (click)="menuNav('billestimate');">
                <div class="menu_icon"> 
                  <i class="icon-menu">
                    <img src="../../../assets/images/admin_nav/bill_estimate.svg" />
                  </i>
                </div>
                <div class="menu_title">
                  <span>Bill estimate</span>
                  <span style="float: right;padding-right: 7px;"> 
                    <i class="fa fa-angle-right"></i>
                  </span>
                </div>
              </div>

              <div [hidden]="manageOrders" class="menu" id="patient"
                (click)="menuNav('pharmacy_ordercreate');setValue()">
                <div class="menu_icon">
                  <i class="icon-menu"> 
                    <img src="../../../assets/images/admin_nav/order.svg" />
                  </i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied && minimizedAsied !== undefined">
                  <span>Orders</span>
                  <span style="float: right;padding-right: 7px;"> 
                    <i class="fa fa-angle-right"></i>
                  </span>
                </div>
                <ul *ngIf="submenuFlag" class="nav-children">
                  <li [hidden]="currentorder" (click)="menuNav('pharmacycurentinformation');setValue()">
                    <a id="nav-a">
                      <img class="subiconi" src="../../../assets/images/admin_nav/current_order.svg" />
                      <span class="sub-nav-icon">Current order</span>
                    </a>
                  </li>
                  <li [hidden]="pastorder" (click)="menuNav('pharmacypastinformation');setValue()">
                    <a> 
                      <img class="subiconi" src="../../../assets/images/admin_nav/past_order.svg" />
                      <span class="sub-nav-icon">Past order</span>
                    </a>
                  </li>
                  <li [hidden]="interdepart" (click)="menuNav('interdeptlist');setValue()">
                    <a>
                      <img class="subiconi" src="../../../assets/images/admin_nav/inter_department_transfer.svg" />
                      <span class="sub-nav-icon">Inter department transfer</span>
                    </a>
                  </li>
                </ul>
              </div>

              <div [hidden]="inwardFlag" class="menu" id="inward" (click)="menuNav('inward');setValue()">
                <div class="menu_icon">
                  <i class="icon-menu">
                    <img class="subiconmain" src="../../../assets/images/admin_nav/in_ward.svg" />
                  </i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Stock inwards</span>
                  <span style="float: right;padding-right: 7px;"> 
                    <i class="fa fa-angle-right"></i>
                  </span>
                </div>
              </div>

              <div class="menu" id="stockmanagment"
                (click)="menuNav('stockmanagment');" [hidden]="new_stock_management">
                <div class="menu_icon"> 
                  <i class="icon-menu">
                    <img src="../../../assets/images/admin_nav/stock_management.svg" /> 
                  </i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined"> 
                  <span>Stock management</span>
                  <span style="float: right;padding-right: 7px;"> 
                    <i class="fa fa-angle-right"></i>
                  </span> 
                </div>
                <ul *ngIf="submenuFlag1" class="nav-children">
                  <li [hidden]="manufacturer" (click)="menuNav('manufacturer');setValue()">
                    <a id="nav-a">
                      <img class="subiconi" src="../../../assets/images/admin_nav/manufacturen.svg" />
                      <span class="sub-nav-icon">Manufacturer</span>
                    </a>
                  </li>
                  <li [hidden]="stockorlevel" (click)="menuNav('srl');setValue()">
                    <a id="nav-a">
                      <img class="subiconi" src="../../../assets/images/admin_nav/reorder_level.svg" />
                      <span class="sub-nav-icon">Stock reorder level</span>
                    </a>
                  </li>
                  <li [hidden]="suppliers" (click)="menuNav('pharmacysupplier');setValue()">
                    <a id="nav-a">
                      <img class="subiconi" src="../../../assets/images/admin_nav/suppliers.svg" />
                      <span class="sub-nav-icon">Suppliers</span>
                    </a>
                  </li>
                  <li [hidden]="suppliersProduct" (click)="menuNav('supplierProduct');setValue();setReport('suppro')">
                    <a id="nav-a">
                      <img class="subiconi" src="../../../assets/images/admin_nav/suppliers.svg" />
                      <span class="sub-nav-icon">Suppliers product</span>
                    </a>
                  </li>
                  <li [hidden]="discount" (click)="menuNav('discounts');setValue()">
                    <a>
                      <img class="subiconi" src="../../../assets/images/admin_nav/discount.svg" />
                      <span class="sub-nav-icon">Discounts</span>
                    </a>
                  </li>
                  <li [hidden]="productMaster" (click)="menuNav('product');setValue()">
                    <a>
                      <img class="subiconi" src="../../../assets/images/admin_nav/Pharmacy_billing.svg" />
                      <span class="sub-nav-icon">Product</span>
                    </a>
                  </li>
                  <li [hidden]="purchaseRequest" (click)="menuNav('purchasereqlist');setValue()">
                    <a id="nav-a">
                      <img class="subiconi" src="../../../assets/images/admin_nav/product_request.svg" />
                      <span class="sub-nav-icon">Purchase request</span> 
                    </a>
                  </li>
                  <li [hidden]="purchaseOrder" (click)="menuNav('pharmacyresponse');setValue()">
                    <a>
                      <img class="subiconi" src="../../../assets/images/admin_nav/reorder_level.svg" />
                      <span class="sub-nav-icon">Purchase order</span>
                    </a>
                  </li>
                  <li [hidden]="inventoryReceivables" (click)="menuNav('inventory_receivables');setValue()">
                    <a>
                      <img class="subiconi" src="../../../assets/images/admin_nav/inventory_recevivables.svg" />
                      <span class="sub-nav-icon">Inventory receivables</span>
                    </a>
                  </li>
                  <li [hidden]="stockEdit" (click)="menuNav('stockStatusAdmin');setValue()">
                    <a id="nav-a">
                      <img class="subiconi" src="../../../assets/images/admin_nav/stock_edit.svg" />
                      <span class="sub-nav-icon">Stock edit</span>
                    </a>
                  </li>
                  <li [hidden]="new_stockreturns" (click)="menuNav('productReturns');setValue()">
                    <a>
                      <img class="subiconi" src="../../../assets/images/admin_nav/stock_returns.svg" />
                      <span class="sub-nav-icon">Stock returns</span>
                    </a>
                  </li>
                  <li [hidden]="suppaymentret" (click)="menuNav('supppay');setValue();setReport('sup_pay')">
                    <a>
                      <img class="subiconi" src="../../../assets/images/admin_nav/supplier_payment.svg" />
                      <span class="sub-nav-icon">Supplier payment</span>
                    </a>
                  </li>
                  <li [hidden]="stockadjustflag" (click)="menuNav('stockadjustment');setValue()">
                    <a>
                      <img src="../../../assets/images/admin_nav/stock_edit.svg" class="subiconi" />
                      <span class="sub-nav-icon">Stock adjustment</span>
                    </a>
                  </li>
                  <li [hidden]="ProductusageFlag" (click)="menuNav('departwise');setValue()">
                    <a>
                      <img src="../../../assets/images/admin_nav/stock_edit.svg" class="subiconi" />
                      <span class="sub-nav-icon">Product usage</span>
                    </a>
                  </li>
                  <li [hidden]="rackandbinflag" (click)="menuNav('rackbin');setValue()">
                    <a>
                      <img class="subiconi" src="../../../assets/images/admin_nav/bins.svg" />
                      <span class="sub-nav-icon">Racks and bins</span> 
                    </a>
                  </li>
                  <li [hidden]="consingment_flag" (click)="menuNav('consing');setValue()">
                    <a>
                      <img class="subiconi" src="../../../assets/images/admin_nav/bins.svg" />
                      <span class="sub-nav-icon">Consingment</span> 
                    </a>
                  </li>
                </ul>
              </div>

              <div class="menu" [hidden]="docHomecareFlag" id="homecare"
                (click)="menuNav('homecare');appointments('home');setAppoinmentHelper('homecare')">
                <div class="menu_icon"> 
                  <i class="icon-menu">
                    <img class="subiconmain" src="../../../assets/images/admin_nav/Homecare.svg" />
                  </i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Homecare</span>
                  <span style="float: right;padding-right: 7px;">
                    <i class="fa fa-angle-right"></i>
                  </span>
                </div>
              </div>
              
              <div [hidden]="docDoctorcalender" class="menu" id="schedule" (click)="menuNav('schedule');">
                <div class="menu_icon">
                  <img class="subiconmain" src="../../../assets/images/admin_nav/View_schedule.svg" />
                </div>
                <div class="menu_title" *ngIf="!minimized_asied  && minimized_asied !== undefined">
                  <span>Schedule</span>
                  <span style="float: right;padding-right: 7px;">
                    <i class="fa fa-angle-right"></i>
                  </span>
                </div>
                <ul *ngIf="docScheduleSubmenuFlag" class="nav-children">
                  <li [hidden]="schedulescalendar" (click)="menuNav('calender');" class="mb-1">
                    <a id="nav-a">
                      <img class="subiconi" src="../../../assets/images/admin_nav/My_calender.svg" />
                      <span class="sub-nav-icon">My calender </span>
                    </a>
                  </li>
                  <li [hidden]="schedulesavailability"
                    (click)="setAppoinmentHelper('doctor_availability');menuNav('doctor_availability');" class="mb-1">
                    <a> 
                      <img class="subiconi" src="../../../assets/images/admin_nav/Doctor_availability.svg" />
                      <span class="sub-nav-icon">Doctor availability</span>
                    </a>
                  </li>
                  <li [hidden]="schedulesviewschedules" (click)="menuNav('manage_schedule');" class="mb-1">
                    <a>
                      <img class="subiconi" src="../../../assets/images/admin_nav/View_schedule.svg" />
                      <span class="sub-nav-icon">View schedule</span>
                    </a>
                  </li>
                </ul>
              </div>

              <div class="menu" id="appointment" [hidden]="nursedoclist" (click)="menuNav('doctorList');">
                <div class="menu_icon"> 
                  <i class="icon-menu">
                    <img src="../../../assets/images/admin_nav/diabetics_readings.svg" />
                  </i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Doctor list</span>
                </div>
              </div>

              <div class="menu" id="DrugsName" (click)="menuNav('DrugsName');" [hidden]="FraMasters">
                <div class="menu_icon"> 
                  <i class="icon-menu">
                    <img class="subiconmain" src="../../../assets/images/admin_nav/master.svg" />
                  </i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Masters</span>
                  <span style="float: right;padding-right: 7px;"> 
                    <i class="fa fa-angle-right"></i>
                  </span>
                </div>
                <ul *ngIf="DrugsNameFlag" class="nav-children">
                  <li (click)="menuNav('wardmaster');setReport('CultureSensitivityDrugGroup');"
                    [hidden]="wardMasterFlag" class="mb-1">
                    <a id="nav-a">
                      <img class="subiconi" src=" ../../../assets/images/admin_nav/Ward_master.svg" />
                      <span class="sub-nav-icon">Ward master</span>
                    </a>
                  </li>
                  <li (click)="menuNav('OTmaster');setReport('CultureSensitivityDrugGroup');" 
                    [hidden]="Otmasterfra" class="mb-1">
                    <a id="nav-a">
                      <img class="subiconi" src="../../../assets/images/admin_nav/ot_master.svg" />
                      <span class="sub-nav-icon">OT master</span>
                    </a>
                  </li>
                  <li [hidden]="diag_testAndPack_flag" (click)="menuNav('testsandpackadv');" class="mb-1">
                    <a id="nav-a">
                      <img class="subiconi" src="../../../assets/images/admin_nav/lab_test_wise_report.svg" />
                      <span class="sub-nav-icon">Tests & profiles</span>
                    </a>
                  </li>
                  <li (click)="menuNav('DrugsNameType');setReport('CultureSensitivityDrugGroup');"
                    [hidden]="cult_sense_drugType_flag" class="mb-1">
                    <a id="nav-a">
                      <img class="subiconi" src="../../../assets/images/admin_nav/drug_group.svg" />
                      <span class="sub-nav-icon">Sensitive drug type</span>
                    </a>
                  </li>
                  <li (click)="menuNav('CultureSensitivityDrug');setReport('CultureSensitivityDrug');"
                    [hidden]="cult_sense_drugName_flag" class="mb-1">
                    <a>
                      <img class="subiconi" src="../../../assets/images/admin_nav/drug_group.svg" />
                      <span class="sub-nav-icon">Sensitive drug</span>
                    </a>
                  </li>
                  <li [hidden]="cult_path_flag" (click)="menuNav('CulturePathology');setReport('culturepathology');" class="mb-1">
                    <a>
                      <img class="subiconi" src="../../../assets/images/admin_nav/Test_tube.svg" />
                      <span class="sub-nav-icon">Culture pathogens</span>
                    </a>
                  </li>
                  <li (click)="menuNav('Sampletype');setReport('sampletype');" [hidden]="specimen_type_flag" class="mb-1">
                    <a>
                      <img src="../../../assets/images/admin_nav/specimen.svg" class="subiconi" />
                      <span class="sub-nav-icon">Specimen type</span>
                    </a>
                  </li>
                  <li (click)="menuNav('DiagnosticTube');setReport('Diagnostict');" [hidden]="DiagnosticTube" 
                    class="mb-1">
                    <a>
                      <img class="subiconi" src="../../../assets/images/admin_nav/Test_tube.svg" />
                      <span class="sub-nav-icon">Test tube type</span>
                    </a>
                  </li>
                  <li (click)="menuNav('DiagnosticReagent');setReport('DiagnosticR');" [hidden]="DiagnosticReagentflag"
                    class="mb-1">
                    <a>
                      <img class="subiconi" src="../../../assets/images/admin_nav/diabetics_readings.svg" />
                      <span class="sub-nav-icon">Diagnosis reagent</span>
                    </a>
                  </li>
                  <li [hidden]="Surgery_equipmentsFlag" (click)="menuNav('Surgery_equipments');setReport('Surgery_Equipments');" 
                    class="mb-1">
                    <a>
                      <img class="subiconi" src="../../../assets/images/admin_nav/surgery_product.svg" />
                      <span class="sub-nav-icon">Surgery products</span>
                    </a>
                  </li>
                  <li [hidden]="specimenaccessionerFlag" (click)="menuNav('specimenaccessioner');setReport('specimenaccessioner');" 
                    class="mb-1">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/specimen_accessioner.svg">
                      <span class="sub-nav-icon">Specimen assertion</span>
                    </a>
                  </li>
                  <li [hidden]="ConductionDoctorFlag" (click)="menuNav('ConductionDoctor');setReport('Conductiondoctor');" 
                    class="mb-1">
                    <a>
                      <img class="subiconi" src="../../../assets/images/admin_nav/Nurse_by_doctor.svg" />
                      <span class="sub-nav-icon">Conduction doctor</span>
                    </a>
                  </li>
                  <li (click)="menuNav('addmaster');setReport('ADmaster');" [hidden]="UserRoleFlag" class="mb-1">
                    <a>
                      <img src="../../../assets/images/admin_nav/Role.svg" class="subiconi" />
                      <span class="sub-nav-icon">Role</span>
                    </a>
                  </li>
                  <li (click)="menuNav('Stockexchange');setReport('stockar');" [hidden]="Stockexchangeflag"
                    class="mb-1">
                    <a>
                      <img src="../../../assets/images/admin_nav/Stock_adjustment _reason.svg" class="subiconi" />
                      <span class="sub-nav-icon">Stock adjustment reason</span>
                    </a>
                  </li>
                  <li (click)="menuNav('StoreName');setReport('StoreName');" [hidden]="StoreNameflag" class="mb-1">
                    <a>
                      <img class="subiconi" src="../../../assets/images/admin_nav/Store_name.svg" />
                      <span class="sub-nav-icon">Store name</span>
                    </a>
                  </li>
                  <li (click)="menuNav('GenericName');setReport('GM');" [hidden]="genericnameflag" class="mb-1">
                    <a>
                      <img class="subiconi" src="../../../assets/images/admin_nav/Generic_name.svg" />
                      <span class="sub-nav-icon">Generic name</span>
                    </a>
                  </li>
                  <li (click)="menuNav('ScheduleName');setReport('SM');" [hidden]="fraScheduleName" class="mb-1">
                    <a>
                      <img class="subiconi" src="../../../assets/images/admin_nav/Schedule.svg" />
                      <span class="sub-nav-icon">Schedule name</span>
                    </a>
                  </li>
                  <li [hidden]="ProductFormflag" (click)="menuNav('ProductForm');setReport('IFM');" class="mb-1">
                    <a>
                      <img src="../../../assets/images/admin_nav/product.svg" class="subiconi" />
                      <span class="sub-nav-icon">Product Form</span>
                    </a>
                  </li>
                  <li (click)="menuNav('Sub-Deparmentc');setReport('Sub-Deparment');" [hidden]="fraSubDeparment"
                    class="mb-1">
                    <a>
                      <img class="subiconi" src="../../../assets/images/admin_nav/sub_department.svg" />
                      <span class="sub-nav-icon">Sub-deparment</span>
                    </a>
                  </li>
                  <li (click)="menuNav('addtemplate');setReport('Addtemplate');" [hidden]="fraAddtemplate" class="mb-1">
                    <a>
                      <img class="subiconi" src="../../../assets/images/admin_nav/reports.svg" />
                      <span class="sub-nav-icon">Report template</span>
                    </a>
                  </li>
                  <li (click)="menuNav('gramstains');setReport('Gramstains');" [hidden]="gramstainsflag" class="mb-1">
                    <a>
                      <img class="subiconi" src="../../../assets/images/admin_nav/gram_stains.svg" />
                      <span class="sub-nav-icon">Gram stains</span>
                    </a>
                  </li>
                  <li (click)="menuNav('rateCard');setReport('RateCard');" [hidden]="RatecardFlag" class="mb-1">
                    <a><i class="fa-solid fa-paperclip subiconi"></i>
                      <span class="sub-nav-icon">Rate card </span></a>
                  </li>
                  <li (click)="menuNav('visitpurpose');setReport('Visitpurpose');" [hidden]="visitpurposeFlag"
                    class="mb-1">
                    <a>
                      <img class="subiconi" src="../../../assets/images/admin_nav/Visits.svg" />
                      <span class="sub-nav-icon">Visit purpose</span>
                    </a>
                  </li>
                  <li [hidden]="UserRoleFlag" (click)="menuNav('UserRole');setReport('UserRole');" class="mb-1">
                    <a>
                      <img class="subiconi" src="../../../assets/images/admin_nav/User_role_mapping.svg" />
                      <span class="sub-nav-icon">User role mapping</span>
                    </a>
                  </li>
                  <li [hidden]="weightageFlag" (click)="menuNav('weightflag')" class="mb-1">
                    <a>
                      <img class="subiconi" src="../../../assets/images/admin_nav/Nurse_by_doctor.svg" />
                      <span class="sub-nav-icon">Weightage</span></a>
                    </li>
                      <li [hidden]="surgdocFlag"
                      (click)="menuNav('surgery_doc');setReport('surgery_doc');" class="mb-1"><a><img
                          class="subiconi" src="../../../assets/images/admin_nav/Nurse_by_doctor.svg" />
                        <span class="sub-nav-icon">Surgery doctor payments</span></a></li>
                </ul>
              </div>
              
              <div [hidden]="reportsFlag" class="menu" id="reports" (click)="menuNav('reports');">
                <div class="menu_icon">
                  <i class="icon-menu">
                    <img class="subiconmain" src="../../../assets/images/admin_nav/reports.svg" />
                  </i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Reports</span>
                  <span style="float: right;padding-right: 7px;"> 
                    <i class="fa fa-angle-right"></i>
                  </span>
                </div>
                <ul *ngIf="reportsSubmenuFlag" class="nav-children">
                  <li [hidden]="hospBillsReportFlag" (click)="menuNav('hosp_bill_reports');;setReport('HBR');"
                    class="mb-1">
                    <a id="nav-a">
                      <img class="subiconi" src="../../../assets/images/admin_nav/hospital_bills_reports.svg" />
                      <span class="sub-nav-icon">User based bills report</span>
                    </a>
                  </li>
                  <li [hidden]="diagBillsReportFlag" (click)="menuNav('diag_bill_reports');;setReport('DBR');"
                    class="mb-1">
                    <a id="nav-a">
                      <img class="subiconi" src="../../../assets/images/admin_nav/diagnasis_bills_reports.svg" />
                      <span class="sub-nav-icon">Diagnosis bills reports</span>
                    </a>
                  </li>
                  <li [hidden]="salesreportflag" (click)="menuNav('sales_report');setValue();setReport('sales')">
                    <a id="nav-a">
                      <img class="subiconi" src="../../../assets/images/admin_nav/Pharma_sales report.svg" />
                      <span class="sub-nav-icon">Sales report</span>
                    </a>
                  </li>
                  <li [hidden]="pharmasalesGst" (click)="menuNav('salesGst');" class="mb-1">
                    <a>
                      <img class="subiconi" src="../../../assets/images/admin_nav/report_product.svg" />
                      <span class="sub-nav-icon">Sales report GST</span>
                    </a>
                  </li>
                  <li [hidden]="refund_reportflag" (click)="menuNav('bill_refund');setReport('Bill_refund');"
                    class="mb-1">
                    <a id="nav-a">
                      <img class="subiconi" src="../../../assets/images/admin_nav/bill_refund.svg" />
                      <span class="sub-nav-icon">Bill refund</span>
                    </a>
                  </li>
                  <li [hidden]="billDueFlag" (click)="menuNav('bill_due');;setReport('Bill_due');" class="mb-1">
                    <a id="nav-a">
                      <img class="subiconi" src="../../../assets/images/admin_nav/bill_due.svg" />
                      <span class="sub-nav-icon">Bill due</span>
                    </a>
                  </li>
                  <li [hidden]="productReceivablesFlag" (click)="menuNav('product_receivables');;setReport('product_receivables');" 
                    class="mb-1">
                    <a id="nav-a">
                      <i class="fa-solid fa-receipt subiconi"></i>
                      <span class="sub-nav-icon">Product receivables</span>
                    </a>
                  </li>
                  <li [hidden]="labtestFlag" (click)="menuNav('lab_test_wise');;setReport('lab_test_report');" class="mb-1">
                    <a id="nav-a">
                      <img class="subiconi" src="../../../assets/images/admin_nav/lab_test_wise_report.svg" />
                      <span class="sub-nav-icon">Lab test wise report</span>
                    </a>
                  </li>
                  <li [hidden]="notvistedpatreportflag" (click)="menuNav('notvisited_report');setReport('NVR');" class="mb-1">
                    <a id="nav-a">
                      <img class="subiconi" src="../../../assets/images/admin_nav/not_visited_patient.svg" />
                      <span class="sub-nav-icon">Not visited patient</span>
                    </a>
                  </li>
                  <li [hidden]="consolidatedreportflag" (click)="menuNav('consolidat_report');setReport('CR');" class="mb-1">
                    <a id="nav-a">
                      <img class="subiconi" src="../../../assets/images/admin_nav/reports.svg" />
                      <span class="sub-nav-icon">Consolidate report</span>
                    </a>
                  </li>
                  <li [hidden]="front_inpatientreport" (click)="menuNav('inPat_report');;setReport('IPR');" class="mb-1">
                    <a id="nav-a">
                      <img class="subiconi" src="../../../assets/images/admin_nav/reports.svg" />
                      <span class="sub-nav-icon">Inpatient report</span>
                    </a>
                  </li>
                  <li [hidden]="dayBookReportFlag" (click)="menuNav('Day_book');" class="mb-1">
                    <a id="nav-a">
                      <img class="subiconi" src="../../../assets/images/admin_nav/reports.svg" />
                      <span class="sub-nav-icon">Day book</span>
                    </a>
                  </li>
                  <li [hidden]="bill_refund_repo" (click)="menuNav('refund-report');" class="mb-1">
                    <a id="nav-a">
                      <img class="subiconi" src="../../../assets/images/admin_nav/reports.svg" />
                      <span class="sub-nav-icon">Refunds report</span>
                    </a>
                  </li>
                  <li [hidden]="Stockreports" (click)="setAppoinmentHelper('stockReport');menuNav('stockReport');" class="mb-1">
                    <a> 
                      <img class="subiconi" src="../../../assets/images/admin_nav/stock_returns.svg" />
                      <span class="sub-nav-icon">Stock reports</span>
                    </a>
                  </li>
                  <!-- <li [hidden]="receivalblesreportflag"
                    (click)="setAppoinmentHelper('receivablesReport');menuNav('receivablesReport');" class="mb-1"><a>
                      <img class="subiconi" src="../../../assets/images/admin_nav/receivables_reports.svg" /><span class="sub-nav-icon">Receivable
                        reports doctor</span></a>
                  </li> -->
                  <li [hidden]="front_stockstatusflag" (click)="menuNav('stock_status');setValue()">
                    <a id="nav-a">
                      <img class="subiconi" src="../../../assets/images/admin_nav/stock_management.svg" />
                      <span class="sub-nav-icon">Stock ledger</span>
                    </a>
                  </li>
                  <li [hidden]="frontpurchase_report" (click)="menuNav('purchase_report');setValue();setReport('purchase')">
                    <a>
                      <img class="subiconi" src="../../../assets/images/admin_nav/reports.svg" />
                      <span class="sub-nav-icon">Purchase report GST</span>
                    </a>
                  </li>
                  <li [hidden]="receivalblesreportflag" (click)="menuNav('receivables_report');setValue()">
                    <a>
                      <img class="subiconi" src="../../../assets/images/admin_nav/receivables_reports.svg" />
                      <span class="sub-nav-icon">Receivables report</span>
                    </a>
                  </li>
                  <li [hidden]="returnsreportflag" (click)="menuNav('productReturnsReport');setValue();setReport('returnsReport');">
                    <a>
                      <img class="subiconi" src="../../../assets/images/admin_nav/stock_returns.svg" /> 
                      <span class="sub-nav-icon">Returns report</span>
                    </a>
                  </li>
                  <li [hidden]="schdForDrugInspFlag" (click)="menuNav('gsd');setReport('gsd');" class="mb-1">
                    <a>
                      <img class="subiconi" src="../../../assets/images/admin_nav/stock_returns.svg" /> 
                      <span class="sub-nav-icon">Schedule register</span>
                    </a>
                  </li>
                  <li [hidden]="salesForDrugInspFlag" (click)="menuNav('SDI');setReport('SDI');" class="mb-1">
                    <a>
                      <img class="subiconi" src="../../../assets/images/admin_nav/stock_returns.svg" /> 
                      <span class="sub-nav-icon">Sales for drug inspector</span>
                    </a>
                  </li>
                  <li [hidden]="bedOccupancyFlag" (click)="menuNav('bed_occupancypharm');;setReport('bed_occupancy');"
                    class="mb-1">
                    <a id="nav-a"> 
                      <img class="subiconi" src="../../../assets/images/admin_nav/Bed_occupancy_ratio.svg" />
                      <span class="sub-nav-icon">Bed occupancy ratio</span>
                    </a>
                  </li>
                  <li [hidden]="abcAnalysisFlag" (click)="menuNav('abc_analysispharm');setReport('abc_analysis');"
                    class="mb-1">
                    <a id="nav-a">
                      <img class="subiconi" src="../../../assets/images/admin_nav/ABC_analysis.svg" />
                      <span class="sub-nav-icon">ABC analysis </span>
                    </a>
                  </li>
                  <li [hidden]="nurs_shortexpiryflag" (click)="menuNav('shrtexpiry')" class="mb-1">
                    <a id="nav-a">
                      <img class="subiconi" src="../../../assets/images/admin_nav/ABC_analysis.svg" />
                      <span class="sub-nav-icon">Short expiry </span>
                    </a>
                  </li>
                  <li [hidden]="inwardReportFlag" (click)="menuNav('pastinwards')" class="mb-1">
                    <a id="nav-a">
                      <img class="subiconi" src="../../../assets/images/admin_nav/ABC_analysis.svg" />
                      <span class="sub-nav-icon">Inwards report </span>
                    </a>
                  </li>
                  <li [hidden]="logreportFlag" (click)="menuNav('log_rep');setReport('bill_log')" class="mb-1">
                    <a id="nav-a"><img class="subiconi" src="../../../assets/images/admin_nav/reports.svg" /><span
                        class="sub-nav-icon">Billing log</span></a>
                  </li>
                  <li [hidden]="pharmalogreportFlag" (click)="menuNav('pharma_log_rep');setReport('pharma_log')" class="mb-1">
                    <a id="nav-a"><img class="subiconi" src="../../../assets/images/admin_nav/reports.svg" /><span
                        class="sub-nav-icon">Pharmacy log report</span></a>
                  </li>
                  <li [hidden]="DiaglogreportFlag" (click)="menuNav('diag_log_rep');setReport('diag_log')" class="mb-1">
                    <a id="nav-a"><img class="subiconi" src="../../../assets/images/admin_nav/reports.svg" /><span
                        class="sub-nav-icon">Diagnosis log report</span></a>
                  </li>
                  <li [hidden]="conductdocFlag" (click)="menuNav('cond_log_rep');setReport('cond_doc')" class="mb-1">
                    <a id="nav-a"><img class="subiconi" src="../../../assets/images/admin_nav/reports.svg" /><span
                        class="sub-nav-icon">Conduction doctor</span></a>
                  </li>
                  <li [hidden]="outsourcepayFlag" (click)="menuNav('out_log_rep');setReport('out_pay')" class="mb-1">
                    <a id="nav-a"><img class="subiconi" src="../../../assets/images/admin_nav/reports.svg" /><span
                        class="sub-nav-icon">Out-source payment</span></a>
                  </li>
                  
                  <li [hidden]="diagtestFlag"  (click)="menuNav('Diag_test');setReport('DTR');" class="mb-1">
                    <a id="nav-a"><img class="subiconi" src="../../../assets/images/admin_nav/ABC_analysis.svg" />
                      <span class="sub-nav-icon">Diagnosis test report</span>
                    </a>
                  </li>
                  <li [hidden]="DiagcountFlag" (click)="menuNav('Diag_count');setReport('DTCR');" class="mb-1">
                    <a id="nav-a"><img class="subiconi" src="../../../assets/images/admin_nav/ABC_analysis.svg" />
                      <span class="sub-nav-icon">Diagnosis test count</span>
                    </a>
                  </li>
                  <li [hidden]="RevenuerepoFlag" (click)="menuNav('revenue_rep');setReport('revenue');" class="mb-1">
                    <a id="nav-a"><img class="subiconi" src="../../../assets/images/admin_nav/ABC_analysis.svg" />
                      <span class="sub-nav-icon">Revenue report </span>
                    </a>
                  </li>
                  <li [hidden]="adjustreportFlag" (click)="menuNav('stock_adjust');setValue();setReport('stock_adjust');" class="mb-1">
                    <a id="nav-a"><img class="subiconi" src="../../../assets/images/admin_nav/ABC_analysis.svg" />
                      <span class="sub-nav-icon">Stock adjustment report</span>
                    </a>
                  </li>
                </ul>
              </div>

              <div [hidden]="TAT_report_flag" class="menu" id="tatreports" (click)="menuNav('tatreports');">
                <div class="menu_icon"> 
                  <i class="icon-menu">
                    <img class="nav-img" src="../../../assets/images/admin_nav/tat_report.svg" />
                  </i>
                </div>
                <div class="menu_title" *ngIf="!minimized_asied  && minimized_asied != undefined">
                  <span>TAT report</span>
                  <span style="float: right;padding-right: 7px;"> 
                    <i class="fa fa-angle-right"></i>
                  </span>
                </div>
                <ul *ngIf="tatsubmenuFlag1" class="nav-children">
                  <li [hidden]="hospitaltatflag" (click)="menuNav('hospitaltat');" class="mb-1">
                    <a>
                      <img class="subiconi" src="../../../assets/images/admin_nav/tat_hospital.svg" />
                      <span class="sub-nav-icon">Hospital TAT</span>
                    </a>
                  </li>
                  <li [hidden]="diagtatflag" (click)="menuNav('diagnosistat');" class="mb-1">
                    <a>
                      <img class="subiconi" src="../../../assets/images/admin_nav/tat_diagnosis.svg" />
                      <span class="sub-nav-icon">Diagnosis TAT</span>
                    </a>
                  </li>
                  <li [hidden]="pharmatatflag" (click)="menuNav('pharmasistat');" class="mb-1">
                    <a id="nav-a">
                      <img class="subiconi" src="../../../assets/images/admin_nav/tat_pharmacy.svg" />
                      <span class="sub-nav-icon">Pharma TAT</span>
                    </a>
                  </li>
                </ul>
              </div>
              
              <div [hidden]="accessRights_flag" class="menu" id="authorize_list" (click)="menuNav('authorize_list');">
                <div class="menu_icon"> 
                  <i class="icon-menu">
                    <img class="nav-img" src="../../../assets/images/admin_nav/access_and_authoriz.svg" />
                  </i>
                </div>
                <div class="menu_title" *ngIf="!minimized_asied  && minimized_asied !== undefined">
                  <span>Access & authorize</span>
                  <span style="float: right;padding-right: 7px;">
                    <i class="fa fa-angle-right"> </i>
                  </span>
                </div>
              </div>
              
              <div [hidden]="help" class="menu" id="help" (click)="menuNav('diag_help');">
                <div class="menu_icon"> 
                  <i class="icon-menu">
                    <img class="subiconmain" src="../../../assets/images/admin_nav/Help.svg" />
                  </i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsiedFlag  && minimizedAsiedFlag !== undefined">
                  <span>Help</span>
                  <span style="float: right;padding-right: 7px;"> 
                    <i class="fa fa-angle-right"></i>
                  </span>
                </div>
              </div>
              
              <div class="menu" id="reference_video" (click)="menuNav('reference_video');" [hidden]="reference_video">
                <div class="menu_icon"> 
                  <i class="icon-menu">
                    <img src="../../../assets/images/admin_nav/Reference_video.svg" />
                  </i>
                </div>
                <div class="menu_title" *ngIf="!minimized_asied && minimized_asied !== undefined">
                  <span>Reference video</span>
                  <span style="float: right;padding-right: 7px;"> 
                    <i class="fa fa-angle-right"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div class="right_content_section" #right_section_width>
      <section id="headar_section">
        <div class="header-left" style="position: relative;">
          <div class="topnav">
            <div class="hospital_logo">
              <img class="hospital_logo" src="{{hospitalLogo}}"
                onerror="this.src='../../../assets/img/default_hosp_logo.png';" alt="user image">
            </div>
            <div class="hospital_name">
              <span class="d-none d-sm-inline-block d-md-inline-block d-lg-inline-block d-xl-inline-block ">
                {{hospitalName}} - {{Usertype}}
              </span>
            </div>
            <div class="headerCover">
              <div class="bell"></div>
              <div class="share"></div>
              <div class="doctor_profile_pc" data-bs-toggle="modal" data-bs-target="#exampleModal">
                <img _ngcontent-ore-c119="" src="{{profileImage}}" onerror="this.src='./././assets/img/default.jpg'">
              </div>
              <div class="doctor_name" data-bs-toggle="modal" data-bs-target="#exampleModal">
                {{firstName}}&nbsp;{{lastName}}
              </div>
            </div>
            <!-- Modal -->
            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
              aria-hidden="true">
              <div class="modal-dialog profile_dialog">
                <div class="modal-content">
                  <div class="modal-header" style="display: inline-block;text-align: center">
                    <div class="modal-title" id="exampleModalLabel"><img class="model_profile" src="{{profileImage}}"
                        onerror="this.src='./././assets/img/default.jpg'"></div>
                  </div>
                  <div class="modal-body">
                    <div class="menu_div" (click)="menuModelAction('profile');"> <i class="far fa-user"></i> <span
                        class="header_model_menu">My profile</span> </div>
                    <div class="menu_div" (click)="menuModelAction('changePassword');"><i class="fas fa-cog"></i><span
                        class="header_model_menu">Change password</span></div>
                    <div class="menu_div" (click)="menuModelAction('logout');" style="border-bottom: none;"><i
                        class="fas fa-sign-out-alt"></i><span class="header_model_menu">Logout</span></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="body_section">
        <div class="body_warpper">
          <div class="card shadow-lg">
            <div [ngSwitch]="displayPage">
              <div *ngIf="loginType == 'Admin'">
                <div *ngSwitchDefault="'admin-home'">
                  <app-admin-home></app-admin-home>
                </div>
              </div>
              <div *ngIf="loginType == 'doctor'">
                <div *ngSwitchDefault="'dashboard'">
                  <app-doctorhome></app-doctorhome>
                </div>
              </div>
              <div *ngIf="loginType == 'front-desk'">
                <div *ngSwitchDefault="'manageappointments'">
                  <app-receptionist-mgmtapplist></app-receptionist-mgmtapplist>
                </div>
              </div>
              <div *ngIf="loginType == 'Billing'">
                <div *ngSwitchDefault="'front'">
                  <app-recept-bill-list></app-recept-bill-list>
                </div>
              </div>
              <div *ngIf="loginType == 'diagnosis'">
                <div *ngSwitchDefault="'diagnosis_home'">
                  <app-diagnosis-home></app-diagnosis-home>
                </div>
              </div>
              <div *ngIf="loginType == 'pharmacy'">
                <div *ngSwitchDefault="'billestimate'">
                  <app-bill-estimate></app-bill-estimate>
                </div>
              </div>
              <div *ngIf="loginType == 'nurse'">
                <div *ngSwitchDefault="'manageappointments'">
                  <app-receptionist-mgmtapplist></app-receptionist-mgmtapplist>
                </div>
              </div>
              <div *ngIf="loginType == 'physio'">
                <div *ngSwitchDefault="'Home'">
                  <app-physio-prof-dash></app-physio-prof-dash>
                </div>
              </div>
              <div *ngIf="loginType == 'dietician'">
                <div *ngSwitchDefault="'diet_dashboard'">
                  <app-doctorhome></app-doctorhome>
                </div>
              </div>
              <div *ngIf="loginType == 'OT admin'">
                <div *ngSwitchDefault="'OTBooking'">
                  <app-ot-booking></app-ot-booking>
                </div>
              </div>
              <div *ngIf="loginType == 'Accounts'">
                <div *ngSwitchDefault="'Day_book'">
                  <app-daybook></app-daybook>
                </div>
              </div>  
              <div *ngSwitchCase="'authorize_list'">
                <app-access-rights></app-access-rights>
              </div> 
              <div *ngSwitchCase="'docpresnocase'">
                <app-med-diag-pres-nocase></app-med-diag-pres-nocase>
              </div>
              <div [hidden]="doctorappointments" *ngSwitchCase="'appointment'">
                <app-nurse-doc-app-list></app-nurse-doc-app-list>
              </div>
              <div *ngSwitchCase="'nurse_appointment'">
                <app-nurse-appointments></app-nurse-appointments>
              </div>
              <div *ngSwitchCase="'appoinmentDetailView'">
                <app-nurse-appointment-detailspage></app-nurse-appointment-detailspage>
              </div>
              <div *ngSwitchCase="'assignList'">
                <app-nurse-assign-list></app-nurse-assign-list>
              </div>
              <div *ngSwitchCase="'assignDetailView'">
                <app-nurse-assign-view></app-nurse-assign-view>
              </div>
              <div *ngSwitchCase="'doctorAppList'">
                <app-nurse-doc-app-list></app-nurse-doc-app-list>
              </div>
              <div *ngSwitchCase="'doctorList'">
                <app-nurse-doc-list></app-nurse-doc-list>
              </div>
              <div *ngSwitchCase="'profile'">
                <app-nurse-profile></app-nurse-profile>
              </div>
              <div *ngSwitchCase="'inpatient'">
                <app-medicine-tracker></app-medicine-tracker>
              </div>
              <div *ngSwitchCase="'medicine_tracker'">
                <app-medicine-tracker></app-medicine-tracker>
              </div>
              <div *ngSwitchCase="'viewIpatient'">
                <app-inpat-patient-info></app-inpat-patient-info>
              </div>
              <div *ngSwitchCase="'DoctorAppDetail'">
                <app-nurse-doc-app-view></app-nurse-doc-app-view>
              </div>
              <div *ngSwitchCase="'casesheet'">
                <app-casesheet></app-casesheet>
              </div>
              <div *ngSwitchCase="'casesheet_list'">
                <app-casesheet-list></app-casesheet-list>
              </div>
              <div *ngSwitchCase="'cardio_casesheet_view'">
                <app-cardio-casesheet-view></app-cardio-casesheet-view>
              </div>
              <div *ngSwitchCase="'DrugsNameType'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'Sampletype'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'DiagnosticDepartment'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'DiagnosticTube'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'DiagnosticReagent'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'Stockexchange'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'StoreName'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'GenericName'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'ScheduleName'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'Sub-Deparmentc'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'addtemplate'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'gramstains'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'visitpurpose'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'rateCard'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'cultureSensitivityDrugName'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'UserRole'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'CultureSensitivityDrug'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'addmaster'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'CulturePathology'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'OTBooking'">
                <app-ot-booking></app-ot-booking>
              </div>
              <div *ngSwitchCase="'nursereports'">
                <app-report-nurse></app-report-nurse>
              </div>
              <div *ngSwitchCase="'dietTracker'">
                <app-diettrackernurse></app-diettrackernurse>
              </div>
              <div *ngSwitchCase="'medicineTracker'">
                <app-medicinetracker-nurse></app-medicinetracker-nurse>
              </div>
              <div *ngSwitchCase="'OTProcedure'">
                <app-ot-procedure></app-ot-procedure>
              </div>
              <div *ngSwitchCase="'Patients'">
                <app-fd-inpatient></app-fd-inpatient>
              </div>
              <div *ngSwitchCase="'wardtall'">
                <app-ward-details></app-ward-details>
              </div>
              <div *ngSwitchCase="'ConductionDoctor'">
                <app-diagnosismaster></app-diagnosismaster>
              </div>
              <div *ngSwitchCase="'specimenaccessioner'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'Surgery_equipments'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'wardTransfer'">
                <app-fd-inpatient></app-fd-inpatient>
              </div>
              <div *ngSwitchCase="'patient_list'">
                <app-recept-patient-list></app-recept-patient-list>
              </div>
              <div *ngSwitchCase="'manageclient'">
                <app-patient-details></app-patient-details>
              </div>
              <div *ngSwitchCase="'discharge_timeline'">
                <app-discharge-timeline></app-discharge-timeline>
              </div>
              <div *ngSwitchCase="'dietaccess'">
                <app-nursedietaccess></app-nursedietaccess>
              </div>
              <div *ngSwitchCase="'other_expenses'">
                <app-other-expenses></app-other-expenses>
              </div>
              <div *ngSwitchCase="'diagmanufacturer'">
                <app-phrama-manufacturer-mst></app-phrama-manufacturer-mst>
              </div>
              <div *ngSwitchCase="'srl'">
                <app-stockreorderlevel></app-stockreorderlevel>
              </div>
              <div *ngSwitchCase="'pharmacysupplier'">
                <app-pharma-supplier-mst></app-pharma-supplier-mst>
              </div>
              <div *ngSwitchCase="'supplierProduct'">
                <app-phrama-supplier-product-mst></app-phrama-supplier-product-mst>
              </div>
              <div *ngSwitchCase="'product'">
                <app-pharma-product-mst></app-pharma-product-mst>
              </div>
              <div *ngSwitchCase="'purchasereqlist'">
                <app-purchaserequestlist></app-purchaserequestlist>
              </div>
              <div *ngSwitchCase="'pharmacyrequest'">
                <app-pharmapurchaserequest></app-pharmapurchaserequest>
              </div>
              <div *ngSwitchCase="'pharmacyresponse'">
                <app-pharmapurchaseordermst></app-pharmapurchaseordermst>
              </div>
              <div *ngSwitchCase="'orderview'">
                <app-purchaseorderview></app-purchaseorderview>
              </div>
              <div *ngSwitchCase="'inventory_receivables'">
                <app-pharma-inventory-receivables></app-pharma-inventory-receivables>
              </div>
              <div *ngSwitchCase="'inventory_upload'">
                <app-pharma-inventory-upload></app-pharma-inventory-upload>
              </div>
              <div *ngSwitchCase="'stockStatusAdmin'">
                <app-pharma-stock-status-admin></app-pharma-stock-status-admin>
              </div>
              <div *ngSwitchCase="'productReturns'">
                <app-pharma-returns></app-pharma-returns>
              </div>
              <div *ngSwitchCase="'supppay'">
                <app-pharma-supplier-payment></app-pharma-supplier-payment>
              </div>
              <div *ngSwitchCase="'stockadjustment'">
                <app-stockadjustment></app-stockadjustment>
              </div>
              <div *ngSwitchCase="'rackbin'">
                <app-pharma-rackbin></app-pharma-rackbin>
              </div>
              <div *ngSwitchCase="'stock_status'">
                <app-pharma-stock-status></app-pharma-stock-status>
              </div>
              <div *ngSwitchCase="'sales_report'">
                <app-pharma-sales-report></app-pharma-sales-report>
              </div>
              <div *ngSwitchCase="'receivables_report'">
                <app-pharma-receivable-report></app-pharma-receivable-report>
              </div>
              <div *ngSwitchCase="'productReturnsReport'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'gsd'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'SDI'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'bed_occupancypharm'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'abc_analysispharm'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'shrtexpiry'">
                <app-shortexpiry></app-shortexpiry>
              </div>
              <div *ngSwitchCase="'pastinwards'">
                <app-pastinwards></app-pastinwards>
              </div>
              <div *ngSwitchCase="'appcreate'">
                <app-recept-app-create-page></app-recept-app-create-page>
              </div>
              <div *ngSwitchCase="'diaAppCreate'">
                <app-diag-app-create></app-diag-app-create>
              </div>
              <div *ngSwitchCase="'dietappointmentscreate'">
                <app-diet-appointments-creation></app-diet-appointments-creation>
              </div>
              <div *ngSwitchCase="'receptionistdoctordetailview'">
                <app-receptionistdoctordetailview></app-receptionistdoctordetailview>
              </div>
              <div *ngSwitchCase="'diaAppDetail'">
                <app-diagnosisappointmentsview></app-diagnosisappointmentsview>
              </div>
              <div *ngSwitchCase="'purchase_report'">
                <app-pharma-sales-report></app-pharma-sales-report>
              </div>
              <div *ngSwitchCase="'dashboard'">
                <app-doctorhome></app-doctorhome>
              </div>
              <div *ngSwitchCase="'docassessment'">
                <app-assessment></app-assessment>
              </div>
              <div *ngSwitchCase="'in_patient'">
                <app-inpat-patient-list></app-inpat-patient-list>
              </div>
              <div *ngSwitchCase="'inPatientViewInfo'">
                <app-inpat-patient-info></app-inpat-patient-info>
              </div>
              <div *ngSwitchCase="'appointment_list'">
                <app-doctor-app-list></app-doctor-app-list>
              </div>
              <div *ngSwitchCase="'walkin'">
                <app-noappointment></app-noappointment>
              </div>
              <div *ngSwitchCase="'billing'">
                <app-doctorbilllistpage></app-doctorbilllistpage>
              </div>
              <div *ngSwitchCase="'billcreate'">
                <app-bill-create></app-bill-create>
              </div>
              <div *ngSwitchCase="'billedit'">
                <app-bill-edit></app-bill-edit>
              </div>
              <div *ngSwitchCase="'inPatientDS'">
                <app-inpat-discharge-summary></app-inpat-discharge-summary>
              </div>
              <div *ngSwitchCase="'patient_dashboard'">
                <app-inpat-dashboard></app-inpat-dashboard>
              </div>
              <div *ngSwitchCase="'inpatient_Discharge_view'">
                <app-inpat-discharge-view></app-inpat-discharge-view>
              </div>
              <div *ngSwitchCase="'inpatient_guno_Discharge_view'">
                <app-gynac-dis-summary></app-gynac-dis-summary>
              </div>
              <div *ngSwitchCase="'notification'">
                <app-doctor-notification></app-doctor-notification>
              </div>
              <div *ngSwitchCase="'help'">
                <app-help></app-help>
              </div>
              <div *ngSwitchCase="'calender'">
                <app-doctor-schedule-list-page></app-doctor-schedule-list-page>
              </div>
              <div *ngSwitchCase="'doctor_availability'">
                <app-doctor-available-nonavailable-scedule-page>
                </app-doctor-available-nonavailable-scedule-page>
              </div>
              <div *ngSwitchCase="'manage_schedule'">
                <app-doctor-manage-schedule-main></app-doctor-manage-schedule-main>
              </div>
              <div *ngSwitchCase="'homecare'">
                <app-homecare></app-homecare>
              </div>
              <div *ngSwitchCase="'secondopinion'">
                <app-second-opinion></app-second-opinion>
              </div>
              <div *ngSwitchCase="'secondopinion_casesheet'">
                <app-second-opinion-casesheet></app-second-opinion-casesheet>
              </div>
              <div *ngSwitchCase="'profile_update'">
                <app-doctor-profile-update></app-doctor-profile-update>
              </div>
              <div *ngSwitchCase="'permission'">
                <app-permission></app-permission>
              </div>
              <div *ngSwitchCase="'medprescription'">
                <app-med-diag-prescription></app-med-diag-prescription>
              </div>
              <div *ngSwitchCase="'ModalPage'">
                <app-dropdown-modelpage></app-dropdown-modelpage>
              </div>
              <div *ngSwitchCase="'appointment_view'">
                <app-appoinmentview></app-appoinmentview>
              </div>
              <div *ngSwitchCase="'reference_video'">
                <app-reference-video></app-reference-video>
              </div>
              <div *ngSwitchCase="'salseReport'">
                <app-doc-sales-report></app-doc-sales-report>
              </div>
              <div *ngSwitchCase="'receivablesReport'">
                <app-doc-receivables-report></app-doc-receivables-report>
              </div>
              <div *ngSwitchCase="'stockReport'">
                <app-doc-stock-report></app-doc-stock-report>
              </div>
              <div *ngSwitchCase="'ipbillcreate'">
                <app-ipatient-bill-create></app-ipatient-bill-create>
              </div>
              <div *ngSwitchCase="'ipbilling'">
                <app-doctorbilllistpage></app-doctorbilllistpage>
              </div>
              <div *ngSwitchCase="'ipcard'">
                <app-inpatien-card></app-inpatien-card>
              </div>
              <div *ngSwitchCase="'consolidatedBills'">
                <app-consolidated-bills></app-consolidated-bills>
              </div>
              <div *ngSwitchCase="'inPat_report'">
                <app-inpatient-reports></app-inpatient-reports>
              </div>
              <div *ngSwitchCase="'Day_book'">
                <app-daybook></app-daybook>
              </div>
              <div *ngSwitchCase="'docappcreate'">
                <app-doc-app-create></app-doc-app-create>
              </div>
              <div *ngSwitchCase="'Sales_report_pharma'">
                <app-pharma-sales-report-doc></app-pharma-sales-report-doc>
              </div>
              <div *ngSwitchCase="'diag_bill_reports'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'hosp_bill_reports'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'prescriptionlist'">
                <app-recept-prescription-list></app-recept-prescription-list>
              </div>
              <div *ngSwitchCase="'receptviewprescription'">
                <app-receptviewprescription></app-receptviewprescription>
              </div>
              <div *ngSwitchCase="'editpres'">
                <app-edit-prescription></app-edit-prescription>
              </div>
              <div *ngSwitchCase="'viewpres'">
                <app-med-pres-view></app-med-pres-view>
              </div>
              <div *ngSwitchCase="'concernform'">
                <app-concent-form></app-concent-form>
              </div>
              <div *ngSwitchCase="'notification'">
                <app-doctor-notification></app-doctor-notification>
              </div>
              <div *ngSwitchCase="'patreg'">
                <app-patient-registration></app-patient-registration>
              </div>
              <div *ngSwitchCase="'home'">
                <app-frontdesk-home></app-frontdesk-home>
              </div>
              <div *ngSwitchCase="'registration'">
                <app-front-desk-registration></app-front-desk-registration>
              </div>
              <div *ngSwitchCase="'receptviewprescription'">
                <app-receptviewprescription></app-receptviewprescription>
              </div>
              <div *ngSwitchCase="'frontdeskbilling'">
                <app-recept-bill-list></app-recept-bill-list>
              </div>
              <div *ngSwitchCase="'receptbillcreate'">
                <app-recept-bill-create></app-recept-bill-create>
              </div>
              <div *ngSwitchCase="'receptbilledit'">
                <app-recept-bill-edit></app-recept-bill-edit>
              </div>
              <div *ngSwitchCase="'receptbilldetail'">
                <app-recept-bill-detailview></app-recept-bill-detailview>
              </div>
              <div *ngSwitchCase="'nurse_ward'">
                <app-nurse-ward></app-nurse-ward>
              </div>
              <div *ngSwitchCase="'nurse_patient'">
                <app-nurse-patient></app-nurse-patient>
              </div>
              <div *ngSwitchCase="'nurse_doctor'">
                <app-nurse-doctor></app-nurse-doctor>
              </div>
              <div *ngSwitchCase="'advances'">
                <app-bill-advances></app-bill-advances>
              </div>
              <div *ngSwitchCase="'billingDetailsView'">
                <app-billng-detail-view></app-billng-detail-view>
              </div>
              <div *ngSwitchCase="'billingDiagDetailsView'">
                <app-billing-diag-view></app-billing-diag-view>
              </div>
              <div *ngSwitchCase="'charges'">
                <app-billing-charges></app-billing-charges>
              </div>
              <div *ngSwitchCase="'refund'">
                <app-bill-refund></app-bill-refund>
              </div>
              <div *ngSwitchCase="'createRefund'">
                <app-bill-refund-create></app-bill-refund-create>
              </div>
              <div *ngSwitchCase="'dischargeList'">
                <app-inpatient-diacharge-list></app-inpatient-diacharge-list>
              </div>
              <div *ngSwitchCase="'consultant'">
                <app-consultant></app-consultant>
              </div>
              <div *ngSwitchCase="'diaBillCreate'">
                <app-diagbillcreate></app-diagbillcreate>
              </div>
              <div *ngSwitchCase="'diaBillingDetailsView'">
                <app-diagbilldetailview></app-diagbilldetailview>
              </div>
              <div *ngSwitchCase="'wardmaster'">
                <app-ward-master></app-ward-master>
              </div>
              <div *ngSwitchCase="'warddetails'">
                <app-ward-details></app-ward-details>
              </div>
              <div *ngSwitchCase="'OTmaster'">
                <app-operation-theatre></app-operation-theatre>
              </div>
              <div *ngSwitchCase="'ipatientBillCreate'">
                <app-ipatient-bill-create></app-ipatient-bill-create>
              </div>
              <div *ngSwitchCase="'frontConsolidatedBills'">
                <app-consolidated-bills></app-consolidated-bills>
              </div>
              <div *ngSwitchCase="'dietappointments'">
                <app-diet-appointments></app-diet-appointments>
              </div>
              <div *ngSwitchCase="'dietappointmentsdetailed'">
                <app-diet-appointments-detailed-view></app-diet-appointments-detailed-view>
              </div>
              <div *ngSwitchCase="'inpat_reports'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'createPackage'">
                <app-create-package></app-create-package>
              </div>
              <div *ngSwitchCase="'listPackage'">
                <app-list-package></app-list-package>
              </div>
              <div *ngSwitchCase="'inpdischargeview'">
                <app-inp-discharge-view></app-inp-discharge-view>
              </div>
              <div *ngSwitchCase="'docref'">
                <app-doc-ref-list></app-doc-ref-list>
              </div>
              <div *ngSwitchCase="'docrefcreate'">
                <app-doc-ref-create></app-doc-ref-create>
              </div>
              <div *ngSwitchCase="'leadmgmt'">
                <app-lead-mgmt></app-lead-mgmt>
              </div>
              <div *ngSwitchCase="'partialPayments'">
                <app-partial-payment></app-partial-payment>
              </div>
              <div *ngSwitchCase="'insuranceBill'">
                <app-insurancebill></app-insurancebill>
              </div>
              <div *ngSwitchCase="'sales_report_doctor'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'bill_refund'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'bill_due'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'bed_occupancy'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'abc_analysis'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'purchase_order'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'productreport'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'product_receivables'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'lab_test_wise'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'notvisited_report'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'consolidat_report'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'appedit'">
                <app-diag-app-edit></app-diag-app-edit>
              </div>
              <div *ngSwitchCase="'frontproduct'">
                <app-pharma-product-mst></app-pharma-product-mst>
              </div>
              <div *ngSwitchCase="'nextvisit_report'">
                <app-next-visit-report></app-next-visit-report>
              </div>
              <div [hidden]="deappointment" *ngSwitchCase="'diag_appointment'">
                <app-diagnmosis-appointments></app-diagnmosis-appointments>
              </div>
              <div *ngSwitchCase="'tests'">
                <app-diag-test-home></app-diag-test-home>
              </div>
              <div *ngSwitchCase="'testsandpackadv'">
                <app-diag-testandpackages-adv></app-diag-testandpackages-adv>
              </div>
              <div *ngSwitchCase="'nephrology_readings'">
                <app-nephrologyreadings></app-nephrologyreadings>
              </div>
              <div *ngSwitchCase="'diabetics_readings'">
                <app-diabeticsreading></app-diabeticsreading>
              </div>
              <div *ngSwitchCase="'billinglist'">
                <app-diagbilllist></app-diagbilllist>
              </div>
              <div *ngSwitchCase="'pending'">
                <app-diagnosis-report-upload></app-diagnosis-report-upload>
              </div>
              <div *ngSwitchCase="'rejected'">
                <app-diagnosis-modify-report></app-diagnosis-modify-report>
              </div>
              <div *ngSwitchCase="'report_upload_edit'">
                <app-diagnosis-report-edit></app-diagnosis-report-edit>
              </div>
              <div *ngSwitchCase="'completed'">
                <app-diagnosis-complete-report></app-diagnosis-complete-report>
              </div>
              <div *ngSwitchCase="'assertion'">
                <app-diag-specimen-assertion></app-diag-specimen-assertion>
              </div>
              <div *ngSwitchCase="'report_upload_view'">
                <app-diagnosis-report-detailview></app-diagnosis-report-detailview>
              </div>
              <div *ngSwitchCase="'diagnosisview'">
                <app-diagnosisappointmentsview></app-diagnosisappointmentsview>
              </div>
              <div *ngSwitchCase="'nephrologyreadingviewpage'">
                <app-nephrologyreadingviewpage></app-nephrologyreadingviewpage>
              </div>
              <div *ngSwitchCase="'diagnosis_home'">
                <app-diagnosis-home></app-diagnosis-home>
              </div>
              <div *ngSwitchCase="'users'">
                <app-diagnosis-user-list></app-diagnosis-user-list>
              </div>
              <div *ngSwitchCase="'users_view'">
                <app-diagnosis-user-view></app-diagnosis-user-view>
              </div>
              <div *ngSwitchCase="'report_approval'">
                <app-diagnosis-report-approval></app-diagnosis-report-approval>
              </div>
              <div *ngSwitchCase="'report_approval_detailed'">
                <app-diagnosis-report-approval-detailedview>
                </app-diagnosis-report-approval-detailedview>
              </div>
              <div *ngSwitchCase="'diag_help'">
                <app-diagnosis-help></app-diagnosis-help>
              </div>
              <div *ngSwitchCase="'diagtestadd'">
                <app-diag-test-add></app-diag-test-add>
              </div>
              <div *ngSwitchCase="'diagtestaddadv'">
                <app-diag-test-create-adv></app-diag-test-create-adv>
              </div>
              <div *ngSwitchCase="'diabeticsreading'">
                <app-diabeticsreading></app-diabeticsreading>
              </div>
              <div *ngSwitchCase="'diagbillcreate'">
                <app-diagbillcreate></app-diagbillcreate>
              </div>
              <div *ngSwitchCase="'diagbilldetailview'">
                <app-diagbilldetailview></app-diagbilldetailview>
              </div>
              <div *ngSwitchCase="'diag_app_create'">
                <app-diag-app-create></app-diag-app-create>
              </div>
              <div *ngSwitchCase="'reports'">
                <app-diag-reports></app-diag-reports>
              </div>
              <div *ngSwitchCase="'billedit'">
                <app-diagbilledit></app-diagbilledit>
              </div>
              <div *ngSwitchCase="'clientPackageList'">
                <app-user-package-list></app-user-package-list>
              </div>
              <div *ngSwitchCase="'capp-diag-packagereateUserPackage'">
                <app-create-package></app-create-package>
              </div>
              <div *ngSwitchCase="'diagPackageCreate'">
                <app-diag-package-create></app-diag-package-create>
              </div>
              <div *ngSwitchCase="'samplecollect'">
                <app-diag-sample-list></app-diag-sample-list>
              </div>
              <div *ngSwitchCase="'samplecollectADV'">
                <app-diag-sample-list-adv></app-diag-sample-list-adv>
              </div>
              <div *ngSwitchCase="'out-centre'">
                <app-outsource-centre></app-outsource-centre>
              </div>
              <div *ngSwitchCase="'out-reports'">
                <app-outsource-reports></app-outsource-reports>
              </div>
              <div *ngSwitchCase="'out-payments'">
                <app-outsource-payments></app-outsource-payments>
              </div>
              <div *ngSwitchCase="'out-payments-adv'">
                <app-diag-outsource-payments-adv></app-diag-outsource-payments-adv>
              </div>
              <div *ngSwitchCase="'out-centre-list'">
                <app-outsource-centre-list></app-outsource-centre-list>
              </div>
              <div *ngSwitchCase="'out-test-map'">
                <app-outsource-test-map></app-outsource-test-map>
              </div>
              <div *ngSwitchCase="'path_samplecollectADV'">
                <app-diag-sample-list-adv></app-diag-sample-list-adv>
              </div>
              <div *ngSwitchCase="'pathpending'">
                <app-diagnosis-report-upload></app-diagnosis-report-upload>
              </div>
              <div *ngSwitchCase="'pathrejected'">
                <app-diagnosis-modify-report></app-diagnosis-modify-report>
              </div>
              <div *ngSwitchCase="'pathcompleted'">
                <app-diagnosis-complete-report></app-diagnosis-complete-report>
              </div>
              <div *ngSwitchCase="'report_approval_pathology'">
                <app-diagnosis-report-approval></app-diagnosis-report-approval>
              </div>
              <div *ngSwitchCase="'radio_samplecollectADV'">
                <app-diag-sample-list-adv></app-diag-sample-list-adv>
              </div>
              <div *ngSwitchCase="'radiopending'">
                <app-diagnosis-report-upload></app-diagnosis-report-upload>
              </div>
              <div *ngSwitchCase="'radiorejected'">
                <app-diagnosis-modify-report></app-diagnosis-modify-report>
              </div>
              <div *ngSwitchCase="'radiocompleted'">
                <app-diagnosis-complete-report></app-diagnosis-complete-report>
              </div>
              <div *ngSwitchCase="'report_approval_radiology'">
                <app-diagnosis-report-approval></app-diagnosis-report-approval>
              </div>
              <div *ngSwitchCase="'micro_samplecollectADV'">
                <app-diag-sample-list-adv></app-diag-sample-list-adv>
              </div>
              <div *ngSwitchCase="'micropending'">
                <app-diagnosis-report-upload></app-diagnosis-report-upload>
              </div>
              <div *ngSwitchCase="'microrejected'">
                <app-diagnosis-modify-report></app-diagnosis-modify-report>
              </div>
              <div *ngSwitchCase="'microcompleted'">
                <app-diagnosis-complete-report></app-diagnosis-complete-report>
              </div>
              <div *ngSwitchCase="'report_approval_microbiology'">
                <app-diagnosis-report-approval></app-diagnosis-report-approval>
              </div>
              <div *ngSwitchCase="'specimen-report-template'">
                <app-diag-specimen-report-template></app-diag-specimen-report-template>
              </div>
              <div *ngSwitchCase="'out-tests'">
                <app-outsource-tests-upload></app-outsource-tests-upload>
              </div>
              <div *ngSwitchCase="'manageclient'">
                <app-patient-details></app-patient-details>
              </div>
              <div *ngSwitchCase="'conductionDoc'">
                <app-pharma-supplier-payment></app-pharma-supplier-payment>
              </div>
              <div *ngSwitchCase="'Home'">
                <app-physio-prof-dash></app-physio-prof-dash>
              </div>
              <div *ngSwitchCase="'physio_appointment'">
                <app-physio-appointments></app-physio-appointments>
              </div>
              <div *ngSwitchCase="'physiocasesheet'">
                <app-physio-casesheet></app-physio-casesheet>
              </div>
              <div *ngSwitchCase="'assignmentList'">
                <app-physio-assign-list></app-physio-assign-list>
              </div>
              <div *ngSwitchCase="'assignmentDetailPage'">
                <app-physio-assign-view></app-physio-assign-view>
              </div>
              <div *ngSwitchCase="'therapies'">
                <app-physio-therapies></app-physio-therapies>
              </div>
              <div *ngSwitchCase="'userList'">
                <app-physio-user-list></app-physio-user-list>
              </div>
              <div *ngSwitchCase="'userViewPage'">
                <app-physio-user-view></app-physio-user-view>
              </div>
              <div *ngSwitchCase="'Home'">
                <app-physio-prof-dash></app-physio-prof-dash>
              </div>
              <div *ngSwitchCase="'phy_billing'">
                <app-physio-billlist></app-physio-billlist>
              </div>
              <div *ngSwitchCase="'billdetails'">
                <app-physio-bill-details></app-physio-bill-details>
              </div>
              <div *ngSwitchCase="'DiagnosticDepartment'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'ProductForm'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'diet_manageappointments'">
                <app-doc-app-list></app-doc-app-list>
              </div>
              <div *ngSwitchCase="'profile'">
                <app-profile-update></app-profile-update>
              </div>
              <div *ngSwitchCase="'dietPlan'">
                <app-dietplan></app-dietplan>
              </div>
              <div *ngSwitchCase="'dietPlancard'">
                <app-dietplan></app-dietplan>
              </div>
              <div *ngSwitchCase="'diet_appointment'">
                <app-diet-appointments></app-diet-appointments>
              </div>
              <div *ngSwitchCase="'appt_create'">
                <app-diet-appointments-creation></app-diet-appointments-creation>
              </div>
              <div *ngSwitchCase="'dietcasesheet'">
                <app-diet-casesheet></app-diet-casesheet>
              </div>
              <div *ngSwitchCase="'notification'">
                <app-doctor-notification></app-doctor-notification>
              </div>
              <div *ngSwitchCase="'patientList'">
                <app-diet-patient-list></app-diet-patient-list>
              </div>
              <div *ngSwitchCase="'patientTracking'">
                <app-diet-tracking></app-diet-tracking>
              </div>
              <div *ngSwitchCase="'billList'">
                <app-doctorbilllistpage></app-doctorbilllistpage>
              </div>
              <div *ngSwitchCase="'billCreate'">
                <app-bill-create></app-bill-create>
              </div>
              <div *ngSwitchCase="'billdetail'">
                <app-bill-detail-view></app-bill-detail-view>
              </div>
              <div *ngSwitchCase="'readings'">
                <app-diet-patient-list></app-diet-patient-list>
              </div>
              <div *ngSwitchCase="'diabReadings'">
                <app-diab-readings></app-diab-readings>
              </div>
              <div *ngSwitchCase="'billestimate'">
                <app-bill-estimate></app-bill-estimate>
              </div>
              <div *ngSwitchCase="'pharmacycurentinformation'">
                <app-pharmacycurrentinformation></app-pharmacycurrentinformation>
              </div>
              <div *ngSwitchCase="'ordercreate'">
                <app-ordercreate></app-ordercreate>
              </div>
              <div *ngSwitchCase="'pharmacypastinformation'">
                <app-pharmacypastinformation></app-pharmacypastinformation>
              </div>
              <div *ngSwitchCase="'pharmacymoduleorderdetailview'">
                <app-pharmacymodule-orderdetiledview></app-pharmacymodule-orderdetiledview>
              </div>
              <div *ngSwitchCase="'phamratimeline'">
                <app-timeline-report></app-timeline-report>
              </div>
              <div *ngSwitchCase="'discounts'">
                <app-pharma-discount-mst></app-pharma-discount-mst>
              </div>
              <div *ngSwitchCase="'pharmaratecard'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'pharmacy_help'">
                <app-pharma-help></app-pharma-help>
              </div>
              <div *ngSwitchCase="'referral'">
                <app-pharma-referral></app-pharma-referral>
              </div>
              <div *ngSwitchCase="'pharmacyhome'">
                <app-pharma-home></app-pharma-home>
              </div>
              <div *ngSwitchCase="'inward'">
                <app-inward></app-inward>
              </div>
              <div *ngSwitchCase="'ipWriteRx'">
                <app-inpatient-write-rx></app-inpatient-write-rx>
              </div>
              <div *ngSwitchCase="'manufacturer'">
                <app-phrama-manufacturer-mst></app-phrama-manufacturer-mst>
              </div>
              <div *ngSwitchCase="'pharmaracksbins'">
                <app-pharma-racks-bins></app-pharma-racks-bins>
              </div>
              <div *ngSwitchCase="'interdept'">
                <app-interdept-trans></app-interdept-trans>
              </div>
              <div *ngSwitchCase="'interdeptlist'">
                <app-interdept-trans-list></app-interdept-trans-list>
              </div>
              <div *ngSwitchCase="'consing'">
                <app-consingment></app-consingment>
              </div>
              <div *ngSwitchCase="'weightflag'">
                <app-weightage-master></app-weightage-master>
              </div>
              <div [hidden]="defront" *ngSwitchCase="'front'">
                <app-recept-bill-list></app-recept-bill-list>
              </div>
              <div *ngSwitchCase="'chargesTYPE'">
                <app-billing-charges></app-billing-charges>
              </div>
              <div *ngSwitchCase="'Frontrefund'">
                <app-bill-refund></app-bill-refund>
              </div>
              <div *ngSwitchCase="'FrontAdmininsuranceBill'">
                <app-insurancebill></app-insurancebill>
              </div>
              <div *ngSwitchCase="'frontAdvances'">
                <app-bill-advances></app-bill-advances>
              </div>
              <div *ngSwitchCase="'frontAdminConsolidatedBills'">
                <app-inpatient-bill></app-inpatient-bill>
              </div>
              <div *ngSwitchCase="'frontpartialPayments'">
                <app-partial-payment></app-partial-payment>
              </div>
              <div *ngSwitchCase="'registration'">
                <app-front-desk-registration></app-front-desk-registration>
              </div>
              <div *ngSwitchCase="'registration'">
                <app-front-desk-registration></app-front-desk-registration>
              </div>
              <div *ngSwitchCase="'receptviewprescription'">
                <app-receptviewprescription></app-receptviewprescription>
              </div>
              <div *ngSwitchCase="'diagBillingList'">
                <app-diagbilllist></app-diagbilllist>
              </div>
              <div *ngSwitchCase="'chargetype'">
                <app-billing-charges></app-billing-charges>
              </div>
              <div *ngSwitchCase="'consolidat_reportadmin'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'notvisited_reportadmin'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'stock_report'">
                <app-pharma-stock-status></app-pharma-stock-status>
              </div>
              <div *ngSwitchCase="'IPR'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'IR'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'DBR'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'HBR'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'stockReceivable'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'salesGst'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'inpatientBill'">
                <app-inpatient-bill></app-inpatient-bill>
              </div>
              <div *ngSwitchCase="'inpatientbilldetail'">
                <app-inpatient-bill-detailview></app-inpatient-bill-detailview>
              </div>
              <div *ngSwitchCase="'inpatientbilledit'">
                <app-inpatient-bill-edit></app-inpatient-bill-edit>
              </div>
              <div *ngSwitchCase="'ot_bookinghome'">
                <app-ot-booking></app-ot-booking>
              </div>
              <div *ngSwitchCase="'consingmentlist'">
                <app-consingmentlist></app-consingmentlist>
              </div>
              <!-- newly added -->
              <div *ngSwitchCase="'log_rep'">
                <app-logreport></app-logreport>
              </div>
              <div *ngSwitchCase="'pharma_log_rep'">
                <app-logreport></app-logreport>
              </div>
              <div *ngSwitchCase="'diag_log_rep'">
                <app-logreport></app-logreport>
              </div>
              <div *ngSwitchCase="'cond_log_rep'">
                <app-logreport></app-logreport>
              </div>
              <div *ngSwitchCase="'out_log_rep'">
                <app-logreport></app-logreport>
              </div>
              <div *ngSwitchCase="'Diag_test'">
                <app-diag-test-count-report></app-diag-test-count-report>
              </div>
              <div *ngSwitchCase="'Diag_count'">
                <app-diag-test-count-report></app-diag-test-count-report>
              </div>
              <div *ngSwitchCase="'revenue_rep'">
                <app-diag-test-count-report></app-diag-test-count-report>
              </div>
              <div *ngSwitchCase="'departwise'">
                <app-department-wise-product></app-department-wise-product>
              </div>
              <div *ngSwitchCase="'surgery_doc'">
                <app-diagnosismaster></app-diagnosismaster>
              </div>
              <div *ngSwitchCase="'stock_adjust'">
                <app-pharma-stock-status></app-pharma-stock-status>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer id="footer_section">
        <div class="footer_left">Copyright © 2024 All rights reserved </div>
        <div class="footer_right">
          <p class="pull-right sm-pull-reset">
            <span>
              <a href="#" class="m-r-10">Support</a> | <a href="#" class="m-l-10 m-r-10">Terms of use</a>
              | <a href="#" class="m-l-10">Privacy policy</a>
            </span>
          </p>
        </div>
      </footer>
    </div>
  </div>
</div>