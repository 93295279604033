import { Component, ElementRef, HostListener, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Headers, Http } from '@angular/http';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Helper_Class } from '../../helper_class';
import { ipaddress } from '../../ipaddress';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CommonDataService } from '../../providers/common-data.service';
import { PharmacyService } from '../pharmacy/pharmacy.service';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
declare var $: any;
import { FrontDesk_Helper } from 'src/app/FrontDesk_module/FrontDesk_Helper';
import { Date_Formate, Time_Formate, convertNumberToWords } from 'src/assets/js/common';
import { PharmaPrintService } from '../pharmacy-print.service';
import { Message_data } from 'src/assets/js/Message_data';
import moment from 'moment';
import { pharmacy_helper } from '../Pharmcy_Helper';
import { concatAll } from 'rxjs/operators';
import { NgxBarcodeModule } from 'ngx-barcode';


@Component({
  selector: 'app-inpatient-write-rx',
  templateUrl: './inpatient-write-rx.component.html',
  styleUrls: ['./inpatient-write-rx.component.scss']
})
export class InpatientWriteRxComponent implements OnInit {
  @ViewChild('billprint') printsection: ElementRef;
  @ViewChild('noheader') noheader: ElementRef;
  @ViewChild('banner') banner: ElementRef;
  @ViewChild('logowithname') logowithname: ElementRef;
  @ViewChild('billid') billid: ElementRef;
  @ViewChild('printsmallcover') printsmallcover: ElementRef;
  @ViewChild('printnoheader') printnoheader: ElementRef;
  @ViewChild('printbanner') printbanner: ElementRef;
  @ViewChild('printlogowithname') printlogowithname: ElementRef;

  public amtbdGST;
  public prodAmount;
  public prodDiscount: any = 0.00;
  public prodDiscountinsure: any = 0.00;
  public amountDiscount: any = 0.00;
  public amountDiscountinsure: any = 0.00;
  public duesponser: any = 0.00;
  public returnamountDicount = 0.00;
  public subtotalAmount: number;
  public
  preferredTimeFlag: boolean;
  orderDate: string;
  orderTime: string;
  public drugDetailsArray: any = [];
  taxArray = [];
  taxpercentageArray = [];
  dureShowFlag;
  public doctorName = "";
  taxAmount: string;
  finalAmount: any;
  public totalAmount: any = 0;
  public totalAmountinsure: any = 0;
  todayDate: string;
  paidText = "0.00";
  remaingBalance = "0.00";
  shortName: string;
  name: any = "";
  address1: any;
  address2: any;
  public imageString;
  saveFlag: boolean;
  printFlag: boolean;
  public medicineArray = [];
  public medicineArrayreturns = [];
  public medicineListArrayreturns = [];
  public pharmacyName: string;
  public pharmacyAddress: string;
  public pharmacyCSZ: string;
  public pharmacyMobile: string;
  public pharmacyCount: string;
  public PurchaseType: string;

  public medicalPrescriptionArray = [];
  public MedicineNameArray = [];
  public medicineListArray = [];
  public medicineDataArray = [];
  public drugArray = [];
  public discSalesVolArray = [];
  public discTransVolArray = [];
  public discregSalesVolArray = [];
  public discountDatas = [];
  public discType: string;
  public discURL: string;
  discountFlag: boolean;
  public medicareName: string;
  public medTypeTxt: string;
  public drugType: string;
  public medicineName: string;
  public medID: string;
  public batchNumber: string;
  public saveOrderURl: string;
  public clientName: string = "";
  public sendData;
  public price;
  public qty;
  public disc;
  public gst;
  public amt;
  public perUnit;
  public imageStr;
  public ReferedDoctorArray = [];
  public referedName = [];
  public getData;
  public pharmacistID;
  public pharmacyID;
  public ipaddress;
  //mdbTable
  dtOptions: DataTables.Settings = {};
  public pharmaLogo;
  public billTemplate;
  public stateGST;
  public centerGST;
  public bgImage: string;
  public location;
  public city;
  public state;
  public country;
  public pharmaName;
  public dlNO;
  public GSTNo;
  public pharmaAddress1;
  public pharmaAddress2;
  public billID = undefined;
  public selectedIntake;
  public intakesession;
  public sessionhide: boolean = false;
  public sessionhrs: boolean = true;
  public alternatsession = true;
  public coverpatientname;
  //
  public backenddrugquantity;
  public balanceamount;
  public returnbilltotalAmount: number = 0;
  public returnbillid;
  public showreturns: boolean = false;
  public hiddeaddbutton: boolean = false;
  public showaddbutton: boolean = true;
  public displayDescription: boolean;
  //added by me 
  public returnsDetailsArray = [];
  public MedicineNameArrayreturns = [];
  public retivebillnodata;
  public pharmaidforbill;
  public highqtydrugid;
  public checkquantitybatch;
  public checkquantityindex;
  public checkbackendquantity;
  public checkbatchnoarray = [];
  public orderType: boolean;
  orderNumber: string;
  public tranFlag: boolean;
  public TransactionID;
  public cardFlag: boolean;
  public cardNumber;
  public cardHolderName;
  public chequeFlag: boolean;
  public insurFlag: boolean;
  public bankName;
  public payTypeListArray: any = [];
  public insurerListArray: any = [];
  public payType: string;
  public insurerID: string;
  public hospitalID;
  public docRegID;
  public billingCreateArray: any = [];
  public docIDWithName;
  public appointText;
  public showSaveFlag: boolean = true;
  public rowID: string;
  public addMedFlag: boolean = false;
  public currentDatetime;
  public appDate: string;
  public dayDuration;
  public dctHospital: string;
  public doctName: string;
  public doctLocation: string;
  public doctAddress: string;
  public doctCity: string;
  public doctState: string;
  public doctClientName: string;
  public clntgender: string;
  public engFlag: boolean;
  public tamilFlag: boolean;
  public teluguFlag: boolean;
  public kannadaFlag: boolean;
  public malayFlag: boolean;
  public orthoFlag: boolean;
  public hospitalInfoArray;

  public symptomsFlag: boolean;
  public medTypeText: string;

  public drugMedID: string;
  public getIntakeArray = [];
  public medDetailListArray = [];
  public intake: string;
  public mor: string;
  public aft: string;
  public eve: string;
  public ngt: string;
  public everySix: string;
  public dureText: string;
  public dureWrite: string;
  public listProducts = [];
  public dayText: string;
  public afterfoodText: string;
  public medicineTableFlag: boolean = true;
  public prferPharma: string;
  public preferAdd: string;
  public ClientID;
  public relationID;
  public subRelationID;
  public appID;
  public mrNumb;

  public discout = "";
  public pharmaID;

  public morLable: boolean = true;
  public afterEvent: boolean = true;
  public morID: boolean;
  public afterID: boolean;
  public intake_id;

  public morningRequset: boolean;
  public morningDiaplayFlag: boolean;
  public afternoonReq: boolean;
  public evenDisplayFlag: boolean;
  public afternoonDisplayFlag: boolean;
  public evening_req: boolean;
  public nightReq: boolean;
  public nightDis: boolean;
  public dureFlag: boolean;
  public shortForm;
  public show;
  public nightEvent: boolean = true;
  public mornEventxt: boolean;
  public intakeShow: boolean;
  public eveID: boolean;
  public ngtDisCount: boolean;
  public dureRequire: boolean;
  public hptlName;
  public address;
  public subRelID: string;
  public telephone: string;
  public appointment: boolean = false;
  public symptoms: string;
  public language: string;
  public daydurationShow: boolean;
  public currentDate;
  public symptomData = [];
  public symptomListArray = [];
  public symptomsListArray = [];
  public selectedSymptoms = [];
  public newSymptomsArray = [];
  public symptomsText = "";
  public presID;
  public tempBatch;
  public expiryDate;
  public recItemID;
  public batchRequired = { "border-color": "rgb(250, 40, 2)" };
  public showBatchArray: boolean = false;
  public quantityDisable: boolean = true;
  public save_flag: boolean = true;
  public productReturn;
  public genericDisplayFlag: boolean = false;
  public payDes;
  public userArray = [];
  public usertype: string;
  public rackId;
  public binId;
  public createdby;
  public editbutton: boolean = false;
  public newbutton: boolean = false;
  public deletebutton: boolean = false;
  public printbutton: boolean = false;
  public viewbutton: boolean = false;
  public totalvalue;
  public orderNo;
  public viewOrderFlag: boolean = false;
  OrderDate: String;
  PrepareTime: String;
  OrderByName: String;
  presFlag: boolean;
  totalTime: string;
  public rec_item_id;
  public batcharraylength;
  public checkbatchlength;
  public breakqty;
  public rounddesc;
  public round_off_descflag: boolean;
  isInputDisabled: boolean = true;
  public req_roundoffval;
  public selectedbatch_flag: boolean = false;
  public firstItem = [];
  public selectedDrugs = [];
  public drugprice_value: number = 0;
  public filter: string;
  public firstNameFlag: boolean;
  public fromFlag: boolean;
  public toFlag: boolean;
  public uhidFlag: boolean;
  public mobileFlag: boolean;
  public doctorFlag: boolean;
  public firstName: string;
  public lastName: string;
  public patientId: string;
  public mobileNo: string;
  public billList = [];
  public BillnoFlag: boolean;
  public rateplaneflag: boolean = false;
  public discountDatasArray: any = [];
  public discountdesc;
  public discount_id;
  public discountvalue_amount;
  public disperce;
  public GSTamountflag: boolean;
  public discamount: number = 0;
  public discamountinsure: number = 0;
  public GSTamount: number = 0;
  public CGSTamount: any = 0;
  public SGSTamount: any = 0;
  public GSTamountinsure: number = 0;
  public gstdecimalamt: number = 0;
  public gstdecimalamtinsure: number = 0;
  public wholegstdecimal: number = 0;
  public aftdiscamt: number = 0;
  public aftdiscamtinsure: number = 0;
  public discoutFlag: boolean;
  public clntAge;
  private intakereq: string;
  public ddarray_cgst;
  public ddarray_sgst;
  public rowperunit;
  public rack_binrequire: boolean;
  public ddarray_day_txtflag;
  public ddarray_show_intakeFlag;
  public ddarray_alternativedays;
  public ddarray_select;
  public ddarray_morning;
  public ddarray_afternoon;
  public ddarray_evening;
  public ddarray_night;
  public ddarray_cost_price;
  public ddarray_sales_price;
  public ddarray_mrp;
  public ddarray_daytext;
  public comparequantity;
  public stripprice;
  public aftdiscamtreturn: number = 0;
  public discamountreturn: number = 0;
  public totalAmountreturn: any = 0;
  public amountval: any;
  public numToWords: string;
  public paytypreq: boolean = false;
  public paymentsarray: any = [];
  public credit_flag: boolean = true;
  public hosp_credits: any = [];
  public payType1: string;
  public credit_type;
  public credit_person;
  private tamt: number = 0;
  public credit_bill;
  public patientdetailsurl = "gen/gpda";
  public docsal: string = "Dr. ";
  public clntSal;
  public toDate;
  public fromDate;
  public billingList: any = [];
  public headergen;
  public patientdetailsarray = [];
  ipop: any;
  public retrivemr: boolean = false;
  public docsalflag: boolean = true;
  public patsearchtype;
  public patient_detailsflag: boolean;
  public clientsalflag: boolean;
  public clnt_sal: string;

  public clntFirstName;
  public clntMiddleName;
  public clntLastName;
  public clntGender;
  public clntContNumber;
  public clntEmergNumber;
  public clntEmergNumber1;
  public clientname;
  public clntDOB;
  public clntdob;
  public dobDisabledFlag: boolean;

  public clntFatherOrGuardName;
  public clntAddress1;
  public clntAddress2;
  public aadharNumber;

  public clntLocation;
  public clntCountry;
  public clntState;
  public clntCity;
  public clntZipcode;
  public ref_source;
  public whatsappNumber;
  public Ward_name;
  public Bed_no;
  public admissiondate;
  public admission_time;
  public referredDoctor;
  public aftdeletflag: boolean;
  public lineitmdisflag: boolean;
  public rateplanetype;
  public priceedit;
  public insurancestatus: boolean;
  public accepted: boolean;
  public insuranceflag: boolean;
  public insuranceType;
  public sponsuredue;
  public ddins_allow;
  public insurername;
  public insurancechkflag: boolean;
  public normaldt_formate: string;
  public logostyle;
  public bill_pay_type_req;
  public show_hsn;
  public printlogostyle;
  public printFontSize;
  public show_disc;
  public show_gst;
  public value1 = "true";
  public fin_year;
  public totalamountfalg: boolean;
  public amountinwordflag: boolean;
  public barcode_logo;
  format: '' | 'CODE128' | 'CODE128A' | 'CODE128B' | 'CODE128C' | 'EAN' | 'UPC' | 'EAN8' | 'EAN5' | 'EAN2' | 'CODE39' | 'ITF14' | 'MSI' | 'MSI10' | 'MSI11' | 'MSI1010' | 'MSI1110' | 'pharmacode' | 'codabar';
  elementType: 'svg';
  lineColor = '#000000';
  width = 1;
  barcodeheight = 25;
  displayValue = true;
  fontOptions = '';
  font = 'monospace';
  textAlign = 'center';
  textPosition = 'bottom';
  textMargin = 1;
  fontSize = 15;
  background = '#ffffff';
  margin = 2;
  marginTop = 2;
  marginBottom = 2;
  marginLeft = 2;
  marginRight = 2;
  public medicinecolorarray: any = [];
  public lookalike;
  public soundalike;
  public highrisk;
  public ward;
  public ddarray_lookalike;
  public ddarray_soundalike;
  public ddarray_highrisk;
  public ddarray_ward;
  public ddarray_lookalikevalue;
  public ddarray_soundalikevalue;
  public ddarray_highriskvalue;
  public ddarray_wardvalue;
  public insuranceflag_paid;
  public ddarray_no_of_IU;
  public ddarray_qty_per_unit;
  public inp_hosp_id: string = '';
  public wardflag: boolean;
  public qualification: string;
  public qualification_flag: boolean = true;
  public ddarray_sno: any;

  constructor(public pharmacyservice: MenuViewService, public toastr: ToastrService, public gservice: CommonDataService, public httpp: HttpClient, public http: Http, public httpclnt: HttpClient, public router: Router, public dialog: MatDialog, public printService: PharmaPrintService, public cdr: ChangeDetectorRef) {
    this.insurFlag = true;
    this.tranFlag = true;
    this.cardFlag = true;
    this.chequeFlag = true;
    this.insuranceflag = false;
    this.insuranceflag_paid = false;
    this.firstNameFlag = true;
    this.fromFlag = true;
    this.toFlag = true;
    this.uhidFlag = true;
    this.mobileFlag = true;
    this.doctorFlag = false;
    this.printFlag = true;
    this.saveFlag = true;
    this.saveOrderURl = ipaddress.getIp + "pharmacontrol/sporder";
    this.PurchaseType = "Store pickup"
    this.discURL = ipaddress.getIp.toString() + "pharmacontrol/gdis";
    this.getIntakeSession()
    this.lineitmdisflag = true;
  }

  ngOnInit(): void {
    this.logostyle = Helper_Class.getInfo().hospitals[0].bill_template_style;
    this.bill_pay_type_req = Helper_Class.getInfo().hospitals[0].bill_pay_type_req;
    this.printlogostyle = Helper_Class.getInfo().bill_print_logo_style;
    this.printFontSize = Helper_Class.getInfo().hospitals[0].print_font_size;
    this.show_hsn = Helper_Class.getInfo().hospitals[0].show_hsn;
    this.show_disc = Helper_Class.getInfo().hospitals[0].show_disc;
    this.show_gst = Helper_Class.getInfo().hospitals[0].show_gst;
    this.lookalike = Helper_Class.getInfo().hospitals[0].look_alike_color;
    this.soundalike = Helper_Class.getInfo().hospitals[0].sound_alike_color;
    this.highrisk = Helper_Class.getInfo().hospitals[0].high_risk_color;
    this.ward = Helper_Class.getInfo().hospitals[0].ward_only_color;
    if (this.show_hsn == undefined) { this.show_hsn = "0" }
    if (this.show_disc == undefined) { this.show_disc = "0" }
    if (this.show_gst == undefined) { this.show_gst = "0" }
    this.pharmaName = Helper_Class.getInfo().hospitals[0].hptl_name;
    this.round_off_descflag = false;
    this.insuranceflag = true;
    this.insurancechkflag = true;
    this.req_roundoffval = Helper_Class.getInfo().hospitals[0].req_roundoff;
    this.round_off_descflag = this.req_roundoffval === "1" ? true : false;
    this.insurancestatus = false;
    this.insuranceType = "1";
    $(document).ready(function () {
      $("input").attr("autocomplete", "off");
    });
    this.userArray = [{ user_type: "1", user_type_desc: "Active" }, { user_type: "0", user_type_desc: "Discharged" }];
    document.getElementById("pharma_inpattbl_overlay").style.overflowX = "auto";
    this.usertype = this.userArray[0].user_type;

    this.loadScript();
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 10,
      "lengthMenu": [[5, 10, 25, 50, -1], [5, 10, 25, 50, "All"]],
      "columnDefs": [
        { "orderable": false, "targets": [0, 1, 2] },
      ],
      "language": {
        "search": "Filter"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12">><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"l><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };

    this.getData = Helper_Class.getInfo();
    this.createdby = Helper_Class.getInfo().user_id;

    if (this.getData.middle_name != undefined)
      this.createdby = this.getData.first_name + " " + this.getData.middle_name + " " + this.getData.last_name;
    else {
      if (this.getData.last_name != undefined)
        this.createdby = this.getData.first_name + " " + this.getData.last_name;
      else
        this.createdby = this.getData.first_name;
    }

    this.fin_year = this.getData.fin_year;
    this.pharmaidforbill = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
    this.ipaddress = Helper_Class.getIPAddress();
    this.pharmacistID = this.getData.pharmacy;
    this.pharmacyID = this.getData.hospitals[0].hptl_clinic_id;
    this.pharmaName = this.getData.hospitals[0].hptl_name;
    this.location = this.getData.hospitals[0].location_desc;
    this.city = this.getData.hospitals[0].city_desc;
    this.state = this.getData.hospitals[0].state_desc;
    this.country = this.getData.hospitals[0].country_desc;
    this.pharmaAddress1 = this.getData.hospitals[0].address1 != undefined && this.getData.hospitals[0].address1 != null && this.getData.hospitals[0].address1 != "" && this.getData.hospitals[0].address1 != "undefined" ? this.getData.hospitals[0].address1 : undefined;
    this.pharmaAddress2 = this.getData.hospitals[0].address2 != undefined && this.getData.hospitals[0].address2 != null && this.getData.hospitals[0].address2 != "" && this.getData.hospitals[0].address2 != "undefined" ? this.getData.hospitals[0].address2 : undefined;
    this.show_hsn = this.getData.hospitals[0].show_hsn;
    this.dlNO = this.getData.hospitals[0].dl_no;
    this.GSTNo = this.getData.hospitals[0].gst_no;
    this.intakereq = this.getData.hospitals[0].intake_required;
    if (this.getData.hospitals[0].price_edit != undefined) {
      this.priceedit = this.getData.hospitals[0].price_edit;
    } else {
      this.priceedit = "0"
    }

    this.medicareName = this.getData.Medicare_name != undefined && this.getData.Medicare_name != "undefined" ? this.getData.Medicare_name : "allopathy";
    this.pharmaLogo = ipaddress.Ip_with_img_address + this.getData.hospitals[0].bill_logo;
    this.billTemplate = this.getData.hospitals[0].bill_template;
    this.bgImage = this.getData.hospitals[0].bg_image != undefined ? this.getData.hospitals[0].bg_image : undefined;
    this.productReturn = this.getData.hospitals[0].product_return;
    if (this.getData.hospitals[0].rackbin_req == "0") {
      this.rack_binrequire = true
    } else {
      this.rack_binrequire = false
    }
    if (this.getData.hospitals[0].bill_pay_type_req == "1") {
      this.paytypreq = true;
    } else {
      this.paytypreq = false;
    }
    this.CurrentDate();
    this.getPharmacyDetails();
    this.getDiscTypes();
    this.getDiscountDetails();
    this.getPaymentType();
    // this.getIntakeSession();
    this.filter = "bill_no";
    this.changeFilter()
    this.dayDuration = "day(s)";
    this.Duration();
    this.afterfoodText = "After food";
    this.addNewDrugDetails();
    this.addreturnsnewDrugDetails()
    this.changeIntake(this.drugDetailsArray);
    this.changeIntake(this.returnsDetailsArray);
    var Moduleidlist;
    if (pharmacy_helper.getModuleList() != null) {
      Moduleidlist = pharmacy_helper.getModuleList();
      for (var i = 0; i < Moduleidlist.length; i++) {
        if (Moduleidlist[i] == "99") {
          this.genericDisplayFlag = true;
        }
      }
    }
    this.doctorName = "All";
    this.docRegID = "All";
    this.hospitalID = Helper_Class.getInfo().hospitals[0].hosp_id;
    this.Select_refer_doctor("All");

    if (Helper_Class.getmodulelist() != undefined) {
      console.log("id" + JSON.stringify(Helper_Class.getmodulelist()))
      for (var i = 0; i < Helper_Class.getmodulelist().length; i++) {
        if (Helper_Class.getmodulelist()[i].module_id == "45" && Helper_Class.getmodulelist()[i].description == "Orders - current") {

          if (Helper_Class.getmodulelist()[i].edit == "1") {
            this.editbutton = true;
          }
          if (Helper_Class.getmodulelist()[i].create == "1") {
            this.newbutton = true;
          }
          if (Helper_Class.getmodulelist()[i].delete == "1") {
            this.deletebutton = true;
          }
          if (Helper_Class.getmodulelist()[i].print == "1") {
            this.printbutton = true;
          }
          if (Helper_Class.getmodulelist()[i].view == "1") {
            this.viewbutton = true;
          }
        }
      }
    }
    this.docsalflag = true;
    this.patsearchtype = "name";
    this.patient_detailsflag = true;
    this.aftdeletflag = true;
  }

  public loadScript() {
    let body = <HTMLDivElement>document.body;
    let script = document.createElement('script');
    script.innerHTML = '';
    script.src = 'assets/dist/inpatient-write-rx.js';
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }

  addNewDrugDetails() {
    // if(this.hiddeaddbutton == false){
    var index = 0;
    this.MedicineNameArray = [];
    this.medicineListArray = [];
    this.medicineArray = [];
    if (this.drugDetailsArray.length == 0) {
      this.drugDetailsArray.push({
        sno: "1",
        drug_id: "",
        type_name: "",
        drug_name: "",
        drug_disp_name: "",
        quantity: "",
        tempquantity: "",
        amount: "",
        price: "",
        discount: "",
        per_unit: "",
        prod_disc: "",
        batch_no: "",
        amt_bdgst: "",
        cgst: "",
        sgst: "",
        expiry_date: "",
        rec_item_id: "",
        index: index,
        batch_required: { "border-color": "rgb(250, 40, 2)" },
        batcharray: "",
        // day_txt: "",
        daydur_txt: "day(s)",
        afterfood_txt: "After food",
        dure_txt: "30",
        intake_txt: "Thrice a day",
        morning: 1,
        afternoon: 1,
        evening: 0,
        night: 1,
        every_six: "1 - 1 - 1",
        mor_lable: false,
        quan_lable: true,
        show_batcharray: false,
        quantityDisable: true,
        rack_id: "",
        bin_id: "",
        look_alike: "",
        sound_alike: "",
        high_risk: "",
        ward_only: "",
        look_alikevalue: "",
        sound_alikevalue: "",
        high_riskvalue: "",
        ward_onlyvalue: "",
      });

    } else {

      for (var i = (this.drugDetailsArray.length - 1); i < this.drugDetailsArray.length; i++) {
        this.MedicineNameArray = [];
        this.medicineArray = [];
        this.medicineListArray = [];
        if (this.drugDetailsArray[i].drug_name != "" && this.drugDetailsArray[i].batch_no != "" && this.drugDetailsArray[i].quantity != "") {
          index = this.drugDetailsArray[i].index + 1;
          this.drugDetailsArray.push({
            sno: index + 1,
            drug_id: "",
            type_name: "",
            drug_name: "",
            drug_disp_name: "",
            quantity: 0,
            tempquantity: "",
            amount: "",
            price: "",
            discount: "",
            per_unit: "",
            prod_disc: "",
            batch_no: "",
            amt_bdgst: "",
            cgst: "",
            sgst: "",
            expiry_date: "",
            rec_item_id: "",
            index: index,
            batch_required: { "border-color": "rgb(250, 40, 2)" },
            batcharray: "",
            // day_txt: "",
            daydur_txt: "day(s)",
            afterfood_txt: "After food",
            dure_txt: "30",
            intake_txt: "Thrice a day",
            morning: 1,
            afternoon: 1,
            evening: 0,
            night: 1,
            every_six: "1 - 1 - 1",
            mor_lable: false,
            quan_lable: true,
            show_batcharray: false,
            quantityDisable: true,
            rack_id: "",
            bin_id: "",
            look_alike: "",
            sound_alike: "",
            high_risk: "",
            ward_only: "",
            look_alikevalue: "",
            sound_alikevalue: "",
            high_riskvalue: "",
            ward_onlyvalue: "",
          });
        } else {
          this.toastr.error(Message_data.checkDrugListEmpty);
        }
        break;
      }
    }
    setTimeout(function () {
      $(".getMedcien").last().focus();
    }, 500);
    // }

  }

  addreturnsnewDrugDetails() {
    var index = 0;
    this.returnsDetailsArray.push({
      drug_id: "",
      type_name: "",
      drug_name: "",
      drug_disp_name: "",
      quantity: "",
      tempquantity: "",
      amount: "",
      price: "",
      discount: "",
      per_unit: "",
      prod_disc: "",
      batch_no: "",
      amt_bdgst: "",
      cgst: "",
      sgst: "",
      expiry_date: "",
      rec_item_id: "",
      index: index,
      batch_required: { "border-color": "rgb(250, 40, 2)" },
      batcharray: "",
      day_txt: "",
      daydur_txt: "day(s)",
      afterfood_txt: "After food",
      dure_txt: "30",
      intake_txt: "Thrice a day",
      mor: "",
      aft: "",
      eve: "",
      ngt: "",
      every_six: "1 - 1 - 1",
      mor_lable: false,
      quan_lable: true,
      show_batcharray: false,
      quantityDisable: true,
      rack_id: "",
      bin_id: ""
    });
    setTimeout(function () {
      $(".getMedcien").last().focus();
    }, 500);
  }

  //Get payment type
  getPaymentType() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'bill/paytype',
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          this.payTypeListArray = obj.payments;
          this.payType1 = this.payTypeListArray[0].pay_id;
          this.payType = this.payTypeListArray[0].pay_id;
          this.payDes = this.payTypeListArray[0].pay_desc;
          this.getInsurers();
        },
        error => {
          this.toastr.error(Message_data.network);
        });
  }

  //Get payment type
  getInsurers() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'bill/gins',
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          this.insurerListArray = obj.insurers;
        },
        error => {
          this.toastr.error(Message_data.network);
        });
  }

  changePay() {
    document.getElementById("paytype")!.style.borderColor = '#2ca8fe';
    this.insurFlag = true;
    this.tranFlag = true;
    this.cardFlag = true;
    this.chequeFlag = true;
    this.payTypeListArray.forEach(element => {
      if (element.pay_id == this.payType) {
        this.payDes = element.pay_desc;
      }
    });
    if (this.payType == "3") {
      this.insurFlag = false;
    } else if (this.payType == "4") {
      this.tranFlag = false;
    } else if (this.payType == "2") {
      this.tranFlag = false;
      this.cardFlag = false;
    } else if (this.payType == "5") {
      this.chequeFlag = false;
    } else {
      this.insurFlag = true;
    }
  }

  changeIntake(drug_detailsarray) {//Intake change
    if (drug_detailsarray.afterfood_txt == "After food" || drug_detailsarray.afterfood_txt == "Before food") {
      drug_detailsarray.dure_show = false;
      drug_detailsarray.dure_require = true;
      drug_detailsarray.dure_txt = "30";
    } else {
      drug_detailsarray.dure_show = true;
      drug_detailsarray.dure_require = false;
      drug_detailsarray.dure_txt = undefined;
    }
  }

  changeMedicineName(e, type) {//Get medicine name
    var senddetails = JSON.stringify({
      drug_name: e.toString(),
      pharmacy_id: this.pharmacyID,
      medicare_name: "1",
      type: type
    });

    if (e.toString() != null && e.toString().length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'pharmacontrol/nmeddetails/', senddetails,
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            console.log("obj" + JSON.stringify(obj));
            if (obj.med_details != null) {
              this.MedicineNameArray = [];
              this.medicineListArray = [];
              this.medicineArray = [];
              this.medicineArrayreturns = [];
              this.medicinecolorarray = [];
              // if(this.hiddeaddbutton == false){
              for (var i = 0; i < obj.med_details.length; i++) {

                if (obj.med_details[i].short_name == undefined) {
                  obj.med_details[i].short_name = ""
                }
                var medname = obj.med_details[i].short_name + " " + obj.med_details[i].med_name;
                if (obj.med_details[i].default_quantity != undefined) {
                  obj.med_details[i].default_quantity = obj.med_details[i].default_quantity
                } else {
                  obj.med_details[i].default_quantity = "0"
                }
                this.medicineArray.push({
                  name: medname,
                  hsn_no: obj.med_details[i].hsn_no,
                  quantity: obj.med_details[i].quantity,
                  generic: obj.med_details[i].generic_name,
                  default_quantity: obj.med_details[i].default_quantity,
                  qty_per_unit: obj.med_details[i].qty_per_unit,
                  no_of_IU: obj.med_details[i].no_of_IU
                });

                this.medicineListArray.push({
                  hsn_no: obj.med_details[i].hsn_no,
                  med_id: obj.med_details[i].med_id,
                  med_name: obj.med_details[i].med_name,
                  med_type_id: obj.med_details[i].med_type_id,
                  med_code: obj.med_details[i].med_code,
                  type_name: obj.med_details[i].type_name,
                  short_name: obj.med_details[i].short_name,
                  short_form: obj.med_details[i].short_form,
                  show_short_form: obj.med_details[i].show_short_form,
                  show_intake: obj.med_details[i].show_intake,
                  medname: medname,
                  cgst: obj.med_details[i].cgst,
                  sgst: obj.med_details[i].sgst,
                  quantity: obj.med_details[i].quantity,
                  rack_id: obj.med_details[i].rack_id,
                  rack_desc: obj.med_details[i].rack_desc,
                  bin_id: obj.med_details[i].bin_id,
                  bin_desc: obj.med_details[i].bin_desc,
                  default_quantity: obj.med_details[i].default_quantity,
                  look_alike: obj.med_details[i].look_alike,
                  sound_alike: obj.med_details[i].sound_alike,
                  high_risk: obj.med_details[i].high_risk,
                  ward_only: obj.med_details[i].ward_only,
                  qty_per_unit: obj.med_details[i].qty_per_unit,
                  no_of_IU: obj.med_details[i].no_of_IU
                })
              }
              // }
              console.log("this.medicineListArray" + JSON.stringify(this.medicineArray));
            }
          },
          error => { }
        )
    } else {
      this.MedicineNameArray = [];
    }
  }

  changeMedicineNamereturns(e, type) {//Get medicine name
    var senddetails = JSON.stringify({
      drug_name: e.toString(),
      pharmacy_id: this.pharmacyID,
      medicare_name: "1",
      type: type
    });

    if (e.toString() != null && e.toString().length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'pharmacontrol/nmeddetails/', senddetails,
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            console.log("obj" + JSON.stringify(obj));
            if (obj.med_details != null) {
              this.MedicineNameArrayreturns = [];
              this.medicineListArrayreturns = [];
              this.medicineArrayreturns = [];
              this.medicineArrayreturns = [];

              // if(this.hiddeaddbutton == true){
              for (var i = 0; i < obj.med_details.length; i++) {
                if (obj.med_details[i].default_quantity != undefined) {
                  obj.med_details[i].default_quantity = obj.med_details[i].default_quantity
                } else {
                  obj.med_details[i].default_quantity = "0"
                }
                var medname = obj.med_details[i].short_name + " " + obj.med_details[i].med_name
                this.medicineArrayreturns.push({ name: medname, quantity: obj.med_details[i].quantity, generic: obj.med_details[i].generic_name, default_quantity: obj.med_details[i].default_quantity });
                this.medicineListArrayreturns.push({
                  med_id: obj.med_details[i].med_id,
                  med_name: obj.med_details[i].med_name,
                  med_type_id: obj.med_details[i].med_type_id,
                  med_code: obj.med_details[i].med_code,
                  type_name: obj.med_details[i].type_name,
                  short_name: obj.med_details[i].short_name,
                  short_form: obj.med_details[i].short_form,
                  show_short_form: obj.med_details[i].show_short_form,
                  show_intake: obj.med_details[i].show_intake,
                  medname: medname,
                  cgst: obj.med_details[i].cgst,
                  sgst: obj.med_details[i].sgst,
                  quantity: obj.med_details[i].quantity,
                  rack_id: obj.med_details[i].rack_id,
                  bin_id: obj.med_details[i].bin_id,
                  default_quantity: obj.med_details[i].default_quantity
                })
              }
              // }


              // this.MedicineNameArray = this.medicineArray.filter(function (this: any, el) {
              //   return el.toLowerCase().indexOf(e.target.value.toString().toLowerCase()) > -1;
              // }.bind(this));
              console.log("this.medicineListArray" + JSON.stringify(this.medicineArrayreturns));
            }
          },
          error => { }
        )
    } else {
      this.MedicineNameArrayreturns = [];
    }
  }

  selectMedicine(item, drug_detailsarray) {
    // if(this.hiddeaddbutton == false){
    console.log("medicine array  = " + JSON.stringify(this.medDetailListArray))
    this.selectedbatch_flag = false;
    for (var i = 0; i < this.medicineListArray.length; i++) {

      var shortName;

      if (this.medicineListArray[i].show_short_form == "1") {
        shortName = this.medicineListArray[i].short_name;
      } else {
        shortName = "";
      }
      if (item.name == this.medicineListArray[i].medname) {



        if (this.medicineArray[i].ward_only != undefined && this.medicineArray[i].ward_only != '0') {
          if (this.wardflag == true) {
            drug_detailsarray.gen_name = item.generic;
            drug_detailsarray.short_name = this.medicineListArray[i].short_name;
            drug_detailsarray.drug_name = item.name;
            if (this.medicineArray[i].short_name != undefined) {
              drug_detailsarray.drug_disp_name = this.medicineListArray[i].short_name + " " + this.medicineListArray[i].med_name;
            } else {
              drug_detailsarray.drug_disp_name = this.medicineListArray[i].med_name;
            }
            drug_detailsarray.drug_id = this.medicineListArray[i].med_id;
            this.medicineName = this.medicineListArray[i].med_name;
            drug_detailsarray.cgst = this.medicineListArray[i].cgst;
            drug_detailsarray.sgst = this.medicineListArray[i].sgst;
            drug_detailsarray.type_name = this.medicineListArray[i].type_name;
            drug_detailsarray.drug_typetxt = this.medicineListArray[i].med_type_id;
            drug_detailsarray.short_form = this.medicineListArray[i].short_form;
            drug_detailsarray.show = this.medicineListArray[i].show_short_form;
            drug_detailsarray.show_intake = this.medicineListArray[i].show_intake;
            this.medicalPrescriptionArray = this.medicineListArray;
            drug_detailsarray.shortname = shortName;
            drug_detailsarray.rack_id = this.medicineListArray[i].rack_id
            drug_detailsarray.rack_desc = this.medicineListArray[i].rack_desc
            drug_detailsarray.bin_id = this.medicineListArray[i].bin_id;
            drug_detailsarray.bin_desc = this.medicineListArray[i].bin_desc;
            drug_detailsarray.hsn_no = this.medicineArray[i].hsn_no;
            drug_detailsarray.default_quantity = this.medicineArray[i].default_quantity
            drug_detailsarray.day_txt = "1";
            drug_detailsarray.no_of_IU = this.medicineArray[i].no_of_IU;
            drug_detailsarray.qty_per_unit = this.medicineArray[i].qty_per_unit;

            drug_detailsarray.ins_allow = "0"
            if (this.intakereq == "1") {
              if (drug_detailsarray.show_intake == "0") {
                drug_detailsarray.day_txtflag = "false";
                drug_detailsarray.show_intakeFlag = "true";

                drug_detailsarray.intake_txt = "Alternative day"
                drug_detailsarray.afterfood_txt = "Select"
                drug_detailsarray.morning = "0";
                drug_detailsarray.afternoon = "0";
                drug_detailsarray.evening = "0";
                drug_detailsarray.night = "0";
              } else {
                drug_detailsarray.day_txtflag = "true";
                drug_detailsarray.show_intakeFlag = "false";
                this.getIntakeName(drug_detailsarray)
              }
            } else {
              drug_detailsarray.day_txtflag = "false";
              drug_detailsarray.show_intakeFlag = "true";

              drug_detailsarray.intake_txt = "Alternative day"
              drug_detailsarray.afterfood_txt = "Select"
              drug_detailsarray.morning = "0";
              drug_detailsarray.afternoon = "0";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "0";
            }
            drug_detailsarray.shortname = shortName;
            if (this.medicineListArray[i].look_alike != undefined && this.medicineListArray[i].look_alike != '0') {
              drug_detailsarray.look_alike = this.lookalike
            } else {
              drug_detailsarray.look_alike = ''
            }

            if (this.medicineListArray[i].sound_alike != undefined && this.medicineListArray[i].sound_alike != '0') {
              drug_detailsarray.sound_alike = this.soundalike
            } else {
              drug_detailsarray.sound_alike = ''
            }

            if (this.medicineListArray[i].high_risk != undefined && this.medicineListArray[i].high_risk != '0') {
              drug_detailsarray.high_risk = this.highrisk
            } else {
              drug_detailsarray.high_risk = ''
            }

            if (this.medicineListArray[i].ward_only != undefined && this.medicineListArray[i].ward_only != '0') {
              drug_detailsarray.ward_only = this.ward
            } else {
              drug_detailsarray.ward_only = ''
            }

            if (this.medicineArray[i].look_alike != undefined && this.medicineArray[i].look_alike != '0') {
              drug_detailsarray.look_alikevalue = "Look a like"
            } else {
              drug_detailsarray.look_alikevalue = ''
            }

            if (this.medicineArray[i].sound_alike != undefined && this.medicineArray[i].sound_alike != '0') {
              drug_detailsarray.sound_alikevalue = "Sound a like"
            } else {
              drug_detailsarray.sound_alikevalue = ''
            }

            if (this.medicineArray[i].high_risk != undefined && this.medicineArray[i].high_risk != '0') {
              drug_detailsarray.high_riskvalue = "High risk"
            } else {
              drug_detailsarray.high_riskvalue = ''
            }

            if (this.medicineArray[i].ward_only != undefined && this.medicineArray[i].ward_only != '0') {
              drug_detailsarray.ward_onlyvalue = "IC & Ward"
            } else {
              drug_detailsarray.ward_onlyvalue = ''
            }

          } else {
            drug_detailsarray.drug_name = ''
            this.medicineListArray = []
            this.toastr.error("The medicine is ICU and Ward based medicine")
          }
        } else {
          drug_detailsarray.gen_name = item.generic;
          drug_detailsarray.short_name = this.medicineListArray[i].short_name;
          drug_detailsarray.drug_name = item.name;
          if (this.medicineArray[i].short_name != undefined) {
            drug_detailsarray.drug_disp_name = this.medicineListArray[i].short_name + " " + this.medicineListArray[i].med_name;
          } else {
            drug_detailsarray.drug_disp_name = this.medicineListArray[i].med_name;
          }
          drug_detailsarray.drug_id = this.medicineListArray[i].med_id;
          this.medicineName = this.medicineListArray[i].med_name;
          drug_detailsarray.cgst = this.medicineListArray[i].cgst;
          drug_detailsarray.sgst = this.medicineListArray[i].sgst;
          drug_detailsarray.type_name = this.medicineListArray[i].type_name;
          drug_detailsarray.drug_typetxt = this.medicineListArray[i].med_type_id;
          drug_detailsarray.short_form = this.medicineListArray[i].short_form;
          drug_detailsarray.show = this.medicineListArray[i].show_short_form;
          drug_detailsarray.show_intake = this.medicineListArray[i].show_intake;
          this.medicalPrescriptionArray = this.medicineListArray;
          drug_detailsarray.shortname = shortName;
          drug_detailsarray.rack_id = this.medicineListArray[i].rack_id
          drug_detailsarray.rack_desc = this.medicineListArray[i].rack_desc
          drug_detailsarray.bin_id = this.medicineListArray[i].bin_id;
          drug_detailsarray.bin_desc = this.medicineListArray[i].bin_desc;
          drug_detailsarray.hsn_no = this.medicineArray[i].hsn_no;
          drug_detailsarray.default_quantity = this.medicineArray[i].default_quantity
          drug_detailsarray.day_txt = "1";
          drug_detailsarray.ins_allow = "0";
          drug_detailsarray.no_of_IU = this.medicineArray[i].no_of_IU;
          drug_detailsarray.qty_per_unit = this.medicineArray[i].qty_per_unit
          if (this.intakereq == "1") {
            if (drug_detailsarray.show_intake == "0") {
              drug_detailsarray.day_txtflag = "false";
              drug_detailsarray.show_intakeFlag = "true";

              drug_detailsarray.intake_txt = "Alternative day"
              drug_detailsarray.afterfood_txt = "Select"
              drug_detailsarray.morning = "0";
              drug_detailsarray.afternoon = "0";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "0";
            } else {
              drug_detailsarray.day_txtflag = "true";
              drug_detailsarray.show_intakeFlag = "false";
              this.getIntakeName(drug_detailsarray)
            }
          } else {
            drug_detailsarray.day_txtflag = "false";
            drug_detailsarray.show_intakeFlag = "true";

            drug_detailsarray.intake_txt = "Alternative day"
            drug_detailsarray.afterfood_txt = "Select"
            drug_detailsarray.morning = "0";
            drug_detailsarray.afternoon = "0";
            drug_detailsarray.evening = "0";
            drug_detailsarray.night = "0";
          }
          drug_detailsarray.shortname = shortName;
          if (this.medicineListArray[i].look_alike != undefined && this.medicineListArray[i].look_alike != '0') {
            drug_detailsarray.look_alike = this.lookalike
          } else {
            drug_detailsarray.look_alike = ''
          }

          if (this.medicineListArray[i].sound_alike != undefined && this.medicineListArray[i].sound_alike != '0') {
            drug_detailsarray.sound_alike = this.soundalike
          } else {
            drug_detailsarray.sound_alike = ''
          }

          if (this.medicineListArray[i].high_risk != undefined && this.medicineListArray[i].high_risk != '0') {
            drug_detailsarray.high_risk = this.highrisk
          } else {
            drug_detailsarray.high_risk = ''
          }

          if (this.medicineListArray[i].ward_only != undefined && this.medicineListArray[i].ward_only != '0') {
            drug_detailsarray.ward_only = this.ward
          } else {
            drug_detailsarray.ward_only = ''
          }

          if (this.medicineArray[i].look_alike != undefined && this.medicineArray[i].look_alike != '0') {
            drug_detailsarray.look_alikevalue = "Look a like"
          } else {
            drug_detailsarray.look_alikevalue = ''
          }

          if (this.medicineArray[i].sound_alike != undefined && this.medicineArray[i].sound_alike != '0') {
            drug_detailsarray.sound_alikevalue = "Sound a like"
          } else {
            drug_detailsarray.sound_alikevalue = ''
          }

          if (this.medicineArray[i].high_risk != undefined && this.medicineArray[i].high_risk != '0') {
            drug_detailsarray.high_riskvalue = "High risk"
          } else {
            drug_detailsarray.high_riskvalue = ''
          }

          if (this.medicineArray[i].ward_only != undefined && this.medicineArray[i].ward_only != '0') {
            drug_detailsarray.ward_onlyvalue = "ICU & Ward"
          } else {
            drug_detailsarray.ward_onlyvalue = ''
          }

        }
        console.log("alert = " + JSON.stringify(this.medicineListArray[i]));




      }

      this.medicineArray.forEach(medicine => {
        let lookAlikeColor = medicine.look_alike ? this.lookalike : '';
        let soundAlikeColor = medicine.sound_alike ? this.soundalike : '';
        let highRiskColor = medicine.high_risk ? this.highrisk : '';
        let wardOnlyColor = medicine.ward_only ? this.ward : '';

        this.medicinecolorarray.push(
          { type: 'look_alike', color: lookAlikeColor },
          { type: 'sound_alike', color: soundAlikeColor },
          { type: 'high_risk', color: highRiskColor },
          { type: 'ward_only', color: wardOnlyColor }
        );
      });


    }
    // }
    this.aftdeletflag = true;
    this.getItemPrices(drug_detailsarray);
    drug_detailsarray.batcharray = [];
    drug_detailsarray.batch_no = "";
    drug_detailsarray.quantityDisable = true;
    this.MedicineNameArray = [];
  }

  getLeftPosition(index: number): number {
    return 5 + index * 1; // Adjust the multiplier (15) for spacing between squares
  }

  selectMedicineretuns(item, drug_detailsarray) {
    // if(this.hiddeaddbutton == true){

    for (var i = 0; i < this.medicineListArrayreturns.length; i++) {

      var shortName;

      if (this.medicineListArrayreturns[i].show_short_form == "1") {
        shortName = this.medicineListArrayreturns[i].short_name;
      } else {
        shortName = "";
      }


      if (item.name == this.medicineListArrayreturns[i].medname) {
        drug_detailsarray.gen_name = item.generic;
        drug_detailsarray.short_name = this.medicineListArrayreturns[i].short_name;
        drug_detailsarray.drug_name = item.name;
        drug_detailsarray.drug_disp_name = this.medicineListArrayreturns[i].short_name + " " + this.medicineListArrayreturns[i].med_name;
        drug_detailsarray.drug_id = this.medicineListArrayreturns[i].med_id;
        this.medicineName = this.medicineListArrayreturns[i].med_name;
        drug_detailsarray.cgst = this.medicineListArrayreturns[i].cgst;
        drug_detailsarray.sgst = this.medicineListArrayreturns[i].sgst;
        drug_detailsarray.type_name = this.medicineListArrayreturns[i].type_name;
        drug_detailsarray.drug_typetxt = this.medicineListArrayreturns[i].med_type_id;
        drug_detailsarray.short_form = this.medicineListArrayreturns[i].short_form;
        drug_detailsarray.show = this.medicineListArrayreturns[i].show_short_form;
        drug_detailsarray.show_intake = this.medicineListArrayreturns[i].show_intake;
        this.medicalPrescriptionArray = this.medicineListArrayreturns;
        drug_detailsarray.shortname = shortName;
        drug_detailsarray.rack_id = this.medicineListArrayreturns[i].rack_id
        drug_detailsarray.bin_id = this.medicineListArrayreturns[i].bin_id;


      }
    }
    this.aftdeletflag = true;
    this.getItemPrices(drug_detailsarray);
    drug_detailsarray.batcharray = [];
    drug_detailsarray.batch_no = "";
    drug_detailsarray.quantityDisable = true;
    this.MedicineNameArrayreturns = [];
    // this.hideBatchArrayreturns(drug_detailsarray);
  }

  getIntakeName(drug_detailsarray) {
    var send_data = {
      product_id: drug_detailsarray.drug_id
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'pharmacontrol/gis', send_data,
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          console.log("this.getIntakeArray" + JSON.stringify(this.getIntakeArray));
          for (var i = 0; i < this.getIntakeArray.length; i++) {
            if (obj.intake == this.getIntakeArray[i].drug_int_id) {
              drug_detailsarray.intake_txt = this.getIntakeArray[i].description;
              drug_detailsarray.intakeid = this.getIntakeArray[i].drug_int_id;
              this.intake_id = this.getIntakeArray[i].drug_int_id;
            }
          }
          this.sesssionBaseChange(drug_detailsarray);

        })
  }

  hideBatchArray(drug_detailsarray) {
    // if(this.hiddeaddbutton == false){
    if (this.drugDetailsArray.length > 1) {
      for (var i = 0; i < this.drugDetailsArray.length; i++) {
        if (drug_detailsarray.index == this.drugDetailsArray[i].index)
          document.getElementById('drug_' + drug_detailsarray.index).style.display = 'block';
        else
          document.getElementById('drug_' + drug_detailsarray.index).style.display = 'none';

      }
    }
    // }
  }

  hideBatchArrayreturns(drug_detailsarray) {
    // if(this.hiddeaddbutton == true){
    if (this.returnsDetailsArray.length > 1) {
      for (var i = 0; i < this.returnsDetailsArray.length; i++) {
        if (drug_detailsarray.index == this.drugDetailsArray[i].index)
          document.getElementById('drug_' + drug_detailsarray.index).style.display = 'block';
        else
          document.getElementById('drug_' + drug_detailsarray.index).style.display = 'none';

      }
    }
    // }
  }

  getItemPrices(drug_detailsarray) {

    this.checkbatchnoarray = [];
    this.sendData = {
      pharma_id: this.pharmacyID,
      drug_ids: drug_detailsarray.drug_id
    }

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "pharmacontrol/ipnopres/", JSON.stringify(this.sendData), { headers: headers }).subscribe(
      data => {
        var dataval = data.json();
        if (dataval != undefined && dataval.length != 0) {
          drug_detailsarray.batcharray = [];
          if (this.tempBatch == undefined) {
            this.qty = "";
            this.amt = "";
            this.price = "";
          }
          var index = 0;
          if (dataval.pharma_price.length == 1) {
            this.rackId = dataval.pharma_price[0].rackid;
            this.binId = dataval.pharma_price[0].binid;
          } else if (dataval.pharma_price.length == 0) {
            this.rackId = dataval.pharma_price[0].rackid;
            this.binId = dataval.pharma_price[0].binid;
          }
          else {
            this.rackId = dataval.pharma_price[1].rackid;
            this.binId = dataval.pharma_price[1].binid;
          }

          for (var i = 0; i < dataval.pharma_price.length; i++) {
            if (this.aftdeletflag == true) {
              this.drugArray.push({
                drug_id: dataval.pharma_price[i].pharma_price,
                drug_name: dataval.pharma_price[i].drug_name,
                price_txt: (dataval.pharma_price[i].price / dataval.pharma_price[i].per_unit).toFixed(2),
                per_unit: dataval.pharma_price[i].per_unit,
                batch_no: dataval.pharma_price[i].batch_no,
                quantity: dataval.pharma_price[i].avail_qty,
                discount: dataval.pharma_price[i].prod_disc,
                cgst: dataval.pharma_price[i].prod_cgst,
                sgst: dataval.pharma_price[i].prod_sgst,
                price: (dataval.pharma_price[i].price / dataval.pharma_price[i].per_unit).toFixed(2),
                mrp: (dataval.pharma_price[i].price / dataval.pharma_price[i].per_unit).toFixed(2),
                sales_price: dataval.pharma_price[i].sales_price,
                cost_price: dataval.pharma_price[i].cost_price,
                expiry_date: dataval.pharma_price[i].expiry_date,
                rec_item_id: dataval.pharma_price[i].rec_item_id,
                index: index,
              });

              var color = this.checkExpiryDate(data.json().pharma_price[i].expiry_date);
              drug_detailsarray.batch_no = dataval.pharma_price[0].batch_no;
              drug_detailsarray.expiry_date = dataval.pharma_price[0].expiry_date;

              if (this.discountDatasArray.length != 0) {
                if (this.discountdesc == "cost plus gst") {
                  if (dataval.pharma_price[0].cost_price != undefined)
                    drug_detailsarray.price = (dataval.pharma_price[0].cost_price / dataval.pharma_price[0].per_unit).toFixed(2);
                  else
                    drug_detailsarray.price = (0.00 / dataval.pharma_price[0].per_unit).toFixed(2);
                }
                else if (this.discountdesc == "sales plus gst") {

                  if (dataval.pharma_price[0].sales_price != undefined)
                    drug_detailsarray.price = (dataval.pharma_price[0].sales_price / dataval.pharma_price[0].per_unit).toFixed(2);
                  else
                    drug_detailsarray.price = (0.00 / dataval.pharma_price[0].per_unit).toFixed(2);
                }
                else if (this.discountdesc == "MRP") {

                  if (dataval.pharma_price[0].price != undefined)
                    drug_detailsarray.price = (dataval.pharma_price[0].price / dataval.pharma_price[0].per_unit).toFixed(2);
                  else
                    drug_detailsarray.price = (0.00 / dataval.pharma_price[0].per_unit).toFixed(2);

                } else {
                  drug_detailsarray.price = (dataval.pharma_price[0].price / dataval.pharma_price[0].per_unit).toFixed(2)
                }
              }


              drug_detailsarray.mrp = (dataval.pharma_price[0].price / dataval.pharma_price[0].per_unit).toFixed(2);
              drug_detailsarray.per_unit = dataval.pharma_price[0].per_unit;
              drug_detailsarray.batch_required = { "border-color": "#2ca8fe", };
              drug_detailsarray.rec_item_id = dataval.pharma_price[0].rec_item_id;
              drug_detailsarray.sales_price = dataval.pharma_price[0].sales_price;
              drug_detailsarray.cost_price = dataval.pharma_price[0].cost_price;
              console.log("rect_item-id" + JSON.stringify(drug_detailsarray.rec_item_id))
              drug_detailsarray.batcharray.push({
                index: index,
                batch_no: dataval.pharma_price[i].batch_no,
                rec_item_id: data.json().pharma_price[i].rec_item_id,
                mrp: (dataval.pharma_price[i].price / dataval.pharma_price[i].per_unit).toFixed(2),
                quantity: dataval.pharma_price[i].avail_qty,
                expiry_date: data.json().pharma_price[i].expiry_date,
                color: color
              })
            }
            this.checkbatchnoarray.push({
              price_txt: (dataval.pharma_price[i].price / dataval.pharma_price[i].per_unit).toFixed(2),
              per_unit: dataval.pharma_price[i].per_unit,
              batch_no: dataval.pharma_price[i].batch_no,
              quantity: dataval.pharma_price[i].avail_qty,
              discount: dataval.pharma_price[i].prod_disc,
              cgst: dataval.pharma_price[i].prod_cgst,
              sgst: dataval.pharma_price[i].prod_sgst,
              expiry_date: dataval.pharma_price[i].expiry_date,
              rec_item_id: dataval.pharma_price[i].rec_item_id,
              index: index,
              rack_id: dataval.pharma_price[i].rackid,
              bin_id: dataval.pharma_price[i].binid
            });
            index++;
          }
          this.batcharraylength = drug_detailsarray.batcharray.length
          if (this.selectedbatch_flag == false) {
            this.checkquantitybatch = dataval.pharma_price[0].batch_no;
            this.checkquantityindex = 0;
            this.backenddrugquantity = drug_detailsarray.quantity;
            this.checkbackendquantity = dataval.pharma_price[0].avail_qty;
          }
        }
      }, error => { }
    );
  }

  selectedBatch(batch, drug_detailsarray, index) {
    this.firstItem = [];
    this.selectedbatch_flag = true;
    //  if(this.hiddeaddbutton == false){
    this.checkquantitybatch = batch.batch_no;
    this.checkquantityindex = batch.index;
    this.checkbackendquantity = batch.quantity;
    var checkList = this.fineDrugList(batch.batch_no, drug_detailsarray.drug_id);
    this.backenddrugquantity = drug_detailsarray.quantity
    // if( checkList != true){
    this.showBatchArray = false;
    drug_detailsarray.batch_required = { "border-color": "#2ca8fe", };
    drug_detailsarray.batch_no = batch.batch_no;
    if (drug_detailsarray.batch_no != "")
      drug_detailsarray.quantityDisable = false;

    for (var i = 0; i < this.drugArray.length; i++) {
      if (batch.batch_no == this.drugArray[i].batch_no) {
        if (batch.index == this.drugArray[i].index) {
          if (this.tempBatch != undefined) {
            if (this.discountdesc == "cost plus gst") {
              drug_detailsarray.price = (this.drugArray[i].cost_price / this.drugArray[i].per_unit).toFixed(2);
            }
            else if (this.discountdesc == "sales plus gst") {
              drug_detailsarray.price = (this.drugArray[i].sales_price / this.drugArray[i].per_unit).toFixed(2);
            }
            else if (this.discountdesc == "MRP") {
              drug_detailsarray.price = (this.drugArray[i].price / this.drugArray[i].per_unit).toFixed(2);
            } else {
              drug_detailsarray.price = this.drugArray[i].price_txt
            }
            drug_detailsarray.per_unit = this.drugArray[i].per_unit;
            //newly added
            drug_detailsarray.stripprice = this.drugArray[i].stripprice;
            drug_detailsarray.sales_price = this.drugArray[i].sales_price;
            drug_detailsarray.cost_price = this.drugArray[i].cost_price;
            drug_detailsarray.mrp = this.drugArray[i].mrp;
            drug_detailsarray.rec_item_id = this.drugArray[i].rec_item_id;
          } else {
            if (this.discountdesc == "cost plus gst") {
              drug_detailsarray.price = (this.drugArray[i].cost_price / this.drugArray[i].per_unit).toFixed(2);
            }
            else if (this.discountdesc == "sales plus gst") {
              drug_detailsarray.price = (this.drugArray[i].sales_price / this.drugArray[i].per_unit).toFixed(2);
            }
            else if (this.discountdesc == "MRP") {
              drug_detailsarray.price = (this.drugArray[i].price / this.drugArray[i].per_unit).toFixed(2);
            } else {
              drug_detailsarray.price = this.drugArray[i].price_txt
            }
            ;

            // drug_detailsarray.price = price;
            drug_detailsarray.per_unit = this.drugArray[i].per_unit;
            drug_detailsarray.cgst = this.drugArray[i].cgst;
            drug_detailsarray.sgst = this.drugArray[i].sgst;
            drug_detailsarray.discount = this.drugArray[i].discount;
            drug_detailsarray.expiry_date = this.drugArray[i].expiry_date;
            drug_detailsarray.rec_item_id = this.drugArray[i].rec_item_id;
            drug_detailsarray.stripprice = this.drugArray[i].stripprice;
            drug_detailsarray.sales_price = this.drugArray[i].sales_price;
            drug_detailsarray.cost_price = this.drugArray[i].cost_price;
            drug_detailsarray.mrp = this.drugArray[i].mrp;
          }
          // this.calculateAmount(drug_detailsarray);
          this.discountcalculatestaff();
        }

      }

    }

    drug_detailsarray.show_batcharray = false;
    this.loadScript();
    document.getElementById(index).style.display = 'none';
    document.getElementById("pharma_inpattbl_overlay").style.overflowY = "auto";
    document.getElementById("pharma_inpattbl_overlay").style.overflowX = "auto";
    // }
    // else
    // {
    //   this.toastr.error("Medicine with same batch no already added");
    // }
    // document.getElementById("tbl_med").style.overflowY = "auto";
    console.log("this.drugDetailsArray" + JSON.stringify(this.drugDetailsArray));
    // }

  }

  selectedBatchreturns(batch, drug_detailsarray, index) {

    // if(this.hiddeaddbutton == true){

    var checkList = this.fineDrugListreturns(batch.batch_no, drug_detailsarray.drug_id);
    // this.backenddrugquantity=drug_detailsarray.quantity
    if (checkList != true) {
      this.showBatchArray = false;
      drug_detailsarray.batch_required = { "border-color": "#2ca8fe", };
      drug_detailsarray.batch_no = batch.batch_no;
      if (drug_detailsarray.batch_no != "")
        drug_detailsarray.quantityDisable = false;

      for (var i = 0; i < this.drugArray.length; i++) {
        if (batch.batch_no == this.drugArray[i].batch_no) {
          if (batch.index == this.drugArray[i].index) {
            if (this.tempBatch != undefined) {
              drug_detailsarray.price = this.drugArray[i].price_txt;
              // drug_detailsarray.quantity = this.drugArray[i].quantity;
              // drug_detailsarray.tempquantity = this.drugArray[i].quantity;
            }
            else {
              drug_detailsarray.price = this.drugArray[i].price_txt;
              // drug_detailsarray.quantity = this.drugArray[i].quantity;
              // drug_detailsarray.tempquantity = this.drugArray[i].quantity;
              drug_detailsarray.per_unit = this.drugArray[i].per_unit;
              drug_detailsarray.cgst = this.drugArray[i].cgst;
              drug_detailsarray.sgst = this.drugArray[i].sgst;
              drug_detailsarray.discount = this.drugArray[i].discount;
              drug_detailsarray.expiry_date = this.drugArray[i].expiry_date;
              drug_detailsarray.rec_item_id = this.drugArray[i].rec_item_id
            }
            // this.calculateAmountreturns(drug_detailsarray);
          }
        }

      }
      drug_detailsarray.show_batcharray = false;
      this.loadScript();
      document.getElementById(index).style.display = 'none';
      // document.getElementById("pharma_overlay").style.overflow = "auto";
      document.getElementById("pharma_inpattbl_overlay").style.overflowY = "auto";
      document.getElementById("pharma_inpattbl_overlay").style.overflowX = "auto";
    }
    else {
      this.toastr.error("Medicine with same batch no already added");
    }
    // document.getElementById("tbl_med").style.overflowY = "auto";
    console.log("this.returnsdrugDetailsArray" + JSON.stringify(this.returnsDetailsArray));

    // }

  }

  fineDrugList(batch, drugID) {
    // if(this.hiddeaddbutton == false){
    var getFlag = 0;
    for (var i = 0; i < this.drugDetailsArray.length; i++) {
      if ((this.drugDetailsArray[i].drug_id == drugID) && (this.drugDetailsArray[i].batch_no == batch)) {
        getFlag = 1;
      }
    }
    return getFlag == 1 ? true : false;
    // }



  }
  fineDrugListreturns(batch, drugID) {
    // if(this.hiddeaddbutton == true){
    var getFlag = 0;
    for (var i = 0; i < this.returnsDetailsArray.length; i++) {
      if ((this.returnsDetailsArray[i].drug_id == drugID) && (this.returnsDetailsArray[i].batch_no == batch)) {
        getFlag = 1;
      }
    }
    return getFlag == 1 ? true : false;
    // }
  }
  isReadonly() { return true; }//Read only

  CurrentDate() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');

    this.http.post(ipaddress.getIp + "adm/curdate", JSON.stringify({
      country: ipaddress.country_code,
    }),
      { headers: headers }).subscribe(
        response => {
          //order_details
          var obj = JSON.parse(response["_body"]);
          this.todayDate = obj.current_date;
          this.orderDate = this.todayDate;
          this.normaldt_formate = Date_Formate(this.orderDate)
          this.orderTime = obj.current_time;
          this.currentDate = obj.current_date;
          this.fromDate = obj.current_date;
          this.toDate = obj.current_date;
        },
      )
  }

  getPharmacyDetails() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');

    this.http.post(ipaddress.getIp + "pharmacontrol/gphar", JSON.stringify({
      pharma_id: this.pharmacyID,
    }),
      { headers: headers }).subscribe(
        response => {

          var data = JSON.parse(response["_body"]);
          if (data != undefined) {
            this.pharmacyName = data.pharmacy_name;
            this.pharmacyAddress = data.address1 + ", " + data.address2;
            this.pharmacyCSZ = data.city + ", " + data.state + " - " + data.zipcode;
            this.pharmacyCount = data.country;
            this.pharmacyMobile = data.telephone;
          }
        },
      )
  }
  //
  public drugquantity;

  calculateAmount(drug_detailsarray) {

    // if(this.hiddeaddbutton == false){
    if (drug_detailsarray.quantity != "") {
      var priceval = parseFloat(drug_detailsarray.price);
      if (drug_detailsarray.quantity != undefined) {
        var pricecal: any = priceval * drug_detailsarray.quantity;
      }

      if (drug_detailsarray.discount != null) {
        var disc: any = (pricecal * (drug_detailsarray.discount / 100)).toFixed(2);
        drug_detailsarray.amount = pricecal - disc;
      } else {
        disc = 0;
      }

      var pricegst;
      pricegst = pricecal - disc;
      // drug_detailsarray.amount = pricegst.toFixed(2);
      var dpricewithcal;
      var amount;
      this.drugprice_value = drug_detailsarray.price === undefined ? 0.00 : parseFloat(drug_detailsarray.price);
      var dprice = this.drugprice_value.toFixed(2).toString();
      dpricewithcal = parseFloat(this.drugprice_value.toFixed(2));
      const perUnit = drug_detailsarray.per_unit === undefined || " " || null ? 1 : parseInt(drug_detailsarray.per_unit);
      amount = perUnit !== 0 ? (parseFloat(this.drugprice_value.toFixed(2)) / perUnit * parseInt(drug_detailsarray.quantity)) : 0;
      drug_detailsarray.amount = amount;



      this.prodAmount = pricecal;
    }
    this.calculateDiscount();
    // }
  }

  calculateAmountreturns(drug_detailsarray) {
    // if(this.hiddeaddbutton == true){
    if (drug_detailsarray.quantity != "") {
      var priceval = parseFloat(drug_detailsarray.price);
      if (drug_detailsarray.quantity != undefined) {
        var pricecal: any = priceval * drug_detailsarray.quantity;
      }

      if (drug_detailsarray.discount != null) {
        var disc: any = (pricecal * (drug_detailsarray.discount / 100)).toFixed(2);
        drug_detailsarray.amount = pricecal - disc;
      } else {
        disc = 0;
      }

      var pricegst;
      pricegst = pricecal - disc;
      drug_detailsarray.amount = pricegst.toFixed(2);
      this.prodAmount = pricecal;
    }
    this.calculateDiscountreturns();
    // }
  }

  createReturns():
    void {
    // this.showreturns = !this.showreturns;
    this.showaddbutton = !this.showaddbutton
    this.showreturns = true
    // this.hiddeaddbutton = !this.hiddeaddbutton
    this.hiddeaddbutton = true;
    if (this.returnsDetailsArray.length == 0) {
      this.createReturns();
    }

    // this.hideReturns()
  }

  hideReturns() {
    this.showaddbutton = !this.showaddbutton

    this.showreturns = false;
    this.hiddeaddbutton = false;
    //  if (this.returnsDetailsArray.length == 0) {
    //   this.createReturns();
    // }

    //  this.showaddbutton=false;
    //  this.showreturns = !this.showreturns;
    // this.createReturns()
  }

  quantity(drug_detailsarray) {
    var high_qty_drug_id = drug_detailsarray.drug_id;
    this.drugquantity = parseInt(drug_detailsarray.quantity)
    var batchnoforloop;

    if (this.checkquantityindex != undefined) {
      this.checkbatchlength = this.checkquantityindex + 1
      const firstDrug = this.checkbatchnoarray[this.checkquantityindex];
      this.backenddrugquantity = parseInt(firstDrug.quantity);


      console.log("backend drug quantity", this.backenddrugquantity);

      if ((this.drugquantity > this.backenddrugquantity) && (this.checkbatchlength != this.batcharraylength)) {
        var totalQuantity = 0;
        for (var k = this.checkquantityindex + 1; k < this.checkbatchnoarray.length; k++) {
          var nextIndex = this.checkbatchnoarray[k].index;
          const secondDrug = this.checkbatchnoarray[nextIndex];
          this.firstItem = [];
          this.firstItem.push(secondDrug)
          for (var x = 0; x < this.firstItem.length; x++) {
            var blamt = 0;
            blamt = this.drugquantity - this.backenddrugquantity;
            this.balanceamount = this.drugquantity - this.backenddrugquantity;
            if (parseInt(this.firstItem[x].quantity) < blamt) {
              this.balanceamount = this.firstItem[x].quantity;
            }
            this.drugquantity = blamt;
            this.backenddrugquantity = parseInt(this.firstItem[x].quantity);
            this.selectedDrugs.push(this.firstItem[x])
            drug_detailsarray.quantity = parseInt(this.checkbackendquantity);
            drug_detailsarray.rec_item_id = this.firstItem[x].rec_item_id;
            this.rec_item_id = this.firstItem[x].rec_item_id;
            drug_detailsarray.drug_id = high_qty_drug_id;

            this.qty = parseInt(this.checkbatchnoarray[k].quantity);
            totalQuantity += this.qty
            if (blamt <= totalQuantity) {

              break;
            }
          }
          if (blamt <= totalQuantity) {
            // this.toastr.error(Message_data.quantityerror);
            break;
          }
        }



        console.log("balance amount", this.balanceamount);
        // console.log("drugarray 1", firstDrug);
        // this.firstItem.push(secondDrug)
        // console.log("second drug",this.firstItem)
        // this.selectedDrugs.push(this.firstItem[0])
        // drug_detailsarray.quantity=parseInt(this.checkbackendquantity);
        // drug_detailsarray.drug_id = high_qty_drug_id;
        // this.newRowatforquantity();
        // console.log("batch   ",batchnoforloop)
        // console.log("SELECTED DRUGS", JSON.stringify(this.selectedDrugs));

      }

    }




    this.calculateAmount(drug_detailsarray);
    var prec_tx: any = "0.00";
    if (this.drugDetailsArray.length > 1) {
      for (var i = 0; i < this.drugDetailsArray.length; i++) {
        prec_tx = parseFloat(prec_tx) + parseFloat(this.drugDetailsArray[i].amount);
      }
    } else {
      prec_tx = parseFloat(prec_tx) + parseFloat(drug_detailsarray.amount);
    }
    this.totalAmount = parseFloat(prec_tx).toFixed(2);
    this.printFlag = false;
    this.saveFlag = false;
    this.displayDescription = this.prodDiscount == "0.00" || this.prodDiscount == undefined || this.prodDiscount == null ? false : true;
    this.medicineArray = [];

    this.drugquantity = 0;
  }

  quantityreturns(drug_detailsarray) {
    // if(this.hiddeaddbutton == true){
    if (parseInt(drug_detailsarray.quantity) > parseInt(drug_detailsarray.tempquantity)) {
      drug_detailsarray.quantity = drug_detailsarray.tempquantity;
      this.toastr.error("Quantity Exits to avilable quantity");
    }

    //
    this.drugquantity = parseInt(drug_detailsarray.quantity)


    // 
    var batchnoforloop;
    for (var j = 0; j < this.drugArray.length; j++) {
      // Access the properties of the first object
      const firstDrug = this.drugArray[1];
      const secondDrug = this.drugArray[0];
      console.log("first drug---------->>", firstDrug)
      this.backenddrugquantity = parseInt(firstDrug.quantity);

      console.log("backend drug quantity", this.backenddrugquantity);

      if (this.drugquantity > this.backenddrugquantity) {


        this.balanceamount = this.drugquantity - this.backenddrugquantity;

        console.log("balance amount", this.balanceamount);
        console.log("drugarray 1", firstDrug);
        this.firstItem.push(secondDrug)

        console.log("second drug", this.firstItem)
        this.selectedDrugs.push(this.firstItem[0])

        drug_detailsarray.quantity = parseInt(firstDrug.quantity)
        this.newRowatforquantity(drug_detailsarray, "");
        console.log("batch   ", batchnoforloop)
        console.log("SELECTED DRUGS", JSON.stringify(this.selectedDrugs));

      }


    }



    this.calculateAmountreturns(drug_detailsarray);
    var prec_tx: any = "0.00";
    if (this.returnsDetailsArray.length > 1) {
      for (var i = 0; i < this.returnsDetailsArray.length; i++) {
        prec_tx = parseFloat(prec_tx) + parseFloat(this.returnsDetailsArray[i].amount);
      }
    } else {
      prec_tx = parseFloat(prec_tx) + parseFloat(drug_detailsarray.amount);
    }
    this.returnbilltotalAmount = parseFloat(prec_tx.toFixed(2));


    this.printFlag = false;
    this.saveFlag = false;
    this.displayDescription = this.prodDiscount == "0.00" || this.prodDiscount == undefined || this.prodDiscount == null ? false : true;
    // }
  }
  //
  public medicinename = [];
  public rowindex;
  public rowbatchno;
  public rowexpdate;
  public discount;
  public rowprice;

  addRow(selected_drug) {
    this.drugquantity = selected_drug.quantity
    var balqty = 0;
    var singlebalqty = 0;
    if (this.checkbatchnoarray.length == 1) {

      for (var d = 0; d < this.checkbatchnoarray.length; d++) {
        if (this.drugquantity > parseInt(this.checkbatchnoarray[d].quantity)) {
          singlebalqty = this.drugquantity - this.checkbatchnoarray[d].quantity
          this.drugquantity = singlebalqty
          selected_drug.quantity = this.checkbatchnoarray[d].quantity
          this.balanceamount = this.checkbatchnoarray[d].quantity
        } else {
          this.addNewDrugDetails()
          this.discountcalculatestaff()
        }

      }
      if (singlebalqty != 0) {

        this.toastr.error("Insufficient stock")
      }

    } else {

      for (var d = 0; d < this.checkbatchnoarray.length; d++) {

        if (selected_drug.rec_item_id == this.checkbatchnoarray[d].rec_item_id) {


          if (this.drugquantity > parseInt(this.checkbatchnoarray[d].quantity)) {

            balqty = this.drugquantity - this.checkbatchnoarray[d].quantity
            this.drugquantity = balqty
            selected_drug.quantity = this.checkbatchnoarray[d].quantity
            this.balanceamount = this.checkbatchnoarray[d].quantity

          } else {
            this.addNewDrugDetails()
            this.discountcalculatestaff()
            break;
          }

        }
      }
      if (balqty != 0) {
        for (var d = 0; d < this.checkbatchnoarray.length; d++) {

          if (selected_drug.rec_item_id != this.checkbatchnoarray[d].rec_item_id) {

            if (this.drugquantity > this.checkbatchnoarray[d].quantity) {
              balqty = this.drugquantity - this.checkbatchnoarray[d].quantity
              this.drugquantity = balqty
              this.balanceamount = this.checkbatchnoarray[d].quantity
              this.newRowatforquantity(selected_drug.drug_id, this.checkbatchnoarray[d])
              this.addNewDrugDetails()
            } else {
              this.drugquantity = balqty
              this.balanceamount = balqty
              this.newRowatforquantity(selected_drug.drug_id, this.checkbatchnoarray[d])
              this.addNewDrugDetails()
              balqty = 0;
              break;
            }

          }
        }
      }

      if (balqty != 0) {

        this.toastr.error("Insufficient stock")
      }

    }



    var prec_tx = 0;
    var medsshort, med;
    if (this.drugDetailsArray.length > 1) {
      for (var i = 0; i < this.drugDetailsArray.length; i++) {
        console.log("amount 4" + this.drugDetailsArray[i]);
        prec_tx = prec_tx + parseFloat(this.drugDetailsArray[i].amount);

        medsshort = this.drugDetailsArray[i].short_name;
        med = this.drugDetailsArray[i].med_name;
      }
    } else {
      prec_tx = prec_tx + parseFloat(selected_drug.amount);
      medsshort = selected_drug.short_name;
      med = selected_drug.med_name;
    }
    //  this.returnmedicinename = medsshort + " " + med;
    // this.totalAmount = parseInt(prec_tx.toFixed(2));
    //  this.printBtn = false;
    //  this.saveBtn = false;
    // this.displayDisplay = this.prodDiscount == "0.00" || this.prodDiscount == undefined || this.prodDiscount == null ? false : true;
    // this.calculateamount(selected_drug);
    this.medicineArray = [];

    if (this.rateplaneflag == true) {
      // this.getIntakeName(drug_detailsarray);

      this.discountcalculatestaff();
    }
    this.drugquantity = 0;


  }

  addRowreturn() {
    this.addreturnsnewDrugDetails();
    this.medicineArrayreturns = [];
  }

  newRowatforquantity(drugid, data) {
    this.addNewDrugDetailsforquantity(drugid, data);
  }

  addNewDrugDetailsforquantity(drugid, data) {
    this.selectedDrugs = [];
    this.selectedDrugs.push(data)

    for (var i = 0; i < this.selectedDrugs.length; i++) {
      console.log("Selectdrugs = " + JSON.stringify(data[i]))
      this.rowbatchno = this.selectedDrugs[i].batch_no,
        this.rowexpdate = this.selectedDrugs[i].expiry_date,
        this.discount = this.selectedDrugs[i].discount,
        this.rowprice = this.selectedDrugs[i].price_txt,
        this.rec_item_id = this.selectedDrugs[i].rec_item_id,
        this.ddarray_cgst = this.selectedDrugs[i].cgst,
        this.ddarray_sgst = this.selectedDrugs[i].sgst,
        this.rowperunit = this.selectedDrugs[i].per_unit
    }

    for (var j = 0; j < this.drugDetailsArray.length; j++) {
      if (drugid == this.drugDetailsArray[j].drug_id) {
        this.ddarray_sno = this.drugDetailsArray[j].sno + 1;
        this.medicinename = this.drugDetailsArray[j].drug_name
        this.highqtydrugid = this.drugDetailsArray[j].drug_id;
        this.ddarray_day_txtflag = this.drugDetailsArray[j].day_txtflag;
        this.ddarray_show_intakeFlag = this.drugDetailsArray[j].show_intakeFlag;
        this.ddarray_alternativedays = this.drugDetailsArray[j].intake_txt;
        this.ddarray_select = this.drugDetailsArray[j].afterfood_txt;
        this.ddarray_morning = this.drugDetailsArray[j].morning;
        this.ddarray_afternoon = this.drugDetailsArray[j].afternoon;
        this.ddarray_evening = this.drugDetailsArray[j].evening;
        this.ddarray_night = this.drugDetailsArray[j].night;
        this.ddarray_cost_price = this.drugDetailsArray[j].cost_price;
        this.ddarray_sales_price = this.drugDetailsArray[j].sales_price;
        this.ddarray_mrp = this.drugDetailsArray[j].mrp;
        this.rowindex = this.drugDetailsArray[j].index + 1;
        this.ddarray_daytext = this.drugDetailsArray[j].day_txt;
        this.ddins_allow = this.drugDetailsArray[j].ins_allow;
        this.ddarray_lookalike = this.drugDetailsArray[j].look_alike;
        this.ddarray_soundalike = this.drugDetailsArray[j].sound_alike;
        this.ddarray_highrisk = this.drugDetailsArray[j].high_risk;
        this.ddarray_ward = this.drugDetailsArray[j].ward_only;

        this.ddarray_lookalikevalue = this.drugDetailsArray[j].look_alikevalue;
        this.ddarray_soundalikevalue = this.drugDetailsArray[j].sound_alikevalue;
        this.ddarray_highriskvalue = this.drugDetailsArray[j].high_riskvalue;
        this.ddarray_wardvalue = this.drugDetailsArray[j].ward_onlyvalue;
        this.ddarray_no_of_IU = this.drugDetailsArray[j].no_of_IU;
        this.ddarray_qty_per_unit = this.drugDetailsArray[j].qty_per_unit;
      }
    }





    var amount = this.balanceamount * this.rowprice
    this.drugDetailsArray.push({
      sno: this.ddarray_sno,
      drug_id: this.highqtydrugid,
      drug_disp_name: this.medicinename,
      drug_name: this.medicinename,
      quantity: this.balanceamount,
      price: this.rowprice,
      discount: this.discount,
      batch_no: this.rowbatchno,
      per_unit: this.rowperunit,
      expiry_date: this.rowexpdate,
      amount: amount.toFixed(2),
      index: this.rowindex,
      batcharray: "",
      rack_id: this.rackId,
      bin_id: this.binId,
      rec_item_id: this.rec_item_id,
      cgst: this.ddarray_cgst,
      sgst: this.ddarray_sgst,
      day_txt: this.ddarray_daytext,
      daydur_txt: "day(s)",
      afterfood_txt: this.ddarray_select,
      dure_txt: "30",
      intake_txt: this.ddarray_alternativedays,
      morning: this.ddarray_morning,
      afternoon: this.ddarray_afternoon,
      evening: this.ddarray_evening,
      night: this.ddarray_night,
      every_six: "1 - 1 - 1",
      ins_allow: this.ddins_allow,
      mor_lable: false,
      quan_lable: true,
      night_eventxt: true,
      day_txtflag: this.ddarray_day_txtflag,
      show_intakeFlag: this.ddarray_show_intakeFlag,
      sales_price: this.ddarray_sales_price,
      cost_price: this.ddarray_cost_price,
      mrp: this.ddarray_mrp,
      look_alike: this.ddarray_lookalike,
      sound_alike: this.ddarray_soundalike,
      high_risk: this.ddarray_highrisk,
      ward_only: this.ddarray_ward,
      look_alikevalue: this.ddarray_lookalikevalue,
      sound_alikevalue: this.ddarray_soundalikevalue,
      high_riskvalue: this.ddarray_highriskvalue,
      ward_onlyvalue: this.ddarray_wardvalue,
      no_of_IU: this.ddarray_no_of_IU,
      qty_per_unit: this.ddarray_qty_per_unit
    })


  }

  addNewreturnsDetailsforquantity() {
    if (this.hiddeaddbutton == true) {





      console.log("mediciene name is here", this.medicinename)


      const indexAlreadyPushed = this.returnsDetailsArray.some(
        item => item.index === this.rowindex
      );

      if (!indexAlreadyPushed) {
        for (var i = 0; i < this.selectedDrugs.length; i++) {
          if (this.selectedDrugs[i] != null) {
            this.rowbatchno = this.selectedDrugs[i].batch_no,
              this.rowexpdate = this.selectedDrugs[i].expiry_date,
              this.discount = this.selectedDrugs[i].discount,
              this.rowprice = this.selectedDrugs[i].price_txt,
              this.rowindex = this.selectedDrugs[i].index
          }

        }
        console.log("Array erro" + JSON.stringify(this.returnsDetailsArray))
        for (var j = 0; j < this.returnsDetailsArray.length; j++) {
          this.medicinename = this.returnsDetailsArray[0].drug_name


        }
        var amount = this.balanceamount * this.rowprice
        this.returnsDetailsArray.push({

          drug_name: this.medicinename,

          quantity: this.balanceamount,

          price: this.rowprice,
          discount: this.discount,

          batch_no: this.rowbatchno,

          expiry_date: this.rowexpdate,
          amount: amount.toFixed(2),
          index: this.rowindex,
          batcharray: "",
          rack_id: this.rackId,
          bin_id: this.binId

        })
      }
    }
  }

  calculatePrice(drugid, typename, drugname, quantity, price, per_unit, discount) {
    if (this.hiddeaddbutton == false) {
      if (quantity != "") {
        var priceval = parseFloat(price);
        var pricecal: any = priceval * quantity;

        if (this.disc != null) {
          var disc: any = Math.floor(pricecal * (this.disc / 100)).toFixed(2);
        }

        var pricegst;
        // if (gst != undefined) {
        //   pricegst = (pricecal - disc) + ((pricecal - disc) * gst / 100);

        // } else {
        pricegst = pricecal - disc;

        // }
        for (var i = 0; i < this.drugDetailsArray.length; i++) {
          if (drugid == this.drugDetailsArray[i].drug_id) {
            this.drugDetailsArray[i].quantity = quantity;
            this.drugDetailsArray[i].amount = pricegst.toFixed(2);
            this.drugDetailsArray[i].price = price;
            this.drugDetailsArray[i].discount = discount;
          }
        }

        var totamount = 0;
        for (var j = 0; j < this.drugDetailsArray.length; j++) {
          totamount += parseFloat(this.drugDetailsArray[j].amount);
        }

        //Tax
        this.taxArray = [];
        if (this.taxpercentageArray != null) {
          for (var i = 0; i < this.taxpercentageArray.length; i++) {
            var taxtxt = ((totamount * this.taxpercentageArray[i].tax) / 100).toFixed(2);
            this.taxArray.push({
              tax_des: this.taxpercentageArray[i].tax_des,
              tax_id: taxtxt,
            })
          }
        }

        this.finalAmount = 0;
        for (var i = 0; i < this.taxArray.length; i++) {
          this.taxAmount = this.taxArray[i].tax_id;
          this.finalAmount = (parseFloat(this.finalAmount) + parseFloat(this.taxAmount)).toFixed(2);
        }

        var x = totamount + parseFloat(this.finalAmount);
        x = Math.round(x * 100) / 100;
        this.totalAmount = x.toFixed(2);
      }
    }

    if (this.hiddeaddbutton == true) {
      if (quantity != "") {
        var priceval = parseFloat(price);
        var pricecal: any = priceval * quantity;

        if (this.disc != null) {
          var disc: any = Math.floor(pricecal * (this.disc / 100)).toFixed(2);
        }

        var pricegst;
        // if (gst != undefined) {
        //   pricegst = (pricecal - disc) + ((pricecal - disc) * gst / 100);

        // } else {
        pricegst = pricecal - disc;

        // }
        for (var i = 0; i < this.returnsDetailsArray.length; i++) {
          if (drugid == this.returnsDetailsArray[i].drug_id) {
            this.returnsDetailsArray[i].quantity = quantity;
            this.returnsDetailsArray[i].amount = pricegst.toFixed(2);
            this.returnsDetailsArray[i].price = price;
            this.returnsDetailsArray[i].discount = discount;
          }
        }

        var totamount = 0;
        for (var j = 0; j < this.returnsDetailsArray.length; j++) {
          totamount += parseFloat(this.returnsDetailsArray[j].amount);
        }

        //Tax
        this.taxArray = [];
        if (this.taxpercentageArray != null) {
          for (var i = 0; i < this.taxpercentageArray.length; i++) {
            var taxtxt = ((totamount * this.taxpercentageArray[i].tax) / 100).toFixed(2);
            this.taxArray.push({
              tax_des: this.taxpercentageArray[i].tax_des,
              tax_id: taxtxt,
            })
          }
        }

        this.finalAmount = 0;
        for (var i = 0; i < this.taxArray.length; i++) {
          this.taxAmount = this.taxArray[i].tax_id;
          this.finalAmount = (parseFloat(this.finalAmount) + parseFloat(this.taxAmount)).toFixed(2);
        }

        var x = totamount + parseFloat(this.finalAmount);
        x = Math.round(x * 100) / 100;
        this.totalAmount = x.toFixed(2);
      }
    }
  }

  calculateDiscount() {
    var tamtbg = 0.00;
    var amtval = 0.00;

    if (this.drugDetailsArray[0].quantity != "") {

      for (var i = 0; i < this.drugDetailsArray.length; i++) {
        tamtbg = parseFloat(tamtbg + this.drugDetailsArray[i].amt_bdgst);
        amtval = amtval + parseFloat(this.drugDetailsArray[i].amount);
        this.subtotalAmount = this.drugDetailsArray[i].amount
      }
      this.totalAmount = amtval;
      var subractedreturns = this.totalAmount - this.returnbilltotalAmount
      // this.returnbilltotalAmount = parseInt(amtval.toFixed(2));

      // console.log("bill amount", this.totalAmount)
      // var subreturnamunt = this.subtotalAmount - this.returnbilltotalAmount
      // this.returnamountDicount = parseInt(subreturnamunt.toFixed(2))
      console.log("2 Amount" + this.subtotalAmount)
      console.log("return amount", this.returnamountDicount)


      if (this.discType == "3") {
        this.amtbdGST = tamtbg.toFixed(2);
        for (var j = 0; j < this.discregSalesVolArray.length; j++) {
          if (parseInt(this.amtbdGST) >= this.discregSalesVolArray[j].volume) {
            var disamt = parseInt(this.amtbdGST) * (this.discregSalesVolArray[j].discount / 100);
            this.prodDiscount = Math.floor(disamt).toFixed(2);
            this.amountDiscount = `${subractedreturns}`;
            this.amountDiscount = (this.totalAmount - this.prodDiscount).toFixed(2);
            // this.amountDiscount  = (Math.round(parseFloat(this.amountDiscount))).toFixed(2);
            if (this.round_off_descflag == false) {
              this.amountDiscount = (Math.round(parseFloat(this.amountDiscount))).toFixed(2);
            }
            else if (this.round_off_descflag == true) {
              this.amountDiscount = (parseFloat(this.amountDiscount)).toFixed(2);

            }
          }
        }
      } else if (this.discType == "4") {
        this.amtbdGST = tamtbg.toFixed(2);
        for (var j = 0; j < this.discSalesVolArray.length; j++) {
          if (parseInt(this.amtbdGST) >= this.discSalesVolArray[j].volume) {
            var disamt = parseInt(this.amtbdGST) * (this.discSalesVolArray[j].discount / 100);
            this.prodDiscount = Math.floor(disamt).toFixed(2);
            this.amountDiscount = `${subractedreturns}`;
            this.amountDiscount = (this.totalAmount - this.prodDiscount).toFixed(2);
            // this.amountDiscount  = (Math.round(parseFloat(this.amountDiscount))).toFixed(2);
            if (this.round_off_descflag == false) {
              this.amountDiscount = (Math.round(parseFloat(this.amountDiscount))).toFixed(2);
            }
            else if (this.round_off_descflag == true) {
              this.amountDiscount = (parseFloat(this.amountDiscount)).toFixed(2);

            }
          }
        }
      } else if (this.discType == "5") {
        this.amtbdGST = tamtbg.toFixed(2);
        for (var j = 0; j < this.discregSalesVolArray.length; j++) {
          if (parseInt(this.amtbdGST) >= this.discregSalesVolArray[j].volume) {
            var disamt = parseInt(this.amtbdGST) * (this.discregSalesVolArray[j].discount / 100);
            this.prodDiscount = Math.floor(disamt).toFixed(2);
            this.amountDiscount = `${subractedreturns}`
            this.amountDiscount = (this.totalAmount - this.prodDiscount).toFixed(2);
            // this.amountDiscount = (Math.round(parseFloat(this.amountDiscount))).toFixed(2);
            if (this.round_off_descflag == false) {
              this.amountDiscount = (Math.round(parseFloat(this.amountDiscount))).toFixed(2);
            }
            else if (this.round_off_descflag == true) {
              this.amountDiscount = (parseFloat(this.amountDiscount)).toFixed(2);

            }

          }
        }
      } else {
        this.amountDiscount = `${subractedreturns}`
        // this.amountDicount = amtval.toFixed(2);

        if (this.round_off_descflag == false) {
          this.amountDiscount = (Math.round(parseFloat(this.amountDiscount))).toFixed(2);
        }
        else if (this.round_off_descflag == true) {
          this.amountDiscount = (parseFloat(this.amountDiscount)).toFixed(2);

        }


      }
      this.remaingBalance = parseFloat(this.amountDiscount).toFixed(2);
      this.changeTotal();
    }
  }

  public calvalue: string;
  public calculation_value;
  public round_off;

  decision() {
    if (this.rounddesc == "without_round") {
      this.round_off_descflag = false

    } else {
      this.round_off_descflag = true
    }
  }

  addRound(data) {
    if (data == 'plus') {
      this.calculation_value = 'plus'
      this.calvalue = "increase";
    } else {
      this.calculation_value = 'minus'
      this.calvalue = "decrease";
    }
    this.isInputDisabled = false;
  }

  updatedvalue(value) {
    if (this.calculation_value != null || this.calculation_value != undefined || this.calculation_value == "") {

      if (value != "") {
        if (this.prodDiscount === undefined || this.prodDiscount === "") {
          this.prodDiscount = 0.00; // Set a default value, you can use 0 or any other default value
        } else {
          this.prodDiscount = parseFloat(this.prodDiscount);
        }
        if (this.calculation_value == "plus") {

          // this.remainBalance= (Math.round(parseFloat(this.amountDicount)) + parseFloat(value)).toFixed(2);
          this.amountDiscount = (Math.round(parseFloat(this.totalAmount) - this.prodDiscount + parseFloat(value))).toFixed(2);
          this.remaingBalance = this.amountDiscount;

        }
        else if
          (this.calculation_value == 'minus') {
          // this.remainBalance= (Math.round(parseFloat(this.amountDicount)) - parseFloat(value)).toFixed(2);
          this.amountDiscount = (Math.round(parseFloat(this.totalAmount) - this.prodDiscount - parseFloat(value))).toFixed(2);
          this.remaingBalance = this.amountDiscount;
        }
        else {

          console.log("error to be calculated symbol")
        }
      } else {
        // this.remainBalance = ((parseFloat(this.amountDicount))).toFixed(2);
        this.amountDiscount = ((parseFloat(this.totalAmount) - this.prodDiscount)).toFixed(2);
        this.remaingBalance = this.amountDiscount

      }

    }

  }

  calculateDiscountreturns() {
    // if(this.hiddeaddbutton == true){  
    var tamtbg = 0.00;
    var amtval = 0.00;

    for (var i = 0; i < this.returnsDetailsArray.length; i++) {
      tamtbg = parseFloat(tamtbg + this.returnsDetailsArray[i].amt_bdgst);
      amtval = amtval + parseFloat(this.returnsDetailsArray[i].amount);
      console.log(this.returnsDetailsArray[i].amount);
    }
    console.log("tamtbg =" + tamtbg)
    console.log("amtval =", amtval)
    this.returnbilltotalAmount = amtval != undefined || null || '' || NaN ? (amtval) : 0.00;

    var subractedreturns = this.totalAmount - this.returnbilltotalAmount

    //  var subractreturns = this.totalAmount - this.returnbilltotalAmount
    console.log("return amount subract" + subractedreturns)
    // this.amountDiscount = `${subractedreturns}`
    if (this.discType == "3") {

      this.amtbdGST = tamtbg.toFixed(2);
      for (var j = 0; j < this.discregSalesVolArray.length; j++) {
        if (parseInt(this.amtbdGST) >= this.discregSalesVolArray[j].volume) {
          var disamt = parseInt(this.amtbdGST) * (this.discregSalesVolArray[j].discount / 100);
          this.prodDiscount = Math.floor(disamt).toFixed(2);


          this.amountDiscount = (subractedreturns - this.prodDiscount).toFixed(2);
          this.amountDiscount = (Math.round(parseFloat(this.amountDiscount))).toFixed(2);
        }
      }
    } else if (this.discType == "4") {

      this.amtbdGST = tamtbg.toFixed(2);
      for (var j = 0; j < this.discSalesVolArray.length; j++) {
        if (parseInt(this.amtbdGST) >= this.discSalesVolArray[j].volume) {
          var disamt = parseInt(this.amtbdGST) * (this.discSalesVolArray[j].discount / 100);
          this.prodDiscount = Math.floor(disamt).toFixed(2);
          this.amountDiscount = (subractedreturns - this.prodDiscount).toFixed(2);
          this.amountDiscount = (Math.round(parseFloat(this.amountDiscount))).toFixed(2);
        }
      }
    } else if (this.discType == "5") {

      this.amtbdGST = tamtbg.toFixed(2);
      for (var j = 0; j < this.discTransVolArray.length; j++) {
        if (parseInt(this.amtbdGST) >= this.discTransVolArray[j].volume) {
          var disamt = parseInt(this.amtbdGST) * (this.discTransVolArray[j].discount / 100);
          this.prodDiscount = Math.floor(disamt).toFixed(2);
          this.amountDiscount = (subractedreturns - this.prodDiscount).toFixed(2);
          this.amountDiscount = (Math.round(parseFloat(this.amountDiscount))).toFixed(2);
        }
      }
    } else {

      // this.amountDiscount = amtval.toFixed(2);
      this.amountDiscount = `${subractedreturns}`
      console.log("amttm" + this.amountDiscount)
      this.amountDiscount = (Math.round(parseFloat(this.amountDiscount))).toFixed(2);
    }
    this.remaingBalance = parseFloat(this.amountDiscount).toFixed(2);
    // }

  }

  changeTotal() {
    if (this.prodDiscount == "") {
      this.displayDescription = false;
      this.prodDiscount = "0.00";
    } else {
      // this.prod_disc = parseFloat(this.prod_disc);
      this.displayDescription = true;
    }
    // this.amountDiscount = (parseFloat(this.totalAmount) - parseFloat(this.prodDiscount)).toFixed(2);
    // this.amountDiscount =  (Math.round(parseFloat(this.amountDiscount))).toFixed(2);
    this.remaingBalance = (parseFloat(this.amountDiscount) - (parseFloat(this.prodDiscount) + parseFloat(this.paidText))).toFixed(2);
    //this.remaingBalance = (parseFloat(this.totalAmount) - (parseFloat(this.prodDiscount) + parseFloat(this.paidText))).toFixed(2);
  }

  deleteMedicine(drug_id, index) {
    if (drug_id.length == 0) {
      if (index >= 0 && index < this.drugDetailsArray.length) {
        this.drugDetailsArray.splice(index, 1); // Remove the row at the specified index
      }
    } else {
      for (var i = 0; i < this.drugDetailsArray.length; i++) {
        if (this.drugDetailsArray[i].drug_id != "" && this.drugDetailsArray[i].drug_id != undefined && this.drugDetailsArray[i].drug_id != null) {
          if (this.drugDetailsArray[i].drug_id == drug_id) {
            if (this.drugDetailsArray[i].index == index) {
              this.deleteMedicalpres(this.drugDetailsArray[i].med_typetxt, this.drugDetailsArray[i].drug_name);
              this.drugDetailsArray.splice(i, 1);
            }
          }
        }
      }
      // Rearrange indexes of remaining elements
      for (let i = 0; i < this.drugDetailsArray.length; i++) {
        this.drugDetailsArray[i].index = i;
      }
      var prec_tx = 0;
      for (var i = 0; i < this.drugDetailsArray.length; i++) {
        if (this.drugDetailsArray[i].drug_id != "" && this.drugDetailsArray[i].drug_id != undefined && this.drugDetailsArray[i].drug_id != null)
          prec_tx = prec_tx + parseFloat(this.drugDetailsArray[i].amount)
      }
      this.totalAmount = prec_tx;
      this.amountDiscount = prec_tx.toFixed(2);
      this.amountDiscount = (Math.round(parseFloat(this.amountDiscount))).toFixed(2);
      this.remaingBalance = this.amountDiscount;

      if (this.rateplaneflag == true) {
        this.discountcalculatestaff()
      } else {
        this.discountcalculatestaff();
      }
      if (this.drugDetailsArray.length > 0) {
        var drugarraylength = this.drugDetailsArray.length - 1
        this.aftdeletflag = false
        this.getItemPrices(this.drugDetailsArray[drugarraylength])
      }
      if (this.drugDetailsArray.length == 0) {
        this.addNewDrugDetails();
      }
    }
    this.drugDetailsArray.forEach((item, index) => {
      item.sno = index + 1;
    })
  }

  deleteMedicinereturn(drugId, index) {
    if (drugId.length == 0) {
      if (index >= 0 && index < this.drugDetailsArray.length) {
        this.drugDetailsArray.splice(index, 1); // Remove the row at the specified index
      }
    } else {
      for (var i = 0; i < this.returnsDetailsArray.length; i++) {
        console.log(this.returnsDetailsArray[i])

        if (this.returnsDetailsArray[i].drug_id != "" && this.returnsDetailsArray[i].drug_id != undefined && this.returnsDetailsArray[i].drug_id != null) {
          if (this.returnsDetailsArray[i].drug_id == drugId) {
            if (this.returnsDetailsArray[i].index == index) {

              const itemToDelete = this.returnsDetailsArray.find(drug => drug.drug_id === drugId && drug.index === index);
              console.log("item to delete" + itemToDelete)

              if (itemToDelete) {
                const indexToDelete = this.returnsDetailsArray.indexOf(itemToDelete);
                console.log("item to delete 2" + indexToDelete)
                if (indexToDelete !== -1) {
                  this.returnsDetailsArray.splice(indexToDelete, 1);
                  this.discountcalculatestaffreturn()
                }
              }
            }
          }
        }
      }
      if (this.returnsDetailsArray.length == 0) {
        this.addreturnsnewDrugDetails();
      }
    }
    console.log(" return 2" + this.returnbilltotalAmount)
  }

  changeAdvance() {//add the bill details]
    if (this.paidText != undefined && this.paidText != "") {
      if (this.rateplaneflag == true) {
        if (this.insuranceflag == true) {
          this.remaingBalance = (parseFloat(this.amountDiscount) - (parseFloat(this.paidText) + this.returnbilltotalAmount)).toFixed(2);
        } else {
          console.log("AMOUNT " + parseFloat(this.amountDiscount) + " == " + (this.duesponser + parseFloat(this.paidText)))
          this.remaingBalance = (parseFloat(this.amountDiscount) - (parseFloat(this.duesponser) + parseFloat(this.paidText))).toFixed(2);
        }
      } else {
        if (this.insuranceflag == true) {
          this.remaingBalance = (parseFloat(this.amountDiscount) - ((parseFloat(this.paidText) + parseFloat(this.prodDiscount))) + this.returnbilltotalAmount).toFixed(2);
        } else {

          this.remaingBalance = (parseFloat(this.amountDiscount) - (this.duesponser + parseFloat(this.prodDiscount) + parseFloat(this.paidText))).toFixed(2);
        }
      }

    } else {
      this.paidText = "0.00";
      if (this.insuranceflag == true) {
        this.remaingBalance = (parseFloat(this.amountDiscount) - this.returnbilltotalAmount).toFixed(2);

      } else {
        this.remaingBalance = (parseFloat(this.amountDiscount) - parseFloat(this.duesponser)).toFixed(2);
      }
    }
  }

  changePaid() {
    this.paidText = this.paidText == "" ? "0.00" : this.paidText;
    if (this.returnbilltotalAmount != 0) {
      this.amountval = parseFloat(this.paidText);
    } else {
      if (this.insuranceflag == true) {
        if (Number(this.paidText) > Number(this.amountDiscount)) {
          this.amountval = parseFloat(this.amountDiscount);
        } else {
          this.amountval = parseFloat(this.paidText);
        }
      } else {
        if (Number(this.paidText) > Number(this.amountDiscount)) {
          this.amountval = parseFloat(this.amountDiscount);
        } else {
          this.amountval = parseFloat(this.paidText);
        }
      }
    }
    this.numToWords = this.paidText == "" ? " " : convertNumberToWords(this.paidText, ipaddress.country_code);
  }

  paidBlur() {
    if (this.paidText != "") {
      this.paidText = (parseFloat(this.paidText)).toFixed(2);
    }
  }

  dissBlur() {
    if (this.prodDiscount != "") {
      this.prodDiscount = (parseFloat(this.prodDiscount)).toFixed(2);
    }
  }

  saveOrder(e) {
    //var saveflag = false;
    // if(this.hiddeaddbutton == false){  
    var saveflag;
    var save_order_data;
    if (this.doctorName == "" || this.clientName == "") {
      this.toastr.error(Message_data.allMandatory);
      saveflag = true;
    } else if (this.drugDetailsArray.length == 0) {
      this.toastr.error(Message_data.enterMedicine);
      saveflag = true;
    } else {
      var paid_flag;
      var paytype;
      var instype;
      this.save_flag = true;
      paytype = this.payType;
      instype = this.insurerID;
      if (this.amountDiscount == this.remaingBalance) {
        paid_flag = "Un paid";
      } else if (this.amountDiscount != "0.00" && (this.paidText != "0.00") && this.amountDiscount != this.paidText) {
        paid_flag = "Partially paid";
      } else if (this.remaingBalance == "0.00" || this.remaingBalance == "0") {
        paid_flag = "Fully paid";
      }
      console.log('SAVE DRUG DETAILS = ' + JSON.stringify(this.drugDetailsArray))
      var drug_detailsarray = [];
      for (var i = 0; i < this.drugDetailsArray.length; i++) {
        if (this.drugDetailsArray[i].drug_id != "" || this.drugDetailsArray[i].drug_id != undefined || this.drugDetailsArray[i].drug_id != null) {
          drug_detailsarray.push({
            sno: this.drugDetailsArray[i].sno,
            drug_id: this.drugDetailsArray[i].drug_id,
            type_name: this.drugDetailsArray[i].type_name,
            drug_type_id: this.drugDetailsArray[i].drug_typetxt,
            drug_name: this.drugDetailsArray[i].drug_name,
            drug_disp_name: this.drugDetailsArray[i].drug_disp_name,
            quantity: this.drugDetailsArray[i].quantity,
            amount: this.drugDetailsArray[i].amount,
            price: this.drugDetailsArray[i].price,
            discount: this.drugDetailsArray[i].discount,
            per_unit: this.drugDetailsArray[i].per_unit,
            prod_disc: this.drugDetailsArray[i].disc_txt,
            batch_no: this.drugDetailsArray[i].batch_no,
            amt_bdgst: this.prodAmount,
            cgst: this.drugDetailsArray[i].cgst,
            sgst: this.drugDetailsArray[i].sgst,
            cgst_amt: this.drugDetailsArray[i].cgstcal,
            sgst_amt: this.drugDetailsArray[i].sgstcal,
            expiry_date: this.drugDetailsArray[i].expiry_date,
            rec_item_id: this.drugDetailsArray[i].rec_item_id,
            ins_allow: this.drugDetailsArray[i].ins_allow,
            no_of_IU: this.drugDetailsArray[i].no_of_IU,
            qty_per_unit: this.drugDetailsArray[i].qty_per_unit

          });
        }
      }
      // for (var r = 0; r < this.returnsDetailsArray.length; r++) {
      //   if (this.returnsDetailsArray[r].drug_id == "" || this.returnsDetailsArray[r].drug_id == undefined || this.returnsDetailsArray[r].drug_id == null) {
      //     this.returnsDetailsArray.splice(r, 1);
      //   }
      // }
      if (this.credit_flag) {
        if (this.paidText != "") {
          this.paidText;
        } else {
          this.paidText = "0.00"
        }

        if (this.prodDiscount != "") {
          this.prodDiscount;
        } else {
          this.prodDiscount = "0.00"
        }

        if (this.amountDiscount != "") {
          this.amountDiscount;
        } else {
          this.amountDiscount = "0.00"
        }

        if (this.remaingBalance != "") {
          this.remaingBalance;
        } else {
          this.remaingBalance = "0.00"
        }

        if (this.totalAmount != "") {
          this.totalAmount;
        } else {
          this.totalAmount = "0.00"
        }

        if (this.returnsDetailsArray[0].drug_id != "") {
          console.log("0")
          save_order_data = {
            pharma_id: this.pharmacyID,
            purchase_type_id: "1",
            medicines: drug_detailsarray,
            country: ipaddress.country_code,
            created_by: this.createdby,
            name: this.clientName,
            doc_name: this.doctorName,
            pres_image: this.imageStr,
            disc_type: this.discType,
            gst_amt: this.GSTamount,

            disc_amt: parseFloat(this.prodDiscount).toFixed(2),
            bill_amt: parseFloat(this.amountDiscount).toFixed(2),
            paid_amt: parseFloat(this.paidText).toFixed(2),
            balance: parseFloat(this.remaingBalance).toFixed(2),
            actual_amt: parseFloat(this.totalAmount).toFixed(2),
            paid_flag: paid_flag,
            order_date: this.orderDate,
            pay_type: paytype,
            insurer: instype,
            card_holder_name: this.cardHolderName,
            transaction_no: this.TransactionID,
            bank_name: this.bankName,
            fin_year: this.fin_year,
            ret_bill_id: this.returnbillid,
            ret_amount: this.returnbilltotalAmount,
            ret_meds: this.returnsDetailsArray,
            rate_card: this.discType,
            payments: this.paymentsarray,
            credit_type: this.credit_type,
            credit_reason: this.credit_person,
            pres_drug_id: this.presID,
            is_inpatient:'1'
          };
        }
        else {
          console.log("1")
          if (parseFloat(this.amountDiscount) < parseFloat(this.paidText)) {
            this.remaingBalance = "0.00"
            this.paidText = this.amountDiscount
            this.numToWords = this.paidText == "0.00" ? " " : convertNumberToWords(this.amountDiscount, ipaddress.country_code);
          }
          save_order_data = {
            pharma_id: this.pharmacyID,
            purchase_type_id: "1",
            medicines: drug_detailsarray,
            country: ipaddress.country_code,
            created_by: this.createdby,
            name: this.clientName,
            doc_name: this.doctorName,
            pres_image: this.imageStr,
            disc_type: this.discType,
            gst_amt: this.GSTamount,
            cgst_amt: parseFloat(this.CGSTamount).toFixed(2),
            sgst_amt: parseFloat(this.SGSTamount).toFixed(2),
            disc_amt: parseFloat(this.prodDiscount).toFixed(2),
            bill_amt: parseFloat(this.amountDiscount).toFixed(2),
            paid_amt: parseFloat(this.paidText).toFixed(2),
            balance: parseFloat(this.remaingBalance).toFixed(2),
            actual_amt: parseFloat(this.totalAmount).toFixed(2),
            sponsor_due: parseFloat(this.duesponser).toFixed(2),
            paid_flag: paid_flag,
            order_date: this.orderDate,
            pay_type: paytype,
            insurer: instype,
            card_holder_name: this.cardHolderName,
            transaction_no: this.TransactionID,
            bank_name: this.bankName,
            ret_bill_id: this.returnbillid,
            rate_card: this.discType,
            payments: this.paymentsarray,
            credit_type: this.credit_type,
            credit_reason: this.credit_person,
            pres_drug_id: this.presID,
            is_inpatient:'1'
          };

        }
      } else {
        if (this.paidText != "") {
          this.paidText;
        } else {
          this.paidText = "0.00"
        }

        if (this.prodDiscount != "") {
          this.prodDiscount;
        } else {
          this.prodDiscount = "0.00"
        }

        if (this.amountDiscount != "") {
          this.amountDiscount;
        } else {
          this.amountDiscount = "0.00"
        }

        if (this.remaingBalance != "") {
          this.remaingBalance;
        } else {
          this.remaingBalance = "0.00"
        }

        if (this.totalAmount != "") {
          this.totalAmount;
        } else {
          this.totalAmount = "0.00"
        }

        if (this.returnsDetailsArray[0].drug_id != "") {
          console.log("0")
          save_order_data = {
            pharma_id: this.pharmacyID,
            purchase_type_id: "1",
            medicines: drug_detailsarray,
            country: ipaddress.country_code,
            created_by: this.createdby,
            name: this.clientName,
            doc_name: this.doctorName,
            pres_image: this.imageStr,
            disc_type: this.discType,
            gst_amt: this.GSTamount,
            disc_amt: parseFloat(this.prodDiscount).toFixed(2),
            bill_amt: parseFloat(this.amountDiscount).toFixed(2),
            paid_amt: parseFloat(this.paidText).toFixed(2),
            balance: parseFloat(this.remaingBalance).toFixed(2),
            actual_amt: parseFloat(this.totalAmount).toFixed(2),
            paid_flag: paid_flag,
            order_date: this.orderDate,
            pay_type: paytype,
            insurer: instype,
            card_holder_name: this.cardHolderName,
            transaction_no: this.TransactionID,
            bank_name: this.bankName,
            pres_drug_id: this.presID,
            ret_bill_id: this.returnbillid,
            ret_amount: this.returnbilltotalAmount,
            ret_meds: this.returnsDetailsArray,
            rate_card: this.discType,
            fin_year: this.fin_year,
            is_inpatient:'1'
          };

        } else {
          console.log("1")
          if (parseFloat(this.amountDiscount) < parseFloat(this.paidText)) {
            this.remaingBalance = "0.00"
            this.paidText = this.amountDiscount
            this.numToWords = this.paidText == "0.00" ? " " : convertNumberToWords(this.amountDiscount, ipaddress.country_code);
          }
          save_order_data = {
            pharma_id: this.pharmacyID,
            purchase_type_id: "1",
            medicines: drug_detailsarray,
            country: ipaddress.country_code,
            created_by: this.createdby,
            name: this.clientName,
            doc_name: this.doctorName,
            pres_image: this.imageStr,
            disc_type: this.discType,
            gst_amt: this.GSTamount,
            cgst_amt: parseFloat(this.CGSTamount).toFixed(2),
            sgst_amt: parseFloat(this.SGSTamount).toFixed(2),
            disc_amt: parseFloat(this.prodDiscount).toFixed(2),
            bill_amt: parseFloat(this.amountDiscount).toFixed(2),
            paid_amt: parseFloat(this.paidText).toFixed(2),
            balance: parseFloat(this.remaingBalance).toFixed(2),
            actual_amt: parseFloat(this.totalAmount).toFixed(2),
            sponsor_due: parseFloat(this.duesponser).toFixed(2),
            paid_flag: paid_flag,
            order_date: this.orderDate,
            pay_type: paytype,
            insurer: instype,
            card_holder_name: this.cardHolderName,
            transaction_no: this.TransactionID,
            bank_name: this.bankName,
            pres_drug_id: this.presID,
            ret_bill_id: this.returnbillid,
            rate_card: this.discType,
            is_inpatient:'1'
          };
        }
      }

      this.save_flag = false
      console.log("save_order_data" + JSON.stringify(save_order_data));
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.saveOrderURl, JSON.stringify(save_order_data), { headers: headers }).subscribe(
        data => {
          var obj = data.json();
          console.log(JSON.stringify(obj))
          if (data.json().key !== null && data.json().key == "1") {
            this.toastr.success(Message_data.billgen);
            this.billID = data.json().order_id;
            this.save_flag = false;
            if (e == 'print') {
              if (Math.round(this.totalAmount) == Number(this.amountDiscount)) {
                this.totalAmount = "0.00"
              }
              if (this.totalAmount == '0.00') {
                this.totalamountfalg = true
              } else {
                this.totalamountfalg = false
              }
              if (this.paidText != "0.00" && this.bill_pay_type_req == "1") {
                this.amountinwordflag = false
              } else {
                this.amountinwordflag = true
              }
              this.cdr.detectChanges();
              this.printnonserv();
            }
            this.pharmacyservice.sendMessage('pharmacycurentinformation');
          } else {
            this.save_flag = true;
            this.toastr.error(Message_data.orderNotPlaced);
          }
        }, error => { this.save_flag = true; });
    }
    // }
  }

  changeFilter() {
    if (this.filter == "Name") {
      this.firstNameFlag = false;
      this.fromFlag = true;
      this.toFlag = true;
      this.uhidFlag = true;
      this.mobileFlag = true;
      this.doctorFlag = true;
      this.BillnoFlag = true;
      this.filter = "Name"
    }
    else if (this.filter == "date") {
      this.firstNameFlag = true;
      this.fromFlag = true;
      this.toFlag = false;
      this.uhidFlag = true;
      this.mobileFlag = true;
      this.doctorFlag = true;
      this.BillnoFlag = true;
      this.filter = "date"
    }
    else if (this.filter == "bill_no") {
      this.firstNameFlag = true;
      this.fromFlag = true;
      this.toFlag = true;
      this.uhidFlag = true;
      this.mobileFlag = true;
      this.doctorFlag = true;
      this.BillnoFlag = false;
      this.filter = "bill_no"
    }
    else if (this.filter == "Patient_ID") {
      this.firstNameFlag = true;
      this.fromFlag = true;
      this.toFlag = true;
      this.uhidFlag = false;
      this.mobileFlag = true;
      this.doctorFlag = true;
      this.BillnoFlag = true;
      this.filter = "Patient_ID"
    } else if (this.filter == "Mobile_No") {
      this.firstNameFlag = true;
      this.fromFlag = true;
      this.toFlag = true;
      this.uhidFlag = true;
      this.mobileFlag = false;
      this.doctorFlag = true;
      this.BillnoFlag = true;
      this.filter = "Mobile_No"
    }

  }


  backButton() {
    this.pharmacyservice.sendMessage('pharmacycurentinformation');
    this.rateplaneflag = false;
  }

  print_area() {
    var saveflag = false;
    if (this.doctorName == "" || this.clientName == "") {
      this.toastr.error(Message_data.allMandatory);
      saveflag = true;
    } else if (this.drugDetailsArray.length == 0) {
      this.toastr.error(Message_data.enterMedicine);
      saveflag = true;
    } else {
      this.saveMedicalPrescription('print');
    }
  }

  print() {


    var billnumber = {
      no: "1"
    }
    Helper_Class.setbillprintgen(billnumber);

    if (this.billID != undefined) {
      this.numToWords = this.paidText == "0.00" ? " " : convertNumberToWords(this.paidText, ipaddress.country_code);
      var pharmaAddr = this.pharmaAddress2 != undefined ? this.pharmaAddress1 + "," + this.pharmaAddress2 : this.pharmaAddress1;
      var template;
      if (this.billTemplate != undefined && this.billTemplate == "banner") {
        template = '<div #banner id="banner">'
      } else if (this.billTemplate != undefined && this.billTemplate == "noheader") {
        template = '<div #noheader id="noheader">'
      } else if (this.billTemplate != undefined && this.billTemplate == "logowithname") {
        template = '<div #logowithname id="logowithname"style="background-image: url({{bg_image}}); background-position: center;">';
      } else {
        template = '<div #billprint id="billprint">';
      }

      var printData;

      if (this.clntSal == undefined) {
        this.clntSal = ""
      }

      if (this.clientName == undefined) {
        this.clientName = ""
      }
      if (this.clntAge == undefined) {
        this.clntAge = ""
      }
      if (this.Bed_no != undefined) {
        this.Bed_no
      } else {
        this.Bed_no = ""
      }
      if (this.Ward_name != undefined) {
        this.Ward_name
      } else {
        this.Ward_name = ""
      }

      // if (this.headergen == undefined) {
      //   this.headergen = ""
      // }

      if (this.clntgender == "Male" || this.clntgender == "M") {
        this.headergen = "M";
      } else if (this.clntgender == "Female" || this.clntgender == "F") {
        this.headergen = "F";
      } else if (this.clntgender == "Transgender" || this.clntgender == "M") {
        this.headergen = "TG";
      } else {
        this.headergen = "";
      }

      if (this.mrNumb == undefined) {
        this.mrNumb = ""
      }
      if (this.name == undefined) {
        this.name = ""
      }
      if (this.doctorName != undefined && this.doctorName != "" && this.doctorName != "All" && this.doctorName != "all") {
        var docsal = "Dr "
      } else {
        var docsal = ""
      }
      if (this.returnbilltotalAmount != 0) {
        printData = {
          clntsal: this.clntSal,
          mrno: this.mrNumb,
          age: this.clntAge,
          gender: this.headergen,
          discountprest: this.disperce,
          template: this.billTemplate,
          content: template,
          doctor_desc: docsal,
          pharma_logo: this.pharmaLogo,
          pharma_name: this.pharmaName,
          pharma_addr: pharmaAddr,
          location: this.location,
          city: this.city,
          state: this.state,
          country: this.country,
          dl_no: this.dlNO,
          gst_no: this.GSTNo,
          patient_name: this.clientName,
          bill_no: this.billID,
          PurchaseType: this.PurchaseType,
          doc_name: this.doctorName,
          order_date: Date_Formate(this.orderDate),
          order_time: this.orderTime,
          drugList: this.drugDetailsArray,
          cs_gst: this.GSTamount,
          billamount: parseFloat(this.totalAmount).toFixed(2),
          total: parseFloat(this.amountDiscount).toFixed(2),
          paid: parseFloat(this.paidText).toFixed(2),
          balance: parseFloat(this.remaingBalance).toFixed(2),
          discount: this.prodDiscount,
          // discount: this.prodDiscount,
          // total: this.totalAmount,
          // paid: this.paidText,
          // balance: this.remaingBalance,
          returnsdata: this.returnsDetailsArray,
          returns: this.returnbilltotalAmount,
          order_type: true,
          show_discount: this.displayDescription,
          pay_type: this.payDes,
          pay_flag: this.paytypreq,
          payments: this.paymentsarray,
          numtowords: this.numToWords,
          discountdesc: this.rateplanetype,
        }
      } else {
        if (parseFloat(this.amountDiscount) < parseFloat(this.paidText)) {
          this.remaingBalance = "0.00"
          this.paidText = this.amountDiscount
          this.numToWords = this.paidText == "0.00" ? " " : convertNumberToWords(this.amountDiscount, ipaddress.country_code);
        }
        printData = {
          pagetype: "OTC RX Bill - Inpatient",
          bedno: this.Bed_no,
          wardname: this.Ward_name,
          admissiondate: "",
          admissiontime: "",
          clntsal: this.clntSal,
          mrno: this.mrNumb,
          age: this.clntAge,
          gender: this.headergen,
          discountprest: this.disperce,
          template: this.billTemplate,
          content: template,
          pharma_logo: this.pharmaLogo,
          pharma_name: this.pharmaName,
          pharma_addr: pharmaAddr,
          location: this.location,
          city: this.city,
          state: this.state,
          country: this.country,
          dl_no: this.dlNO,
          gst_no: this.GSTNo,
          patient_name: this.clientName,
          bill_no: this.billID,
          PurchaseType: this.PurchaseType,
          doc_name: this.doctorName,
          order_date: Date_Formate(this.orderDate),
          order_time: this.orderTime,
          drugList: this.drugDetailsArray,
          cs_gst: this.GSTamount,
          billamount: parseFloat(this.totalAmount).toFixed(2),
          total: parseFloat(this.amountDiscount).toFixed(2),
          paid: parseFloat(this.paidText).toFixed(2),
          balance: parseFloat(this.remaingBalance).toFixed(2),
          sponsor_due: parseFloat(this.duesponser).toFixed(2),
          discount: this.prodDiscount,
          // total: this.totalAmount,
          // paid: this.paidText,
          // balance: this.remaingBalance,
          // returns: this.returnbilltotalAmount,
          order_type: true,
          show_discount: this.displayDescription,
          pay_type: this.payDes,
          pay_flag: this.paytypreq,
          payments: this.paymentsarray,
          numtowords: this.numToWords,
          discountdesc: this.rateplanetype,
          doctor_desc: docsal,
        }
      }

      this.printService.print(printData);
    }
  }

  fileChange(data) {
    this.readThis(data.target);
  }

  readThis(inputValue: any): void {
    var ValidFileExtension = ['jpg', 'JPG', 'jpeg', 'JPEG', 'png', 'PNG', 'gif', 'GIF', 'bmp', 'BMP'];

    var file: File = inputValue.files[0];
    var myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      var fileSize = Math.round(inputValue.files[0].size / 1024);
      this.imageString = myReader.result.toString();
      this.imageStr = this.imageString.split(',')[1];
    }
    myReader.readAsDataURL(inputValue.files[0]);
  }

  fnameToUpper() {
    if (this.firstName != undefined)
      this.firstName = this.firstName.toLocaleUpperCase().trim();
  }

  lnameToUpper() {
    if (this.lastName != undefined)
      this.lastName = this.lastName.toLocaleUpperCase().trim();
  }

  selectFromDate(e) {
    FrontDesk_Helper.setBillFromDate(undefined);
    this.fromDate = e;
    FrontDesk_Helper.setBillFromDate(e);
    // this.fdateFlag = true;
  }

  retrivebillno(e) {
    console.log("1")
    var send_data;
    // if( this.retivebillnodata != undefined && this.retivebillnodata.length >= 0){
    console.log("2")
    send_data = {
      order_id: e,
      pharmacy_id: this.pharmaidforbill,
      type: "inward"
    }
    console.log("send data to get using bill number" + JSON.stringify(send_data))
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "pharmacontrol/vieworderdetails/", send_data,
      { headers: headers }).subscribe(
        response => {
          var obj = response.json();
          console.log("object value of object", JSON.stringify(obj))
          var drug_details = obj.drug_details
          console.log("only drug details" + JSON.stringify(drug_details))
          this.returnsDetailsArray = [];
          var number;
          var reverse_exp;
          var price;
          this.discType = obj.disc_type
          for (var i = 0; i < drug_details.length; i++) {
            console.log("retunrs details............." + JSON.stringify(this.returnsDetailsArray))
            console.log("drug amount", drug_details[i].amount)
            number = drug_details[i].discount != undefined ? (parseInt(drug_details[i].discount)).toFixed(2) : 0;

            if (drug_details[i].discount != undefined && "") {
              number = drug_details[i].discount
            }
            else {
              number = 0
            }
            if (drug_details[i].expiry_date != undefined) {
              var expiry_date = drug_details[i].expiry_date != undefined ? drug_details[i].expiry_date.split('-') : "";
              reverse_exp = expiry_date[2] + "-" + expiry_date[1] + "-" + expiry_date[0];
            }

            if (this.discType != undefined) {
              this.discountChange(this.discType)

              if (this.discountdesc == "cost plus gst") {
                price = (drug_details[i].cost_price / drug_details[i].per_unit).toFixed(2);
              }
              else if (this.discountdesc == "sales plus gst") {
                price = (drug_details[i].sales_price / drug_details[i].per_unit).toFixed(2);
              }
              else if (this.discountdesc == "MRP") {
                price = (drug_details[i].price / drug_details[i].per_unit).toFixed(2);
              } else {
                price = (drug_details[i].price / drug_details[i].per_unit).toFixed(2);
              }
            }

            if (drug_details[i].short_name == undefined) {
              drug_details[i].short_name = ""
            }

            if (obj.middle_name != undefined) {
              this.clientName = encrypt_decript.Decript(obj.first_name) + " " + encrypt_decript.Decript(obj.middle_name) + " " + encrypt_decript.Decript(obj.last_name);
              this.name = this.clientname
            } else if (obj.middle_name == undefined && obj.last_name != undefined) {
              this.clientName = encrypt_decript.Decript(obj.first_name) + " " + encrypt_decript.Decript(obj.last_name);
              this.name = this.clientname
            } else {
              this.clientName = "";
              this.name = this.clientname;
            }

            if (obj.mr_no != undefined) {
              this.mrNumb = obj.mr_no
            } else {
              this.mrNumb = ""
            }

            if (obj.doc_qualif != undefined) {
              this.qualification = obj.doc_qualif
              this.qualification_flag = false
            } else {
              this.qualification = ''
              this.qualification_flag = true
            }

            for (var m = 0; m < this.patientdetailsarray.length; m++) {
              if (this.patientdetailsarray[m].mrno == this.mrNumb) {
                this.patientdetailslist(this.patientdetailsarray[m])
              }
            }
            // if (obj.age != undefined) {
            //   this.clntAge = obj.age;

            // } else {
            //   this.clntAge = "";
            // }
            // if (obj.gender != undefined) {
            //   this.clntGender = obj.gender;
            // } else {
            //   this.clntGender = "";
            // }
            // if (this.clntGender == "Male" || this.clntGender == "M") {
            //   this.headergen = "M";
            // } else if (this.clntGender == "Female" || this.clntGender == "F") {
            //   this.headergen = "F";
            // } else if (this.clntGender == "Transgender" || this.clntGender == "M") {
            //   this.headergen = "TG";
            // } else {
            //   this.headergen = "";
            // }

            this.returnsDetailsArray.push({
              drug_name: drug_details[i].short_name + " " + drug_details[i].drug_name,
              drug_disp_name: drug_details[i].short_name + " " + drug_details[i].drug_name,
              // batch_required:drug_details[i].batch_no,
              drug_id: drug_details[i].drug_id,
              index: i + 1,
              quantity: drug_details[i].quantity,
              rack_id: drug_details[i].rack_id,
              bin_id: drug_details[i].bin_id,
              rack_desc: drug_details[i].rack_desc,
              bin_desc: drug_details[i].bin_desc,
              price: drug_details[i].price,
              per_unit: drug_details[i].per_unit,
              cost_price: drug_details[i].cost_price,
              sales_price: drug_details[i].sales_price,
              mrp: drug_details[i].price,
              // discount:drug_details[i].discount!= undefined ? (parseInt(drug_details[i].discount)).toFixed(2) : "0.00",
              discount: drug_details[i].discount || 0,
              amount: parseInt(drug_details[i].amount),
              batch_no: drug_details[i].batch_no,
              expiry_date: reverse_exp,
              batcharray: "",
              sgst: drug_details[i].sgst,
              cgst: drug_details[i].cgst
            })
            console.log(" prod 2 =" + this.prodDiscount)
          }

          if (this.discType != undefined) {
            this.discountcalculatestaffreturn()
          }
        }
        ,
        error => {
          console.log("in retrive the bill data")
        }
      )
    // }
  }

  searchBill() {
    FrontDesk_Helper.setBillToDate(this.toDate)
    this.billList = [];
    this.billingList = [];
    var fieldvalue;
    if (this.filter == "Patient_ID") {
      fieldvalue = {
        mr_no: this.patientId,
        type: "mr_no",
        pharma_id: this.pharmacyID,
      }

    } else if (this.filter == "Name") {
      fieldvalue = {
        type: "first_name",
        first_name: this.firstName,
        last_name: this.lastName,
        pharma_id: this.pharmacyID,
      }

    } else if (this.filter == "date") {
      fieldvalue = {
        type: "date",
        fdate: this.fromDate,
        tdate: this.toDate,
        pharma_id: this.pharmacyID,
      }

    } else if (this.filter == "Mobile_No") {
      fieldvalue = {
        type: "mobile",
        mobile: this.mobileNo,
        pharma_id: this.pharmacyID,
      }

    }
    console.log("send value = " + JSON.stringify(fieldvalue))
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'pharmacontrol/gbbf/', fieldvalue,
      { headers: headers })
      .subscribe(
        response => {
          // var obj = JSON.parse(JSON.stringify(response));
          var obj = response.json();
          console.log("bill list = " + JSON.stringify(obj));
          if (obj.bill_details.length != 0) {
            console.log(obj);
            this.setBillList(obj);
          }
          else {
            this.billList = [];
          }

        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        });
    this.patientId = "";
    this.location = "";
    // this.cityDesc = "";
    this.firstName = "";
    this.lastName = "";
    this.mobileNo = "";

  }

  setBillList(obj) {
    this.billList = [];

    if (obj.bill_details != null) {
      for (var i = 0; i < obj.bill_details.length; i++) {
        var clnt_nmae = null;
        if (obj.bill_details[i].middle_name != undefined && obj.bill_details[i].middle_name != null) {
          clnt_nmae = encrypt_decript.Decript(obj.bill_details[i].first_name) + " " + encrypt_decript.Decript(obj.bill_details[i].middle_name) + " " + encrypt_decript.Decript(obj.bill_details[i].last_name);
        } else {
          if (obj.bill_details[i].last_name != undefined && obj.bill_details[i].last_name != null) {
            clnt_nmae = encrypt_decript.Decript(obj.bill_details[i].first_name) + " " + encrypt_decript.Decript(obj.bill_details[i].last_name);
          } else if (obj.bill_details[i].first_name != undefined) {
            clnt_nmae = encrypt_decript.Decript(obj.bill_details[i].first_name);
          } else {
            clnt_nmae = ""
          }
        }
        var get_date = obj.bill_details[i].order_date.split('-');
        this.billList.push({
          bill_id: obj.bill_details[i].order_id,
          date: get_date[2] + "-" + get_date[1] + "-" + get_date[0],
          client_name: clnt_nmae,
          relation_id: obj.bill_details[i].relation_id,
          salutation: obj.bill_details[i].salutation,
          bill_amount: obj.bill_details[i].bill_amount,
          paid_amount: obj.bill_details[i].paid_amount,
          balance: obj.bill_details[i].balance,
          paid_flag: obj.bill_details[i].paid_flag,
          pay_type: obj.bill_details[i].payment_desc,
          fin_year: obj.bill_details[i].fin_year,
          client_reg_id: obj.bill_details[i].client_reg_id
        });
      }
      var tot = 0;
      for (var i = 0; i < this.billList.length; i++) {
        tot = tot + parseFloat(this.billList[i].amount_only);
      }
    }
  }

  getReferralData(e) {
    this.ReferedDoctorArray = [];
    this.referedName = [];

    if (this.doctorName != undefined && this.doctorName.length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'search/dbyp',
        JSON.stringify({
          center_id: this.pharmacyID,
          doc_name: this.doctorName,
          type: "pharma"
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            console.log("referal obj = " + JSON.stringify(obj))
            this.referedName = obj.doctors;
            if (this.referedName != undefined) {
              for (var i = 0; i < this.referedName.length; i++) {
                var docname;
                if (this.referedName[i].middle_name != undefined) {
                  docname = this.referedName[i].first_name + " " + this.referedName[i].middle_name + " " + this.referedName[i].last_name;
                } else {
                  if (this.referedName[i].last_name != undefined) {
                    docname = this.referedName[i].first_name + " " + this.referedName[i].last_name;
                  } else {
                    docname = this.referedName[i].first_name
                  }
                }

                if (this.referedName[i].doc_qualif != undefined && this.referedName[i].doc_qualif != '') {
                  this.referedName[i].doc_qualif = this.referedName[i].doc_qualif
                } else {
                  this.referedName[i].doc_qualif = ''
                }


                this.ReferedDoctorArray.push({
                  docname: docname,
                  doc_reg_id: this.referedName[i].prov_id,
                  hptl_clinic_id: this.referedName[i].hptl_clinic_id,
                  doc_qualif: this.referedName[i].doc_qualif
                });
              }
            }
          },
          error => {
            this.toastr.error(Message_data.network);
          });
    }
    else {
      this.ReferedDoctorArray = [];
    }
  }

  Select_refer_doctor(id) {
    this.docsalflag = false;
    var item1 = this.doctorName;
    for (var i = 0; i < this.referedName.length; i++) {
      if (this.referedName[i].prov_id == id) {
        this.hospitalID = this.referedName[i].hptl_clinic_id;
        this.docRegID = this.referedName[i].prov_id;
        if (this.referedName[i].doc_qualif != '') {
          this.qualification = this.referedName[i].doc_qualif
          this.qualification_flag = false
        } else {
          this.qualification = ''
          this.qualification_flag = true
        }
        break;
      }
    }
    this.ReferedDoctorArray = [];
    this.getAppointments();
  }

  getDiscountDetails() {
    this.discregSalesVolArray = [];
    this.discSalesVolArray = [];
    this.discTransVolArray = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "pharmacontrol/gpdis/",
      JSON.stringify({
        pharma_id: this.pharmacyID,
        imei: this.ipaddress
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          this.discregSalesVolArray = obj.regsal_vol;
          this.discSalesVolArray = obj.sales_vol;
          this.discTransVolArray = obj.trans_vol;
        });
  }

  getDiscTypes() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.discURL, { center_id: this.pharmacyID }, { headers: headers }).subscribe(
      data => {
        this.discountDatas = [];
        var distypes = data.json();
        this.discountDatasArray = distypes.disc_types;
        for (var i = 0; i < this.discountDatasArray.length; i++) {
          if (this.discountDatasArray[i].inp_default == 1) {
            this.discType = this.discountDatasArray[i].disc_type_id;
            this.rateplaneflag = true
            break
          } else {
            this.discType = this.discountDatasArray[0].disc_type_id;
            this.rateplaneflag = true
          }
        }
        this.discountChange(this.discType)
      }, error => { });
  }

  discountChange(distype) {
    var discountdesc;
    var discount_id;
    this.disc = "";

    for (let k = 0; k < this.drugDetailsArray.length; k++) {
      if (this.drugDetailsArray[k].drug_id != "") {
        this.rateplaneflag = true;
      }
    }
    for (var i = 0; i < this.discountDatasArray.length; i++) {
      if (distype == this.discountDatasArray[i].disc_type_id) {
        this.rateplanetype = this.discountDatasArray[i].description;
        this.discountdesc = this.discountDatasArray[i].discount_type;
        this.discount_id = this.discountDatasArray[i].disc_type_id;
        this.disperce = this.discountDatasArray[i].discount;
        this.discountvalue_amount = this.discountDatasArray[i].discount;
        this.discountcalculatestaff();
        // this.discountcalculatestaffreturn();
      }
    }
  }

  discountcalculatestaff() {
    if (this.rateplanetype == "Product wise") {
      this.lineitmdisflag = false
    } else {
      this.lineitmdisflag = true
    }

    if (this.discountdesc == "cost plus gst") {
      this.remaingBalance = "0.00";
      this.GSTamountflag = false;
      this.discamount = 0;
      this.discamountinsure = 0;
      this.totalAmount = 0;
      this.totalAmountinsure = 0;
      this.prodDiscount = 0;
      this.prodDiscountinsure = 0;
      this.GSTamount = 0;
      this.CGSTamount = 0.00;
      this.SGSTamount = 0.00;
      this.GSTamountinsure = 0;
      this.gstdecimalamt = 0;
      this.gstdecimalamtinsure = 0;
      this.amountDiscount = "0.00";
      this.amountDiscountinsure = 0.00;
      this.duesponser = 0.00;

      if (this.insuranceflag == true) {
        for (var i = 0; i < this.drugDetailsArray.length; i++) {
          this.aftdiscamt = 0;
          var salesprice = 0;
          var costprice = 0;
          var discgst = 0;
          var dissgst = 0;
          var cgstcal = 0;
          var sgstcal = 0;
          var calTotalamount = 0;
          var costpricesubstute;
          var salsepricesubstute;
          var costlineamount = 0;

          if (this.drugDetailsArray[i].drug_id != "") {

            if (this.drugDetailsArray[i].cost_price != undefined && this.drugDetailsArray[i].cost_price != null && this.drugDetailsArray[i].cost_price != "") {
              //costprice=
              if (this.priceedit == "0") {
                costprice = parseFloat(this.drugDetailsArray[i].cost_price) / parseInt(this.drugDetailsArray[i].per_unit);
              } else {
                costprice = parseFloat(this.drugDetailsArray[i].price);
              }
              costlineamount = costprice * this.drugDetailsArray[i].quantity;
              costpricesubstute = costlineamount - (parseFloat(this.discountvalue_amount) / 100);

              //salesprice
              salesprice = parseInt(this.drugDetailsArray[i].sales_price) / parseInt(this.drugDetailsArray[i].per_unit);
              saleslineamount = salesprice * this.drugDetailsArray[i].quantity;
              // discount cal
              var disamt = costlineamount * (parseFloat(this.discountvalue_amount) / 100)
              this.discamount += (costlineamount * (parseFloat(this.discountvalue_amount) / 100))
              this.aftdiscamt += (costlineamount - disamt);
            }
            else {
              costpricesubstute = 0;
              salsepricesubstute = 0;
            }
            if (this.drugDetailsArray[i].cgst != undefined && this.drugDetailsArray[i].cgst != null && this.drugDetailsArray[i].cgst != "") {
              discgst = parseFloat(this.drugDetailsArray[i].cgst)
            } else {
              discgst = 0
            }
            if (this.drugDetailsArray[i].sgst != undefined && this.drugDetailsArray[i].sgst != null && this.drugDetailsArray[i].sgst != "") {
              dissgst = parseFloat(this.drugDetailsArray[i].sgst)
            } else {
              dissgst = 0
            }
            if (this.priceedit == "0") {
              this.drugDetailsArray[i].price = costprice.toFixed(2);
            }
            this.drugDetailsArray[i].amount = costlineamount.toFixed(2);
            this.drugDetailsArray[i].discountstaff = this.discamount;
            cgstcal = parseFloat((this.aftdiscamt * discgst / 100).toFixed(2))
            sgstcal = parseFloat((this.aftdiscamt * dissgst / 100).toFixed(2))
            this.drugDetailsArray[i].cgstcal = cgstcal
            this.drugDetailsArray[i].sgstcal = sgstcal
            this.CGSTamount += parseFloat((cgstcal).toFixed(2));
            this.SGSTamount += parseFloat((sgstcal).toFixed(2));
            this.gstdecimalamt += parseFloat((cgstcal + sgstcal).toFixed(2));
            this.GSTamount = this.spliceDecimal(this.gstdecimalamt);
            if (this.GSTamount == 0) {
              this.GSTamountflag = true

            } else {
              this.GSTamountflag = false
            }
            calTotalamount += this.aftdiscamt + cgstcal + sgstcal;
            this.drugDetailsArray[i].calamt = calTotalamount;

            this.totalAmount = parseFloat(this.totalAmount) + parseFloat(costlineamount.toFixed(2));
            this.totalAmount = this.totalAmount.toFixed(2); // Convert totalAmount to a string with 2 decimal places
            this.prodDiscount = this.discamount.toFixed(2);

            if (this.prodDiscount == 0.00) {
              this.discoutFlag = true

            } else {
              this.discoutFlag = false
            }
            if (this.amountDiscount != "") {
              this.amountDiscount = Math.round(parseFloat(this.amountDiscount) + calTotalamount).toFixed(2)

            } else {
              this.amountDiscount = Math.round(calTotalamount).toFixed(2)
              // Math.round(amtval).toFixed(2);
            }



            if (this.paidText != "") {
              this.remaingBalance = Math.round(parseFloat(this.amountDiscount) - parseFloat(this.paidText)).toFixed(2);
            } else {
              this.remaingBalance = Math.round(parseFloat(this.amountDiscount) - 0).toFixed(2);
            }

            if (this.returnbilltotalAmount != 0) {
              this.remaingBalance = (parseFloat(this.remaingBalance) - this.returnbilltotalAmount).toFixed(2)
            }


          }


        }
      } else {
        for (var i = 0; i < this.drugDetailsArray.length; i++) {
          this.aftdiscamt = 0;
          this.aftdiscamtinsure = 0;
          var salesprice = 0;
          var costprice = 0;
          var discgst = 0;
          var dissgst = 0;
          var cgstcal = 0;
          var sgstcal = 0;
          var calTotalamount = 0;
          var amountDiscountinsure = 0.00
          var calTotalamountinsure = 0.00;
          var costpricesubstute;
          var salsepricesubstute;
          var costlineamount = 0;

          if (this.drugDetailsArray[i].drug_id != "") {
            if (this.drugDetailsArray[i].ins_allow == "0") {

              if (this.drugDetailsArray[i].cost_price != undefined && this.drugDetailsArray[i].cost_price != null && this.drugDetailsArray[i].cost_price != "") {
                //costprice=
                if (this.priceedit == "0") {
                  costprice = parseFloat(this.drugDetailsArray[i].cost_price) / parseInt(this.drugDetailsArray[i].per_unit);
                } else {
                  costprice = parseFloat(this.drugDetailsArray[i].price);
                }
                costlineamount = costprice * this.drugDetailsArray[i].quantity;
                costpricesubstute = costlineamount - (parseFloat(this.discountvalue_amount) / 100);

                //salesprice
                salesprice = parseInt(this.drugDetailsArray[i].sales_price) / parseInt(this.drugDetailsArray[i].per_unit);
                saleslineamount = salesprice * this.drugDetailsArray[i].quantity;
                // discount cal
                var disamt = costlineamount * (parseFloat(this.discountvalue_amount) / 100)
                this.discamount += (costlineamount * (parseFloat(this.discountvalue_amount) / 100))
                this.aftdiscamt += (costlineamount - disamt);
              }
              else {
                costpricesubstute = 0;
                salsepricesubstute = 0;
              }
              if (this.drugDetailsArray[i].cgst != undefined && this.drugDetailsArray[i].cgst != null && this.drugDetailsArray[i].cgst != "") {
                discgst = parseFloat(this.drugDetailsArray[i].cgst)
              } else {
                discgst = 0
              }
              if (this.drugDetailsArray[i].sgst != undefined && this.drugDetailsArray[i].sgst != null && this.drugDetailsArray[i].sgst != "") {
                dissgst = parseFloat(this.drugDetailsArray[i].sgst)
              } else {
                dissgst = 0
              }
              if (this.priceedit == "0") {
                this.drugDetailsArray[i].price = costprice.toFixed(2);
              }
              this.drugDetailsArray[i].amount = costlineamount.toFixed(2);
              this.drugDetailsArray[i].discountstaff = this.discamount;
              cgstcal = parseFloat((this.aftdiscamt * discgst / 100).toFixed(2))
              sgstcal = parseFloat((this.aftdiscamt * dissgst / 100).toFixed(2))
              this.drugDetailsArray[i].cgstcal = cgstcal
              this.drugDetailsArray[i].sgstcal = sgstcal
              this.CGSTamount += parseFloat((cgstcal).toFixed(2));
              this.SGSTamount += parseFloat((sgstcal).toFixed(2));
              this.gstdecimalamt += parseFloat((cgstcal + sgstcal).toFixed(2));
              this.GSTamount = this.spliceDecimal(this.gstdecimalamt);
              if (this.GSTamount == 0) {
                this.GSTamountflag = true

              } else {
                this.GSTamountflag = false
              }
              calTotalamount += this.aftdiscamt + cgstcal + sgstcal;
              this.drugDetailsArray[i].calamt = calTotalamount;

              this.totalAmount = parseFloat(this.totalAmount) + parseFloat(costlineamount.toFixed(2));
              this.totalAmount = this.totalAmount.toFixed(2); // Convert totalAmount to a string with 2 decimal places
              this.prodDiscount = this.discamount.toFixed(2);

              if (this.prodDiscount == 0.00) {
                this.discoutFlag = true

              } else {
                this.discoutFlag = false
              }

              if (this.amountDiscountinsure != "") {
                this.amountDiscountinsure = Math.round(parseFloat(this.amountDiscountinsure) + calTotalamount).toFixed(2)
              } else {
                this.amountDiscountinsure = Math.round(calTotalamount).toFixed(2)
              }

              if (this.amountDiscount != "") {
                this.amountDiscount = Math.round(parseFloat(this.amountDiscount) + calTotalamount).toFixed(2)

              } else {
                this.amountDiscount = Math.round(calTotalamount).toFixed(2)

              }



              if (this.paidText != "") {
                this.remaingBalance = Math.round(parseFloat(this.amountDiscountinsure) - parseFloat(this.paidText)).toFixed(2);
              } else {
                this.remaingBalance = Math.round(parseFloat(this.amountDiscountinsure) - 0).toFixed(2);
              }

              // if (this.returnbilltotalAmount != 0) {
              //   this.remaingBalance = (parseFloat(this.remaingBalance) - this.returnbilltotalAmount).toFixed(2)
              // }

            } else {
              if (this.drugDetailsArray[i].cost_price != undefined && this.drugDetailsArray[i].cost_price != null && this.drugDetailsArray[i].cost_price != "") {
                //costprice=
                if (this.priceedit == "0") {
                  costprice = parseFloat(this.drugDetailsArray[i].cost_price) / parseInt(this.drugDetailsArray[i].per_unit);
                } else {
                  costprice = parseFloat(this.drugDetailsArray[i].price);
                }
                costlineamount = costprice * this.drugDetailsArray[i].quantity;
                costpricesubstute = costlineamount - (parseFloat(this.discountvalue_amount) / 100);

                //salesprice
                salesprice = parseInt(this.drugDetailsArray[i].sales_price) / parseInt(this.drugDetailsArray[i].per_unit);
                saleslineamount = salesprice * this.drugDetailsArray[i].quantity;
                // discount cal
                var disamt = costlineamount * (parseFloat(this.discountvalue_amount) / 100)
                var itemaftdisamt = costlineamount - disamt;
                this.discamountinsure += (costlineamount * (parseFloat(this.discountvalue_amount) / 100))
                this.aftdiscamtinsure += (costlineamount - disamt);
              }
              else {
                costpricesubstute = 0;
                salsepricesubstute = 0;
              }
              if (this.drugDetailsArray[i].cgst != undefined && this.drugDetailsArray[i].cgst != null && this.drugDetailsArray[i].cgst != "") {
                discgst = parseFloat(this.drugDetailsArray[i].cgst)
              } else {
                discgst = 0
              }
              if (this.drugDetailsArray[i].sgst != undefined && this.drugDetailsArray[i].sgst != null && this.drugDetailsArray[i].sgst != "") {
                dissgst = parseFloat(this.drugDetailsArray[i].sgst)
              } else {
                dissgst = 0
              }
              if (this.priceedit == "0") {
                this.drugDetailsArray[i].price = costprice.toFixed(2);
              }
              this.drugDetailsArray[i].amount = costlineamount.toFixed(2);
              this.drugDetailsArray[i].discountstaff = this.discamount;
              cgstcal = parseFloat((itemaftdisamt * discgst / 100).toFixed(2))
              sgstcal = parseFloat((itemaftdisamt * dissgst / 100).toFixed(2))
              this.gstdecimalamtinsure += parseFloat((cgstcal + sgstcal).toFixed(2));
              this.GSTamountinsure = this.spliceDecimal(this.gstdecimalamtinsure);
              if (this.GSTamountinsure == 0) {
                this.GSTamountflag = true

              } else {
                this.GSTamountflag = false
              }
              calTotalamountinsure += itemaftdisamt + cgstcal + sgstcal;
              this.drugDetailsArray[i].calamt = calTotalamountinsure;
              this.totalAmount = parseFloat(this.totalAmount) + parseFloat(costlineamount.toFixed(2));
              this.totalAmount = this.totalAmount.toFixed(2);
              this.totalAmountinsure = parseFloat(this.totalAmountinsure) + parseFloat(costlineamount.toFixed(2));
              this.totalAmountinsure = this.totalAmountinsure.toFixed(2); // Convert totalAmount to a string with 2 decimal places
              this.prodDiscountinsure = this.discamountinsure.toFixed(2);

              if (this.prodDiscountinsure == 0.00) {
                this.discoutFlag = true

              } else {
                this.discoutFlag = false
              }
              if (this.duesponser != "") {
                this.duesponser = Math.round(parseFloat(this.duesponser) + calTotalamountinsure).toFixed(2)
              } else {
                this.duesponser = Math.round(calTotalamountinsure).toFixed(2)
              }
              if (this.amountDiscount != "") {
                this.amountDiscount = Math.round(parseFloat(this.amountDiscount) + calTotalamountinsure).toFixed(2)

              } else {
                this.amountDiscount = Math.round(calTotalamountinsure).toFixed(2)
                // Math.round(amtval).toFixed(2);
              }
              // if (this.paidText != "") {
              //   this.remaingBalance = Math.round(parseFloat(this.amountDiscount) - parseFloat(this.paidText)).toFixed(2);
              // } else {
              //   this.remaingBalance = Math.round(parseFloat(this.amountDiscount) - 0).toFixed(2);
              // }
            }
            this.prodDiscount = (parseFloat(this.prodDiscount) + parseFloat(this.prodDiscountinsure)).toFixed(2);
            this.wholegstdecimal = this.GSTamount + this.GSTamountinsure
            this.GSTamount = this.spliceDecimal(this.wholegstdecimal)
            amountDiscountinsure = parseFloat(this.duesponser) + parseFloat(this.amountDiscountinsure)
          }
        }
      }
    } else if (this.discountdesc == "sales plus gst") {
      //salesplucgst----	Own staff
      this.remaingBalance = "0.00";
      this.GSTamountflag = false;
      this.discamount = 0;
      this.discamountinsure = 0;
      this.totalAmount = 0;
      this.totalAmountinsure = 0;
      this.amountDiscount = 0.00;
      this.amountDiscountinsure = 0.00;
      this.duesponser = 0.00;
      this.prodDiscount = 0;
      this.prodDiscountinsure = 0;
      this.GSTamount = 0;
      this.CGSTamount = 0.00;
      this.SGSTamount = 0.00;
      this.GSTamountinsure = 0;
      this.gstdecimalamt = 0;
      this.gstdecimalamtinsure = 0;
      if (this.insuranceflag == true) {
        for (var i = 0; i < this.drugDetailsArray.length; i++) {
          this.aftdiscamt = 0;
          var salesprice = 0;
          var costprice = 0;
          var discgst = 0;
          var dissgst = 0;
          var cgstcal = 0;
          var sgstcal = 0;
          var calTotalamount = 0.00;
          var saleslineamount = 0;
          var costpricesubstute;
          var salsepricesubstute;
          if (this.drugDetailsArray[i].drug_id != "") {

            if (this.drugDetailsArray[i].sales_price != undefined && this.drugDetailsArray[i].sales_price != null && this.drugDetailsArray[i].sales_price != "") {
              costprice = parseInt(this.drugDetailsArray[i].cost_price) / parseInt(this.drugDetailsArray[i].per_unit);
              var costlineamount = costprice * this.drugDetailsArray[i].quantity;
              costpricesubstute = costlineamount - (parseFloat(this.discountvalue_amount) / 100);
              //sales price

              if (this.priceedit == "0") {
                salesprice = parseFloat(this.drugDetailsArray[i].sales_price) / parseInt(this.drugDetailsArray[i].per_unit);
              } else {
                salesprice = parseFloat(this.drugDetailsArray[i].price);
              }

              saleslineamount = salesprice * this.drugDetailsArray[i].quantity;
              //mrp
              var disamt = saleslineamount * (parseFloat(this.discountvalue_amount) / 100);
              var itemaftdisamt = saleslineamount - disamt;
              this.discamount += disamt
              this.aftdiscamt += itemaftdisamt;
            } else {
              costprice = 0;
              salesprice = 0;
            }
            if (this.drugDetailsArray[i].cgst != undefined && this.drugDetailsArray[i].cgst != null && this.drugDetailsArray[i].cgst != "") {
              discgst = parseFloat(this.drugDetailsArray[i].cgst)
            } else {
              discgst = 0
            }
            if (this.drugDetailsArray[i].sgst != undefined && this.drugDetailsArray[i].sgst != null && this.drugDetailsArray[i].sgst != "") {
              dissgst = parseFloat(this.drugDetailsArray[i].sgst)
            } else {
              dissgst = 0
            }

            if (this.priceedit == "0") {
              this.drugDetailsArray[i].price = salesprice.toFixed(2)
            }

            this.drugDetailsArray[i].amount = saleslineamount.toFixed(2);
            this.drugDetailsArray[i].discountstaff = this.discamount;
            cgstcal = parseFloat((itemaftdisamt * (discgst / 100)).toFixed(2));
            sgstcal = parseFloat((itemaftdisamt * (dissgst / 100)).toFixed(2));
            this.drugDetailsArray[i].cgstcal = cgstcal
            this.drugDetailsArray[i].sgstcal = sgstcal
            this.CGSTamount += parseFloat((cgstcal).toFixed(2));
            this.SGSTamount += parseFloat((sgstcal).toFixed(2));
            this.gstdecimalamt += parseFloat((cgstcal + sgstcal).toFixed(2));
            this.GSTamount = this.spliceDecimal(this.gstdecimalamt);
            if (this.GSTamount == 0) {
              this.GSTamountflag = true
            } else {
              this.GSTamountflag = false
            }
            calTotalamount += itemaftdisamt + cgstcal + sgstcal
            this.drugDetailsArray[i].calamt = calTotalamount
            this.totalAmount = parseFloat(this.totalAmount) + parseFloat(saleslineamount.toFixed(2));
            this.totalAmount = this.totalAmount.toFixed(2); // Convert totalAmount to a string with 2 decimal places
            this.prodDiscount = this.discamount.toFixed(2);
            if (this.prodDiscount == 0.00) {
              this.discoutFlag = true
            } else {
              this.discoutFlag = false
            }
            if (this.amountDiscount != "") {
              this.amountDiscount = Math.round(parseFloat(this.amountDiscount) + calTotalamount).toFixed(2)
            } else {
              this.amountDiscount = Math.round(calTotalamount).toFixed(2)
            }

            if (this.paidText != "") {
              this.remaingBalance = Math.round(parseFloat(this.amountDiscount) - parseFloat(this.paidText)).toFixed(2);
            } else {
              this.remaingBalance = Math.round(parseFloat(this.amountDiscount) - 0).toFixed(2);
            }

            if (this.returnbilltotalAmount != 0) {
              this.remaingBalance = (parseFloat(this.remaingBalance) - this.returnbilltotalAmount).toFixed(2)
            }
            console.log("DISCOUNT VALUE sa = " + JSON.stringify(this.drugDetailsArray[i]))
            console.log("saleslineamount = " + saleslineamount)
            console.log("saleslineamount" + saleslineamount + " sales_price = " + salesprice + " quantity " + this.drugDetailsArray[i].quantity)
            console.log("disamt = " + disamt + " sales_price = " + saleslineamount + " discountvalue _amount " + parseFloat(this.discountvalue_amount) / 100)
            console.log("after discamt = " + this.aftdiscamt + " salesline = " + saleslineamount + " disamt " + disamt)
            console.log("sales price = " + salesprice + " sales_price = " + this.drugDetailsArray[i].sales_price + "" + this.drugDetailsArray[i].per_unit)
            console.log("discamount = " + this.discamount + " salesline amount  = " + saleslineamount + " discount amount =  " + parseFloat(this.discountvalue_amount) / 100)
            console.log("sales price = " + salesprice)
            console.log("afterdiscamt = " + this.aftdiscamt + " cgst " + cgstcal + " sgst " + sgstcal)
            console.log("calTotalamount = " + calTotalamount)
            console.log("afterdiscamt = " + this.totalAmount + " cgst " + saleslineamount)
            console.log("amount dicont = " + this.amountDiscount + " calTotalamount = " + calTotalamount)
          }
        }
      } else {
        for (var i = 0; i < this.drugDetailsArray.length; i++) {
          this.aftdiscamt = 0;
          this.aftdiscamtinsure = 0;
          var salesprice = 0;
          var costprice = 0;
          var discgst = 0;
          var dissgst = 0;
          var cgstcal = 0;
          var sgstcal = 0;
          var calTotalamount = 0.00;
          var amountDiscountinsure = 0.00
          var calTotalamountinsure = 0.00;
          var saleslineamount = 0;
          var costpricesubstute;
          var salsepricesubstute;
          if (this.drugDetailsArray[i].drug_id != "") {
            if (this.drugDetailsArray[i].ins_allow == "0") {
              if (this.drugDetailsArray[i].sales_price != undefined && this.drugDetailsArray[i].sales_price != null && this.drugDetailsArray[i].sales_price != "") {
                costprice = parseInt(this.drugDetailsArray[i].cost_price) / parseInt(this.drugDetailsArray[i].per_unit);
                var costlineamount = costprice * this.drugDetailsArray[i].quantity;
                costpricesubstute = costlineamount - (parseFloat(this.discountvalue_amount) / 100);
                //sales price

                if (this.priceedit == "0") {
                  salesprice = parseFloat(this.drugDetailsArray[i].sales_price) / parseInt(this.drugDetailsArray[i].per_unit);
                } else {
                  salesprice = parseFloat(this.drugDetailsArray[i].price);
                }

                saleslineamount = salesprice * this.drugDetailsArray[i].quantity;
                console.log("PRICE BEFORE DISC " + this.drugDetailsArray[i].drug_id + " === " + saleslineamount)
                //mrp
                var disamt = saleslineamount * (parseFloat(this.discountvalue_amount) / 100);
                console.log("DISCOUNT VALUE " + disamt);
                var itemaftdisamt = saleslineamount - disamt;
                console.log("AFTER DISC AMOUNT ================ " + itemaftdisamt);
                this.discamount += disamt
                this.aftdiscamt += itemaftdisamt;
              } else {
                costprice = 0;
                salesprice = 0;
              }
              if (this.drugDetailsArray[i].cgst != undefined && this.drugDetailsArray[i].cgst != null && this.drugDetailsArray[i].cgst != "") {
                discgst = parseFloat(this.drugDetailsArray[i].cgst)
              } else {
                discgst = 0
              }
              if (this.drugDetailsArray[i].sgst != undefined && this.drugDetailsArray[i].sgst != null && this.drugDetailsArray[i].sgst != "") {
                dissgst = parseFloat(this.drugDetailsArray[i].sgst)
              } else {
                dissgst = 0
              }

              if (this.priceedit == "0") {
                this.drugDetailsArray[i].price = salesprice.toFixed(2)
              }

              this.drugDetailsArray[i].amount = saleslineamount.toFixed(2);
              this.drugDetailsArray[i].discountstaff = this.discamount;
              cgstcal = parseFloat((itemaftdisamt * (discgst / 100)).toFixed(2));
              sgstcal = parseFloat((itemaftdisamt * (dissgst / 100)).toFixed(2));
              this.drugDetailsArray[i].cgstcal = cgstcal
              this.drugDetailsArray[i].sgstcal = sgstcal
              this.CGSTamount += parseFloat((cgstcal).toFixed(2));
              this.SGSTamount += parseFloat((sgstcal).toFixed(2));
              this.gstdecimalamt += parseFloat((cgstcal + sgstcal).toFixed(2));
              this.GSTamount = this.spliceDecimal(this.gstdecimalamt);

              if (this.GSTamount == 0) {
                this.GSTamountflag = true
              } else {
                this.GSTamountflag = false
              }
              calTotalamount += itemaftdisamt + cgstcal + sgstcal
              console.log("GST VALUE ================ " + itemaftdisamt + " ===== " + cgstcal + " ===== " + sgstcal + " ===== " + calTotalamount)
              this.drugDetailsArray[i].calamt = calTotalamount
              this.totalAmount = parseFloat(this.totalAmount) + parseFloat(saleslineamount.toFixed(2));
              this.totalAmount = this.totalAmount.toFixed(2); // Convert totalAmount to a string with 2 decimal places
              this.prodDiscount = this.discamount.toFixed(2);
              if (this.prodDiscount == 0.00) {
                this.discoutFlag = true
              } else {
                this.discoutFlag = false
              }
              if (this.amountDiscountinsure != "") {
                this.amountDiscountinsure = Math.round(parseFloat(this.amountDiscountinsure) + calTotalamount).toFixed(2)
              } else {
                this.amountDiscountinsure = Math.round(calTotalamount).toFixed(2)
              }

              if (this.amountDiscount != "") {
                this.amountDiscount = Math.round(parseFloat(this.amountDiscount) + calTotalamount).toFixed(2)
              } else {
                this.amountDiscount = Math.round(calTotalamount).toFixed(2)
              }

              if (this.paidText != "") {
                this.remaingBalance = Math.round(parseFloat(this.amountDiscountinsure) - parseFloat(this.paidText)).toFixed(2);
              } else {
                this.remaingBalance = Math.round(parseFloat(this.amountDiscountinsure) - 0).toFixed(2);
              }

              // if (this.returnbilltotalAmount != 0) {
              //   this.remaingBalance = (parseFloat(this.remaingBalance) - this.returnbilltotalAmount).toFixed(2)
              // }
            }
            else {

              if (this.drugDetailsArray[i].sales_price != undefined && this.drugDetailsArray[i].sales_price != null && this.drugDetailsArray[i].sales_price != "") {
                costprice = parseInt(this.drugDetailsArray[i].cost_price) / parseInt(this.drugDetailsArray[i].per_unit);
                var costlineamount = costprice * this.drugDetailsArray[i].quantity;
                costpricesubstute = costlineamount - (parseFloat(this.discountvalue_amount) / 100);
                //sales price
                if (this.priceedit == "0") {
                  salesprice = parseFloat(this.drugDetailsArray[i].sales_price) / parseInt(this.drugDetailsArray[i].per_unit);
                } else {
                  salesprice = parseFloat(this.drugDetailsArray[i].price);
                }
                saleslineamount = salesprice * this.drugDetailsArray[i].quantity;
                //mrp
                var disamt = saleslineamount * (parseFloat(this.discountvalue_amount) / 100);
                var itemaftdisamt = saleslineamount - disamt;
                this.discamountinsure += disamt
                this.aftdiscamtinsure += itemaftdisamt;
              } else {
                costprice = 0;
                salesprice = 0;
              }
              if (this.drugDetailsArray[i].cgst != undefined && this.drugDetailsArray[i].cgst != null && this.drugDetailsArray[i].cgst != "") {
                discgst = parseFloat(this.drugDetailsArray[i].cgst)
              } else {
                discgst = 0
              }
              if (this.drugDetailsArray[i].sgst != undefined && this.drugDetailsArray[i].sgst != null && this.drugDetailsArray[i].sgst != "") {
                dissgst = parseFloat(this.drugDetailsArray[i].sgst)
              } else {
                dissgst = 0
              }

              if (this.priceedit == "0") {
                this.drugDetailsArray[i].price = salesprice.toFixed(2)
              }

              this.drugDetailsArray[i].amount = saleslineamount.toFixed(2);
              this.drugDetailsArray[i].discountstaff = this.discamount;
              cgstcal = parseFloat((itemaftdisamt * (discgst / 100)).toFixed(2));
              sgstcal = parseFloat((itemaftdisamt * (dissgst / 100)).toFixed(2));
              this.CGSTamount += parseFloat((cgstcal).toFixed(2));
              this.SGSTamount += parseFloat((sgstcal).toFixed(2));
              this.gstdecimalamtinsure += parseFloat((cgstcal + sgstcal).toFixed(2));
              this.GSTamountinsure = this.spliceDecimal(this.gstdecimalamtinsure);
              if (this.GSTamountinsure == 0) {
                this.GSTamountflag = true
              } else {
                this.GSTamountflag = false
              }
              calTotalamountinsure += itemaftdisamt + cgstcal + sgstcal
              this.drugDetailsArray[i].calamt = calTotalamountinsure
              this.totalAmount = parseFloat(this.totalAmount) + parseFloat(saleslineamount.toFixed(2));
              this.totalAmount = this.totalAmount.toFixed(2);
              this.totalAmountinsure = parseFloat(this.totalAmountinsure) + parseFloat(saleslineamount.toFixed(2));
              this.totalAmountinsure = this.totalAmountinsure.toFixed(2); // Convert totalAmount to a string with 2 decimal places
              this.prodDiscountinsure = this.discamountinsure.toFixed(2);
              if (this.prodDiscountinsure == 0.00) {
                this.discoutFlag = true
              } else {
                this.discoutFlag = false
              }
              if (this.duesponser != "") {
                this.duesponser = Math.round(parseFloat(this.duesponser) + calTotalamountinsure).toFixed(2)
              } else {
                this.duesponser = Math.round(calTotalamountinsure).toFixed(2)
              }
              if (this.amountDiscount != "") {
                this.amountDiscount = Math.round(parseFloat(this.amountDiscount) + calTotalamountinsure).toFixed(2)
              } else {
                this.amountDiscount = Math.round(calTotalamountinsure).toFixed(2)
              }
              // if (this.paidText != "") {
              //   this.remaingBalance = Math.round(parseFloat(this.duesponser) - parseFloat(this.paidText)).toFixed(2);
              // } else {
              //   this.remaingBalance = Math.round(parseFloat(this.amountDiscount) - 0).toFixed(2);
              // }

              // if (this.returnbilltotalAmount != 0) {
              //   this.remaingBalance = (parseFloat(this.remaingBalance) - this.returnbilltotalAmount).toFixed(2)
              // }
            }

            this.prodDiscount = (parseFloat(this.prodDiscount) + parseFloat(this.prodDiscountinsure)).toFixed(2);
            this.wholegstdecimal = this.GSTamount + this.GSTamountinsure
            this.GSTamount = this.spliceDecimal(this.wholegstdecimal)
            amountDiscountinsure = parseFloat(this.duesponser) + parseFloat(this.amountDiscountinsure)
            // this.amountDiscount = Math.round(parseFloat(this.amountDiscount) + amountDiscountinsure).toFixed(2)
            // Math.round(parseFloat(this.duesponser) + this.amountDiscountinsure);
          }

        }
      }
    } else if (this.discountdesc == "MRP") {

      this.discamount = 0;
      this.discamountinsure = 0;
      this.remaingBalance = "0.00";
      this.GSTamountflag = true;
      this.totalAmount = 0;
      this.totalAmountinsure = 0;
      this.prodDiscount = 0;
      this.prodDiscountinsure = 0;
      this.GSTamount = 0;
      this.GSTamountinsure = 0;
      this.amountDiscount = "0.00";
      this.amountDiscountinsure = 0.00;
      this.duesponser = 0.00;
      if (this.insuranceflag == true) {
        for (var i = 0; i < this.drugDetailsArray.length; i++) {
          this.aftdiscamt = 0;
          var salesprice = 0;
          var costprice = 0;
          var discgst = 0;
          var dissgst = 0;
          var cgstcal = 0;
          var sgstcal = 0;
          var calTotalamount = 0;
          var saleslineamount = 0;
          var costpricesubstute;
          var salsepricesubstute;
          var mrpprice = 0;
          var mrpcallineamount: any = 0;
          var mrpdisamt;
          console.log("DISCOUNT VALUE mr = " + JSON.stringify(this.drugDetailsArray[i]))
          if (this.drugDetailsArray[i].drug_id != "") {
            if (this.drugDetailsArray[i].cost_price != undefined && this.drugDetailsArray[i].cost_price != null && this.drugDetailsArray[i].cost_price != "") {
              costprice = parseInt(this.drugDetailsArray[i].cost_price) / parseInt(this.drugDetailsArray[i].per_unit);
              costpricesubstute = costprice - this.discountvalue_amount
              salesprice = parseInt(this.drugDetailsArray[i].sales_price) / parseInt(this.drugDetailsArray[i].per_unit);
              salsepricesubstute = salsepricesubstute - this.discountvalue_amount
            } else {
              costprice = 0;
              salesprice = 0;
            }
            if (this.drugDetailsArray[i].cgst != undefined && this.drugDetailsArray[i].cgst != null && this.drugDetailsArray[i].cgst != "") {
              discgst = parseFloat(this.drugDetailsArray[i].cgst)
            } else {
              discgst = 0
            }
            if (this.drugDetailsArray[i].sgst != undefined && this.drugDetailsArray[i].sgst != null && this.drugDetailsArray[i].sgst != "") {
              dissgst = parseFloat(this.drugDetailsArray[i].sgst)
            } else {
              dissgst = 0
            }
            if (this.priceedit == "0") {
              this.drugDetailsArray[i].price = this.drugDetailsArray[i].mrp
            }
            if (this.priceedit == "0") {
              mrpprice = this.drugDetailsArray[i].mrp
              this.drugDetailsArray[i].amount = (parseFloat(this.drugDetailsArray[i].mrp) * this.drugDetailsArray[i].quantity).toFixed(2);
            } else {
              mrpprice = this.drugDetailsArray[i].price
              this.drugDetailsArray[i].amount = (parseFloat(this.drugDetailsArray[i].price) * this.drugDetailsArray[i].quantity).toFixed(2);
            }



            mrpcallineamount = mrpprice * ((this.drugDetailsArray[i].quantity));
            if (this.rateplanetype == "Product wise") {
              if (this.drugDetailsArray[i].discount != undefined && this.drugDetailsArray[i].discount != null && this.drugDetailsArray[i].discount != "") {
                console.log("lineitemdiscount" + this.drugDetailsArray[i].discount)
              }
              else {
                this.drugDetailsArray[i].discount = 0
              }
              mrpdisamt = parseFloat(mrpcallineamount) * (parseFloat(this.drugDetailsArray[i].discount) / 100)
              this.discamount += (mrpcallineamount * (parseFloat(this.drugDetailsArray[i].discount) / 100))
            } else {
              mrpdisamt = parseFloat(mrpcallineamount) * (parseFloat(this.discountvalue_amount) / 100)
              this.discamount += (mrpcallineamount * (parseFloat(this.discountvalue_amount) / 100))
            }
            this.aftdiscamt += parseFloat(mrpcallineamount) - parseFloat(mrpdisamt);
            this.drugDetailsArray[i].discountstaff = this.discamount;
            calTotalamount += this.aftdiscamt
            this.drugDetailsArray[i].calamt = calTotalamount
            this.totalAmount = parseFloat(this.totalAmount) + parseFloat(mrpcallineamount.toFixed(2));
            this.totalAmount = this.totalAmount.toFixed(2); // Convert totalAmount to a string with 2 decimal places
            this.prodDiscount = this.discamount.toFixed(2);
            console.log("mRP PRICE IN DISCONT =  = " + JSON.stringify(this.drugDetailsArray[i].price))
            console.log("aMOUNT =  = " + JSON.stringify(this.drugDetailsArray[i].amount))
            console.log("mrpcallineamount =  = " + mrpcallineamount)
            console.log("mrpdisamt = " + mrpdisamt + " mrpcallineamount = " + mrpcallineamount + " discountvalue =  " + (parseFloat(this.discountvalue_amount) / 100))
            console.log("discamount  = " + this.discamount + " mrpcallineamount = " + mrpcallineamount + " discountvalue =  " + (parseFloat(this.discountvalue_amount) / 100))
            console.log("After discount   = " + this.aftdiscamt + " mrpcallineamount = " + mrpcallineamount + " mrpdisamt =  " + mrpdisamt)
            console.log("After discount   = " + this.aftdiscamt + " calTotalamount = " + calTotalamount)
            console.log("this.totalAmount    = " + this.totalAmount + " mrpcallineamount = " + parseFloat(mrpcallineamount.toFixed(2)))
            if (this.prodDiscount == 0.00) {
              this.discoutFlag = true

            } else {
              this.discoutFlag = false
            }
            if (this.amountDiscount != "") {
              this.amountDiscount = (parseFloat(this.amountDiscount) + calTotalamount).toFixed(2)
            } else {
              this.amountDiscount = Math.round(calTotalamount).toFixed(2)
            }


            if (this.paidText != "") {
              this.remaingBalance = Math.round(parseFloat(this.amountDiscount) - parseFloat(this.paidText)).toFixed(2);
            } else {
              this.remaingBalance = Math.round(parseFloat(this.amountDiscount) - 0).toFixed(2);
            }

            if (this.returnbilltotalAmount != 0) {
              this.remaingBalance = (parseFloat(this.remaingBalance) - this.returnbilltotalAmount).toFixed(2)
            }

          }
        }
        this.amountDiscount = Math.round(this.amountDiscount)
      } else {
        console.log("AMOUNT CALCULATION CALLED")
        this.amountDiscountinsure = 0;
        this.amountDiscount = 0;

        for (var i = 0; i < this.drugDetailsArray.length; i++) {
          this.aftdiscamt = 0;
          this.aftdiscamtinsure = 0
          var salesprice = 0;
          var costprice = 0;
          var discgst = 0;
          var dissgst = 0;
          var cgstcal = 0;
          var sgstcal = 0;
          var calTotalamount = 0;
          var calTotalamountinsure = 0.00;
          var saleslineamount = 0;
          var costpricesubstute;
          var salsepricesubstute;
          var mrpprice = 0;
          var mrpcallineamount: any = 0;
          var mrpdisamt;
          console.log("DISCOUNT VALUE ELSE " + JSON.stringify(this.drugDetailsArray[i]))
          if (this.drugDetailsArray[i].drug_id != "") {
            if (this.drugDetailsArray[i].ins_allow == "0") {
              if (this.drugDetailsArray[i].is_added == true) {
                this.amountDiscountinsure -= this.drugDetailsArray[i].amount;
                this.amountDiscount -= this.drugDetailsArray[i].amount;
              }
              this.drugDetailsArray[i].is_added = false;



              if (this.drugDetailsArray[i].cost_price != undefined && this.drugDetailsArray[i].cost_price != null && this.drugDetailsArray[i].cost_price != "") {
                costprice = parseInt(this.drugDetailsArray[i].cost_price) / parseInt(this.drugDetailsArray[i].per_unit);
                costpricesubstute = costprice - this.discountvalue_amount
                salesprice = parseInt(this.drugDetailsArray[i].sales_price) / parseInt(this.drugDetailsArray[i].per_unit);
                salsepricesubstute = salsepricesubstute - this.discountvalue_amount;

              } else {
                costprice = 0;
                salesprice = 0;
              }

              if (this.drugDetailsArray[i].cgst != undefined && this.drugDetailsArray[i].cgst != null && this.drugDetailsArray[i].cgst != "") {
                discgst = parseFloat(this.drugDetailsArray[i].cgst)
              } else {
                discgst = 0
              }

              if (this.drugDetailsArray[i].sgst != undefined && this.drugDetailsArray[i].sgst != null && this.drugDetailsArray[i].sgst != "") {
                dissgst = parseFloat(this.drugDetailsArray[i].sgst)
              } else {
                dissgst = 0
              }

              if (this.priceedit == "0") {
                this.drugDetailsArray[i].price = this.drugDetailsArray[i].mrp
              }

              if (this.priceedit == "0") {
                mrpprice = this.drugDetailsArray[i].mrp
                this.drugDetailsArray[i].amount = (parseFloat(this.drugDetailsArray[i].mrp) * this.drugDetailsArray[i].quantity).toFixed(2);

              } else {
                mrpprice = this.drugDetailsArray[i].price
                this.drugDetailsArray[i].amount = (parseFloat(this.drugDetailsArray[i].price) * this.drugDetailsArray[i].quantity).toFixed(2);
              }

              mrpcallineamount = mrpprice * ((this.drugDetailsArray[i].quantity));


              if (this.rateplanetype == "Product wise") {
                if (this.drugDetailsArray[i].discount != undefined && this.drugDetailsArray[i].discount != null && this.drugDetailsArray[i].discount != "") {
                } else {
                  this.drugDetailsArray[i].discount = 0
                }
                mrpdisamt = mrpcallineamount * (parseFloat(this.drugDetailsArray[i].discount) / 100)
                this.discamount += (mrpcallineamount * (parseFloat(this.drugDetailsArray[i].discount) / 100))

              } else {
                mrpdisamt = mrpcallineamount * (parseFloat(this.discountvalue_amount) / 100)
                this.discamount += (mrpcallineamount * (parseFloat(this.discountvalue_amount) / 100))
              }

              this.aftdiscamt += (mrpcallineamount - mrpdisamt);

              this.drugDetailsArray[i].discountstaff = this.discamount;
              calTotalamount += this.aftdiscamt

              this.drugDetailsArray[i].calamt = calTotalamount
              this.totalAmount = parseFloat(this.totalAmount) + parseFloat(mrpcallineamount.toFixed(2));
              this.totalAmount = this.totalAmount.toFixed(2); // Convert totalAmount to a string with 2 decimal places
              this.prodDiscount = this.discamount.toFixed(2);
              // console.log("mRP PRICE IN DISCONT =  = " + JSON.stringify(this.drugDetailsArray[i].price))
              // console.log("aMOUNT =  = " + JSON.stringify(this.drugDetailsArray[i].amount))
              // console.log("mrpcallineamount =  = " + mrpcallineamount)
              // console.log("mrpdisamt = " + mrpdisamt + " mrpcallineamount = " + mrpcallineamount + " discountvalue =  " + (parseFloat(this.discountvalue_amount) / 100))
              // console.log("discamount  = " + this.discamount + " mrpcallineamount = " + mrpcallineamount + " discountvalue =  " + (parseFloat(this.discountvalue_amount) / 100))
              // console.log("After discount   = " + this.aftdiscamt + " mrpcallineamount = " + mrpcallineamount + " mrpdisamt =  " + mrpdisamt)
              // console.log("After discount   = " + this.aftdiscamt + " calTotalamount = " + calTotalamount)
              // console.log("this.totalAmount    = " + this.totalAmount + " mrpcallineamount = " + parseFloat(mrpcallineamount.toFixed(2)))
              if (this.prodDiscount == 0.00) {
                this.discoutFlag = true

              } else {
                this.discoutFlag = false
              }
              console.log("AMOUNT VALUE ================= " + parseFloat(this.amountDiscountinsure) + " ===== " + calTotalamount)
              if (this.amountDiscountinsure != "") {
                this.amountDiscountinsure = Math.round(this.amountDiscountinsure + calTotalamount).toFixed(2)
              } else {
                this.amountDiscountinsure = Math.round(calTotalamount).toFixed(2)
              }

              if (this.amountDiscount != "") {
                this.amountDiscount = Math.round(parseFloat(this.amountDiscount) + calTotalamount).toFixed(2);
              } else {
                this.amountDiscount = Math.round(calTotalamount).toFixed(2)
              }

              if (this.paidText != "") {

                this.remaingBalance = Math.round(parseFloat(this.amountDiscountinsure) - parseFloat(this.paidText)).toFixed(2);
              } else {
                this.remaingBalance = Math.round(parseFloat(this.amountDiscountinsure) - 0).toFixed(2);
              }

              // if (this.returnbilltotalAmount != 0) {
              //   this.remaingBalance = (parseFloat(this.remaingBalance) - this.returnbilltotalAmount).toFixed(2)
              // }
            } else {
              this.drugDetailsArray[i].is_added = true;
              if (this.drugDetailsArray[i].cost_price != undefined && this.drugDetailsArray[i].cost_price != null && this.drugDetailsArray[i].cost_price != "") {
                costprice = parseInt(this.drugDetailsArray[i].cost_price) / parseInt(this.drugDetailsArray[i].per_unit);
                costpricesubstute = costprice - this.discountvalue_amount
                salesprice = parseInt(this.drugDetailsArray[i].sales_price) / parseInt(this.drugDetailsArray[i].per_unit);
                salsepricesubstute = salsepricesubstute - this.discountvalue_amount
              } else {
                costprice = 0;
                salesprice = 0;
              }
              if (this.drugDetailsArray[i].cgst != undefined && this.drugDetailsArray[i].cgst != null && this.drugDetailsArray[i].cgst != "") {
                discgst = parseFloat(this.drugDetailsArray[i].cgst)
              } else {
                discgst = 0
              }
              if (this.drugDetailsArray[i].sgst != undefined && this.drugDetailsArray[i].sgst != null && this.drugDetailsArray[i].sgst != "") {
                dissgst = parseFloat(this.drugDetailsArray[i].sgst)
              } else {
                dissgst = 0
              }
              if (this.priceedit == "0") {
                this.drugDetailsArray[i].price = this.drugDetailsArray[i].mrp
              }
              if (this.priceedit == "0") {
                mrpprice = this.drugDetailsArray[i].mrp
                this.drugDetailsArray[i].amount = (parseFloat(this.drugDetailsArray[i].mrp) * this.drugDetailsArray[i].quantity).toFixed(2);

              } else {
                mrpprice = this.drugDetailsArray[i].price
                this.drugDetailsArray[i].amount = (parseFloat(this.drugDetailsArray[i].price) * this.drugDetailsArray[i].quantity).toFixed(2);
              }
              mrpcallineamount = mrpprice * ((this.drugDetailsArray[i].quantity));
              if (this.rateplanetype == "Product wise") {
                if (this.drugDetailsArray[i].discount != undefined && this.drugDetailsArray[i].discount != null && this.drugDetailsArray[i].discount != "") {
                  console.log("lineitemdiscount" + this.drugDetailsArray[i].discount)
                }
                else {
                  this.drugDetailsArray[i].discount = 0
                }

                mrpdisamt = mrpcallineamount * (parseFloat(this.drugDetailsArray[i].discount) / 100)
                this.discamountinsure += (mrpcallineamount * (parseFloat(this.drugDetailsArray[i].discount) / 100))
              } else {
                mrpdisamt = mrpcallineamount * (parseFloat(this.discountvalue_amount) / 100)
                this.discamountinsure += (mrpcallineamount * (parseFloat(this.discountvalue_amount) / 100))
              }
              this.aftdiscamtinsure += (mrpcallineamount - mrpdisamt);
              this.drugDetailsArray[i].discountstaff = this.discamountinsure;
              calTotalamountinsure += this.aftdiscamtinsure
              this.drugDetailsArray[i].calamt = calTotalamount
              this.totalAmount = parseFloat(this.totalAmount) + parseFloat(mrpcallineamount.toFixed(2));
              this.totalAmount = this.totalAmount.toFixed(2); // Convert totalAmount to a string with 2 decimal places
              this.totalAmountinsure = parseFloat(this.totalAmountinsure) + parseFloat(mrpcallineamount.toFixed(2));
              this.totalAmountinsure = this.totalAmountinsure.toFixed(2); // Convert totalAmount to a string with 2 decimal places
              this.prodDiscountinsure = this.discamountinsure.toFixed(2);

              console.log("mRP PRICE IN DISCONT =  = " + JSON.stringify(this.drugDetailsArray[i].price))
              console.log("aMOUNT =  = " + JSON.stringify(this.drugDetailsArray[i].amount))
              console.log("mrpcallineamount =  = " + mrpcallineamount)
              console.log("mrpdisamt = " + mrpdisamt + " mrpcallineamount = " + mrpcallineamount + " discountvalue =  " + (parseFloat(this.discountvalue_amount) / 100))
              console.log("discamount  = " + this.discamount + " mrpcallineamount = " + mrpcallineamount + " discountvalue =  " + (parseFloat(this.discountvalue_amount) / 100))
              console.log("After discount   = " + this.aftdiscamt + " mrpcallineamount = " + mrpcallineamount + " mrpdisamt =  " + mrpdisamt)
              console.log("After discount   = " + this.aftdiscamt + " calTotalamount = " + calTotalamount)
              console.log("this.totalAmount    = " + this.totalAmount + " mrpcallineamount = " + parseFloat(mrpcallineamount.toFixed(2)))

              if (this.prodDiscountinsure == 0.00) {
                this.discoutFlag = true
              } else {
                this.discoutFlag = false
              }
              if (this.duesponser != "") {
                this.duesponser = Math.round(parseFloat(this.duesponser) + calTotalamountinsure).toFixed(2)
              } else {
                this.duesponser = Math.round(calTotalamountinsure).toFixed(2)
              }
              if (this.amountDiscount != "") {
                this.amountDiscount = Math.round(parseFloat(this.amountDiscount) + calTotalamountinsure).toFixed(2)
              } else {
                this.amountDiscount = Math.round(calTotalamountinsure).toFixed(2)
              }
              // if (this.paidText != "") {
              //   this.remaingBalance = Math.round(parseFloat(this.amountDiscount) - parseFloat(this.paidText)).toFixed(2);
              // } else {
              //   this.remaingBalance = Math.round(parseFloat(this.amountDiscount) - 0).toFixed(2);
              // }

              // if (this.returnbilltotalAmount != 0) {
              //   this.remaingBalance = (parseFloat(this.remaingBalance) - this.returnbilltotalAmount).toFixed(2)
              // }
            }
            this.prodDiscount = (parseFloat(this.prodDiscount) + parseFloat(this.prodDiscountinsure)).toFixed(2);
          }
        }
      }
    }
    // if (this.returnbilltotalAmount >= 1) {
    //   this.discountcalculatestaffreturn()
    // }
  }

  discountcalculatestaffreturn() {
    if (this.discountdesc == "cost plus gst") {
      this.discamountreturn = 0;
      this.totalAmountreturn = 0;
      this.returnbilltotalAmount = 0.00;
      this.aftdiscamtreturn = 0.00;

      for (var i = 0; i < this.returnsDetailsArray.length; i++) {
        this.aftdiscamtreturn = 0;
        var salesprice = 0;
        var costprice = 0;
        var discgst = 0;
        var dissgst = 0;
        var cgstcal = 0;
        var sgstcal = 0;
        var calTotalamount = 0;
        var costpricesubstute;
        var salsepricesubstute;
        var costlineamount = 0;

        console.log("DISCOUNT VALUE return = " + JSON.stringify(this.returnsDetailsArray[i]))
        if (this.returnsDetailsArray[i].drug_id != "") {
          if (this.returnsDetailsArray[i].cost_price != undefined && this.returnsDetailsArray[i].cost_price != null && this.returnsDetailsArray[i].cost_price != "") {
            //costprice=
            costprice = parseFloat(this.returnsDetailsArray[i].price)
            costlineamount = costprice * this.returnsDetailsArray[i].quantity;
            costpricesubstute = parseFloat(this.discountvalue_amount) / 100;
            console.log("costpricesubstute = " + costpricesubstute + "costlinamount = " + costlineamount + "-" + parseFloat(this.discountvalue_amount) / 100)
            var disamt = costlineamount * costpricesubstute;
            console.log("disamt = " + disamt + "costlinamount = " + costlineamount + "*" + parseFloat(this.discountvalue_amount) / 100)
            this.discamountreturn += disamt;

            console.log("discamount = " + costlineamount + "*" + parseFloat(this.discountvalue_amount) / 100)
            var itemaftdisamt = costlineamount - disamt;
            this.aftdiscamtreturn += (costlineamount - disamt);
            console.log("discamount = " + this.aftdiscamtreturn + "* " + costlineamount + "-" + disamt)
          }
          else {
            costpricesubstute = 0;
            salsepricesubstute = 0;
          }
          if (this.returnsDetailsArray[i].cgst != undefined && this.returnsDetailsArray[i].cgst != null && this.returnsDetailsArray[i].cgst != "") {
            discgst = parseFloat(this.returnsDetailsArray[i].cgst)
          } else {
            discgst = 0
          }
          if (this.returnsDetailsArray[i].sgst != undefined && this.returnsDetailsArray[i].sgst != null && this.returnsDetailsArray[i].sgst != "") {
            dissgst = parseFloat(this.returnsDetailsArray[i].sgst)
          } else {
            dissgst = 0
          }
          console.log("return cost price" + this.returnsDetailsArray[i].cost_price + " " + parseInt(this.returnsDetailsArray[i].per_unit) + " " + costprice)
          this.returnsDetailsArray[i].price = costprice.toFixed(2);
          this.returnsDetailsArray[i].amount = costlineamount.toFixed(2);

          this.returnsDetailsArray[i].discountstaff = this.discamountreturn;
          // if (this.discamountreturn != 0) {
          cgstcal = parseFloat((itemaftdisamt * discgst / 100).toFixed(2))
          sgstcal = parseFloat((itemaftdisamt * dissgst / 100).toFixed(2))
          // }
          console.log("return cost price cgst sgst" + cgstcal + " " + sgstcal + " " + this.aftdiscamtreturn)
          calTotalamount += itemaftdisamt + cgstcal + sgstcal;
          console.log("return caltotamount" + calTotalamount)
          this.returnsDetailsArray[i].calamt = calTotalamount;

          if (this.returnbilltotalAmount !== 0.00) {
            const returnbilltotalAmount = this.returnbilltotalAmount + parseFloat(calTotalamount.toFixed(2));
            this.returnbilltotalAmount = Math.round(parseFloat(returnbilltotalAmount.toFixed(2)));
          } else {

            this.returnbilltotalAmount = Math.round(parseFloat(calTotalamount.toFixed(2)));
          }

          console.log("return cost price cgst sgst" + this.returnbilltotalAmount + " " + calTotalamount)
        }
      }
    } else if (this.discountdesc == "sales plus gst") {

      //salesplucgst----	Own staff

      this.discamountreturn = 0;
      this.totalAmountreturn = 0;
      this.returnbilltotalAmount = 0.00;
      this.aftdiscamtreturn = 0.00;
      for (var i = 0; i < this.returnsDetailsArray.length; i++) {
        this.aftdiscamt = 0;
        var salesprice = 0;
        var costprice = 0;
        var discgst = 0;
        var dissgst = 0;
        var cgstcal = 0;
        var sgstcal = 0;
        var calTotalamount = 0;
        var saleslineamount = 0;
        var costpricesubstute;
        var salsepricesubstute;

        if (this.returnsDetailsArray[i].drug_id != "") {
          if (this.returnsDetailsArray[i].sales_price != undefined && this.returnsDetailsArray[i].sales_price != null && this.returnsDetailsArray[i].sales_price != "") {
            costprice = parseInt(this.returnsDetailsArray[i].cost_price) / parseInt(this.returnsDetailsArray[i].per_unit);

            var costlineamount = costprice * this.returnsDetailsArray[i].quantity;
            costpricesubstute = costlineamount - (parseFloat(this.discountvalue_amount) / 100);
            //
            salesprice = parseFloat(this.returnsDetailsArray[i].price)

            saleslineamount = salesprice * this.returnsDetailsArray[i].quantity;

            salsepricesubstute = parseFloat(this.discountvalue_amount) / 100;
            //
            var disamt = saleslineamount * salsepricesubstute;
            var itemaftdisamt = saleslineamount - disamt;
            this.discamountreturn += disamt
            this.aftdiscamtreturn += itemaftdisamt;

          } else {
            costprice = 0;
            salesprice = 0;
          }
          if (this.returnsDetailsArray[i].cgst != undefined && this.returnsDetailsArray[i].cgst != null && this.returnsDetailsArray[i].cgst != "") {

            discgst = parseFloat(this.returnsDetailsArray[i].cgst)
          } else {
            discgst = 0
          }
          if (this.returnsDetailsArray[i].sgst != undefined && this.returnsDetailsArray[i].sgst != null && this.returnsDetailsArray[i].sgst != "") {
            dissgst = parseFloat(this.returnsDetailsArray[i].sgst)
          } else {
            dissgst = 0
          }
          this.returnsDetailsArray[i].price = salesprice.toFixed(2)
          this.returnsDetailsArray[i].amount = saleslineamount.toFixed(2);

          this.returnsDetailsArray[i].discountstaff = this.discamountreturn;
          // if (this.discamountreturn != 0) {
          cgstcal = itemaftdisamt * (discgst / 100)
          sgstcal = itemaftdisamt * (dissgst / 100)
          // }
          calTotalamount += itemaftdisamt + cgstcal + sgstcal
          this.returnsDetailsArray[i].calamt = calTotalamount

          if (this.returnbilltotalAmount !== 0.00) {
            const returnbilltotalAmount = this.returnbilltotalAmount + parseFloat(calTotalamount.toFixed(2));
            this.returnbilltotalAmount = Math.round(parseFloat(returnbilltotalAmount.toFixed(2)));
          } else {
            this.returnbilltotalAmount = Math.round(parseFloat(calTotalamount.toFixed(2)));
          }
        }
      }
    } else if (this.discountdesc == "MRP") {

      this.discamountreturn = 0;
      this.totalAmountreturn = 0;
      this.returnbilltotalAmount = 0.00;
      this.aftdiscamtreturn = 0.00;
      for (var i = 0; i < this.returnsDetailsArray.length; i++) {
        this.aftdiscamt = 0;
        var salesprice = 0;
        var costprice = 0;
        var discgst = 0;
        var dissgst = 0;
        var cgstcal = 0;
        var sgstcal = 0;
        var calTotalamount = 0;
        var saleslineamount = 0;
        var costpricesubstute;
        var salsepricesubstute;
        var mrpprice = 0;
        var mrpcallineamount = 0;
        var mrpdisamt;
        console.log("DISCOUNT VALUE mr = " + JSON.stringify(this.drugDetailsArray[i]))
        if (this.returnsDetailsArray[i].drug_id != "") {
          if (this.returnsDetailsArray[i].cost_price != undefined && this.returnsDetailsArray[i].cost_price != null && this.returnsDetailsArray[i].cost_price != "") {
            costprice = parseInt(this.returnsDetailsArray[i].cost_price) / parseInt(this.returnsDetailsArray[i].per_unit);
            costpricesubstute = costprice - this.discountvalue_amount
            salesprice = parseInt(this.returnsDetailsArray[i].sales_price) / parseInt(this.returnsDetailsArray[i].per_unit);
            salsepricesubstute = salsepricesubstute - this.discountvalue_amount
          } else {
            costprice = 0;
            salesprice = 0;
          }
          if (this.returnsDetailsArray[i].cgst != undefined && this.returnsDetailsArray[i].cgst != null && this.returnsDetailsArray[i].cgst != "") {

            discgst = parseFloat(this.returnsDetailsArray[i].cgst)
          } else {
            discgst = 0
          }
          if (this.returnsDetailsArray[i].sgst != undefined && this.returnsDetailsArray[i].sgst != null && this.returnsDetailsArray[i].sgst != "") {

            dissgst = parseFloat(this.returnsDetailsArray[i].sgst)
          } else {
            dissgst = 0
          }

          // this.returnsDetailsArray[i].price = this.returnsDetailsArray[i].mrp
          mrpprice = this.returnsDetailsArray[i].price
          this.returnsDetailsArray[i].amount = (parseFloat(this.returnsDetailsArray[i].price) * this.returnsDetailsArray[i].quantity).toFixed(2);
          mrpcallineamount = mrpprice * this.returnsDetailsArray[i].quantity;

          if (this.rateplanetype == "Product wise") {

            if (this.returnsDetailsArray[i].discount != undefined && this.returnsDetailsArray[i].discount != null && this.returnsDetailsArray[i].discount != "") {
              console.log("lineitemdiscount" + this.returnsDetailsArray[i].discount)
            }
            else {
              this.returnsDetailsArray[i].discount = 0
            }

            mrpdisamt = mrpcallineamount * (parseFloat(this.returnsDetailsArray[i].discount) / 100)
            this.discamountreturn += (mrpcallineamount * (parseFloat(this.returnsDetailsArray[i].discount) / 100))
          } else {
            mrpdisamt = mrpcallineamount * (parseFloat(this.discountvalue_amount) / 100)
            this.discamountreturn += (mrpcallineamount * (parseFloat(this.discountvalue_amount) / 100))
          }



          this.aftdiscamtreturn += (mrpcallineamount - mrpdisamt);
          this.returnsDetailsArray[i].discountstaff = this.discamountreturn;
          calTotalamount += this.aftdiscamtreturn
          this.totalAmountreturn = Math.round(parseFloat(this.totalAmountreturn) + mrpcallineamount).toFixed(2)

          if (this.returnbilltotalAmount !== 0.00) {
            const returnbilltotalAmount = parseFloat(calTotalamount.toFixed(2));
            this.returnbilltotalAmount = Math.round(parseFloat(returnbilltotalAmount.toFixed(2)));
          } else {
            this.returnbilltotalAmount = Math.round(parseFloat(calTotalamount.toFixed(2)));
          }
          console.log(JSON.stringify(this.returnsDetailsArray[i].quantity) + " " + JSON.stringify(this.returnsDetailsArray[i].mrp) + " " + JSON.stringify((parseFloat(this.returnsDetailsArray[i].mrp) * this.returnsDetailsArray[i].quantity).toFixed(2)))
          console.log("aftdiscamt" + this.aftdiscamt)
          console.log("mrp disamount = " + mrpdisamt)
        }
      }
    }

    if (this.amountDiscount != "0.00") {
      this.remaingBalance = (parseFloat(this.amountDiscount) - this.returnbilltotalAmount).toFixed(2)
    }
  }

  spliceDecimal(number) {
    // Convert the number to a string
    let numberStr = number.toString();
    // Split the number into integer and decimal parts
    let parts = numberStr.split('.');
    // Check if there's a decimal part
    if (parts.length > 1) {
      // Take the first two digits of the decimal part
      let newDecimal = parts[1].slice(0, 2);
      // Combine the integer part with the truncated decimal part
      var newNumberStr = parts[0] + '.' + newDecimal;
      // Convert back to a number
      return parseFloat(newNumberStr);
    } else {
      // If there's no decimal part, return the original number
      return number;
    }
    // 
  }

  changeOrderDate(e) {
    this.orderDate = e;
  }

  getAppointments() {
    this.billingCreateArray = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'inpat/gpat',
      JSON.stringify({
        doc_reg_id: this.docRegID,
        hptl_clinic_id: this.hospitalID,
        country: ipaddress.country_code.toString(),
        imei: FrontDesk_Helper.getIPAddress(),
        type: "pharma",
        inpat_type: "pharma",
        usertype: this.usertype,
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          console.log("appointment inpat", JSON.stringify(obj))
          if (obj.patients != null) {
            for (var i = 0; i < obj.patients.length; i++) {
              var patientName;
              var salutation;
              var age;
              var gender;
              var insurer;
              var mobileno;
              var admission_time;
              var admissiondate2;
              if (obj.patients[i].middle_name != undefined) {
                this.docIDWithName = obj.patients[i].doc_app_id + " - " + encrypt_decript.Decript(obj.patients[i].first_name) + " " + encrypt_decript.Decript(obj.patients[i].middle_name) + " " + encrypt_decript.Decript(obj.patients[i].last_name);
                patientName = encrypt_decript.Decript(obj.patients[i].first_name) + " " + encrypt_decript.Decript(obj.patients[i].middle_name) + " " + encrypt_decript.Decript(obj.patients[i].last_name);
              } else {
                if (obj.patients[i].last_name != undefined) {
                  this.docIDWithName = obj.patients[i].doc_app_id + " - " + encrypt_decript.Decript(obj.patients[i].first_name) + " " + encrypt_decript.Decript(obj.patients[i].last_name);
                  patientName = encrypt_decript.Decript(obj.patients[i].first_name) + " " + encrypt_decript.Decript(obj.patients[i].last_name);
                } else {
                  this.docIDWithName = obj.patients[i].doc_app_id + " - " + encrypt_decript.Decript(obj.patients[i].first_name);
                  patientName = encrypt_decript.Decript(obj.patients[i].first_name);
                }
              }

              var sessiondata = null;
              if (obj.patients[i].session != undefined && obj.patients[i].session != undefined && obj.patients[i].session != null) {
                sessiondata = encrypt_decript.Decript(obj.patients[i].session);
              }

              if (obj.patients[i].salutation_desc != undefined) {
                salutation = obj.patients[i].salutation_desc
              } else {
                salutation = ""
              }

              if (obj.patients[i].age != undefined) {
                age = obj.patients[i].age
              } else {
                age = ""
              }

              if (obj.patients[i].gender != undefined) {
                gender = obj.patients[i].gender
              } else {
                gender = ""
              }

              if (obj.patients[i].insurer != undefined) {
                insurer = obj.patients[i].insurer
                // alert(JSON.stringify(obj.patients[i].insurer)+" "+ this.insuranceflag_paid)
                // this.insuranceflag_paid=true;
              } else {
                insurer = ""
                // this.insuranceflag_paid=false;
              }

              if (obj.patients[i].mobile != undefined) {

                mobileno = encrypt_decript.Decript(obj.patients[i].mobile)
              } else {
                mobileno = ""
              }


              if (obj.patients[i].admission_time != undefined) {
                admission_time = Time_Formate(obj.patients[i].admission_time)
              } else {
                admission_time = "";
              }

              if (obj.patients[i].admission_date != undefined) {
                var admission_date = obj.patients[i].admission_date != undefined ? obj.patients[i].admission_date.split('-') : "";
                admissiondate2 = admission_date[2] + "-" + admission_date[1] + "-" + admission_date[0];
              } else {
                admissiondate2 = "";
              }

              if (obj.patients[i].barcode != undefined) {
                var barcode = ipaddress.Ip_with_img_address + obj.patients[i].barcode
              } else {
                barcode = ""
              }


              console.log("admission-date 2" + admissiondate2)

              this.billingCreateArray.push({
                sal: salutation,
                doc_reg_id: obj.patients[i].doc_reg_id,
                doc_app_id: obj.patients[i].doc_app_id,
                client_reg_id: obj.patients[i].client_reg_id,
                relation_id: obj.patients[i].relation_id,
                sub_rel_id: obj.patients[i].sub_rel_id,
                first_name: obj.patients[i].first_name,
                middle_name: obj.patients[i].middle_name,
                last_name: obj.patients[i].last_name,
                hptl_clinic_id: this.hospitalID,
                session: sessiondata,
                ward_name: obj.patients[i].ward_name,
                bed_no: obj.patients[i].bed_no,
                docid_name: this.docIDWithName,
                patientName: patientName,
                patient_id: obj.patients[i].patient_id,
                age: obj.patients[i].age,
                admission_time: admission_time,
                admission_date: admissiondate2,
                insurer: insurer,
                mobile: mobileno,
                gender: encrypt_decript.Decript(obj.patients[i].gender),
                barcode: barcode
              });
            }
          }
        });
  }

  patientdetails() {
  }

  changeAppointmnetID() {
    for (var i = 0; i < this.billingCreateArray.length; i++) {
      console.log("bill create" + JSON.stringify(this.billingCreateArray[i]))
      if (this.billingCreateArray[i].doc_app_id == this.appointText) {
        this.clntAge = this.billingCreateArray[i].age;
        this.clntgender = this.billingCreateArray[i].gender;
        this.ClientID = this.billingCreateArray[i].client_reg_id;
        this.relationID = this.billingCreateArray[i].relation_id;
        this.subRelationID = this.billingCreateArray[i].sub_rel_id;
        this.appID = this.billingCreateArray[i].doc_app_id;
        this.clientName = this.billingCreateArray[i].patientName;
        this.Ward_name = this.billingCreateArray[i].ward_name;
        this.Bed_no = this.billingCreateArray[i].bed_no;
        this.docRegID = this.billingCreateArray[i].doc_reg_id;
        this.mrNumb = this.billingCreateArray[i].patient_id;
        this.clntSal = this.billingCreateArray[i].sal;
        this.insurername = this.billingCreateArray[i].insurer;
        this.clntContNumber = this.billingCreateArray[i].mobile;
        this.admissiondate = this.billingCreateArray[i].admission_date;
        this.admission_time = this.billingCreateArray[i].admission_time;
        this.barcode_logo = this.billingCreateArray[i].barcode;
        const wardContainsICUOrTheater = /ICU|Theater/i.test(this.billingCreateArray[i].ward_name);
        this.wardflag = wardContainsICUOrTheater;
        if (this.billingCreateArray[i].insurer != "") {
          this.insuranceflag_paid = true;

        } else {
          this.insuranceflag_paid = false;
          //            if (this.getData.hospitals[0].bill_pay_type_req == "1") {
          //   this.paytypreq = true;
          // } else {
          //   this.paytypreq = false; 
          // }
        }
        break;
      }
    }
  }

  Duration() {
    var duration = this.dayText + " " + this.dayDuration;
    return duration
  }

  //Get intake session
  getIntakeSession() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'prescription/dintypes/',
      { headers: headers })
      .subscribe(
        response => {

          var obj = response.json();
          console.log("GET SESSION S" + JSON.stringify(obj));
          this.getIntakeArray = obj.drug_intake;
          this.intake = "Thrice a day";
        },
        error => {
        }
      )
  }


  sessionqty(drug) {
    console.log(" Session drug  1 = " + JSON.stringify(drug))
    var medqty;
    var durationinnum;
    var totalqty;
    var morning; var afternoon; var evening; var night;
    var durationflag: boolean = true;
    console.log(" =---- " + JSON.stringify(drug.day_txt))
    if (((drug.qty_per_unit != 0 || drug.no_of_IU != 0) || drug.default_quantity != "1")) {
      if (drug.drug_typetxt == "14" || drug.drug_typetxt == "26" || drug.drug_typetxt == "44") {

        // drug.drug_typetxt == "5" || 
        medqty = 1
        totalqty = 1
      }
      else if (drug.intake_txt == "Morning only" && drug.day_txt != undefined && drug.day_txt != "") {
        if (drug.morning == "1/2") {
          morning = parseFloat("0.5")
        }
        else if (drug.morning == "1/4") {
          morning = parseFloat("0.25")
        }
        else if (drug.morning == "1/3") {
          morning = parseFloat("0.33")
        } else if (drug.morning == "0") {
          morning = parseInt("0")
        }
        else if (drug.morning == "1") {
          morning = 1
        } else {
          morning = parseInt(drug.morning)
        }

        if (drug.afternoon == "1/2") {
          afternoon = parseFloat("0.5")
        }
        else if (drug.afternoon == "1/4") {
          afternoon = parseFloat("0.25")
        }
        else if (drug.afternoon == "1/3") {
          afternoon = parseFloat("0.33")
        } else if (drug.afternoon == "0") {
          afternoon = parseInt("0")
        } else if (drug.afternoon == "1") {
          afternoon = 1
        }
        else {
          afternoon = parseFloat(drug.afternoon)
        }

        if (drug.evening == "1/2") {
          evening = parseFloat("0.5")
        }
        else if (drug.evening == "1/4") {
          evening = parseFloat("0.25")
        }
        else if (drug.evening == "1/3") {
          evening = parseFloat("0.33")
        } else if (drug.evening == "0") {
          evening = parseInt("0")
        }
        else {
          evening = 1
        }

        if (drug.night == "1/2") {
          night = parseFloat("0.5")
        }
        else if (drug.night == "1/4") {
          night = parseFloat("0.25")
        }
        else if (drug.night == "1/3") {
          night = parseFloat("0.33")
        } else if (drug.night == "0") {
          night = parseInt("0")
        } else if (drug.night == "1") {
          night = 1
        }
        else {
          night = parseFloat(drug.night)
        }


        medqty = morning + afternoon + evening + night;

        if (drug.daydur_txt == "day(s)") {
          durationinnum = drug.day_txt
        } else if (drug.daydur_txt == "week(s)") {
          durationinnum = drug.day_txt * 7
        } else {
          durationinnum = drug.day_txt * 30
        }

        totalqty = parseInt(durationinnum) * medqty
        console.log("drug_text " + drug.day_txt)
        console.log("morning = " + drug.morning);
        console.log("afternoon " + drug.afternoon)
        console.log("" + drug.evening)
        console.log("night = " + drug.night)
        console.log("duration minimum = " + durationinnum);
        console.log("med qty = " + medqty)
        console.log("totalqty" + totalqty)
      }

      else if (drug.intake_txt == "Afternoon only" && drug.day_txt != undefined && drug.day_txt != "") {
        if (drug.morning == "1/2") {
          morning = parseFloat("0.5")
        }
        else if (drug.morning == "1/4") {
          morning = parseFloat("0.25")
        }
        else if (drug.morning == "1/3") {
          morning = parseFloat("0.33")
        } else if (drug.morning == "0") {
          morning = parseInt("0")
        } else if (drug.morning == "1") {
          morning = 1
        } else {
          morning = parseFloat(drug.morning)
        }

        if (drug.afternoon == "1/2") {
          afternoon = parseFloat("0.5")
        }
        else if (drug.afternoon == "1/4") {
          afternoon = parseFloat("0.25")
        }
        else if (drug.afternoon == "1/3") {
          afternoon = parseFloat("0.33")
        } else if (drug.afternoon == "0") {
          afternoon = parseInt("0")
        } else if (drug.afternoon == "1") {
          afternoon = 1
        }
        else {
          afternoon = parseFloat(drug.afternoon)
        }

        if (drug.evening == "1/2") {
          evening = parseFloat("0.5")
        }
        else if (drug.evening == "1/4") {
          evening = parseFloat("0.25")
        }
        else if (drug.evening == "1/3") {
          evening = parseFloat("0.33")
        } else if (drug.evening == "0") {
          evening = parseInt("0")
        } else if (evening == "1") {
          evening = 1
        }
        else {
          evening = parseFloat(drug.evening)
        }

        if (drug.night == "1/2") {
          night = parseFloat("0.5")
        }
        else if (drug.night == "1/4") {
          night = parseFloat("0.25")
        }
        else if (drug.night == "1/3") {
          night = parseFloat("0.33")
        } else if (drug.night == "0") {
          night = parseInt("0")
        }
        else if (drug.night == "1") {
          night = 1
        }
        else {
          night = parseFloat(drug.night)
        }
        medqty = morning + afternoon + evening + night;

        if (drug.daydur_txt == "day(s)") {
          durationinnum = drug.day_txt
        } else if (drug.daydur_txt == "week(s)") {
          durationinnum = drug.day_txt * 7
        } else {
          durationinnum = drug.day_txt * 30
        }

        totalqty = parseInt(durationinnum) * medqty
        console.log("drug_text " + drug.day_txt)
        console.log("morning = " + drug.morning);
        console.log("afternoon " + drug.afternoon)
        console.log("" + drug.evening)
        console.log("night = " + drug.night)
        console.log("duration minimum = " + durationinnum);
        console.log("med qty = " + medqty)
        console.log("totalqty" + totalqty)



      }

      else if (drug.intake_txt == "Evening only" && drug.day_txt != undefined && drug.day_txt != "") {


        if (drug.morning == "1/2") {
          morning = parseFloat("0.5")
        }
        else if (drug.morning == "1/4") {
          morning = parseFloat("0.25")
        }
        else if (drug.morning == "1/3") {
          morning = parseFloat("0.33")
        } else if (drug.morning == "0") {
          morning = parseInt("0")
        } else if (drug.morning == "1") {
          morning = 1
        } else {
          morning = parseFloat(drug.morning)
        }

        if (drug.afternoon == "1/2") {
          afternoon = parseFloat("0.5")
        }
        else if (drug.afternoon == "1/4") {
          afternoon = parseFloat("0.25")
        }
        else if (drug.afternoon == "1/3") {
          afternoon = parseFloat("0.33")
        } else if (drug.afternoon == "0") {
          afternoon = parseInt("0")
        } else if (drug.afternoon == "1") {
          afternoon = 1
        }
        else {
          afternoon = parseFloat(drug.afternoon)
        }

        if (drug.evening == "1/2") {
          evening = parseFloat("0.5")
        }
        else if (drug.evening == "1/4") {
          evening = parseFloat("0.25")
        }
        else if (drug.evening == "1/3") {
          evening = parseFloat("0.33")
        } else if (drug.evening == "0") {
          evening = parseInt("0")
        } else if (evening == "1") {
          evening = 1
        }
        else {
          evening = parseFloat(drug.evening)
        }

        if (drug.night == "1/2") {
          night = parseFloat("0.5")
        }
        else if (drug.night == "1/4") {
          night = parseFloat("0.25")
        }
        else if (drug.night == "1/3") {
          night = parseFloat("0.33")
        } else if (drug.night == "0") {
          night = parseInt("0")
        } else if (drug.night == "1") {
          night = 1
        }
        else {
          night = parseFloat(drug.night)
        }
        medqty = morning + afternoon + evening + night;
        console.log("morning = " + drug.morning);
        console.log("afternoon " + drug.afternoon)
        console.log("" + drug.evening)
        console.log("night = " + drug.night)
        if (drug.daydur_txt == "day(s)") {
          durationinnum = drug.day_txt
        } else if (drug.daydur_txt == "week(s)") {
          durationinnum = drug.day_txt * 7
        } else {
          durationinnum = drug.day_txt * 30
        }
        console.log("drug_text " + drug.day_txt)
        totalqty = parseInt(durationinnum) * medqty
        console.log("duration minimum = " + durationinnum);
        console.log("med qty = " + medqty)
        console.log("totalqty" + totalqty)

      }

      else if (drug.intake_txt == "Night only" && drug.day_txt != undefined && drug.day_txt != "") {

        if (drug.morning == "1/2") {
          morning = parseFloat("0.5")
        }
        else if (drug.morning == "1/4") {
          morning = parseFloat("0.25")
        }
        else if (drug.morning == "1/3") {
          morning = parseFloat("0.33")
        } else if (drug.morning == "0") {
          morning = parseInt("0")
        } else if (drug.morning == "1") {
          morning = 1
        } else {
          morning = parseFloat(drug.morning)
        }

        if (drug.afternoon == "1/2") {
          afternoon = parseFloat("0.5")
        }
        else if (drug.afternoon == "1/4") {
          afternoon = parseFloat("0.25")
        }
        else if (drug.afternoon == "1/3") {
          afternoon = parseFloat("0.33")
        } else if (drug.afternoon == "0") {
          afternoon = parseInt("0")
        } else if (drug.afternoon == "1") {
          afternoon = 1
        }
        else {
          afternoon = parseFloat(drug.afternoon)
        }

        if (drug.evening == "1/2") {
          evening = parseFloat("0.5")
        }
        else if (drug.evening == "1/4") {
          evening = parseFloat("0.25")
        }
        else if (drug.evening == "1/3") {
          evening = parseFloat("0.33")
        } else if (drug.evening == "0") {
          evening = parseInt("0")
        } else if (evening == "1") {
          evening = 1
        }
        else {
          evening = parseFloat(drug.evening)
        }

        if (drug.night == "1/2") {
          night = parseFloat("0.5")
        }
        else if (drug.night == "1/4") {
          night = parseFloat("0.25")
        }
        else if (drug.night == "1/3") {
          night = parseFloat("0.33")
        } else if (drug.night == "0") {
          night = parseInt("0")
        } else if (drug.night == "1") {
          night = 1
        }
        else {
          night = parseFloat(drug.night)
        }
        medqty = morning + afternoon + evening + night;

        if (drug.daydur_txt == "day(s)") {
          durationinnum = drug.day_txt
        } else if (drug.daydur_txt == "week(s)") {
          durationinnum = drug.day_txt * 7
        } else {
          durationinnum = drug.day_txt * 30
        }

        totalqty = parseInt(durationinnum) * medqty
        console.log("drug_text " + drug.day_txt)
        console.log("morning = " + drug.morning);
        console.log("afternoon " + drug.afternoon)
        console.log("" + drug.evening)
        console.log("night = " + drug.night)
        console.log("duration minimum = " + durationinnum);
        console.log("med qty = " + medqty)
        console.log("totalqty" + totalqty)



      } else if (drug.intake_txt == "Morning & Afternoon" && drug.day_txt != undefined && drug.day_txt != "") {


        if (drug.morning == "1/2") {
          morning = parseFloat("0.5")
        }
        else if (drug.morning == "1/4") {
          morning = parseFloat("0.25")
        }
        else if (drug.morning == "1/3") {
          morning = parseFloat("0.33")
        } else if (drug.morning == "0") {
          morning = parseInt("0")
        } else if (drug.morning == "1") {
          morning = 1
        } else {
          morning = parseFloat(drug.morning)
        }

        if (drug.afternoon == "1/2") {
          afternoon = parseFloat("0.5")
        }
        else if (drug.afternoon == "1/4") {
          afternoon = parseFloat("0.25")
        }
        else if (drug.afternoon == "1/3") {
          afternoon = parseFloat("0.33")
        } else if (drug.afternoon == "0") {
          afternoon = parseInt("0")
        } else if (drug.afternoon == "1") {
          afternoon = 1
        }
        else {
          afternoon = parseFloat(drug.afternoon)
        }

        if (drug.evening == "1/2") {
          evening = parseFloat("0.5")
        }
        else if (drug.evening == "1/4") {
          evening = parseFloat("0.25")
        }
        else if (drug.evening == "1/3") {
          evening = parseFloat("0.33")
        } else if (drug.evening == "0") {
          evening = parseInt("0")
        } else if (evening == "1") {
          evening = 1
        }
        else {
          evening = parseFloat(drug.evening)
        }

        if (drug.night == "1/2") {
          night = parseFloat("0.5")
        }
        else if (drug.night == "1/4") {
          night = parseFloat("0.25")
        }
        else if (drug.night == "1/3") {
          night = parseFloat("0.33")
        } else if (drug.night == "0") {
          night = parseInt("0")
        } else if (drug.night == "1") {
          night = 1
        }
        else {
          night = parseFloat(drug.night)
        }
        medqty = morning + afternoon + evening + night;
        console.log("morning = " + drug.morning);
        console.log("afternoon " + drug.afternoon)
        console.log("" + drug.evening)
        console.log("night = " + drug.night)
        if (drug.daydur_txt == "day(s)") {
          durationinnum = drug.day_txt
        } else if (drug.daydur_txt == "week(s)") {
          durationinnum = drug.day_txt * 7
        } else {
          durationinnum = drug.day_txt * 30
        }
        console.log("drug_text " + drug.day_txt)
        totalqty = parseInt(durationinnum) * medqty
        console.log("duration minimum = " + durationinnum);
        console.log("med qty = " + medqty)
        console.log("totalqty" + totalqty)

      }

      else if (drug.intake_txt == "Morning & Evening" && drug.day_txt != undefined && drug.day_txt != "") {

        if (drug.morning == "1/2") {
          morning = parseFloat("0.5")
        }
        else if (drug.morning == "1/4") {
          morning = parseFloat("0.25")
        }
        else if (drug.morning == "1/3") {
          morning = parseFloat("0.33")
        } else if (drug.morning == "0") {
          morning = parseInt("0")
        } else if (drug.morning == "1") {
          morning = 1
        } else {
          morning = parseFloat(drug.morning)
        }

        if (drug.afternoon == "1/2") {
          afternoon = parseFloat("0.5")
        }
        else if (drug.afternoon == "1/4") {
          afternoon = parseFloat("0.25")
        }
        else if (drug.afternoon == "1/3") {
          afternoon = parseFloat("0.33")
        } else if (drug.afternoon == "0") {
          afternoon = parseInt("0")
        } else if (drug.afternoon == "1") {
          afternoon = 1
        }
        else {
          afternoon = parseFloat(drug.afternoon)
        }

        if (drug.evening == "1/2") {
          evening = parseFloat("0.5")
        }
        else if (drug.evening == "1/4") {
          evening = parseFloat("0.25")
        }
        else if (drug.evening == "1/3") {
          evening = parseFloat("0.33")
        } else if (drug.evening == "0") {
          evening = parseInt("0")
        } else if (evening == "1") {
          evening = 1
        }
        else {
          evening = parseFloat(drug.evening)
        }

        if (drug.night == "1/2") {
          night = parseFloat("0.5")
        }
        else if (drug.night == "1/4") {
          night = parseFloat("0.25")
        }
        else if (drug.night == "1/3") {
          night = parseFloat("0.33")
        } else if (drug.night == "0") {
          night = parseInt("0")
        } else if (drug.night == "1") {
          night = 1
        }
        else {
          night = parseFloat(drug.night)
        }
        medqty = morning + afternoon + evening + night;
        console.log("morning = " + drug.morning);
        console.log("afternoon " + drug.afternoon)
        console.log("" + drug.evening)
        console.log("night = " + drug.night)
        if (drug.daydur_txt == "day(s)") {
          durationinnum = drug.day_txt
        } else if (drug.daydur_txt == "week(s)") {
          durationinnum = drug.day_txt * 7
        } else {
          durationinnum = drug.day_txt * 30
        }
        console.log("drug_text " + drug.day_txt)
        totalqty = parseInt(durationinnum) * medqty
        console.log("duration minimum = " + durationinnum);
        console.log("med qty = " + medqty)
        console.log("totalqty" + totalqty)


      }

      else if (drug.intake_txt == "Morning & Night" && drug.day_txt != undefined && drug.day_txt != "") {
        console.log("FORM drug value = " + JSON.stringify(drug))
        if (drug.morning == "1/2") {
          morning = parseFloat("0.5")
        }
        else if (drug.morning == "1/4") {
          morning = parseFloat("0.25")
        }
        else if (drug.morning == "1/3") {
          morning = parseFloat("0.33")
        } else if (drug.morning == "0") {
          morning = parseInt("0")
        } else if (drug.morning == "1") {
          morning = 1
        } else {
          morning = parseFloat(drug.morning)
        }

        if (drug.afternoon == "1/2") {
          afternoon = parseFloat("0.5")
        }
        else if (drug.afternoon == "1/4") {
          afternoon = parseFloat("0.25")
        }
        else if (drug.afternoon == "1/3") {
          afternoon = parseFloat("0.33")
        } else if (drug.afternoon == "0") {
          afternoon = parseInt("0")
        } else if (drug.afternoon == "1") {
          afternoon = 1
        }
        else {
          afternoon = parseFloat(drug.afternoon)
        }

        if (drug.evening == "1/2") {
          evening = parseFloat("0.5")
        }
        else if (drug.evening == "1/4") {
          evening = parseFloat("0.25")
        }
        else if (drug.evening == "1/3") {
          evening = parseFloat("0.33")
        } else if (drug.evening == "0") {
          evening = parseInt("0")
        } else if (evening == "1") {
          evening = 1
        }
        else {

          evening = parseFloat(drug.evening)

        }

        if (drug.night == "1/2") {
          night = parseFloat("0.5")
        }
        else if (drug.night == "1/4") {
          night = parseFloat("0.25")
        }
        else if (drug.night == "1/3") {
          night = parseFloat("0.33")
        } else if (drug.night == "0") {
          night = parseInt("0")
        } else if (drug.night == "1") {
          night = 1
        }
        else {
          night = parseFloat(drug.night)
        }
        medqty = morning + afternoon + evening + night;
        console.log("morning = " + drug.morning);
        console.log("afternoon " + drug.afternoon)
        console.log("" + drug.evening)
        console.log("night = " + drug.night)
        if (drug.daydur_txt == "day(s)") {
          durationinnum = drug.day_txt
        } else if (drug.daydur_txt == "week(s)") {
          durationinnum = drug.day_txt * 7
        } else {
          durationinnum = drug.day_txt * 30
        }
        console.log("drug_text " + drug.day_txt)
        totalqty = parseInt(durationinnum) * medqty
        console.log("duration minimum = " + durationinnum);
        console.log("med qty = " + medqty)
        console.log("totalqty" + totalqty)

      }

      else if (drug.intake_txt == "Afternoon & Night" && drug.day_txt != undefined && drug.day_txt != "") {

        if (drug.morning == "1/2") {
          morning = parseFloat("0.5")
        }
        else if (drug.morning == "1/4") {
          morning = parseFloat("0.25")
        }
        else if (drug.morning == "1/3") {
          morning = parseFloat("0.33")
        } else if (drug.morning == "0") {
          morning = parseInt("0")
        } else if (drug.morning == "1") {
          morning = 1
        } else {
          morning = parseFloat(drug.morning)
        }

        if (drug.afternoon == "1/2") {
          afternoon = parseFloat("0.5")
        }
        else if (drug.afternoon == "1/4") {
          afternoon = parseFloat("0.25")
        }
        else if (drug.afternoon == "1/3") {
          afternoon = parseFloat("0.33")
        } else if (drug.afternoon == "0") {
          afternoon = parseInt("0")
        } else if (drug.afternoon == "1") {
          afternoon = 1
        }
        else {
          afternoon = parseFloat(drug.afternoon)
        }

        if (drug.evening == "1/2") {
          evening = parseFloat("0.5")
        }
        else if (drug.evening == "1/4") {
          evening = parseFloat("0.25")
        }
        else if (drug.evening == "1/3") {
          evening = parseFloat("0.33")
        } else if (drug.evening == "0") {
          evening = parseInt("0")
        } else if (evening == "1") {
          evening = 1
        }
        else {
          evening = parseFloat(drug.evening)
        }

        if (drug.night == "1/2") {
          night = parseFloat("0.5")
        }
        else if (drug.night == "1/4") {
          night = parseFloat("0.25")
        }
        else if (drug.night == "1/3") {
          night = parseFloat("0.33")
        } else if (drug.night == "0") {
          night = parseInt("0")
        } else if (drug.night == "1") {
          night = 1
        }
        else {
          night = parseFloat(drug.night)
        }
        medqty = morning + afternoon + evening + night;
        console.log("morning = " + drug.morning);
        console.log("afternoon " + drug.afternoon)
        console.log("" + drug.evening)
        console.log("night = " + drug.night)
        if (drug.daydur_txt == "day(s)") {
          durationinnum = drug.day_txt
        } else if (drug.daydur_txt == "week(s)") {
          durationinnum = drug.day_txt * 7
        } else {
          durationinnum = drug.day_txt * 30
        }
        console.log("drug_text " + drug.day_txt)
        totalqty = parseInt(durationinnum) * medqty
        console.log("duration minimum = " + durationinnum);
        console.log("med qty = " + medqty)
        console.log("totalqty" + totalqty)

      }

      else if (drug.intake_txt == "Afternoon & Evening" && drug.day_txt != undefined && drug.day_txt != "") {

        if (drug.morning == "1/2") {
          morning = parseFloat("0.5")
        }
        else if (drug.morning == "1/4") {
          morning = parseFloat("0.25")
        }
        else if (drug.morning == "1/3") {
          morning = parseFloat("0.33")
        } else if (drug.morning == "0") {
          morning = parseInt("0")
        } else if (drug.morning == "1") {
          morning = 1
        } else {
          morning = parseFloat(drug.morning)
        }

        if (drug.afternoon == "1/2") {
          afternoon = parseFloat("0.5")
        }
        else if (drug.afternoon == "1/4") {
          afternoon = parseFloat("0.25")
        }
        else if (drug.afternoon == "1/3") {
          afternoon = parseFloat("0.33")
        } else if (drug.afternoon == "0") {
          afternoon = parseInt("0")
        } else if (drug.afternoon == "1") {
          afternoon = 1
        }
        else {
          afternoon = parseFloat(drug.afternoon)
        }

        if (drug.evening == "1/2") {
          evening = parseFloat("0.5")
        }
        else if (drug.evening == "1/4") {
          evening = parseFloat("0.25")
        }
        else if (drug.evening == "1/3") {
          evening = parseFloat("0.33")
        } else if (drug.evening == "0") {
          evening = parseInt("0")
        } else if (evening == "1") {
          evening = 1
        }
        else {
          evening = parseFloat(drug.evening)
        }

        if (drug.night == "1/2") {
          night = parseFloat("0.5")
        }
        else if (drug.night == "1/4") {
          night = parseFloat("0.25")
        }
        else if (drug.night == "1/3") {
          night = parseFloat("0.33")
        } else if (drug.night == "0") {
          night = parseInt("0")
        } else if (drug.night == "1") {
          night = 1
        }
        else {
          night = parseFloat(drug.night)
        }
        medqty = morning + afternoon + evening + night;
        console.log("morning = " + drug.morning);
        console.log("afternoon " + drug.afternoon)
        console.log("" + drug.evening)
        console.log("night = " + drug.night)
        if (drug.daydur_txt == "day(s)") {
          durationinnum = drug.day_txt
        } else if (drug.daydur_txt == "week(s)") {
          durationinnum = drug.day_txt * 7
        } else {
          durationinnum = drug.day_txt * 30
        }
        console.log("drug_text " + drug.day_txt)
        totalqty = parseInt(durationinnum) * medqty
        console.log("duration minimum = " + durationinnum);
        console.log("med qty = " + medqty)
        console.log("totalqty" + totalqty)
      }

      else if (drug.intake_txt == "Morning,Afternoon & Evening" && drug.day_txt != undefined && drug.day_txt != "") {
        if (drug.morning == "1/2") {
          morning = parseFloat("0.5")
        }
        else if (drug.morning == "1/4") {
          morning = parseFloat("0.25")
        }
        else if (drug.morning == "1/3") {
          morning = parseFloat("0.33")
        } else if (drug.morning == "0") {
          morning = parseInt("0")
        } else if (drug.morning == "1") {
          morning = 1
        } else {
          morning = parseFloat(drug.morning)
        }

        if (drug.afternoon == "1/2") {
          afternoon = parseFloat("0.5")
        }
        else if (drug.afternoon == "1/4") {
          afternoon = parseFloat("0.25")
        }
        else if (drug.afternoon == "1/3") {
          afternoon = parseFloat("0.33")
        } else if (drug.afternoon == "0") {
          afternoon = parseInt("0")
        } else if (drug.afternoon == "1") {
          afternoon = 1
        }
        else {
          afternoon = parseFloat(drug.afternoon)
        }

        if (drug.evening == "1/2") {
          evening = parseFloat("0.5")
        }
        else if (drug.evening == "1/4") {
          evening = parseFloat("0.25")
        }
        else if (drug.evening == "1/3") {
          evening = parseFloat("0.33")
        } else if (drug.evening == "0") {
          evening = parseInt("0")
        } else if (evening == "1") {
          evening = 1
        }
        else {
          evening = parseFloat(drug.evening)
        }

        if (drug.night == "1/2") {
          night = parseFloat("0.5")
        }
        else if (drug.night == "1/4") {
          night = parseFloat("0.25")
        }
        else if (drug.night == "1/3") {
          night = parseFloat("0.33")
        } else if (drug.night == "0") {
          night = parseInt("0")
        } else if (drug.night == "1") {
          night = 1
        }
        else {
          night = parseFloat(drug.night)
        }
        medqty = morning + afternoon + evening + night;
        console.log("morning = " + drug.morning);
        console.log("afternoon " + drug.afternoon)
        console.log("" + drug.evening)
        console.log("night = " + drug.night)
        if (drug.daydur_txt == "day(s)") {
          durationinnum = drug.day_txt
        } else if (drug.daydur_txt == "week(s)") {
          durationinnum = drug.day_txt * 7
        } else {
          durationinnum = drug.day_txt * 30
        }
        console.log("drug_text " + drug.day_txt)
        totalqty = parseInt(durationinnum) * medqty
        console.log("duration minimum = " + durationinnum);
        console.log("med qty = " + medqty)
        console.log("totalqty" + totalqty)

      }

      else if (drug.intake_txt == "Thrice a day" && drug.day_txt != undefined && drug.day_txt != "") {



        if (drug.morning == "1/2") {
          morning = parseFloat("0.5")
        }
        else if (drug.morning == "1/4") {
          morning = parseFloat("0.25")
        }
        else if (drug.morning == "1/3") {
          morning = parseFloat("0.33")
        } else if (drug.morning == "0") {
          morning = parseInt("0")
        } else if (drug.morning == "1") {
          morning = 1
        } else {
          morning = parseFloat(drug.morning)
        }

        if (drug.afternoon == "1/2") {
          afternoon = parseFloat("0.5")
        }
        else if (drug.afternoon == "1/4") {
          afternoon = parseFloat("0.25")
        }
        else if (drug.afternoon == "1/3") {
          afternoon = parseFloat("0.33")
        } else if (drug.afternoon == "0") {
          afternoon = parseInt("0")
        } else if (drug.afternoon == "1") {
          afternoon = 1
        }
        else {
          afternoon = parseFloat(drug.afternoon)
        }

        if (drug.evening == "1/2") {
          evening = parseFloat("0.5")
        }
        else if (drug.evening == "1/4") {
          evening = parseFloat("0.25")
        }
        else if (drug.evening == "1/3") {
          evening = parseFloat("0.33")
        } else if (drug.evening == "0") {
          evening = parseInt("0")
        } else if (evening == "1") {
          evening = 1
        }
        else {
          evening = parseFloat(drug.evening)
        }
        if (drug.night == "1/2") {
          night = parseFloat("0.5")
        }
        else if (drug.night == "1/4") {
          night = parseFloat("0.25")
        }
        else if (drug.night == "1/3") {
          night = parseFloat("0.33")
        } else if (drug.night == "0") {
          night = parseInt("0")
        } else if (drug.night == "1") {
          night = 1
        }
        else {
          night = parseFloat(drug.night)
        }
        medqty = morning + afternoon + evening + night;
        console.log("morning = " + drug.morning);
        console.log("afternoon " + drug.afternoon)
        console.log("" + drug.evening)
        console.log("night = " + drug.night)
        if (drug.daydur_txt == "day(s)") {
          durationinnum = drug.day_txt
        } else if (drug.daydur_txt == "week(s)") {
          durationinnum = drug.day_txt * 7
        } else {
          durationinnum = drug.day_txt * 30
        }
        console.log("drug_text " + drug.day_txt)
        totalqty = parseInt(durationinnum) * medqty
        console.log("duration minimum = " + durationinnum);
        console.log("med qty = " + medqty)
        console.log("totalqty" + totalqty)


      }

      else if (drug.intake_txt == "Morning,Afternoon & Night" && drug.day_txt != undefined && drug.day_txt != "") {
        if (drug.morning == "1/2") {
          morning = parseFloat("0.5")
        }
        else if (drug.morning == "1/4") {
          morning = parseFloat("0.25")
        }
        else if (drug.morning == "1/3") {
          morning = parseFloat("0.33")
        } else if (drug.morning == "0") {
          morning = parseInt("0")
        } else if (drug.morning == "1") {
          morning = 1
        } else {
          morning = parseFloat(drug.morning)
        }

        if (drug.afternoon == "1/2") {
          afternoon = parseFloat("0.5")
        }
        else if (drug.afternoon == "1/4") {
          afternoon = parseFloat("0.25")
        }
        else if (drug.afternoon == "1/3") {
          afternoon = parseFloat("0.33")
        } else if (drug.afternoon == "0") {
          afternoon = parseInt("0")
        } else if (drug.afternoon == "1") {
          afternoon = 1
        }
        else {
          afternoon = parseFloat(drug.afternoon)
        }

        if (drug.evening == "1/2") {
          evening = parseFloat("0.5")
        }
        else if (drug.evening == "1/4") {
          evening = parseFloat("0.25")
        }
        else if (drug.evening == "1/3") {
          evening = parseFloat("0.33")
        } else if (drug.evening == "0") {
          evening = parseInt("0")
        } else if (evening == "1") {
          evening = 1
        }
        else {
          evening = parseFloat(drug.evening)
        }

        if (drug.night == "1/2") {
          night = parseFloat("0.5")
        }
        else if (drug.night == "1/4") {
          night = parseFloat("0.25")
        }
        else if (drug.night == "1/3") {
          night = parseFloat("0.33")
        } else if (drug.night == "0") {
          night = parseInt("0")
        } else if (drug.night == "1") {
          night = 1
        }
        else {
          night = parseFloat(drug.night)
        }
        medqty = morning + afternoon + evening + night;
        console.log("morning = " + drug.morning);
        console.log("afternoon " + drug.afternoon)
        console.log("" + drug.evening)
        console.log("night = " + drug.night)
        if (drug.daydur_txt == "day(s)") {
          durationinnum = drug.day_txt
        } else if (drug.daydur_txt == "week(s)") {
          durationinnum = drug.day_txt * 7
        } else {
          durationinnum = drug.day_txt * 30
        }
        console.log("drug_text " + drug.day_txt)
        totalqty = parseInt(durationinnum) * medqty
        console.log("duration minimum = " + durationinnum);
        console.log("med qty = " + medqty)
        console.log("totalqty" + totalqty)


      }

      else if (drug.intake_txt == "Morning,Afternoon,Evening & Night" && drug.day_txt != undefined && drug.day_txt != "") {

        if (drug.morning == "1/2") {
          morning = parseFloat("0.5")
        }
        else if (drug.morning == "1/4") {
          morning = parseFloat("0.25")
        }
        else if (drug.morning == "1/3") {
          morning = parseFloat("0.33")
        } else if (drug.morning == "0") {
          morning = parseInt("0")
        } else if (drug.morning == "1") {
          morning = 1
        } else {
          morning = parseFloat(drug.morning)
        }

        if (drug.afternoon == "1/2") {
          afternoon = parseFloat("0.5")
        }
        else if (drug.afternoon == "1/4") {
          afternoon = parseFloat("0.25")
        }
        else if (drug.afternoon == "1/3") {
          afternoon = parseFloat("0.33")
        } else if (drug.afternoon == "0") {
          afternoon = parseInt("0")
        } else if (drug.afternoon == "1") {
          afternoon = 1
        }
        else {
          afternoon = parseFloat(drug.afternoon)
        }

        if (drug.evening == "1/2") {
          evening = parseFloat("0.5")
        }
        else if (drug.evening == "1/4") {
          evening = parseFloat("0.25")
        }
        else if (drug.evening == "1/3") {
          evening = parseFloat("0.33")
        } else if (drug.evening == "0") {
          evening = parseInt("0")
        } else if (evening == "1") {
          evening = 1
        }
        else {
          evening = parseFloat(drug.evening)
        }

        if (drug.night == "1/2") {
          night = parseFloat("0.5")
        }
        else if (drug.night == "1/4") {
          night = parseFloat("0.25")
        }
        else if (drug.night == "1/3") {
          night = parseFloat("0.33")
        } else if (drug.night == "0") {
          night = parseInt("0")
        } else if (drug.night == "1") {
          night = 1
        }
        else {
          night = parseFloat(drug.night)
        }
        medqty = morning + afternoon + evening + night;
        console.log("morning = " + drug.morning);
        console.log("afternoon " + drug.afternoon)
        console.log("" + drug.evening)
        console.log("night = " + drug.night)
        if (drug.daydur_txt == "day(s)") {
          durationinnum = drug.day_txt
        } else if (drug.daydur_txt == "week(s)") {
          durationinnum = drug.day_txt * 7
        } else {
          durationinnum = drug.day_txt * 30
        }
        console.log("drug_text " + drug.day_txt)
        totalqty = parseInt(durationinnum) * medqty
        console.log("duration minimum = " + durationinnum);
        console.log("med qty = " + medqty)
        console.log("totalqty" + totalqty)
      }

      else if (drug.intake_txt == "Four times a day" && drug.day_txt != undefined && drug.day_txt != "") {
        if (drug.morning == "1/2") {
          morning = parseFloat("0.5")
        }
        else if (drug.morning == "1/4") {
          morning = parseFloat("0.25")
        }
        else if (drug.morning == "1/3") {
          morning = parseFloat("0.33")
        } else if (drug.morning == "0") {
          morning = parseInt("0")
        } else if (drug.morning == "1") {
          morning = 1
        } else {
          morning = parseFloat(drug.morning)
        }

        if (drug.afternoon == "1/2") {
          afternoon = parseFloat("0.5")
        }
        else if (drug.afternoon == "1/4") {
          afternoon = parseFloat("0.25")
        }
        else if (drug.afternoon == "1/3") {
          afternoon = parseFloat("0.33")
        } else if (drug.afternoon == "0") {
          afternoon = parseInt("0")
        } else if (drug.afternoon == "1") {
          afternoon = 1
        }
        else {
          afternoon = parseFloat(drug.afternoon)
        }

        if (drug.evening == "1/2") {
          evening = parseFloat("0.5")
        }
        else if (drug.evening == "1/4") {
          evening = parseFloat("0.25")
        }
        else if (drug.evening == "1/3") {
          evening = parseFloat("0.33")
        } else if (drug.evening == "0") {
          evening = parseInt("0")
        } else if (evening == "1") {
          evening = 1
        }
        else {
          evening = parseFloat(drug.evening)
        }

        if (drug.night == "1/2") {
          night = parseFloat("0.5")
        }
        else if (drug.night == "1/4") {
          night = parseFloat("0.25")
        }
        else if (drug.night == "1/3") {
          night = parseFloat("0.33")
        } else if (drug.night == "0") {
          night = parseInt("0")
        } else if (drug.night == "1") {
          night = 1
        }
        else {
          night = parseFloat(drug.night)
        }
        medqty = morning + afternoon + evening + night;
        console.log("morning = " + drug.morning);
        console.log("afternoon " + drug.afternoon)
        console.log("" + drug.evening)
        console.log("night = " + drug.night)
        if (drug.daydur_txt == "day(s)") {
          durationinnum = drug.day_txt
        } else if (drug.daydur_txt == "week(s)") {
          durationinnum = drug.day_txt * 7
        } else {
          durationinnum = drug.day_txt * 30
        }
        console.log("drug_text " + drug.day_txt)
        totalqty = parseInt(durationinnum) * medqty
        console.log("duration minimum = " + durationinnum);
        console.log("med qty = " + medqty)
        console.log("totalqty" + totalqty)
      }

      else if (drug.intake_txt == "Afternoon,Evening & Night" && drug.day_txt != undefined && drug.day_txt != "") {
        if (drug.morning == "1/2") {
          morning = parseFloat("0.5")
        }
        else if (drug.morning == "1/4") {
          morning = parseFloat("0.25")
        }
        else if (drug.morning == "1/3") {
          morning = parseFloat("0.33")
        } else if (drug.morning == "0") {
          morning = parseInt("0")
        } else if (drug.morning == "1") {
          morning = 1
        } else {
          morning = parseFloat(drug.morning)
        }
        if (drug.afternoon == "1/2") {
          afternoon = parseFloat("0.5")
        }
        else if (drug.afternoon == "1/4") {
          afternoon = parseFloat("0.25")
        }
        else if (drug.afternoon == "1/3") {
          afternoon = parseFloat("0.33")
        } else if (drug.afternoon == "0") {
          afternoon = parseInt("0")
        } else if (drug.afternoon == "1") {
          afternoon = 1
        }
        else {
          afternoon = parseFloat(drug.afternoon)
        }

        if (drug.evening == "1/2") {
          evening = parseFloat("0.5")
        }
        else if (drug.evening == "1/4") {
          evening = parseFloat("0.25")
        }
        else if (drug.evening == "1/3") {
          evening = parseFloat("0.33")
        } else if (drug.evening == "0") {
          evening = parseInt("0")
        } else if (evening == "1") {
          evening = 1
        }
        else {
          evening = parseFloat(drug.evening)
        }

        if (drug.night == "1/2") {
          night = parseFloat("0.5")
        }
        else if (drug.night == "1/4") {
          night = parseFloat("0.25")
        }
        else if (drug.night == "1/3") {
          night = parseFloat("0.33")
        } else if (drug.night == "0") {
          night = parseInt("0")
        } else if (drug.night == "1") {
          night = 1
        }
        else {
          night = parseFloat(drug.night)
        }

      }

      else if (drug.intake_txt == "Evening & Night" && drug.day_txt != undefined && drug.day_txt != "") {
        if (drug.morning == "1/2") {
          morning = parseFloat("0.5")
        }
        else if (drug.morning == "1/4") {
          morning = parseFloat("0.25")
        }
        else if (drug.morning == "1/3") {
          morning = parseFloat("0.33")
        } else if (drug.morning == "0") {
          morning = parseInt("0")
        } else if (drug.morning == "1") {
          morning = 1
        } else {
          morning = parseFloat(drug.morning)
        }

        if (drug.afternoon == "1/2") {
          afternoon = parseFloat("0.5")
        }
        else if (drug.afternoon == "1/4") {
          afternoon = parseFloat("0.25")
        }
        else if (drug.afternoon == "1/3") {
          afternoon = parseFloat("0.33")
        } else if (drug.afternoon == "0") {
          afternoon = parseInt("0")
        } else if (drug.afternoon == "1") {
          afternoon = 1
        }
        else {
          afternoon = parseFloat(drug.afternoon)
        }
        if (drug.evening == "1/2") {
          evening = parseFloat("0.5")
        }
        else if (drug.evening == "1/4") {
          evening = parseFloat("0.25")
        }
        else if (drug.evening == "1/3") {
          evening = parseFloat("0.33")
        } else if (drug.evening == "0") {
          evening = parseInt("0")
        } else if (evening == "1") {
          evening = 1
        }
        else {
          evening = parseFloat(drug.evening)
        }

        if (drug.night == "1/2") {
          night = parseFloat("0.5")
        }
        else if (drug.night == "1/4") {
          night = parseFloat("0.25")
        }
        else if (drug.night == "1/3") {
          night = parseFloat("0.33")
        } else if (drug.night == "0") {
          night = parseInt("0")
        } else if (drug.night == "1") {
          night = 1
        }
        else {
          night = parseFloat(drug.night)
        }
        medqty = morning + afternoon + evening + night;
        console.log("morning = " + drug.morning);
        console.log("afternoon " + drug.afternoon)
        console.log("" + drug.evening)
        console.log("night = " + drug.night)
        if (drug.daydur_txt == "day(s)") {
          durationinnum = drug.day_txt
        } else if (drug.daydur_txt == "week(s)") {
          durationinnum = drug.day_txt * 7
        } else {
          durationinnum = drug.day_txt * 30
        }
        console.log("drug_text " + drug.day_txt)
        totalqty = parseInt(durationinnum) * medqty
        console.log("duration minimum = " + durationinnum);
        console.log("med qty = " + medqty)
        console.log("totalqty" + totalqty)

      }

      else if ((drug.intake_txt == "Every 6 hours" || drug.intake_txt == "Every 8 hours" || drug.intake_txt == "Every 12 hours") && drug.day_txt != undefined && drug.day_txt != "") {

        if (drug.morning == "1/2") {
          morning = parseFloat("0.5")
        }
        else if (drug.morning == "1/4") {
          morning = parseFloat("0.25")
        }
        else if (drug.morning == "1/3") {
          morning = parseFloat("0.33")
        } else if (drug.morning == "0") {
          morning = parseInt("0")
        } else if (drug.morning == "1") {
          morning = 1
        } else {
          morning = parseFloat(drug.morning)
        }

        if (drug.afternoon == "1/2") {
          afternoon = parseFloat("0.5")
        }
        else if (drug.afternoon == "1/4") {
          afternoon = parseFloat("0.25")
        }
        else if (drug.afternoon == "1/3") {
          afternoon = parseFloat("0.33")
        } else if (drug.afternoon == "0") {
          afternoon = parseInt("0")
        } else if (drug.afternoon == "1") {
          afternoon = 1
        }
        else {
          afternoon = parseFloat(drug.afternoon)
        }

        if (drug.evening == "1/2") {
          evening = parseFloat("0.5")
        }
        else if (drug.evening == "1/4") {
          evening = parseFloat("0.25")
        }
        else if (drug.evening == "1/3") {
          evening = parseFloat("0.33")
        } else if (drug.evening == "0") {
          evening = parseInt("0")
        } else if (evening == "1") {
          evening = 1
        }
        else {
          evening = parseFloat(drug.evening)
        }

        if (drug.night == "1/2") {
          night = parseFloat("0.5")
        }
        else if (drug.night == "1/4") {
          night = parseFloat("0.25")
        }
        else if (drug.night == "1/3") {
          night = parseFloat("0.33")
        } else if (drug.night == "0") {
          night = parseInt("0")
        } else if (drug.night == "1") {
          night = 1
        }
        else {
          night = parseFloat(drug.night)
        }
        medqty = morning + afternoon + evening + night;
        console.log("morning = " + drug.morning);
        console.log("afternoon " + drug.afternoon)
        console.log("" + drug.evening)
        console.log("night = " + drug.night)


        if (drug.day_txt != undefined) {
          if (drug.daydur_txt == "day(s)") {
            durationinnum = drug.day_txt
          } else if (drug.daydur_txt == "week(s)") {
            durationinnum = drug.day_txt * 7
          } else {
            durationinnum = drug.day_txt * 30
          }
          totalqty = parseInt(durationinnum) * medqty
        } else {

          if (drug.daydur_txt == "day(s)") {
            durationinnum = medqty
          } else if (drug.daydur_txt == "week(s)") {
            durationinnum = medqty * 7
          } else {
            durationinnum = medqty * 30
          }
          totalqty = parseInt(durationinnum)
        }

        if (drug.intake_txt == "Every 6 hours") {
          // for (let v = 0; v < 24; v++) {
          //   if (v * 6 == 24) {
          //     medqty = v
          //   }
          // }
          medqty = medqty * 4;
        }
        else if (drug.intake_txt == "Every 8 hours") {
          // for (let v = 0; v < 24; v++) {
          //   if (v * 8 == 24) {
          //     medqty = v
          //   }
          // }
          medqty = medqty * 3;
        }
        else if (drug.intake_txt == "Every 12 hours") {
          // for (let v = 0; v < 24; v++) {
          //   if (v * 12 == 24) {
          //     medqty = v
          //   }
          // }
          medqty = medqty * 2;
        }


        if (durationinnum != undefined) {
          totalqty = parseInt(durationinnum) * medqty
        } else {
          totalqty = medqty
        }
        console.log("drug_text " + drug.day_txt)
        console.log("duration minimum = " + durationinnum);
        console.log("med qty = " + medqty)
        console.log("totalqty" + totalqty)
      }
      else if (drug.intake_txt == "Stat") {
        if (drug.morning == "1/2") {
          morning = parseFloat("0.5")
        }
        else if (drug.morning == "1/4") {
          morning = parseFloat("0.25")
        }
        else if (drug.morning == "1/3") {
          morning = parseFloat("0.33")
        } else if (drug.morning == "0") {
          morning = parseInt("0")
        } else if (drug.morning == "1") {
          morning = 1
        } else {
          morning = parseFloat(drug.morning)
        }

        if (drug.afternoon == "1/2") {
          afternoon = parseFloat("0.5")
        }
        else if (drug.afternoon == "1/4") {
          afternoon = parseFloat("0.25")
        }
        else if (drug.afternoon == "1/3") {
          afternoon = parseFloat("0.33")
        } else if (drug.afternoon == "0") {
          afternoon = parseInt("0")
        } else if (drug.afternoon == "1") {
          afternoon = 1
        }
        else {
          afternoon = parseFloat(drug.afternoon)
        }

        if (drug.evening == "1/2") {
          evening = parseFloat("0.5")
        }
        else if (drug.evening == "1/4") {
          evening = parseFloat("0.25")
        }
        else if (drug.evening == "1/3") {
          evening = parseFloat("0.33")
        } else if (drug.evening == "0") {
          evening = parseInt("0")
        } else if (evening == "1") {
          evening = 1
        }
        else {
          evening = parseFloat(drug.evening)
        }

        if (drug.night == "1/2") {
          night = parseFloat("0.5")
        }
        else if (drug.night == "1/4") {
          night = parseFloat("0.25")
        }
        else if (drug.night == "1/3") {
          night = parseFloat("0.33")
        } else if (drug.night == "0") {
          night = parseInt("0")
        } else if (drug.night == "1") {
          night = 1
        }
        else {
          night = parseFloat(drug.night)
        }
        medqty = morning + afternoon + evening + night;


        if (drug.day_txt != undefined) {
          if (drug.daydur_txt == "day(s)") {
            durationinnum = drug.day_txt
          } else if (drug.daydur_txt == "week(s)") {
            durationinnum = drug.day_txt * 7
          } else {
            durationinnum = drug.day_txt * 30
          }
          totalqty = parseInt(durationinnum) * medqty
        } else {

          if (drug.daydur_txt == "day(s)") {
            durationinnum = medqty
          } else if (drug.daydur_txt == "week(s)") {
            durationinnum = medqty * 7
          } else {
            durationinnum = medqty * 30
          }
          totalqty = parseInt(durationinnum)
        }
        console.log("morning = " + drug.morning);
        console.log("afternoon " + drug.afternoon)
        console.log("" + drug.evening)
        console.log("night = " + drug.night)

        console.log("drug_text " + drug.day_txt)

        console.log("duration minimum = " + durationinnum);
        console.log("med qty = " + medqty)
        console.log("totalqty" + totalqty)

      }

      else if (drug.intake_txt == "Once a day" && drug.day_txt != undefined && drug.day_txt != "") {
        if (drug.morning == "1/2") {
          morning = parseFloat("0.5")
        }
        else if (drug.morning == "1/4") {
          morning = parseFloat("0.25")
        }
        else if (drug.morning == "1/3") {
          morning = parseFloat("0.33")
        } else if (drug.morning == "0") {
          morning = parseInt("0")
        } else if (drug.morning == "1") {
          morning = 1
        } else {
          morning = parseFloat(drug.morning)
        }

        if (drug.afternoon == "1/2") {
          afternoon = parseFloat("0.5")
        }
        else if (drug.afternoon == "1/4") {
          afternoon = parseFloat("0.25")
        }
        else if (drug.afternoon == "1/3") {
          afternoon = parseFloat("0.33")
        } else if (drug.afternoon == "0") {
          afternoon = parseInt("0")
        } else if (drug.afternoon == "1") {
          afternoon = 1
        }
        else {
          afternoon = parseFloat(drug.afternoon)
        }

        if (drug.evening == "1/2") {
          evening = parseFloat("0.5")
        }
        else if (drug.evening == "1/4") {
          evening = parseFloat("0.25")
        }
        else if (drug.evening == "1/3") {
          evening = parseFloat("0.33")
        } else if (drug.evening == "0") {
          evening = parseInt("0")
        } else if (evening == "1") {
          evening = 1
        }
        else {
          evening = parseFloat(drug.evening)
        }

        if (drug.night == "1/2") {
          night = parseFloat("0.5")
        }
        else if (drug.night == "1/4") {
          night = parseFloat("0.25")
        }
        else if (drug.night == "1/3") {
          night = parseFloat("0.33")
        } else if (drug.night == "0") {
          night = parseInt("0")
        } else if (drug.night == "1") {
          night = 1
        }
        else {
          night = parseFloat(drug.night)
        }
        medqty = morning + afternoon + evening + night;

        if (drug.day_txt != undefined) {
          if (drug.daydur_txt == "day(s)") {
            durationinnum = drug.day_txt
          } else if (drug.daydur_txt == "week(s)") {
            durationinnum = drug.day_txt * 7
          } else {
            durationinnum = drug.day_txt * 30
          }
          totalqty = parseInt(durationinnum) * medqty
        }

        console.log("morning = " + drug.morning);
        console.log("afternoon " + drug.afternoon)
        console.log("" + drug.evening)
        console.log("night = " + drug.night)

        console.log("drug_text " + drug.day_txt)

        console.log("duration minimum = " + durationinnum);
        console.log("med qty = " + medqty)
        console.log("totalqty" + totalqty)

      }
      else if (drug.intake_txt == "SOS") {
        if (drug.morning == "1/2") {
          morning = parseFloat("0.5")
        }
        else if (drug.morning == "1/4") {
          morning = parseFloat("0.25")
        }
        else if (drug.morning == "1/3") {
          morning = parseFloat("0.33")
        } else if (drug.morning == "0") {
          morning = parseInt("0")
        } else if (drug.morning == "1") {
          morning = 1
        } else {
          morning = parseFloat(drug.morning)
        }

        if (drug.afternoon == "1/2") {
          afternoon = parseFloat("0.5")
        }
        else if (drug.afternoon == "1/4") {
          afternoon = parseFloat("0.25")
        }
        else if (drug.afternoon == "1/3") {
          afternoon = parseFloat("0.33")
        } else if (drug.afternoon == "0") {
          afternoon = parseInt("0")
        } else if (drug.afternoon == "1") {
          afternoon = 1
        }
        else {
          afternoon = parseFloat(drug.afternoon)
        }

        if (drug.evening == "1/2") {
          evening = parseFloat("0.5")
        }
        else if (drug.evening == "1/4") {
          evening = parseFloat("0.25")
        }
        else if (drug.evening == "1/3") {
          evening = parseFloat("0.33")
        } else if (drug.evening == "0") {
          evening = parseInt("0")
        } else if (evening == "1") {
          evening = 1
        }
        else {
          evening = parseFloat(drug.evening)
        }

        if (drug.night == "1/2") {
          night = parseFloat("0.5")
        }
        else if (drug.night == "1/4") {
          night = parseFloat("0.25")
        }
        else if (drug.night == "1/3") {
          night = parseFloat("0.33")
        } else if (drug.night == "0") {
          night = parseInt("0")
        } else if (drug.night == "1") {
          night = 1
        }
        else {
          night = parseFloat(drug.night)
        }
        medqty = morning + afternoon + evening + night;

        if (drug.day_txt != undefined) {
          if (drug.daydur_txt == "day(s)") {
            durationinnum = drug.day_txt
          } else if (drug.daydur_txt == "week(s)") {
            durationinnum = drug.day_txt * 7
          } else {
            durationinnum = drug.day_txt * 30
          }
          totalqty = parseInt(durationinnum) * medqty
        } else {

          if (drug.daydur_txt == "day(s)") {
            durationinnum = medqty
          } else if (drug.daydur_txt == "week(s)") {
            durationinnum = medqty * 7
          } else {
            durationinnum = medqty * 30
          }
          totalqty = parseInt(durationinnum)
        }
        console.log("morning = " + drug.morning);
        console.log("afternoon " + drug.afternoon)
        console.log("" + drug.evening)
        console.log("night = " + drug.night)

        console.log("drug_text " + drug.day_txt)

        console.log("duration minimum = " + durationinnum);
        console.log("med qty = " + medqty)
        console.log("totalqty" + totalqty)

      } else if (drug.intake_txt == "Alternative day") {
        if (drug.morning == "1/2") {
          morning = parseFloat("0.5")
        } else if (drug.morning == "1/4") {
          morning = parseFloat("0.25")
        } else if (drug.morning == "1/3") {
          morning = parseFloat("0.33")
        } else if (drug.morning == "0") {
          morning = parseInt("0")
        } else if (drug.morning == "1") {
          morning = 1
        } else {
          morning = parseFloat(drug.morning)
        }

        if (drug.afternoon == "1/2") {
          afternoon = parseFloat("0.5")
        } else if (drug.afternoon == "1/4") {
          afternoon = parseFloat("0.25")
        } else if (drug.afternoon == "1/3") {
          afternoon = parseFloat("0.33")
        } else if (drug.afternoon == "0") {
          afternoon = parseInt("0")
        } else if (drug.afternoon == "1") {
          afternoon = 1
        } else {
          afternoon = parseFloat(drug.afternoon)
        }

        if (drug.evening == "1/2") {
          evening = parseFloat("0.5")
        } else if (drug.evening == "1/4") {
          evening = parseFloat("0.25")
        } else if (drug.evening == "1/3") {
          evening = parseFloat("0.33")
        } else if (drug.evening == "0") {
          evening = parseInt("0")
        } else if (evening == "1") {
          evening = 1
        } else {
          evening = parseFloat(drug.evening)
        }

        if (drug.night == "1/2") {
          night = parseFloat("0.5")
        } else if (drug.night == "1/4") {
          night = parseFloat("0.25")
        } else if (drug.night == "1/3") {
          night = parseFloat("0.33")
        } else if (drug.night == "0") {
          night = parseInt("0")
        } else if (drug.night == "1") {
          night = 1
        } else {
          night = parseFloat(drug.night)
        }

        medqty = morning + afternoon + evening + night;
        if (drug.day_txt != undefined) {
          if (drug.daydur_txt == "day(s)") {
            durationinnum = drug.day_txt / 2;

          } else if (drug.daydur_txt == "week(s)") {
            durationinnum = (drug.day_txt * 7) / 2
          } else {
            durationinnum = (drug.day_txt * 30) / 2
          }
          totalqty = parseInt(durationinnum) * medqty

        } else {
          if (drug.daydur_txt == "day(s)") {
            durationinnum = medqty / 2
          } else if (drug.daydur_txt == "week(s)") {
            durationinnum = (medqty * 7) / 2
          } else {
            durationinnum = (medqty * 30) / 2
          }
          totalqty = parseInt(durationinnum)
        }
      } else if (drug.intake_txt == "Twice a week") {
        if (drug.morning == "1/2") {
          morning = parseFloat("0.5")
        } else if (drug.morning == "1/4") {
          morning = parseFloat("0.25")
        } else if (drug.morning == "1/3") {
          morning = parseFloat("0.33")
        } else if (drug.morning == "0") {
          morning = parseInt("0")
        } else if (drug.morning == "1") {
          morning = 1
        } else {
          morning = parseFloat(drug.morning)
        }

        if (drug.afternoon == "1/2") {
          afternoon = parseFloat("0.5")
        } else if (drug.afternoon == "1/4") {
          afternoon = parseFloat("0.25")
        } else if (drug.afternoon == "1/3") {
          afternoon = parseFloat("0.33")
        } else if (drug.afternoon == "0") {
          afternoon = parseInt("0")
        } else if (drug.afternoon == "1") {
          afternoon = 1
        } else {
          afternoon = parseFloat(drug.afternoon)
        }

        if (drug.evening == "1/2") {
          evening = parseFloat("0.5")
        } else if (drug.evening == "1/4") {
          evening = parseFloat("0.25")
        } else if (drug.evening == "1/3") {
          evening = parseFloat("0.33")
        } else if (drug.evening == "0") {
          evening = parseInt("0")
        } else if (evening == "1") {
          evening = 1
        } else {
          evening = parseFloat(drug.evening)
        }

        if (drug.night == "1/2") {
          night = parseFloat("0.5")
        } else if (drug.night == "1/4") {
          night = parseFloat("0.25")
        } else if (drug.night == "1/3") {
          night = parseFloat("0.33")
        } else if (drug.night == "0") {
          night = parseInt("0")
        } else if (drug.night == "1") {
          night = 1
        } else {
          night = parseFloat(drug.night)
        }

        medqty = morning + afternoon + evening + night;
        if (drug.day_txt != undefined) {
          if (drug.daydur_txt == "day(s)") {
            durationinnum = drug.day_txt / 3.5;

          } else if (drug.daydur_txt == "week(s)") {
            durationinnum = (drug.day_txt * 7) / 3.5
          } else {
            durationinnum = (drug.day_txt * 30) / 3.5
          }
          totalqty = parseInt(durationinnum) * medqty
        } else {
          if (drug.daydur_txt == "day(s)") {
            durationinnum = medqty / 3.5
          } else if (drug.daydur_txt == "week(s)") {
            durationinnum = (medqty * 7) / 3.5
          } else {
            durationinnum = (medqty * 30) / 3.5
          }
          totalqty = parseInt(durationinnum)
        }
      } else if (drug.intake_txt == "Once a week") {
        if (drug.morning == "1/2") {
          morning = parseFloat("0.5")
        } else if (drug.morning == "1/4") {
          morning = parseFloat("0.25")
        } else if (drug.morning == "1/3") {
          morning = parseFloat("0.33")
        } else if (drug.morning == "0") {
          morning = parseInt("0")
        } else if (drug.morning == "1") {
          morning = 1
        } else {
          morning = parseFloat(drug.morning)
        }

        if (drug.afternoon == "1/2") {
          afternoon = parseFloat("0.5")
        } else if (drug.afternoon == "1/4") {
          afternoon = parseFloat("0.25")
        } else if (drug.afternoon == "1/3") {
          afternoon = parseFloat("0.33")
        } else if (drug.afternoon == "0") {
          afternoon = parseInt("0")
        } else if (drug.afternoon == "1") {
          afternoon = 1
        } else {
          afternoon = parseFloat(drug.afternoon)
        }

        if (drug.evening == "1/2") {
          evening = parseFloat("0.5")
        } else if (drug.evening == "1/4") {
          evening = parseFloat("0.25")
        } else if (drug.evening == "1/3") {
          evening = parseFloat("0.33")
        } else if (drug.evening == "0") {
          evening = parseInt("0")
        } else if (evening == "1") {
          evening = 1
        } else {
          evening = parseFloat(drug.evening)
        }

        if (drug.night == "1/2") {
          night = parseFloat("0.5")
        } else if (drug.night == "1/4") {
          night = parseFloat("0.25")
        } else if (drug.night == "1/3") {
          night = parseFloat("0.33")
        } else if (drug.night == "0") {
          night = parseInt("0")
        } else if (drug.night == "1") {
          night = 1
        } else {
          night = parseFloat(drug.night)
        }

        medqty = morning + afternoon + evening + night;
        if (drug.day_txt != undefined) {
          if (drug.daydur_txt == "day(s)") {
            durationinnum = drug.day_txt / 7;

          } else if (drug.daydur_txt == "week(s)") {
            durationinnum = (drug.day_txt * 7) / 7
          } else {
            durationinnum = (drug.day_txt * 30) / 7
          }
          totalqty = parseInt(durationinnum) * medqty

        } else {
          if (drug.daydur_txt == "day(s)") {
            durationinnum = medqty / 7
          } else if (drug.daydur_txt == "week(s)") {
            durationinnum = (medqty * 7) / 7
          } else {
            durationinnum = (medqty * 30) / 7
          }
          totalqty = parseInt(durationinnum)
        }
      } else if (drug.intake_txt == "Once in two weeks") {
        if (drug.morning == "1/2") {
          morning = parseFloat("0.5")
        } else if (drug.morning == "1/4") {
          morning = parseFloat("0.25")
        } else if (drug.morning == "1/3") {
          morning = parseFloat("0.33")
        } else if (drug.morning == "0") {
          morning = parseInt("0")
        } else if (drug.morning == "1") {
          morning = 1
        } else {
          morning = parseFloat(drug.morning)
        }

        if (drug.afternoon == "1/2") {
          afternoon = parseFloat("0.5")
        } else if (drug.afternoon == "1/4") {
          afternoon = parseFloat("0.25")
        } else if (drug.afternoon == "1/3") {
          afternoon = parseFloat("0.33")
        } else if (drug.afternoon == "0") {
          afternoon = parseInt("0")
        } else if (drug.afternoon == "1") {
          afternoon = 1
        } else {
          afternoon = parseFloat(drug.afternoon)
        }

        if (drug.evening == "1/2") {
          evening = parseFloat("0.5")
        } else if (drug.evening == "1/4") {
          evening = parseFloat("0.25")
        } else if (drug.evening == "1/3") {
          evening = parseFloat("0.33")
        } else if (drug.evening == "0") {
          evening = parseInt("0")
        } else if (evening == "1") {
          evening = 1
        } else {
          evening = parseFloat(drug.evening)
        }

        if (drug.night == "1/2") {
          night = parseFloat("0.5")
        } else if (drug.night == "1/4") {
          night = parseFloat("0.25")
        } else if (drug.night == "1/3") {
          night = parseFloat("0.33")
        } else if (drug.night == "0") {
          night = parseInt("0")
        } else if (drug.night == "1") {
          night = 1
        } else {
          night = parseFloat(drug.night)
        }

        medqty = morning + afternoon + evening + night;
        if (drug.day_txt != undefined) {
          if (drug.daydur_txt == "day(s)") {
            durationinnum = drug.day_txt / 14;

          } else if (drug.daydur_txt == "week(s)") {
            durationinnum = (drug.day_txt * 7) / 14
          } else {
            durationinnum = (drug.day_txt * 30) / 14
          }
          totalqty = parseInt(durationinnum) * medqty

        } else {
          if (drug.daydur_txt == "day(s)") {
            durationinnum = medqty / 14
          } else if (drug.daydur_txt == "week(s)") {
            durationinnum = (medqty * 7) / 14
          } else {
            durationinnum = (medqty * 30) / 14
          }
          totalqty = parseInt(durationinnum)
        }

      } else if (drug.intake_txt == "Once a month") {
        if (drug.morning == "1/2") {
          morning = parseFloat("0.5")
        } else if (drug.morning == "1/4") {
          morning = parseFloat("0.25")
        } else if (drug.morning == "1/3") {
          morning = parseFloat("0.33")
        } else if (drug.morning == "0") {
          morning = parseInt("0")
        } else if (drug.morning == "1") {
          morning = 1
        } else {
          morning = parseFloat(drug.morning)
        }

        if (drug.afternoon == "1/2") {
          afternoon = parseFloat("0.5")
        } else if (drug.afternoon == "1/4") {
          afternoon = parseFloat("0.25")
        } else if (drug.afternoon == "1/3") {
          afternoon = parseFloat("0.33")
        } else if (drug.afternoon == "0") {
          afternoon = parseInt("0")
        } else if (drug.afternoon == "1") {
          afternoon = 1
        } else {
          afternoon = parseFloat(drug.afternoon)
        }

        if (drug.evening == "1/2") {
          evening = parseFloat("0.5")
        } else if (drug.evening == "1/4") {
          evening = parseFloat("0.25")
        } else if (drug.evening == "1/3") {
          evening = parseFloat("0.33")
        } else if (drug.evening == "0") {
          evening = parseInt("0")
        } else if (evening == "1") {
          evening = 1
        } else {
          evening = parseFloat(drug.evening)
        }

        if (drug.night == "1/2") {
          night = parseFloat("0.5")
        } else if (drug.night == "1/4") {
          night = parseFloat("0.25")
        } else if (drug.night == "1/3") {
          night = parseFloat("0.33")
        } else if (drug.night == "0") {
          night = parseInt("0")
        } else if (drug.night == "1") {
          night = 1
        } else {
          night = parseFloat(drug.night)
        }

        medqty = morning + afternoon + evening + night;
        if (drug.day_txt != undefined) {
          if (drug.daydur_txt == "day(s)") {
            durationinnum = drug.day_txt / 30;

          } else if (drug.daydur_txt == "week(s)") {
            durationinnum = ((drug.day_txt * 7) + 2) / 30
          } else {
            durationinnum = (drug.day_txt * 30) / 30
          }
          totalqty = parseInt(durationinnum) * medqty

        } else {
          if (drug.daydur_txt == "day(s)") {
            durationinnum = medqty / 30
          } else if (drug.daydur_txt == "week(s)") {
            durationinnum = ((medqty * 7) + 2) / 30
          } else {
            durationinnum = medqty
          }
          totalqty = parseInt(durationinnum)
        }

      } else if (drug.day_txt == undefined) {
        this.toastr.error("Enter the day")
        durationflag = false

      } else if (drug.day_txt == "") {
        this.toastr.error("Enter the day")
        durationflag = false

      } else {
        medqty = 0
        totalqty = 0
      }

      if (durationflag == true) {
        if (drug.no_of_IU !== '0' && drug.qty_per_unit !== '0' && drug.no_of_IU !== 0 && drug.qty_per_unit !== 0 && drug.no_of_IU !== undefined && drug.qty_per_unit !== undefined && drug.no_of_IU !== '' && drug.qty_per_unit !== '') {
          totalqty = Math.ceil(totalqty / (drug.no_of_IU * drug.qty_per_unit)); // no need for (int) cast, TypeScript will infer the type
          drug.quantity = totalqty;
        } else if (drug.no_of_IU === '0' && drug.qty_per_unit !== '0' && drug.qty_per_unit !== 0 && drug.qty_per_unit !== undefined && drug.qty_per_unit !== '') {
          totalqty = Math.ceil(totalqty / drug.qty_per_unit);
          drug.quantity = totalqty;
        }
        else if (drug.no_of_IU === 0 && drug.qty_per_unit !== '0' && drug.qty_per_unit !== 0 && drug.qty_per_unit !== undefined && drug.qty_per_unit !== '') {
          totalqty = Math.ceil(totalqty / drug.qty_per_unit);
          drug.quantity = totalqty;
        } else if (drug.no_of_IU === undefined && drug.qty_per_unit !== '0' && drug.qty_per_unit !== 0 && drug.qty_per_unit !== undefined && drug.qty_per_unit !== '') {
          totalqty = Math.ceil(totalqty / drug.qty_per_unit);
          drug.quantity = totalqty;
        } else if (drug.no_of_IU === '' && drug.qty_per_unit !== '0' && drug.qty_per_unit !== 0 && drug.qty_per_unit !== undefined && drug.qty_per_unit !== '') {
          totalqty = Math.ceil(totalqty / drug.qty_per_unit);
          drug.quantity = totalqty;
        }
        else {
          console.log("totalqty = " + totalqty)
          drug.quantity = totalqty;
        }
        // this.createRow(drug)
      }
    }
  }

  sesssionBaseChange(drug_detailsarray) {
    console.log(" session BaseChange == " + JSON.stringify(drug_detailsarray))
    this.alternatsession = true;
    if (drug_detailsarray.drug_id == undefined || "" || null) {
      this.toastr.error(Message_data.selectMedicine);
    }
    for (var j = 0; j < this.getIntakeArray.length; j++) {
      if (drug_detailsarray.intake_txt == this.getIntakeArray[j].description ||
        drug_detailsarray.intake_desc == this.getIntakeArray[j].description) {
        drug_detailsarray.intake = this.getIntakeArray[j].drug_int_id
      }
    }
    console.log(" session BaseChange == " + JSON.stringify(drug_detailsarray))
    if (drug_detailsarray.intake_txt == "Morning only") {
      if (drug_detailsarray.show_intake == "1") {
        this.intakesession = "Morning only"
        this.sessionhide = false;
        this.sessionhrs = true;
        if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
          || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
          || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
          || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
          || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
          || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
          || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
          || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
          drug_detailsarray.morning = "1";
          drug_detailsarray.afternoon = "0";
          drug_detailsarray.evening = "0";
          drug_detailsarray.night = "0";

        } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {

          if (this.clntAge != undefined) {
            var cage = parseInt(this.clntAge);
            if (cage <= 5) {
              drug_detailsarray.morning = "2.5";
              drug_detailsarray.afternoon = "0";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "0";
            } else if (cage > 5 && cage <= 13) {
              drug_detailsarray.morning = "5";
              drug_detailsarray.afternoon = "0";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "0";
            } else {
              drug_detailsarray.morning = "10";
              drug_detailsarray.afternoon = "0";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "0";
            }
          }
          // drug_detailsarray.morning = "5";
          // drug_detailsarray.afternoon = "0";
          // drug_detailsarray.evening = "0";
          // drug_detailsarray.night = "0";
        }

        drug_detailsarray.morning_dis = false;
        drug_detailsarray.afternoon_dis = true;
        drug_detailsarray.even_dis = true;
        drug_detailsarray.ngt_dis = true;

        drug_detailsarray.drug_detailsarray = true;

        drug_detailsarray.morningID = false;
        this.afterID = false;
        this.eveID = true;

        drug_detailsarray.eve_eventxt = true;
        drug_detailsarray.night_eventxt = false;
        this.daydurationShow = false;

        drug_detailsarray.drug_detailsarray = true;
        drug_detailsarray.even_dis = true;
        drug_detailsarray.ngt_dis = true;

        drug_detailsarray.mor_lable = false;
        drug_detailsarray.after_eventxt = false;
        drug_detailsarray.quan_lable = true;

        drug_detailsarray.morning_req = true;
        drug_detailsarray.afternoon_req = false;
        drug_detailsarray.evening_req = false;
        drug_detailsarray.night_req = false;

        drug_detailsarray.intake_show = false;
        if (this.afterfoodText != "With food") {
          drug_detailsarray.dure_show = false;
        }
        drug_detailsarray.morn_eventxt = false;
      }



    } else if (drug_detailsarray.intake_txt == "Afternoon only") {

      if (drug_detailsarray.show_intake == "1") {
        this.sessionhide = false;
        this.sessionhrs = true;

        this.intakesession = "Afternoon only"
        if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
          || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
          || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
          || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
          || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
          || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
          || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
          || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
          drug_detailsarray.morning = "0";
          drug_detailsarray.afternoon = "1";
          drug_detailsarray.evening = "0";
          drug_detailsarray.night = "0";

        } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {

          if (this.clntAge != undefined) {
            var cage = parseInt(this.clntAge);
            if (cage <= 5) {
              drug_detailsarray.morning = "0";
              drug_detailsarray.afternoon = "2.5";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "0";
            } else if (cage > 5 && cage <= 13) {
              drug_detailsarray.morning = "0";
              drug_detailsarray.afternoon = "5";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "0";
            } else {
              drug_detailsarray.morning = "0";
              drug_detailsarray.afternoon = "10";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "0";
            }
          }

          // drug_detailsarray.morning = "0";
          // drug_detailsarray.afternoon = "5";
          // drug_detailsarray.evening = "0";
          // drug_detailsarray.night = "0";
        }

        drug_detailsarray.afternoon_dis = false;
        drug_detailsarray.morning_dis = true;
        drug_detailsarray.even_dis = true;
        drug_detailsarray.ngt_dis = true;

        drug_detailsarray.morningID = false;
        this.afterID = false;
        this.eveID = true;

        drug_detailsarray.eve_eventxt = true;
        drug_detailsarray.night_eventxt = false;
        this.daydurationShow = false;

        drug_detailsarray.mor_lable = false;
        drug_detailsarray.after_eventxt = false;
        drug_detailsarray.quan_lable = true;

        drug_detailsarray.morning_req = false;
        drug_detailsarray.afternoon_req = true;
        drug_detailsarray.evening_req = false;
        drug_detailsarray.night_req = true;

        drug_detailsarray.intake_show = false;
        if (this.afterfoodText != "With food") {
          drug_detailsarray.dure_show = false;
        }
        drug_detailsarray.morn_eventxt = false;
      }


    } else if (drug_detailsarray.intake_txt == "Evening only") {

      if (drug_detailsarray.show_intake == "1") {


        this.sessionhide = false;
        this.sessionhrs = true;
        this.intakesession = "Evening only"
        if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
          || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
          || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
          || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
          || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
          || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
          || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
          || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
          drug_detailsarray.morning = "0";
          drug_detailsarray.afternoon = "0";
          drug_detailsarray.evening = "1";
          drug_detailsarray.night = "0";
        }
        else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {
          if (this.clntAge != undefined) {
            var cage = parseInt(this.clntAge);
            if (cage <= 5) {
              drug_detailsarray.morning = "0";
              drug_detailsarray.afternoon = "0";
              drug_detailsarray.evening = "2.5";
              drug_detailsarray.night = "0";
            } else if (cage > 5 && cage <= 13) {
              drug_detailsarray.morning = "0";
              drug_detailsarray.afternoon = "0";
              drug_detailsarray.evening = "5";
              drug_detailsarray.night = "0";
            } else {
              drug_detailsarray.morning = "0";
              drug_detailsarray.afternoon = "0";
              drug_detailsarray.evening = "10";
              drug_detailsarray.night = "0";
            }
          }
          // drug_detailsarray.morning = "0";
          // drug_detailsarray.afternoon = "0";
          // drug_detailsarray.evening = "5";
          // drug_detailsarray.night = "0";
        }
        drug_detailsarray.morning_dis = true;
        drug_detailsarray.afternoon_dis = true;
        drug_detailsarray.even_dis = false;
        drug_detailsarray.ngt_dis = true;

        drug_detailsarray.morningID = false;
        this.afterID = false;
        this.eveID = true;

        drug_detailsarray.eve_eventxt = false;
        drug_detailsarray.night_eventxt = true;
        this.daydurationShow = false;

        drug_detailsarray.mor_lable = false;
        drug_detailsarray.after_eventxt = false;
        drug_detailsarray.quan_lable = true;

        drug_detailsarray.morning_req = false;
        drug_detailsarray.afternoon_req = false;
        drug_detailsarray.evening_req = true;
        drug_detailsarray.night_req = false;

        drug_detailsarray.intake_show = false;
        if (this.afterfoodText != "With food") {
          drug_detailsarray.dure_show = false;
        }
        drug_detailsarray.morn_eventxt = false;
      }
    }
    else if (drug_detailsarray.intake_txt == "Night only") {


      if (drug_detailsarray.show_intake == "1") {
        this.sessionhide = false;
        this.sessionhrs = true;
        this.intakesession = "Night only"
        if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
          || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
          || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
          || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
          || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
          || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
          || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
          || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
          drug_detailsarray.morning = "0";
          drug_detailsarray.afternoon = "0";
          drug_detailsarray.evening = "0";
          drug_detailsarray.night = "1";
        } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {



          if (this.clntAge != undefined) {
            var cage = parseInt(this.clntAge);
            if (cage <= 5) {
              drug_detailsarray.morning = "0";
              drug_detailsarray.afternoon = "0";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "2.5";
            } else if (cage > 5 && cage <= 13) {
              drug_detailsarray.morning = "0";
              drug_detailsarray.afternoon = "0";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "5";
            } else {
              drug_detailsarray.morning = "0";
              drug_detailsarray.afternoon = "0";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "10";
            }
          }





          // drug_detailsarray.morning = "0";
          // drug_detailsarray.afternoon = "0";
          // drug_detailsarray.evening = "0";
          // drug_detailsarray.night = "5";
        }

        drug_detailsarray.afternoon_dis = true;
        drug_detailsarray.morning_dis = true;
        drug_detailsarray.even_dis = true;
        drug_detailsarray.ngt_dis = false;

        drug_detailsarray.morningID = false;
        this.afterID = false;
        this.eveID = true;

        drug_detailsarray.eve_eventxt = true;
        drug_detailsarray.night_eventxt = false;
        this.daydurationShow = false;

        drug_detailsarray.morning_req = false;
        drug_detailsarray.afternoon_req = false;
        drug_detailsarray.evening_req = false;
        drug_detailsarray.night_req = true;

        drug_detailsarray.mor_lable = false;
        drug_detailsarray.after_eventxt = false;
        drug_detailsarray.quan_lable = true;
        drug_detailsarray.intake_show = false;

        if (this.afterfoodText != "With food") {
          drug_detailsarray.dure_show = false;
        }
        drug_detailsarray.morn_eventxt = false;
      }

    } else if (drug_detailsarray.intake_txt == "Morning & Afternoon") {

      if (drug_detailsarray.show_intake == "1") {
        this.sessionhide = false;
        this.sessionhrs = true;
        this.intakesession = "Morning & Afternoon"
        if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
          || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
          || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
          || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
          || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
          || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
          || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
          || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
          drug_detailsarray.morning = "1";
          drug_detailsarray.afternoon = "1";
          drug_detailsarray.evening = "0";
          drug_detailsarray.night = "0";

        } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {


          if (this.clntAge != undefined) {
            var cage = parseInt(this.clntAge);
            if (cage <= 5) {
              drug_detailsarray.morning = "2.5";
              drug_detailsarray.afternoon = "2.5";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "0";
            } else if (cage > 5 && cage <= 13) {
              drug_detailsarray.morning = "5";
              drug_detailsarray.afternoon = "5";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "0";
            } else {
              drug_detailsarray.morning = "10";
              drug_detailsarray.afternoon = "10";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "0";
            }
          }



          // drug_detailsarray.morning = "5";
          // drug_detailsarray.afternoon = "5";
          // drug_detailsarray.evening = "0";
          // drug_detailsarray.night = "0";
        }

        drug_detailsarray.morning_dis = false;
        drug_detailsarray.afternoon_dis = false;
        drug_detailsarray.even_dis = true;
        drug_detailsarray.ngt_dis = true;

        drug_detailsarray.morningID = false;
        this.afterID = false;
        this.eveID = true;

        drug_detailsarray.eve_eventxt = true;
        drug_detailsarray.night_eventxt = false;
        this.daydurationShow = false;

        drug_detailsarray.morning_req = true;
        drug_detailsarray.afternoon_req = true;
        drug_detailsarray.evening_req = false;
        drug_detailsarray.night_req = false;

        drug_detailsarray.mor_lable = false;
        drug_detailsarray.after_eventxt = false;
        drug_detailsarray.quan_lable = true;
        drug_detailsarray.intake_show = false;

        if (this.afterfoodText != "With food") {
          drug_detailsarray.dure_show = false;
        }
        drug_detailsarray.morn_eventxt = false;
      }
    }
    else if (drug_detailsarray.intake_txt == "Morning & Evening") {
      if (drug_detailsarray.show_intake == "1") {
        this.sessionhide = false;
        this.sessionhrs = true;
        this.intakesession = "Morning & Evening"
        if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
          || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
          || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
          || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
          || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
          || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
          || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
          || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
          drug_detailsarray.morning = "1";
          drug_detailsarray.afternoon = "0";
          drug_detailsarray.evening = "1";
          drug_detailsarray.night = "0";

        } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {


          if (this.clntAge != undefined) {
            var cage = parseInt(this.clntAge);
            if (cage <= 5) {
              drug_detailsarray.morning = "0";
              drug_detailsarray.afternoon = "2.5";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "0";
            } else if (cage > 5 && cage <= 13) {
              drug_detailsarray.morning = "5";
              drug_detailsarray.afternoon = "0";
              drug_detailsarray.evening = "5";
              drug_detailsarray.night = "0";
            } else {
              drug_detailsarray.morning = "10";
              drug_detailsarray.afternoon = "0";
              drug_detailsarray.evening = "10";
              drug_detailsarray.night = "0";
            }
          }

          // drug_detailsarray.morning = "5";
          // drug_detailsarray.afternoon = "0";
          // drug_detailsarray.evening = "5";
          // drug_detailsarray.night = "0";
        }

        drug_detailsarray.morning_dis = false;
        drug_detailsarray.afternoon_dis = true;
        drug_detailsarray.even_dis = false;
        drug_detailsarray.ngt_dis = true;

        drug_detailsarray.morningID = false;
        this.afterID = false;
        this.eveID = true;

        drug_detailsarray.eve_eventxt = false;
        drug_detailsarray.night_eventxt = true;
        this.daydurationShow = false;

        drug_detailsarray.morning_req = true;
        drug_detailsarray.afternoon_req = false;
        drug_detailsarray.evening_req = true;
        drug_detailsarray.night_req = false;

        drug_detailsarray.mor_lable = false;
        drug_detailsarray.after_eventxt = false;
        drug_detailsarray.quan_lable = true;
        drug_detailsarray.intake_show = false;

        if (this.afterfoodText != "With food") {
          drug_detailsarray.dure_show = false;
        }
        drug_detailsarray.morn_eventxt = false;
      }

    }
    else if (drug_detailsarray.intake_txt == "Morning & Night") {
      if (drug_detailsarray.show_intake == "1") {
        this.sessionhide = false;
        this.sessionhrs = true;
        this.intakesession = "Morning & Night"
        if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
          || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
          || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
          || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
          || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
          || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
          || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
          || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
          drug_detailsarray.morning = "1";
          drug_detailsarray.afternoon = "0";
          drug_detailsarray.evening = "0";
          drug_detailsarray.night = "1";

        } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {
          if (this.clntAge != undefined) {
            var cage = parseInt(this.clntAge);
            if (cage <= 5) {
              drug_detailsarray.morning = "2.5";
              drug_detailsarray.afternoon = "0";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "2.5";
            } else if (cage > 5 && cage <= 13) {
              drug_detailsarray.morning = "5";
              drug_detailsarray.afternoon = "0";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "5";
            } else {
              drug_detailsarray.morning = "10";
              drug_detailsarray.afternoon = "0";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "10";
            }
          }





          // drug_detailsarray.morning = "5";
          // drug_detailsarray.afternoon = "0";
          // drug_detailsarray.evening = "0";
          // drug_detailsarray.night = "5";
        }

        drug_detailsarray.morning_dis = false;
        drug_detailsarray.afternoon_dis = true;
        drug_detailsarray.even_dis = true;
        drug_detailsarray.ngt_dis = false;

        drug_detailsarray.morningID = false;
        this.afterID = false;
        this.eveID = true;

        drug_detailsarray.eve_eventxt = true;
        drug_detailsarray.night_eventxt = false;
        this.daydurationShow = false;

        drug_detailsarray.morning_req = true;
        drug_detailsarray.afternoon_req = false;
        drug_detailsarray.evening_req = false;
        drug_detailsarray.night_req = true;

        drug_detailsarray.mor_lable = false;
        drug_detailsarray.after_eventxt = false;
        drug_detailsarray.quan_lable = true;
        drug_detailsarray.intake_show = false;

        if (this.afterfoodText != "With food") {
          drug_detailsarray.dure_show = false;
        }
        drug_detailsarray.morn_eventxt = false;
      }
    }
    else if (drug_detailsarray.intake_txt == "Afternoon & Night") {
      if (drug_detailsarray.show_intake == "1") {
        this.sessionhide = false;
        this.sessionhrs = true;
        this.intakesession = "Afternoon & Night"
        if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
          || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
          || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
          || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
          || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
          || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
          || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
          || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
          drug_detailsarray.morning = "0";
          drug_detailsarray.afternoon = "1";
          drug_detailsarray.evening = "0";
          drug_detailsarray.night = "1";
        } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {


          if (this.clntAge != undefined) {
            var cage = parseInt(this.clntAge);
            if (cage <= 5) {
              drug_detailsarray.morning = "0";
              drug_detailsarray.afternoon = "2.5";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "2.5";
            } else if (cage > 5 && cage <= 13) {
              drug_detailsarray.morning = "0";
              drug_detailsarray.afternoon = "5";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "5";
            } else {
              drug_detailsarray.morning = "0";
              drug_detailsarray.afternoon = "10";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "10";
            }
          }

          // drug_detailsarray.morning = "0";
          // drug_detailsarray.afternoon = "5";
          // drug_detailsarray.evening = "0";
          // drug_detailsarray.night = "5";
        }

        drug_detailsarray.morning_dis = true;
        drug_detailsarray.afternoon_dis = false;
        drug_detailsarray.even_dis = true;
        drug_detailsarray.ngt_dis = false;

        drug_detailsarray.morningID = false;
        this.afterID = false;
        this.eveID = true;

        drug_detailsarray.eve_eventxt = true;
        drug_detailsarray.night_eventxt = false;
        this.daydurationShow = false;

        drug_detailsarray.morning_req = false;
        drug_detailsarray.afternoon_req = true;
        drug_detailsarray.evening_req = false;
        drug_detailsarray.night_req = true;

        drug_detailsarray.mor_lable = false;
        drug_detailsarray.after_eventxt = false;
        drug_detailsarray.quan_lable = true;
        drug_detailsarray.intake_show = false;

        if (this.afterfoodText != "With food") {
          drug_detailsarray.dure_show = false;
        }
        drug_detailsarray.morn_eventxt = false;
      }
    }
    else if (drug_detailsarray.intake_txt == "Afternoon & Evening") {
      if (drug_detailsarray.show_intake == "1") {
        this.sessionhide = false;
        this.sessionhrs = true;
        this.intakesession = "Afternoon & Evening"
        if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
          || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
          || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
          || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
          || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
          || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
          || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
          || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
          drug_detailsarray.morning = "0";
          drug_detailsarray.afternoon = "1";
          drug_detailsarray.evening = "1";
          drug_detailsarray.night = "0";

        } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {


          if (this.clntAge != undefined) {
            var cage = parseInt(this.clntAge);
            if (cage <= 5) {
              drug_detailsarray.morning = "0";
              drug_detailsarray.afternoon = "2.5";
              drug_detailsarray.evening = "2.5";
              drug_detailsarray.night = "0";
            } else if (cage > 5 && cage <= 13) {
              drug_detailsarray.morning = "0";
              drug_detailsarray.afternoon = "5";
              drug_detailsarray.evening = "5";
              drug_detailsarray.night = "";
            } else {
              drug_detailsarray.morning = "0";
              drug_detailsarray.afternoon = "10";
              drug_detailsarray.evening = "10";
              drug_detailsarray.night = "0";
            }
          }




          // drug_detailsarray.morning = "0";
          // drug_detailsarray.afternoon = "5";
          // drug_detailsarray.evening = "5";
          // drug_detailsarray.night = "";
        }

        drug_detailsarray.morning_dis = true;
        drug_detailsarray.afternoon_dis = false;
        drug_detailsarray.even_dis = false;
        drug_detailsarray.ngt_dis = true;

        drug_detailsarray.morningID = false;
        this.afterID = false;
        this.eveID = true;

        drug_detailsarray.eve_eventxt = false;
        drug_detailsarray.night_eventxt = true;
        this.daydurationShow = false;

        drug_detailsarray.morning_req = false;
        drug_detailsarray.afternoon_req = true;
        drug_detailsarray.evening_req = true;
        drug_detailsarray.night_req = false;

        drug_detailsarray.mor_lable = false;
        drug_detailsarray.after_eventxt = false;
        drug_detailsarray.quan_lable = true;
        drug_detailsarray.intake_show = false;

        if (this.afterfoodText != "With food") {
          drug_detailsarray.dure_show = false;
        }
        drug_detailsarray.morn_eventxt = false;
      }

    }
    else if (drug_detailsarray.intake_txt == "Morning,Afternoon & Evening") {
      if (drug_detailsarray.show_intake == "1") {
        if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
          || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
          || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
          || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
          || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
          || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
          || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
          || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
          drug_detailsarray.morning = "1";
          drug_detailsarray.afternoon = "1";
          drug_detailsarray.evening = "1";
          drug_detailsarray.night = "0";

        } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {

          if (this.clntAge != undefined) {
            var cage = parseInt(this.clntAge);
            if (cage <= 5) {
              drug_detailsarray.morning = "2.5";
              drug_detailsarray.afternoon = "2.5";
              drug_detailsarray.evening = "2.5";
              drug_detailsarray.night = "0";
            } else if (cage > 5 && cage <= 13) {
              drug_detailsarray.morning = "5";
              drug_detailsarray.afternoon = "5";
              drug_detailsarray.evening = "5";
              drug_detailsarray.night = "";
            } else {
              drug_detailsarray.morning = "10";
              drug_detailsarray.afternoon = "10";
              drug_detailsarray.evening = "10";
              drug_detailsarray.night = "0";
            }
          }

          // drug_detailsarray.morning = "5";
          // drug_detailsarray.afternoon = "5";
          // drug_detailsarray.evening = "5";
          // drug_detailsarray.night = "";
        }

        drug_detailsarray.morning_dis = false;
        drug_detailsarray.afternoon_dis = false;
        drug_detailsarray.even_dis = false;
        drug_detailsarray.ngt_dis = true;

        drug_detailsarray.morningID = false;
        this.afterID = false;
        this.eveID = true;

        drug_detailsarray.eve_eventxt = false;
        drug_detailsarray.night_eventxt = true;
        this.daydurationShow = false;

        drug_detailsarray.morning_req = true;
        drug_detailsarray.afternoon_req = true;
        drug_detailsarray.evening_req = true;
        drug_detailsarray.night_req = false;

        drug_detailsarray.mor_lable = false;
        drug_detailsarray.after_eventxt = false;
        drug_detailsarray.quan_lable = true;
        drug_detailsarray.intake_show = false;

        if (this.afterfoodText != "With food") {
          drug_detailsarray.dure_show = false;
        }
        drug_detailsarray.morn_eventxt = false;
      }
    }
    else if (drug_detailsarray.intake_txt == "Thrice a day") {
      if (drug_detailsarray.show_intake == "1") {

        if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
          || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
          || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
          || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
          || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
          || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
          || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
          || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
          drug_detailsarray.morning = "1";
          drug_detailsarray.afternoon = "1";
          drug_detailsarray.evening = "0";
          drug_detailsarray.night = "1";

        } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {

          if (this.clntAge != undefined) {
            var cage = parseInt(this.clntAge);
            if (cage <= 5) {
              drug_detailsarray.morning = "2.5";
              drug_detailsarray.afternoon = "2.5";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "2.5";

            } else if (cage > 5 && cage <= 13) {
              drug_detailsarray.morning = "5";
              drug_detailsarray.afternoon = "5";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "5";

            } else {
              drug_detailsarray.morning = "10";
              drug_detailsarray.afternoon = "10";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "10";
            }
          }

          // drug_detailsarray.morning = "5";
          // drug_detailsarray.afternoon = "5";
          // drug_detailsarray.evening = "0";
          // drug_detailsarray.night = "5";
        }

        drug_detailsarray.morning_dis = false;
        drug_detailsarray.afternoon_dis = false;
        drug_detailsarray.even_dis = true;
        drug_detailsarray.ngt_dis = false;

        drug_detailsarray.morningID = false;
        this.afterID = false;
        this.eveID = true;

        drug_detailsarray.eve_eventxt = true;
        drug_detailsarray.night_eventxt = false;
        this.daydurationShow = false;

        drug_detailsarray.morning_req = true;
        drug_detailsarray.afternoon_req = true;
        drug_detailsarray.evening_req = false;
        drug_detailsarray.night_req = true;

        drug_detailsarray.mor_lable = false;
        drug_detailsarray.after_eventxt = false;
        drug_detailsarray.quan_lable = true;
        drug_detailsarray.intake_show = false;

        if (this.afterfoodText != "With food") {
          drug_detailsarray.dure_show = false;
        }
        drug_detailsarray.morn_eventxt = false;
      }

    }
    else if (drug_detailsarray.intake_txt == "Morning,Afternoon & Night") {
      if (drug_detailsarray.show_intake == "1") {
        if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
          || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
          || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
          || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
          || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
          || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
          || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
          || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
          drug_detailsarray.morning = "1";
          drug_detailsarray.afternoon = "1";
          drug_detailsarray.evening = "0";
          drug_detailsarray.night = "1";

        } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {



          if (this.clntAge != undefined) {
            var cage = parseInt(this.clntAge);
            if (cage <= 5) {
              drug_detailsarray.morning = "2.5";
              drug_detailsarray.afternoon = "2.5";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "2.5";

            } else if (cage > 5 && cage <= 13) {
              drug_detailsarray.morning = "5";
              drug_detailsarray.afternoon = "5";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "5";

            } else {
              drug_detailsarray.morning = "10";
              drug_detailsarray.afternoon = "10";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "10";
            }
          }



          // drug_detailsarray.morning = "5";
          // drug_detailsarray.afternoon = "5";
          // drug_detailsarray.evening = "0";
          // drug_detailsarray.night = "5";
        }

        drug_detailsarray.morning_dis = false;
        drug_detailsarray.afternoon_dis = false;
        drug_detailsarray.even_dis = true;
        drug_detailsarray.ngt_dis = false;

        drug_detailsarray.morningID = false;
        this.afterID = false;
        this.eveID = true;

        drug_detailsarray.eve_eventxt = true;
        drug_detailsarray.night_eventxt = false;
        this.daydurationShow = false;

        drug_detailsarray.morning_req = true;
        drug_detailsarray.afternoon_req = true;
        drug_detailsarray.evening_req = false;
        drug_detailsarray.night_req = true;

        drug_detailsarray.mor_lable = false;
        drug_detailsarray.after_eventxt = false;
        drug_detailsarray.quan_lable = true;
        drug_detailsarray.intake_show = false;

        if (this.afterfoodText != "With food") {
          drug_detailsarray.dure_show = false;
        }
        drug_detailsarray.morn_eventxt = false;
      }

    }
    else if (drug_detailsarray.intake_txt == "Morning,Afternoon,Evening & Night") {
      if (drug_detailsarray.show_intake == "1") {
        if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
          || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
          || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
          || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
          || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
          || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
          || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
          || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
          drug_detailsarray.morning = "1";
          drug_detailsarray.afternoon = "1";
          drug_detailsarray.evening = "1";
          drug_detailsarray.night = "1";

        } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {



          if (this.clntAge != undefined) {
            var cage = parseInt(this.clntAge);
            if (cage <= 5) {
              drug_detailsarray.morning = "2.5";
              drug_detailsarray.afternoon = "2.5";
              drug_detailsarray.evening = "2.5";
              drug_detailsarray.night = "2.5";

            } else if (cage > 5 && cage <= 13) {
              drug_detailsarray.morning = "5";
              drug_detailsarray.afternoon = "5";
              drug_detailsarray.evening = "5";
              drug_detailsarray.night = "5";

            } else {
              drug_detailsarray.morning = "10";
              drug_detailsarray.afternoon = "10";
              drug_detailsarray.evening = "10";
              drug_detailsarray.night = "10";
            }
          }


          // drug_detailsarray.morning = "5";
          // drug_detailsarray.afternoon = "5";
          // drug_detailsarray.evening = "5";
          // drug_detailsarray.night = "5";
        }

        drug_detailsarray.morning_dis = false;
        drug_detailsarray.afternoon_dis = false;
        drug_detailsarray.even_dis = false;
        drug_detailsarray.ngt_dis = false;

        drug_detailsarray.morningID = false;
        this.afterID = false;
        this.eveID = false;

        drug_detailsarray.eve_eventxt = false;
        drug_detailsarray.night_eventxt = false;
        this.daydurationShow = false;

        drug_detailsarray.morning_req = true;
        drug_detailsarray.afternoon_req = true;
        drug_detailsarray.evening_req = true;
        drug_detailsarray.night_req = true;

        drug_detailsarray.mor_lable = false;
        drug_detailsarray.after_eventxt = false;
        drug_detailsarray.quan_lable = true;
        drug_detailsarray.intake_show = false;

        if (this.afterfoodText != "With food") {
          drug_detailsarray.dure_show = false;
        }
        drug_detailsarray.morn_eventxt = false;
      }
    }
    else if (drug_detailsarray.intake_txt == "Four times a day") {
      if (drug_detailsarray.show_intake == "1") {
        this.intakesession = "Four times a day"
        if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
          || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
          || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
          || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
          || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
          || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
          || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
          || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
          drug_detailsarray.morning = "1";
          drug_detailsarray.afternoon = "1";
          drug_detailsarray.evening = "1";
          drug_detailsarray.night = "1";

        } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {



          if (this.clntAge != undefined) {
            var cage = parseInt(this.clntAge);
            if (cage <= 5) {
              drug_detailsarray.morning = "2.5";
              drug_detailsarray.afternoon = "2.5";
              drug_detailsarray.evening = "2.5";
              drug_detailsarray.night = "2.5";

            } else if (cage > 5 && cage <= 13) {
              drug_detailsarray.morning = "5";
              drug_detailsarray.afternoon = "5";
              drug_detailsarray.evening = "5";
              drug_detailsarray.night = "5";

            } else {
              drug_detailsarray.morning = "10";
              drug_detailsarray.afternoon = "10";
              drug_detailsarray.evening = "10";
              drug_detailsarray.night = "10";
            }
          }




          // drug_detailsarray.morning = "5";
          // drug_detailsarray.afternoon = "5";
          // drug_detailsarray.evening = "5";
          // drug_detailsarray.night = "5";
        }

        drug_detailsarray.morning_dis = false;
        drug_detailsarray.afternoon_dis = false;
        drug_detailsarray.even_dis = false;
        drug_detailsarray.ngt_dis = false;

        drug_detailsarray.morningID = false;
        this.afterID = false;
        this.eveID = false;

        drug_detailsarray.eve_eventxt = false;
        drug_detailsarray.night_eventxt = false;
        this.daydurationShow = false;

        drug_detailsarray.morning_req = true;
        drug_detailsarray.afternoon_req = true;
        drug_detailsarray.evening_req = true;
        drug_detailsarray.night_req = true;

        drug_detailsarray.mor_lable = false;
        drug_detailsarray.after_eventxt = false;
        drug_detailsarray.quan_lable = true;
        drug_detailsarray.intake_show = false;

        if (this.afterfoodText != "With food") {
          drug_detailsarray.dure_show = false;
        }
        drug_detailsarray.morn_eventxt = false;
      }
    }
    else if (drug_detailsarray.intake_txt == "Afternoon,Evening & Night") {
      if (drug_detailsarray.show_intake == "1") {
        if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
          || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
          || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
          || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
          || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
          || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
          || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
          || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
          drug_detailsarray.morning = "0";
          drug_detailsarray.afternoon = "1";
          drug_detailsarray.evening = "1";
          drug_detailsarray.night = "1";

        } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {

          if (this.clntAge != undefined) {
            var cage = parseInt(this.clntAge);
            if (cage <= 5) {
              drug_detailsarray.morning = "0";
              drug_detailsarray.afternoon = "2.5";
              drug_detailsarray.evening = "2.5";
              drug_detailsarray.night = "2.5";

            } else if (cage > 5 && cage <= 13) {
              drug_detailsarray.morning = "0";
              drug_detailsarray.afternoon = "5";
              drug_detailsarray.evening = "5";
              drug_detailsarray.night = "5";

            } else {
              drug_detailsarray.morning = "0";
              drug_detailsarray.afternoon = "10";
              drug_detailsarray.evening = "10";
              drug_detailsarray.night = "10";
            }
          }

          // drug_detailsarray.morning = "0";
          // drug_detailsarray.afternoon = "5";
          // drug_detailsarray.evening = "5";
          // drug_detailsarray.night = "5";
        }

        drug_detailsarray.morning_dis = true;
        drug_detailsarray.afternoon_dis = false;
        drug_detailsarray.even_dis = false;
        drug_detailsarray.ngt_dis = false;

        drug_detailsarray.morningID = false;
        this.afterID = false;
        this.eveID = false;

        drug_detailsarray.eve_eventxt = false;
        drug_detailsarray.night_eventxt = false;
        this.daydurationShow = false;

        drug_detailsarray.morning_req = false;
        drug_detailsarray.afternoon_req = true;
        drug_detailsarray.evening_req = true;
        drug_detailsarray.night_req = true;

        drug_detailsarray.mor_lable = false;
        drug_detailsarray.after_eventxt = false;
        drug_detailsarray.quan_lable = true;
        drug_detailsarray.intake_show = false;

        if (this.afterfoodText != "With food") {
          drug_detailsarray.dure_show = false;
        }
        drug_detailsarray.morn_eventxt = false;
      }
    }
    else if (drug_detailsarray.intake_txt == "Evening & Night") {
      if (drug_detailsarray.show_intake == "1") {
        this.intakesession = "Evening & Night"
        this.sessionhide = false;
        this.sessionhrs = true;
        if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
          || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
          || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
          || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
          || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
          || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
          || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
          || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
          drug_detailsarray.morning = "0";
          drug_detailsarray.afternoon = "0";
          drug_detailsarray.evening = "1";
          drug_detailsarray.night = "1";

        } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {


          if (this.clntAge != undefined) {
            var cage = parseInt(this.clntAge);
            if (cage <= 5) {
              drug_detailsarray.morning = "0";
              drug_detailsarray.afternoon = "0";
              drug_detailsarray.evening = "2.5";
              drug_detailsarray.night = "2.5";

            } else if (cage > 5 && cage <= 13) {
              drug_detailsarray.morning = "0";
              drug_detailsarray.afternoon = "0";
              drug_detailsarray.evening = "5";
              drug_detailsarray.night = "5";

            } else {
              drug_detailsarray.morning = "0";
              drug_detailsarray.afternoon = "0";
              drug_detailsarray.evening = "10";
              drug_detailsarray.night = "10";
            }
          }



          // drug_detailsarray.morning = "0";
          // drug_detailsarray.afternoon = "0";
          // drug_detailsarray.evening = "5";
          // drug_detailsarray.night = "5";
        }

        drug_detailsarray.morning_dis = true;
        drug_detailsarray.afternoon_dis = true;
        drug_detailsarray.even_dis = false;
        drug_detailsarray.ngt_dis = false;

        drug_detailsarray.morningID = false;
        this.afterID = false;
        this.eveID = false;

        drug_detailsarray.eve_eventxt = false;
        drug_detailsarray.night_eventxt = false;
        this.daydurationShow = false;

        drug_detailsarray.morning_req = false;
        drug_detailsarray.afternoon_req = false;
        drug_detailsarray.evening_req = true;
        drug_detailsarray.night_req = true;

        drug_detailsarray.mor_lable = false;
        drug_detailsarray.after_eventxt = false;
        drug_detailsarray.quan_lable = true;
        drug_detailsarray.intake_show = false;

        if (this.afterfoodText != "With food") {
          drug_detailsarray.dure_show = false;
        }
        drug_detailsarray.morn_eventxt = false;
      }

    }
    else if ((drug_detailsarray.intake_txt == "Every 6 hours" || drug_detailsarray.intake_txt == "Every 8 hours"
      || drug_detailsarray.intake_txt == "Every 12 hours")) {
      if (drug_detailsarray.show_intake == "1") {
        this.sessionhide = true;
        this.sessionhrs = false;
        if (drug_detailsarray.intake_txt == "Every 6 hours") {
          this.intakesession = "Every 6 hours";
        }
        else if (drug_detailsarray.intake_txt == "Every 8 hours") {
          this.intakesession = "Every 8 hours";
        }
        else if (drug_detailsarray.intake_txt == "Every 12 hours") {
          this.intakesession = "Every 12 hours";
        } else {
          this.intakesession = "";
        }
        if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
          || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
          || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
          || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
          || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
          || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
          || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
          || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
          drug_detailsarray.morning = "1";
          drug_detailsarray.afternoon = "0";
          drug_detailsarray.evening = "0";
          drug_detailsarray.night = "0";

        } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {

          if (this.clntAge != undefined) {
            var cage = parseInt(this.clntAge);
            if (cage <= 5) {
              drug_detailsarray.morning = "2.5";
              drug_detailsarray.afternoon = "0";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "0";

            } else if (cage > 5 && cage <= 13) {
              drug_detailsarray.morning = "5";
              drug_detailsarray.afternoon = "0";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "0";

            } else {
              drug_detailsarray.morning = "10";
              drug_detailsarray.afternoon = "0";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "0";
            }
          }





          // drug_detailsarray.morning = "5";
          // drug_detailsarray.afternoon = "0";
          // drug_detailsarray.evening = "0";
          // drug_detailsarray.night = "0";
        }

        drug_detailsarray.quan_lable = false;
        drug_detailsarray.morning_dis = false;

        drug_detailsarray.afternoon_dis = true;
        drug_detailsarray.even_dis = true;
        drug_detailsarray.ngt_dis = true;


        drug_detailsarray.morningID = true;
        this.afterID = true;
        this.eveID = true;

        drug_detailsarray.eve_eventxt = true;
        drug_detailsarray.night_eventxt = true;
        this.daydurationShow = false;

        drug_detailsarray.mor_lable = true;
        drug_detailsarray.after_eventxt = true;
        drug_detailsarray.morning_req = true;
        drug_detailsarray.intake_show = false;
        if (this.afterfoodText != "With food") {
          drug_detailsarray.dure_show = false;
        }
        drug_detailsarray.morn_eventxt = false;
      }
    }
    else if (drug_detailsarray.intake_txt == "Stat") {
      this.intakesession = "stat";
      this.sessionhide = true;
      this.sessionhrs = false;
      if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
        || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
        || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
        || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
        || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
        || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
        || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
        || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
        drug_detailsarray.morning = "1";
        drug_detailsarray.afternoon = "0";
        drug_detailsarray.evening = "0";
        drug_detailsarray.night = "0";

      } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {

        if (this.clntAge != undefined) {
          var cage = parseInt(this.clntAge);
          if (cage <= 5) {
            drug_detailsarray.morning = "2.5";
            drug_detailsarray.afternoon = "0";
            drug_detailsarray.evening = "0";
            drug_detailsarray.night = "0";

          } else if (cage > 5 && cage <= 13) {
            drug_detailsarray.morning = "5";
            drug_detailsarray.afternoon = "0";
            drug_detailsarray.evening = "0";
            drug_detailsarray.night = "0";

          } else {
            drug_detailsarray.morning = "10";
            drug_detailsarray.afternoon = "0";
            drug_detailsarray.evening = "0";
            drug_detailsarray.night = "0";
          }
        }

        // drug_detailsarray.morning = "5";
        // drug_detailsarray.afternoon = "0";
        // drug_detailsarray.evening = "0";
        // drug_detailsarray.night = "0";
      }

      drug_detailsarray.quan_lable = false;

      drug_detailsarray.morning_dis = false;
      drug_detailsarray.afternoon_dis = true;
      drug_detailsarray.even_dis = true;
      drug_detailsarray.ngt_dis = true;

      drug_detailsarray.morningID = true;
      this.afterID = true;
      this.eveID = true;
      drug_detailsarray.intake_show = true;
      drug_detailsarray.dure_show = true;

      drug_detailsarray.eve_eventxt = true;
      drug_detailsarray.night_eventxt = true;
      this.daydurationShow = true;

      drug_detailsarray.mor_lable = true;
      drug_detailsarray.after_eventxt = true;
      drug_detailsarray.morning_req = true;
      drug_detailsarray.morn_eventxt = false;

    }
    else if (drug_detailsarray.intake_txt == "Once a day") {
      this.intakesession = "Once a day"
      this.sessionhide = true;
      this.sessionhrs = false;
      if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
        || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
        || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
        || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
        || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
        || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
        || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
        || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
        drug_detailsarray.morning = "1";
        drug_detailsarray.afternoon = "0";
        drug_detailsarray.evening = "0";
        drug_detailsarray.night = "0";

      } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {


        if (this.clntAge != undefined) {
          var cage = parseInt(this.clntAge);
          if (cage <= 5) {
            drug_detailsarray.morning = "2.5";
            drug_detailsarray.afternoon = "0";
            drug_detailsarray.evening = "0";
            drug_detailsarray.night = "0";

          } else if (cage > 5 && cage <= 13) {
            drug_detailsarray.morning = "5";
            drug_detailsarray.afternoon = "0";
            drug_detailsarray.evening = "0";
            drug_detailsarray.night = "0";

          } else {
            drug_detailsarray.morning = "10";
            drug_detailsarray.afternoon = "0";
            drug_detailsarray.evening = "0";
            drug_detailsarray.night = "0";
          }
        }


        // drug_detailsarray.morning = "5";
        // drug_detailsarray.afternoon = "0";
        // drug_detailsarray.evening = "0";
        // drug_detailsarray.night = "0";
      }

      drug_detailsarray.quan_lable = false;

      drug_detailsarray.morning_dis = false;
      drug_detailsarray.afternoon_dis = true;
      drug_detailsarray.even_dis = true;
      drug_detailsarray.ngt_dis = true;

      drug_detailsarray.morningID = true;
      this.afterID = true;
      this.eveID = true;

      drug_detailsarray.eve_eventxt = true;
      drug_detailsarray.night_eventxt = true;
      this.daydurationShow = false;
      drug_detailsarray.mor_lable = true;
      drug_detailsarray.after_eventxt = true;
      drug_detailsarray.morning_req = true;
      drug_detailsarray.intake_show = false;
      if (this.afterfoodText != "With food") {
        drug_detailsarray.dure_show = false;
      }
      drug_detailsarray.morn_eventxt = false;

    }
    else if (drug_detailsarray.intake_txt == "SOS") {
      this.intakesession = "SOS"
      this.sessionhide = true;
      this.sessionhrs = false;
      if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
        || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
        || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
        || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
        || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
        || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
        || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
        || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
        drug_detailsarray.morning = "1";
        drug_detailsarray.afternoon = "0";
        drug_detailsarray.evening = "0";
        drug_detailsarray.night = "0";

      } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {

        if (this.clntAge != undefined) {
          var cage = parseInt(this.clntAge);
          if (cage <= 5) {
            drug_detailsarray.morning = "2.5";
            drug_detailsarray.afternoon = "0";
            drug_detailsarray.evening = "0";
            drug_detailsarray.night = "0";

          } else if (cage > 5 && cage <= 13) {
            drug_detailsarray.morning = "5";
            drug_detailsarray.afternoon = "0";
            drug_detailsarray.evening = "0";
            drug_detailsarray.night = "0";

          } else {
            drug_detailsarray.morning = "10";
            drug_detailsarray.afternoon = "0";
            drug_detailsarray.evening = "0";
            drug_detailsarray.night = "0";
          }
        }


        // drug_detailsarray.morning = "5";
        // drug_detailsarray.afternoon = "0";
        // drug_detailsarray.evening = "0";
        // drug_detailsarray.night = "0";


      } else {
        drug_detailsarray.morning = "0";
        drug_detailsarray.afternoon = "0";
        drug_detailsarray.evening = "0";
        drug_detailsarray.night = "0";
      }

      drug_detailsarray.quan_lable = false;

      drug_detailsarray.morningID = true;
      this.afterID = true;
      this.eveID = true;

      drug_detailsarray.eve_eventxt = true;
      drug_detailsarray.night_eventxt = true;
      this.daydurationShow = false;

      drug_detailsarray.mor_lable = true;
      drug_detailsarray.after_eventxt = true;
      drug_detailsarray.morning_req = true;
      drug_detailsarray.intake_show = false;

      drug_detailsarray.morning_dis = false;
      drug_detailsarray.afternoon_dis = true;
      drug_detailsarray.even_dis = true;
      drug_detailsarray.ngt_dis = true;

      if (this.afterfoodText != "With food") {
        drug_detailsarray.dure_show = false;
      }
      drug_detailsarray.morn_eventxt = false;

    }
    else if (drug_detailsarray.intake_txt == "Alternative day" || drug_detailsarray.intake_txt == "Once a week"
      || drug_detailsarray.intake_txt == "Once in two weeks" || drug_detailsarray.intake_txt == "Twice a week" || drug_detailsarray.intake_txt == "Once a month") {


      this.alternatsession = false;
      this.sessionhide = false;
      this.sessionhrs = true;
      if (drug_detailsarray.intake_txt == "Alternative day") {
        this.intakesession = "Alternative day";
      }
      else if (drug_detailsarray.intake_txt == "Once a week") {
        this.intakesession = "Once a week";
      }
      else if (drug_detailsarray.intake_txt == "Once in two weeks") {
        this.intakesession = "Once in two weeks";
      }
      else if (drug_detailsarray.intake_txt == "Once a month") {
        this.intakesession = "Once a month";
      } else if (drug_detailsarray.intake_txt == "Twice a week") {
        this.intakesession = "Twice a week";
      }
      else {
        this.intakesession = "";
      }
      if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
        || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
        || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
        || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
        || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
        || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
        || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
        || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
        drug_detailsarray.morning = "0";
        drug_detailsarray.afternoon = "0";
        drug_detailsarray.evening = "0";
        drug_detailsarray.night = "0";

      } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {

        if (this.clntAge != undefined) {
          var cage = parseInt(this.clntAge);
          if (cage <= 5) {
            drug_detailsarray.morning = "2.5";
            drug_detailsarray.afternoon = "0";
            drug_detailsarray.evening = "2.5";
            drug_detailsarray.night = "2.5";

          } else if (cage > 5 && cage <= 13) {
            drug_detailsarray.morning = "5";
            drug_detailsarray.afternoon = "5";
            drug_detailsarray.evening = "0";
            drug_detailsarray.night = "5";

          } else {
            drug_detailsarray.morning = "10";
            drug_detailsarray.afternoon = "10";
            drug_detailsarray.evening = "0";
            drug_detailsarray.night = "10";
          }
        }
        // drug_detailsarray.morning = "5";
        //  drug_detailsarray.afternoon = "5";
        //  drug_detailsarray.evening = "0";
        // drug_detailsarray.night = "5";
      }
      drug_detailsarray.drug_detailsarray = false;

      drug_detailsarray.morningID = false;
      drug_detailsarray.mor_lable = false;
      this.afterID = false;
      this.eveID = false;

      drug_detailsarray.eve_eventxt = false;
      drug_detailsarray.night_eventxt = false;
      this.daydurationShow = false;

      drug_detailsarray.morning_dis = false;
      drug_detailsarray.afternoon_dis = false;
      drug_detailsarray.even_dis = false;
      drug_detailsarray.ngt_dis = false;

      drug_detailsarray.after_eventxt = false;
      drug_detailsarray.quan_lable = true;
      drug_detailsarray.evening_req = true;
      drug_detailsarray.afternoon_req = true;
      drug_detailsarray.morning_req = true;
      drug_detailsarray.intake_show = false;
      if (this.afterfoodText != "With food") {
        drug_detailsarray.dure_show = false;
      }
      drug_detailsarray.morn_eventxt = false;
    }
    this.sessionqty(drug_detailsarray)
  }

  // sesssionBaseChange(drug_detailsarray) {
  //   if (drug_detailsarray.show_intake == undefined) {
  //     this.toastr.error(Message_data.selectMedicine);
  //   }
  //   if (drug_detailsarray.intake_txt == "Morning only" && drug_detailsarray.show_intake == "1") {
  //     if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
  //       || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
  //       || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
  //       || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
  //       || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
  //       || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
  //       || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
  //       || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
  //         drug_detailsarray.mor = "1";
  //         drug_detailsarray.aft = "0";
  //         drug_detailsarray.eve = "0";
  //         drug_detailsarray.ngt = "0";

  //     } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {
  //           drug_detailsarray.mor = "5";
  //           drug_detailsarray.aft = "0";
  //           drug_detailsarray.eve = "0";
  //           drug_detailsarray.ngt = "0";
  //     }

  //     drug_detailsarray.morning_dis = false;
  //     drug_detailsarray.afternoon_dis = true;
  //     drug_detailsarray.even_dis = true;
  //     drug_detailsarray.ngt_dis = true;

  //     drug_detailsarray.drug_detailsarray = true;

  //     this.morID = false;
  //     this.afterID = false;
  //     this.eveID = true;

  //     drug_detailsarray.eve_eventxt = true;
  //     drug_detailsarray.night_eventxt = false;
  //     this.daydurationShow = false;

  //     drug_detailsarray.drug_detailsarray = true;
  //     drug_detailsarray.even_dis = true;
  //     drug_detailsarray.ngt_dis = true;

  //     drug_detailsarray.mor_lable = false;
  //     drug_detailsarray.after_eventxt = false;
  //     drug_detailsarray.quan_lable = true;

  //     drug_detailsarray.morning_req = true;
  //     drug_detailsarray.afternoon_req = false;
  //     drug_detailsarray.evening_req = false;
  //     drug_detailsarray.night_req = false;

  //     drug_detailsarray.intake_show = false;
  //     if (this.afterfoodText != "With food") {
  //       drug_detailsarray.dure_show = false;
  //     }
  //     drug_detailsarray.morn_eventxt = false;
  //   } else if (drug_detailsarray.intake_txt == "Afternoon only" && drug_detailsarray.show_intake == "1") {
  //     if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
  //       || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
  //       || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
  //       || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
  //       || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
  //       || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
  //       || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
  //       || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
  //         drug_detailsarray.mor = "0";
  //         drug_detailsarray.aft = "1";
  //         drug_detailsarray.eve = "0";
  //         drug_detailsarray.ngt = "0";

  //     } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {
  //           drug_detailsarray.mor = "0";
  //           drug_detailsarray.aft = "5";
  //           drug_detailsarray.eve = "0";
  //           drug_detailsarray.ngt = "0";
  //     }

  //     drug_detailsarray.afternoon_dis = false;
  //     drug_detailsarray.morning_dis = true;
  //     drug_detailsarray.even_dis = true;
  //     drug_detailsarray.ngt_dis = true;

  //     this.morID = false;
  //     this.afterID = false;
  //     this.eveID = true;

  //     drug_detailsarray.eve_eventxt = true;
  //     drug_detailsarray.night_eventxt = false;
  //     this.daydurationShow = false;

  //     drug_detailsarray.mor_lable = false;
  //     drug_detailsarray.after_eventxt = false;
  //     drug_detailsarray.quan_lable = true;

  //     drug_detailsarray.morning_req = false;
  //     drug_detailsarray.afternoon_req = true;
  //     drug_detailsarray.evening_req = false;
  //     drug_detailsarray.night_req = true;

  //     drug_detailsarray.intake_show = false;
  //     if (this.afterfoodText != "With food") {
  //       drug_detailsarray.dure_show = false;
  //     }
  //     drug_detailsarray.morn_eventxt = false;

  //   } else if (drug_detailsarray.intake_txt == "Evening only" && drug_detailsarray.show_intake == "1") {
  //     if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
  //       || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
  //       || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
  //       || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
  //       || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
  //       || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
  //       || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
  //       || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
  //         drug_detailsarray.mor = "0";
  //         drug_detailsarray.aft = "0";
  //         drug_detailsarray.eve = "1";
  //         drug_detailsarray.ngt = "0";

  //     } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {
  //           drug_detailsarray.mor = "0";
  //           drug_detailsarray.aft = "0";
  //           drug_detailsarray.eve = "5";
  //           drug_detailsarray.ngt = "0";
  //     }

  //     drug_detailsarray.morning_dis = true;
  //     drug_detailsarray.afternoon_dis = true;
  //     drug_detailsarray.even_dis = false;
  //     drug_detailsarray.ngt_dis = true;

  //     this.morID = false;
  //     this.afterID = false;
  //     this.eveID = true;

  //     drug_detailsarray.eve_eventxt = false;
  //     drug_detailsarray.night_eventxt = true;
  //     this.daydurationShow = false;

  //     drug_detailsarray.mor_lable = false;
  //     drug_detailsarray.after_eventxt = false;
  //     drug_detailsarray.quan_lable = true;

  //     drug_detailsarray.morning_req = false;
  //     drug_detailsarray.afternoon_req = false;
  //     drug_detailsarray.evening_req = true;
  //     drug_detailsarray.night_req = false;

  //     drug_detailsarray.intake_show = false;
  //     if (this.afterfoodText != "With food") {
  //       drug_detailsarray.dure_show = false;
  //     }
  //     drug_detailsarray.morn_eventxt = false;

  //   } else if (drug_detailsarray.intake_txt == "Night only" && drug_detailsarray.show_intake == "1") {
  //     if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
  //       || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
  //       || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
  //       || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
  //       || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
  //       || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
  //       || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
  //       || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
  //         drug_detailsarray.mor = "0";
  //         drug_detailsarray.aft = "0";
  //         drug_detailsarray.eve = "0";
  //         drug_detailsarray.ngt = "1";

  //     } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {
  //           drug_detailsarray.mor = "0";
  //           drug_detailsarray.aft = "0";
  //           drug_detailsarray.eve = "0";
  //           drug_detailsarray.ngt = "5";
  //     }

  //     drug_detailsarray.afternoon_dis = true;
  //     drug_detailsarray.morning_dis = true;
  //     drug_detailsarray.even_dis = true;
  //     drug_detailsarray.ngt_dis = false;

  //     this.morID = false;
  //     this.afterID = false;
  //     this.eveID = true;

  //     drug_detailsarray.eve_eventxt = true;
  //     drug_detailsarray.night_eventxt = false;
  //     this.daydurationShow = false;

  //     drug_detailsarray.morning_req = false;
  //     drug_detailsarray.afternoon_req = false;
  //     drug_detailsarray.evening_req = false;
  //     drug_detailsarray.night_req = true;

  //     drug_detailsarray.mor_lable = false;
  //     drug_detailsarray.after_eventxt = false;
  //     drug_detailsarray.quan_lable = true;
  //     drug_detailsarray.intake_show = false;

  //     if (this.afterfoodText != "With food") {
  //       drug_detailsarray.dure_show = false;
  //     }
  //     drug_detailsarray.morn_eventxt = false;

  //   } else if (drug_detailsarray.intake_txt == "Morning & Afternoon" && drug_detailsarray.show_intake == "1") {
  //     if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
  //       || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
  //       || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
  //       || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
  //       || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
  //       || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
  //       || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
  //       || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
  //         drug_detailsarray.mor = "1";
  //         drug_detailsarray.aft = "1";
  //         drug_detailsarray.eve = "0";
  //         drug_detailsarray.ngt = "0";

  //     } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {
  //           drug_detailsarray.mor = "5";
  //           drug_detailsarray.aft = "5";
  //           drug_detailsarray.eve = "0";
  //           drug_detailsarray.ngt = "0";
  //     }

  //     drug_detailsarray.morning_dis = false;
  //     drug_detailsarray.afternoon_dis = false;
  //     drug_detailsarray.even_dis = true;
  //     drug_detailsarray.ngt_dis = true;

  //     this.morID = false;
  //     this.afterID = false;
  //     this.eveID = true;

  //     drug_detailsarray.eve_eventxt = true;
  //     drug_detailsarray.night_eventxt = false;
  //     this.daydurationShow = false;

  //     drug_detailsarray.morning_req = true;
  //     drug_detailsarray.afternoon_req = true;
  //     drug_detailsarray.evening_req = false;
  //     drug_detailsarray.night_req = false;

  //     drug_detailsarray.mor_lable = false;
  //     drug_detailsarray.after_eventxt = false;
  //     drug_detailsarray.quan_lable = true;
  //     drug_detailsarray.intake_show = false;

  //     if (this.afterfoodText != "With food") {
  //       drug_detailsarray.dure_show = false;
  //     }
  //     drug_detailsarray.morn_eventxt = false;

  //   } else if (drug_detailsarray.intake_txt == "Morning & Evening" && drug_detailsarray.show_intake == "1") {
  //     if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
  //       || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
  //       || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
  //       || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
  //       || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
  //       || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
  //       || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
  //       || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
  //         drug_detailsarray.mor = "1";
  //         drug_detailsarray.aft = "0";
  //         drug_detailsarray.eve = "1";
  //         drug_detailsarray.ngt = "0";

  //     } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {
  //           drug_detailsarray.mor = "5";
  //           drug_detailsarray.aft = "0";
  //           drug_detailsarray.eve = "5";
  //           drug_detailsarray.ngt = "0";
  //     }

  //     drug_detailsarray.morning_dis = false;
  //     drug_detailsarray.afternoon_dis = true;
  //     drug_detailsarray.even_dis = false;
  //     drug_detailsarray.ngt_dis = true;

  //     this.morID = false;
  //     this.afterID = false;
  //     this.eveID = true;

  //     drug_detailsarray.eve_eventxt = false;
  //     drug_detailsarray.night_eventxt = true;
  //     this.daydurationShow = false;

  //     drug_detailsarray.morning_req = true;
  //     drug_detailsarray.afternoon_req = false;
  //     drug_detailsarray.evening_req = true;
  //     drug_detailsarray.night_req = false;

  //     drug_detailsarray.mor_lable = false;
  //     drug_detailsarray.after_eventxt = false;
  //     drug_detailsarray.quan_lable = true;
  //     drug_detailsarray.intake_show = false;

  //     if (this.afterfoodText != "With food") {
  //       drug_detailsarray.dure_show = false;
  //     }
  //     drug_detailsarray.morn_eventxt = false;

  //   } else if (drug_detailsarray.intake_txt == "Morning & Night" && drug_detailsarray.show_intake == "1") {
  //     if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
  //       || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
  //       || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
  //       || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
  //       || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
  //       || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
  //       || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
  //       || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
  //         drug_detailsarray.mor = "1";
  //         drug_detailsarray.aft = "0";
  //       drug_detailsarray.eve = "0";
  //       drug_detailsarray.ngt = "1";

  //     } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {
  //           drug_detailsarray.mor = "5";
  //           drug_detailsarray.aft = "0";
  //           drug_detailsarray.eve = "0";
  //           drug_detailsarray.ngt = "5";
  //     }

  //     drug_detailsarray.morning_dis = false;
  //     drug_detailsarray.afternoon_dis = true;
  //     drug_detailsarray.even_dis = true;
  //     drug_detailsarray.ngt_dis = false;

  //     this.morID = false;
  //     this.afterID = false;
  //     this.eveID = true;

  //     drug_detailsarray.eve_eventxt = true;
  //     drug_detailsarray.night_eventxt = false;
  //     this.daydurationShow = false;

  //     drug_detailsarray.morning_req = true;
  //     drug_detailsarray.afternoon_req = false;
  //     drug_detailsarray.evening_req = false;
  //     drug_detailsarray.night_req = true;

  //     drug_detailsarray.mor_lable = false;
  //     drug_detailsarray.after_eventxt = false;
  //     drug_detailsarray.quan_lable = true;
  //     drug_detailsarray.intake_show = false;

  //     if (this.afterfoodText != "With food") {
  //       drug_detailsarray.dure_show = false;
  //     }
  //     drug_detailsarray.morn_eventxt = false;

  //   } else if (drug_detailsarray.intake_txt == "Afternoon & Night" && drug_detailsarray.show_intake == "1") {
  //     if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
  //       || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
  //       || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
  //       || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
  //       || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
  //       || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
  //       || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
  //       || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
  //         drug_detailsarray.mor = "0";
  //         drug_detailsarray.aft = "1";
  //         drug_detailsarray.eve = "0";
  //         drug_detailsarray.ngt = "1";
  //     } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {
  //           drug_detailsarray.mor = "0";
  //           drug_detailsarray.aft = "5";
  //           drug_detailsarray.eve = "0";
  //           drug_detailsarray.ngt = "5";
  //     }

  //     drug_detailsarray.morning_dis = true;
  //     drug_detailsarray.afternoon_dis = false;
  //     drug_detailsarray.even_dis = true;
  //     drug_detailsarray.ngt_dis = false;

  //     this.morID = false;
  //     this.afterID = false;
  //     this.eveID = true;

  //     drug_detailsarray.eve_eventxt = true;
  //     drug_detailsarray.night_eventxt = false;
  //     this.daydurationShow = false;

  //     drug_detailsarray.morning_req = false;
  //     drug_detailsarray.afternoon_req = true;
  //     drug_detailsarray.evening_req = false;
  //     drug_detailsarray.night_req = true;

  //     drug_detailsarray.mor_lable = false;
  //     drug_detailsarray.after_eventxt = false;
  //     drug_detailsarray.quan_lable = true;
  //     drug_detailsarray.intake_show = false;

  //     if (this.afterfoodText != "With food") {
  //       drug_detailsarray.dure_show = false;
  //     }
  //     drug_detailsarray.morn_eventxt = false;

  //   } else if (drug_detailsarray.intake_txt == "Afternoon & Evening" && drug_detailsarray.show_intake == "1") {
  //     if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
  //       || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
  //       || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
  //       || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
  //       || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
  //       || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
  //       || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
  //       || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
  //       drug_detailsarray.mor = "0";
  //       drug_detailsarray.aft = "1";
  //       drug_detailsarray.eve = "1";
  //       drug_detailsarray.ngt = "0";

  //     } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {
  //           drug_detailsarray.mor = "0";
  //           drug_detailsarray.aft = "5";
  //           drug_detailsarray.eve = "5";
  //           drug_detailsarray.ngt = "";
  //     }

  //     drug_detailsarray.morning_dis = true;
  //     drug_detailsarray.afternoon_dis = false;
  //     drug_detailsarray.even_dis = false;
  //     drug_detailsarray.ngt_dis = true;

  //     this.morID = false;
  //     this.afterID = false;
  //     this.eveID = true;

  //     drug_detailsarray.eve_eventxt = false;
  //     drug_detailsarray.night_eventxt = true;
  //     this.daydurationShow = false;

  //     drug_detailsarray.morning_req = false;
  //     drug_detailsarray.afternoon_req = true;
  //     drug_detailsarray.evening_req = true;
  //     drug_detailsarray.night_req = false;

  //     drug_detailsarray.mor_lable = false;
  //     drug_detailsarray.after_eventxt = false;
  //     drug_detailsarray.quan_lable = true;
  //     drug_detailsarray.intake_show = false;

  //     if (this.afterfoodText != "With food") {
  //       drug_detailsarray.dure_show = false;
  //     }
  //     drug_detailsarray.morn_eventxt = false;

  //   } else if (drug_detailsarray.intake_txt == "Morning,Afternoon & Evening" && drug_detailsarray.show_intake == "1") {
  //     if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
  //       || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
  //       || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
  //       || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
  //       || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
  //       || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
  //       || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
  //       || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
  //         drug_detailsarray.mor = "1";
  //         drug_detailsarray.aft = "1";
  //         drug_detailsarray.eve = "1";
  //         drug_detailsarray.ngt = "0";

  //     } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {
  //           drug_detailsarray.mor = "5";
  //           drug_detailsarray.aft = "5";
  //           drug_detailsarray.eve = "5";
  //           drug_detailsarray.ngt = "";
  //     }

  //     drug_detailsarray.morning_dis = false;
  //     drug_detailsarray.afternoon_dis = false;
  //     drug_detailsarray.even_dis = false;
  //     drug_detailsarray.ngt_dis = true;

  //     this.morID = false;
  //     this.afterID = false;
  //     this.eveID = true;

  //     drug_detailsarray.eve_eventxt = false;
  //     drug_detailsarray.night_eventxt = true;
  //     this.daydurationShow = false;

  //     drug_detailsarray.morning_req = true;
  //     drug_detailsarray.afternoon_req = true;
  //     drug_detailsarray.evening_req = true;
  //     drug_detailsarray.night_req = false;

  //     drug_detailsarray.mor_lable = false;
  //     drug_detailsarray.after_eventxt = false;
  //     drug_detailsarray.quan_lable = true;
  //     drug_detailsarray.intake_show = false;

  //     if (this.afterfoodText != "With food") {
  //       drug_detailsarray.dure_show = false;
  //     }
  //     drug_detailsarray.morn_eventxt = false;

  //   } else if (drug_detailsarray.intake_txt == "Thrice a day" && drug_detailsarray.show_intake == "1") {
  //     if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
  //       || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
  //       || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
  //       || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
  //       || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
  //       || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
  //       || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
  //       || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
  //         drug_detailsarray.mor = "1";
  //         drug_detailsarray.aft = "1";
  //         drug_detailsarray.eve = "0";
  //         drug_detailsarray.ngt = "1";

  //     } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {
  //           drug_detailsarray.mor = "5";
  //           drug_detailsarray.aft = "5";
  //           drug_detailsarray.eve = "0";
  //           drug_detailsarray.ngt = "5";
  //     }

  //     drug_detailsarray.morning_dis = false;
  //     drug_detailsarray.afternoon_dis = false;
  //     drug_detailsarray.even_dis = true;
  //     drug_detailsarray.ngt_dis = false;

  //     this.morID = false;
  //     this.afterID = false;
  //     this.eveID = true;

  //     drug_detailsarray.eve_eventxt = true;
  //     drug_detailsarray.night_eventxt = false;
  //     this.daydurationShow = false;

  //     drug_detailsarray.morning_req = true;
  //     drug_detailsarray.afternoon_req = true;
  //     drug_detailsarray.evening_req = false;
  //     drug_detailsarray.night_req = true;

  //     drug_detailsarray.mor_lable = false;
  //     drug_detailsarray.after_eventxt = false;
  //     drug_detailsarray.quan_lable = true;
  //     drug_detailsarray.intake_show = false;

  //     if (this.afterfoodText != "With food") {
  //       drug_detailsarray.dure_show = false;
  //     }
  //     drug_detailsarray.morn_eventxt = false;


  //   } else if (drug_detailsarray.intake_txt == "Morning,Afternoon & Night" && drug_detailsarray.show_intake == "1") {
  //     if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
  //       || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
  //       || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
  //       || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
  //       || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
  //       || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
  //       || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
  //       || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
  //         drug_detailsarray.mor = "1";
  //         drug_detailsarray.aft = "1";
  //         drug_detailsarray.eve = "0";
  //         drug_detailsarray.ngt = "1";

  //     } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {
  //           drug_detailsarray.mor = "5";
  //           drug_detailsarray.aft = "5";
  //           drug_detailsarray.eve = "0";
  //           drug_detailsarray.ngt = "5";
  //     }

  //     drug_detailsarray.morning_dis = false;
  //     drug_detailsarray.afternoon_dis = false;
  //     drug_detailsarray.even_dis = true;
  //     drug_detailsarray.ngt_dis = false;

  //     this.morID = false;
  //     this.afterID = false;
  //     this.eveID = true;

  //     drug_detailsarray.eve_eventxt = true;
  //     drug_detailsarray.night_eventxt = false;
  //     this.daydurationShow = false;

  //     drug_detailsarray.morning_req = true;
  //     drug_detailsarray.afternoon_req = true;
  //     drug_detailsarray.evening_req = false;
  //     drug_detailsarray.night_req = true;

  //     drug_detailsarray.mor_lable = false;
  //     drug_detailsarray.after_eventxt = false;
  //     drug_detailsarray.quan_lable = true;
  //     drug_detailsarray.intake_show = false;

  //     if (this.afterfoodText != "With food") {
  //       drug_detailsarray.dure_show = false;
  //     }
  //     drug_detailsarray.morn_eventxt = false;

  //   } else if (drug_detailsarray.intake_txt == "Morning,Afternoon,Evening & Night" && drug_detailsarray.show_intake == "1") {
  //     if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
  //       || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
  //       || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
  //       || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
  //       || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
  //       || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
  //       || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
  //       || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
  //         drug_detailsarray.mor = "1";
  //         drug_detailsarray.aft = "1";
  //         drug_detailsarray.eve = "1";
  //         drug_detailsarray.ngt = "1";

  //     } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {
  //           drug_detailsarray.mor = "5";
  //           drug_detailsarray.aft = "5";
  //           drug_detailsarray.eve = "5";
  //           drug_detailsarray.ngt = "5";
  //     }

  //     drug_detailsarray.morning_dis = false;
  //     drug_detailsarray.afternoon_dis = false;
  //     drug_detailsarray.even_dis = false;
  //     drug_detailsarray.ngt_dis = false;

  //     this.morID = false;
  //     this.afterID = false;
  //     this.eveID = false;

  //     drug_detailsarray.eve_eventxt = false;
  //     drug_detailsarray.night_eventxt = false;
  //     this.daydurationShow = false;

  //     drug_detailsarray.morning_req = true;
  //     drug_detailsarray.afternoon_req = true;
  //     drug_detailsarray.evening_req = true;
  //     drug_detailsarray.night_req = true;

  //     drug_detailsarray.mor_lable = false;
  //     drug_detailsarray.after_eventxt = false;
  //     drug_detailsarray.quan_lable = true;
  //     drug_detailsarray.intake_show = false;

  //     if (this.afterfoodText != "With food") {
  //       drug_detailsarray.dure_show = false;
  //     }
  //     drug_detailsarray.morn_eventxt = false;

  //   } else if (drug_detailsarray.intake_txt == "Four times a day" && drug_detailsarray.show_intake == "1") {
  //     if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
  //       || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
  //       || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
  //       || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
  //       || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
  //       || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
  //       || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
  //       || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
  //         drug_detailsarray.mor = "1";
  //         drug_detailsarray.aft = "1";
  //         drug_detailsarray.eve = "1";
  //         drug_detailsarray.ngt = "1";

  //     } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {
  //           drug_detailsarray.mor = "5";
  //           drug_detailsarray.aft = "5";
  //           drug_detailsarray.eve = "5";
  //           drug_detailsarray.ngt = "5";
  //     }

  //     drug_detailsarray.morning_dis = false;
  //     drug_detailsarray.afternoon_dis = false;
  //     drug_detailsarray.even_dis = false;
  //     drug_detailsarray.ngt_dis = false;

  //     this.morID = false;
  //     this.afterID = false;
  //     this.eveID = false;

  //     drug_detailsarray.eve_eventxt = false;
  //     drug_detailsarray.night_eventxt = false;
  //     this.daydurationShow = false;

  //     drug_detailsarray.morning_req = true;
  //     drug_detailsarray.afternoon_req = true;
  //     drug_detailsarray.evening_req = true;
  //     drug_detailsarray.night_req = true;

  //     drug_detailsarray.mor_lable = false;
  //     drug_detailsarray.after_eventxt = false;
  //     drug_detailsarray.quan_lable = true;
  //     drug_detailsarray.intake_show = false;

  //     if (this.afterfoodText != "With food") {
  //       drug_detailsarray.dure_show = false;
  //     }
  //     drug_detailsarray.morn_eventxt = false;

  //   } else if (drug_detailsarray.intake_txt == "Afternoon,Evening & Night" && drug_detailsarray.show_intake == "1") {
  //     if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
  //       || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
  //       || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
  //       || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
  //       || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
  //       || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
  //       || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
  //       || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
  //         drug_detailsarray.mor = "0";
  //         drug_detailsarray.aft = "1";
  //         drug_detailsarray.eve = "1";
  //         drug_detailsarray.ngt = "1";

  //     } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {
  //           drug_detailsarray.mor = "0";
  //           drug_detailsarray.aft = "5";
  //           drug_detailsarray.eve = "5";
  //           drug_detailsarray.ngt = "5";
  //     }

  //     drug_detailsarray.morning_dis = true;
  //     drug_detailsarray.afternoon_dis = false;
  //     drug_detailsarray.even_dis = false;
  //     drug_detailsarray.ngt_dis = false;

  //     this.morID = false;
  //     this.afterID = false;
  //     this.eveID = false;

  //     drug_detailsarray.eve_eventxt = false;
  //     drug_detailsarray.night_eventxt = false;
  //     this.daydurationShow = false;

  //     drug_detailsarray.morning_req = false;
  //     drug_detailsarray.afternoon_req = true;
  //     drug_detailsarray.evening_req = true;
  //     drug_detailsarray.night_req = true;

  //     drug_detailsarray.mor_lable = false;
  //     drug_detailsarray.after_eventxt = false;
  //     drug_detailsarray.quan_lable = true;
  //     drug_detailsarray.intake_show = false;

  //     if (this.afterfoodText != "With food") {
  //       drug_detailsarray.dure_show = false;
  //     }
  //     drug_detailsarray.morn_eventxt = false;

  //   } else if (drug_detailsarray.intake_txt == "Evening & Night" && drug_detailsarray.show_intake == "1") {
  //     if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
  //       || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
  //       || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
  //       || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
  //       || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
  //       || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
  //       || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
  //       || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
  //         drug_detailsarray.mor = "0";
  //       drug_detailsarray.aft = "0";
  //       drug_detailsarray.eve = "1";
  //       drug_detailsarray.ngt = "1";

  //     } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {
  //           drug_detailsarray.mor = "0";
  //           drug_detailsarray.aft = "0";
  //           drug_detailsarray.eve = "5";
  //           drug_detailsarray.ngt = "5";
  //     }

  //     drug_detailsarray.morning_dis = true;
  //     drug_detailsarray.afternoon_dis = true;
  //     drug_detailsarray.even_dis = false;
  //     drug_detailsarray.ngt_dis = false;

  //     this.morID = false;
  //     this.afterID = false;
  //     this.eveID = false;

  //     drug_detailsarray.eve_eventxt = false;
  //     drug_detailsarray.night_eventxt = false;
  //     this.daydurationShow = false;

  //     drug_detailsarray.morning_req = false;
  //     drug_detailsarray.afternoon_req = false;
  //     drug_detailsarray.evening_req = true;
  //     drug_detailsarray.night_req = true;

  //     drug_detailsarray.mor_lable = false;
  //     drug_detailsarray.after_eventxt = false;
  //     drug_detailsarray.quan_lable = true;
  //     drug_detailsarray.intake_show = false;

  //     if (this.afterfoodText != "With food") {
  //       drug_detailsarray.dure_show = false;
  //     }
  //     drug_detailsarray.morn_eventxt = false;

  //   } else if ((drug_detailsarray.intake_txt == "Every 6 hours" || drug_detailsarray.intake_txt == "Every 8 hours"
  //     || drug_detailsarray.intake_txt == "Every 12 hours") && drug_detailsarray.show_intake == "1") {
  //     if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
  //       || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
  //       || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
  //       || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
  //       || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
  //       || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
  //       || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
  //       || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
  //       drug_detailsarray.mor = "1";
  //       drug_detailsarray.aft = "0";
  //       drug_detailsarray.eve = "0";
  //       drug_detailsarray.ngt = "0";

  //     } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {
  //           drug_detailsarray.mor = "5";
  //           drug_detailsarray.aft = "0";
  //           drug_detailsarray.eve = "0";
  //           drug_detailsarray.ngt = "0";
  //     }

  //     drug_detailsarray.quan_lable = false;
  //     drug_detailsarray.morning_dis = false;

  //     drug_detailsarray.afternoon_dis = true; 
  //     drug_detailsarray.even_dis = true; 
  //     drug_detailsarray.ngt_dis = true; 


  //     this.morID = true;
  //     this.afterID = true;
  //     this.eveID = true;

  //     drug_detailsarray.eve_eventxt = true;
  //     drug_detailsarray.night_eventxt = true;
  //     this.daydurationShow = false;

  //     drug_detailsarray.mor_lable = true;
  //     drug_detailsarray.after_eventxt = true;
  //     drug_detailsarray.morning_req = true;
  //     drug_detailsarray.intake_show = false;
  //     if (this.afterfoodText != "With food") {
  //       drug_detailsarray.dure_show = false;
  //     }
  //     drug_detailsarray.morn_eventxt = false;

  //   } else if (drug_detailsarray.intake_txt == "Stat") {
  //     if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
  //       || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
  //       || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
  //       || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
  //       || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
  //       || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
  //       || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
  //       || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
  //       drug_detailsarray.mor = "1";
  //       drug_detailsarray.aft = "0";
  //       drug_detailsarray.eve = "0";
  //       drug_detailsarray.ngt = "0";

  //     } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {
  //           drug_detailsarray.mor = "5";
  //           drug_detailsarray.aft = "0";
  //           drug_detailsarray.eve = "0";
  //           drug_detailsarray.ngt = "0";
  //     }

  //     drug_detailsarray.quan_lable = false;

  //     drug_detailsarray.morning_dis = false;
  //     drug_detailsarray.afternoon_dis = true; 
  //     drug_detailsarray.even_dis = true; 
  //     drug_detailsarray.ngt_dis = true; 

  //     this.morID = true;
  //     this.afterID = true;
  //     this.eveID = true;
  //     drug_detailsarray.intake_show = true;
  //     drug_detailsarray.dure_show = true;

  //     drug_detailsarray.eve_eventxt = true;
  //     drug_detailsarray.night_eventxt = true;
  //     this.daydurationShow = true;

  //     drug_detailsarray.mor_lable = true;
  //     drug_detailsarray.after_eventxt = true;
  //     drug_detailsarray.morning_req = true;
  //     drug_detailsarray.morn_eventxt = false;

  //   } else if (drug_detailsarray.intake_txt == "Once a day") {
  //     if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
  //       || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
  //       || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
  //       || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
  //       || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
  //       || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
  //       || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
  //       || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
  //       drug_detailsarray.mor = "1";
  //       drug_detailsarray.aft = "0";
  //       drug_detailsarray.eve = "0";
  //       drug_detailsarray.ngt = "0";

  //     } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {
  //           drug_detailsarray.mor = "5";
  //           drug_detailsarray.aft = "0";
  //           drug_detailsarray.eve = "0";
  //           drug_detailsarray.ngt = "0";
  //     }

  //     drug_detailsarray.quan_lable = false;

  //     drug_detailsarray.morning_dis = false;
  //     drug_detailsarray.afternoon_dis = true; 
  //     drug_detailsarray.even_dis = true; 
  //     drug_detailsarray.ngt_dis = true; 

  //     this.morID = true;
  //     this.afterID = true;
  //     this.eveID = true;

  //     drug_detailsarray.eve_eventxt = true;
  //     drug_detailsarray.night_eventxt = true;
  //     this.daydurationShow = false;
  //     drug_detailsarray.mor_lable = true;
  //     drug_detailsarray.after_eventxt = true;
  //     drug_detailsarray.morning_req = true;
  //     drug_detailsarray.intake_show = false;
  //     if (this.afterfoodText != "With food") {
  //       drug_detailsarray.dure_show = false;
  //     }
  //     drug_detailsarray.morn_eventxt = false;

  //   } else if (drug_detailsarray.intake_txt == "SOS") {
  //     if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
  //       || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
  //       || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
  //       || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
  //       || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
  //       || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
  //       || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
  //       || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
  //       drug_detailsarray.mor = "1";
  //       drug_detailsarray.aft = "0";
  //       drug_detailsarray.eve = "0";
  //       drug_detailsarray.ngt = "0";

  //     } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {
  //           drug_detailsarray.mor = "5";
  //           drug_detailsarray.aft = "0";
  //           drug_detailsarray.eve = "0";
  //           drug_detailsarray.ngt = "0";


  //     } else {
  //       drug_detailsarray.mor = "0";
  //       drug_detailsarray.aft = "0";
  //       drug_detailsarray.eve = "0";
  //       drug_detailsarray.ngt = "0";
  //     }

  //     drug_detailsarray.quan_lable = false;

  //     this.morID = true;
  //     this.afterID = true;
  //     this.eveID = true;

  //     drug_detailsarray.eve_eventxt = true;
  //     drug_detailsarray.night_eventxt = true;
  //     this.daydurationShow = false;

  //     drug_detailsarray.mor_lable = true;
  //     drug_detailsarray.after_eventxt = true;
  //     drug_detailsarray.morning_req = true;
  //     drug_detailsarray.intake_show = false;

  //     drug_detailsarray.morning_dis = false;
  //     drug_detailsarray.afternoon_dis = true; 
  //     drug_detailsarray.even_dis = true; 
  //     drug_detailsarray.ngt_dis = true; 

  //     if (this.afterfoodText != "With food") {
  //       drug_detailsarray.dure_show = false;
  //     }
  //     drug_detailsarray.morn_eventxt = false;

  //   } else if (drug_detailsarray.intake_txt == "Alternative day" || drug_detailsarray.intake_txt == "Once a week"
  //     || drug_detailsarray.intake_txt == "Once in two weeks" || drug_detailsarray.intake_txt == "Once a month") {
  //     if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
  //       || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
  //       || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
  //       || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
  //       || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
  //       || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
  //       || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
  //       || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
  //       drug_detailsarray.mor = "1";
  //       drug_detailsarray.aft = "1";
  //       drug_detailsarray.eve = "0";
  //       drug_detailsarray.ngt = "1";

  //     } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {
  //           drug_detailsarray.mor = "5";
  //           drug_detailsarray.aft = "5";
  //           drug_detailsarray.eve = "0";
  //           drug_detailsarray.ngt = "5";
  //     }
  //     drug_detailsarray.drug_detailsarray = false;

  //     this.morID = false;
  //     drug_detailsarray.mor_lable = false;
  //     this.afterID = false;
  //     this.eveID = false;

  //     drug_detailsarray.eve_eventxt = false;
  //     drug_detailsarray.night_eventxt = false;
  //     this.daydurationShow = false;

  //     drug_detailsarray.morning_dis = false;
  //     drug_detailsarray.afternoon_dis = false; 
  //     drug_detailsarray.even_dis = false; 
  //     drug_detailsarray.ngt_dis = false; 

  //     drug_detailsarray.after_eventxt = false;
  //     drug_detailsarray.quan_lable = true;
  //     drug_detailsarray.evening_req = true;
  //     drug_detailsarray.afternoon_req = true;
  //     drug_detailsarray.morning_req = true;
  //     drug_detailsarray.intake_show = false;
  //     if (this.afterfoodText != "With food") {
  //       drug_detailsarray.dure_show = false;
  //     }
  //     drug_detailsarray.morn_eventxt = false;
  //   }

  // }

  addMedicalPres(drug_detailsarray) { //Add medicine table
    console.log("drug details array = " + JSON.stringify(drug_detailsarray))
    for (var k = 0; k < drug_detailsarray.length; k++) {
      var flag = false;

      // if (drug_detailsarray[k].type_name == undefined || drug_detailsarray[k].type_name == "") {


      //   this.toastr.error(Message_data.enterMedType);
      //   flag = true;
      // }
      if (drug_detailsarray[k].drug_name == undefined || drug_detailsarray[k].drug_name == "") {

        this.toastr.error(Message_data.enterMedName);
        flag = true;
      }
      // else if (drug_detailsarray[k].intake_txt != "Stat" && (drug_detailsarray[k].day_txt == undefined || drug_detailsarray[k].day_txt == "")) {
      //   this.toastr.error(Message_data.enterNoOfDays);
      //   flag = true;
      // } 
      else if (drug_detailsarray[k].intake_txt == undefined) {

        this.toastr.error(Message_data.enterIntkSess);
        flag = true;
      }


      if (drug_detailsarray[k].show_intake == "1") {
        if (drug_detailsarray[k].intake_txt != undefined && drug_detailsarray[k].intake_txt == "Morning only" && (drug_detailsarray[k].morning == undefined || drug_detailsarray[k].morning == "")) {
          this.toastr.error(Message_data.enterDailyIntk);
          flag = true;
        } else if (drug_detailsarray[k].intake_txt != undefined && drug_detailsarray[k].intake_txt == "Afternoon only" && (drug_detailsarray[k].afternoon == undefined || drug_detailsarray[k].afternoon == "")) {
          this.toastr.error(Message_data.enterDailyIntk);
          flag = true;
        } else if (drug_detailsarray[k].intake_txt != undefined && drug_detailsarray[k].intake_txt == "Evening only" && (drug_detailsarray[k].evening == undefined || drug_detailsarray[k].evening == "")) {
          this.toastr.error(Message_data.enterDailyIntk);
          flag = true;
        } else if (drug_detailsarray[k].intake_txt != undefined && drug_detailsarray[k].intake_txt == "Night only" && (drug_detailsarray[k].night == undefined || drug_detailsarray[k].night == "")) {
          this.toastr.error(Message_data.enterDailyIntk);
          flag = true;
        } else if (drug_detailsarray[k].intake_txt != undefined && drug_detailsarray[k].intake_txt == "Morning & Afternoon" &&
          ((drug_detailsarray[k].morning == undefined || drug_detailsarray[k].morning == "") || (drug_detailsarray[k].afternoon == undefined || drug_detailsarray[k].afternoon == ""))) {
          this.toastr.error(Message_data.enterDailyIntk);
          flag = true;
        } else if (drug_detailsarray[k].intake_txt != undefined && drug_detailsarray[k].intake_txt == "Morning & Evening" &&
          ((drug_detailsarray[k].morning == undefined || drug_detailsarray[k].morning == "") || (drug_detailsarray[k].evening == undefined || drug_detailsarray[k].evening == ""))) {
          this.toastr.error(Message_data.enterDailyIntk);
          flag = true;
        } else if (drug_detailsarray[k].intake_txt != undefined && drug_detailsarray[k].intake_txt == "Morning & Night" &&
          ((drug_detailsarray[k].morning == undefined || drug_detailsarray[k].morning == "") || (drug_detailsarray[k].night == undefined || drug_detailsarray[k].night == ""))) {
          this.toastr.error(Message_data.enterDailyIntk);
          flag = true;
        } else if (drug_detailsarray[k].intake_txt != undefined && drug_detailsarray[k].intake_txt == "Afternoon & Evening" &&
          ((drug_detailsarray[k].afternoon == undefined || drug_detailsarray[k].afternoon == "") || (drug_detailsarray[k].evening == undefined || drug_detailsarray[k].evening == ""))) {
          this.toastr.error(Message_data.enterDailyIntk);
          flag = true;
        } else if (drug_detailsarray[k].intake_txt != undefined && drug_detailsarray[k].intake_txt == "Afternoon & Night" &&
          ((drug_detailsarray[k].afternoon == undefined || drug_detailsarray[k].afternoon == "") || (drug_detailsarray[k].night == undefined || drug_detailsarray[k].night == ""))) {
          this.toastr.error(Message_data.enterDailyIntk);
          flag = true;
        } else if (drug_detailsarray[k].intake_txt != undefined && drug_detailsarray[k].intake_txt == "Morning,Afternoon & Evening" &&
          ((drug_detailsarray[k].morning == undefined || drug_detailsarray[k].morning == "") || (drug_detailsarray[k].afternoon == undefined || drug_detailsarray[k].afternoon == "")
            || (drug_detailsarray[k].evening == undefined || drug_detailsarray[k].evening == ""))) {
          this.toastr.error(Message_data.enterDailyIntk);
          flag = true;
        } else if (drug_detailsarray[k].intake_txt != undefined && drug_detailsarray[k].intake_txt == "Morning,Afternoon & Night" &&
          ((drug_detailsarray[k].morning == undefined || drug_detailsarray[k].morning == "") || (drug_detailsarray[k].afternoon == undefined || drug_detailsarray[k].afternoon == "")
            || (drug_detailsarray[k].night == undefined || drug_detailsarray[k].night == ""))) {
          this.toastr.error(Message_data.enterDailyIntk);
          flag = true;
        } else if (drug_detailsarray[k].intake_txt != undefined && drug_detailsarray[k].intake_txt == "Morning,Afternoon,Evening & Night" &&
          ((drug_detailsarray[k].morning == undefined || drug_detailsarray[k].morning == "") || (drug_detailsarray[k].afternoon == undefined || drug_detailsarray[k].afternoon == "")
            || (drug_detailsarray[k].evening == undefined || drug_detailsarray[k].evening == "") || (drug_detailsarray[k].night == undefined || drug_detailsarray[k].night == ""))) {
          this.toastr.error(Message_data.enterDailyIntk);
          flag = true;
        } else if (drug_detailsarray[k].intake_txt != undefined && drug_detailsarray[k].intake_txt == "Afternoon,Evening & Night" &&
          ((drug_detailsarray[k].evening == undefined || drug_detailsarray[k].afternoon == "") || (drug_detailsarray[k].evening == undefined || drug_detailsarray[k].evening == "")
            || (drug_detailsarray[k].night == undefined || drug_detailsarray[k].night == ""))) {
          this.toastr.error(Message_data.enterDailyIntk);
          flag = true;
        } else if (drug_detailsarray[k].intake_txt != undefined && drug_detailsarray[k].intake_txt == "Evening & Night" &&
          ((drug_detailsarray[k].evening == undefined || drug_detailsarray[k].evening == "") || (drug_detailsarray[k].night == undefined || drug_detailsarray[k].night == ""))) {
          this.toastr.error(Message_data.enterDailyIntk);
          flag = true;
        } else if (drug_detailsarray[k].intake_txt != undefined && drug_detailsarray[k].intake_txt == "Thrice a day" &&
          ((drug_detailsarray[k].morning == undefined || drug_detailsarray[k].morning == "") || (drug_detailsarray[k].afternoon == undefined || drug_detailsarray[k].afternoon == "")
            || (drug_detailsarray[k].night == undefined || drug_detailsarray[k].night == ""))) {
          this.toastr.error(Message_data.enterDailyIntk);
          flag = true;
        } else if (drug_detailsarray[k].intake_txt != undefined && drug_detailsarray[k].intake_txt == "Four times a day" &&
          ((drug_detailsarray[k].morning == undefined || drug_detailsarray[k].morning == "") || (drug_detailsarray[k].afternoon == undefined || drug_detailsarray[k].afternoon == "")
            || (drug_detailsarray[k].evening == undefined || drug_detailsarray[k].evening == "") || (drug_detailsarray[k].night == undefined || drug_detailsarray[k].night == ""))) {
          this.toastr.error(Message_data.enterDailyIntk);
          flag = true;
        }
        // else if (drug_detailsarray[k].intake_txt != undefined && (drug_detailsarray[k].intake_txt == "Every 6 hours" || drug_detailsarray[k].intake_txt == "Every 8 hours"
        //   || drug_detailsarray[k].intake_txt == "Every 12 hours" || drug_detailsarray[k].intake_txt == "Once a day" || drug_detailsarray[k].intake_txt == "Stat")
        //   && (drug_detailsarray[k].morning == undefined || drug_detailsarray[k].morning == "")) {
        //   this.toastr.error(Message_data.enterIntake);
        //   flag = true;
        // } 
        // else if (drug_detailsarray[k].intake_txt == "Stat" && (drug_detailsarray[k].morning == undefined || drug_detailsarray[k].morning == "")) {
        //   this.toastr.error(Message_data.enterIntake);
        //   flag = true;
        // } 
        // else if (drug_detailsarray[k].intake_txt != undefined && (drug_detailsarray[k].intake_txt == "Alternative day" || drug_detailsarray[k].intake_txt == "Once a week"
        //   || drug_detailsarray[k].intake_txt == "Once in two weeks") && (drug_detailsarray[k].morning == "0" && drug_detailsarray[k].afternoon == "0" && drug_detailsarray[k].evening == "0")) {
        //   this.toastr.error(Message_data.enterIntake);
        //   flag = true;
        // }
      }

      if (flag == false) {
        for (var i = 0; i < this.medicalPrescriptionArray.length; i++) {
          if (this.medicalPrescriptionArray[i].med_type_id == drug_detailsarray[k].type_name) {
            drug_detailsarray.drug_typetxt = this.medicalPrescriptionArray[i].med_type_id;
          }
        }

        for (var i = 0; i < this.getIntakeArray.length; i++) {
          if (drug_detailsarray[k].intake_txt == this.getIntakeArray[i].description) {
            this.intake_id = this.getIntakeArray[i].drug_int_id;
          }
        }

        this.medicineTableFlag = false;
        if (drug_detailsarray[k].drug_id == null) {
          drug_detailsarray[k].drug_id = "0";
        }

        var aftfood;
        if (drug_detailsarray[k].show == "1") {
          var morntxt;
          if (parseFloat(drug_detailsarray[k].morning) != 0) {
            morntxt = drug_detailsarray[k].mor + drug_detailsarray[k].short_form;
          } else {
            morntxt = "0"
          }

          var afttxt;
          if (parseFloat(drug_detailsarray[k].afternoon) != 0) {
            afttxt = drug_detailsarray[k].aft + drug_detailsarray[k].short_form;
          } else {
            afttxt = "0"
          }

          var evetxt;
          if (parseFloat(drug_detailsarray[k].evening) != 0) {
            evetxt = drug_detailsarray[k].eve + drug_detailsarray[k].short_form;
          } else {
            evetxt = "0"
          }

          var ngttxt;
          if (parseFloat(this.ngt) != 0) {
            ngttxt = drug_detailsarray[k].night + drug_detailsarray[k].short_form;
          } else {
            ngttxt = "0"
          }

          if (drug_detailsarray[k].show_intake == "0") {
            this.everySix = drug_detailsarray[k].intake_txt;
          } else {
            if (drug_detailsarray[k].intake_txt == "Every 6 hours") {
              this.everySix = morntxt + " - Every 6 hours";
            } else if (drug_detailsarray[k].intake_txt == "Every 8 hours") {
              this.everySix = morntxt + " - Every 8 hours";
            } else if (drug_detailsarray[k].intake_txt == "Every 12 hours") {
              this.everySix = morntxt + " - Every 12 hours";
            } else if (drug_detailsarray[k].intake_txt == "SOS") {
              if (drug_detailsarray[k].drug_typetxt == "1" || drug_detailsarray[k].drug_typetxt == "7" || drug_detailsarray[k].drug_typetxt == "10"
                || drug_detailsarray[k].drug_typetxt == "23" || drug_detailsarray[k].drug_typetxt == "30") {
                this.everySix = morntxt + " SOS - if required";
              } else {
                this.everySix = morntxt + " SOS - if required";
              }
            } else if (drug_detailsarray[k].intake_txt == "Thrice a day") {
              this.everySix = morntxt + " - " + afttxt + " - " + ngttxt;
            } else if (drug_detailsarray[k].intake_txt == "Once a week") {
              this.everySix = morntxt + " - " + afttxt + " - " + evetxt + " - " + ngttxt;
            } else if (drug_detailsarray[k].intake_txt == "Four times a day") {
              this.everySix = morntxt + " - " + afttxt + " - " + evetxt + " - " + ngttxt;
            } else if (drug_detailsarray[k].intake_txt == "Alternative day") {
              this.everySix = morntxt + " - " + afttxt + " - " + evetxt + " - " + ngttxt;
            } else if (drug_detailsarray[k].intake_txt == "Once a day") {
              this.everySix = morntxt + " - per day";
            } else if (drug_detailsarray[k].intake_txt == "Stat") {
              this.everySix = morntxt + " - Stat";
            } else {
              this.everySix = morntxt + " - " + afttxt + " - " + ngttxt;
            }
          }

          if (drug_detailsarray[k].intake_txt != "Stat" && (drug_detailsarray[k].dure_txt != undefined && drug_detailsarray[k].dure_txt != "")) {
            this.dureWrite = this.dureText + " " + "mins";
          } else {
            this.dureWrite = "";
            this.dureFlag = true;
          }

          if (drug_detailsarray[k].afterfood_txt == undefined) {
            this.afterfoodText = "";
          }

          var frequency;
          if (drug_detailsarray[k].intake_txt == "Alternative day" || drug_detailsarray[k].intake_txt == "Once a week" || drug_detailsarray[k].intake_txt == "Once in two weeks") {
            frequency = drug_detailsarray.intake_txt;
          } else {
            if (drug_detailsarray[k].intake_txt == "Stat" || drug_detailsarray[k].intake_txt == "SOS") {
              frequency = "";
            } else {
              if (drug_detailsarray[k].day_txt == "1" && drug_detailsarray[k].daydur_txt == "day(s)") {
                frequency = "";
              } else {
                frequency = "Daily";
              }
            }
          }

          if (drug_detailsarray[k].intake_txt == "Stat") {
            period = "";
            this.dayText = "";
          } else {
            var period
            if (drug_detailsarray[k].day_txt == "1" && drug_detailsarray[k].daydur_txt == "day(s)") {
              period = "day";
            } else if (drug_detailsarray[k].day_txt != "1" && drug_detailsarray[k].daydur_txt == "day(s)") {
              period = "days";
            } else if (drug_detailsarray[k].day_txt == "1" && drug_detailsarray[k].daydur_txt == "week(s)") {
              period = "week";
            } else if (drug_detailsarray[k].day_txt != "1" && drug_detailsarray[k].daydur_txt == "week(s)") {
              period = "weeks";
            } else if (drug_detailsarray[k].day_txt == "1" && drug_detailsarray[k].daydur_txt == "month(s)") {
              period = "month";
            } else if (drug_detailsarray[k].day_txt != "1" && drug_detailsarray[k].daydur_txt == "month(s)") {
              period = "months";
            } else {
              period = "";
            }
          }

          if (drug_detailsarray[k].intake_txt != "Stat" && (drug_detailsarray[k].afterfood_txt != undefined && drug_detailsarray[k].afterfood_txt != 'Select')) {
            aftfood = drug_detailsarray[k].afterfood_txt;
          } else {
            aftfood = "";
          }

          this.listProducts.push({
            med_typetxt: drug_detailsarray[k].type_name,
            drug_name: drug_detailsarray[k].drug_name,
            days: drug_detailsarray[k].day_txt,
            period: period,
            drug_intake: aftfood,
            quantity: drug_detailsarray[k].quantity,
            intake: this.intake_id,
            every_six: drug_detailsarray[k].every_six,
            dure_txt_table: this.dureWrite,
            time_duration: drug_detailsarray[k].dure_txt,
            morning: drug_detailsarray[k].morning,
            afternoon: drug_detailsarray[k].afternoon,
            evening: drug_detailsarray[k].evening,
            night: drug_detailsarray[k].night,
            drug_id: drug_detailsarray[k].drug_id,
            drug_type_id: drug_detailsarray[k].drug_typetxt,
            display_intake: drug_detailsarray[k].intake_txt,
            frequency: frequency,
            show_intake: drug_detailsarray[k].show_intake,
            rowid: drug_detailsarray[k].index,
          });
        } else {
          var morntxt;
          if (parseFloat(drug_detailsarray[k].mor) != 0) {
            morntxt = drug_detailsarray[k].mor + drug_detailsarray[k].short_form;
          } else {
            morntxt = "0"
          }

          var afttxt;
          if (parseFloat(drug_detailsarray[k].aft) != 0) {
            afttxt = drug_detailsarray[k].aft + drug_detailsarray[k].short_form;
          } else {
            afttxt = "0"
          }

          var evetxt;
          if (parseFloat(drug_detailsarray[k].eve) != 0) {
            evetxt = drug_detailsarray[k].eve + drug_detailsarray[k].short_form;
          } else {
            evetxt = "0"
          }

          var ngttxt;
          if (parseFloat(drug_detailsarray[k].afternoon) != 0) {
            ngttxt = drug_detailsarray[k].ngt + drug_detailsarray[k].short_form;
          } else {
            ngttxt = "0"
          }
          if (drug_detailsarray[k].show_intake == "0") {
            this.everySix = drug_detailsarray[k].intake_txt;

          } else {
            if (drug_detailsarray[k].intake_txt == "Every 6 hours") {
              this.everySix = morntxt + " " + drug_detailsarray[k].short_form + " - Every 6 hours";
            } else if (drug_detailsarray[k].intake_txt == "Every 8 hours") {
              this.everySix = morntxt + " " + drug_detailsarray[k].short_form + " - Every 8 hours";
            } else if (drug_detailsarray[k].intake_txt == "Every 12 hours") {
              this.everySix = morntxt + " " + drug_detailsarray[k].short_form + " - Every 12 hours";
            } else if (drug_detailsarray[k].intake_txt == "SOS") {
              if (drug_detailsarray[k].drug_typetxt == "1" || drug_detailsarray[k].drug_typetxt == "7" || drug_detailsarray[k].drug_typetxt == "10"
                || drug_detailsarray[k].drug_typetxt == "23" || drug_detailsarray[k].drug_typetxt == "30") {
                this.everySix = morntxt + " " + drug_detailsarray[k].short_form + " SOS - if required";
              } else {
                this.everySix = morntxt + " " + this.shortForm + " SOS - if required";
              }

            } else if (drug_detailsarray[k].intake_txt == "Thrice a day") {
              this.everySix = morntxt + " - " + afttxt + " - " + ngttxt;
            } else if (drug_detailsarray[k].intake_txt == "Once a week") {
              this.everySix = morntxt + " - " + afttxt + " - " + evetxt + " - " + ngttxt;
            } else if (drug_detailsarray[k].intake_txt == "Four times a day") {
              this.everySix = morntxt + " - " + afttxt + " - " + evetxt + " - " + ngttxt;
            } else if (drug_detailsarray[k].intake_txt == "Alternative day") {
              this.everySix = morntxt + " - " + afttxt + " - " + evetxt + " - " + ngttxt;
            } else if (drug_detailsarray[k].intake_txt == "Once a day") {
              this.everySix = morntxt + " - per day";
            } else if (drug_detailsarray[k].intake_txt == "Stat") {
              this.everySix = morntxt + " " + drug_detailsarray[k].short_form + " - Stat";
            } else {
              this.everySix = morntxt + " - " + afttxt + " - " + ngttxt;
            }
          }

          if (drug_detailsarray[k].dure_txt != undefined && drug_detailsarray[k].dure_txt != "") {
            this.dureWrite = drug_detailsarray[k].dure_txt + " " + "mins";
          } else {
            this.dureWrite = "";
            this.dureFlag = true;
          }

          var frequency;
          if (drug_detailsarray[k].intake_txt == "Alternative day" || drug_detailsarray[k].intake_txt == "Once a week" || drug_detailsarray[k].intake_txt == "Once in two weeks") {
            frequency = drug_detailsarray[k].intake_txt;

          } else {
            if (drug_detailsarray[k].intake_txt == "Stat" || drug_detailsarray[k].intake_txt == "SOS") {
              frequency = "";
            } else {
              if (drug_detailsarray[k].day_txt == "1" && drug_detailsarray[k].daydur_txt == "day(s)") {
                frequency = "";
              } else {
                frequency = "Daily";
              }
            }
          }

          var period
          if (drug_detailsarray[k].day_txt == "1" && drug_detailsarray[k].daydur_txt == "day(s)") {
            period = "day";
          } else if (drug_detailsarray[k].day_txt != "1" && drug_detailsarray[k].daydur_txt == "day(s)") {
            period = "days";
          } else if (drug_detailsarray[k].day_txt == "1" && drug_detailsarray[k].daydur_txt == "week(s)") {
            period = "week";
          } else if (drug_detailsarray[k].day_txt != "1" && drug_detailsarray[k].daydur_txt == "week(s)") {
            period = "weeks";
          } else if (drug_detailsarray[k].day_txt == "1" && drug_detailsarray[k].daydur_txt == "month(s)") {
            period = "month";
          } else if (drug_detailsarray[k].day_txt != "1" && drug_detailsarray[k].daydur_txt == "month(s)") {
            period = "months";
          } else {
            period = "";
          }

          if (drug_detailsarray[k].afterfood_txt != undefined && drug_detailsarray[k].afterfood_txt != 'Select') {
            aftfood = drug_detailsarray[k].afterfood_txt;
          } else {
            aftfood = "";
          }

          this.listProducts.push({
            med_typetxt: drug_detailsarray[k].type_name,
            drug_id: drug_detailsarray[k].drug_id,
            drug_name: drug_detailsarray[k].drug_name,
            days: drug_detailsarray[k].day_txt,
            period: period,
            drug_intake: aftfood,
            intake: this.intake_id,
            every_six: drug_detailsarray[k].every_six,
            quantity: drug_detailsarray[k].quantity,
            dure_txt_table: this.dureWrite,
            time_duration: drug_detailsarray[k].dure_txt,
            morning: drug_detailsarray[k].morning,
            afternoon: drug_detailsarray[k].afternoon,
            evening: drug_detailsarray[k].evening,
            night: drug_detailsarray[k].night,
            drug_type_id: drug_detailsarray[k].drug_typetxt,
            frequency: frequency,
            display_intake: drug_detailsarray[k].intake_txt,
            show_intake: drug_detailsarray[k].show_intake,
            rowid: drug_detailsarray[k].rowid,
          });
        }
        this.medTypeText = "";
        this.medicineName = "";
      }
    }
  }

  getSymptoms() {
    var searchString;
    if (this.discout.indexOf(',') > -1) {
      var dataval = this.discout.split(",");
      searchString = dataval[dataval.length - 1].trim();
    } else {
      searchString = this.discout;
    }

    if (searchString.length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'adm/gsymbn/',
        JSON.stringify({
          symp_name: searchString
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            if (obj.symptoms != null) {
              this.symptomsListArray = [];
              this.symptomListArray = [];
              this.symptomData = [];
              this.symptomsListArray = obj.symptoms;
              for (var i = 0; i < obj.symptoms.length; i++) {
                this.symptomData.push(obj.symptoms[i].description);
              }

              this.symptomListArray = this.symptomData.filter(function (el) {
                return el.toLowerCase().indexOf(searchString.toLowerCase()) > -1;
              }.bind(this));

            }
          },
          error => {

          })
    }
  }

  selectSymptom(data) {
    this.newSymptomsArray.push(data);
    for (var j = 0; j < this.symptomsListArray.length; j++) {
      if (this.symptomsListArray[j].description == data) {
        this.selectedSymptoms.push(data);
        break;
      }
    }

    if (this.discout.indexOf(',') > -1) {
      var dataval = this.discout.split(",");
      dataval.pop();
      this.discout = dataval.join(",");

      this.discout = this.discout + "," + data;
    } else {
      this.discout = data;
    }
    this.symptomListArray = [];
  }

  deleteMedicalpres(medtype, medname) {
    for (var i = 0; i < this.listProducts.length; i++) {
      if (this.listProducts[i].med_typetxt == medtype && this.listProducts[i].drug_name == medname) {
        this.listProducts.splice(i, 1);
        break;
      }
    }
    if (this.listProducts.length != 0) {
      this.medicineTableFlag = false;

    }
    else {
      this.medicineTableFlag = true;

    }
  }

  async saveMedicalPrescription(e) {
    for (var i = 0; i < this.drugDetailsArray.length; i++) {
      if (this.drugDetailsArray[i].drug_id == "" || this.drugDetailsArray[i].drug_id == undefined || this.drugDetailsArray[i].drug_id == null) {
        this.drugDetailsArray.splice(i, 1);
      }
    }
    console.log("SAVEMED array = " + JSON.stringify(this.drugDetailsArray))
    await this.addMedicalPres(this.drugDetailsArray);
    var flag = true;

    if (this.listProducts.length == 0) {

      this.toastr.error(Message_data.addMedDetails);
      flag = false;
    }

    if (flag == true) {
      var sen_pass;
      sen_pass = {
        client_reg_id: this.ClientID,
        relation_id: this.relationID,
        appointment_id: this.appID,
        sub_rel_id: this.subRelationID,
        country: ipaddress.country_code,
        date: this.currentDate,
        disease: this.discout,
        medicines: this.listProducts,
        pharmacy_id: this.pharmacyID,
        newsymptoms: "",
        doc_reg_id: this.docRegID,
        is_inpatient:'1',
      };

      if (this.subRelationID != undefined) {
        sen_pass.sub_rel_id = this.subRelationID;
      }

      console.log("data" + JSON.stringify(sen_pass))
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'prescription/savepres/', JSON.stringify(sen_pass),
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            console.log("json stringfy = " + JSON.stringify(obj))
            if (obj.status == "1") {
              this.presID = obj.pres_id;
              this.saveOrder(e);
            }
          },
          error => {
          }
        )
    }
  }

  searchByOrderNo() {
    this.drugDetailsArray = [];
    this.totalvalue = 0;
    var send_data = {
      order_id: this.orderNo,
      pharmacy_id: this.pharmacyID,
    }
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.httpclnt.post(ipaddress.getIp + "pharmacontrol/vieworderdetails/", send_data,
      { headers: headers }).subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log(" object = " + obj)
          if (obj != null && obj != undefined) {
            this.viewOrderFlag = true;
            var orderdate = obj.order_date != undefined ? obj.order_date.split('-') : "";
            this.OrderDate = orderdate[2] + "-" + orderdate[1] + "-" + orderdate[0];
            this.PrepareTime = Time_Formate(obj.order_time);
            this.OrderByName = obj.middle_name != undefined ? encrypt_decript.Decript(obj.first_name) + " " + encrypt_decript.Decript(obj.middle_name) + " " + encrypt_decript.Decript(obj.last_name) : encrypt_decript.Decript(obj.first_name) + " " + encrypt_decript.Decript(obj.last_name);
            this.presFlag = obj.pres_drug_id != undefined ? false : true;
            this.orderNumber = obj.drug_purchase_id != undefined ? obj.drug_purchase_id : "";
            if (obj.description != undefined) {
              this.PurchaseType = obj.description;
            }
            if (obj.preferred_from_time != undefined) {
              this.totalTime = (obj.preferred_from_time) + " " + (obj.preferred_to_time);
              this.preferredTimeFlag = false;
            } else {
              this.preferredTimeFlag = true;
            }
            if (obj.order_date != undefined) {
              this.orderDate = Date_Formate(obj.order_date);
            }
            if (obj.order_time != undefined) {
              this.orderTime = Time_Formate(obj.order_time);
            }
            if (obj.drug_details != null) {
              for (var i = 0; i < obj.drug_details.length; i++) {
                var priceval = (parseFloat(obj.drug_details[i].price) / parseFloat(obj.drug_details[i].per_unit)).toFixed(2);
                var amountval = (parseFloat(obj.drug_details[i].quantity) * parseFloat(priceval)).toFixed(2);
                this.drugDetailsArray.push({
                  drug_id: obj.drug_details[i].drug_id,
                  type_name: obj.drug_details[i].type_name,
                  drug_name: obj.drug_details[i].drug_name,
                  quantity: obj.drug_details[i].quantity,
                  amount: amountval,
                  price: priceval,
                  batch_no: obj.drug_details[i].batch_no,
                  batch_hide: this.orderType,
                  cgst: obj.drug_details[i].cgst,
                  sgst: obj.drug_details[i].sgst,
                  checked: false
                })
              }
            }
            var addval = obj.phar_address2 != undefined ? obj.phar_address1 + "," + obj.phar_address2 : obj.phar_address1;
          } else {
            this.viewOrderFlag = false;
          }
        });
  }

  lineQuantity(array) {
    var prec_tx = 0;
    for (var i = 0; i < this.drugDetailsArray.length; i++) {
      if (this.drugDetailsArray[i].drug_id == array.drug_id) {
        //this.drug_detailsarray[i].amount = (parseFloat(array.quantity) * parseFloat(array.price)).toFixed(2);
        if (array.quantity != "") {
          var priceval = parseFloat(array.price);
          if (array.quantity != undefined) {
            var pricecal: any = priceval * array.quantity;
          }

          if (array.discount != 0) {
            var disc: any = (pricecal * (array.discount / 100)).toFixed(2);
            this.drugDetailsArray[i].amount = pricecal - disc;
          } else {
            disc = 0;
          }

          var pricegst;
          pricegst = pricecal - disc;
          this.drugDetailsArray[i].amount = pricegst.toFixed(2);
        }
      }
      prec_tx = prec_tx + parseFloat(this.drugDetailsArray[i].amount)
    }
    this.totalAmount = prec_tx.toFixed(2);
    this.calculateDiscount();
  }

  getCORD(e, i) {
    this.showBatchArray = true;
    var x = e.clientX;
    var y = e.clientY;
    var coords = "X coords: " + x + ", Y coords: " + y;
    document.getElementById(i).style.position = 'fixed';
    document.getElementById(i).style.display = 'block';
    document.getElementById(i).style.top = y + 'px';
    this.loadScript();

    // document.getElementById("pharma_overlay").style.overflow = "hidden";
    document.getElementById("pharma_inpattbl_overlay").style.overflowY = "hidden";
    document.getElementById("pharma_inpattbl_overlay").style.overflowX = "hidden";
  }
  getCORDreturns(e, i) {
    this.showBatchArray = true;
    var x = e.clientX;
    var y = e.clientY;
    var coords = "X coords: " + x + ", Y coords: " + y;
    document.getElementById(i).style.position = 'fixed';
    document.getElementById(i).style.display = 'block';
    document.getElementById(i).style.top = y + 'px';
    this.loadScript();

    // document.getElementById("pharma_overlay").style.overflow = "hidden";
    document.getElementById("pharma_inpattbl_overlay").style.overflowY = "hidden";
    document.getElementById("pharma_inpattbl_overlay").style.overflowX = "hidden";
  }

  // @HostListener('window:click', ['$event'])
  // onclick(event: any): void {
  //   if(this.showBatchArray == false){
  //     if(this.drugDetailsArray.length >= 1){
  //       for(var i = 0; i < this.drugDetailsArray.length; i++){
  //           document.getElementById('drug_'+this.drugDetailsArray[i].index).style.display = 'none';
  //       }
  //     }
  //     // document.getElementById("pharma_overlay").style.overflow = "auto";
  //     document.getElementById("pharma_inpattbl_overlay").style.overflowY = "auto";
  //     document.getElementById("pharma_inpattbl_overlay").style.overflowX = "auto";
  //   }else{
  //     this.showBatchArray = false;
  //   }
  // }

  focusFunction(e, drug) {
    if (drug.intake_txt == "Alternative day" || drug.intake_txt == "Once a week"
      || drug.intake_txt == "Once in two weeks" || drug.intake_txt == "Once a month") {
      if (e.target.value == '') {
        this.toastr.error(Message_data.fillIntk);
        e.target.focus();
      }
    }
    else {
      if (e.target.value == '' || e.target.value == '0') {
        this.toastr.error(Message_data.fillIntk);
        e.target.focus();
      }
    }
  }

  checkExpiryDate(expiryDate) {
    var exp = "";
    if (expiryDate.split("-").length != 1)
      exp = expiryDate.split("-");
    var d = new Date();
    var a = moment(exp[1] + "-" + exp[0]);
    var b = moment(d);
    var intervals: any = ['years', 'months', 'weeks', 'days'];
    var out = [];

    for (var i = 0; i < intervals.length; i++) {
      var diff = a.diff(b, intervals[i]);
      b.add(diff, intervals[i]);
      out.push(diff + ' ' + intervals[i]);
    }

    console.log("out ==> " + out);
    var expD: any = out.toString().split(",");
    var yy = expD[0].split(/\s+/g);
    console.log("yy ==> " + yy);
    var mmm = expD[1].split(/\s+/g);
    console.log("mmm ==> " + mmm);
    var subtract = moment().add(mmm[0], mmm[1].toString()).format('YYYY-MM');
    var sub1 = moment(subtract).add(yy[0], yy[1].toString()).format('YYYY-MM');
    var final = moment().add(this.productReturn, 'months').format('YYYY-MM');
    console.log("subtract ==> " + subtract + "   sub1 ==> " + sub1 + "final ==> " + final);
    var color;
    if (sub1 <= final) {
      color = "red";
    } else {
      color = "black";
    }
    // if(yy[0] == 0 || yy[0] == 1){
    //   if(mmm[0] <= this.productReturn){
    //     color = "red";
    //   }else{
    //     color = "black";
    //   }
    // }else{
    //   color = "black";
    // }

    return color;

    // return out.join(', ');
    // if(exp[1] == d.getFullYear()){
    //   if(exp[0] >= d.getMonth()){
    //     console.log("check1 " +exp[0] + " >= " + d.getMonth());
    //     var es = new Date(exp[1] + "-" +exp[0]);
    //     es.setDate(es.getDate() - 92)
    //     var r = this.getDateFormate(es);
    //     var sax = r.split("-");
    //     console.log("exp before 3 ==> " + r);
    //     if(r[1] >= )

    //   }else if(exp[0] == d.getMonth()){
    //     console.log("check2 " +exp[0] + " >= " + d.getMonth());
    //   }else if(exp[0] <= d.getMonth()){
    //     console.log("check3 " +exp[0] + " >= " + d.getMonth());
    //   }
    //   d.setDate(d.getDate() - 92)
    //   e = this.getDateFormate(d);
    //   console.log("ecp ==> " +e);
    // }else{
    //   console.log("no ==> " +exp);
    // }
  }

  getDateFormate(d) {
    var month = ((d.getMonth() + 1) < 10 ? '0' : '') + (d.getMonth() + 1);
    var day = (d.getDate() < 10 ? '0' : '') + d.getDate();
    var temp = d.getFullYear() + "-" + month + "-" + day;
    return temp
  }
  paidFocus() {
    this.paidText = "";
  }

  printcover() {
    var count = 0

    console.log("array " + JSON.stringify(this.drugDetailsArray))
    for (var i = 0; i < this.drugDetailsArray.length; i++) {

      if ((this.drugDetailsArray[i].drug_id == "" && this.drugDetailsArray[i].drug_name == "")) {
        count = + 1

      }
    }

    if (count == 0) {

      this.printsmallcov();
    } else {
      this.toastr.error(Message_data.removerow);
    }
  }

  printsmallcov() {
    let printContents, popupWin;
    printContents = this.printsmallcover.nativeElement.innerHTML;
    // printContents=document.getElementById('printsmallcover').innerHTML;

    // popupWin = window.open('', '_blank', 'top=20,left=10,height=100%,width=auto');
    popupWin = window.open('', '_blank', 'top=100,left=10,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`<head>
  <title>Reports</title>
  
<link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/css/bootstrap-theme.min.css" integrity="sha384-rHyoN1iRsVXV4nD0JutlnGaslCJuC7uwjduW9SVrLvRYooPp2bWYgmgJQIXwl/Sp" crossorigin="anonymous">
<style>

.covertablebig thead td{
  border-top:1px solid transparent;
} 
.covertablebig thead td {
  border: 1px solid black;

}
.covertablebig thead th {
  border: 1px solid black;

}
.covertablebig tfoot th {
  border: 1px solid black;
}
.covertablebig tbody td{
  border: 1px solid black;
}

  </style>
</head>
    <body onload="window.print();window.onfocus=function(){ window.close();}">
    <div style="padding-top:20%">
     
    ${printContents}
  
    </div>
 </body>
  </html>`
    );
    popupWin.document.close();

  }

  showCreditOption() {
    this.credit_flag = !this.credit_flag;

    this.paymentsarray = [];
    this.getCreditTypes()
  }

  getCreditTypes() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(
        ipaddress.getIp.toString() + 'bill/ghco',
        JSON.stringify({
          country: ipaddress.country_code.toString(),
          hptl_clinic_id: this.getData.hptl_clinic_id,
          type: 'hospital',
        }),
        { headers: headers }
      )
      .subscribe(
        (response) => {
          var obj = response.json();

          this.hosp_credits = [];
          if (obj.hosp_credits != null) {
            for (var i = 0; i < obj.hosp_credits.length; i++) {
              this.hosp_credits.push({
                credit_id: obj.hosp_credits[i].credit_id,
                credit_desc: obj.hosp_credits[i].credit_desc,
              });
            }
          }
        },
        (error) => { }
      );
  }

  addPaymentDetails() {
    var bamt: any = 0,
      balance = 0;
    var results = this.finekeyInArray_Payment(
      this.paymentsarray,
      this.payType1
    );
    for (var i = 0; i < this.payTypeListArray.length; i++) {
      if (this.payType1 == this.payTypeListArray[i].pay_id) {
        if (this.paymentsarray.length != 0) {
          for (var j = 0; j < this.paymentsarray.length; j++) {
            bamt += parseInt(this.paymentsarray[j].amount);
          }
          bamt += parseFloat(this.amountval);
        } else {
          bamt += parseFloat(this.amountval);
        }

        if (bamt > this.paidText) {
          this.toastr.error('Amount cannot be more than bill amount');
        } else if (this.amountval == 0 || this.amountval < 0) {
          this.toastr.error('Amount cannot be zero or minus amount');
        } else if (results == true) {
          this.toastr.error('Payment type already exists');
        } else {
          if (
            this.amountval < parseFloat(this.paidText) ||
            this.amountval == parseFloat(this.paidText)
          ) {
            if (parseFloat(this.paidText) > parseFloat(this.amountDiscount)) {
              if (bamt > parseFloat(this.amountDiscount)) {
                this.toastr.error('Amount cannot be more than bill amount');
              } else {
                this.paymentsarray.push({
                  pay_id: this.payTypeListArray[i].pay_id,
                  pay_desc: this.payTypeListArray[i].pay_desc,
                  amount: parseFloat(this.amountval).toFixed(2),
                });
                this.amountval = parseFloat(this.amountDiscount) - bamt;
                this.amountval = parseFloat(this.amountval).toFixed(2);

                // this.payType1 = '';
              }
            } else {
              if (bamt > parseFloat(this.paidText)) {
                this.toastr.error('Amount cannot be more than bill amount');
              } else {
                this.paymentsarray.push({
                  pay_id: this.payTypeListArray[i].pay_id,
                  pay_desc: this.payTypeListArray[i].pay_desc,
                  amount: parseFloat(this.amountval).toFixed(2),
                });
                this.amountval = parseFloat(this.paidText) - bamt;
                this.amountval = parseFloat(this.amountval).toFixed(2);

                // this.payType1 = '';
              }
            }
          } else {
            this.amountval = 0;
          }
        }
      }
    }
  }

  finekeyInArray_Payment(array, id) {
    var flag = false;
    for (var i = 0; i < array.length; i++) {
      if (array[i].pay_id === id) {
        flag = true;
        break;
      }
    }
    return flag;
  }

  editPayment(data) {
    this.payType1 = data.pay_id;
    this.amountval = parseInt(data.amount);

    for (var i = 0; i < this.paymentsarray.length; i++) {
      if (data.pay_id == this.paymentsarray[i].pay_id) {
        this.tamt -= this.paymentsarray[i].amount;
        this.paymentsarray.splice(i, 1);
      }
    }
  }
  deletePayment(data) {
    for (var i = 0; i < this.paymentsarray.length; i++) {
      if (data.pay_id == this.paymentsarray[i].pay_id) {



        this.paymentsarray.splice(i, 1);
      }
    }
  }

  patientdetail() {
    var senddetails = JSON.stringify({
      hptl_clinic_id: this.pharmaidforbill,
      country: ipaddress.country_code,
      fdate: this.orderDate,
      tdate: this.orderDate,
      pat_type: "inpatient"
    })
    var headers = new Headers();
    headers.append('content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + this.patientdetailsurl, senddetails,
      { headers: headers })
      .subscribe(response => {
        this.patientdetailsarray = [];
        var obj = response.json();
        console.log("patientdetails" + JSON.stringify(obj));
        if (obj.med_procedures != null) {
          for (let i = 0; i < obj.med_procedures.length; i++) {
            var docname;
            var patientname;
            var mobilenumber;
            var ipop;
            if (obj.med_procedures[i].dr_middle_name != undefined) {
              docname = obj.med_procedures[i].dr_first_name + " " + obj.med_procedures[i].dr_middle_name + " " + obj.med_procedures[i].dr_last_name;
            } else if (obj.med_procedures[i].dr_middle_name == undefined && obj.med_procedures[i].dr_last_name != undefined) {
              docname = obj.med_procedures[i].dr_first_name + " " + obj.med_procedures[i].dr_last_name;
            } else {
              docname = ""
            }
            if (obj.med_procedures[i].middle_name != undefined) {
              patientname = encrypt_decript.Decript(obj.med_procedures[i].first_name) + " " + encrypt_decript.Decript(obj.med_procedures[i].middle_name) + " " + encrypt_decript.Decript(obj.med_procedures[i].last_name);
            } else if (obj.med_procedures[i].middle_name == undefined && obj.med_procedures[i].last_name != undefined) {
              patientname = encrypt_decript.Decript(obj.med_procedures[i].first_name) + " " + encrypt_decript.Decript(obj.med_procedures[i].last_name);
            } else {
              patientname = ""
            }
            if (obj.med_procedures[i].mobile != undefined) {
              mobilenumber = encrypt_decript.Decript(obj.med_procedures[i].mobile);
            }
            if (obj.med_procedures[i].op_ip != undefined) {
              ipop = obj.med_procedures[i].op_ip
              if (ipop == "op") {
                ipop = "OP"
              } else if (ipop == "ip") {
                ipop = "IP"
              } else {
                ipop = obj.med_procedures[i].op_ip
              }
            }

            if (obj.med_procedures[i].inp_hosp_id != undefined) {
              obj.med_procedures[i].inp_hosp_id = obj.med_procedures[i].inp_hosp_id
            } else {
              obj.med_procedures[i].inp_hosp_id = ''
            }

            if (obj.med_procedures[i].insurer != undefined) {
              obj.med_procedures[i].insurer = obj.med_procedures[i].insurer
            } else {
              obj.med_procedures[i].insurer = ''
            }

            if (obj.med_procedures[i].doc_qualif) {
              obj.med_procedures[i].doc_qualif = obj.med_procedures[i].doc_qualif
            } else {
              obj.med_procedures[i].doc_qualif = ''
            }

            if (ipop == "IP") {
              this.patientdetailsarray.push({
                mrno: obj.med_procedures[i].mrno,
                name: patientname,
                dr_name: docname,
                mobile: mobilenumber,
                age: obj.med_procedures[i].age,
                client_reg_id: obj.med_procedures[i].client_reg_id,
                sub_rel_id: obj.med_procedures[i].sub_rel_id,
                relation_id: obj.med_procedures[i].relation_id,
                op_ip: ipop,
                doc_app_id: obj.med_procedures[i].doc_app_id,
                barcode: obj.med_procedures[i].barcode,
                inp_hosp_id: obj.med_procedures[i].inp_hosp_id,
                insurer: obj.med_procedures[i].insurer,
                doc_qualif: obj.med_procedures[i].doc_qualif
              })
            }
          }
        }
      },
        error => { }
      );
  }

  patientdetailslist(details) {

    console.log("Details = " + JSON.stringify(details))
    this.name = details.name;
    this.doctorName = details.dr_name
    this.patientId = details.mrno;
    this.mrNumb = details.mrno;
    this.relationID = details.relation_id;
    this.ClientID = details.client_reg_id;
    this.subRelationID = details.sub_rel_id;
    this.docsalflag = false;
    this.ipop = details.op_ip;
    this.inp_hosp_id = details.inp_hosp_id;
    if (details.barcode != undefined) {
      this.barcode_logo = ipaddress.Ip_with_img_address + details.barcode;
    } else {
      this.barcode_logo = ""
    }
    if (details.name != "") {
      this.insuranceflag_paid = true;

    } else {
      this.insuranceflag_paid = false;
      //  if (this.getData.hospitals[0].bill_pay_type_req == "1") {
      //   this.paytypreq = true;
      // } else {
      //   this.paytypreq = false;
      // }
    }

    if (details.doc_qualif != undefined && details.doc_qualif != '') {
      this.qualification = details.doc_qualif;
      this.qualification_flag = false;
    } else {
      this.qualification = ''
      this.qualification_flag = true;
    }
    this.appointText = details.doc_app_id;
    this.retrieveClient(details)
    this.changeAppointmnetID()
  }

  retrieveClient(person) {
    if (this.retrivemr == true) {
      var get_data = {
        // user_id: person[0].client_reg_id,
        // relation_id: person[0].relation_id,
        // sub_rel_id: person[0].sub_rel_id,
        // country: ipaddress.country_code
        user_id: person.client_reg_id,
        relation_id: person.relation_id,
        sub_rel_id: person.sub_rel_id,
        country: ipaddress.country_code
      };
    }
    else {

      var get_data = {
        user_id: person.client_reg_id,
        relation_id: person.relation_id,
        sub_rel_id: person.sub_rel_id,
        country: ipaddress.country_code
      };

    }
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.httpp.post(ipaddress.getIp.toString() + 'usercontroller/grclnt/',
      get_data,
      { headers: headers })
      .subscribe(
        response => {
          this.patient_detailsflag = false;
          this.clientsalflag = true;
          var obj = JSON.parse(JSON.stringify(response));
          console.log("check patientid" + JSON.stringify(obj))


          if (obj.client_id != null) {

            // this.profileImg = ipaddress.Ip_with_img_address + obj.profile_image;
            if (this.patsearchtype == "patientid") {
              // this.client_reg_id = person[0].client_reg_id;
              // this.relation_id = person[0].relation_id;
              // this.sub_rel_Id = person[0].sub_rel_id;
              this.ClientID = person.client_reg_id;
              this.relationID = person.relation_id;
              this.subRelationID = person.sub_rel_id;

            } else {
              this.ClientID = person.client_reg_id;
              this.relationID = person.relation_id;
              this.subRelationID = person.sub_rel_id;
            }
            if (obj.salutation != undefined) {
              this.clntSal = obj.salutation + ".";

            } else {
              this.clntSal = "";

            }

            this.clnt_sal = obj.salutation_id;
            this.clntFirstName = obj.first_name != null && (encrypt_decript.Decript(obj.first_name) != "") ? encrypt_decript.Decript(obj.first_name) : "";
            this.clntMiddleName = obj.middle_name != null && (encrypt_decript.Decript(obj.middle_name) != "") ? encrypt_decript.Decript(obj.middle_name) : "";
            this.clntLastName = obj.last_name != null && (encrypt_decript.Decript(obj.last_name) != "") ? encrypt_decript.Decript(obj.last_name) : "";
            this.clntGender = obj.gender != null && (encrypt_decript.Decript(obj.gender) != "") ? encrypt_decript.Decript(obj.gender) : "";
            if (this.clntGender == "Male" || this.clntGender == "M") {
              this.headergen = "M";
            } else if (this.clntGender == "Female" || this.clntGender == "F") {
              this.headergen = "F";
            } else if (this.clntGender == "Transgender" || this.clntGender == "M") {
              this.headergen = "TG";
            } else {
              this.headergen = "";
            }
            this.clntContNumber = obj.mobile != null && obj.mobile != "" && obj.mobile != undefined && (encrypt_decript.Decript(obj.mobile) != "") ? encrypt_decript.Decript(obj.mobile) : "";
            this.clntEmergNumber = obj.emerg_contact1 != null && obj.emerg_contact1 != "" && obj.emerg_contact1 != undefined && (encrypt_decript.Decript(obj.emerg_contact1) != "") ? encrypt_decript.Decript(obj.emerg_contact1) : "";
            this.clntEmergNumber1 = obj.emerg_contact2 != null && obj.emerg_contact2 != "" && obj.emerg_contact2 != undefined && (encrypt_decript.Decript(obj.emerg_contact2) != "") ? encrypt_decript.Decript(obj.emerg_contact2) : "";
            this.clntAddress1 = obj.address1 != null && obj.address1 != "" && obj.address1 != undefined && (encrypt_decript.Decript(obj.address1) + ", " != "") ? encrypt_decript.Decript(obj.address1) + ", " : "";
            this.clntAddress2 = obj.address2 != null && obj.address2 != "" && obj.address2 != undefined && (encrypt_decript.Decript(obj.address2) + ", " != "") ? encrypt_decript.Decript(obj.address2) + ", " : "";
            if (this.clntAddress2 == ",") {
              this.clntAddress2 = ""
            }
            this.clntCity = obj.city_desc != null && obj.city_desc != "" && obj.city_desc != undefined && obj.city_desc != "" ? obj.city_desc + ". " : "";
            this.clntCountry = obj.country_desc != null && obj.country_desc != "" && obj.country_desc != undefined && obj.country_desc != "" ? obj.country_desc + ". " : "";
            this.clntState = obj.state_desc != null && obj.state_desc != "" && obj.state_desc != undefined && obj.state_desc != "" ? obj.state_desc + ". " : "";
            this.clntLocation = obj.location_desc != null && obj.location_desc != "" && obj.location_desc != undefined && obj.location_desc != "" ? obj.location_desc : "";
            this.clntZipcode = obj.location_desc != null && obj.zipcode != "" && obj.zipcode != undefined && (encrypt_decript.Decript(obj.zipcode) != "") ? encrypt_decript.Decript(obj.zipcode) : "";
            this.clntdob = obj.dob != null && obj.dob != "" && obj.dob != undefined && obj.dob != "" ? obj.dob : "";

            if (this.ipop == undefined) {
              this.ipop = "OP"
            }

            if (this.clntMiddleName != "") {
              // this.name =this.clntSal + this.clntFirstName+" "+this.clntLastName;
              this.clientname = this.clntSal + this.clntFirstName + " " + this.clntLastName;
            } else {
              // this.name = this.clntSal + this.clntFirstName+" "+this.clntMiddleName +" "+this.clntLastName;
              this.clientname = this.clntSal + this.clntFirstName + " " + this.clntMiddleName + " " + this.clntLastName;
            }

            if (obj.patient_id != undefined) {
              this.mrNumb = obj.patient_id
            }
            if (obj.ref_source != null) {
              this.ref_source = obj.ref_source;
            }

            if (obj.dob != null) {
              this.clntDOB = Date_Formate(obj.dob);

            } else {
              this.clntDOB = null;
              this.dobDisabledFlag = true;
            }

            if (obj.age != undefined) {
              this.clntAge = obj.age;

            } else {
              this.clntAge = "";
            }

            this.clntFatherOrGuardName = this.checkData(obj.guardian);
            if (obj.address1 != null && obj.address1 != "") {
              this.clntAddress1 = encrypt_decript.Decript(obj.address1);
            }
            if (obj.SSN_PAN_number != undefined) {
              this.aadharNumber = encrypt_decript.Decript(obj.SSN_PAN_number);
            }
            if (obj.emerg_contact1 != undefined) {
              this.clntEmergNumber = encrypt_decript.Decript(obj.emerg_contact1);
            }
            if (obj.emerg_contact2 != undefined) {
              this.clntEmergNumber1 = encrypt_decript.Decript(obj.emerg_contact2);
            }
            //emerg_contact1,emerg_contact2

            if (obj.address2 != null && obj.address2 != undefined && obj.address1 != undefined) {
              this.clntAddress1 = encrypt_decript.Decript(obj.address1) + ",";
              this.clntAddress2 = encrypt_decript.Decript(obj.address2) + ",";
            } else if (obj.clnt_address1 != undefined) {
              this.clntAddress1 = encrypt_decript.Decript(obj.address1) + ",";
            } else {
              this.clntAddress2 = encrypt_decript.Decript(obj.address2) + ",";
            }
            this.clntLocation = '';
            if (obj.location_desc != undefined && obj.location_desc != null) {
              this.clntLocation = this.checkData(obj.location_desc) + ",";
            }

            this.clntCity = '';
            if (obj.phar_city != undefined && obj.city != null) {
              this.clntCity = obj.city + "-";
            }

            this.clntZipcode;
            if (obj.zipcode != undefined && obj.zipcode != null) {
              this.clntZipcode = encrypt_decript.Decript(obj.zipcode) + ",";
            }

            this.clntState = '';
            if (obj.state_desc != undefined && obj.state_desc != null) {
              this.clntState = this.checkData(obj.state_desc) + ".";
            }

            this.clntCountry = '';
            if (obj.clnt_country != undefined && obj.clnt_country != null) {
              this.clntCountry = this.checkData(obj.country_desc) + ".";
            }
            if (obj.ward_name != undefined) {
              this.Ward_name = obj.ward_name
            } else {
              this.Ward_name = ""
            }

            const wardContainsICUOrTheater = /ICU|Theater/i.test(obj.ward_name);
            this.wardflag = wardContainsICUOrTheater;

            if (obj.bed_no != undefined) {
              this.Bed_no = obj.bed_no
            } else {
              this.Bed_no = ""
            }

            // if (obj.address2 != null && obj.address2 != "") {
            //   this.clntAddress2 = encrypt_decript.Decript(obj.address2);
            //   this.address = this.clntAddress1 +""+ this.clntAddress2
            // }else{
            //   this.address = this.clntAddress1 
            // }

            // this.clientCityId = obj.city;
            // this.clientStateId = obj.state;
            // this.clientcountryId = obj.country;
            // this.clntLocation = this.checkData(obj.location_desc);
            // this.clntCountry = this.checkData(obj.country_desc);
            // this.clntState = this.checkData(obj.state_desc);
            // this.clntCity = this.checkData(obj.city_desc);
            // this.clntZipcode = encrypt_decript.Decript(obj.zipcode);
          }
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        });


  }

  handleKeymedicineUp(event: KeyboardEvent, gen_name, drug) {
    const key = event.key;
    if (key !== 'ArrowUp' && key !== 'ArrowDown') {
      var gen_name
      this.changeMedicineName(gen_name, 'medicine')
    }
    if (key === 'Enter') {
      let medicine;
      for (let i = 0; i < this.medicineArray.length; i++) {
        if (this.medicineArray[i].name === gen_name) {
          const { name, quantity, generic } = this.medicineArray[i];
          medicine = {
            name,
            quantity,
            generic
          };
          break;
        }
      }
      if (gen_name) {
        this.selectMedicine(medicine, drug)
        console.log("value = " + JSON.stringify(medicine))
      }
    }
  }

  handleKeyreturnmedicineUp(event: KeyboardEvent, gen_name, drug) {
    const key = event.key;
    if (key !== 'ArrowUp' && key !== 'ArrowDown') {
      var gen_name
      this.changeMedicineNamereturns(gen_name, 'medicine')
    }
    if (key === 'Enter') {
      let medicine;
      for (let i = 0; i < this.medicineArrayreturns.length; i++) {
        if (this.medicineArrayreturns[i].name === gen_name) {
          const { name, quantity, generic } = this.medicineArrayreturns[i];
          medicine = {
            name,
            quantity,
            generic
          };
          break;
        }
      }
      if (gen_name) {
        this.selectMedicineretuns(medicine, drug)
        console.log("value = " + JSON.stringify(medicine))
      }
    }
  }

  // searchtype(value) {
  //   if (value == "name") {
  //     this.patient_detailsflag = true;
  //     this.name = undefined;
  //     this.doctorName = undefined;
  //     this.patientArray = [];
  //     this.retrivenameflag = true;
  //     this.retrivemr = false;
  //     this.patsearchtype = "name";
  //   } else if (value == "mrnumber") {
  //     this.patient_detailsflag = true;
  //     this.name = undefined;
  //     this.doctorName = undefined;
  //     this.patientArray = [];
  //     this.retrivemr = true;
  //     this.retrivenameflag = false;
  //     this.patsearchtype = "patientid";
  //   }

  checkData(value) {
    var data = "";
    if (value != undefined && value != null && value != "" && value != "undefined" && value != "null")
      data = value;
    else
      data = "";
    return data;
  }

  insurancecondition(value) {
    if (value == false) {
      this.insuranceflag = false;
      this.discountcalculatestaff()
    } else {
      this.insuranceflag = true;
      this.discountcalculatestaff()
    }

  }


  insuranceinduvalcheck(drug, value) {
    this.discountcalculatestaff()
  }


  printnonserv() {
    for (var i = 0; i < this.drugDetailsArray.length; i++) {
      if (this.drugDetailsArray[i].drug_id == "" || this.drugDetailsArray[i].drug_id == undefined || this.drugDetailsArray[i].drug_id == null) {
        this.drugDetailsArray.splice(i, 1);
      }
    }
    setTimeout(() => {
      this.prnintorder()
    }, 500);
  }

  prnintorder() {

    console.log("normaldt_formate = " + this.normaldt_formate)
    var margin_top;
    var fontsize = `${this.printFontSize}px`
    let printContents, popupWin;
    if (this.billTemplate != undefined && this.billTemplate == "noheader") {
      margin_top = '122px';
      printContents = this.printnoheader.nativeElement.innerHTML;
    } else if (this.billTemplate == "logowithname") {
      margin_top = '0px';
      printContents = this.printlogowithname.nativeElement.innerHTML;
    }
    else {
      margin_top = '0px';
      printContents = this.printbanner.nativeElement.innerHTML;
    }
    popupWin = window.open('', '_blank', 'top=10,left=20,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
    <html>
      <head>
        <title>Bill</title>
        <link rel="stylesheet" media="screen" href="">
        <style>                        
        * {
        font-size:  ${fontsize} !important; 
      }
        .table_class {
          background-color: rgb(108, 180, 248);
        }
        table.report-container {
          page-break-after:always;
      }
      thead.report-header {
          display:table-header-group;
      }
      tfoot.report-footer {
          display:table-footer-group;
      } 
      tfoot > tr > td.multiColumn {
        position: relative;
        z-index: 1;
        border:none !important;
        }
      p{
        margin:0;
      }
      table { page-break-inside:auto }
      tr{ page-break-inside:avoid; page-break-after:auto }
        *{
          font-size:15px
        }
           .noheader_cls{
          margin-top: ${margin_top}!important;
          }
        </style>
      </head>
      <body onload="window.print(); window.onfocus=function(){ window.close();}">
      ${printContents}
      </body>
    </html>`);
    popupWin.document.close();
  }




}




