<!-- web page -->
<div class="row" style="width:100%;margin:0 auto;">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white" style="padding:7px 20px;">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h6 class="m-0" class="mainHeadingStyle">Discharge Summary</h6>
          </div>
          <div class="headerButtons">
            <a (click)="goToPatientlist()"><img src="../../../assets/ui_icons/buttons/Back_button.svg"
                class="saveimgbtn_inpatinfo" /></a>
            <!-- [hidden]="!confirm_btn && !updateFlag" -->
            <a> <img (click)="print_area()" src="../../../assets/img/printer.svg" width="25px" height="25px" /> </a>

            
            <a style="margin-right: 5px;" (click)="view_prescription()" *ngIf="viewpres"><img
                src="../../../assets/ui_icons/buttons/view_RX_button.svg" class="viewrximgbtn_inpatinfo" /></a>
            
                <a style="margin-right: 5px;" (click)="Write_prescription()" *ngIf="medicalprescription"><img
                src="../../../assets/ui_icons/buttons/write_RX_Button.svg" class="writerximgbtn_inpatinfo" /></a>
            
                <a [hidden]="confirm_btn" (click)="saveDischargeSummary()"><img
                src="../../../assets/ui_icons/buttons/save_button.svg" class="saveimgbtn_inpatinfo" /></a>


            <a [hidden]="!confirm_btn" (click)="updateDischarge()"><img
                src="../../../assets/ui_icons/buttons/update_button.svg" class="saveimgbtn_inpatinfo" /></a>
            <!-- <a style="margin-right: 5px;" [hidden]="update_btn" (click)="Write_prescription()"><img src="../../../assets/ui_icons/buttons/write_RX_Button.svg" class="writerximgbtn_inpatinfo"/></a> -->
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="row">
          <div class="Details">
            <div class="cover_div" style="padding-bottom: 13px;">
              <div class="header_lable">Personal details</div>
              <div class="content_cover">
                <div class="row" style="">
                  <div class="col-lg-6 col-xl-6 col-md-6 col-sm-12">
                    <div class="row">
                      <div class="col-3 headernew"><span style="font-weight: 550;">MR No</span></div>
                      <div class="col-1">:</div>
                      <div class="col-8 optionnew">{{patient_identifier}}</div>
                      <div class="col-3 headernew"><span style="font-weight: 550;">Patient Name</span></div>
                      <div class="col-1">:</div>
                      <div class="col-8 optionnew">{{patient_name}}</div>
                      <div class="col-3 headernew"><span style="font-weight: 550;">Age/Gender</span></div>
                      <div class="col-1">:</div>
                      <div class="col-8 optionnew">{{age}}/{{gender}}</div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-xl-6 col-md-6 col-sm-12">
                    <div class="row">
                      <div class="col-4 headernew"><span style="font-weight: 550;">DOA</span></div>
                      <div class="col-1">:</div>

                      <div class="col-7 optionnew">{{admis_date}} {{admis_time}}</div>
                      <div class="col-4 headernew"><span style="font-weight: 550;">DOD</span></div>
                      <div class="col-1">:</div>


                      <div class="col-7 optionnew">{{dis_date}} {{dis_time}}</div>
                      <div class="col-4 headernew"><span style="font-weight: 550;">Consultant(s)</span></div>
                      <div class="col-1">:</div>
                      <div class="col-7 optionnew">
                        <table class="table1" style="width: 100%;">
                          <tr *ngFor="let name of consultarray">
                            <td style="text-align: left;font-size: 12px;">{{name.name}}</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="cover_div" style="padding-bottom: 13px;" *ngIf="surgery_date != undefined">
              <div class="header_lable">Surgery details</div>
              <div class="content_cover">
                <div class="row " style="margin-top: 5px;margin-bottom: 5px;" *ngIf="surgery_date != undefined">
                  <div class="col-lg-6 col-xl-6 col-md-6 col-sm-12">
                    <div class="row">
                      <div class="col-3 headernew"><span style="font-weight: 550;">Surgery name</span></div>
                      <div class="col-1">:</div>
                      <div class="col-8 optionnew">{{surgery_name}}</div>
                      <div class="col-3 headernew" *ngIf="reportlistarray.surgery_date_req == 1"><span
                          style="font-weight: 550;">{{reportlistarray.surgery_date_desc}}</span></div>
                      <div class="col-1" *ngIf="reportlistarray.surgery_date_req == 1">:</div>
                      <div class="col-8 optionnew" *ngIf="reportlistarray.surgery_date_req == 1">{{surgery_date}}</div>
                      <div class="col-3 headernew" *ngIf="reportlistarray.surgeon_req == 1"><span
                          style="font-weight: 550;">{{reportlistarray.surgeon_desc}}</span></div>
                      <div class="col-1" *ngIf="reportlistarray.surgeon_req == 1">:</div>
                      <div class="col-8 optionnew" *ngIf="reportlistarray.surgeon_req == 1">
                        <table>
                          <tr *ngFor="let surgeon of surgeon_list;">
                            <td style="text-align: left;font-size: 12px;" class="matlabel">{{surgeon.name}}</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-xl-6 col-md-6 col-sm-12">
                    <div class="row">
                      <div class="col-3 headernew" *ngIf="reportlistarray.anaestetist_req == 1"><span
                          style="font-weight: 550;">{{reportlistarray.anaestetist_desc}}</span></div>
                      <div class="col-1" *ngIf="reportlistarray.anaestetist_req == 1">:</div>
                      <div class="col-8 optionnew" *ngIf="reportlistarray.anaestetist_req == 1">
                        <table>
                          <tr *ngFor="let anaest of anaestetist;">
                            <td style="text-align: left;font-size: 12px;" class="matlabel">{{anaest.name}}</td>
                          </tr>
                        </table>
                      </div>
                      <div class="col-3 headernew" *ngIf="reportlistarray.anaestesia_req == 1"><span
                          style="font-weight: 550;">{{reportlistarray.anaestesia_desc}}</span></div>
                      <div class="col-1" *ngIf="reportlistarray.anaestesia_req == 1">:</div>
                      <div class="col-8 optionnew" *ngIf="reportlistarray.surgeon_req == 1">{{anaestetype}}</div>

                      <div class="col-3 headernew" *ngIf="reportlistarray.surgery_procedure_req == 1"><span
                          style="font-weight: 550;">{{reportlistarray.surgery_procedure_desc}}</span></div>
                      <div class="col-1" *ngIf="reportlistarray.surgery_procedure_req == 1">:</div>
                      <div class="col-8 optionnew" *ngIf="reportlistarray.surgery_procedure_req == 1">{{surgery_proc}}
                      </div>
                      <div class="col-3 headernew" *ngIf="reportlistarray.anaestesia_req == 1"><span
                          style="font-weight: 550;">{{reportlistarray.anaestesia_procedure_desc}}</span></div>
                      <div class="col-1" *ngIf="reportlistarray.anaestesia_req == 1">:</div>
                      <div class="col-8 optionnew" *ngIf="reportlistarray.surgeon_req == 1"> {{anaest_proc}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="cover_div" style="padding-bottom: 13px;" *ngIf="complaints != undefined && reportlistarray.chief_complaints_req == 1" >
              <div class="header_lable">{{reportlistarray.chief_complaints_desc}}</div>
              <div class="content_cover">
                <div class="row">
                  <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12">
                    <div class="row" *ngIf="complaints != undefined && reportlistarray.chief_complaints_req == 1 ">
                      <div class="col-sm-6 col-lg-2 col-xl-2 col-md-6">
                        <!-- <mat-label class="matlabel"><b class="label_bold">{{reportlistarray.chief_complaints_desc}} :</b>
                        </mat-label> -->
                      </div>
                      <div style="margin-top:10px;text-align: justify;" [innerHTML]="complaints"></div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <!-- Newly added -->
            <div class="cover_div" *ngIf="pres_ill != undefined && reportlistarray.present_illness_req == 1">
              <div class="header_lable">{{reportlistarray.present_illness_desc}}</div>
              <div class="content_cover">
                <div class="row">
                  <div class="col-sm-6 col-lg-2 col-xl-4 col-md-6">
                  </div>
                  <div style="margin-top:10px;text-align: justify;" [innerHTML]="pres_ill"></div>
                </div>
              </div>
            </div>

            <div class="cover_div" *ngIf="medical_hist != undefined && reportlistarray.prev_med_hist_req == 1">
              <div class="header_lable">{{reportlistarray.prev_med_hist_desc}}</div>
              <div class="content_cover">
                <div class="row">
                  <div class="col-sm-6 col-lg-2 col-xl-4 col-md-6">

                  </div>
                  <div style="margin-top:10px;text-align: justify;" [innerHTML]="medical_hist"></div>
                </div>

              </div>
            </div>

            <!-- <div class="cover_div" *ngIf="physical_exam != undefined && reportlistarray.physical_exam_req == 1">
                <div class="header_lable">{{reportlistarray.physical_exam_desc}}</div>
                  <div class="content_cover">
                    <div class="row" >
                      <div class="col-sm-6 col-lg-2 col-xl-2 col-md-6">
                      </div>
                      <div style="margin-top:10px;text-align: justify;" [innerHTML]="physical_exam"></div>
                    </div>
                    </div>
                </div> -->

            <div class="cover_div" style="padding-bottom: 13px;" *ngIf="reportlistarray.vitals_req == 1">
              <div class="header_lable">{{reportlistarray.vitals_desc}}</div>
              <div class="content_cover">
                <div class="row" *ngIf="reportlistarray.vitals_req == 1">
                  <div class="col-3" *ngIf="reportlistarray.height_req == 1">
                    <p class="textformat"><span style="font-weight: 550;">Height:</span> {{height}} {{height_measure}}
                    </p>
                  </div>
                  <div class="col-3" *ngIf="reportlistarray.weight_req == 1">
                    <p class="textformat"><span style="font-weight: 550;">Weight:</span> {{weight}} {{weight_measure}}
                    </p>
                  </div>

                  <div class="col-3" *ngIf="reportlistarray.weight_req == 1 && reportlistarray.height_req == 1">
                    <p class="textformat"><span style="font-weight: 550;">BMI:</span> {{bmi}} </p>
                  </div>
                  <div class="col-3" *ngIf="reportlistarray.weight_req == 1 && reportlistarray.height_req == 1">
                    <p class="textformat"><span style="font-weight: 550;">BMR:</span> {{bmr}}</p>
                  </div>

                  <div class="col-3" *ngIf="reportlistarray.bp_req == 1">
                    <p class="textformat"><span style="font-weight: 550;">{{reportlistarray.bp_desc}}:</span>
                      {{blood_pressure}}</p>
                  </div>
                  <div class="col-3" *ngIf="reportlistarray.temparature_req == 1">
                    <p class="textformat"><span style="font-weight: 550;">Temp:</span> {{temparature}}</p>
                  </div>
                  <div class="col-3" *ngIf="reportlistarray.pulse_req == 1">
                    <p class="textformat"><span style="font-weight: 550;">{{reportlistarray.pulse_desc}}:</span>
                      {{pulse}} bpm</p>
                  </div>
                  <div class="col-3" *ngIf="reportlistarray.spo2_req == 1">
                    <p class="textformat"><span style="font-weight: 550;">{{reportlistarray.spo2_desc}}:</span>
                      {{this.spo2}}</p>
                  </div>

                  <div class="col-3" *ngIf="reportlistarray.cvs_req == 1">
                    <p class="textformat"><span style="font-weight: 550;">{{reportlistarray.cvs_desc}}:</span> {{cvs}}
                    </p>
                  </div>
                  <div class="col-3" *ngIf="reportlistarray.abd_req == 1">
                    <p class="textformat"><span style="font-weight: 550;">{{reportlistarray.abd_desc}}:</span>
                      {{abdtxt}}</p>
                  </div>
                  <div class="col-3" *ngIf="reportlistarray.cns_req == 1">
                    <p class="textformat"><span style="font-weight: 550;">{{reportlistarray.cns_desc}}:</span> {{cns}}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-6" *ngIf="reportlistarray.diagnosis_req == 1">
                <div class="row">
                  <div class="col-12 margin_10">

                    <mat-label class="matlabel"><b class="label_bold">{{
                        reportlistarray.diagnosis_desc}}</b></mat-label>
                  </div>
                  <div class="col-12">
                    <quill-editor id="editor1" [(ngModel)]="dis_diag" [style]="editorStyle" [modules]="modules"
                      placeholder="Enter Text" (onContentChanged)="onContentChanged($event)"></quill-editor>
                    <div>
                      <!-- Preview -->
                      <quill-view-html hidden id="viewhtml1" [content]="dis_diag"></quill-view-html>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6" *ngIf="reportlistarray.treatment_req == 1">
                <div class="row">
                  <div class="col-12 margin_10">
                    <mat-label class="matlabel"><b class="label_bold">{{reportlistarray.treatment_desc}}
                      </b></mat-label>
                  </div>
                  <div class="col-12">
                    <quill-editor id="editor1" [(ngModel)]="Treatmetnt_done" [style]="editorStyle" [modules]="modules"
                      placeholder="Enter Text" (onContentChanged)="onContentChangedTreatment($event)"></quill-editor>
                    <div>
                      <!-- Preview -->
                      <quill-view-html hidden id="viewhtml1" [content]="Treatmetnt_done"></quill-view-html>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6" *ngIf="reportlistarray.summary_course_req == 1">
                <div class="row">
                  <div class="col-12 margin_10">
                    <mat-label class="matlabel"><b class="label_bold">{{reportlistarray.summary_course_desc}}</b>
                    </mat-label>
                  </div>
                  <div class="col-12">
                    <quill-editor id="editor1" [(ngModel)]="hosp_course" [style]="editorStyle" [modules]="modules"
                      placeholder="Enter Text" (onContentChanged)="onContentChangedHospitalSummary($event)">
                    </quill-editor>
                    <div>
                      <!-- Preview -->
                      <quill-view-html hidden id="viewhtml1" [content]="hosp_course"></quill-view-html>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6" *ngIf="reportlistarray.investigation_req == 1">
                <div class="row">
                  <div class="col-12 margin_10 ">
                    <mat-label class="matlabel"><b class="label_bold">{{reportlistarray.investigation_desc}}</b>
                    </mat-label>
                  </div>
                  <div class="col-12">
                    <quill-editor id="editor1" [(ngModel)]="investigation" [style]="editorStyle" [modules]="modules"
                      placeholder="Enter Text" (onContentChanged)="onContentChangedInvestigation($event)">
                    </quill-editor>
                    <div>
                      <!-- Preview -->
                      <quill-view-html hidden id="viewhtml1" [content]="investigation"></quill-view-html>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6" *ngIf="reportlistarray.emerygency_req == 1">
                <div class="row">
                  <div class="col-12 margin_10">
                    <mat-label class="matlabel"><b class="label_bold">{{reportlistarray.emergency_desc}}</b></mat-label>
                  </div>
                  <div class="col-12">
                    <!-- <textarea class="ipcss" maxlength="500" [(ngModel)]="emergency_notes"></textarea> -->
                    <quill-editor id="editor1" [(ngModel)]="emerg_contact" [style]="editorStyle" [modules]="modules"
                      placeholder="Enter Text" (onContentChanged)="onContentChangedEmergency($event)"></quill-editor>
                    <div>
                      <!-- Preview -->
                      <quill-view-html hidden id="viewhtml1" [content]="emerg_contact"></quill-view-html>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6" *ngIf="reportlistarray.discharge_advice_req == 1">
                <div class="row">
                  <div class="col-12 margin_10">
                    <mat-label class="matlabel"><b class="label_bold">{{reportlistarray.discharge_advice_desc}}</b>
                    </mat-label>
                  </div>
                  <div class="col-12">
                    <!-- <textarea class="ipcss" maxlength="500" [(ngModel)]="hospital_followup"></textarea> -->
                    <quill-editor id="editor1" [(ngModel)]="hospital_followup" [style]="editorStyle" [modules]="modules"
                      placeholder="Enter Text" (onContentChanged)="onContentChangedDischargeAdvice($event)">
                    </quill-editor>
                    <div>
                      <!-- Preview -->
                      <quill-view-html hidden id="viewhtml1" [content]="hospital_followup"></quill-view-html>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-6" *ngIf="reportlistarray.diet_adv_req == 1">
                <div class="row">
                  <div class="col-12 margin_10">
                    <mat-label class="matlabel"><b class="label_bold">{{reportlistarray.diet_adv_desc}}</b>
                    </mat-label>
                  </div>
                  <div class="col-12">
                    <!-- <textarea class="ipcss" maxlength="500" [(ngModel)]="hospital_followup"></textarea> -->
                    <quill-editor id="editor1" [(ngModel)]="dietaryadvice" [style]="editorStyle" [modules]="modules"
                      placeholder="Enter Text" (onContentChanged)="onContentChangeddeit($event)">
                    </quill-editor>
                    <div>
                      <!-- Preview -->
                      <quill-view-html hidden id="viewhtml1" [content]="dietaryadvice"></quill-view-html>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-6" *ngIf="reportlistarray.physical_exam_req == 1">
                <div class="row">
                  <div class="col-12 margin_10">
                    <mat-label class="matlabel"><b class="label_bold">{{reportlistarray.physical_exam_desc}}</b>
                    </mat-label>
                  </div>
                  <div class="col-12">
                    <!-- <textarea class="ipcss" maxlength="500" [(ngModel)]="hospital_followup"></textarea> -->
                    <quill-editor id="editor1" [(ngModel)]="physical_exam" [style]="editorStyle" [modules]="modules"
                      placeholder="Enter Text" (onContentChanged)="onContentgeneralexa($event)">
                    </quill-editor>
                    <div>
                      <!-- Preview -->
                      <quill-view-html hidden id="viewhtml1" [content]="physical_exam"></quill-view-html>
                    </div>
                  </div>
                </div>
              </div>


              <div class="col-6" *ngIf="reportlistarray.endoscopy_req == 1">
                <div class="row">
                  <div class="col-12 margin_10">
                    <mat-label class="matlabel"><b class="label_bold">{{reportlistarray.endoscopy_desc}}</b>
                    </mat-label>
                  </div>
                  <div class="col-12">
                    <quill-editor id="editor1" [(ngModel)]="endoscopy" [style]="editorStyle" [modules]="modules"
                      placeholder="Enter Text" (onContentChanged)="onContentChangedEndo($event)">
                    </quill-editor>
                    <div>
                      <!-- Preview -->
                      <quill-view-html hidden id="viewhtml1" [content]="endoscopy"></quill-view-html>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-6" *ngIf="reportlistarray.colonoscopy_req == 1">
                <div class="row">
                  <div class="col-12 margin_10">
                    <mat-label class="matlabel"><b class="label_bold">{{reportlistarray.colonoscopy_desc}}</b>
                    </mat-label>
                  </div>
                  <div class="col-12">
                    <quill-editor id="editor1" [(ngModel)]="colonoscopy" [style]="editorStyle" [modules]="modules"
                      placeholder="Enter Text" (onContentChanged)="onContentChangedcolon($event)">
                    </quill-editor>
                    <div>
                      <!-- Preview -->
                      <quill-view-html hidden id="viewhtml1" [content]="colonoscopy"></quill-view-html>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-6" *ngIf="reportlistarray.ct_scan_req == 1">
                <div class="row">
                  <div class="col-12 margin_10">
                    <mat-label class="matlabel"><b class="label_bold">{{reportlistarray.ct_scan_desc}}</b>
                    </mat-label>
                  </div>
                  <div class="col-12">
                    <quill-editor id="editor1" [(ngModel)]="ct_scan" [style]="editorStyle" [modules]="modules"
                      placeholder="Enter Text" (onContentChanged)="onContentChangedCtscan($event)">
                    </quill-editor>
                    <div>
                      <!-- Preview -->
                      <quill-view-html hidden id="viewhtml1" [content]="ct_scan"></quill-view-html>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-6" *ngIf="reportlistarray.mri_scan_req == 1">
                <div class="row">
                  <div class="col-12 margin_10">
                    <mat-label class="matlabel"><b class="label_bold">{{reportlistarray.mri_scan_desc}}</b>
                    </mat-label>
                  </div>
                  <div class="col-12">
                    <quill-editor id="editor1" [(ngModel)]="mri_scan" [style]="editorStyle" [modules]="modules"
                      placeholder="Enter Text" (onContentChanged)="onContentChangedMri($event)">
                    </quill-editor>
                    <div>
                      <!-- Preview -->
                      <quill-view-html hidden id="viewhtml1" [content]="mri_scan"></quill-view-html>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-6" *ngIf="reportlistarray.doppler_req == 1">
                <div class="row">
                  <div class="col-12 margin_10">
                    <mat-label class="matlabel"><b class="label_bold">{{reportlistarray.doppler_desc}}</b>
                    </mat-label>
                  </div>
                  <div class="col-12">
                    <quill-editor id="editor1" [(ngModel)]="doppler" [style]="editorStyle" [modules]="modules"
                      placeholder="Enter Text" (onContentChanged)="onContentChangedDropler($event)">
                    </quill-editor>
                    <div>
                      <!-- Preview -->
                      <quill-view-html hidden id="viewhtml1" [content]="doppler"></quill-view-html>
                    </div>
                  </div>
                </div>
              </div>


              <div class="cover_div" style="padding-bottom: 13px; margin-top: 20px;" [hidden]="med_history.length == 0">
                <div class="header_lable">{{reportlistarray.drug_hist_desc}}</div>
                <div class="content_cover">
                  <div class="row">
                    <div class="col-12">
                      <div class="table-responsive dig_table_overflowmed">
                        <table *ngIf="med_history.length" class="table table-nowrap table-sm dataTable billlisttable">
                          <thead>
                            <tr>
                              <th style="width: 200px;"><b>Medicine</b></th>
                              <th style="width: 100px;">Morning - After - Eveng- night </th>
                              <th style="width: 100px;"><b>Dosage</b></th>
                              <!-- <th style="width: 150px;"><b>Days</b></th> -->
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let product of med_history">
                              <td>{{product.product_name}}</td>
                              <td style="text-align: center">{{product.morning}} - {{product.afternoon}} -
                                {{product.evening}} - {{product.night}}</td>
                              <td style="text-align: center">{{product.time_duration}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div class="col-12">
                <div class="row">
                  <div class="col-12 margin_10">
                    <mat-label class="matlabel">Next visit</mat-label>
                  </div>
                  <div class="col-3 col-md-3 col-xl-3 col-lg-3">
                    <input type="date" class="ipcss_date" id="appt_date" (change)="NextDate(next_data)"
                      [(ngModel)]="next_data" min="{{currentDate}}" #matInput>
                  </div>
                </div>
              </div>

              <div class="cover_div" style="padding-bottom: 13px; margin-top: 20px;" [hidden]="druglist.length==0">
                <div class="header_lable">{{reportlistarray.medicine_desc}}</div>
                <div class="content_cover">
                  <div class="row">
                    <div class="col-12">
                      <div class="table-responsive dig_table_overflow">
                        <table *ngIf="druglist.length" class="table table-nowrap table-sm dataTable billlisttable">
                          <thead>
                            <tr>
                              <th style="width: 200px;"><b>Medicine</b></th>
                              <th style="width: 100px;"><b>Dosage</b></th>
                              <th style="width: 150px;"><b>Days</b></th>

                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let product of druglist">
                              <td>{{product.medicine_name}}</td>
                              <td style="text-align: center">{{product.medicine_dosage}}</td>
                              <td style="text-align: center">{{product.medicine_intakedays}} - {{product.medicine_food}}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="cover_div" style="padding-bottom: 13px; margin-top: 20px;" [hidden]="diag_tests.length==0">
                <div class="header_lable">Diagnosis tests</div>
                <div class="content_cover">
                  <div class="row">
                    <div class="col-12">
                      <div class="table-responsive dig_table_overflow">
                        <table *ngIf="diag_tests.length" class="table table-nowrap table-sm dataTable billlisttable">
                          <thead>
                            <tr>
                              <th style="width: 200px;"><b>Testname</b></th>
                              <th style="width: 100px;"><b>Department</b></th>
                              <th style="width: 150px;"><b>Test category</b></th>

                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let test of diag_tests">
                              <td>{{test.test_name}}</td>
                              <td style="text-align: center">{{test.department}}</td>
                              <td style="text-align: center">{{test.test_cat}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>

<!-- end of print section -->
<div [hidden]="true">

  <div #printlogowithname id="printlogowithname">
    <table style="border:'0';width:100%">
      <thead>
        <tr>
          <th style="width:100%;">
            <div>
              <table style="width: 100%; ">
                <tr>
                  <td style="display: flex;justify-content: center;">
                    <div>
                      <img alt="image" src={{hospital_logo}} width="100px" height="100px"
                        style="vertical-align: middle;">
                    </div>
                    <div style="position: relative;top: 20px;left: 10px;text-align: left;">
                      <span><b>{{hosp_name}}</b>,</span><br />
                      <span *ngIf="hosp_addr != ''">{{hosp_addr}}</span><br />
                      <span>{{hosp_csz}}</span><br />
                      <span *ngIf="hosp_tel != undefined"><img src="../../../assets/img/phone.png" width="15px"
                          height="15px" />{{hosp_tel}}.</span><br>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </th>
        </tr>
      </thead>
      <tfoot>
        <tr>
          <td width="100%">
            <table style="border:'0';width:100%">
              <tr>
                <td style="width:100%;height: 100px;">&nbsp;</td>
              </tr>
            </table>
      </tfoot>
      <tbody>
        <tr>
          <td width="100%">
            <table style="margin-left:10px;margin-top:-100px;margin-right: 10px;">
              <tr>
                <td>
                  <div style="height: 100px;">&nbsp;</div>
                </td>
              </tr>
            </table>
            <br /><br />
            <div
              style="display: flex; justify-content: space-between; margin-left: 10px;padding: 10px; margin-top: 10px; margin-bottom: 10px; border: 1px solid black; border-radius: 10px;">
              <table style="width: 55%;vertical-align: top !important;">
                <tbody>
                  <tr>
                    <td style="vertical-align: top !important;"><strong>Patient Name</strong></td>
                    <td style="vertical-align: top !important;">: {{patient_name}}</td>
                  </tr>
                  <tr>
                    <td><strong>Age / Gender</strong></td>
                    <td>: {{age}} {{gender}}</td>
                  </tr>
                  <tr>
                    <td><strong>Inpatient ID</strong></td>
                    <td>: {{inpId}}</td>
                  </tr>
                  <tr>
                    <td style="vertical-align: top !important;"><strong>Address</strong></td>
                    <td style="vertical-align: top !important;">: {{fulladdress}}</td>
                  </tr>
                  <tr>
                    <td style="vertical-align: top !important;"><strong>Consultant(s)</strong></td>
                    <td style="text-align: left;vertical-align: top !important;"> :
                      <div *ngFor="let name of consultarray">
                        <p style="text-align: left;">{{name.name}}</p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table style="width: 45%;vertical-align: top !important;height: 0%;">
                <tbody>
                  <tr>
                    <td style="vertical-align: top !important;width: 30px;"><strong>DOA</strong></td>
                    <td style="vertical-align: top !important;"> : {{admis_date}} {{admis_time}}</td>
                  </tr>
                  <tr>
                    <td style="vertical-align: top !important;width: 30px;"><strong>DOD</strong></td>
                    <td style="vertical-align: top !important;"> : {{dis_date}} {{dis_time}}</td>
                  </tr>
                  <tr>
                    <td style="vertical-align: top !important;column-span:2;">
                      <div style="margin-left: -10px;">
                        <div class="d-flex align-items-center justify-content-center">
                          <ngx-barcode class="barcode" [bc-value]="patient_identifier" [bc-display-value]="displayValue"
                            [bc-line-color]="lineColor" [element-type]="elementType" [format]="format"
                            [bc-width]="width" [bc-height]="barcodeheight" [bc-font-options]="fontOptions"
                            [bc-font]="font" [bc-text-align]="textAlign" [bc-text-position]="textPosition"
                            [bc-text-margin]="textMargin" [bc-font-size]="fontSize" [bc-background]="background"
                            [bc-margin]="margin" [bc-margin-top]="marginTop" [bc-margin-bottom]="marginBottom"
                            [bc-margin-left]="marginLeft" [bc-margin-right]="marginRight"></ngx-barcode>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <table style="width: 90%; margin: 0 auto;">
              <tr>
                <td style="{{reportlistarray.heading_style}}" colspan="2">
                  {{reportlistarray.discharge_heading}}
                </td>
              </tr>
            </table>

            <div class="block1_grid" style="margin-left: 25px !important;margin-right:25px !important;width:90%;">
              <ng-container *ngFor="let section of sections">
                <ng-container *ngTemplateOutlet="section.template"></ng-container>
              </ng-container>
            </div>

            <ng-template #vitalstemp>
              <!-- margin-left: 25px !important; -->
              <div style="margin-right:25px !important; width:90%;" [hidden]="reportlistarray.vitals_req ==0">
                <div>
                  <p [style]="header_table_style">{{reportlistarray.vitals_desc}}:</p>
                </div>
                <div class="content_cover">
                  <div class="row" *ngIf="reportlistarray.vitals_req == 1">
                    <div class="col-3" *ngIf="reportlistarray.height_req == 1">
                      <p class="textformat"><span style="font-weight: 550;">Height:</span> {{height}} {{height_measure}}
                      </p>
                    </div>
                    <div class="col-3" *ngIf="reportlistarray.weight_req == 1">
                      <p class="textformat"><span style="font-weight: 550;">Weight:</span> {{weight}} {{weight_measure}}
                      </p>
                    </div>

                    <div class="col-3" *ngIf="reportlistarray.weight_req == 1 && reportlistarray.height_req == 1">
                      <p class="textformat"><span style="font-weight: 550;">BMI:</span> {{bmi}} </p>
                    </div>
                    <div class="col-3" *ngIf="reportlistarray.weight_req == 1 && reportlistarray.height_req == 1">
                      <p class="textformat"><span style="font-weight: 550;">BMR:</span> {{bmr}}</p>
                    </div>

                    <div class="col-3" *ngIf="reportlistarray.bp_req == 1">
                      <p class="textformat"><span style="font-weight: 550;">{{reportlistarray.bp_desc}}:</span>
                        {{blood_pressure}}</p>
                    </div>
                    <div class="col-3" *ngIf="reportlistarray.temparature_req == 1">
                      <p class="textformat"><span style="font-weight: 550;">Temp:</span> {{temparature}}</p>
                    </div>
                    <div class="col-3" *ngIf="reportlistarray.pulse_req == 1">
                      <p class="textformat"><span style="font-weight: 550;">{{reportlistarray.pulse_desc}}:</span>
                        {{pulse}} bpm</p>
                    </div>
                    <div class="col-3" *ngIf="reportlistarray.rr_req == 1">
                      <p class="textformat"><span style="font-weight: 550;">{{reportlistarray.rr_desc}}:</span>
                        {{resp_rate}} bpm</p>
                    </div>
                    <div class="col-3" *ngIf="reportlistarray.spo2_req == 1">
                      <p class="textformat"><span style="font-weight: 550;">{{reportlistarray.spo2_desc}}:</span>
                        {{this.spo2}}</p>
                    </div>
                    <div class="col-3" *ngIf="reportlistarray.cvs_req == 1">
                      <p class="textformat"><span style="font-weight: 550;">{{reportlistarray.cvs_desc}}:</span> {{cvs}}
                      </p>
                    </div>
                    <div class="col-3" *ngIf="reportlistarray.abd_req == 1">
                      <p class="textformat"><span style="font-weight: 550;">{{reportlistarray.abd_desc}}:</span>
                        {{abdtxt}}</p>
                    </div>
                    <div class="col-3" *ngIf="reportlistarray.cns_req == 1">
                      <p class="textformat"><span style="font-weight: 550;">{{reportlistarray.cns_desc}}:</span> {{cns}}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>

            <!-- diagnosis_desc -->
            <ng-template #diagnosis>
              <div style="margin-bottom: 10px;" *ngIf="dis_diag_flag" [hidden]="reportlistarray.diagnosis_req ==0">
                <mat-label class="matlabel" style="font-weight: 600;">{{reportlistarray.diagnosis_desc}}
                  :</mat-label>
                <div style="margin-top:1px;text-align: justify;" [innerHTML]="dis_diag">
                </div>
              </div>
            </ng-template>

            <!--treatment_desc  -->
            <ng-template #treatment>
              <div style="margin-bottom: 10px;" *ngIf="treat_flag" [hidden]="reportlistarray.treatment_req ==0">
                <mat-label class="matlabel" style="font-weight: 600;">{{reportlistarray.treatment_desc}}
                  :
                </mat-label>
                <div style="margin-top:1px;text-align: justify;" [innerHTML]="Treatmetnt_done">
                </div>
              </div>
            </ng-template>

            <!-- history of present illness --> <!-- dietry -->
            <ng-template #present_illne>
              <div style="margin-bottom: 10px;" *ngIf="this.presentillness" [hidden]="reportlistarray.present_illness_req ==0">
                <mat-label class="matlabel" style="font-weight: 600;">{{reportlistarray.present_illness_desc}}
                  :
                </mat-label>
                <div style="margin-top:1px;text-align: justify;" [innerHTML]="pres_ill">
                </div>
              </div>
            </ng-template>


            <ng-template #diet_advice>
              <div style="margin-bottom: 10px;" *ngIf="dietaryadviceflag" [hidden]="reportlistarray.diet_adv_req ==0">
                <mat-label class="matlabel" style="font-weight: 600;">{{reportlistarray.diet_adv_desc}}
                  :
                </mat-label>
                <div style="margin-top:1px;text-align: justify;" [innerHTML]="dietaryadvice">
                </div>
              </div>
            </ng-template>
            
            <!-- Chief_complaints -->
            <ng-template #chief_complaints>
              <div style="margin-bottom: 10px;" *ngIf="comp_flag" [hidden]="reportlistarray.chief_complaints_req ==0">
                <mat-label class="matlabel" style="font-weight: 600;">{{reportlistarray.chief_complaints_desc}} :
                </mat-label>
                <div style="text-align: justify;" [innerHTML]="complaints"></div>
              </div>
            </ng-template>

            <!-- medical_history_desc -->
            <ng-template #medical_histtemp>
              <div style="margin-bottom: 10px;" *ngIf="prev_med_flag" [hidden]="reportlistarray.medical_history_req ==0">
                <mat-label class="matlabel" style="font-weight: 600;">{{reportlistarray.medical_history_desc}}
                  :
                </mat-label>
                <div style="text-align: justify;" [innerHTML]="medical_hist">
                </div>
              </div>
            </ng-template>

            <!-- physical_exam_desc -->
            <ng-template #physical_examtemp>
              <div style="margin-bottom: 10px;" *ngIf="phy_exam_flag" [hidden]="reportlistarray.physical_exam_req ==0">
                <mat-label class="matlabel" style="font-weight: 600;">{{reportlistarray.physical_exam_desc}} :
                </mat-label>
                <div style="text-align: justify;" [innerHTML]="physical_exam">
                </div>
              </div>
            </ng-template>

            <!-- endoscopy_desc -->
            <!-- <ng-template #endoscopytemp>
                <div style="margin-bottom: 10px;" *ngIf="endoscopy_flag">
                  <mat-label class="matlabel" style="font-weight: 600;">{{reportlistarray.endoscopy_desc}} :
                  </mat-label>
                  <div style="text-align: justify;" [innerHTML]="endoscopy">
                  </div>
                </div>
                </ng-template> -->

            <!-- colonoscopy_desc -->
            <!-- <ng-template #colonoscopytemp>
                <div style="margin-bottom: 10px;" *ngIf="colonoscopy_flag">
                  <mat-label class="matlabel" style="font-weight: 600;">{{reportlistarray.colonoscopy_desc}} :
                  </mat-label>
                  <div style="text-align: justify;" [innerHTML]="colonoscopy">
                  </div>
                </div>
                </ng-template> -->

            <!-- ct_scan_desc -->
            <!-- <ng-template #ct_scantemp>
                <div style="margin-bottom: 10px;" *ngIf="ct_scan_flag">
                  <mat-label class="matlabel" style="font-weight: 600;">{{reportlistarray.ct_scan_desc}} :
                  </mat-label>
                  <div style="text-align: justify;" [innerHTML]="ct_scan">
                  </div>
                </div>
                </ng-template> -->

            <!-- mir_scan_desc -->
            <!-- <ng-template #mi_scantemp>
                <div style="margin-bottom: 10px;" *ngIf="mri_scan_flag">
                  <mat-label class="matlabel" style="font-weight: 600;">{{reportlistarray.mri_scan_desc}} :
                  </mat-label>
                  <div style="text-align: justify;" [innerHTML]="mri_scan">
                  </div>
                </div>
                </ng-template> -->

            <!-- doppler_desc -->
            <!-- <ng-template #doppler_desc>
                <div style="margin-bottom: 10px;" *ngIf="doppler_flag">
                  <mat-label class="matlabel" style="font-weight: 600;">{{reportlistarray.doppler_desc}} :
                  </mat-label>
                  <div style="text-align: justify;" [innerHTML]="doppler">
                  </div>
                </div>
                </ng-template> -->

            <!-- investigation order -->
            <ng-template #investigationtemp>
              <div style="margin-bottom: 10px;" *ngIf="inv_flag" [hidden]="reportlistarray.investigation_req ==0">
                <mat-label class="matlabel" style="font-weight: 600;">{{reportlistarray.investigation_desc}} :
                </mat-label>
                <div style="margin-top:1px;text-align: justify;" [innerHTML]="investigation">
                </div>
              </div>
            </ng-template>

            <!-- ot_notes_desc -->
            <ng-template #ot_notestemp>
              <div style="margin-bottom: 10px;" *ngIf="surgery_date" [hidden]="reportlistarray.ot_notes_req == 0">
                <mat-label class="matlabel" style="font-weight: 600;">{{reportlistarray.ot_notes_desc}} : </mat-label>
                <div style="margin-bottom: 5px;">
                  <span><strong>Surgery name : </strong>{{surgery_name}} </span><br />
                  <span><strong>Surgery date : </strong>{{surgery_date}} </span><br />
                  <span><strong>Surgery : </strong>{{surgery_proc}} </span><br />
                  <span><strong>Surgeon : </strong>
                    <table>
                      <tr *ngFor="let surgeon of surgeon_list;">
                        <td style="text-align: left;" class="matlabel">{{surgeon.name}}
                        </td>
                      </tr>
                    </table>
                  </span><br />
                  <span><strong>Anaestetist :2 </strong>
                    <table>
                      <tr *ngFor="let anaest of anaestetist;">
                        <td style="text-align: left;" class="matlabel">{{anaest.name}}
                        </td>
                      </tr>
                    </table>
                  </span><br />
                  <span><strong>Anaestesia : </strong>{{anaestetype}} </span><br />
                  <span><strong>Findings : </strong>{{anaest_findings}} </span><br />
                  <span><strong>Procedure done : </strong>{{anaest_proc}} </span><br />
                </div>
              </div>
            </ng-template>

            <!-- follow_summary_desc -->
            <ng-template #follow_sum>
              <div style="margin-bottom: 10px;" *ngIf="sum_course_flag" [hidden]="reportlistarray.summary_course_req == 0">
                <mat-label class="matlabel" style="font-weight: 600;"> {{reportlistarray.summary_course_desc}} :
                </mat-label>
                <div style="margin-top:5px;text-align: justify;" [innerHTML]="hosp_course">
                </div>
              </div>
            </ng-template>

            <!-- Discharge Advice -->
            <ng-template #discharge_adv>
              <div style="margin-bottom: 10px;" *ngIf="dis_adv_flag" [hidden]="reportlistarray.discharge_advice_req == 0">
                <mat-label class="matlabel" style="font-weight: 600;">
                 {{reportlistarray.discharge_advice_desc}}
                </mat-label>
                <div style="margin-top:5px;text-align: justify;" [innerHTML]="hospital_followup">
                </div>
              </div>
            </ng-template>

             <!-- In case of emergency -->
             <ng-template #emergencyies>
              <div style="margin-bottom: 10px;" *ngIf="emergency_flag" [hidden]="reportlistarray.emergency_req == 0">
                <mat-label class="matlabel" style="font-weight: 600;"> {{reportlistarray.emergency_desc}}:
                </mat-label>
                <div style="margin-top:5px;text-align: justify;" [innerHTML]="emergency_notes">
                </div>
              </div>
            </ng-template>

            <!-- <div class="block1_grid" style="margin-left: 25px !important;margin-right:25px !important;width:90%;">
            
            </div>

            <div class="block1_grid" style=" margin-left: 25px !important;margin-right:25px !important;margin-top: 1px;width:90%;">
             
            </div> -->
            <ng-template #drug_hist_order>
              <div style="margin-right: 30px !important;" *ngIf="med_history.length !=0" [hidden]="reportlistarray.drug_hist_req == 0">
                <div class="col-12"  *ngIf="med_history.length !=0">
                  <mat-label class="matlabel" style="font-weight: 600;">{{reportlistarray.drug_hist_desc}} :
                  </mat-label>
                  <table id="tbl" style="border:1px solid;width:90%" cellpadding="5" cellspacing="0">
                    <tr style="border:1px solid">
                      <th>Medicine</th>
                      <th>Dosages</th>
                      <th>Days</th>
                    </tr>
                    <tr *ngFor="let medicine of med_history">
                      <td>{{medicine.product_name}}</td>
                      <td>{{medicine.morning}} - {{medicine.afternoon}} - {{medicine.evening}} - {{medicine.night}}</td>
                      <td>{{medicine.time_duration}}</td>
                    </tr>
                  </table>
                </div>
              </div>
              <br />
            </ng-template> 
  
            <ng-template #followup_order>
              <div style="margin-right: 30px !important;" *ngIf="druglist.length !=0" [hidden]="reportlistarray.followup_req == 0">
                <mat-label class="matlabel" style="font-weight: 600;">{{reportlistarray.followup_desc}} : 
                </mat-label>
                <div class="col-12">
                  <table id="tbl" cellpadding="5" cellspacing="0" style="border:1px solid;width:90%">
                    <tr style="border:1px solid">
                      <th>Medicine</th>
                      <th>Dosage</th>
                      <th>Days</th>
                    </tr>
                    <tr *ngFor="let medicine of druglist">
                      <td>{{medicine.medicine_name}}</td>
                      <td>{{medicine.medicine_dosage}}</td>
                      <td>{{medicine.medicine_intakedays+" - "+medicine.medicine_food}}</td>
                    </tr>
                  </table>
                </div>
              </div>
              <br />
              <div style="margin-right: 30px !important;" *ngIf="diag_tests.length !=0">
                <mat-label class="matlabel" style="font-weight: 600;">Next-visit tests : 
                </mat-label>
                <div class="col-12">
                  <table id="tbl" cellpadding="5" cellspacing="0" style="border:1px solid;width:90%">
                    <tr style="border:1px solid">
                      <th>Testname</th>
                      <th>Department</th>
                      <th>Test category</th>
                    </tr>
                    <tr *ngFor="let test of diag_tests">
                      <td>{{test.test_name}}</td>
                      <td>{{test.department}}</td>
                      <td>{{test.test_cat}}</td>
                    </tr>
                  </table>
                </div>
              </div>
            </ng-template>
            <div style="width: 90%; margin-left: 10px;">
              <mat-label class="matlabel"> <b>Next Visit :</b> {{next_data1}}</mat-label>
              <br />
              <br />
              <!-- <mat-label class="matlabel"> <b>In case of emergency contact :</b> {{emerg_contact}}</mat-label> -->
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <br />
    <table style="width: 100%; margin-top: 80px;">
      <tr>
        <td style="width: 50%;"></td>
        <td style="width: 50%;">
          <p style="text-align: center;font-size: 13px"><b>( {{doctor_name}} )</b></p>
        </td>
      </tr>
    </table>
  </div>
  <!-- print page  start-->
  <div #printbanner id="printbanner">
    <table style="border:'0';width:100%; margin-left: 50px !important;margin-right: 50px !important;">
      <thead>
        <tr>
          <th style="width:100%;">
            <table style="margin-left:10px;margin-right: 10px;margin-top: 5px !important;">
              <tr>
                <td>
                  <img alt="image" src={{hptl_pres_logo}} width="750px">
                </td>
              </tr>
            </table>
          </th>
        </tr>
      </thead>
      <tfoot>
        <tr>
          <td width="100%">
            <table style="border:'0';width:100%">
              <tr>
                <td style="width:100%;height: 100px;">&nbsp;</td>
              </tr>
            </table>
          </td>
        </tr>
      </tfoot>
      <tbody>
        <tr>
          <td width="100%">
            <table style="margin-left:10px;margin-top:-140px !important;margin-right: 10px;">
              <tr>
                <td>
                  <div style="height: 100px;">&nbsp;</div>
                </td>
              </tr>
            </table>
            <br /><br />
            <div
              style="display: flex; justify-content: space-between; margin-left: 10px;padding: 10px; margin-top: 10px; margin-bottom: 10px; border: 1px solid black; border-radius: 10px; width: 85% !important;">
              <table style="width: 55%;vertical-align: top !important;">
                <tbody>
                  <tr>
                    <td style="vertical-align: top !important;width: 100px;"><strong>Patient Name</strong></td>
                    <td style="vertical-align: top !important;">: {{patient_name}}</td>
                  </tr>
                  <tr>
                    <td><strong>Age / Gender</strong></td>
                    <td>: {{age}} {{gender}}</td>
                  </tr>
                  <tr>
                    <td><strong>Inpatient ID</strong></td>
                    <td>: {{inpId}}</td>
                  </tr>
                  <tr>
                    <td style="vertical-align: top !important;"><strong>Address</strong></td>
                    <td style="vertical-align: top !important;">: {{fulladdress}}</td>
                  </tr>
                  <tr>
                    <td style="vertical-align: top !important;"><strong>Consultant(s)</strong></td>
                    <td style="text-align: left;vertical-align: top !important;"> :
                      <div *ngFor="let name of consultarray">
                        <p style="text-align: left;margin-bottom: -5px !important;">{{name.name}}</p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table style="width: 45%;vertical-align: top !important;height: 0%;">
                <tbody>
                  <tr>
                    <td style="vertical-align: top !important;width: 30px;"><strong>DOA</strong></td>
                    <td style="vertical-align: top !important;"> : {{admis_date}} {{admis_time}}</td>
                  </tr>
                  <tr>
                    <td style="vertical-align: top !important;width: 30px;"><strong>DOD</strong></td>
                    <td style="vertical-align: top !important;"> : {{dis_date}} {{dis_time}}</td>
                  </tr>
                  <tr>
                    <td style="vertical-align: top !important;column-span:2;">
                      <div style="margin-left: -10px;">
                        <div class="d-flex align-items-center justify-content-center">
                          <ngx-barcode class="barcode" [bc-value]="patient_identifier" [bc-display-value]="displayValue"
                            [bc-line-color]="lineColor" [element-type]="elementType" [format]="format"
                            [bc-width]="width" [bc-height]="barcodeheight" [bc-font-options]="fontOptions"
                            [bc-font]="font" [bc-text-align]="textAlign" [bc-text-position]="textPosition"
                            [bc-text-margin]="textMargin" [bc-font-size]="fontSize" [bc-background]="background"
                            [bc-margin]="margin" [bc-margin-top]="marginTop" [bc-margin-bottom]="marginBottom"
                            [bc-margin-left]="marginLeft" [bc-margin-right]="marginRight"></ngx-barcode>
                          <!-- <img
                          style="width: 130px; height: 35px; margin-left: -12px; margin-top: -25px;"
                          src={{barcode_url}} /> -->
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <table style="width: 90%; margin: 0 auto;">
              <tr>
                <td style="{{reportlistarray.heading_style}}" colspan="2">
                  {{reportlistarray.discharge_heading}}
                </td>
              </tr>
            </table>

            <div class="block1_grid" style="margin-left: 25px !important;margin-right:25px !important;width:90%;">
              <ng-container *ngFor="let section of sections">
                <ng-container *ngTemplateOutlet="section.template"></ng-container>
              </ng-container>
            </div>


            <ng-template #vitalstemp>
              <!-- margin-left: 25px !important; -->
              <div style="margin-right:25px !important; width:90%;" [hidden]="reportlistarray.vitals_req ==0">
                <div>
                  <p [style]="header_table_style">{{reportlistarray.vitals_desc}}:</p>
                </div>
                <div class="content_cover">
                  <div class="row" *ngIf="reportlistarray.vitals_req == 1">
                    <div class="col-3" *ngIf="reportlistarray.height_req == 1">
                      <p class="textformat"><span style="font-weight: 550;">Height:</span> {{height}} {{height_measure}}
                      </p>
                    </div>
                    <div class="col-3" *ngIf="reportlistarray.weight_req == 1">
                      <p class="textformat"><span style="font-weight: 550;">Weight:</span> {{weight}} {{weight_measure}}
                      </p>
                    </div>

                    <div class="col-3" *ngIf="reportlistarray.weight_req == 1 && reportlistarray.height_req == 1">
                      <p class="textformat"><span style="font-weight: 550;">BMI:</span> {{bmi}} </p>
                    </div>
                    <div class="col-3" *ngIf="reportlistarray.weight_req == 1 && reportlistarray.height_req == 1">
                      <p class="textformat"><span style="font-weight: 550;">BMR:</span> {{bmr}}</p>
                    </div>

                    <div class="col-3" *ngIf="reportlistarray.bp_req == 1">
                      <p class="textformat"><span style="font-weight: 550;">{{reportlistarray.bp_desc}}:</span>
                        {{blood_pressure}}</p>
                    </div>
                    <div class="col-3" *ngIf="reportlistarray.temparature_req == 1">
                      <p class="textformat"><span style="font-weight: 550;">Temp:</span> {{temparature}}</p>
                    </div>
                    <div class="col-3" *ngIf="reportlistarray.pulse_req == 1">
                      <p class="textformat"><span style="font-weight: 550;">{{reportlistarray.pulse_desc}}:</span>
                        {{pulse}} bpm</p>
                    </div>
                    <div class="col-3" *ngIf="reportlistarray.rr_req == 1">
                      <p class="textformat"><span style="font-weight: 550;">{{reportlistarray.rr_desc}}:</span>
                        {{resp_rate}} bpm</p>
                    </div>
                    <div class="col-3" *ngIf="reportlistarray.spo2_req == 1">
                      <p class="textformat"><span style="font-weight: 550;">{{reportlistarray.spo2_desc}}:</span>
                        {{this.spo2}}</p>
                    </div>
                    <div class="col-3" *ngIf="reportlistarray.cvs_req == 1">
                      <p class="textformat"><span style="font-weight: 550;">{{reportlistarray.cvs_desc}}:</span> {{cvs}}
                      </p>
                    </div>
                    <div class="col-3" *ngIf="reportlistarray.abd_req == 1">
                      <p class="textformat"><span style="font-weight: 550;">{{reportlistarray.abd_desc}}:</span>
                        {{abdtxt}}</p>
                    </div>
                    <div class="col-3" *ngIf="reportlistarray.cns_req == 1">
                      <p class="textformat"><span style="font-weight: 550;">{{reportlistarray.cns_desc}}:</span> {{cns}}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>

            <!-- diagnosis_desc -->
            <ng-template #diagnosis>
              <div style="margin-bottom: 10px;" *ngIf="dis_diag_flag" [hidden]="reportlistarray.diagnosis_req ==0">
                <mat-label class="matlabel" style="font-weight: 600;">{{reportlistarray.diagnosis_desc}}
                  :</mat-label>
                <div style="margin-top:1px;text-align: justify;" [innerHTML]="dis_diag">
                </div>
              </div>
            </ng-template>

            <!--treatment_desc  -->
            <ng-template #treatment>
              <div style="margin-bottom: 10px;" *ngIf="treat_flag" [hidden]="reportlistarray.treatment_req ==0">
                <mat-label class="matlabel" style="font-weight: 600;">{{reportlistarray.treatment_desc}}
                  :
                </mat-label>
                <div style="margin-top:1px;text-align: justify;" [innerHTML]="Treatmetnt_done">
                </div>
              </div>
            </ng-template>

            <!-- history of present illness --> <!-- dietry -->
            <ng-template #present_illne>
              <div style="margin-bottom: 10px;" *ngIf="this.presentillness" [hidden]="reportlistarray.present_illness_req ==0">
                <mat-label class="matlabel" style="font-weight: 600;">{{reportlistarray.present_illness_desc}}
                  :
                </mat-label>
                <div style="margin-top:1px;text-align: justify;" [innerHTML]="pres_ill">
                </div>
              </div>
            </ng-template>


            <ng-template #diet_advice>
              <div style="margin-bottom: 10px;" *ngIf="dietaryadviceflag" [hidden]="reportlistarray.diet_adv_req ==0">
                <mat-label class="matlabel" style="font-weight: 600;">{{reportlistarray.diet_adv_desc}}
                  :
                </mat-label>
                <div style="margin-top:1px;text-align: justify;" [innerHTML]="dietaryadvice">
                </div>
              </div>
            </ng-template>
            
            <!-- Chief_complaints -->
            <ng-template #chief_complaints>
              <div style="margin-bottom: 10px;" *ngIf="comp_flag" [hidden]="reportlistarray.chief_complaints_req ==0">
                <mat-label class="matlabel" style="font-weight: 600;">{{reportlistarray.chief_complaints_desc}} :
                </mat-label>
                <div style="text-align: justify;" [innerHTML]="complaints"></div>
              </div>
            </ng-template>

            <!-- medical_history_desc -->
            <ng-template #medical_histtemp>
              <div style="margin-bottom: 10px;" *ngIf="prev_med_flag" [hidden]="reportlistarray.medical_history_req ==0">
                <mat-label class="matlabel" style="font-weight: 600;">{{reportlistarray.medical_history_desc}}
                  :
                </mat-label>
                <div style="text-align: justify;" [innerHTML]="medical_hist">
                </div>
              </div>
            </ng-template>

            <!-- physical_exam_desc -->
            <ng-template #physical_examtemp>
              <div style="margin-bottom: 10px;" *ngIf="phy_exam_flag" [hidden]="reportlistarray.physical_exam_req ==0">
                <mat-label class="matlabel" style="font-weight: 600;">{{reportlistarray.physical_exam_desc}} :
                </mat-label>
                <div style="text-align: justify;" [innerHTML]="physical_exam">
                </div>
              </div>
            </ng-template>

            <!-- endoscopy_desc -->
            <!-- <ng-template #endoscopytemp>
                <div style="margin-bottom: 10px;" *ngIf="endoscopy_flag">
                  <mat-label class="matlabel" style="font-weight: 600;">{{reportlistarray.endoscopy_desc}} :
                  </mat-label>
                  <div style="text-align: justify;" [innerHTML]="endoscopy">
                  </div>
                </div>
                </ng-template> -->

            <!-- colonoscopy_desc -->
            <!-- <ng-template #colonoscopytemp>
                <div style="margin-bottom: 10px;" *ngIf="colonoscopy_flag">
                  <mat-label class="matlabel" style="font-weight: 600;">{{reportlistarray.colonoscopy_desc}} :
                  </mat-label>
                  <div style="text-align: justify;" [innerHTML]="colonoscopy">
                  </div>
                </div>
                </ng-template> -->

            <!-- ct_scan_desc -->
            <!-- <ng-template #ct_scantemp>
                <div style="margin-bottom: 10px;" *ngIf="ct_scan_flag">
                  <mat-label class="matlabel" style="font-weight: 600;">{{reportlistarray.ct_scan_desc}} :
                  </mat-label>
                  <div style="text-align: justify;" [innerHTML]="ct_scan">
                  </div>
                </div>
                </ng-template> -->

            <!-- mir_scan_desc -->
            <!-- <ng-template #mi_scantemp>
                <div style="margin-bottom: 10px;" *ngIf="mri_scan_flag">
                  <mat-label class="matlabel" style="font-weight: 600;">{{reportlistarray.mri_scan_desc}} :
                  </mat-label>
                  <div style="text-align: justify;" [innerHTML]="mri_scan">
                  </div>
                </div>
                </ng-template> -->

            <!-- doppler_desc -->
            <!-- <ng-template #doppler_desc>
                <div style="margin-bottom: 10px;" *ngIf="doppler_flag">
                  <mat-label class="matlabel" style="font-weight: 600;">{{reportlistarray.doppler_desc}} :
                  </mat-label>
                  <div style="text-align: justify;" [innerHTML]="doppler">
                  </div>
                </div>
                </ng-template> -->

            <!-- investigation order -->
            <ng-template #investigationtemp>
              <div style="margin-bottom: 10px;" *ngIf="inv_flag" [hidden]="reportlistarray.investigation_req ==0">
                <mat-label class="matlabel" style="font-weight: 600;">{{reportlistarray.investigation_desc}} :
                </mat-label>
                <div style="margin-top:1px;text-align: justify;" [innerHTML]="investigation">
                </div>
              </div>
            </ng-template>

            <!-- ot_notes_desc -->
            <ng-template #ot_notestemp>
              <div style="margin-bottom: 10px;" *ngIf="surgery_date" [hidden]="reportlistarray.ot_notes_req == 0">
                <mat-label class="matlabel" style="font-weight: 600;">{{reportlistarray.ot_notes_desc}} : </mat-label>
                <div style="margin-bottom: 5px;">
                  <span><strong>Surgery name : </strong>{{surgery_name}} </span><br />
                  <span><strong>Surgery date : </strong>{{surgery_date}} </span><br />
                  <span><strong>Surgery : </strong>{{surgery_proc}} </span><br />
                  <span><strong>Surgeon : </strong>
                    <table>
                      <tr *ngFor="let surgeon of surgeon_list;">
                        <td style="text-align: left;" class="matlabel">{{surgeon.name}}
                        </td>
                      </tr>
                    </table>
                  </span><br />
                  <span><strong>Anaestetist :2 </strong>
                    <table>
                      <tr *ngFor="let anaest of anaestetist;">
                        <td style="text-align: left;" class="matlabel">{{anaest.name}}
                        </td>
                      </tr>
                    </table>
                  </span><br />
                  <span><strong>Anaestesia : </strong>{{anaestetype}} </span><br />
                  <span><strong>Findings : </strong>{{anaest_findings}} </span><br />
                  <span><strong>Procedure done : </strong>{{anaest_proc}} </span><br />
                </div>
              </div>
            </ng-template>

            <!-- follow_summary_desc -->
            <ng-template #follow_sum>
              <div style="margin-bottom: 10px;" *ngIf="sum_course_flag" [hidden]="reportlistarray.summary_course_req == 0">
                <mat-label class="matlabel" style="font-weight: 600;"> {{reportlistarray.summary_course_desc}} :
                </mat-label>
                <div style="margin-top:5px;text-align: justify;" [innerHTML]="hosp_course">
                </div>
              </div>
            </ng-template>

            <!-- Discharge Advice -->
            <ng-template #discharge_adv>
              <div style="margin-bottom: 10px;" *ngIf="dis_adv_flag" [hidden]="reportlistarray.discharge_advice_req == 0">
                <mat-label class="matlabel" style="font-weight: 600;">
                 {{reportlistarray.discharge_advice_desc}}
                </mat-label>
                <div style="margin-top:5px;text-align: justify;" [innerHTML]="hospital_followup">
                </div>
              </div>
            </ng-template>

             <!-- In case of emergency -->
             <ng-template #emergencyies>
              <div style="margin-bottom: 10px;" *ngIf="emergency_flag" [hidden]="reportlistarray.emergency_req == 0">
                <mat-label class="matlabel" style="font-weight: 600;"> {{reportlistarray.emergency_desc}}:
                </mat-label>
                <div style="margin-top:5px;text-align: justify;" [innerHTML]="emergency_notes">
                </div>
              </div>
            </ng-template>

            <!-- <div class="block1_grid" style="margin-left: 25px !important;margin-right:25px !important;width:90%;">
            
            </div>

            <div class="block1_grid" style=" margin-left: 25px !important;margin-right:25px !important;margin-top: 1px;width:90%;">
             
            </div> -->
            <ng-template #drug_hist_order>
              <div style="margin-right: 30px !important;" *ngIf="med_history.length !=0" [hidden]="reportlistarray.drug_hist_req == 0">
                <div class="col-12"  *ngIf="med_history.length !=0">
                  <mat-label class="matlabel" style="font-weight: 600;">{{reportlistarray.drug_hist_desc}} :
                  </mat-label>
                  <table id="tbl" style="border:1px solid;width:90%" cellpadding="5" cellspacing="0">
                    <tr style="border:1px solid">
                      <th>Medicine</th>
                      <th>Dosages</th>
                      <th>Days</th>
                    </tr>
                    <tr *ngFor="let medicine of med_history">
                      <td>{{medicine.product_name}}</td>
                      <td>{{medicine.morning}} - {{medicine.afternoon}} - {{medicine.evening}} - {{medicine.night}}</td>
                      <td>{{medicine.time_duration}}</td>
                    </tr>
                  </table>
                </div>
              </div>
              <br />
            </ng-template> 
  
            <ng-template #followup_order>
              <div style="margin-right: 30px !important;" *ngIf="druglist.length !=0" [hidden]="reportlistarray.followup_req == 0">
                <mat-label class="matlabel" style="font-weight: 600;">{{reportlistarray.followup_desc}} : 
                </mat-label>
                <div class="col-12">
                  <table id="tbl" cellpadding="5" cellspacing="0" style="border:1px solid;width:90%">
                    <tr style="border:1px solid">
                      <th>Medicine</th>
                      <th>Dosage</th>
                      <th>Days</th>
                    </tr>
                    <tr *ngFor="let medicine of druglist">
                      <td>{{medicine.medicine_name}}</td>
                      <td>{{medicine.medicine_dosage}}</td>
                      <td>{{medicine.medicine_intakedays+" - "+medicine.medicine_food}}</td>
                    </tr>
                  </table>
                </div>
              </div>
              <br />
              <div style="margin-right: 30px !important;" *ngIf="diag_tests.length !=0">
                <mat-label class="matlabel" style="font-weight: 600;">Next-visit tests : 
                </mat-label>
                <div class="col-12">
                  <table id="tbl" cellpadding="5" cellspacing="0" style="border:1px solid;width:90%">
                    <tr style="border:1px solid">
                      <th>Testname</th>
                      <th>Department</th>
                      <th>Test category</th>
                    </tr>
                    <tr *ngFor="let test of diag_tests">
                      <td>{{test.test_name}}</td>
                      <td>{{test.department}}</td>
                      <td>{{test.test_cat}}</td>
                    </tr>
                  </table>
                </div>
              </div>
            </ng-template>
            <div style="width: 90%; margin-left: 10px;">
              <mat-label class="matlabel"> <b>Next Visit :</b> {{next_data1}}</mat-label>
              <br />
              <br />
              <!-- <mat-label class="matlabel"> <b>In case of emergency contact :</b> {{emerg_contact}}</mat-label> -->
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <table style="width: 100%; ">
      <tr>
        <td style="width: 50%;"></td>
        <td style="width: 50%;">
          <p style="text-align: center;font-size: 13px"><b>( {{doctor_name}} )</b></p>
        </td>
      </tr>
    </table>
  </div>

  <div #printnoheader id="printnoheader">
    <table style="border:'0';margin-left: 20px !important;margin-right: 20px !important; width:95%">
      <thead>
        <tr>
          <th style="width:100%;">
            <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
              <div style="width: 90%; height: 278px;">
                <!-- <img alt="image" src={{hospitalLogo}} width="100%" height="170px"> -->
                <div style="height: 170px;"></div>
                <table
                  style="width: 100%; font-size: 11px; border: 1px solid black; margin-top: 5px; border-radius: 10px;   margin-bottom: 5px;">
                  <tbody>
                    <tr>
                      <td style="padding-left: 5px;"><strong>Patient Name</strong></td>
                      <td>: {{patient_name}}</td>
                      <td style="padding-left: 5px;"><strong>DOA</strong></td>
                      <td> : {{admis_date}} {{admis_time}}</td>
                    </tr>
                    <tr>
                      <td style="padding-left: 5px;"><strong>Age / Gender</strong></td>
                      <td>: {{age}} {{gender}}</td>
                      <td style="padding-left: 5px;"><strong>DOD</strong></td>
                      <td> : {{dis_date}} {{dis_time}}</td>
                    </tr>
                    <tr>
                      <td style="padding-left: 5px;"><strong>Inpatient ID</strong></td>
                      <td>: {{inpId}}</td>

                    </tr>
                    <tr>
                      <td style="padding-left: 5px;"><strong>Consultant(s)</strong></td>
                      <!-- <td> : <div style="text-align: left;" *ngFor="let name of consultarray">{{name.name}}</div></td> -->
                      <td>
                        <div class="row d-flex flex-row align-items-center">
                          <!-- <div class="col-1">:</div> -->
                          <div class="col-11">
                            <div style="text-align: left; margin-left: -23px;" *ngFor="let name of consultarray">
                              :{{name.name}}</div>
                          </div>
                        </div>
                      </td>
                      <td *ngIf="barcode_flag"><img
                          style="width: 130px; height: 35px; margin-left: -12px; margin-top: -25px;"
                          src={{barcode_url}} />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </th>
        </tr>
      </thead>

      <tbody>
        <tr>
          <td width="100%">
            <div style="width: 95%;height: auto;display: contents; display: flex; justify-content: center;">
              <table class=paging style="width:100%;">
                <tbody>
                  <tr>
                    <td>
                      <div class="main"
                        style="display: flex; flex-direction: column; justify-content: center; align-items: center; margin-top: 10px; ">

                        <table style="width: 90%; margin: 0 auto;">
                          <tr>
                            <td style="{{reportlistarray.heading_style}}" colspan="2">
                              {{reportlistarray.discharge_heading}}
                            </td>
                          </tr>
                        </table>

                        <div class="block1_grid"
                          style="margin-left: 25px !important;margin-right:25px !important;width:90%;">
                          <ng-container *ngFor="let section of sections">
                            <ng-container *ngTemplateOutlet="section.template"></ng-container>
                          </ng-container>
                        </div>

                        <ng-template #vitalstemp>
                          <!-- margin-left: 25px !important; -->
                          <div style="margin-right:25px !important; width:90%;" [hidden]="reportlistarray.vitals_req ==0">
                            <div>
                              <p [style]="header_table_style">{{reportlistarray.vitals_desc}}:</p>
                            </div>
                            <div class="content_cover">
                              <div class="row" *ngIf="reportlistarray.vitals_req == 1">
                                <div class="col-3" *ngIf="reportlistarray.height_req == 1">
                                  <p class="textformat"><span style="font-weight: 550;">Height:</span> {{height}} {{height_measure}}
                                  </p>
                                </div>
                                <div class="col-3" *ngIf="reportlistarray.weight_req == 1">
                                  <p class="textformat"><span style="font-weight: 550;">Weight:</span> {{weight}} {{weight_measure}}
                                  </p>
                                </div>
            
                                <div class="col-3" *ngIf="reportlistarray.weight_req == 1 && reportlistarray.height_req == 1">
                                  <p class="textformat"><span style="font-weight: 550;">BMI:</span> {{bmi}} </p>
                                </div>
                                <div class="col-3" *ngIf="reportlistarray.weight_req == 1 && reportlistarray.height_req == 1">
                                  <p class="textformat"><span style="font-weight: 550;">BMR:</span> {{bmr}}</p>
                                </div>
            
                                <div class="col-3" *ngIf="reportlistarray.bp_req == 1">
                                  <p class="textformat"><span style="font-weight: 550;">{{reportlistarray.bp_desc}}:</span>
                                    {{blood_pressure}}</p>
                                </div>
                                <div class="col-3" *ngIf="reportlistarray.temparature_req == 1">
                                  <p class="textformat"><span style="font-weight: 550;">Temp:</span> {{temparature}}</p>
                                </div>
                                <div class="col-3" *ngIf="reportlistarray.pulse_req == 1">
                                  <p class="textformat"><span style="font-weight: 550;">{{reportlistarray.pulse_desc}}:</span>
                                    {{pulse}} bpm</p>
                                </div>
                                <div class="col-3" *ngIf="reportlistarray.rr_req == 1">
                                  <p class="textformat"><span style="font-weight: 550;">{{reportlistarray.rr_desc}}:</span>
                                    {{resp_rate}} bpm</p>
                                </div>
                                <div class="col-3" *ngIf="reportlistarray.spo2_req == 1">
                                  <p class="textformat"><span style="font-weight: 550;">{{reportlistarray.spo2_desc}}:</span>
                                    {{this.spo2}}</p>
                                </div>
                                <div class="col-3" *ngIf="reportlistarray.cvs_req == 1">
                                  <p class="textformat"><span style="font-weight: 550;">{{reportlistarray.cvs_desc}}:</span> {{cvs}}
                                  </p>
                                </div>
                                <div class="col-3" *ngIf="reportlistarray.abd_req == 1">
                                  <p class="textformat"><span style="font-weight: 550;">{{reportlistarray.abd_desc}}:</span>
                                    {{abdtxt}}</p>
                                </div>
                                <div class="col-3" *ngIf="reportlistarray.cns_req == 1">
                                  <p class="textformat"><span style="font-weight: 550;">{{reportlistarray.cns_desc}}:</span> {{cns}}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </ng-template>
            
                        <!-- diagnosis_desc -->
                        <ng-template #diagnosis>
                          <div style="margin-bottom: 10px;" *ngIf="dis_diag_flag" [hidden]="reportlistarray.diagnosis_req ==1">
                            <mat-label class="matlabel" style="font-weight: 600;">{{reportlistarray.diagnosis_desc}}
                              :</mat-label>
                            <div style="margin-top:1px;text-align: justify;" [innerHTML]="dis_diag">
                            </div>
                          </div>
                        </ng-template>
            
                        <!--treatment_desc  -->
                        <ng-template #treatment>
                          <div style="margin-bottom: 10px;" *ngIf="treat_flag" [hidden]="reportlistarray.treatment_req ==0">
                            <mat-label class="matlabel" style="font-weight: 600;">{{reportlistarray.treatment_desc}}
                              :
                            </mat-label>
                            <div style="margin-top:1px;text-align: justify;" [innerHTML]="Treatmetnt_done">
                            </div>
                          </div>
                        </ng-template>
            
                        <!-- history of present illness --> <!-- dietry -->
                        <ng-template #present_illne>
                          <div style="margin-bottom: 10px;" *ngIf="this.presentillness" [hidden]="reportlistarray.present_illness_req ==0">
                            <mat-label class="matlabel" style="font-weight: 600;">{{reportlistarray.present_illness_desc}}
                              :
                            </mat-label>
                            <div style="margin-top:1px;text-align: justify;" [innerHTML]="pres_ill">
                            </div>
                          </div>
                        </ng-template>
            
            
                        <ng-template #diet_advice>
                          <div style="margin-bottom: 10px;" *ngIf="dietaryadviceflag" [hidden]="reportlistarray.diet_adv_req ==1">
                            <mat-label class="matlabel" style="font-weight: 600;">{{reportlistarray.diet_adv_desc}}
                              :
                            </mat-label>
                            <div style="margin-top:1px;text-align: justify;" [innerHTML]="dietaryadvice">
                            </div>
                          </div>
                        </ng-template>
                        
                        <!-- Chief_complaints -->
                        <ng-template #chief_complaints>
                          <div style="margin-bottom: 10px;" *ngIf="comp_flag" [hidden]="reportlistarray.chief_complaints_req ==0">
                            <mat-label class="matlabel" style="font-weight: 600;">{{reportlistarray.chief_complaints_desc}} :
                            </mat-label>
                            <div style="text-align: justify;" [innerHTML]="complaints"></div>
                          </div>
                        </ng-template>
            
                        <!-- medical_history_desc -->
                        <ng-template #medical_histtemp>
                          <div style="margin-bottom: 10px;" *ngIf="prev_med_flag" [hidden]="reportlistarray.medical_history_req ==0">
                            <mat-label class="matlabel" style="font-weight: 600;">{{reportlistarray.medical_history_desc}}
                              :
                            </mat-label>
                            <div style="text-align: justify;" [innerHTML]="medical_hist">
                            </div>
                          </div>
                        </ng-template>
            
                        <!-- physical_exam_desc -->
                        <ng-template #physical_examtemp>
                          <div style="margin-bottom: 10px;" *ngIf="phy_exam_flag" [hidden]="reportlistarray.physical_exam_req ==0">
                            <mat-label class="matlabel" style="font-weight: 600;">{{reportlistarray.physical_exam_desc}} :
                            </mat-label>
                            <div style="text-align: justify;" [innerHTML]="physical_exam">
                            </div>
                          </div>
                        </ng-template>
            
                        <!-- endoscopy_desc -->
                        <!-- <ng-template #endoscopytemp>
                            <div style="margin-bottom: 10px;" *ngIf="endoscopy_flag">
                              <mat-label class="matlabel" style="font-weight: 600;">{{reportlistarray.endoscopy_desc}} :
                              </mat-label>
                              <div style="text-align: justify;" [innerHTML]="endoscopy">
                              </div>
                            </div>
                            </ng-template> -->
            
                        <!-- colonoscopy_desc -->
                        <!-- <ng-template #colonoscopytemp>
                            <div style="margin-bottom: 10px;" *ngIf="colonoscopy_flag">
                              <mat-label class="matlabel" style="font-weight: 600;">{{reportlistarray.colonoscopy_desc}} :
                              </mat-label>
                              <div style="text-align: justify;" [innerHTML]="colonoscopy">
                              </div>
                            </div>
                            </ng-template> -->
            
                        <!-- ct_scan_desc -->
                        <!-- <ng-template #ct_scantemp>
                            <div style="margin-bottom: 10px;" *ngIf="ct_scan_flag">
                              <mat-label class="matlabel" style="font-weight: 600;">{{reportlistarray.ct_scan_desc}} :
                              </mat-label>
                              <div style="text-align: justify;" [innerHTML]="ct_scan">
                              </div>
                            </div>
                            </ng-template> -->
            
                        <!-- mir_scan_desc -->
                        <!-- <ng-template #mi_scantemp>
                            <div style="margin-bottom: 10px;" *ngIf="mri_scan_flag">
                              <mat-label class="matlabel" style="font-weight: 600;">{{reportlistarray.mri_scan_desc}} :
                              </mat-label>
                              <div style="text-align: justify;" [innerHTML]="mri_scan">
                              </div>
                            </div>
                            </ng-template> -->
            
                        <!-- doppler_desc -->
                        <!-- <ng-template #doppler_desc>
                            <div style="margin-bottom: 10px;" *ngIf="doppler_flag">
                              <mat-label class="matlabel" style="font-weight: 600;">{{reportlistarray.doppler_desc}} :
                              </mat-label>
                              <div style="text-align: justify;" [innerHTML]="doppler">
                              </div>
                            </div>
                            </ng-template> -->
            
                        <!-- investigation order -->
                        <ng-template #investigationtemp>
                          <div style="margin-bottom: 10px;" *ngIf="inv_flag" [hidden]="reportlistarray.investigation_req ==0">
                            <mat-label class="matlabel" style="font-weight: 600;">{{reportlistarray.investigation_desc}} :
                            </mat-label>
                            <div style="margin-top:1px;text-align: justify;" [innerHTML]="investigation">
                            </div>
                          </div>
                        </ng-template>
            
                        <!-- ot_notes_desc -->
                        <ng-template #ot_notestemp>
                          <div style="margin-bottom: 10px;" *ngIf="surgery_date" [hidden]="reportlistarray.ot_notes_req == 0">
                            <mat-label class="matlabel" style="font-weight: 600;">{{reportlistarray.ot_notes_desc}} : </mat-label>
                            <div style="margin-bottom: 5px;">
                              <span><strong>Surgery name : </strong>{{surgery_name}} </span><br />
                              <span><strong>Surgery date : </strong>{{surgery_date}} </span><br />
                              <span><strong>Surgery : </strong>{{surgery_proc}} </span><br />
                              <span><strong>Surgeon : </strong>
                                <table>
                                  <tr *ngFor="let surgeon of surgeon_list;">
                                    <td style="text-align: left;" class="matlabel">{{surgeon.name}}
                                    </td>
                                  </tr>
                                </table>
                              </span><br />
                              <span><strong>Anaestetist :2 </strong>
                                <table>
                                  <tr *ngFor="let anaest of anaestetist;">
                                    <td style="text-align: left;" class="matlabel">{{anaest.name}}
                                    </td>
                                  </tr>
                                </table>
                              </span><br />
                              <span><strong>Anaestesia : </strong>{{anaestetype}} </span><br />
                              <span><strong>Findings : </strong>{{anaest_findings}} </span><br />
                              <span><strong>Procedure done : </strong>{{anaest_proc}} </span><br />
                            </div>
                          </div>
                        </ng-template>
            
                        <!-- follow_summary_desc -->
                        <ng-template #follow_sum>
                          <div style="margin-bottom: 10px;" *ngIf="sum_course_flag" [hidden]="reportlistarray.summary_course_req == 0">
                            <mat-label class="matlabel" style="font-weight: 600;"> {{reportlistarray.summary_course_desc}} :
                            </mat-label>
                            <div style="margin-top:5px;text-align: justify;" [innerHTML]="hosp_course">
                            </div>
                          </div>
                        </ng-template>
            
                        <!-- Discharge Advice -->
                        <ng-template #discharge_adv>
                          <div style="margin-bottom: 10px;" *ngIf="dis_adv_flag" [hidden]="reportlistarray.discharge_advice_req == 0">
                            <mat-label class="matlabel" style="font-weight: 600;">
                             {{reportlistarray.discharge_advice_desc}}
                            </mat-label>
                            <div style="margin-top:5px;text-align: justify;" [innerHTML]="hospital_followup">
                            </div>
                          </div>
                        </ng-template>
            
                         <!-- In case of emergency -->
                         <ng-template #emergencyies>
                          <div style="margin-bottom: 10px;" *ngIf="emergency_flag" [hidden]="reportlistarray.emergency_req == 0">
                            <mat-label class="matlabel" style="font-weight: 600;"> {{reportlistarray.emergency_desc}}:
                            </mat-label>
                            <div style="margin-top:5px;text-align: justify;" [innerHTML]="emergency_notes">
                            </div>
                          </div>
                        </ng-template>
            
                        <!-- <div class="block1_grid" style="margin-left: 25px !important;margin-right:25px !important;width:90%;">
                        
                        </div>
            
                        <div class="block1_grid" style=" margin-left: 25px !important;margin-right:25px !important;margin-top: 1px;width:90%;">
                         
                        </div> -->
                        <ng-template #drug_hist_order>
                          <div style="margin-right: 30px !important;" *ngIf="med_history.length !=0" [hidden]="reportlistarray.drug_hist_req == 0">
                            <div class="col-12"  *ngIf="med_history.length !=0">
                              <mat-label class="matlabel" style="font-weight: 600;">{{reportlistarray.drug_hist_desc}} :
                              </mat-label>
                              <table id="tbl" style="border:1px solid;width:90%" cellpadding="5" cellspacing="0">
                                <tr style="border:1px solid">
                                  <th>Medicine</th>
                                  <th>Dosages</th>
                                  <th>Days</th>
                                </tr>
                                <tr *ngFor="let medicine of med_history">
                                  <td>{{medicine.product_name}}</td>
                                  <td>{{medicine.morning}} - {{medicine.afternoon}} - {{medicine.evening}} - {{medicine.night}}</td>
                                  <td>{{medicine.time_duration}}</td>
                                </tr>
                              </table>
                            </div>
                          </div>
                          <br />
                          </ng-template> 
              
                          <ng-template #followup_order>
                            <!-- || reportlistarray.followup_order == 0 -->
                          <div style="margin-right: 30px !important;" *ngIf="druglist.length !=0" [hidden]="reportlistarray.followup_req == 0">
                            <mat-label class="matlabel" style="font-weight: 600;">{{reportlistarray.followup_desc}} : 
                            </mat-label>
                            <div class="col-12">
                              <table id="tbl" cellpadding="5" cellspacing="0" style="border:1px solid;width:90%">
                                <tr style="border:1px solid">
                                  <th>Medicine</th>
                                  <th>Dosage</th>
                                  <th>Days</th>
                                </tr>
                                <tr *ngFor="let medicine of druglist">
                                  <td>{{medicine.medicine_name}}</td>
                                  <td>{{medicine.medicine_dosage}}</td>
                                  <td>{{medicine.medicine_intakedays+" - "+medicine.medicine_food}}</td>
                                </tr>
                              </table>
                            </div>
                          </div>
                          <br />
                          <div style="margin-right: 30px !important;" *ngIf="diag_tests.length !=0">
                            <mat-label class="matlabel" style="font-weight: 600;">Next-visit tests : 
                            </mat-label>
                            <div class="col-12">
                              <table id="tbl" cellpadding="5" cellspacing="0" style="border:1px solid;width:90%">
                                <tr style="border:1px solid">
                                  <th>Testname</th>
                                  <th>Department</th>
                                  <th>Test category</th>
                                </tr>
                                <tr *ngFor="let test of diag_tests">
                                  <td>{{test.test_name}}</td>
                                  <td>{{test.department}}</td>
                                  <td>{{test.test_cat}}</td>
                                </tr>
                              </table>
                            </div>
                          </div>
                        </ng-template>
                        <div style="width: 90%; margin-left: 10px;">
                          <mat-label class="matlabel"> <b>Next Visit :</b> {{next_data1}}</mat-label>
                          <br />
                          <br />
                          <!-- <mat-label class="matlabel"> <b>In case of emergency contact :</b> {{emerg_contact}}</mat-label> -->
                        </div>

                        <div class="empty-footer" style="margin-top: 20px; width: 100%;">
                          <p style="text-align: right;font-size: 13px"><b>( {{doctor_name}} )</b></p>
                        </div>
                      </div>

                      
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td width="100%">
                      <table style="border:'0';width:100%">
                        <tr>
                          <td style="width:100%;height: 70px;">&nbsp;
                          </td>
                        </tr>
                      </table>
                </tfoot>
              </table>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <footer style="width:100%;">
      <table style="border:'0';width:100%; margin-bottom: 0;">
        <tr>
          <td style="width:100%;height: 100px;">&nbsp;
            <!-- <img src={{hospitalFooter}} width="100%" height="200px"> -->
          </td>
        </tr>
      </table>
    </footer>
  </div>

  <div #printbanner_llh id="printbanner_llh">
    <table style="border:'0';width:100%; margin-left: 50px !important;margin-right: 50px !important;">
      <thead>
        <tr>
          <th style="width:100%;">
            <table style="margin-left:10px;margin-right: 10px;margin-top: 5px !important;">
              <tr>
                <td>
                  <img alt="image" src={{hptl_pres_logo}} width="750px">
                </td>
              </tr>
            </table>
          </th>
        </tr>
      </thead>
      <tfoot>
        <tr>
          <td width="100%">
            <table style="border:'0';width:100%">
              <tr>
                <td style="width:100%;height: 100px;">&nbsp;</td>
              </tr>
            </table>
          </td>
        </tr>
      </tfoot>
      <tbody>
        <tr>
          <td width="100%">
            <table style="margin-left:10px;margin-top:-140px !important;margin-right: 10px;">
              <tr>
                <td>
                  <div style="min-height: 100px;">&nbsp;</div>
                </td>
              </tr>
            </table>
            <br /><br />
            <div
              style="display: flex; justify-content: space-between; margin-left: 10px;padding: 10px; margin-top: 10px; margin-bottom: 10px; border: 1px solid black; border-radius: 10px; width: 85% !important;">
              <table style="width: 55%;vertical-align: top !important;">
                <tbody>
                  <tr>
                    <td style="vertical-align: top !important;width: 100px;"><strong>Patient Name</strong></td>
                    <td style="vertical-align: top !important;">: {{patient_name}}</td>
                  </tr>
                  <tr>
                    <td><strong>Age / Gender</strong></td>
                    <td>: {{age}} {{gender}}</td>
                  </tr>
                  <tr>
                    <td><strong>Inpatient ID</strong></td>
                    <td>: {{inpId}}</td>
                  </tr>
                  <tr>
                    <td style="vertical-align: top !important;"><strong>Address</strong></td>
                    <td style="vertical-align: top !important;">: {{fulladdress}}</td>
                  </tr>
                  <tr>
                    <td style="vertical-align: top !important;"><strong>Consultant(s)</strong></td>
                    <td style="text-align: left;vertical-align: top !important;"> :
                      <div *ngFor="let name of consultarray">
                        <p style="text-align: left;margin-bottom: -5px !important;">{{name.name}}</p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table style="width: 45%;vertical-align: top !important;height: 0%;">
                <tbody>
                  <tr>
                    <td style="vertical-align: top !important;width: 30px;"><strong>DOA</strong></td>
                    <td style="vertical-align: top !important;"> : {{admis_date}} {{admis_time}}</td>
                  </tr>
                  <tr>
                    <td style="vertical-align: top !important;width: 30px;"><strong>DOD</strong></td>
                    <td style="vertical-align: top !important;"> : {{dis_date}} {{dis_time}}</td>
                  </tr>
                  <tr>
                    <td style="vertical-align: top !important;column-span:2;">
                      <div style="margin-left: -10px;">
                        <div class="d-flex align-items-center justify-content-center">
                          <ngx-barcode class="barcode" [bc-value]="patient_identifier" [bc-display-value]="displayValue"
                            [bc-line-color]="lineColor" [element-type]="elementType" [format]="format"
                            [bc-width]="width" [bc-height]="barcodeheight" [bc-font-options]="fontOptions"
                            [bc-font]="font" [bc-text-align]="textAlign" [bc-text-position]="textPosition"
                            [bc-text-margin]="textMargin" [bc-font-size]="fontSize" [bc-background]="background"
                            [bc-margin]="margin" [bc-margin-top]="marginTop" [bc-margin-bottom]="marginBottom"
                            [bc-margin-left]="marginLeft" [bc-margin-right]="marginRight"></ngx-barcode>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="block1_grid" style="margin-left: 25px !important;margin-right:25px !important;width:90%;">
              <ng-container *ngFor="let section of sections">
                <ng-container *ngTemplateOutlet="section.template"></ng-container>
              </ng-container>
            </div>

            
            <ng-template #vitalstemp>
              <!-- margin-left: 25px !important; -->
              <div style="margin-right:25px !important; width:90%;" [hidden]="reportlistarray.vitals_req ==0">
                <div>
                  <p [style]="header_table_style">{{reportlistarray.vitals_desc}}:</p>
                </div>
                <div class="content_cover">
                  <div class="row" *ngIf="reportlistarray.vitals_req == 1">
                    <div class="col-3" *ngIf="reportlistarray.height_req == 1">
                      <p class="textformat"><span style="font-weight: 550;">Height:</span> {{height}} {{height_measure}}
                      </p>
                    </div>
                    <div class="col-3" *ngIf="reportlistarray.weight_req == 1">
                      <p class="textformat"><span style="font-weight: 550;">Weight:</span> {{weight}} {{weight_measure}}
                      </p>
                    </div>

                    <div class="col-3" *ngIf="reportlistarray.weight_req == 1 && reportlistarray.height_req == 1">
                      <p class="textformat"><span style="font-weight: 550;">BMI:</span> {{bmi}} </p>
                    </div>
                    <div class="col-3" *ngIf="reportlistarray.weight_req == 1 && reportlistarray.height_req == 1">
                      <p class="textformat"><span style="font-weight: 550;">BMR:</span> {{bmr}}</p>
                    </div>

                    <div class="col-3" *ngIf="reportlistarray.bp_req == 1">
                      <p class="textformat"><span style="font-weight: 550;">{{reportlistarray.bp_desc}}:</span>
                        {{blood_pressure}}</p>
                    </div>
                    <div class="col-3" *ngIf="reportlistarray.temparature_req == 1">
                      <p class="textformat"><span style="font-weight: 550;">Temp:</span> {{temparature}}</p>
                    </div>
                    <div class="col-3" *ngIf="reportlistarray.pulse_req == 1">
                      <p class="textformat"><span style="font-weight: 550;">{{reportlistarray.pulse_desc}}:</span>
                        {{pulse}} bpm</p>
                    </div>
                    <div class="col-3" *ngIf="reportlistarray.rr_req == 1">
                      <p class="textformat"><span style="font-weight: 550;">{{reportlistarray.rr_desc}}:</span>
                        {{resp_rate}} bpm</p>
                    </div>
                    <div class="col-3" *ngIf="reportlistarray.spo2_req == 1">
                      <p class="textformat"><span style="font-weight: 550;">{{reportlistarray.spo2_desc}}:</span>
                        {{this.spo2}}</p>
                    </div>
                    <div class="col-3" *ngIf="reportlistarray.cvs_req == 1">
                      <p class="textformat"><span style="font-weight: 550;">{{reportlistarray.cvs_desc}}:</span> {{cvs}}
                      </p>
                    </div>
                    <div class="col-3" *ngIf="reportlistarray.abd_req == 1">
                      <p class="textformat"><span style="font-weight: 550;">{{reportlistarray.abd_desc}}:</span>
                        {{abdtxt}}</p>
                    </div>
                    <div class="col-3" *ngIf="reportlistarray.cns_req == 1">
                      <p class="textformat"><span style="font-weight: 550;">{{reportlistarray.cns_desc}}:</span> {{cns}}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>

            <!-- diagnosis_desc -->
            <ng-template #diagnosis>
              <div style="margin-bottom: 10px;" *ngIf="dis_diag_flag" [hidden]="reportlistarray.diagnosis_req ==0">
                <mat-label class="matlabel" style="font-weight: 600;">{{reportlistarray.diagnosis_desc}}
                  :</mat-label>
                <div style="margin-top:1px;text-align: justify;" [innerHTML]="dis_diag">
                </div>
              </div>
            </ng-template>

            <!--treatment_desc  -->
            <ng-template #treatment>
              <div style="margin-bottom: 10px;" *ngIf="treat_flag" [hidden]="reportlistarray.treatment_req ==0">
                <mat-label class="matlabel" style="font-weight: 600;">{{reportlistarray.treatment_desc}}
                  :
                </mat-label>
                <div style="margin-top:1px;text-align: justify;" [innerHTML]="Treatmetnt_done">
                </div>
              </div>
            </ng-template>

            <!-- history of present illness --> <!-- dietry -->
            <ng-template #present_illne>
              <div style="margin-bottom: 10px;" *ngIf="this.presentillness" [hidden]="reportlistarray.present_illness_req ==0">
                <mat-label class="matlabel" style="font-weight: 600;">{{reportlistarray.present_illness_desc}}
                  :
                </mat-label>
                <div style="margin-top:1px;text-align: justify;" [innerHTML]="pres_ill">
                </div>
              </div>
            </ng-template>


            <ng-template #diet_advice>
              <div style="margin-bottom: 10px;" *ngIf="dietaryadviceflag" [hidden]="reportlistarray.diet_adv_req ==0">
                <mat-label class="matlabel" style="font-weight: 600;">{{reportlistarray.diet_adv_desc}}
                  :
                </mat-label>
                <div style="margin-top:1px;text-align: justify;" [innerHTML]="dietaryadvice">
                </div>
              </div>
            </ng-template>
            
            <!-- Chief_complaints -->
            <ng-template #chief_complaints>
              <div style="margin-bottom: 10px;" *ngIf="comp_flag" [hidden]="reportlistarray.chief_complaints_req ==0">
                <mat-label class="matlabel" style="font-weight: 600;">{{reportlistarray.chief_complaints_desc}} :
                </mat-label>
                <div style="text-align: justify;" [innerHTML]="complaints"></div>
              </div>
            </ng-template>

            <!-- medical_history_desc -->
            <ng-template #medical_histtemp>
              <div style="margin-bottom: 10px;" *ngIf="prev_med_flag" [hidden]="reportlistarray.medical_history_req ==0">
                <mat-label class="matlabel" style="font-weight: 600;">{{reportlistarray.medical_history_desc}}
                  :
                </mat-label>
                <div style="text-align: justify;" [innerHTML]="medical_hist">
                </div>
              </div>
            </ng-template>

            <!-- physical_exam_desc -->
            <ng-template #physical_examtemp>
              <div style="margin-bottom: 10px;" *ngIf="phy_exam_flag" [hidden]="reportlistarray.physical_exam_req ==0">
                <mat-label class="matlabel" style="font-weight: 600;">{{reportlistarray.physical_exam_desc}} :
                </mat-label>
                <div style="text-align: justify;" [innerHTML]="physical_exam">
                </div>
              </div>
            </ng-template>

            <!-- endoscopy_desc -->
            <!-- <ng-template #endoscopytemp>
                <div style="margin-bottom: 10px;" *ngIf="endoscopy_flag">
                  <mat-label class="matlabel" style="font-weight: 600;">{{reportlistarray.endoscopy_desc}} :
                  </mat-label>
                  <div style="text-align: justify;" [innerHTML]="endoscopy">
                  </div>
                </div>
                </ng-template> -->

            <!-- colonoscopy_desc -->
            <!-- <ng-template #colonoscopytemp>
                <div style="margin-bottom: 10px;" *ngIf="colonoscopy_flag">
                  <mat-label class="matlabel" style="font-weight: 600;">{{reportlistarray.colonoscopy_desc}} :
                  </mat-label>
                  <div style="text-align: justify;" [innerHTML]="colonoscopy">
                  </div>
                </div>
                </ng-template> -->

            <!-- ct_scan_desc -->
            <!-- <ng-template #ct_scantemp>
                <div style="margin-bottom: 10px;" *ngIf="ct_scan_flag">
                  <mat-label class="matlabel" style="font-weight: 600;">{{reportlistarray.ct_scan_desc}} :
                  </mat-label>
                  <div style="text-align: justify;" [innerHTML]="ct_scan">
                  </div>
                </div>
                </ng-template> -->

            <!-- mir_scan_desc -->
            <!-- <ng-template #mi_scantemp>
                <div style="margin-bottom: 10px;" *ngIf="mri_scan_flag">
                  <mat-label class="matlabel" style="font-weight: 600;">{{reportlistarray.mri_scan_desc}} :
                  </mat-label>
                  <div style="text-align: justify;" [innerHTML]="mri_scan">
                  </div>
                </div>
                </ng-template> -->

            <!-- doppler_desc -->
            <!-- <ng-template #doppler_desc>
                <div style="margin-bottom: 10px;" *ngIf="doppler_flag">
                  <mat-label class="matlabel" style="font-weight: 600;">{{reportlistarray.doppler_desc}} :
                  </mat-label>
                  <div style="text-align: justify;" [innerHTML]="doppler">
                  </div>
                </div>
                </ng-template> -->

            <!-- investigation order -->
            <ng-template #investigationtemp>
              <div style="margin-bottom: 10px;" *ngIf="inv_flag" [hidden]="reportlistarray.investigation_req ==0">
                <mat-label class="matlabel" style="font-weight: 600;">{{reportlistarray.investigation_desc}} :
                </mat-label>
                <div style="margin-top:1px;text-align: justify;" [innerHTML]="investigation">
                </div>
              </div>
            </ng-template>

            <!-- ot_notes_desc -->
            <ng-template #ot_notestemp>
              <div style="margin-bottom: 10px;" *ngIf="surgery_date" [hidden]="reportlistarray.ot_notes_req == 0">
                <mat-label class="matlabel" style="font-weight: 600;">{{reportlistarray.ot_notes_desc}} : </mat-label>
                <div style="margin-bottom: 5px;">
                  <span><strong>Surgery name : </strong>{{surgery_name}} </span><br />
                  <span><strong>Surgery date : </strong>{{surgery_date}} </span><br />
                  <span><strong>Surgery : </strong>{{surgery_proc}} </span><br />
                  <span><strong>Surgeon : </strong>
                    <table>
                      <tr *ngFor="let surgeon of surgeon_list;">
                        <td style="text-align: left;" class="matlabel">{{surgeon.name}}
                        </td>
                      </tr>
                    </table>
                  </span><br />
                  <span><strong>Anaestetist :2 </strong>
                    <table>
                      <tr *ngFor="let anaest of anaestetist;">
                        <td style="text-align: left;" class="matlabel">{{anaest.name}}
                        </td>
                      </tr>
                    </table>
                  </span><br />
                  <span><strong>Anaestesia : </strong>{{anaestetype}} </span><br />
                  <span><strong>Findings : </strong>{{anaest_findings}} </span><br />
                  <span><strong>Procedure done : </strong>{{anaest_proc}} </span><br />
                </div>
              </div>
            </ng-template>

            <!-- follow_summary_desc -->
            <ng-template #follow_sum>
              <div style="margin-bottom: 10px;" *ngIf="sum_course_flag" [hidden]="reportlistarray.summary_course_req == 0">
                <mat-label class="matlabel" style="font-weight: 600;"> {{reportlistarray.summary_course_desc}} :
                </mat-label>
                <div style="margin-top:5px;text-align: justify;" [innerHTML]="hosp_course">
                </div>
              </div>
            </ng-template>

            <!-- Discharge Advice -->
            <ng-template #discharge_adv>
              <div style="margin-bottom: 10px;" *ngIf="dis_adv_flag" [hidden]="reportlistarray.discharge_advice_req == 0">
                <mat-label class="matlabel" style="font-weight: 600;">
                 {{reportlistarray.discharge_advice_desc}}
                </mat-label>
                <div style="margin-top:5px;text-align: justify;" [innerHTML]="hospital_followup">
                </div>
              </div>
            </ng-template>

             <!-- In case of emergency -->
             <ng-template #emergencyies>
              <div style="margin-bottom: 10px;" *ngIf="emergency_flag" [hidden]="reportlistarray.emergency_req == 0">
                <mat-label class="matlabel" style="font-weight: 600;"> {{reportlistarray.emergency_desc}}:
                </mat-label>
                <div style="margin-top:5px;text-align: justify;" [innerHTML]="emergency_notes">
                </div>
              </div>
            </ng-template>

            <!-- <div class="block1_grid" style="margin-left: 25px !important;margin-right:25px !important;width:90%;">
            
            </div>

            <div class="block1_grid" style=" margin-left: 25px !important;margin-right:25px !important;margin-top: 1px;width:90%;">
             
            </div> -->
            <ng-template #drug_hist_order>
              <div style="margin-right: 30px !important;" *ngIf="med_history.length !=0" [hidden]="reportlistarray.drug_hist_req == 0">
                <div class="col-12"  *ngIf="med_history.length !=0">
                  <mat-label class="matlabel" style="font-weight: 600;">{{reportlistarray.drug_hist_desc}} :
                  </mat-label>
                  <table id="tbl" style="border:1px solid;width:90%" cellpadding="5" cellspacing="0">
                    <tr style="border:1px solid">
                      <th>Medicine</th>
                      <th>Dosages</th>
                      <th>Days</th>
                    </tr>
                    <tr *ngFor="let medicine of med_history">
                      <td>{{medicine.product_name}}</td>
                      <td>{{medicine.morning}} - {{medicine.afternoon}} - {{medicine.evening}} - {{medicine.night}}</td>
                      <td>{{medicine.time_duration}}</td>
                    </tr>
                  </table>
                </div>
              </div>
              <br />
              </ng-template> 
  
              <ng-template #followup_order>

              <div style="margin-right: 30px !important;" *ngIf="druglist.length !=0" [hidden]="reportlistarray.followup_req == 0">
                <mat-label class="matlabel" style="font-weight: 600;">{{reportlistarray.followup_desc}} : 
                </mat-label>
                <div class="col-12">
                  <table id="tbl" cellpadding="5" cellspacing="0" style="border:1px solid;width:90%">
                    <tr style="border:1px solid">
                      <th>Medicine</th>
                      <th>Dosage</th>
                      <th>Days</th>
                    </tr>
                    <tr *ngFor="let medicine of druglist">
                      <td>{{medicine.medicine_name}}</td>
                      <td>{{medicine.medicine_dosage}}</td>
                      <td>{{medicine.medicine_intakedays+" - "+medicine.medicine_food}}</td>
                    </tr>
                  </table>
                </div>
              </div>
              <br />
            </ng-template>

            
            <div style="width: 90%; margin-left: 10px;">
              <mat-label class="matlabel"> <b>Next Visit :</b> {{next_data1}}</mat-label>
              <br />
              <br />
              <!-- <mat-label class="matlabel"> <b>In case of emergency contact :</b> {{emerg_contact}}</mat-label> -->
            </div>

          </td>
        </tr>
      </tbody>
    </table>
    <br />
    <table style="width: 100%; margin-top: 30px;">
      <tr>
        <td style="width: 50%;"></td>
        <td style="width: 50%;">
          <p style="text-align: center;font-size: 13px"><b>({{doctor_name}})</b></p>
        </td>
      </tr>
    </table>
  </div>
</div>