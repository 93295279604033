<div class="row" style="width:100%;margin:0 auto;">
    <div class="col-12">
      <mdb-card>
        <mdb-card-header class="bg-white" style="padding-bottom: 0px;">
          <div class="headerCover">
            <div class="headerTilte app_list_header">
              <h5 class="m-0" class="mainHeadingStyle">Inpatient Bill Details</h5>
            </div>
            <div class="headerButtons">     
              <a (click)="back()">
                <img src="../../../assets/ui_icons/buttons/Back_button.svg" class="saveimgbtn_inpatinfo" />
              </a>&nbsp;
              <a (click)="history()" *ngIf="viewbutton">
                <img src="../../../assets/ui_icons/buttons/History_Bill_icon.svg" class="saveimgbtn_inpatinfo" />
              </a>&nbsp;
              <a *ngIf="printbutton" (click)="print()">
                <img src="../../../assets/img/printer.svg"
                    class="backimgbtn_inpatinfo" style="width: 25px; height: 25px;" />
              </a>         
            </div>
          </div>
        </mdb-card-header>
        <mdb-card-body style="padding-top: 0;">
          <div *ngFor="let billprint of billingDetailList">         
            <div class="row chwidth">
              <div class="col-12">
                <div class="cover_div" style="font-size: 13px !important;">
                  <div class="header_lable">
                     Patient details
                  </div>
                  <div class="content_cover">                              
                    <div class="row">
                      <div class="col-2" style="text-align: center;">
                        <img src="{{billprint.profile_image}}" onerror="this.src='../../../assets/img/default.jpg';"
                          class="profileimage">
                      </div>
                      <div class="col-10">
                        <div class="row">                                                                              
                          <div class="col-3">
                            <table style="width: 100%;" class="head-table">
                              <tbody>
                                <tr class="head-tr">
                                  <td class="head-td" style="width: 74px; font-weight: 500;font-family: Arial;color: black;">
                                    <b>MR No</b></td>
                                  <td class="head-td" style="width: 10px;font-weight: 500;">:&nbsp;</td>
                                  <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">{{billprint.uhid}}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div class="col-3">
                            <table style="width: 100%;" class="head-table">
                              <tbody>
                                <tr class="head-tr">
                                  <td class="head-td" style="width: 74px; font-weight: 500;font-family: Arial;color: black;">
                                    <b>Patient</b></td>
                                  <td class="head-td" style="width: 10px; font-weight: 500;">:&nbsp;</td>
                                  <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">{{billprint.client_name}}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div class="col-3">
                            <table style="width: 100%;" class="head-table">
                              <tbody>
                                <tr class="head-tr">
                                  <td class="head-td" style="width: 74px; font-weight: 500;font-family: Arial;color: black;">
                                    <b>Age/Gender</b></td>
                                  <td class="head-td" style="width: 10px; font-weight: 500;">:&nbsp;</td>
                                  <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">
                                    {{billprint.age}} <span *ngIf="billprint.gender != '' && billprint.gender != undefined">/</span> {{billprint.gender}}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div class="col-3" *ngIf="billprint.mobile != undefined && billprint.mobile != ''">
                            <table style="width: 100%;" class="head-table">
                              <tbody>
                                <tr class="head-tr">
                                  <td class="head-td" style="width: 74px; font-weight: 500;font-family: Arial;color: black;">
                                    <b>Mobile</b></td>
                                  <td class="head-td" style="width: 10px;font-weight: 500;">:&nbsp;</td>
                                  <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">{{billprint.mobile}}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>      
                          <div class="col-3" *ngIf="billprint.dr_name != '' && billprint.dr_name != undefined">
                            <table style="width: 100%;" class="head-table">
                              <tbody>
                                <tr class="head-tr">
                                  <td class="head-td" style="width: 74px; font-weight: 500;font-family: Arial;color: black;">
                                    <b>Doctor</b></td>
                                  <td class="head-td" style="width: 10px;font-weight: 500;">:&nbsp;</td>
                                  <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">{{billprint.dr_name}}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div> 
                          <div class="col-3">
                            <table style="width: 100%;" class="head-table">
                              <tbody>
                                <tr class="head-tr">
                                  <td class="head-td" style="width: 74px; font-weight: 500;font-family: Arial;color: black;">
                                    <b>Inpatient Id</b></td>
                                  <td class="head-td" style="width: 10px;font-weight: 500;">:&nbsp;</td>
                                  <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">{{billprint.inpatientid}}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div class="col-3" *ngIf="inpatientflag">
                            <table style="width: 100%;" class="head-table">
                              <tbody>
                                <tr class="head-tr">
                                  <td class="head-td" style="width: 74px; font-weight: 500;font-family: Arial;color: black;">
                                    <b>DOA</b></td>
                                  <td class="head-td" style="width: 10px;font-weight: 500;">:&nbsp;</td>
                                  <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">
                                    {{billprint.admitteddate}} {{billprint.admittedtime}}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>  
                          <div class="col-3" *ngIf="billprint.discharge_date != undefined && billprint.discharge_date != ''">
                            <table style="width: 100%;" class="head-table">
                              <tbody>
                                <tr class="head-tr">
                                  <td class="head-td" style="width: 74px; font-weight: 500;font-family: Arial;color: black;">
                                    <b>DOD</b></td>
                                  <td class="head-td" style="width: 10px;font-weight: 500;">:&nbsp;</td>
                                  <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">
                                    {{billprint.discharge_date}} {{billprint.discharge_time}}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div> 
                          <div class="col-3" *ngIf="payment_mode != undefined && payment_mode != '' && insurance_flag">
                            <table style="width: 100%;" class="head-table">
                              <tbody>
                                <tr class="head-tr">
                                  <td class="head-td" style="width: 74px; font-weight: 500;font-family: Arial;color: black;">
                                    <b>Pay mode</b></td>
                                  <td class="head-td" style="width: 10px;font-weight: 500;">:&nbsp;</td>
                                  <td class="head-td" style="font-family: Arial, Helvetica, sans-serif"> {{payment_mode}} </td>
                                </tr>
                              </tbody>
                            </table>
                          </div> 
                          <div class="col-3" *ngIf="insurer != undefined && insurer != '' && insurance_flag">
                            <table style="width: 100%;" class="head-table">
                              <tbody>
                                <tr class="head-tr">
                                  <td class="head-td" style="width: 74px; font-weight: 500;font-family: Arial;color: black;">
                                    <b>Ins Comp</b></td>
                                  <td class="head-td" style="width: 10px;font-weight: 500;">:&nbsp;</td>
                                  <td class="head-td" style="font-family: Arial, Helvetica, sans-serif"> {{insurer}} </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>  
                          <div class="col-3" *ngIf="bill_type_pharma != undefined && bill_type_pharma != ''">
                            <table style="width: 100%;" class="head-table">
                              <tbody>
                                <tr class="head-tr">
                                  <td class="head-td" style="width: 74px; font-weight: 500;font-family: Arial;color: black;">
                                    <b>Bill type</b></td>
                                  <td class="head-td" style="width: 10px;font-weight: 500;">:&nbsp;</td>
                                  <td class="head-td" style="font-family: Arial, Helvetica, sans-serif"> {{bill_type_pharma}} </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>                  
                          <div class="col-3" *ngIf="billprint.wardname != undefined && billprint.wardname != '' && inpatientflag">
                            <table style="width: 100%;" class="head-table">
                              <tbody>
                                <tr class="head-tr">
                                  <td class="head-td" style="width: 74px; font-weight: 500;font-family: Arial;color: black;">
                                    <b>Ward/Bed</b></td>
                                  <td class="head-td" style="width: 10px;font-weight: 500;">:&nbsp;</td>
                                  <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">{{billprint.wardname}} <span *ngIf="billprint.bedno != undefined && billprint.bedno != ''">/</span> {{billprint.bedno}}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>  
                          <div class="col-3" *ngIf="surgery != undefined && surgery != ''">
                            <table style="width: 100%;" class="head-table">
                              <tbody>
                                <tr class="head-tr">
                                  <td class="head-td" style="width: 74px; font-weight: 500;font-family: Arial;color: black;">
                                    <b>Surgery name</b></td>
                                  <td class="head-td" style="width: 10px;font-weight: 500;">:&nbsp;</td>
                                  <td class="head-td" style="font-family: Arial, Helvetica, sans-serif"> {{surgery}} </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>                       
                          <div class="col-3" *ngIf="billprint.created_date != undefined && billprint.created_date != ''">
                            <table style="width: 100%;" class="head-table">
                              <tbody>
                                <tr class="head-tr">
                                  <td class="head-td" style="width: 88px; font-weight: 500;font-family: Arial;color: black;">
                                    <b>Bill Date/Time</b></td>
                                  <td class="head-td" style="width: 10px;font-weight: 500;">:&nbsp;</td>
                                  <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">{{billprint.created_date}}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div class="col-3" *ngIf="billId != undefined && billId != ''">
                            <table style="width: 100%;" class="head-table">
                              <tbody>
                                <tr class="head-tr">
                                  <td class="head-td" style="width: 74px; font-weight: 500;font-family: Arial;color: black;">
                                    <b>Bill No</b></td>
                                  <td class="head-td" style="width: 10px;font-weight: 500;">:&nbsp;</td>
                                  <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">
                                    {{billId}} 
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>        
                          <!-- <div class="col-3" [hidden]="mainBalanceFlag">
                            <table style="width: 100%;" class="head-table">
                              <tbody>
                                <tr class="head-tr">
                                  <td class="head-td" style="width: 74px; font-weight: 500;font-family: Arial;color: black;">
                                    <b>Bill Amount (र)</b></td>
                                  <td class="head-td" style="width: 10px;font-weight: 500;">:&nbsp;</td>
                                  <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">
                                    {{billAmount}} 
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>    -->
                          <div class="col-3" *ngIf="credit_type != undefined && credit_type != ''">
                            <table style="width: 100%;" class="head-table">
                              <tbody>
                                <tr class="head-tr">
                                  <td class="head-td" style="width: 74px; font-weight: 500;font-family: Arial;color: black;">
                                    <b>Credit bill</b></td>
                                  <td class="head-td" style="width: 10px;font-weight: 500;">:&nbsp;</td>
                                  <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">
                                    {{credit_type}} 
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>                                 
                          <div class="col-6">
                            <table style="width: 100%;" class="head-table">
                                <tbody>
                                  <tr class="head-tr">
                                      <td class="head-td" style="width: 74px; font-weight: 500;font-family: Arial;color: black;">
                                        <b>Address</b></td>
                                      <td class="head-td" style="width: 10px; font-weight: 500;">:&nbsp;</td>
                                      <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">
                                        {{billprint.caddress1}} {{billprint.caddress2}} {{billprint.clocation}} {{ billprint.clientcity}} {{billprint.clestare}}
                                    </td>
                                  </tr>                 
                                </tbody>
                            </table>
                          </div>                                              
                        </div>                 
                      </div>                    
                    </div>
                  </div>
                </div>          
              </div>
            </div>
            
            <div class="row" *ngIf="pharma_bill_flag">
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom"  style="margin-left: auto;margin-top: -10px;height: 15px !important;"><br/>
                <mat-label class="matlabel" style="margin-top: 10px !important;">Medicine with details
                    <mat-checkbox color="primary" [(ngModel)]="med_with_det" (change)="getBillDetailedView()"></mat-checkbox>
                </mat-label>
              </div>
            </div>

            <div class="row">
              <div class="col-12" [hidden]="billingPrintViewFlag" style="font-family: Arial, Helvetica, sans-serif;">
                <p  
                  class="tervys_heading" style="font-weight: 600;">Bill particulars</p> 
                  <div class="table-responsive dig_table_overflow">
                    <table id="card_tbl"  class="table table-nowrap table-sm" >
                      <thead class="thvalues">
                        <tr style="height: 25px;">
                          <th style="width: 2% !important;">Sno</th>
                          <th style="width: 5%;">Created date/time</th>
                          <th style="width: 10% !important;">Service</th>
                          <th style="width: 5% !important;" [hidden]="paytypreq">Payment type</th>
                          <th style="width: 3% !important;">Rate</th>
                          <th style="width: 3% !important;" [hidden]="discount_flag">Discount(%)</th>
                          <th style="width: 3% !important;" [hidden]="!bill_qty_flag || !inpatient_flag">Quantity</th>
                          <th style="width: 3% !important;" [hidden]="bill_qty_flag && discount_flag && !inpatient_flag">Amount</th>                              
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let charge of categoryChargeList">
                          <td colspan="8"> 
                            <div>
                              <div>
                                <p class="tervys_heading1 col-12" *ngIf="charge.category_flag"><b
                                  style="text-transform: capitalize;">{{charge.category}}</b></p>
                                <p class="tervys_heading1 col-12" *ngIf="!charge.category_flag"><b
                                    style="text-transform: capitalize;">{{charge.bill_type}}</b></p>
                                <table class="table" style="margin-bottom: 0;">
                                  <tbody>
                                    <tr *ngFor='let person of charge.charges'>
                                      <td class="billlistcenter" style="width: 2% !important;">{{person.sno}}</td>
                                      <td class="billlistcenter" style="width: 5% !important;">{{person.created_date_time}}</td>
                                      <td class="billlistleft" style="width: 10% !important;">{{person.bill_type}}</td>
                                      <td class="billlistleft" style="width: 5% !important;" [hidden]="paytypreq">{{person.pay_type}}</td>
                                      <td class="billlistright" style="width: 3% !important;" [hidden]="!bill_qty_flag">{{person.amount_txt}}</td>
                                      <td class="billlistright" style="width: 3% !important;" [hidden]="bill_qty_flag"> {{person.amount_txt}}<span *ngIf="person.quantity != '-'"> x {{person.quantity}}</span></td>
                                      <td class="billlistright" style="width: 3% !important;" [hidden]="discount_flag">{{person.concession}}</td>
                                      <td class="billlistright" style="width: 3% !important;" [hidden]="!bill_qty_flag || !inpatient_flag">{{person.quantity}}</td>
                                      <td class="billlistright" style="width: 3% !important;" [hidden]="multiply_flag">{{person.multiply_value}}</td>                 
                                      <td class="billlistright" style="width: 3% !important;" [hidden]="discount_flag">{{person.fee}}</td>
                                    </tr>
                                  </tbody>
                                </table>
                                <table class="table" style="margin-bottom: 0;" *ngIf="charge.charges.length != 0">
                                  <tbody>
                                    <tr>
                                      <td class="billlistcenter" style="width: 2% !important;"></td>
                                      <td class="billlistcenter" style="width: 5% !important;"></td>
                                      <td class="billlistleft" style="width: 10% !important;"></td>
                                      <td class="billlistleft" style="width: 5% !important;" [hidden]="paytypreq"></td>
                                      <td class="billlistright" style="width: 3% !important;" [hidden]="!bill_qty_flag"></td>
                                      <td class="billlistright" style="width: 3% !important;" [hidden]="bill_qty_flag"></td>
                                      <td class="billlistright" style="width: 3% !important;" [hidden]="discount_flag"></td>
                                      <td class="billlistright" style="width: 3% !important;" [hidden]="!bill_qty_flag || !inpatient_flag"></td>
                                      <td class="billlistright" style="width: 3% !important;" [hidden]="multiply_flag">{{"Subtotal -  "}}&nbsp;&nbsp;&nbsp;{{charge.sub_total}}</td>                 
                                      <td class="billlistright" style="width: 3% !important;" [hidden]="discount_flag"></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
              </div>           
            </div>
  
            <div [hidden]="treatementPlanFlag">
              <p class="casesheet_para">Treatement plan</p>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" [hidden]="treatementPlanFlag">
              <table mdbTable datatable [dtOptions]="dtOptions" class="table table-nowrap table-sm dataTable">
                <thead>
                  <tr>
                    <th>Plan</th>
                    <th class="tbl_txt_center">Estimate</th>
                    <th class="tbl_txt_center">Select</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let person of estimatePrintData let i = index">
                    <td class="billlistleft">{{person.treatment_desc}}</td>
                    <td class="billlistright">{{person.estimate}}</td>
                  </tr>
                </tbody>
              </table>
              <br><br><br>
            </div>
            <div class="row">
              <div class="col-4 col-sm-4 col-md-8 col-lg-8 col-xl-9">
                <div class="col-4 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                  <table  style="margin-top: 10px;font-family: Arial, Helvetica, sans-serif !important;"> 
                    <tr *ngFor="let pay of paymentsarray">
                      <td style="width: 150px !important;font-size: 12px;">{{pay.pay_desc}}</td>
                      <td style="width: 150px !important;font-size: 12px;text-align: right;">{{pay.amount}}</td>
                    </tr>
                  </table>
                </div>
              </div>           
        
              <div class="col-8 col-sm-8 col-md-4 col-lg-4 col-xl-3" style="margin-top: 10px;">
                <div class="row ">
                  <div class="col-8 col-sm-8 col-md-6 col-lg-6 col-xl-8 alignRight" [hidden]="cgstFlag">
                    <mat-label class="matlabel ">CGST ({{billprint.cgst}} %) र </mat-label>
                  </div>
                  <div class="col-4 col-sm-4 col-md-5 col-lg-6 col-xl-4 billlistright" [hidden]="cgstFlag">
                    <span class="billlistright"> {{cgst}}</span>
                  </div>
  
                  <div class="col-8 col-sm-8 col-md-6 col-lg-6 col-xl-8 alignRight" [hidden]="sgstFlag">
                    <mat-label class="matlabel ">SGST ({{billprint.sgst}} %) र </mat-label>
                  </div>
                  <div class="col-4 col-sm-4 col-md-5 col-lg-6 col-xl-4 billlistright" [hidden]="sgstFlag">
                    <span class="billlistright"> {{sgst}}</span>
                  </div>
  
                  <!-- <div class="col-8 col-sm-8 col-md-6 col-lg-6 col-xl-8 alignRight" [hidden]="prevBalanceFlag">
                    <mat-label class="matlabel"> Previous balance र</mat-label>
                  </div>
                  <div class="col-4 col-sm-4 col-md-5 col-lg-6 col-xl-4 billlistright" [hidden]="prevBalanceFlag">
                    <span class="billlistright">{{prevBalance}}</span>
                  </div> -->
  
                  <div class="col-8 col-sm-8 col-md-6 col-lg-6 col-xl-8 alignRight">
                    <mat-label class="matlabel ">Total (र)</mat-label>
                  </div>
                  <div class="col-4 col-sm-4 col-md-5 col-lg-6 col-xl-4 billlistright">
                    <span style="padding-right: 15px;">{{totalBillAmount}}</span>
                  </div>

                  <div class="col-8 col-sm-8 col-md-6 col-lg-6 col-xl-8 alignRight" [hidden]="advancevalue == 0">
                    <mat-label class="matlabel">Advance (र)</mat-label>
                  </div>
                  <div class="col-4 col-sm-4 col-md-5 col-lg-6 col-xl-4 billlistright" [hidden]="advancevalue == 0">
                    <span style="padding-right: 15px;">{{advancevalue}}</span>
                  </div>

                  <div class="col-8 col-sm-8 col-md-6 col-lg-6 col-xl-8 alignRight" [hidden]="sponsor_due == 0">
                    <mat-label class="matlabel "> Sponsor Due (र)</mat-label>
                  </div>
                  <div class="col-4 col-sm-4 col-md-5 col-lg-6 col-xl-4 billlistright" [hidden]="sponsor_due == 0">
                    <span style="padding-right: 15px;">{{sponsor_due}} </span>
                  </div>

                  <div class="col-8 col-sm-8 col-md-6 col-lg-6 col-xl-8 alignRight" [hidden]="spons_set_amount == 0">
                    <mat-label class="matlabel "> Sponsor Settled (र)</mat-label>
                  </div>
                  <div class="col-4 col-sm-4 col-md-5 col-lg-6 col-xl-4 billlistright" [hidden]="spons_set_amount == 0">
                    <span style="padding-right: 15px;">{{spons_set_amount}} </span>
                  </div>

                  <div class="col-8 col-sm-8 col-md-6 col-lg-6 col-xl-8 alignRight" [hidden]="tds_amount == 0">
                    <mat-label class="matlabel "> TDS ({{spons_tds_percen}} %) (र)</mat-label>
                  </div>
                  <div class="col-4 col-sm-4 col-md-5 col-lg-6 col-xl-4 billlistright" [hidden]="tds_amount == 0">
                    <span style="padding-right: 15px;">{{tds_amount}} </span>
                  </div>
                  
                  <div class="col-8 col-sm-8 col-md-6 col-lg-6 col-xl-8 alignRight" [hidden]="discount_amount == 0">
                    <mat-label class="matlabel ">Discount (र)</mat-label> 
                  </div>
                  <div class="col-4 col-sm-4 col-md-5 col-lg-6 col-xl-4 billlistright" [hidden]="discount_amount == 0">
                    <span style="padding-right: 15px;">{{discount_amount}}</span>
                  </div>
  
                  <!-- <div class="col-8 col-sm-8 col-md-6 col-lg-6 col-xl-8 alignRight" [hidden]="totalFlag">
                    <mat-label class="matlabel "> Bill amount र</mat-label>
                  </div>
                  <div class="col-4 col-sm-4 col-md-5 col-lg-6 col-xl-4 billlistright" [hidden]="totalFlag">
                    <span class="">{{billAmount}} </span>
                  </div> -->
                 
                  <div class="col-8 col-sm-8 col-md-6 col-lg-6 col-xl-8 alignRight">
                    <mat-label class="matlabel">Paid (र)</mat-label>
                  </div>
                  <div class="col-4 col-sm-4 col-md-5 col-lg-6 col-xl-4 billlistright" >
                    <span style="padding-right: 15px;">{{paid}} </span>
                  </div>
  
                  <div class="col-8 col-sm-8 col-md-6 col-lg-6 col-xl-8 alignRight" [hidden]="refund != 0">
                    <mat-label class="matlabel">Balance (र)</mat-label>
                  </div>
                  <div class="col-4 col-sm-4 col-md-5 col-lg-6 col-xl-4 billlistright" [hidden]="refund != 0">
                    <span style="padding-right: 15px;">{{balance}}</span>
                  </div>
                  <div class="col-8 col-sm-8 col-md-6 col-lg-6 col-xl-8 alignRight" [hidden]="refund == 0">
                    <mat-label class="matlabel">Refund (र)</mat-label>
                  </div>
                  <div class="col-4 col-sm-4 col-md-5 col-lg-6 col-xl-4 billlistright" [hidden]="refund == 0">
                    <span style="padding-right: 15px;">{{refund}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
  
          <div [hidden]="true">               
            <div [hidden]="true" #printbanner id="printbanner" *ngFor="let billprint of billingDetailList">
              <table style="border:'0';width:100%">              
               <thead>
                <tr>
                  <th style="width:100%;">                                 
                    <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;margin-right: 30px; margin-left: 20px;"> 
                      <div style="width: 100%; height: 235px !important;"> 
                        <div style="width: 100%;">
                          <img alt="image" src={{hospitalLogo}}   [style]="printlogostyle"> 
                        </div>                                                             
                        <table style="width: 100%;font-size: 12px;border: 1px solid black;margin-top: 15px;margin-bottom: 5px;border-radius: 10px;height: 95px;"> 
                          <tbody>
                            <tr>
                              <td style="padding-left: 5px;"><strong>Patient Name</strong></td>
                              <td>: {{client_name}} </td>
                              <td style="padding-left: 5px;"><strong>Inpatient Id</strong></td>
                              <td>: {{billprint.inpatientid}} </td>
                            </tr>
                            <tr>
                              <td style="padding-left: 5px;"><strong>Age/Gender</strong></td>
                              <td>: {{age}} / {{gender}}</td>
                              <td style="padding-left: 5px;"><strong>Admission Date/Time</strong></td>
                              <td>: {{billprint.admitteddate}}&nbsp;{{billprint.admittedtime}}</td>
                            </tr>
                            <tr>
                              <td style="padding-left: 5px;"><strong>Doctor</strong></td>
                              <td>: {{dr_name}}</td>
                              <td style="padding-left: 5px;"><strong>Discharge Date/Time</strong></td>
                              <td>: {{billprint.discharge_date}}&nbsp;{{billprint.discharge_time}}</td>
                            </tr>
                            <tr>
                              <td style="padding-left: 5px;"><strong>Bill No</strong></td>
                              <td>: {{billId}}</td>
                              <td style="padding-left: 5px;"><strong>Address</strong></td>
                              <td>: {{caddress1}} {{caddress2}}</td>
                            </tr>
                            <tr>
                              <td style="padding-left: 5px;"><strong>Bill Date/Time</strong></td>
                              <td>: {{created_date}}</td>
                              <td style="padding-left: 5px;"><strong></strong></td>
                              <td><span class="address_visibility">:</span> {{clocation}} {{clientcity}}</td>
                            </tr>
                            <tr>
                              <td style="padding-left: 5px;"><strong>Ward/bed no</strong></td>
                              <td>: {{billprint.wardname}} <span *ngIf="billprint.bedno != '' && billprint.bedno != undefined">/</span> {{billprint.bedno}}</td>   
                              <td style="padding-left: 5px;"><strong></strong></td>
                              <td><span class="address_visibility">:</span> {{clestare}}</td>                                                
                            </tr>
                            <tr *ngIf="surgery != undefined && surgery != ''">
                              <td style="padding-left: 5px;"><strong>Surgery name</strong></td>                             
                              <td>: {{surgery}}</td>
                            </tr> 
                            <tr *ngIf="credit_type != undefined && credit_type != ''">
                              <td style="padding-left: 5px;"><strong>Credit bill</strong></td>                             
                              <td>: {{credit_person}}</td>
                            </tr>                      
                            <tr> 
                              <td style="padding-left: 5px;"><strong></strong></td>                             
                              <td></td>
                              <td style="padding-left: 5px;"><strong></strong></td>
                              <td *ngIf="barcode_flag">
                                <img [style]="barcode_style"
                                  src={{barcode_url}} /></td> 
                            </tr>                         
                          </tbody>
                        </table>
                      </div>
                    </div>               
                  </th>
                </tr>
               </thead>
               <tbody >  
                 <tr>  
                   <td width="100%">  
                    <!-- <p [style]="p_tag_style" *ngIf="bill_heading != ''"><b>{{bill_heading}}</b></p> -->
                    <p [style]="p_tag_style"><b>Bill Details</b></p>
                    <div style="margin-left: 20px;margin-right: 10px;margin-top: -10px;" *ngIf="insurance_flag">
                      <h3 style="margin:10px 0 ;" class="heading">Insurance details</h3>
                      <table style="width: 98%;border: 1px solid black;border-radius: 10px;margin-right: 20px;margin-bottom: 10px !important;">
                        <tr>
                          <td style="width: 60%; vertical-align: top;">
                            <div>
                              <table style="width: 100%;" class="head-table">
                                <tbody>
                                  <tr class="head-tr">
                                    <td class="head-td" style="width: 100px; font-weight: 600 !important;padding: 0px;">
                                      Insurance Comp</td>
                                    <td class="head-td" style="width: 10px; font-weight: 600 !important;padding: 0px;">:&nbsp;</td>
                                    <td class="head-td" style="font-family: Times New Roman;padding: 0px;">{{insurer}}</td>
                                  </tr>
                                </tbody>
                              </table>
                              <table style="width: 100%;" class="head-table">
                                <tbody>
                                  <tr class="head-tr">
                                    <td class="head-td" style="width: 100px; font-weight: 600 !important;padding: 0px;">
                                      Sponsor</td>
                                    <td class="head-td" style="width: 10px; font-weight: 600 !important;padding: 0px;">:&nbsp;</td>
                                    <td class="head-td" style="font-family: Times New Roman;padding: 0px;">{{sponser_name}}</td>
                                  </tr>
                                </tbody>
                              </table> 
                              <table style="width: 100%;" class="head-table">
                                <tbody>
                                  <tr class="head-tr">
                                    <td class="head-td" style="width: 100px; font-weight: 600 !important;padding: 0px;">
                                      Sponsor GSTIN</td>
                                    <td class="head-td" style="width: 10px; font-weight: 600 !important;padding: 0px;">:&nbsp;</td>
                                    <td class="head-td" style="font-family: Times New Roman;padding: 0px;">{{sponser_GSTIN}}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </td>
                        </tr>
                      </table>
                    </div>
                    <div class="bill_border">
                      <div *ngFor="let billprint of billingDetailList">
                        <div [hidden]="billingPrintViewFlag" style="margin-left: 20px;margin-right: 10px;">
                          <div class="row">
                            <table style="border: 1px solid black;border-collapse: collapse;margin-right: 20px;">
                              <thead>
                                <tr>
                                  <th style="width: 70px;border: 1px solid black;border-collapse: collapse;"><b>Sno</b></th>
                                  <th style="width: 150px;border: 1px solid black;border-collapse: collapse;" *ngIf="wardName != 'Dialysis'"><b>Created date/time</b></th>
                                  <th style="width: 150px;border: 1px solid black;border-collapse: collapse;" *ngIf="wardName == 'Dialysis'"><b>Created date</b></th>
                                  <th style="width: 380px;border: 1px solid black;border-collapse: collapse;"><b>Service</b></th>
                                  <th style="width: 150px;border: 1px solid black;border-collapse: collapse;" [hidden]="paytypreq"><b>Payment type</b></th>
                                  <th style="width: 150px;border: 1px solid black;border-collapse: collapse;"><b>Rate</b></th>
                                  <th [hidden]="discount_flag" style="width: 150px;border: 1px solid black;border-collapse: collapse;">Discount(%)</th>
                                  <th [hidden]="!bill_qty_flag || !inpatient_flag" style="width: 150px;border: 1px solid black;border-collapse: collapse;">Quantity</th>
                                  <th [hidden]="bill_qty_flag && discount_flag && !inpatient_flag" style="width: 170px;border: 1px solid black;border-collapse: collapse;"><b>Amount</b></th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let charge of categoryChargeList">
                                    <td colspan="8"> 
                                      <div>
                                        <div>
                                          <p style="font-weight: 500;font-family: Arial;color: black;font-size: 13px !important;margin: 5px 0 0 0 !important;
                                                    text-align: left !important;"><b
                                            style="text-transform: capitalize;" *ngIf="charge.category_flag">{{charge.category}}</b></p>
                                          <p style="font-weight: 500;font-family: Arial;color: black;font-size: 13px !important;margin: 5px 0 0 0 !important;
                                                    text-align: left !important;" *ngIf="!charge.category_flag"><b
                                            style="text-transform: capitalize;">{{charge.bill_type}}</b></p>
                                          <table style="border: 1px solid black;border-collapse: collapse;border-style: solid hidden solid hidden;">
                                            <tbody>
                                              <tr *ngFor='let billprinttable of charge.charges'>
                                                <td style="width: 70px;text-align: center;border: 1px solid black;border-collapse: collapse;"
                                                  data-th="sno">{{billprinttable.sno}}</td>
                                                <td style="width: 150px;text-align: center;border: 1px solid black;border-collapse: collapse;"
                                                  data-th="sno" *ngIf="wardName != 'Dialysis'">{{billprinttable.created_date_time}}</td>
                                                <td style="width: 150px;text-align: center;border: 1px solid black;border-collapse: collapse;"
                                                  data-th="sno" *ngIf="wardName == 'Dialysis'">{{billprinttable.created_date | date : "dd-MM-yyyy"}}</td>
                                                <td style="width: 380px;text-align: left;border: 1px solid black;border-collapse: collapse;"
                                                  data-th="Type">{{billprinttable.bill_type}}</td>
                                                <td style="width: 150px;text-align: center;border: 1px solid black;border-collapse: collapse;"
                                                  data-th="Type" [hidden]="paytypreq">{{billprinttable.pay_type}}</td>
                                                <td [style]="amount_td_style"
                                                  data-th="Fee" [hidden]="!bill_qty_flag">{{billprinttable.amount_txt}}</td>
                                                <td style="width: 150px;text-align: right; border: 1px solid black;border-collapse: collapse;"
                                                  data-th="Fee" [hidden]="bill_qty_flag">{{billprinttable.amount_txt}}<span *ngIf="billprinttable.quantity != '-'"> x {{billprinttable.quantity}}</span></td>
                                                <td style="width: 150px;text-align: right;border: 1px solid black;border-collapse: collapse;"
                                                  data-th="Type" [hidden]="discount_flag">{{billprinttable.concession}}</td>
                                                <td style="width: 150px;text-align: right; border: 1px solid black;border-collapse: collapse;"
                                                  data-th="Fee"[hidden]="!bill_qty_flag || !inpatient_flag"> {{billprinttable.quantity}} </td>
                                                <td style="width: 170px;text-align: right; border: 1px solid black;border-collapse: collapse;"
                                                  data-th="Fee" [hidden]="multiply_flag">{{billprinttable.multiply_value}}</td> 
                                                <td style="width: 170px;text-align: right; border: 1px solid black;border-collapse: collapse;"
                                                  data-th="Fee" [hidden]="discount_flag">{{billprinttable.fee}}</td>
                                              </tr>
                                              <tr *ngIf="charge.charges.length != 0">
                                                <td colspan="3"></td>
                                                <td style="border: 1px solid black;border-collapse: collapse;">
                                                  <div style="width: 100%; text-align: end;"><b>Subtotal</b></div>
                                                </td>
                                                <td style="border: 1px solid black;border-collapse: collapse;">
                                                  <div style="width: 100%; text-align: end;">{{charge.sub_total}}</div>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                              </tbody>                                                    
                            </table>
                          </div>
                        </div>                 
                        <div style="width: 100%;">
                            <table style="margin-bottom: -20px;">
                                <tr>
                                  <td style="vertical-align: top; width: 26%;">
                                    <table class="col-3 col-md-5 col-lg-3 col-xl-3" style="float: left;margin-left: 15px;" *ngIf="paymentsarray !=0">
                                      <tr *ngFor="let pay of paymentsarray">
                                        <td style="width: 90px !important;vertical-align: top;">{{pay.pay_desc}}</td>
                                        <td style="width: 100px !important;vertical-align: top;text-align: right;">{{pay.amount}}</td>
                                      </tr>
                                    </table>              
                                  </td>                           
                                  <td style="vertical-align: top;width: 31%;">
                                    <table  style="float: right;margin-left: 110px;margin-right: 25px;" *ngIf="paymentsarray.length != 0">             
                                      <tr [hidden]="cgstFlag">
                                        <th style="text-align: left;"><strong>CGST</strong>({{billprint.cgst}} %) (र)</th>
                                        <td style="text-align: right; width: 100px;" data-th="Central GST"
                                          class="fee_txt">
                                          {{cgst}}</td>
                                      </tr>
                                      <tr [hidden]="sgstFlag">
                                        <th style="text-align: left;"><strong>SGST</strong>({{billprint.sgst}} %) (र)</th>
                                        <td style="text-align: right;width: 100px;" data-th="State GST" class="fee_txt">
                                          {{sgst}}</td>
                                      </tr>        
                                      <tr [hidden]="totalBillAmount == 0">
                                        <th style="text-align: left;">Total (र) </th>
                                        <td style="text-align: right;width: 100px;" data-th="Total र" class="fee_txt">
                                          {{totalBillAmount}}</td>
                                      </tr>
                                      <tr [hidden]="advancevalue == 0">
                                        <th style="text-align: left;">Advance (र)</th>
                                        <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                                           class="fee_txt">
                                          {{advancevalue}}</td>
                                      </tr>
                                      <tr [hidden]="sponsor_due == 0">
                                          <th style="text-align: left;">Sponser Due (र)</th>
                                          <td style="text-align: right;width: 100px;" data-th="Bill amount"
                                            class="fee_txt">
                                            {{sponsor_due}}</td>
                                      </tr>
                                      <tr *ngIf="spons_set_amount != 0">
                                        <th style="text-align: left;">Sponsor Settled (र)</th>
                                        <td style="text-align: right;width: 100px;" data-th="Bill amount"
                                          class="fee_txt">
                                          {{spons_set_amount}}</td>
                                      </tr>
                                      <tr *ngIf="tds_amount != 0">
                                        <th style="text-align: left;">TDS ({{spons_tds_percen}} %) (र)</th>
                                        <td style="text-align: right;width: 100px;" data-th="Bill amount"
                                          class="fee_txt">
                                          {{tds_amount}}</td>
                                      </tr>
                                      <tr [hidden]="discount_amount == 0">
                                        <th style="text-align: left;">Discount (र) </th> 
                                        <td style="text-align: right;width: 100px;" data-th="Discount र" class="fee_txt">
                                          {{discount_amount}}</td>
                                      </tr>
                                      <tr>
                                        <th style="text-align: left;">Paid (र)</th>
                                        <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                                          class="fee_txt">
                                          {{paid}}</td>
                                      </tr>
                                      <tr [hidden]="balance == 0">
                                        <th style="text-align: left;">Balance (र)</th>
                                        <td style="text-align: right;width: 100px;" data-th="Balance" class="fee_txt">
                                          {{balance}}</td>
                                      </tr> 
                                      <tr [hidden]="refund == 0">
                                        <th style="text-align: left;">Refund (र)</th>
                                        <td style="text-align: right;width: 100px;" data-th="Refund" class="fee_txt">
                                          {{refund}}</td>
                                      </tr>
                                    </table>
                                    <table  style="float: right;margin-left: 120px;margin-right: 25px;" *ngIf="paymentsarray.length == 0">             
                                      <tr [hidden]="cgstFlag">
                                          <th style="text-align: left;"><strong>CGST</strong>({{billprint.cgst}} %) (र)</th>
                                          <td style="text-align: right; width: 100px;" data-th="Central GST"
                                            class="fee_txt">
                                            {{cgst}}</td>
                                        </tr>
                                        <tr [hidden]="sgstFlag">
                                          <th style="text-align: left;"><strong>SGST</strong>({{billprint.sgst}} %) (र)</th>
                                          <td style="text-align: right;width: 100px;" data-th="State GST" class="fee_txt">
                                            {{sgst}}</td>
                                        </tr>        
                                        <tr [hidden]="totalBillAmount == 0">
                                          <th style="text-align: left;">Total (र) </th>
                                          <td style="text-align: right;width: 100px;" data-th="Total र" class="fee_txt">
                                            {{totalBillAmount}}</td>
                                        </tr>
                                        <tr [hidden]="advancevalue == 0">
                                          <th style="text-align: left;">Advance (र)</th>
                                          <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                                             class="fee_txt">
                                            {{advancevalue}}</td>
                                        </tr>
                                        <tr [hidden]="sponsor_due == 0">
                                            <th style="text-align: left;">Sponser Due (र)</th>
                                            <td style="text-align: right;width: 100px;" data-th="Bill amount"
                                              class="fee_txt">
                                              {{sponsor_due}}</td>
                                        </tr>
                                        <tr *ngIf="spons_set_amount != 0">
                                          <th style="text-align: left;">Sponsor Settled (र)</th>
                                          <td style="text-align: right;width: 100px;" data-th="Bill amount"
                                            class="fee_txt">
                                            {{spons_set_amount}}</td>
                                        </tr>
                                        <tr *ngIf="tds_amount != 0">
                                          <th style="text-align: left;">TDS ({{spons_tds_percen}} %) (र)</th>
                                          <td style="text-align: right;width: 100px;" data-th="Bill amount"
                                            class="fee_txt">
                                            {{tds_amount}}</td>
                                        </tr>
                                        <tr [hidden]="discount_amount == 0">
                                          <th style="text-align: left;">Discount (र) </th>
                                          <td style="text-align: right;width: 100px;" data-th="Discount र" class="fee_txt">
                                            {{discount_amount}}</td>
                                        </tr>
                                        <tr>
                                          <th style="text-align: left;">Paid (र)</th>
                                          <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                                            class="fee_txt">
                                            {{paid}}</td>
                                        </tr>
                                        <tr [hidden]="balance == 0">
                                          <th style="text-align: left;">Balance (र)</th>
                                          <td style="text-align: right;width: 100px;" data-th="Balance" class="fee_txt">
                                            {{balance}}</td>
                                        </tr> 
                                        <tr [hidden]="refund == 0">
                                          <th style="text-align: left;">Refund (र)</th>
                                          <td style="text-align: right;width: 100px;" data-th="Refund" class="fee_txt">
                                            {{refund}}</td>
                                        </tr>
                                    </table>
                                  </td>                        
                                </tr>
                            </table>
                        </div>
                      </div>
                    </div>                           
                   </td>
                 </tr>
               </tbody>
               <tfoot style="margin-bottom: 10px;"> 
                <tr>  
                  <td width="100%">  
                    <table style="width: 100%;margin-top: 50px !important;">  
                      <tr>  
                        <td style="vertical-align: top;width:50%;">
                          <table style="float: left !important;margin-left: 15px;">
                            <tr>
                              <td>&nbsp;</td>
                            </tr>
                          </table> 
                        </td>  
                        <td style="vertical-align: top;width:50%;">
                          <table style="float: right !important;margin-left: 20px;margin-right: 32px;">
                            <tr>
                              <div style="float: right !important;margin-left: 20px;margin-right: 32px;">
                                <p style="margin-bottom: 3px !important;text-align: center;">{{logUserName}}</p>
                                <p style="text-align: center;margin-top: 6px;margin-bottom: 20px;"><strong>Created by</strong></p>
                              </div>                       
                            </tr>
                          </table>                      
                        </td>
                      </tr>  
                    </table> 
                  </td>
                </tr> 
               </tfoot>    
              </table>
            </div>
  
            <div [hidden]="true" #printnoheader id="printnoheader" *ngFor="let billprint of billingDetailList">
              <table style="border:'0';width:100%;">  
                <thead>
                  <tr>
                    <th style="width:100%;">
                      <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;margin-right: 30px; margin-left: 20px;"> 
                        <div [style]="nobanner_style">
                          <div style="height: 5px !important;"></div>
                          <table style="width: 100%;font-size: 12px;border: 1px solid black;border-radius: 10px;height: 85px;"> 
                            <tbody>
                              <tr>
                                <td style="padding-left: 5px;"><strong>Patient Name</strong></td>
                                <td>: {{client_name}} </td>
                                <td style="padding-left: 5px;"><strong>Inpatient Id</strong></td>
                                <td>: {{billprint.inpatientid}} </td>
                              </tr>
                              <tr>
                                <td style="padding-left: 5px;"><strong>Age/Gender</strong></td>
                                <td>: {{age}} / {{gender}}</td>
                                <td style="padding-left: 5px;"><strong>Admission Date/Time</strong></td>
                                <td>: {{billprint.admitteddate}}&nbsp;{{billprint.admittedtime}}</td>
                              </tr>
                              <tr>
                                <td style="padding-left: 5px;"><strong>Doctor</strong></td>
                                <td>: {{dr_name}}</td>
                                <td style="padding-left: 5px;"><strong>Discharge Date/Time</strong></td>
                                <td>: {{billprint.discharge_date}}&nbsp;{{billprint.discharge_time}}</td>
                              </tr>
                              <tr>
                                <td style="padding-left: 5px;"><strong>Bill No</strong></td>
                                <td>: {{billId}}</td>
                                <td style="padding-left: 5px;"><strong>Address</strong></td>
                                <td>: {{caddress1}} {{caddress2}}</td>
                              </tr>
                              <tr>
                                <td style="padding-left: 5px;"><strong>Bill Date/Time</strong></td>
                                <td>: {{created_date}}</td>
                                <td style="padding-left: 5px;"><strong></strong></td>
                                <td><span class="address_visibility">:</span> {{clocation}} {{clientcity}}</td>
                              </tr>
                              <tr>
                                <td style="padding-left: 5px;"><strong>Ward/bed no</strong></td>
                                <td>: {{billprint.wardname}} <span *ngIf="billprint.bedno != '' && billprint.bedno != undefined">/</span> {{billprint.bedno}}</td>   
                                <td style="padding-left: 5px;"><strong></strong></td>
                                <td><span class="address_visibility">:</span> {{clestare}}</td>                                                
                              </tr>
                              <tr *ngIf="surgery != undefined && surgery != ''">
                                <td style="padding-left: 5px;"><strong>Surgery name</strong></td>                             
                                <td>: {{surgery}}</td>
                              </tr> 
                              <tr *ngIf="credit_type != undefined && credit_type != ''">
                                <td style="padding-left: 5px;"><strong>Credit bill</strong></td>                             
                                <td>: {{credit_person}}</td>
                              </tr>                      
                              <tr> 
                                <td style="padding-left: 5px;"><strong></strong></td>                             
                                <td></td>
                                <td style="padding-left: 5px;"><strong></strong></td>
                                <td *ngIf="barcode_flag">
                                  <img [style]="barcode_style"
                                    src={{barcode_url}} /></td> 
                              </tr>                         
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody style="width: 100%;">  
                  <tr>  
                    <td width="100%">  
                      <!-- <p [style]="p_tag_style" *ngIf="bill_heading != ''"><b>{{bill_heading}}</b></p> -->
                      <p [style]="p_tag_style"><b>Bill Details</b></p>
                      <div style="margin-left: 20px;margin-right: 10px;margin-top: -10px;" *ngIf="insurance_flag">
                        <h3 style="margin:10px 0 ;" class="heading">Insurance details</h3>
                        <table style="width: 98%;border: 1px solid black;border-radius: 10px;margin-right: 20px;margin-bottom: 10px !important;">
                          <tr>
                            <td style="width: 60%; vertical-align: top;">
                              <div>
                                <table style="width: 100%;" class="head-table">
                                  <tbody>
                                    <tr class="head-tr">
                                      <td class="head-td" style="width: 100px; font-weight: 600 !important;padding: 0px;">
                                        Insurance Comp</td>
                                      <td class="head-td" style="width: 10px; font-weight: 600 !important;padding: 0px;">:&nbsp;</td>
                                      <td class="head-td" style="font-family: Times New Roman;padding: 0px;">{{insurer}}</td>
                                    </tr>
                                  </tbody>
                                </table>
                                <table style="width: 100%;" class="head-table">
                                  <tbody>
                                    <tr class="head-tr">
                                      <td class="head-td" style="width: 100px; font-weight: 600 !important;padding: 0px;">
                                        Sponsor</td>
                                      <td class="head-td" style="width: 10px; font-weight: 600 !important;padding: 0px;">:&nbsp;</td>
                                      <td class="head-td" style="font-family: Times New Roman;padding: 0px;">{{sponser_name}}</td>
                                    </tr>
                                  </tbody>
                                </table> 
                                <table style="width: 100%;" class="head-table">
                                  <tbody>
                                    <tr class="head-tr">
                                      <td class="head-td" style="width: 100px; font-weight: 600 !important;padding: 0px;">
                                        Sponsor GSTIN</td>
                                      <td class="head-td" style="width: 10px; font-weight: 600 !important;padding: 0px;">:&nbsp;</td>
                                      <td class="head-td" style="font-family: Times New Roman;padding: 0px;">{{sponser_GSTIN}}</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div class="bill_border">
                        <div *ngFor="let billprint of billingDetailList">
                          <div [hidden]="billingPrintViewFlag" style="margin-left: 20px;margin-right: 10px;">
                            <div class="row">
                              <table style="border: 1px solid black;border-collapse: collapse;margin-right: 20px;">
                                <thead>
                                  <tr>
                                    <th style="width: 70px;border: 1px solid black;border-collapse: collapse;"><b>Sno</b></th>
                                    <th style="width: 150px;border: 1px solid black;border-collapse: collapse;" *ngIf="wardName != 'Dialysis'"><b>Created date/time</b></th>
                                    <th style="width: 150px;border: 1px solid black;border-collapse: collapse;" *ngIf="wardName == 'Dialysis'"><b>Created date</b></th>
                                    <th style="width: 380px;border: 1px solid black;border-collapse: collapse;"><b>Service</b></th>
                                    <th style="width: 150px;border: 1px solid black;border-collapse: collapse;" [hidden]="paytypreq"><b>Payment type</b></th>
                                    <th style="width: 150px;border: 1px solid black;border-collapse: collapse;"><b>Rate</b></th>
                                    <th [hidden]="discount_flag" style="width: 150px;border: 1px solid black;border-collapse: collapse;">Discount(%)</th>
                                    <th [hidden]="!bill_qty_flag || !inpatient_flag" style="width: 150px;border: 1px solid black;border-collapse: collapse;">Quantity</th>
                                    <th [hidden]="bill_qty_flag && discount_flag && !inpatient_flag" style="width: 170px;border: 1px solid black;border-collapse: collapse;"><b>Amount</b></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr *ngFor="let charge of categoryChargeList">
                                    <td colspan="8"> 
                                      <div>
                                        <div>
                                          <p style="font-weight: 500;font-family: Arial;color: black;font-size: 13px !important;margin: 5px 0 0 0 !important;
                                                    text-align: left !important;"><b
                                            style="text-transform: capitalize;" *ngIf="charge.category_flag">{{charge.category}}</b></p>
                                          <p style="font-weight: 500;font-family: Arial;color: black;font-size: 13px !important;margin: 5px 0 0 0 !important;
                                                    text-align: left !important;" *ngIf="!charge.category_flag"><b
                                            style="text-transform: capitalize;">{{charge.bill_type}}</b></p>
                                          <table style="border: 1px solid black;border-collapse: collapse;border-style: solid hidden solid hidden;">
                                            <tbody>
                                              <tr *ngFor='let billprinttable of charge.charges'>
                                                <td style="width: 70px;text-align: center;border: 1px solid black;border-collapse: collapse;"
                                                  data-th="sno">{{billprinttable.sno}}</td>
                                                <td style="width: 150px;text-align: center;border: 1px solid black;border-collapse: collapse;"
                                                  data-th="sno" *ngIf="wardName != 'Dialysis'">{{billprinttable.created_date_time}}</td>
                                                <td style="width: 150px;text-align: center;border: 1px solid black;border-collapse: collapse;"
                                                  data-th="sno" *ngIf="wardName == 'Dialysis'">{{billprinttable.created_date | date : "dd-MM-yyyy"}}</td>
                                                <td style="width: 380px;text-align: left;border: 1px solid black;border-collapse: collapse;"
                                                  data-th="Type">{{billprinttable.bill_type}}</td>
                                                <td style="width: 150px;text-align: center;border: 1px solid black;border-collapse: collapse;"
                                                  data-th="Type" [hidden]="paytypreq">{{billprinttable.pay_type}}</td>
                                                <td [style]="amount_td_style"
                                                  data-th="Fee" [hidden]="!bill_qty_flag">{{billprinttable.amount_txt}}</td>
                                                <td style="width: 150px;text-align: right; border: 1px solid black;border-collapse: collapse;"
                                                  data-th="Fee" [hidden]="bill_qty_flag">{{billprinttable.amount_txt}}<span *ngIf="billprinttable.quantity != '-'"> x {{billprinttable.quantity}}</span></td>
                                                <td style="width: 150px;text-align: right;border: 1px solid black;border-collapse: collapse;"
                                                  data-th="Type" [hidden]="discount_flag">{{billprinttable.concession}}</td>
                                                <td style="width: 150px;text-align: right; border: 1px solid black;border-collapse: collapse;"
                                                  data-th="Fee"[hidden]="!bill_qty_flag || !inpatient_flag"> {{billprinttable.quantity}} </td>
                                                <td style="width: 170px;text-align: right; border: 1px solid black;border-collapse: collapse;"
                                                  data-th="Fee" [hidden]="multiply_flag">{{billprinttable.multiply_value}}</td> 
                                                <td style="width: 170px;text-align: right; border: 1px solid black;border-collapse: collapse;"
                                                  data-th="Fee" [hidden]="discount_flag">{{billprinttable.fee}}</td>
                                              </tr>
                                              <tr *ngIf="charge.charges.length != 0">
                                                <td colspan="3"></td>
                                                <td style="border: 1px solid black;border-collapse: collapse;">
                                                  <div style="width: 100%; text-align: end;"><b>Subtotal</b></div>
                                                </td>
                                                <td style="border: 1px solid black;border-collapse: collapse;">
                                                  <div style="width: 100%; text-align: end;">{{charge.sub_total}}</div>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>                                                     
                              </table>
                            </div>
                          </div>                    
                          <div style="width: 100%;">
                            <table style="margin-bottom: -20px;">
                              <tr>
                                <td style="vertical-align: top; width: 26%;">
                                  <table class="col-3 col-md-5 col-lg-3 col-xl-3" style="float: left;margin-left: 15px;" *ngIf="paymentsarray !=0">
                                    <tr *ngFor="let pay of paymentsarray">
                                      <td style="width: 90px !important;vertical-align: top;">{{pay.pay_desc}}</td>
                                      <td style="width: 100px !important;vertical-align: top;text-align: right;">{{pay.amount}}</td>
                                    </tr>
                                  </table>              
                                </td>                           
                                <td style="vertical-align: top;width: 31%;">
                                  <table  style="float: right;margin-left: 110px;margin-right: 25px;" *ngIf="paymentsarray.length != 0">             
                                    <tr [hidden]="cgstFlag">
                                      <th style="text-align: left;"><strong>CGST</strong>({{billprint.cgst}} %) (र)</th>
                                      <td style="text-align: right; width: 100px;" data-th="Central GST"
                                        class="fee_txt">
                                        {{cgst}}</td>
                                    </tr>
                                    <tr [hidden]="sgstFlag">
                                      <th style="text-align: left;"><strong>SGST</strong>({{billprint.sgst}} %) (र)</th>
                                      <td style="text-align: right;width: 100px;" data-th="State GST" class="fee_txt">
                                        {{sgst}}</td>
                                    </tr>        
                                    <tr [hidden]="totalBillAmount == 0">
                                      <th style="text-align: left;">Total (र) </th>
                                      <td style="text-align: right;width: 100px;" data-th="Total र" class="fee_txt">
                                        {{totalBillAmount}}</td>
                                    </tr>
                                    <tr [hidden]="advancevalue == 0">
                                      <th style="text-align: left;">Advance (र)</th>
                                      <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                                         class="fee_txt">
                                        {{advancevalue}}</td>
                                    </tr>
                                    <tr [hidden]="sponsor_due == 0">
                                        <th style="text-align: left;">Sponser Due (र)</th>
                                        <td style="text-align: right;width: 100px;" data-th="Bill amount"
                                          class="fee_txt">
                                          {{sponsor_due}}</td>
                                    </tr>
                                    <tr *ngIf="spons_set_amount != 0">
                                      <th style="text-align: left;">Sponsor Settled (र)</th>
                                      <td style="text-align: right;width: 100px;" data-th="Bill amount"
                                        class="fee_txt">
                                        {{spons_set_amount}}</td>
                                    </tr>
                                    <tr *ngIf="tds_amount != 0">
                                      <th style="text-align: left;">TDS ({{spons_tds_percen}} %) (र)</th>
                                      <td style="text-align: right;width: 100px;" data-th="Bill amount"
                                        class="fee_txt">
                                        {{tds_amount}}</td>
                                    </tr>
                                    <tr [hidden]="discount_amount == 0">
                                      <th style="text-align: left;">Discount (र) </th> 
                                      <td style="text-align: right;width: 100px;" data-th="Discount र" class="fee_txt">
                                        {{discount_amount}}</td>
                                    </tr>
                                    <tr>
                                      <th style="text-align: left;">Paid (र)</th>
                                      <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                                        class="fee_txt">
                                        {{paid}}</td>
                                    </tr>
                                    <tr [hidden]="balance == 0">
                                      <th style="text-align: left;">Balance (र)</th>
                                      <td style="text-align: right;width: 100px;" data-th="Balance" class="fee_txt">
                                        {{balance}}</td>
                                    </tr> 
                                    <tr [hidden]="refund == 0">
                                      <th style="text-align: left;">Refund (र)</th>
                                      <td style="text-align: right;width: 100px;" data-th="Refund" class="fee_txt">
                                        {{refund}}</td>
                                    </tr>
                                  </table>
                                  <table  style="float: right;margin-left: 120px;margin-right: 25px;" *ngIf="paymentsarray.length == 0">             
                                    <tr [hidden]="cgstFlag">
                                        <th style="text-align: left;"><strong>CGST</strong>({{billprint.cgst}} %) (र)</th>
                                        <td style="text-align: right; width: 100px;" data-th="Central GST"
                                          class="fee_txt">
                                          {{cgst}}</td>
                                      </tr>
                                      <tr [hidden]="sgstFlag">
                                        <th style="text-align: left;"><strong>SGST</strong>({{billprint.sgst}} %) (र)</th>
                                        <td style="text-align: right;width: 100px;" data-th="State GST" class="fee_txt">
                                          {{sgst}}</td>
                                      </tr>        
                                      <tr [hidden]="totalBillAmount == 0">
                                        <th style="text-align: left;">Total (र) </th>
                                        <td style="text-align: right;width: 100px;" data-th="Total र" class="fee_txt">
                                          {{totalBillAmount}}</td>
                                      </tr>
                                      <tr [hidden]="advancevalue == 0">
                                        <th style="text-align: left;">Advance (र)</th>
                                        <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                                           class="fee_txt">
                                          {{advancevalue}}</td>
                                      </tr>
                                      <tr [hidden]="sponsor_due == 0">
                                          <th style="text-align: left;">Sponser Due (र)</th>
                                          <td style="text-align: right;width: 100px;" data-th="Bill amount"
                                            class="fee_txt">
                                            {{sponsor_due}}</td>
                                      </tr>
                                      <tr *ngIf="spons_set_amount != 0">
                                        <th style="text-align: left;">Sponsor Settled (र)</th>
                                        <td style="text-align: right;width: 100px;" data-th="Bill amount"
                                          class="fee_txt">
                                          {{spons_set_amount}}</td>
                                      </tr>
                                      <tr *ngIf="tds_amount != 0">
                                        <th style="text-align: left;">TDS ({{spons_tds_percen}} %) (र)</th>
                                        <td style="text-align: right;width: 100px;" data-th="Bill amount"
                                          class="fee_txt">
                                          {{tds_amount}}</td>
                                      </tr>
                                      <tr [hidden]="discount_amount == 0">
                                        <th style="text-align: left;">Discount (र) </th>
                                        <td style="text-align: right;width: 100px;" data-th="Discount र" class="fee_txt">
                                          {{discount_amount}}</td>
                                      </tr>
                                      <tr>
                                        <th style="text-align: left;">Paid (र)</th>
                                        <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                                          class="fee_txt">
                                          {{paid}}</td>
                                      </tr>
                                      <tr [hidden]="balance == 0">
                                        <th style="text-align: left;">Balance (र)</th>
                                        <td style="text-align: right;width: 100px;" data-th="Balance" class="fee_txt">
                                          {{balance}}</td>
                                      </tr> 
                                      <tr [hidden]="refund == 0">
                                        <th style="text-align: left;">Refund (र)</th>
                                        <td style="text-align: right;width: 100px;" data-th="Refund" class="fee_txt">
                                          {{refund}}</td>
                                      </tr>
                                  </table>
                                </td>                        
                              </tr>
                            </table>
                          </div>                                              
                        </div>
                      </div>                           
                    </td>
                  </tr>
                </tbody>
                <tfoot style="margin-bottom: 20px;"> 
                  <tr>  
                    <td width="100%">  
                      <table style="width: 100%;margin-top: 50px !important;">  
                        <tr>  
                          <td style="vertical-align: top;width:50%;">
                            <table style="float: left !important;margin-left: 15px;">
                              <tr>
                                <td>&nbsp;</td>
                              </tr>
                            </table> 
                          </td>  
                          <td style="vertical-align: top;width:50%;">
                            <table style="float: right !important;margin-left: 20px;margin-right: 32px;">
                              <tr>
                                <div style="float: right !important;margin-left: 20px;margin-right: 32px;">
                                  <p style="margin-bottom: 3px !important;text-align: center;">{{logUserName}}</p>
                                  <p style="text-align: center;margin-top: 6px;margin-bottom: 20px;"><strong>Created by</strong></p>
                                </div>                       
                              </tr>
                            </table>                      
                          </td>
                        </tr>  
                      </table> 
                    </td>
                  </tr> 
                </tfoot>  
              </table>
            </div>           
          </div>
        </mdb-card-body>
      </mdb-card>
    </div>
  </div>
