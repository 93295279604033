import { Component, OnInit,HostListener } from '@angular/core';
import { Physio_Helper } from '../Physio_Helper';
import { Helper_Class } from '../../helper_class';
import { Router, ActivatedRoute } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { DropdownModelpageComponent } from '../../Doctor_module/dropdown-modelpage/dropdown-modelpage.component';
import { PhysioCasesheetService } from '../physio-casesheet/physio-casesheet.service';
import { ipaddress } from '../../ipaddress';

@Component({
  selector: 'app-physio-history',
  templateUrl: './physio-history.component.html',
  styleUrls: ['./physio-history.component.scss']
})
export class PhysioHistoryComponent implements OnInit {
  isReadonly() { return true; }
  public historyFlag = "min";
  public medicalHistoryMasterList = [];
  public caseHistId;
  public name: string;
  public bmi;
  public bmr;
  public stressFactor: string;
  public dietHabits: string;
  public height;
  public heightMeasure: string;
  public weight;
  public weightMeasure: string;
  public gender: string;
  public age;
  public retrvMedicalHistory = [];
  public lifeStyleId: string;
  public concerHist: string;
  public surgical: string;
  public nameLabel;
  public nameFlag:boolean;
  public genderLabel;
  public genderFlag: boolean;
  public ageLabel;
  public ageFlag: boolean;
  public mainconLabel;
  public mainconFlag: boolean;
  public pastMedicalLabel;
  public pastMedicalFlag: boolean;
  public heightFlag: boolean;
  public weightFlag: boolean;
  public weightLabel;
  public heightMeasureLabel;
  public htmeasureFlag: boolean;
  public wtmeasureFlag: boolean;
  public weightMeasureLabel;
  public bmiLabel;
  public bmiFlag: boolean;
  public bmrLabel;
  public bmrFlag: boolean;
  public bpDia:string;
  public bpSys:string;
  public stressLabel;
  public stressFlag: boolean;
  public dietaryLabel;
  public dietaryFlag: boolean;
  public mainConcern;
  public pastMedical;
  public obervationFlag: boolean;
  public sendMedicalHistory;
  public medHistFlag: string;
  public heartRateFlag: boolean;
  public heartRateLabel: string;
  public bloodPressureFlag: boolean;
  public bloodPressureLabel: string;
  public surgicalLabel: string;
  public surgicalFlag: boolean;
  public bnatureLabel: string;
  public bnatureFlag: boolean;
  public bodyNature: string;
  public heartRate: string;
  public ageReadOnlyFlag: boolean;
  subscription:Subscription;
  public bpDisabledFlag:boolean;
  public heartDisabledFlag:boolean;
  public heightLabel: string;
  public userInfo;
  public patientList;
  public historytab:boolean;
  
  constructor(public dialog:MatDialog,public http: Http, public routes: ActivatedRoute, public router: Router, public toastr: ToastrService,public messageservice1:PhysioCasesheetService, public dropdown_dialog: MatDialog) {
    this.bmr = undefined;
    this.bmi = undefined;
    this.genderFlag = true;
    this.ageFlag = true;
    this.mainconFlag = true;
    this.pastMedicalFlag = true;
    this.heightFlag = true;
    this.weightFlag = true;
    this.htmeasureFlag = true;
    this.wtmeasureFlag = true;
    this.bmiFlag = true;
    this.bmrFlag = true;
    this.stressFlag = true;
    this.dietaryFlag = true;
    this.obervationFlag = true;
    this.bnatureFlag = true;
    this.ageReadOnlyFlag=true;
  }

  ngOnInit(): void {
    this.subscription = this.messageservice1.getCasesheetMessage().subscribe(message => {
      if (message) {
          this.historyFlag = message;
      }
    });
    this.userInfo = Helper_Class.getInfo();
    this.patientList = Physio_Helper.getClient_Info();
    if(this.patientList != null && this.patientList != undefined){
      if (this.patientList.client_name != undefined && this.patientList.client_name != null) {
        this.name = this.patientList.client_name;
      }
      if (this.patientList.Gender_data != undefined && this.patientList.Gender_data != "undefined") {
        this.gender = this.patientList.Gender_data;
      }
      if (this.patientList.Age_data != undefined || this.patientList.Age_data != null) {
        this.age = this.patientList.Age_data;
      } else {
        this.age = "";
        this.ageReadOnlyFlag=false;
      }
    }
    for (var i = 0; i < Physio_Helper.getHopitalfieldsRet().length; i++) {
      if (Physio_Helper.getHopitalfieldsRet()[i].page_name == "History") {
        if (Physio_Helper.getHopitalfieldsRet()[i].fields != undefined) {
          if (Physio_Helper.getHopitalfieldsRet()[i].fields != undefined) {
            for (var j = 0; j < Physio_Helper.getHopitalfieldsRet()[i].fields.length; j++) {
              if (Physio_Helper.getHopitalfieldsRet()[i].fields[j].field_name == "Name") {
                this.nameLabel = Physio_Helper.getHopitalfieldsRet()[i].fields[j].field_name;
                this.nameFlag = false;
              }
              if (Physio_Helper.getHopitalfieldsRet()[i].fields[j].field_name == "Gender") {
                this.genderLabel = Physio_Helper.getHopitalfieldsRet()[i].fields[j].field_name;
                this.genderFlag = false;
              }
              if (Physio_Helper.getHopitalfieldsRet()[i].fields[j].field_name == "Age") {
                this.ageLabel = Physio_Helper.getHopitalfieldsRet()[i].fields[j].field_name;
                this.ageFlag = false;
              }
              if (Physio_Helper.getHopitalfieldsRet()[i].fields[j].field_name == "Main concenrns") {
                this.mainconLabel = Physio_Helper.getHopitalfieldsRet()[i].fields[j].field_name;
                this.mainconFlag = false;
              }
              if (Physio_Helper.getHopitalfieldsRet()[i].fields[j].field_name == "Past medical history") {
                this.pastMedicalLabel = Physio_Helper.getHopitalfieldsRet()[i].fields[j].field_name;
                this.pastMedicalFlag = false;
              }
              if (Physio_Helper.getHopitalfieldsRet()[i].fields[j].field_name == "Surgical history") {
                this.surgicalLabel = Physio_Helper.getHopitalfieldsRet()[i].fields[j].field_name;
                this.surgicalFlag = false;
              }
              if (Physio_Helper.getHopitalfieldsRet()[i].fields[j].field_name == "Height") {
                this.heightLabel = Physio_Helper.getHopitalfieldsRet()[i].fields[j].field_name;
                this.heightFlag = false;
              }
              if (Physio_Helper.getHopitalfieldsRet()[i].fields[j].field_name == "Weight") {
                this.weightLabel = Physio_Helper.getHopitalfieldsRet()[i].fields[j].field_name;
                this.weightFlag = false;
              }
              if (Physio_Helper.getHopitalfieldsRet()[i].fields[j].field_name == "Height measure") {
                this.heightMeasureLabel = "Measure";
                this.htmeasureFlag = false;
              }
              if (Physio_Helper.getHopitalfieldsRet()[i].fields[j].field_name == "Weight measure") {
                this.weightMeasureLabel = "Measure";
                this.wtmeasureFlag = false;
              }
              if (Physio_Helper.getHopitalfieldsRet()[i].fields[j].field_name == "BMI") {
                this.bmiLabel = Physio_Helper.getHopitalfieldsRet()[i].fields[j].field_name;
                this.bmiFlag = false;
              }
              if (Physio_Helper.getHopitalfieldsRet()[i].fields[j].field_name == "BMR") {
                this.bmrLabel = Physio_Helper.getHopitalfieldsRet()[i].fields[j].field_name;
                this.bmrFlag = false;
              }
              if (Physio_Helper.getHopitalfieldsRet()[i].fields[j].field_name == "Body nature") {
                this.bnatureLabel = Physio_Helper.getHopitalfieldsRet()[i].fields[j].field_name;
                this.bnatureFlag = false;
              }
              if (Physio_Helper.getHopitalfieldsRet()[i].fields[j].field_name == "Heart rate") {
                this.heartRateLabel = Physio_Helper.getHopitalfieldsRet()[i].fields[j].field_name;
                this.heartRateFlag = false;
              }
              if (Physio_Helper.getHopitalfieldsRet()[i].fields[j].field_name == "Blood pressure") {
                this.bloodPressureLabel = Physio_Helper.getHopitalfieldsRet()[i].fields[j].field_name;
                this.bloodPressureFlag = false;
              }
              if (Physio_Helper.getHopitalfieldsRet()[i].fields[j].field_name == "Stress factors") {
                this.stressLabel = Physio_Helper.getHopitalfieldsRet()[i].fields[j].field_name;
                this.stressFlag = false;
              }
              if (Physio_Helper.getHopitalfieldsRet()[i].fields[j].field_name == "Dietary habits") {
                this.dietaryLabel = Physio_Helper.getHopitalfieldsRet()[i].fields[j].field_name;
                this.dietaryFlag = false;
              }
              if (this.stressFlag == false || this.bmrFlag == false || this.bmiFlag == false || this.weightFlag == false || this.heightFlag == false) {
                this.obervationFlag = false;
              }
            }
          }
        }
      }
    }
    this.getMedicalHistory();
    this.getCommonData(); 
    this.getRetrievalData();
  }

  @HostListener('window:click', ['$event'])
  onclick(event: any): void {
    this.sendData();
  }
  @HostListener('window:blur', ['$event'])
  onBlur(event: any): void {
    this.sendData();
  }

  sendData() {
    var gen_hist = null, case_hist_id = null;
    if (this.caseHistId != null) {
      case_hist_id = this.caseHistId;
    } else {
      case_hist_id = undefined;
    }
    if (this.patientList != undefined && this.patientList.sub_id != null) {
      var sub_rel = this.patientList.sub_id;
    } else {
      sub_rel = undefined;
    }
    var lif_stl_id = undefined;
    if (this.lifeStyleId != null) {
      lif_stl_id = this.lifeStyleId;
    }
    if (this.name == undefined) {
      this.name= null;
    } 
    if (this.retrvMedicalHistory != null) {
      this.sendMedicalHistory = this.retrvMedicalHistory;
    } else {
      this.sendMedicalHistory = undefined;
    }
    var appid;
    if (this.patientList != undefined && this.patientList.app_id != null) {
      appid = this.patientList.app_id;
    }
    var clntid;
    if (this.patientList != undefined && this.patientList.Client_id != null) {
      clntid = this.patientList.Client_id;
    }
    var relid;
    if (this.patientList != undefined && this.patientList.rel_id != null) {
      relid = this.patientList.rel_id;
    }
    gen_hist = {
      case_hist_id: case_hist_id,
      life_style_id: lif_stl_id,
      app_id: appid,
      physiotherapist_id: this.userInfo.user_id,
      client_reg_id: clntid,
      relation_id: relid,
      sub_rel_id: sub_rel,
      name: this.name,
      gender: this.gender,
      age: this.age,
      main_concern: this.mainConcern,
      medical_history: this.sendMedicalHistory,
      medical_history_flag: this.medHistFlag,
      surgical: this.surgical,
      height: this.height,
      height_measure: this.heightMeasure,
      weight: this.weight,
      weight_measure: this.weightMeasure,
      bmi: this.bmi,
      bmr: this.bmr,
      body_nature: this.bodyNature,
      heart_rate:this.heartRate,
      bp_dia: this.bpDia,
      bp_sys:this.bpSys,
      stress_factor: this.stressFactor,
      diet_habits: this.dietHabits,
    }
    Physio_Helper.setDiabHist(gen_hist);
  }

  getCommonData() {
    var obj = Physio_Helper.getAsg();
    if(obj != null){
      if (obj.medical_history != null && obj.medical_history.length !=0) {
        for (var j = 0; j < obj.medical_history.length; j++) {
          this.retrvMedicalHistory.push(obj.medical_history[j]);
        }
        this.changePastMedical(this.retrvMedicalHistory)
      }
    }
  }

  getMedicalHistory() {
    this.medicalHistoryMasterList = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'gen/pmedhis',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.med_hist != null) {
            for (var i = 0; i < obj.med_hist.length; i++) {
              this.medicalHistoryMasterList.push({
                type: 'checkbox',
                value: obj.med_hist[i].med_hist_id,
                label: obj.med_hist[i].description,
                checked: false
              });
            }
          }
        },
        error => {
        }
      )
  }

  calculateCalories() {
    if (this.height == undefined || this.height == "" || this.weight == undefined || this.weight == "") {
      this.bmr = "";
      this.bmi = "";
    } else {
      if (this.height != undefined) {
        if (this.heightMeasure == "cms") {
          this.height = this.height.replace(/[^0-9]/g, '');
        } else {
          this.height = this.height.replace('/[^-0-9\.]/g', '');
          if (this.height.indexOf('.') !== -1) {
            if (this.height.split('.').length > 2) {
              this.height = this.height.height.slice(0, -1);
            }
          }
        }
        if (this.heightMeasure == "cms") {
          if (parseFloat(this.height) > 300) {
            this.height = this.height.slice(0, -1);
          }
        } else {
          if (parseFloat(this.height) > 100) {
            this.height = this.height.slice(0, -1);
          }
        }
      }
      if (this.weightMeasure == "kgs" && this.heightMeasure == "inch") {
        var pound = this.weight * 2.20462;
        var Height_txt = parseFloat(this.height)
        var dat = pound / (Height_txt * Height_txt);
        var to = dat * 703;
        this.bmi = Math.round(to);
        if (this.gender == "Male") {
          var get_bmr = (10 * this.weight) + (6.25 * (this.height * 2.54)) - (5 * this.age) + 5;
          this.bmr = Math.round(get_bmr);
        } else if (this.gender == "Female") {
          var get_bmr = (10 * this.weight) + (6.25 * (this.height * 2.54)) - (5 * this.age) - 161;
          this.bmr = Math.round(get_bmr);
        }
      } else if (this.weightMeasure == "kgs" && this.heightMeasure == "cms") {
        var meter = parseFloat(this.height) / 100.00;
        var to = parseFloat(this.weight) / (meter * meter);
        this.bmi = Math.round(to);
        if (this.gender == "Male") {
          var get_bmr = (10 * this.weight) + (6.25 * this.height) - (5 * this.age) + 5;
          this.bmr = Math.round(get_bmr);
        } else if (this.gender == "Female") {
          var get_bmr = (10 * this.weight) + (6.25 * this.height) - (5 * this.age) - 161;
          this.bmr = Math.round(get_bmr);
        }
      } else if (this.weightMeasure == "lbs" && this.heightMeasure == "inch") {
        var Height_txt = parseFloat(this.height);
        var dat = this.weight / (Height_txt * Height_txt);
        var to = dat * 703;
        this.bmi = Math.round(to);
        if (this.gender == "Male") {
          var get_bmr = (10 * (this.weight * 0.453592)) + (6.25 * (this.height * 2.54)) - (5 * this.age) + 5;
          this.bmr = Math.round(get_bmr);
        } else if (this.gender == "Female") {
          var get_bmr = (10 * (this.weight * 0.453592)) + (6.25 * (this.height * 2.54)) - (5 * this.age) - 161;
          this.bmr = Math.round(get_bmr);
        }
      } else if (this.weightMeasure == "lbs" && this.heightMeasure == "cms") {
        var Height_txt = parseFloat(this.height);
        var inch = Height_txt * 0.393701;
        var dat = this.weight / (inch * inch);
        var to = dat * 703;
        this.bmi = Math.round(to);
        if (this.gender == "Male") {
          var get_bmr = (10 * (this.weight * 0.453592)) + (6.25 * this.height) - (5 * this.age) + 5;
          this.bmr = Math.round(get_bmr);
        } else if (this.gender == "Female") {
          var get_bmr = (10 * (this.weight * 0.453592)) + (6.25 * this.height) - (5 * this.age) - 161;
          this.bmr = Math.round(get_bmr);
        }
      }
      if(this.bmi<18.5){
        this.bodyNature="Under weight";
      } else if(this.bmi>=18.5 && this.bmi<=24.9){
        this.bodyNature="Normal weight";
      } else if(this.bmi>=25 && this.bmi<=29.9){
        this.bodyNature="Over weight";
      } else{
        this.bodyNature="Obase";
      }
    }
  }

  selectPastMedical() {
    for (var j = 0; j < this.medicalHistoryMasterList.length; j++) {
      this.medicalHistoryMasterList[j].checked = false;
    }
    if (this.retrvMedicalHistory != null && this.retrvMedicalHistory != undefined && this.retrvMedicalHistory.length != 0) {
      for (var i = 0; i < this.retrvMedicalHistory.length; i++) {
        for (var j = 0; j < this.medicalHistoryMasterList.length; j++) {
          if (this.retrvMedicalHistory[i] == this.medicalHistoryMasterList[j].value) {
            this.medicalHistoryMasterList[j].checked = true;
          }
        }
      }
    }
    const dialogRef = this.dropdown_dialog.open(DropdownModelpageComponent, {
      width: '600px',
      height: '400px',
      data: {
        title: "Past Medical History",
        main_array: this.medicalHistoryMasterList,
        selected_list: this.retrvMedicalHistory,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        this.changePastMedical(result);
      }
    });
  }
  
  changePastMedical(selected) {
    this.pastMedical = "";
    function StringBuilder(this:any,value) {
      this.strings = new Array("");
      this.append(value);
    }
    StringBuilder.prototype.append = function (value) {
      if (value) {
        this.strings.push(value);
      }
    }
    StringBuilder.prototype.clear = function () {
      this.strings.length = 1;
    }
    StringBuilder.prototype.toString = function () {
      return this.strings.join("");
    }
    var selected_data = new StringBuilder("");
    if (selected != "" && selected != null) {
      this.retrvMedicalHistory = selected;
    }
    if (this.retrvMedicalHistory.length != 0) {
      var n = 0;
      for (var j = 0; j < this.retrvMedicalHistory.length; j++) {
        for (var i = 0; i < this.medicalHistoryMasterList.length; i++) {
          if (this.retrvMedicalHistory[j] == this.medicalHistoryMasterList[i].value) {
            n = n + 1;
            if (n > 1) {
              selected_data.append(",");
              selected_data.append("\n");
            }
            selected_data.append(this.medicalHistoryMasterList[i].label);
          }
        }
      }
      this.pastMedical = selected_data.toString();
    }
  }

  getRetrievalData() {
    this.retrvMedicalHistory=[];
    this.caseHistId = null;
    var obj = Physio_Helper.getRet();
    if(obj != undefined && obj != null){
      if (obj.case_hist_id != null) {
        var diab_treat_plan = null;
        diab_treat_plan = {
          country: ipaddress.country_code,
          ref_hospital: obj.ref_hospital,
          ref_speciality: obj.ref_speciality,
          refered_to: obj.refered_to,
        }
        if (obj.case_hist_id != null) {
          this.caseHistId = obj.case_hist_id;
        }
        if (obj.life_style_id != null) {
          this.lifeStyleId = obj.life_style_id;
        }
        if (obj.name != null) {
          this.name = obj.name;
        }
        if(obj.gender != null){
          this.gender =obj.gender;
        }
        if(obj.age != undefined ){
          this.age = obj.age;
        } else {
          this.age = this.patientList.Age_data;
        }
        if(obj.main_concern != null){
          this.mainConcern = obj.main_concern
        }
        if (obj.surgical != null) {
          this.surgical = obj.surgical;
        }
        if (obj.height != null) {
          this.height = obj.height;
          this.heightMeasure = obj.height_measure;
        }
        if (obj.weight != null) {
          this.weight = obj.weight;
          this.weightMeasure = obj.weight_measure;
        }
        if (obj.bmi != null) {
          this.bmi = obj.bmi;
          this.bmr = obj.bmr;
          this.bodyNature =obj.body_nature;
        }
        this.heartRate = obj.heart_rate;
        this.bpSys =obj.bp_sys;
        this.bpDia = obj.bp_dia;
        if (obj.stress_factor != null) {
          this.stressFactor = obj.stress_factor;
        }
        if (obj.diet_habits != null) {
          this.dietHabits = obj.diet_habits;
        }
      } 
    }else {
      this.age="";
      this.ageReadOnlyFlag=false;               
    }
  }

  physicalHistory() {
    this.concerHist = "concern_hist";
  }  
}
